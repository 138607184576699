import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private httpClient: HttpClient) {}

  public doGetRequest(
    downloadUrl: string,
    params?: HttpParams
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(downloadUrl, {
      params: params,
      responseType: 'blob',
      observe: 'response'
    });
  }

  public doPostRequest(
    url: string,
    payload: any
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient
      .post(url, payload, { responseType: 'blob', observe: 'response' })
      .pipe(map(response => response));
  }
}
