<div
    class="start-date-actions"
    *ngIf="hasProposeStartDateLink || hasAcceptStartDateLink"
>
    <general-button
        (onClick)="acceptStartDate()"
        *ngIf="hasAcceptStartDateLink"
        size="small"
        [text]="'GENERAL.ACCEPT' | translate"
    ></general-button>
    <general-button
        (onClick)="handleStartDateProposal()"
        *ngIf="hasProposeStartDateLink"
        backgroundStyle="outline"
        size="small"
        [text]="proposeDateLabel | translate"
        [color]="datePhaseIsAccepted ? 'secondary' : 'primary'"
    ></general-button>
    <general-button
        (onClick)="handleContractEnd()"
        [isDisabled]="!canEndContract"
        *ngIf="canEndContract"
        backgroundStyle="outline"
        size="small"
        [title]="'SYSTEM.INFO.END_JO' | translate"
        [text]="'GENERAL.FINISH' | translate"
        color="secondary"
    ></general-button>
    <general-button
        (onClick)="handlePauseContract()"
        *ngIf="canPauseContract"
        backgroundStyle="outline"
        size="small"
        [text]="'GENERAL.PAUSE'"
        color="secondary"
    ></general-button>
    <general-button
        (onClick)="handleResumeTechnician()"
        *ngIf="canResumeContract"
        backgroundStyle="outline"
        size="small"
        [text]="'GENERAL.RESUME' | translate"
        color="secondary"
    ></general-button>
</div>
