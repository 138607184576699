<div class="reports-container">
    <div class="wrapper" *ngIf="reportsPlatform">
        <div class="report-selection desktop">
            <select-with-tabs
                title="{{ 'REPORTS.LIST' | translate}}"
                [items]="reportsPlatform"
                [getLabel]="getReportTitle"
                [getValue]="getReportKey"
                [initialValue]="selectedReport"
                (onChange)="setSelectedReport($event)"
            >
                <staffnow-report-platform
                    *ngIf="selectedReport"
                    [report]="selectedReport"
                ></staffnow-report-platform>
            </select-with-tabs>
        </div>

        <div class="report-selection mobile">
            <h1>{{ 'REPORTS' | translate }}</h1>
            <label>{{ 'REPORTS.MOBILE.SELECTION.LABEL' | translate }}</label>
            <simple-select
                id="report-list"
                placeholder="{{'REPORTS.SELECT' | translate}}"
                [(ngModel)]="selectedReportKey"
                [items]="reportsPlatform"
                [getLabel]="getReportTitle"
                [getValue]="getReportKey"
            >
            </simple-select>
            <div class="report-component-mobile">
                <staffnow-report-platform
                    *ngIf="selectedReport"
                    [report]="selectedReport"
                ></staffnow-report-platform>
            </div>
        </div>
    </div>
</div>
