<staffnow-create-offer-banner
    [facilityProfile]="facilityProfile"
></staffnow-create-offer-banner>
<div class="contract-details-container">
    <staffnow-go-back-button
        [currentPath]="breadcrumbs"
    ></staffnow-go-back-button>
    <div *ngIf="isSettingInitialValues" class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    <div
        *ngIf="!isSettingInitialValues"
        class="create-offer-container form-container"
    >
        <!-- CREATE OFFER FORM -->
        <section class="create-offer-main">
            <div class="profile-container">
                <form
                    *ngIf="createAgencyOfferForm"
                    class="form-body"
                    novalidate
                    [ngrxFormState]="createAgencyOfferForm | async"
                >
                    <!-- TYPE AND PRIORITY -->
                    <div class="form-group highlight row create-offer-header">
                        <div class="form-group offer-priority-selector">
                            <label>
                                {{ 'Priority level*' | translate }}
                            </label>
                            <simple-select
                                placeholder="Choose a priority"
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                [ngrxFormControlState]="
                                    createAgencyOfferFormValue.controls.priority
                                "
                            >
                            </simple-select>
                            <span class="user-guide">
                                <small class="text-muted">
                                    {{
                                        'Only visible for Aviation Companies and Agencies.'
                                            | translate
                                    }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <!-- REFERENCE NUMBER AND DATE -->
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            <custom-reference-input
                                *ngIf="!isSettingInitialValues"
                                [isEditMode]="isEditForm"
                                [isRestrictedMode]="isRestrictedMode()"
                                [formState]="createAgencyOfferFormValue"
                            ></custom-reference-input>
                        </div>
                        <div class="form-group col">
                            <label>{{ 'Pick a period*' | translate }}</label>
                            <date-picker-range
                                [range]="agencyOfferPeriod"
                                [disabled]="true"
                            ></date-picker-range>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label>{{ 'Title' | translate }}</label>
                            <input
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                maxlength="60"
                                [placeholder]="
                                    'Type 6 to 60 characters to give a name to your job opening'
                                        | translate
                                "
                                [ngrxFormControlState]="
                                    createAgencyOfferFormValue.controls.title
                                "
                            />
                        </div>
                    </div>
                    <div
                        class="form-group row"
                    >
                        <div class="form-group col">
                            <label for="location">{{ 'Location*' | translate }}</label>
                            <input
                                id="location"
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                disabled
                                [value]="location.name"
                            />
                        </div>
                    </div>
                    <!-- TECHNICIAN TYPE AND LICENSE -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label>{{ 'Technician Type*' | translate }}</label>
                            <select
                                [disabled]="
                                    createAgencyOfferFormValue.controls.ameType
                                        .isDisabled
                                "
                                class="custom-select"
                                name="type"
                                [ngClass]="{
                                    'is-invalid':
                                        createAgencyOfferFormValue.controls
                                            .ameType.isDirty &&
                                        createAgencyOfferFormValue.controls
                                            .ameType.isInvalid
                                }"
                                (change)="onAmeTypeChange($event.target.value)"
                                [(ngModel)]="manualUpdateControlValues.ameType"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="null">
                                    {{ 'Select an option' | translate }}
                                </option>
                                <option
                                    *ngFor="
                                        let option of ameNomenclatureOptions
                                    "
                                    [value]="option.name"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group col"
                            *ngIf="!isSingletonNomenclature"
                        >
                            <label for="level">
                                {{ 'Technician Level*' | translate }}
                            </label>
                            <select
                                class="custom-select"
                                [disabled]="
                                    createAgencyOfferFormValue.controls
                                        .ameTitleId.isDisabled
                                "
                                id="level"
                                [ngClass]="{
                                    'is-invalid':
                                        createAgencyOfferFormValue.controls
                                            .ameTitleId.isDirty &&
                                        createAgencyOfferFormValue.controls
                                            .ameTitleId.isInvalid
                                }"
                                (change)="
                                    onAmeLicenseChange($event.target.value)
                                "
                                [(ngModel)]="
                                    manualUpdateControlValues.ameTitleId
                                "
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="null">
                                    {{ 'Select an option' | translate }}
                                </option>
                                <option
                                    *ngFor="
                                        let license of ameLicenseTypeOptions
                                    "
                                    [value]="license.id"
                                >
                                    {{ license.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- AIRCRAFTS -->
                    <div class="form-group row">
                        <div class="form-group col aircrafts">
                            <label for="airplanes">{{
                                    'Type of aircraft**' | translate
                                }}</label>
                            <multiple-options-select
                                id="airplanes"
                                placeholder="Select aircraft"
                                [elements]="aircraftList"
                                [selectedElements]="selectedAircraftList"
                                (onChange)="getListOfAircrafts($event)"
                                [disabled]="
                                    createAgencyOfferFormValue.controls
                                        .airplanes.isDisabled
                                "
                            ></multiple-options-select>
                        </div>
                        <div class="col">
                            <label for="other-airplanes">
                                {{ 'Other aircraft**' | translate }}
                            </label>
                            <input
                                id="other-airplanes"
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                (keypress)="handleOtherAirplanesLength($event)"
                                [ngrxFormControlState]="
                                    createAgencyOfferFormValue.controls
                                        .otherAirplanes
                                "
                            />
                        </div>
                    </div>
                    <!-- EXPERIENCE AND OPEN POSITIONS -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="experience">{{
                                    'Min Experience*' | translate
                                }}</label>
                            <div class="input-group">
                                <input
                                    id="experience"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="60"
                                    autocomplete="off"
                                    placeholder="{{
                                        'Enter min experience' | translate
                                    }}"
                                    (keypress)="experienceValidation($event)"
                                    [disabled]="
                                        createAgencyOfferFormValue.controls
                                            .minExperience.isDisabled
                                    "
                                    [ngrxFormControlState]="
                                        createAgencyOfferFormValue.controls
                                            .minExperience
                                    "
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text" id="years">{{
                                            'years' | translate
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <div>
                                <label for="vacancies">{{
                                        'Number of Technicians*' | translate
                                    }}</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    id="vacancies"
                                    min="0"
                                    max="1000"
                                    autocomplete="off"
                                    (input)="vacanciesValidation($event)"
                                    placeholder="{{
                                        'Enter number of technicians'
                                            | translate
                                    }}"
                                    [ngrxFormControlState]="
                                        createAgencyOfferFormValue.controls
                                            .vacancies
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <!-- PAY RATE -->
                    <div class="form-group row col">
                        <!--Indicative rate option-->
                        <div class="form-group col custom-control custom-checkbox">
                            <input
                                id="hasIndicativeRate"
                                type="checkbox"
                                class="custom-control-input"
                                [disabled]="this.isEditMode"
                                (click)="toggleIndicativeRateOption($event)"
                                [ngrxFormControlState]="createAgencyOfferFormValue.controls.hasIndicativeRate"
                            />
                            <label
                                for="hasIndicativeRate"
                                class="custom-control-label"
                            >
                                Publicly listed rate range per hour
                            </label>
                        </div>
                    </div>
                    <!--With indicative rate-->
                    <div class="form-group row" *ngIf="hasIndicativeRate else noIndicativePayRate">
                        <div class="form-group col">
                            <label for="payRate">
                                {{ 'Pay rate per hour (lowest)*' | translate }}
                            </label>
                            <div class="input-group">
                                <input
                                    id="payRate"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="payRateMaxValue"
                                    autocomplete="off"
                                    placeholder="{{
                                        'Lowest' | translate
                                    }}"
                                    (keypress)="payRateValidation($event)"
                                    [ngrxFormControlState]="
                                        createAgencyOfferFormValue.controls.payRate
                                    "
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {{ currencySymbol }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <label for="payRate">
                                {{ 'Pay rate per hour (highest)*' | translate }}
                            </label>
                            <div class="input-group">
                                <input
                                    id="maxPayRate"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="payRateMaxValue"
                                    autocomplete="off"
                                    placeholder="{{
                                        'Highest' | translate
                                    }}"
                                    (keypress)="payRateValidation($event)"
                                    [ngrxFormControlState]="
                                        createAgencyOfferFormValue.controls.maxPayRate
                                    "
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {{ currencySymbol }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Without indicative rate-->
                    <ng-template #noIndicativePayRate>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="payRate">{{
                                        'Pay rate per hour*' | translate
                                    }}</label>
                                <div class="input-group">
                                    <input
                                        id="payRate"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        max="payRateMaxValue"
                                        autocomplete="off"
                                        placeholder="{{
                                            'Enter pay rate per hour' | translate
                                        }}"
                                        (keypress)="payRateValidation($event)"
                                        [ngrxFormControlState]="
                                            createAgencyOfferFormValue.controls.payRate
                                        "
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            {{ currencySymbol }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col">
                                &nbsp;
                            </div>
                        </div>
                    </ng-template>
                    <!-- WORKING PATTERN AND DESCRIPTION -->
                    <div class="form-group row">
                        <div class="form-group col-4 pattern-view">
                            <label>{{ 'Work Pattern*' | translate }}</label>
                            <div class="custom-controls-stacked">
                                <div class="custom-control custom-radio">
                                    <input
                                        id="first-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="{{ fixedWorkingPatterns.first }}"
                                        [checked]="
                                            isPatternSelected(
                                                fixedWorkingPatterns.first
                                            )
                                        "
                                        (change)="
                                            setWorkingPattern(
                                                $event.target.value
                                            )
                                        "
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label
                                        for="first-radio"
                                        class="custom-control-label"
                                    >
                                        {{
                                            '5 days on - 2 days off' | translate
                                        }}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        id="second-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="{{
                                            fixedWorkingPatterns.second
                                        }}"
                                        [checked]="
                                            isPatternSelected(
                                                fixedWorkingPatterns.second
                                            )
                                        "
                                        (change)="
                                            setWorkingPattern(
                                                $event.target.value
                                            )
                                        "
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label
                                        for="second-radio"
                                        class="custom-control-label"
                                    >
                                        {{
                                            '3 days on - 3 days off' | translate
                                        }}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        id="third-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="{{ fixedWorkingPatterns.third }}"
                                        [checked]="
                                            isPatternSelected(
                                                fixedWorkingPatterns.third
                                            )
                                        "
                                        (change)="
                                            setWorkingPattern(
                                                $event.target.value
                                            )
                                        "
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label
                                        for="third-radio"
                                        class="custom-control-label"
                                    >
                                        {{
                                            '6 days on - 1 days off' | translate
                                        }}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        id="fourth-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="custom"
                                        (click)="customPatternSet()"
                                        [checked]="isCustomPatternSelected"
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label
                                        for="fourth-radio"
                                        class="custom-control-label"
                                    >
                                        {{ 'Custom' | translate }}
                                    </label>
                                </div>
                                <div class="form-row mt-2">
                                    <div
                                        class="form-group col-6 padded"
                                        *ngIf="isCustomPatternSelected"
                                    >
                                        <label>{{
                                                'Work Days*' | translate
                                            }}</label>
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="work-days"
                                            [tooltip]="'Work days' | translate"
                                            name="ondays"
                                            min="1"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            (input)="
                                                setCustomWorkingPattern(
                                                    $event,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'is-invalid':
                                                    createAgencyOfferFormValue
                                                        .controls.workDaysOn
                                                        .isInvalid
                                            }"
                                            [(ngModel)]="
                                                customWorkDaysPattern.on
                                            "
                                            [disabled]="isRestrictedMode()"
                                        />
                                    </div>
                                    <div
                                        class="form-group col-6"
                                        *ngIf="isCustomPatternSelected"
                                    >
                                        <label>{{
                                                'Off Days*' | translate
                                            }}</label>
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="off-days"
                                            [tooltip]="'Off days' | translate"
                                            name="offdays"
                                            min="0"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            (input)="
                                                setCustomWorkingPattern(
                                                    $event,
                                                    false
                                                )
                                            "
                                            [ngClass]="{
                                                'is-invalid':
                                                    createAgencyOfferFormValue
                                                        .controls.workDaysOff
                                                        .isInvalid
                                            }"
                                            [(ngModel)]="
                                                customWorkDaysPattern.off
                                            "
                                            [disabled]="isRestrictedMode()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label>{{
                                    'Job description*' | translate
                                }}</label>
                            <textarea
                                class="form-control"
                                name="presentation"
                                cols="30"
                                rows="5"
                                [attr.maxlength]="PRESENTATION_MAX_LENGTH"
                                [ngrxFormControlState]="
                                    createAgencyOfferFormValue.controls
                                        .shortPresentation
                                "
                            >
                            </textarea>
                        </div>
                    </div>
                    <!-- ADDITIONAL BENEFITS -->
                    <div class="form-group row mb-3" *ngIf="!hasIndicativeRate">
                        <collapsible
                            heading="Benefits"
                            style="width: 100%"
                            highlight="true"
                        >
                            <div style="padding: 0 20px">
                                <staffnow-offer-benefit
                                    *ngFor="
                                        let benefit of benefits;
                                        let i = index
                                    "
                                    [(ngModel)]="benefits[i]"
                                    [ngModelOptions]="{ standalone: true }"
                                    [currencySymbol]="currencySymbol"
                                    [isValid]="isBenefitValid(benefits[i])"
                                    (changer)="markBenefitsAsDirty()"
                                    [isDisabled]="isRestrictedMode()"
                                ></staffnow-offer-benefit>
                            </div>
                        </collapsible>
                    </div>
                    <!-- FORM ACTIONS -->
                    <div
                        class="form-row justify-content-center create-offer-form-actions"
                    >
                        <general-button
                            (onClick)="cancelCreation()"
                            class="mr-5"
                            backgroundStyle="outline"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            [text]="'CONFIRM' | translate"
                            [isLoading]="isSubmitting"
                            [isDisabled]="
                                !createAgencyOfferFormValue.isValid ||
                                !createAgencyOfferFormValue.isDirty ||
                                !areBenefitsValid()
                            "
                            (onClick)="handleFormSubmit()"
                        ></general-button>
                    </div>
                </form>
            </div>
        </section>
    </div>
</div>
