import {Component, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {getCurrentUTCDateString} from '@libs/shared/helpers/date-utils';
import {Reminder} from '../../../models/reminder.model';

@Component({
  selector: 'staffnow-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrls: ['./date-picker-dialog.component.scss']
})
export class DatePickerDialogComponent {
  @Input() public onAcceptCallback: Function;
  @Input() public selectedDate: string = getCurrentUTCDateString();
  @Input() public warningMessage: string = null;
  @Input() public title: string = null;
  @Input() public reminders: Array<Reminder> = [];
  @Input() public isBackoffice: boolean = false;
  @Input() public minDate: string = null;
  @Input() public maxDate: string = null;

  constructor(public bsModalRef: BsModalRef) {
  }

  public submit() {
    this.onAcceptCallback(this.selectedDate);
    this.bsModalRef.hide();
  }

  public handleDateUpdate(date: string): void {
    this.selectedDate = date;
  }

}
