import {
  initialState,
  RegistrationComponentState
} from './registration-state.model';
import {
  RegistrationAction,
  RegistrationActionTypes
} from '../state/registration.actions';

export function registrationComponentReducer(
  state: RegistrationComponentState = initialState,
  action: RegistrationAction
): RegistrationComponentState {
  if (action) {
    switch (action.type) {
      case RegistrationActionTypes.TechnicianRegistrationFormSubmitted:
        return {
          ...state,
          registerStatus: 'LOADING'
        };
      case RegistrationActionTypes.FailedToRegUser:
        return {
          ...state,
          registerStatus: 'ERROR',
          errorMessage: action.payload.error.message
        };
      case RegistrationActionTypes.SuccessRegUser:
        return {
          ...state,
          registerStatus: 'SUCCESS',
          registerEntity: 'TECHNICIAN'
        };

      case RegistrationActionTypes.ResetForm:
        return {
          ...initialState,
          registerStatus: 'IDLE'
        };
    }
  }

  return state;
}
