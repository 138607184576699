import {Capacitor} from "@capacitor/core";

function getPlatformType() {
  return Capacitor.getPlatform();
}

export const isPlatformWeb = (): boolean => {
    return getPlatformType() === 'web'
}

export const isPlatformIos = (): boolean => {
    return getPlatformType() === 'ios'
}

export const isPlatformAndroid = (): boolean => {
    return getPlatformType() === 'android'
}
