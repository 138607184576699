<div class="active">
    <div>
        <div class="d-flex align-content-center flex-row">
            <div class="flex-grow-1 d-flex flex-column justify-content-center">
                <div class="line"></div>
            </div>
            <div>
                <div>
                    <i class="material-icons stepper-icon"> done </i>
                </div>
            </div>
            <div class="flex-grow-1 d-flex flex-column justify-content-center">
                <div class="line"></div>
            </div>
        </div>
    </div>
</div>
<div class="success-message">
    <h1 class="mb-4 title">
        {{'REGISTRATION.MRO.SUCCESS.TITLE' | translate}}
    </h1>
    <p class="mb-4">
        {{'REGISTRATION.MRO.SUCCESS.EMAIL' | translate: {brandName: brandName$ | async} }}
    </p>
    <p class="mb-4">
        {{'REGISTRATION.MRO.SUCCESS.REVIEW' | translate: {brandName: brandName$ | async} }}
    </p>
    <p class="font-weight-bold">
        {{'REGISTRATION.MRO.SUCCESS.SPAM' | translate: {brandName: brandName$ | async} }}
    </p>
</div>
<div class="mro-registration-actions">
    <general-button
        (onClick)="close()"
        backgroundStyle="outline"
        [text]="'GENERAL.CLOSE' | translate"
    ></general-button>
</div>
