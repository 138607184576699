<staffnow-alert-banner
    *ngIf="shouldShowIncompleteWarning()"
    [message]="'SYSTEM.INFO.INCOMPLETE_PROFILE_TITLE' | translate"
    [additionalMessage]="'SYSTEM.INFO.INCOMPLETE_PROFILE_TITLE_INFO' | translate"
></staffnow-alert-banner>
<article
    class="profile-container"
    [class.incomplete]="shouldShowIncompleteWarning()"
>
    <staffnow-user-info-section
        [availability]="technicianAvailability"
        [selectedAircraftsArray]="selectedAircraftList"
        (goToConversation)="navigateToMailbox()"
        (isStaffie)="isStaffieChangedHandler($event)"
        [canMarkInReview]="canMarkInReview"
    >
    </staffnow-user-info-section>
    <staffnow-edit-section
        #editSection
        [technicianPendingChanges]="technicianPendingChanges"
        [selectedAircraftList]="selectedAircraftList"
        [availability]="technicianAvailability"
        [profileNavItems]="profileNavItems"
        [blocklist]="blocklist"
        (loadBlocklist)="handleLoadBlocklist()"
        (submitBlocklist)="handleSubmitBlocklist($event)"
        (fetchAvailability)="handleFetchAvailability()"
        (loadMonths)="handleLoadMonths($event)"
    >
    </staffnow-edit-section>
</article>
