<button
    (click)="handleClick($event)"
    (keyup.enter)="handleClick($event)"
    *ngIf="isButtonSubmit(); else regularButtonComponent"
    [disabled]="isDisabled"
    [ngClass]="getButtonClasses()"
    [style]="getButtonStyle()"
    type="submit"
>
    <template [ngTemplateOutlet]="buttonContent"></template>
</button>
<ng-template #regularButtonComponent>
    <button
        (click)="handleClick($event)"
        [disabled]="isDisabled"
        [ngClass]="getButtonClasses()"
        [style]="getButtonStyle()"
        type="button"
    >
        <template [ngTemplateOutlet]="buttonContent"></template>
    </button>
</ng-template>
<ng-template #buttonContent>
    <ng-container *ngIf="!isLoading; else loaderTemplate">
        <template *ngIf="isIconOnLeft()" [ngTemplateOutlet]="iconTemplate"></template>
        <span> {{ text }} </span>
        <template *ngIf="isIconOnRight()" [ngTemplateOutlet]="iconTemplate"></template>
    </ng-container>
</ng-template>
<ng-template #iconTemplate>
    <i [ngClass]="getIconClasses()"> {{ icon }} </i>
</ng-template>
<ng-template #loaderTemplate>
    <staff-loader [customClass]="customClass"></staff-loader>
</ng-template>
