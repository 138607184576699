import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AviationCompany} from "../../services/mro-facility-loader.service";

@Component({
  selector: 'aviation-company-selector',
  templateUrl: './aviation-company-selector.component.html',
  styleUrls: ['./aviation-company-selector.component.scss']
})
export class AviationCompanySelectorComponent {
  @Input() public isDisabled: boolean = true;
  @Input() public facilityList: AviationCompany[] = [];
  @Input() public selectedFacility: AviationCompany = null;
  @Output() public onUpdate = new EventEmitter<AviationCompany>();

  public handleChange(selectedFacility: AviationCompany): void {
    this.onUpdate.emit(selectedFacility);
  }

}
