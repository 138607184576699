<i (click)="bsModalRef.hide()" class="material-icons close-icon"> clear </i>
<div class="header">
    <h1 class="heading">{{ 'AGENCY_REJECT.REJECT_TECHNICIAN' | translate }}</h1>
    <p class="text-muted sub-heading"> {{ 'AGENCY_REJECT.EXPLANATION' | translate}}</p>
</div>
<div class="content">
    <div class="notes-container">
        <label class="notes-label" for="reasons">{{ 'GENERAL.REJECTION_REASON' | translate }}</label>
        <staffnow-textarea
            [(ngModel)]="explanation"
            [ngModelOptions]="{ standalone: true }"
            [rows]="8"
            id="reasons"
            name="reasons"
            textAreaMaxLength="{{textAreaMaxLength}}"
        ></staffnow-textarea>
    </div>
    <general-button
        (onClick)="submit()"
        [isDisabled]="isDisabled"
        [text]="'GENERAL.SUBMIT' | translate"
    ></general-button>
</div>
