import { Pipe, PipeTransform } from '@angular/core';
import { toReadable } from '../models/roles.enum';

@Pipe({
  name: 'humanReadableRole'
})
export class HumanReadableRolePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return toReadable(value);
  }
}
