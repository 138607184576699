import { createAction, props } from '@ngrx/store';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { MroRate } from '@libs/shared/models/mro-rate.model';

export const DeleteAgencyPicture = createAction(
  '[Facility Profile - Data] Delete picture',
  props<{ deletePictureUrl: string; facilityUrl: string }>()
);

export const DeleteAgencyPictureFailed = createAction(
  '[Facility Profile - Data] Delete picture successful'
);

export const DeleteAgencyDefaultContract = createAction(
  '[Facility Profile - Data] Upload default contract',
  props<{ document: Resource }>()
);

export const DeleteAgencyDefaultContractSuccessful = createAction(
  '[Facility Profile - Data] Delete default contract successful'
);

export const DeleteAgencyDefaultContractFailed = createAction(
  '[Facility Profile - Data] Delete default contract failed'
);

export const DeleteAgencyApprovals = createAction(
  '[Facility Profile - Data] DeleteAgencyApprovals',
  props<{ document: Resource }>()
);

export const DeleteAgencyApprovalsSuccessful = createAction(
  '[Facility Profile - Data] DeleteAgencyApprovalsSuccessful'
);

export const DeleteAgencyApprovalsFailed = createAction(
  '[Facility Profile - Data] DeleteAgencyApprovalsFailed'
);

export const SetAgencyApprovalsLabel = createAction(
  '[Facility Profile - Data] SetAgencyApprovalsLabel',
  props<{ document: Resource; newLabel: string }>()
);

export const SetAgencyApprovalsLabelSuccessful = createAction(
  '[Facility Profile - Data] SetAgencyApprovalsLabelSuccessful'
);

export const SetAgencyApprovalsLabelFailed = createAction(
  '[Facility Profile - Data] SetAgencyApprovalsLabelFailed'
);

export const DownloadAgencyDefaultContract = createAction(
  '[Facility Profile - Data] Download default contract',
  props<{ document: Resource }>()
);

export const DownloadAgencyDefaultContractSuccessful = createAction(
  '[Facility Profile - Data] Download default contract successful'
);

export const DownloadAgencyDefaultContractFailed = createAction(
  '[Facility Profile - Data] Download default contract failed'
);

export const DownloadAgencyApprovals = createAction(
  '[Facility Profile - Data] DownloadAgencyApprovals',
  props<{ document: Resource }>()
);

export const DownloadAgencyApprovalsSuccessful = createAction(
  '[Facility Profile - Data] DownloadAgencyApprovalsSuccessful'
);

export const DownloadAgencyApprovalsFailed = createAction(
  '[Facility Profile - Data] DownloadAgencyApprovalsFailed'
);

export const MarkFacilityAsVerified = createAction(
  '[Facility Profile - Verify] Set the facility status to verified',
  props<{ actionUrl: string }>()
);

export const MarkFacilityAsPending = createAction(
  '[Facility Profile - Pending] Set the facility status to pending',
  props<{ actionUrl: string }>()
);

export const GetFacilityProfile = createAction(
  '[Facility Profile - Data] Get facility profile',
  props<{ facilityUrl: string }>()
);
export const FacilityProfileLoaded = createAction(
  '[Facility Profile - Data] Facility profile loaded',
  props<{ payload: any }>()
);
export const ResetFacilityProfile = createAction(
  '[Facility Profile - Data] Facility profile reset'
);
export const FailedToGetFacilityProfile = createAction(
  '[Facility Profile - Data] Failed to get facility profile'
);
export const UpdateFacilityProfile = createAction(
  '[Facility Profile - Data] Update facility profile',
  props<{
    payload: any;
    endpointUrl: string;
    facilityUrl: string;
  }>()
);
export const FacilityProfileUpdated = createAction(
  '[Facility Profile - Data] Facility profile successfully updated'
);
export const FailedToUpdateFacilityProfile = createAction(
  '[Facility Profile - Data] Facility profile updated'
);
export const GetApprovedAgencies = createAction(
  '[Facility Profile - Data] Get approved agencies'
);
export const ApprovedAgenciesLoaded = createAction(
  '[Facility Profile - Data] Approved agencies loaded',
  props<{
    payload: {
      selectedFacilities: Array<string>;
      availableFacilities: Array<any>;
    };
  }>()
);
export const FailedToGetApprovedAgencies = createAction(
  '[Facility Profile - Data] Failed to get approved agencies'
);
export const SubmitApprovedAgencies = createAction(
  '[Facility Profile - Data] Submit approved agencies',
  props<{ facilities: Array<string> }>()
);
export const FailedToSubmitApprovedAgencies = createAction(
  '[Facility Profile - Data] Failed to submit approved agencies'
);
export const GetMroRates = createAction(
  '[Facility Profile - Data] Get Mro rates'
);
export const MroRatesLoaded = createAction(
  '[Facility Profile - Data] Mro Rate loaded',
  props<{
    rates: Array<MroRate>;
  }>()
);
export const FailedToGetMroRates = createAction(
  '[Facility Profile - Data] Failed to get mro rates'
);
export const SubmitMroRates = createAction(
  '[Facility Profile - Data] Submit mro rates',
  props<{ rates: Array<MroRate> }>()
);
export const FailToSubmitMroRates = createAction(
  '[Facility Profile - Data] Failed to submit mro rates'
);
export const GetMroApprovals = createAction(
  '[Facility Profile - Data] Get MRO approvals'
);
export const MroApprovalsLoaded = createAction(
  '[Facility Profile - Data] MRO approvals loaded',
  props<{ payload: Array<any> }>()
);
export const FailedToGetMroApprovals = createAction(
  '[Facility Profile - Data] Failed to get MRO approvals'
);
