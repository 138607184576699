import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'select-with-tabs',
  templateUrl: './select-with-tabs.component.html',
  styleUrls: ['./select-with-tabs.component.scss']
})
export class SelectWithTabsComponent implements OnChanges {
  @Input() title: string = null;
  @Input() items: any[];
  @Input() initialValue: any;
  @Input() getLabel = item => item.name;
  @Input() getValue = item => item;

  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public value: any = null;

  public setValue(value: any): void {
    this.value = value;
    this.onChange.emit(value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.hasChanged(changes.initialValue) ||
      this.hasChanged(changes.items)
    ) {
      this.setInitialValue();
    }
  }

  private hasChanged(item) {
    return item && !isEqual(item.currentValue, item.previousValue);
  }

  private setInitialValue() {
    this.setValue(this.getValue(this.initialValue));
  }
}
