import {Component, Input, OnChanges} from '@angular/core';
import {addUtils, Contract, ContractWithUtils} from '@libs/shared/models/contract.model';

@Component({
  selector: 'staffnow-reasons-list',
  templateUrl: './reasons-list.component.html',
  styleUrls: ['./reasons-list.component.scss']
})
export class ReasonsListComponent implements OnChanges {
  @Input() public contract: Contract = null;
  @Input() public isAgency: boolean = false;

  public contractWithUtils: ContractWithUtils = null;

  get pendingReason(): any {
    return {
      ...this.contractWithUtils.getPendingReason(),
      name: 'MORE_DOCS_NEEDED'
    };
  }

  ngOnChanges(): void {
    this.contractWithUtils = addUtils(this.contract);
  }
}
