import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import {
  getEmbeddedResource,
  getUrl,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';

@Component({
  selector: 'staffnow-technician-picture-and-name',
  templateUrl: './technician-picture-and-name.component.html',
  styleUrls: ['./technician-picture-and-name.component.scss']
})
export class TechnicianPictureAndNameComponent {
  @Input() public resource: Resource = null;
  @Output() public onClick = new EventEmitter<string>();

  get hasProfilePicture(): boolean {
    return hasLink(this.resource, UserProfileLinkRel.GetProfilePicture);
  }

  get profilePicture(): string {
    return `'${getUrl(this.resource, UserProfileLinkRel.GetProfilePicture)}'`;
  }

  @HostListener('click') handleClick() {
    const profile: any = getEmbeddedResource(
      this.resource,
      UserProfileLinkRel.Profile
    );
    this.onClick.emit(profile.userUuid);
  }
}
