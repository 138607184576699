<ng-template [ngIf]="!error">
    <ng-template ngFor let-filter [ngForOf]="filterListConfig" let-i="index">
        <div *ngIf="filter.type === FILTER_TYPES.BreakLine" class="break-line"></div>
        <abstract-filter
            *ngIf="filter.type !== FILTER_TYPES.BreakLine"
            [ngClass]="filterListConfig[i].className + '-container'"
            [filterConfig]="filter"
            (enterPressed)="isEnterPressed()"
            (handleChange)="onChangeHandler($event)"
        ></abstract-filter>
    </ng-template>
    <div class="search-actions">
        <general-button
            (onClick)="clearSearch()"
            [isDisabled]="isFilterEmpty()"
            size="small"
            backgroundStyle="outline"
            color="secondary"
            text="CLEAR"
        ></general-button>
        <general-button (onClick)="doSearch()" size="small" text="SEARCH"></general-button>
    </div>
</ng-template>
