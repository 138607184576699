export enum MENU_ITEMS_ENUM {
  BACKOFFICE = 'Backoffice',
  TECHNICIANS = 'Technicians',
  MROS = 'Aviation Companies',
  AGENCIES = 'Agencies'
}

export enum MENU_ITEMS_REVERSE_MAPPING_ENUM {
  'Backoffice' = 'BACKOFFICE',
  'Technicians' = 'TECHNICIANS',
  'Aviation Companies' = 'MROS',
  'Agencies' = 'AGENCIES'
}
