<div class="select">
    <div class="select-label">
        <h1 class="h1">
            {{ 'AGENCIES.SELECT.I_WORK_WITH' | translate }}
        </h1>
        <p class="guidelines">
            {{ 'APPROVED_AGENCIES_SELECT.TITLE' | translate}}
        </p>
        <p class="guidelines"
           [innerHTML]="'APPROVED_AGENCIES_SELECT.TOTAL_SELECTED' | translate: {count: approvedAgencies.selectedFacilities.length }">
        </p>
        <general-button
            text="Confirm agency changes"
            [isLoading]="isLoading"
            [isDisabled]="!isDirty || isDisabled"
            (onClick)="submitApprovedAgencies()"
        ></general-button>
    </div>
    <staffnow-promotional-list
        [isDisabled]="isDisabled"
        [selectedAgencies]="approvedAgencies.selectedFacilities"
        [availableAgencies]="approvedAgencies.availableFacilities"
        (onSelectionUpdate)="updateApprovedAgencies($event)"
    ></staffnow-promotional-list>
</div>
