import { Component, Input } from '@angular/core';
import { OfferPriority } from '@libs/shared/models/offer.model';

@Component({
  selector: 'staffnow-offer-priority-info',
  templateUrl: './offer-priority-info.component.html',
  styleUrls: ['./offer-priority-info.component.scss']
})
export class OfferPriorityInfoComponent {
  @Input() public priority: OfferPriority = null;
  @Input() public displayLabel: boolean = true;

  public isHighPriority() {
    return this.priority === OfferPriority.HIGH;
  }

  public isMediumPriority() {
    return this.priority === OfferPriority.MEDIUM;
  }

  public isLowPriority() {
    return this.priority === OfferPriority.LOW;
  }
}
