import { createAction, props } from '@ngrx/store';
import { Contract } from '@libs/shared/models/contract.model';

export const LoadOwnTechnicianOfferWithContracts = createAction(
  '[Requests Overview] Load own technician offer with contracts',
  props<{ refNumber: string; technicianUuid: string }>()
);
export const OfferWithContractsLoaded = createAction(
  '[Requests Overview] Offer with contracts loaded',
  props<{ response: any }>()
);
export const FailedToLoadOfferWithContracts = createAction(
  '[Requests Overview] Failed to load offer with contracts'
);
export const PermanentOfferWithContractsLoaded = createAction(
  '[Requests Overview] Permanent offer with contracts loaded',
  props<{ response: any }>()
);
export const FailedToLoadPermanentOfferWithContracts = createAction(
  '[Requests Overview] Failed to load permanent offer with contracts'
);
export const NotifyContractEnd = createAction(
  '[Requests Overview] Notify contract can be ended',
  props<{ contract: any; offerRefNumber: string }>()
);
export const FailedToNotifyContractEnd = createAction(
  '[Requests Overview] Failed to notify contract can be ended'
);
export const DisableExperienceLetter = createAction(
  '[Requests Overview] Disable Experience Letter',
  props<{ contract: Contract }>()
);
export const EnableExperienceLetter = createAction(
  '[Requests Overview] Enable Experience Letter',
  props<{ contract: Contract }>()
);
export const ResetOfferDetails = createAction(
  '[Requests Overview] Reset offer details'
);
export const AgencyOfferWithContractsLoaded = createAction(
  '[Requests Overview] Agency Offer with contracts loaded',
  props<{ response: any }>()
);
export const FailedToLoadAgencyOfferWithContracts = createAction(
  '[Requests Overview] Failed to load agency offer with contracts'
);
