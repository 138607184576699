import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'entity-nav',
  templateUrl: './entity-nav.component.html',
  styleUrls: ['./entity-nav.component.scss']
})
export class EntityNavComponent {
  @Input() public menuItems: Array<string> = [];
  @Input() public activeItem: string = '';
  @Output() public selectedItem = new EventEmitter();

  constructor() {}

  public selectNewItem(item: string): void {
    this.selectedItem.emit(item);
  }
}
