import { Component, Input } from '@angular/core';

@Component({
  selector: 'staffnow-registration-go-back',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['./go-back-button.component.scss']
})
export class GoBackButtonComponent {
  @Input() public onClick: Function;

  public callback(): void {
    this.onClick();
  }
}
