import { Pipe, PipeTransform } from '@angular/core';
import { profileStatusConstants } from '../constants/statuses.constants';

@Pipe({
  name: 'humanReadableProfileStatus'
})
export class HumanReadableProfileStatusPipe implements PipeTransform {
  constructor() {}
  transform(value: string): string {
    return profileStatusConstants.serverLangToHumanLang[value];
  }
}
