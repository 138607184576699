import { Resource } from '@libs/shared/bms-common/rest/resource.model';

export enum DocumentsLinkRel {
  SET_DOCUMENT_EXPIRATION_DATE = 'setDocumentExpirationDate',
  SET_DOCUMENT_HAS_POSSESSION = 'setDocumentHasPossession',
  LABEL_DOCUMENT = 'label'
}

export interface StaffnowDocument extends Resource {
  uuid: string;
  label: string;
  name: string;
  context: string;
  fileUrl: string;
  description: string;
  mimeType: string;
  createdOn: string;
  expirationDate?: string;
  rejected?: boolean;
  pendingChange?: boolean;
  isExpirationDateRequired: boolean;
}

export enum FileSizesInMB {
  FOUR = 4,
  TWENTY = 20
}

export enum FileAmounts {
  TWO = 2,
  THREE = 3,
  TEN = 10,
  TWENTY = 20,
  TWENTY_FIVE = 25,
  HUNDRED = 100
}

export interface DocumentType extends Resource {
  context: string;
  title: string;
  tooltip: string;
  allowedFileTypes: Array<string>;
  fileSizeLimit: FileSizesInMB;
  maximumFilesLimit: FileAmounts;
  multipleFileSelect: boolean;
  isMandatory: boolean;
  isExpirationDateRequired: boolean;
  hasPossessionCheck: boolean;
  hasPossession: boolean;
}

export interface DocumentArea {
  id: number;
  title: string;
  isExpanded: boolean;
  documentEntities: Array<DocumentType>;
}

export interface DocumentSummary {
  title: string;
  isExpanded: boolean;
  documentTypes: Array<DocumentType>;
}

export const RELATED_CONTEXT: string[][] = [
  ['EWIS_INITIAL', 'EWIS_RENEWED'],
  ['FTS_INITIAL', 'FTS_RENEWED'],
  ['HF_INITIAL', 'HF_RENEWED']
];

export const CERTIFICATE_CONTEXTS: string[] = [
  'EWIS_INITIAL',
  'EWIS_RENEWED',
  'FTS_INITIAL',
  'FTS_RENEWED',
  'HF_INITIAL',
  'HF_RENEWED'
];
