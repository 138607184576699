<header class="staffnow-header" [ngClass]="brandName">
    <div class="aside-left">
        <div class="notifications-badge">
            <i class="material-icons hamburger" (click)="toggleAsideMenu()" (keydown.enter)="toggleAsideMenu()">
                menu
            </i>
        </div>
        <div
            class="logo-container"
            [class.color]="isBackofficeUser"
            (click)="redirectToMainView()"
            (keydown.enter)="redirectToMainView()"
        ></div>
    </div>
    @if(menuItems.length > 0) {
    <staffnow-navbar [menuItems]="menuItems"></staffnow-navbar>
    }
    <div class="aside-right">
        <ng-content select="[region-selector]"></ng-content>
        @if(canDisplayCopyInvitationButton) {
        <div class="notifications-badge" (click)="copyRegistrationLink()" (keydown.enter)="copyRegistrationLink()">
            @if(copyRegistrationLinkClicked){
            <i *ngIf="copyRegistrationLinkClicked" class="material-icons">done</i>
            } @else {
            <i class="material-icons">file_copy</i>
            }
        </div>
        } @if(hasMailbox){
        <div class="notifications-badge" (click)="gotoMailbox()" (keydown.enter)="gotoMailbox()">
            <i class="material-icons">forum</i>
            @if(messagesCount > 0) {
            <div class="count-badge-wrapper">
                <staffnow-counter-badge [amount]="messagesCount"></staffnow-counter-badge>
            </div>
            }
        </div>
        }
        <div
            class="notifications-badge bell notifications-trigger"
            (click)="toggleNotificationList()"
            (keydown.enter)="toggleNotificationList()"
        >
            <i class="material-icons">notifications</i>
            @if(notificationsCount > 0) {
            <div class="count-badge-wrapper">
                <staffnow-counter-badge [amount]="notificationsCount"></staffnow-counter-badge>
            </div>
            }
            <ng-content select="[notification-list]"></ng-content>
        </div>
        @if(profilePictureUrl) {
        <div
            (click)="toggleUserMenu()"
            (keydown.enter)="toggleUserMenu()"
            class="user-avatar"
            [ngStyle]="{
                'background-image': 'url(\'' + profilePictureUrl + '\')'
            }"
        ></div>
        } @else {
        <div (click)="toggleUserMenu()" (keydown.enter)="toggleUserMenu()" class="user-avatar">
            <i class="material-icons"> person </i>
        </div>
        }
        <div class="user-info" (click)="toggleUserMenu()" (keydown.enter)="toggleUserMenu()">
            @if(!isTechnician) {
            <span>{{ authorizedUser.role | humanReadableRole | translate }}</span>
            }
            <span>{{ (authorizedUser.firstName || 'Unnamed') + ' ' + (authorizedUser.lastName || 'User') }}</span>
        </div>
    </div>

    @if(isUserMenuOpen){
    <div
        class="logout-and-terms-container"
        closeOnOutside
        [triggerClass]="['user-avatar', 'user-info']"
        (close)="toggleUserMenu()"
    >
        <div class="dropdown">
            @if(profilePictureUrl) {
            <div
                class="user-avatar not-clickable"
                [ngStyle]="{
                    'background-image': 'url(\'' + profilePictureUrl + '\')'
                }"
            ></div>
            } @else {
            <div class="user-avatar">
                <i class="material-icons"> person </i>
            </div>
            }
            <div class="user-info">
                <p>{{ authorizedUser.role | humanReadableRole | translate }}</p>
                <p>
                    {{ (authorizedUser.firstName || 'Unnamed') + ' ' + (authorizedUser.lastName || 'User') }}
                </p>
            </div>
            <borderless-button
                (onClick)="logout()"
                color="{{ isBackofficeUser ? 'dark' : 'white' }}"
                text="LOGOUT"
            ></borderless-button>
            <div class="links">
                <p>
                    <a href="{{ getPrivacyPolicy }}" target="_blank">{{ 'GENERAL.PRIVACY_POLICY' | translate }}</a>
                </p>
                <p>
                    <a href="{{ getTermsAndConditions }}" target="_blank">{{ 'GENERAL.T&C' | translate }}</a>
                </p>
                @if (!isBackofficeUser && !isPlatformIos){
                <p>
                    <a href="javascript:void(0);" (click)="handleCookiesSettingsChange()">
                        {{ 'GENERAL.CHANGE_COOKIE_SETTINGS' | translate }}
                    </a>
                </p>
                } @if (isTechnician){
                <p>
                    <a href="javascript:void(0);" (click)="openRequestDeletionModal()">
                        {{ 'GENERAL.DELETE_ACC' | translate }}
                    </a>
                </p>
                }
            </div>
            @if(version) {
            <div class="version">{{ 'GENERAL.VERSION' | translate }} {{ version }}</div>
            }
        </div>
    </div>
    }
</header>
