import { NgModule } from '@angular/core';
import { CountrySelectorComponent } from '@libs/common-ui/country/country-selector/country-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgrxFormsModule } from 'ngrx-forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  COUNTRY_STATE_FEATURE_KEY,
  CountrySelectorStateModel
} from '@libs/common-ui/country/state/country-selector.state.model';
import { countrySelectorReducer, initialState } from '@libs/common-ui/country/state/country-selector.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CountrySelectorEffects } from '@libs/common-ui/country/state/country-selector.effects';
import { StateSelectorComponent } from '@libs/common-ui/country/state-selector/state-selector.component';

function getInitialState(): Partial<CountrySelectorStateModel> {
  return {
    ...initialState
  };
}

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgrxFormsModule,
    TranslateModule,
    NgSelectModule,
    StoreModule.forFeature(COUNTRY_STATE_FEATURE_KEY, countrySelectorReducer, {
      initialState: getInitialState
    }),
    EffectsModule.forFeature([CountrySelectorEffects]),
    CommonModule
  ],
  declarations: [CountrySelectorComponent, StateSelectorComponent],
  exports: [CountrySelectorComponent, StateSelectorComponent]
})
export class CountryModule {}
