<div class="mro-registration-form">
    <form class="form">
        <h1>{{ 'REGISTRATION.STEPS.AGENCY_SELECTION.TITLE' | translate }}</h1>
        <div class="content">
            <span class="h3 info">
                <span class="material-icons-outlined"> help_outline </span>
                <span class="text">
                    {{'REGISTRATION.STEPS.AGENCY_SELECTION.HELP' | translate}}
                </span>
            </span>
            <staffnow-promotional-list
                [selectedAgencies]="selectedAgencies"
                [availableAgencies]="availableAgencies"
                (onSelectionUpdate)="updateSelection($event)"
            ></staffnow-promotional-list>
        </div>
    </form>
    <div class="mro-registration-actions">
        <general-button
            (onClick)="reset()"
            backgroundStyle="outline"
            [text]="'GENERAL.CANCEL' | translate"
        ></general-button>
        <general-button
            (onClick)="previous()"
            [text]="'GENERAL.PREVIOUS' | translate"
            icon="arrow_back"
        ></general-button>
        <general-button
            (onClick)="next()"
            [text]="'GENERAL.NEXT' | translate"
            icon="arrow_forward"
            iconPosition="right"
        ></general-button>
    </div>
</div>
