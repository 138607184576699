<div class="technician-info">
    <div
        *ngIf="hasProfilePicture"
        [ngStyle]="{ 'background-image': 'url(' + profilePicture + ')' }"
        class="profile-pic"
    ></div>
    <div *ngIf="!hasProfilePicture" class="profile-pic">
        <i class="material-icons"> person </i>
    </div>
    <span class="h6 name">
        {{ resource?._embedded?.profile?.firstName }}
        {{ resource?._embedded?.profile?.lastName }}
    </span>
</div>
