export const AUTHENTICATION_FEATURE_KEY = 'auth';

export interface AuthenticationState {
  validTokenReceived: boolean;
  authInitialized: boolean;
}

export interface AppPartialState {
  [AUTHENTICATION_FEATURE_KEY]: AuthenticationState;
}

export const initialState: AuthenticationState = {
  validTokenReceived: null,
  authInitialized: false,
};
