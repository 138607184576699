import { ActionReducer } from '@ngrx/store';
import { InjectionToken } from '@angular/core';

import {
  NotificationsStateModel,
  initialState
} from './notifications-state.model';
import {
  NotificationsActions,
  NotificationAction
} from './notifications.actions';
import { cloneDeep } from 'lodash-es';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export function getReducers(): ActionReducer<NotificationsStateModel> {
  return notificationsReducer;
}

const notificationsReducer = (
  state: NotificationsStateModel = initialState,
  action: NotificationAction | any
): NotificationsStateModel => {
  switch (action.type) {
    case NotificationsActions.BadgesLoaded:
      return { ...state, badges: action.badgesList };
    case NotificationsActions.UpdateNotificationsCount:
      const badges = cloneDeep(state.badges);

      if (!action.count && action.count != 0) {
        badges[
          badges.findIndex(badge => badge.type === action.notificationsTarget)
        ].count++;
      } else {
        badges[
          badges.findIndex(badge => badge.type === action.notificationsTarget)
        ].count = action.count;
      }

      return { ...state, badges: badges };

    case NotificationsActions.NotificationListLoaded:
      return action.page === 0
        ? {
            ...state,
            notificationList: [...action.notificationList],
            isNotificationListLastPageLoaded: action.isLastPage,
            notificationsPage: Number(action.page) + 1,
            pageSize: action.pageSize
          }
        : {
            ...state,
            notificationList: state.notificationList.concat(
              action.notificationList
            ),
            isNotificationListLastPageLoaded: action.isLastPage,
            notificationsPage: Number(action.page) + 1
          };
    case NotificationsActions.ResetNotificationList:
      return {
        ...state,
        notificationList: [],
        isNotificationListLastPageLoaded: false,
        notificationsPage: 0
      };

    case NotificationsActions.NewCommonNotification:
      const newNotificationList = [action.data].concat(state.notificationList);
      // if the totalElements before the new notification was "n * pageSize" , the new length will be
      // "(n * pageSize) + 1 and if it is not the last page we should delete the last notification
      // in order to prevent double the last notification
      const shouldDeleteLastNotification =
        newNotificationList.length % state.pageSize === 1 &&
        !state.isNotificationListLastPageLoaded;
      shouldDeleteLastNotification ? newNotificationList.splice(-1, 1) : null;

      return {
        ...state,
        notificationList: newNotificationList
      };
    case GlobalReset.type:
      return initialState;
  }

  return state;
};

export const reducersInjectionToken = new InjectionToken<
  ActionReducer<NotificationsStateModel>
>('[Notifications] Registered Reducers');
