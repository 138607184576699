import { Injectable } from '@angular/core';
import { UsersFilterValueModel } from '../../../../../apps/back-office/src/app/modules/users/state/users-state.model';
import { isNil, omitBy } from 'lodash-es';
import { Store } from '@ngrx/store';
import { getAirplanesSortedByName } from '../bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { Aircraft } from '../models/aircraft.model';

@Injectable()
export class UsersService {
  private airPlanes: Array<Aircraft> = [];

  constructor(private store: Store) {
    this.store
      .pipe(getAirplanesSortedByName, take(1))
      .subscribe((airPlanes: Aircraft[]) => (this.airPlanes = airPlanes));
  }

  public extractFiltersFromParams(params: { [key: string]: string | number }): UsersFilterValueModel {
    return {
      term: (params.term || '') as string,
      regDate: params.regDate as string,
      createdOn: params.createdOn as string,
      ameType: params.ameType as number,
      ameLicense: params.ameLicense as number,
      role: (params.role as string)?.toUpperCase(),
      aircraft: this.convertAircraftStringToArray(params.aircraft as string),
      name: params.name as string,
      group: params.group as string,
      position: params.position as string,
      mroFacility: params.mroFacility as string,
      accountStatus: params.accountStatus as string,
      profileStatus: params.profileStatus as string,
      agencyFacility: params.agencyFacility as string
    };
  }

  public createFilterValueObject(
    filtersState: UsersFilterValueModel,
    sortingQuery: string,
    page: number
  ): { [param: string]: string } {
    if (!filtersState) {
      return null;
    }
    return omitBy(
      {
        page: page, // Required parameter
        ameType: filtersState.ameType,
        ameLicense: filtersState.ameLicense,
        role: UsersService.preventEmptyString(filtersState.role?.toLowerCase()),
        regDate: filtersState.regDate,
        createdOn: filtersState.createdOn,
        aircraft: UsersService.parseListOfEntitiesToCommaSeparatedString(filtersState.aircraft),
        sort: UsersService.preventEmptyString(sortingQuery),
        term: UsersService.preventEmptyString(filtersState.term),
        name: UsersService.preventEmptyString(filtersState.name),
        group: UsersService.preventEmptyString(filtersState.group),
        position: UsersService.preventEmptyString(filtersState.position),
        mroFacility: UsersService.preventEmptyString(filtersState.mroFacility),
        accountStatus: UsersService.preventEmptyString(filtersState.accountStatus),
        profileStatus: UsersService.preventEmptyString(filtersState.profileStatus),
        agencyFacility: UsersService.preventEmptyString(filtersState.agencyFacility),
        skills: UsersService.parseListOfEntitiesToCommaSeparatedString(filtersState.skills)
      },
      isNil
    );
  }

  private static preventEmptyString(filter: string): string {
    return filter || null;
  }

  private static parseListOfEntitiesToCommaSeparatedString(entityList: Array<{ id: number }>): string {
    if (entityList && entityList.length > 0) {
      return entityList.map(entity => entity.id).join(',');
    }
    return null;
  }

  public convertAircraftStringToArray(aircraft: string): Array<Aircraft> {
    const ids = aircraft?.split(',').map(id => Number(id));
    const airplanes = this.airPlanes?.filter(plane => ids?.includes(plane.id));
    return airplanes || null;
  }
}
