<div
    class="notification-list"
    #notificationsContainer
    (scroll)="onScroll($event)"
>
    <staffnow-notification
        *ngFor="let notification of notificationList"
        [notification]="notification"
        (click)="toggleNotificationList()"
    ></staffnow-notification>
    <staff-loader
        *ngIf="loading"
        [customClass]="'purple-loader'"
    ></staff-loader>
    <div *ngIf="isLastPage" class="no-notifications-message text-muted">
        {{ 'There are no older notifications.' | translate }}
    </div>
</div>
