<i class="material-icons close-icon" (click)="bsModalRef.hide()"> clear </i>
<div class="header">
    <h1 class="heading">{{ title | translate }}</h1>
    <div class="text-muted sub-heading">
        <p class="reminder" *ngFor="let reminder of reminders">
            <strong *ngIf="reminder.label">
                {{ reminder.label | translate }}
            </strong>
            {{ reminder.text | translate }}
        </p>
        <p *ngIf="warningMessage" class="reminder text-danger">
            <strong>{{ 'WARNING: ' | translate }}</strong>
            {{ warningMessage | translate }}
        </p>
    </div>
</div>
<div class="content">
    <div class="wrapper">
        <strong>{{ 'Select the date: ' | translate }}</strong>
        <ng-container *ngIf="isBackoffice; else nonWritableDatePicker">
            <date-picker-input-writable
                [initialValue]="selectedDate"
                (dateUpdated)="handleDateUpdate($event)"
            ></date-picker-input-writable>
        </ng-container>
        <ng-template #nonWritableDatePicker>
            <date-picker-single
                [initialValue]="selectedDate"
                (dateUpdated)="handleDateUpdate($event)"
                [minDate]="minDate"
                [maxDate]="maxDate"
            ></date-picker-single>
        </ng-template>
    </div>
    <general-button
        (onClick)="submit()"
        class="button-custom"
        text="Submit"
    ></general-button>
</div>
