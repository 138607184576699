import { Component, Input } from '@angular/core';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';

@Component({
  selector: 'staffnow-agency-offer-contract-reasons-list',
  templateUrl: './agency-offer-contract-reasons-list.component.html',
  styleUrls: ['./agency-offer-contract-reasons-list.component.scss']
})
export class AgencyOfferContractReasonsListComponent {
  @Input() public contract: AgencyOfferContractOutDto = null;

  get isRejectedByAgency(): boolean  {
    return this.contract.agencyStatus === ContractStatus.rejected;
  }

  get rejectReason(): string {
    return this.contract.rejectReason
  }

  get areDocumentsNeeded(): boolean {
    return this.contract.agencyStatus === ContractStatus.documentsNeeded;
  }

  get documentsNeededExplanation(): string {
    return this.contract.documentsNeededExplanation;
  }
}
