import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';

export const dateTimeFromString = (date: string): DateTime => {
  return DateTime.fromISO(date, { setZone: true });
};

export const isFirstDateBeforeTheSecond = (
  first: string,
  second: string
): boolean => {
  const firstDate: number = dateTimeFromString(first).toMillis();
  const secondDate: number = dateTimeFromString(second).toMillis();
  return firstDate < secondDate;
};

export const getCurrentUTCDateString = (): string => {
  return dateTimeToDateString(DateTime.utc());
};

export const dateToUTCString = (date: Date): string => {
  if (!isNil(date) && !isNaN(date.getTime())) {
    return dateTimeToDateString(
      DateTime.utc(date.getFullYear(), date.getMonth() + 1, date.getDate())
    );
  }
  return '';
};

function dateTimeToDateString(date: DateTime): string {
  return date.toISODate();
}

export const subtractDaysFromDate = (date: string, days: number): string => {
  return dateTimeToDateString(dateTimeFromString(date).minus({ days }));
};

export const isDateInThePastOrToday = (date: string): boolean => {
  const other: number = dateTimeFromString(date).toMillis();
  const today: number = DateTime.now().toMillis();
  return other <= today;
};

export const monthlyDateRangeConverter = (
  date: string
): { fromDate: string; toDate: string } => {
  const dateTime: DateTime = dateTimeFromString(date);
  return {
    fromDate: dateTimeToDateString(dateTime.startOf('month')),
    toDate: dateTimeToDateString(dateTime.plus({ month: 1 }).endOf('month'))
  };
};
