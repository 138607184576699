import {Component, Input, OnChanges} from "@angular/core";
import {ChangeUserEmail, FailedToChangeUserEmail} from "@libs/user-profile/state/user-profile.actions";
import {getEmbeddedResource, getUrl} from "@libs/shared/bms-common/rest/resource.utils";
import {UserProfileLinkRel} from "@libs/shared/linkrels/user-profile.linkrel";
import {UserProfile} from "@libs/shared/models/technician-profile.model";
import {UserProfileType} from "@libs/shared/models/user-profile.type";
import {ActionsSubject, Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ofType} from "@ngrx/effects";

@UntilDestroy()
@Component({
  selector: 'change-user-email',
  templateUrl: 'change-user-email.component.html',
  styleUrls: ['../backoffice-common-components.scss']
})
export class ChangeUserEmailComponent implements OnChanges {
  @Input() public userProfile: UserProfileType = null;
  public userEmail: string = null;
  public isLoading: boolean = false;

  constructor(private store: Store<any>,
              private actionsSubject: ActionsSubject
  ) {
    this.setUpLoadingSpinnerSubscription();
  }

  private setUpLoadingSpinnerSubscription() {
    this.actionsSubject
      .pipe(
        ofType(FailedToChangeUserEmail),
        untilDestroyed(this)
      ).subscribe(() => (this.isLoading = false));
  }

  ngOnChanges(): void {
    if (this.userProfile._embedded) {
      this.isLoading = false;
      this.userEmail = getEmbeddedResource<UserProfile>(
        this.userProfile,
        UserProfileLinkRel.Profile
      ).email;
    }
  }

  handleEmailChange() {
    this.isLoading = true;
    this.store.dispatch(
      ChangeUserEmail({
        email: this.userEmail,
        url: getUrl(this.userProfile, UserProfileLinkRel.ChangeLoginEmail)
      })
    );
  }
}