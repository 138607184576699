import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from '../shared/shared.module';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { RouterLink } from '@angular/router';
import { VerifyEmailService } from './service/verify-email.service';
import { ResendVerificationEmailComponent } from './components/resend-verification-email/resend-verification-email.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    CommonUiModule,
    RouterLink
  ],
  declarations: [ResendVerificationEmailComponent, VerifyEmailComponent],
  providers: [VerifyEmailService],
  exports: [ResendVerificationEmailComponent, VerifyEmailComponent]
})
export class VerifyEmailModule {}
