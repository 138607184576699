import {Component, Input} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {getEmbeddedResource} from '@libs/shared/bms-common/rest/resource.utils'
import {UserProfile} from '@libs/shared/models/technician-profile.model'
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel'
import {isNil} from 'lodash-es'
import {BrandName} from '@libs/shared/bms-common/environment/environment.model'
import {Store} from '@ngrx/store'
import {getBrandName} from '@libs/shared/bms-common/environment/environment.selector'
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'staffnow-technicians-list-modal',
  templateUrl: './technicians-list-modal.component.html',
  styleUrls: ['./technicians-list-modal.component.scss']
})
export class TechniciansListModalComponent {
  @Input() public techniciansList: any[] = []
  @Input() public onConfirmCallback: (selectedTechnicians: any[], labourRegulationsAcknowledgment: boolean) => void = null
  @Input() public jobTypeRequiresLabourAcknowledge: boolean = false
  public displayLabourAcknowledgeError: boolean = false
  public labourAcknowledgeClicked: boolean = false
  public isStaffnowBrand: boolean = false
  public selectedTechnicians: any[] = []

  get shouldDisplayLabourAcknowledge(): boolean {
    return this.isStaffnowBrand && this.jobTypeRequiresLabourAcknowledge
  }

  constructor(
    public bsModalRef: BsModalRef,
    private readonly store: Store<any>) {
    this.store.pipe(getBrandName, untilDestroyed(this)).subscribe(brandName => {
      this.isStaffnowBrand = brandName === BrandName.StaffNow
    })
  }

  public toggleSelected(technician): void {
    const profile: UserProfile = getEmbeddedResource(
      technician,
      UserProfileLinkRel.Profile
    )

    if (this.isTechnicianSelected(technician)) {
      const technicianUuidIndex = this.selectedTechnicians.indexOf(
        profile.userUuid
      )
      this.selectedTechnicians.splice(technicianUuidIndex, 1)
    } else {
      this.selectedTechnicians.push(profile.userUuid)
    }
  }

  public confirm(): void {
    if (!this.shouldDisplayLabourAcknowledge || this.labourAcknowledgeClicked) {
      if (!isNil(this.onConfirmCallback)) {
        this.onConfirmCallback(this.selectedTechnicians, this.labourAcknowledgeClicked)
      }
      this.close()
    } else {
      this.displayLabourAcknowledgeError = true
    }
  }

  public close(): void {
    this.bsModalRef.hide()
  }

  public isTechnicianSelected(technician): boolean {
    const userProfile: UserProfile = getEmbeddedResource(
      technician,
      UserProfileLinkRel.Profile
    )
    return this.selectedTechnicians.includes(userProfile.userUuid)
  }

  get canSubmit(): boolean {
    return this.selectedTechnicians.length > 0 && (!this.shouldDisplayLabourAcknowledge || this.labourAcknowledgeClicked)
  }

}
