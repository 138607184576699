<staffnow-contract-card-with-head
    [contract]="contract"
    (openPublicProfile)="onOpenPublicProfile($event)"
>
    <div class="contract-card-body">
        <contract-status
            (openPublicProfile)="onOpenPublicProfile($event)"
            [contract]="contract"
        >
        </contract-status>
        <staffnow-reasons-list
            *ngIf="hasReasons"
            [contract]="contract"
            [isAgency]="true"
        ></staffnow-reasons-list>
        <staffnow-facility-contract-date
            [contract]="contract"
            [offerStartDate]="offerStartDate"
            *ngIf="needsToPickADate()"
        ></staffnow-facility-contract-date>
        <div
            class="agency-decline-limit"
            *ngIf="hasDeclineContractLink && !isDeclineDateLimitReached()"
        >
            <div class="date">
                <strong>{{ 'GENERAL.DECLINE_LIMIT' | translate }}</strong>
                <span>
                    {{ contract.agencyDeclineDateLimit | localDateWithTimeFormat }}
                </span>
            </div>
            <small class="text-muted">
                ({{ 'SYSTEM.INFO.AGENCY_DECLINE_DATE' | translate }})
            </small>
        </div>
    </div>
    <div class="contract-card-actions">
        <general-button
            (onClick)="notifyContractEnd()"
            *ngIf="hasNotifyContractEndLink"
            size="small"
            title="{{'SYSTEM.INFO.NOTIFY_CONTRACT_END' | translate}}"
            [text]="'GENERAL.NOTIFY_END' | translate | uppercase"
        ></general-button>
        <general-button
            (onClick)="declineContract()"
            [isDisabled]="isDeclineDateLimitReached()"
            *ngIf="hasDeclineContractLink"
            backgroundStyle="outline"
            size="small"
            title="{{declineOfferTooltip}}"
            [text]="'GENERAL.DECLINE' | translate | uppercase"
            color="danger"
        ></general-button>
        <general-button
            (onClick)="initiateMessageWithTechnician()"
            [isDisabled]="
                !canCreateConversationWithTechnician &&
                !canGetPreviousConversationWithTechnician
            "
            backgroundStyle="outline"
            class="chat-button"
            size="small"
            [title]="'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate"
            [text]="'GENERAL.CONTACT_TECHNICIAN' | translate"
            color="secondary"
        ></general-button>
        <general-button
            (onClick)="initiateMessageWithMro()"
            [isDisabled]="
                !canCreateConversationWithMro &&
                !canGetPreviousConversationWithMro
            "
            backgroundStyle="outline"
            class="chat-button"
            size="small"
            [title]="'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate"
            [text]="'GENERAL.CONTACT_AVIATION_COMPANY' | translate"
            color="secondary"
        ></general-button>
    </div>
</staffnow-contract-card-with-head>
