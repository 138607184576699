<h1 class="h1">Performance feedback</h1>
<div class="section">
    <general-button
        (onClick)="openSendPerformanceFeedbackRequest()"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
        text="New request"
    ></general-button>
    <general-button
        (onClick)="goToPreviousRequestsPage()"
        [isDisabled]="isLoading"
        text="Previous requests"
    ></general-button>
</div>
