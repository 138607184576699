<div class="task-container">
    <div class="body">
        <div class="properties">
            <div class="property mr-3" *ngIf="task.hoursLeft || isDocumentsUploadedByTechnician()">
                <div class="time-left mr-1" *ngIf="task.hoursLeft">
                    <span class="material-icons pill-icon"> access_time_filled </span>
                    {{ task.hoursLeft }}h left
                </div>
                <div class="documents-needed" *ngIf="isDocumentsUploadedByTechnician()">
                    <span class="material-icons pill-icon"> insert_drive_file </span>
                    Docs Added
                </div>
            </div>
            <div class="mr-3" style="padding-top: 2px" *ngIf="isStartDateProposal()">
                <span class="material-icons calendar-icon primary">date_range</span>
            </div>

            <div class="property mr-3">
                <span class="title">{{ task.title }}</span>
            </div>
            <div class="icon-properties">
                <div class="property primary mr-3" *ngIf="task.technicianName">
                    <i class="material-icons mr-1">person</i>
                    <span class="primary font-weight-bold">
                        {{ task.technicianName }}
                    </span>
                </div>

                <div class="property primary mr-3" *ngIf="task.agencyName">
                    <i class="material-icons mr-1">store</i>
                    <span class="primary font-weight-bold">
                        {{ task.agencyName }}
                    </span>
                </div>

                <div class="property secondary mr-3" *ngIf="task.offerRefNumber">
                    <i class="material-icons mr-1">airplanemode_active</i>
                    <span>{{ offerDescription }}</span>
                </div>

                <div class="property secondary" *ngIf="task.offerLevel">
                    <i class="material-icons mr-1" style="font-size: 20px">build</i>
                    <span>{{ task.offerLevel }}</span>
                </div>
            </div>
        </div>
        <div class="actions" style="margin-left: auto">
            <div class="hide-wrapper" *ngIf="task.links.length > 0">
                <general-button
                    (onClick)="ignoreTask()"
                    backgroundStyle="outline"
                    class="action-button hide-button text-and-icon-button"
                    color="secondary"
                    size="small"
                    icon="visibility_off"
                    iconOutlined="true"
                    text="{{ 'GENERAL.HIDE' | translate }}"
                ></general-button>
                <general-button
                    (onClick)="ignoreTask()"
                    backgroundStyle="outline"
                    class="action-button hide-button icon-only-button"
                    color="secondary"
                    size="small"
                    icon="visibility_off"
                    iconOutlined="true"
                ></general-button>
            </div>
            <div class="visit-wrapper">
                <general-button
                    backgroundStyle="outline"
                    class="action-button"
                    size="small"
                    (onClick)="visitActionLink()"
                    text="{{ 'GENERAL.VIEW' | translate }}"
                ></general-button>
            </div>
        </div>
    </div>
</div>
