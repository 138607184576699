<div class="toast-message-list">
    <alert
        *ngFor="let message of toastMessages"
        [dismissOnTimeout]="message.dismissTimeout || AUTO_DISMISS_TIMEOUT"
        [dismissible]="true"
        [type]="message.type"
        (onClose)="oncloseHandler(message)"
    >
        {{ message.text | translate }}
    </alert>
</div>
