<div class="container">
    <div class="update">
        <div>
            <h2>Outdated app version</h2>
            <div *ngIf="isPlatformWeb; else app">
                <p>
                    You're using an unsupported version of the web. Please, reload the page in your web browser to
                    continue.
                </p>
                <a class="btn btn-outline-primary" (click)="reloadWebsite()"> Reload page </a>
            </div>
            <ng-template #app>
                <p>
                    Your version of the app is obsolete. Please, update the app from {{ deviceStore }} to continue
                    working with {{ brandName }}. If you don't have an updated version available, please, use the
                    <a [href]="appUrl">web version</a> in the mean time.
                </p>
                <a class="btn btn-outline-primary" [href]="appUrl"> Go to website </a>
            </ng-template>
        </div>
        <p>Your current version is: {{ appVersionHeader.value }}</p>
    </div>
</div>
