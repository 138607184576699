import { createAction, props } from '@ngrx/store';

export const ResetForm = createAction('[ Package Offer ] Create');

export const SubmitPackageOffer = createAction(
  '[ Package Offer ] Submit Package Offer',
  props<{ payload: any; editUrl: string }>()
);

export const SucceededToSubmitPackageOffer = createAction(
  '[ Package Offer ] Succeeded To Submit Package Offer',
  props<{ refNumber: string }>()
);

export const FailedToSubmitPackageOffer = createAction(
  '[ Package Offer ] Failed To Submit Package Offer'
);
