import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {mroDisplayOptionsForm, selectRegistrationState} from '../../../../state/registration.selectors';
import {Observable, Subscription} from 'rxjs';
import {
    MroRegistrationFinished,
    RegisterMro,
    RegisterMroFailed,
    RegisterMroSuccessful,
    UpdateAdvertiseOpenPositions,
    UpdateCompanyImage,
    UpdateCompanyLogo,
    UpdateCompanyPromoMaterials,
    UpdateTermsAgreement,
    UploadMroLogo
} from '../../../../state/registration.actions';
import {MroDisplayOptions, MroRegistrationData} from '../../../../state/registration-state.model';
import {take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {ToastMessageService} from '@libs/toast-messages/toast-message.service';
import {getBrandName, getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

declare var grecaptcha: any;

@UntilDestroy()
@Component({
  selector: 'staffnow-display-options',
  templateUrl: './display-options.component.html',
  styleUrls: ['./display-options.component.scss', '../../../common.scss']
})
export class DisplayOptionsComponent implements OnInit, OnDestroy {
  @Output() onReset = new EventEmitter();
  @Output() onPrevious = new EventEmitter();
  @Output() onComplete = new EventEmitter();

  @ViewChild('file') fileInput;
  accepts: boolean = null;
  formState: MroDisplayOptions = null;
  imagePath;
  imgURL: any;
  message: string;
  isLoading = false;
  image: File = null;

  public brandName$: Observable<string>;
  public googleRecaptchaSiteKey = '';
  public privacyPolicyLink: string;
  public termsAndConditionsLink: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toastService: ToastMessageService,
    private translateService: TranslateService
  ) {
    this.store
      .pipe(getStaticEnvironment)
      .subscribe(envData => {
        this.googleRecaptchaSiteKey = envData.googleRecaptchaSiteKey;
      });
    this.brandName$ = this.store.pipe(getBrandName);

    this.subscription.add(
      this.actions.pipe(ofType(RegisterMroSuccessful)).subscribe(action => {
        this.store.dispatch(
          this.image
            ? UploadMroLogo({
                facilityUuid: action.companyUuid,
                file: this.image
              })
            : MroRegistrationFinished()
        );
      })
    );
    this.subscription.add(
      this.actions.pipe(ofType(MroRegistrationFinished)).subscribe(() => {
        this.isLoading = false;
        this.onComplete.emit();
      })
    );
    this.subscription.add(
      this.actions.pipe(ofType(RegisterMroFailed)).subscribe(() => {
        this.toastService.fail(
          this.translateService.instant('REGISTRATION.MRO.SUBMIT.ERROR')
        );
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(mroDisplayOptionsForm).subscribe(state => {
        this.formState = state;
        if (this.formState.image) {
          this.preview(this.formState.image);
        }
      })
    );
    this.subscription.add(
      this.store.pipe(getStaticEnvironment).subscribe(environment => {
        this.privacyPolicyLink = environment.brandConfig.privacyPolicy;
        this.termsAndConditionsLink = environment.brandConfig.termsAndConditions;
      })
    );
  }

  open() {
    this.fileInput.nativeElement.click();
  }

  onPictureChange(files: FileList) {
    this.image = files.item(0);
    this.store.dispatch(UpdateCompanyImage({ images: this.image }));
    this.preview(this.image);
  }

  preview(file: File) {
    if (!file) return;

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = file;
    reader.readAsDataURL(file);
    reader.onload = _event => {
      this.imgURL = reader.result;
    };
  }

  toggleAllowAdvertisement(value: boolean) {
    this.store.dispatch(UpdateAdvertiseOpenPositions({ value }));
  }

  toggleTermsAndConditions() {
    const value =
      this.formState.termsAgreement === null
        ? true
        : !this.formState.termsAgreement;
    this.store.dispatch(UpdateTermsAgreement({ value }));
  }

  toggleCompanyLogo() {
    const value =
      this.formState.allowToDisplayCompanyLogo === null
        ? true
        : !this.formState.allowToDisplayCompanyLogo;
    this.store.dispatch(UpdateCompanyLogo({ value }));
  }

  toggleCompanyPromoMaterials() {
    const value =
      this.formState.allowToAdvertiseOpenPositions === null
        ? true
        : !this.formState.allowToAdvertiseOpenPositions;
    this.store.dispatch(UpdateCompanyPromoMaterials({ value }));
  }

  isFormValid() {
    const { termsAgreement, allowToAdvertiseOpenPositions } = this.formState;
    return termsAgreement === true && allowToAdvertiseOpenPositions !== null;
  }

  reset() {
    this.onReset.emit();
  }

  complete() {
    if (!this.isFormValid()) {
      return;
    }
    this.isLoading = true;
    grecaptcha.ready((): void => {
      grecaptcha
        .execute(this.googleRecaptchaSiteKey, { action: 'submit' })
        .then((token: string): void => {
          this.registerMro(token);
        });
    });
  }

  previous() {
    this.onPrevious.emit();
  }

  private registerMro(grecaptchaToken): void {
    this.store
      .select(selectRegistrationState)
      .pipe(take(1))
      .subscribe(state => {
        const registrationRequest: MroRegistrationData = {
          requesterLastName: state.mroCompanyDetails.value.requesterLastName,
          locationId: state.mroCompanyDetails.value.locationId,
          selectedAgencies: state.mroSelectedAgencies.selectedAgencies,
          allowToAdvertiseOpenPositions:
            state.mroDisplayOptions.allowToAdvertiseOpenPositions ?? false,
          allowToDisplayCompanyLogo:
            state.mroDisplayOptions.allowToDisplayCompanyLogo ?? false,
          allowToDisplayPromotionalMaterial:
            state.mroDisplayOptions.allowToDisplayPromotionalMaterial ?? false,
          requiresQualityAcceptance:
            state.mroCompanyDetails.value.requiresQualityAcceptance ?? true,
          requiresProductionAcceptance:
            state.mroCompanyDetails.value.requiresProductionAcceptance ?? true,
          requiresHumanResourcesAcceptance:
            state.mroCompanyDetails.value.requiresHumanResourcesAcceptance ??
            false,
          contactPhone: state.mroCompanyDetails.value.contactPhone,
          termsAgreement: state.mroDisplayOptions.termsAgreement,
          companyName: state.mroCompanyDetails.value.companyName,
          requesterName: state.mroCompanyDetails.value.requesterName,
          requesterEmail: state.mroCompanyDetails.value.requesterEmail,
          requesterPosition: state.mroCompanyDetails.value.requesterPosition,
          userRole: state.mroCompanyDetails.value.userRole
        };

        this.store.dispatch(
          RegisterMro({ grecaptchaToken, payload: registrationRequest })
        );
      });
  }
}
