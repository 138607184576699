import { Component, Input } from '@angular/core';
import {
  ExtendedPendingReason,
  RejectReason
} from '@libs/shared/models/reasons.model';

@Component({
  selector: 'staffnow-reasons-list-item',
  templateUrl: './reason-list-item.component.html',
  styleUrls: ['./reason-list-item.component.scss']
})
export class ReasonListItemComponent {
  @Input() public reason: RejectReason | ExtendedPendingReason = null;
}
