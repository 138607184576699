import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isEmpty, isNil } from 'lodash-es';
import { RejectReason } from '@libs/shared/models/reasons.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RejectReasonType } from '@libs/shared/models/reasons-types.enum';

@Component({
  selector: 'staffnow-production-rejects',
  templateUrl: './production-reject.component.html',
  styleUrls: ['./production-reject.component.scss']
})
export class ProductionRejectComponent implements OnInit {
  @Input() public onRejectCallback: Function;
  @Input() public reasonsArray: Array<RejectReason> = [];

  public explanation: string = null;
  public form: UntypedFormGroup;
  public charactersCount: number = 0;
  public textAreaMaxLength: number = 500;
  private readonly explanationMaxLength: number = 250;
  public formValid: boolean = false;
  private selectedReasonsValue: Array<RejectReason> = [];

  constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
        this.reasonsFormInit();
  }

  get hasReasonDescription(): boolean {
    return this.selectedReasonsValue.every(
      reason => reason.explanation !== '' && !isNil(reason.explanation)
    );
  }

  get reasonsGetter() {
    return <UntypedFormArray>this.form.get('reasons');
  }

  get isDisabled(): boolean {
    return this.reasonsContainQualityReasons() ? !this.isFormValid() : isEmpty(this.getExplanation());
  }

  private getExplanation(): string {
    return this.explanation?.trim();
  }

  public charactersCounter(event: any): void {
    this.charactersCount = event.target.value.length;
  }

  public reasonsContainQualityReasons(){
    return this.reasonsArray.some(reason => reason.type === RejectReasonType.QUALITY_REASON);
  }

  public validateDescriptionLength(event: any) {
    if (event.target.value.length >= this.explanationMaxLength) {
      event.preventDefault();
    }
  }

  public isFormValid(): boolean {
    if (this.selectedReasonsValue.length !== 0 && this.hasReasonDescription) {
      this.formValid = true;
      return true;
    }

    this.formValid = false;
    return false;
  }

  public getSelectedReasonsValue() {
    this.selectedReasonsValue = [];

    this.reasonsGetter.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedReasonsValue.push(this.reasonsArray[i]);
      }
    });
  }

  public setSelectedReasonDescription(event: any, id: number) {
    const reason = this.selectedReasonsValue.find(rsn => rsn.id === id);
    if (reason) {
      reason.explanation = event.target.value.trim();
    }
  }

  public submit(): void {
    if (!this.isDisabled) {
      this.reasonsArray[0].explanation = isEmpty(this.reasonsArray[0].explanation) ? this.getExplanation() : this.reasonsArray[0].explanation;
      this.onRejectCallback(this.reasonsArray.filter(reason => !isEmpty(reason.explanation)));
      this.bsModalRef.hide();
    }
  }

  private reasonsFormInit(): void {
    this.form = this.fb.group({
      reasons: this.addReasonsControls()
    });
  }

  private addReasonsControls() {
    const arr = this.reasonsArray.map(() => {
      this.fb.control(false);
    });

    return this.fb.array(arr);
  }
}
