import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { Observable, of } from 'rxjs';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { Store } from '@ngrx/store';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { HttpClient } from '@angular/common/http';
import {
  FailedToSubmitPermanentOffer,
  PermanentOfferSubmitSuccess,
  SubmitPermanentOffer
} from './create-permanent-offer.action';

@Injectable()
export class CreatePermanentOfferEffects {
  public submitPermanentOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitPermanentOffer),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        let url: string;
        let request: Observable<any>;
        if (action.editUrl) {
          url = action.editUrl;
          request = this.httpService.put(url, action.payload);
        } else {
          url = getUrl(apiRoot, ApiRootLinkRel.PermanentOffers);
          request = this.httpService.post(url, action.payload);
        }
        return request.pipe(
          map((response: any) => {
            this.toastMessageService.success(response.message);
            return PermanentOfferSubmitSuccess({
              refNumber: response.textPayload
            });
          }),
          catchError(err => {
            console.error(err);
            this.toastMessageService.fail(err.error.message);
            return of(FailedToSubmitPermanentOffer());
          })
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private toastMessageService: ToastMessageService,
    private httpService: HttpClient
  ) {}
}
