import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadCountry,
  FailedToLoadStates,
  StatesAlreadyCached,
  StatesLoaded
} from '@libs/common-ui/country/state/country-selector.actions';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { CountryLinkRel } from '@libs/shared/linkrels/country.linkrel';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectCountries } from '@libs/common-ui/country/state/country-selector.selectors';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { State } from '@libs/common-ui/country/state/country-selector.state.model';
import { Country } from '@libs/shared/models/country.model';

@Injectable()
export class CountrySelectorEffects {
  loadCountryStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCountry),
      withLatestFrom(this.store.select(selectCountries)),
      switchMap(([action, countries]) => {
        const selectedCountry = countries?.find(country => country.id === action.country.id);
        if (selectedCountry && selectedCountry.states && selectedCountry.states.length > 0) {
          return of(StatesAlreadyCached());
        } else {
          return this.retrieveStatesAndDispatchActions(action.country);
        }
      })
    )
  );

  private retrieveStatesAndDispatchActions(country: Country) {
    const url = getUrl(country, CountryLinkRel.GetCountryStates);
    return this.httpClient.get(url).pipe(
      map((states: State[]) => {
        return StatesLoaded({ states: states, country: country });
      }),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return of(FailedToLoadStates());
      })
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private httpClient: HttpClient,
    private errorMessageService: ErrorMessageService
  ) {}
}
