import { Component, Input } from '@angular/core';
import { ContractStatusUtils } from '@libs/shared/models/contract-status.enum';
import { Contract } from '@libs/shared/models/contract.model';

@Component({
  selector: 'staffnow-collapsible-contracts-container',
  templateUrl: './collapsible-contracts-container.component.html',
  styleUrls: ['./collapsible-contracts-container.component.scss']
})
export class CollapsibleContractsContainerComponent {
  @Input() public contracts: Array<Contract> = [];

  get pendingContracts(): Array<Contract> {
    return this.contracts?.filter(contract =>
      ContractStatusUtils.isPending(contract)
    );
  }

  get rejectedContracts(): Array<Contract> {
    return this.contracts?.filter(contract =>
      ContractStatusUtils.isRejected(contract)
    );
  }

  get fullyAcceptedContracts(): Array<Contract> {
    return this.getFullyAcceptedContracts().filter(
      contract => !contract.isFinished
    );
  }

  get finishedContracts(): Array<Contract> {
    return this.getFullyAcceptedContracts().filter(
      contract => contract.isFinished
    );
  }

  private getFullyAcceptedContracts(): Array<Contract> {
    return this.contracts?.filter(contract =>
      ContractStatusUtils.isAccepted(contract)
    );
  }
}
