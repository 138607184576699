import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';

export interface ToggleWeeklyJoSummarySubscriptionParameters {
  url: string; isSubscribing: boolean
}

@Component({
  selector: 'staffnow-weekly-jo-summary-subscription',
  templateUrl: './weekly-jo-summary-subscription.component.html',
  styleUrls: ['./weekly-jo-summary-subscription.component.scss'],
  providers: []
})
export class WeeklyJoSummarySubscriptionComponent {
  @Input() public userProfile: TechnicianProfileModel;

  @Output()
  public toggleWeeklyJoSummarySubscription: EventEmitter<ToggleWeeklyJoSummarySubscriptionParameters> = new EventEmitter();

  public isLoading = false;

  public toggleTechnicianWeeklyJoSummarySubscription() {
    this.toggleWeeklyJoSummarySubscription.emit({
      url: getUrl(this.userProfile, UserProfileLinkRel.ToggleTechniciansWeeklyJoSummarySubscription),
      isSubscribing: !this.isSubscribedToWeeklyJoSummary
    });
  }

  get canToggleWeeklyJoSummarySubscription(): boolean {
    return hasLink(this.userProfile, UserProfileLinkRel.ToggleTechniciansWeeklyJoSummarySubscription);
  }

  get isSubscribedToWeeklyJoSummary(): boolean {
    return !!this.userProfile.isSubscribedToWeeklyJoSummary;
  }
}
