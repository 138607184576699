<div class="picker">
    <ng-select
        (ngModelChange)="handleChange($event)"
        [ngModel]="value"
        [closeOnSelect]="true"
        [items]="options"
        [multiple]="false"
        [ngModelOptions]="{ standalone: true }"
        [searchable]="true"
        placeholder="Select a time"
    ></ng-select>
</div>
