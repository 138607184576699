import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { AuthenticationService } from './services/authentication.service';
import { AuthenticationGuardService } from './services/authentication-guard.service';
import {
  AUTHENTICATION_FEATURE_KEY,
  AuthenticationState,
  initialState
} from './state/authentication-state.model';
import {
  getReducers,
  reducersInjectionToken
} from './state/authentication.reducer';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { RoleRedirectComponent } from './components/role-redirect.component';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

export function getInitialState(): Partial<AuthenticationState> {
  return {
    ...initialState
  };
}

@NgModule({
  declarations: [RoleRedirectComponent, RegionSelectorComponent],
  imports: [
    HttpClientModule,
    TranslateModule,
    StoreModule.forFeature(AUTHENTICATION_FEATURE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    CommonModule,
    FormsModule
  ],
  exports: [RoleRedirectComponent, RegionSelectorComponent]
})
export class AuthenticationModule {
  public static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        AuthenticationService,
        AuthenticationGuardService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
        },
        {
          provide: reducersInjectionToken,
          useFactory: getReducers
        }
      ]
    };
  }
}
