import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, throttleTime} from 'rxjs/operators';

import {ToastMessageService} from '@libs/toast-messages/toast-message.service';

import {AgencyOfferSubmitSuccess, FailedToSubmitAgencyOffer, SubmitAgencyOffer} from './create-agency-offer.actions';
import {DURATION_1000_MILLISECONDS} from "@libs/shared/constants/duration.constants";
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Injectable()
export class CreateAgencyOfferEffects {
  public submitAgencyOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitAgencyOffer),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action) => {
        let url: string;
        let request: Observable<any>;
        if (action.editUrl) {
          url = action.editUrl;
          request = this.httpService.put(url, action.payload);
        } else {
          url = action.createUrl;
          request = this.httpService.post(url, action.payload);
        }
        return request.pipe(
          map((response: any) => {
            this.toastMessageService.success(response.message);
            return AgencyOfferSubmitSuccess({
              refNumber: response.textPayload
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToSubmitAgencyOffer());
          })
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private toastMessageService: ToastMessageService,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {
  }
}
