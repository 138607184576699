import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {FilterConfigModel} from '../../models/filter-config.model';

@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.component.html'
})
export class DateFilterComponent implements OnChanges {
  @Input() filterConfig: FilterConfigModel = null;
  @Output() public onChange: EventEmitter<string> = new EventEmitter<string>();

  public value: string;

  ngOnChanges() {
    if (this.filterConfig?.initialValue) {
      this.value = this.filterConfig.initialValue;
    }
  }

  public onChangeHandler(newValue: string): void {
    this.value = newValue;
    this.onChange.emit(newValue);
  }

}
