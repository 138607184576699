import { Component, Input, OnDestroy } from '@angular/core';
import { AcceptContract } from '@libs/request-overview-common/state/requests-overview.actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConversationService } from '../../services/conversation.service';
import { QualityAcceptComponent } from '@libs/common-ui/quality-accept/quality-accept.component';
import { Contract } from '@libs/shared/models/contract.model';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils';
import { JobOpeningContractDeclinationService } from '@libs/shared/services/job-opening-contract-declination.service';
import { AbstractContractCardComponent } from './abstract-contract-card.component';
import { StartDatePhase } from '@libs/shared/models/start-date-phase.model';
import { OfferOutDto, OfferType } from '@libs/shared/models/offer.model';

interface ContractWithNotes extends Contract {
  notes?: string;
}

@Component({
  selector: 'staffnow-mro-contract-card',
  templateUrl: './mro-contract-card.component.html',
  styleUrls: ['./mro-contract-card.component.scss']
})
export class MroContractCardComponent extends AbstractContractCardComponent implements OnDestroy {
  @Input() public offerEndDate: string = null;
  @Input() public offerStartDate: string = null;
  @Input() public offer: OfferOutDto = null;
  @Input() public reasonsArray: Array<any> = [];

  private userRole: RoleWrapper = null;
  private subs: Array<Subscription> = [];

  protected readonly OfferType = OfferType;
  constructor(
    store: Store,
    conversationService: ConversationService,
    jobOpeningContractDeclinationService: JobOpeningContractDeclinationService,
    private modalService: BsModalService
  ) {
    super(store, conversationService, jobOpeningContractDeclinationService);
    this.subs.push(
      this.store.pipe(getLoggedInUserRole).subscribe(r => {
        this.userRole = role(r);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  get canAcceptContract(): boolean {
    return this.contract?._links[RequestOverviewLinkRel.AcceptContract] && this.userRole.isMro();
  }

  get canDeclineContract(): boolean {
    return this.contract?._links[RequestOverviewLinkRel.DeclineContract] && this.userRole.isMro();
  }

  get canCreateConversationWithAgency(): boolean {
    return this.conversationService.canCreateConversationWithAgency(this.contract);
  }

  get canGetPreviousConversationWithAgency(): boolean {
    return this.conversationService.canGetPreviousConversationWithAgency(this.contract);
  }

  get hasAlreadyMroAccepted(): boolean {
    return this.getMroStatus() === ContractStatus.accepted;
  }

  get hasAlreadyMroRejected(): boolean {
    return this.getMroStatus() === ContractStatus.rejected;
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offerEndDate);
  }

  private getMroStatus(): ContractStatus {
    return this.userRole.isMroAnyQuality()
      ? this.contract?.mroQualityStatus
      : this.userRole.isMroProduction()
      ? this.contract?.mroStatus
      : this.contract?.mroHumanResourcesStatus;
  }

  public acceptContractMro(): void {
    if (this.userRole.isMroAnyQuality()) {
      this.onAcceptMroQuality(this.contract);
    } else {
      this.onAcceptContract(this.contract);
    }
  }

  //TODO(SN-981): NS - Check for duplication with contract list expand backoffice component
  public onAcceptMroQuality(contract: Contract): void {
    this.modalService.show(QualityAcceptComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (notes: string) =>
          this.onAcceptContract({
            ...contract,
            notes: notes ? notes : null
          })
      }
    });
  }

  private onAcceptContract(contract: ContractWithNotes): void {
    this.store.dispatch(AcceptContract({ contract, agencyUuid: null }));
  }

  public declineContractMro(): void {
    if (this.userRole.isMroAnyQuality()) {
      this.jobOpeningContractDeclinationService.onDeclineMroQuality(this.contract, this.reasonsArray);
    } else {
      this.jobOpeningContractDeclinationService.onDeclineMroProduction(this.contract, this.reasonsArray);
    }
  }

  public initiateMessageWithAgency(): void {
    this.conversationService.initiateMessageWithAgency(this.contract);
  }

  get canMarkDocumentsNeeded(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.MarkAsDocumentsNeeded);
  }

  public markContractAsDocumentsNeeded(): void {
    this.jobOpeningContractDeclinationService.onDocumentsNeeded(this.contract);
  }

  public needsToPickADate(): boolean {
    return this.contract.startDatePhase !== StartDatePhase.NOT_INITIATED;
  }
}
