import { Component, Input } from '@angular/core';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';
import { ContractStatusUtils } from '@libs/shared/models/contract-status.enum';
import { Contract } from '@libs/shared/models/contract.model';

@Component({
  selector: 'staffnow-collapsible-agency-offer-contracts-container',
  templateUrl: './collapsible-agency-offer-contracts-container.component.html',
  styleUrls: ['./collapsible-agency-offer-contracts-container.component.scss']
})
export class CollapsibleAgencyOfferContractsContainerComponent {
  @Input() public contracts: Array<AgencyOfferContractOutDto> = [];

  get pendingContracts(): Array<AgencyOfferContractOutDto> {
    return this.contracts?.filter(
      contract => contract.isPending
    );
  }

  get rejectedContracts(): Array<AgencyOfferContractOutDto> {
    return this.contracts?.filter(
      contract => contract.isRejected
    );
  }

  get fullyAcceptedContracts(): Array<AgencyOfferContractOutDto> {
    return this.contracts.filter(
      contract => contract.isFullyAccepted && !contract.isFinished
    );
  }

  get finishedContracts(): Array<AgencyOfferContractOutDto> {
    return this.contracts.filter(
      contract => contract.isFullyAccepted && contract.isFinished
    );
  }
}
