import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {StaffLoaderComponent} from "@libs/common-ui/staff-loader/staff-loader.component";
import {GeneralButtonComponent} from "./components/general-button/general-button.component";
import {BorderlessButtonComponent} from './components/borderless-button/borderless-button.component';
import {GoBackButtonComponent} from "./components/go-back-button/go-back-button.component";
import {FavoriteButtonComponent} from "./components/favorite-button/favorite-button.component";
import { DangerButtonComponent } from '@libs/common-ui/buttons/components/danger-button/danger-button.component'
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    StaffLoaderComponent,
    TooltipModule,
  ],
  declarations: [
    GeneralButtonComponent,
    BorderlessButtonComponent,
    GoBackButtonComponent,
    FavoriteButtonComponent,
    DangerButtonComponent
  ],
  exports: [
    GeneralButtonComponent,
    BorderlessButtonComponent,
    GoBackButtonComponent,
    FavoriteButtonComponent,
    DangerButtonComponent
  ]
})
export class ButtonsModule {
}
