import { HttpParams } from '@angular/common/http';

export function buildQueryParams(actingAs?, uuid?) {
  let result = new HttpParams();
  if (actingAs) {
    result = result.append('actingAs', actingAs);
  }
  if (uuid) {
    result = result.append('uuid', uuid);
  }
  return result;
}
