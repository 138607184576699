import { Injectable } from '@angular/core'
import { isNil } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'
import { StorageService } from '@libs/shared/services/storage.service'

export interface Header {
  name: string
  value: string
}

@Injectable({
  providedIn: 'root'
})
export class HeaderProviderService {
  private readonly appVersionSubject = new BehaviorSubject<Header>(null)
  private readonly appClassSubject = new BehaviorSubject<Header>(null)
  public appVersionObservable = this.appVersionSubject.asObservable()
  public appClassObservable = this.appClassSubject.asObservable()

  constructor (private readonly storageService: StorageService) {
  }

  async getBearerAccessToken (): Promise<Header | null> {
    const accessToken = await this.storageService.getItem('access_token')
    return isNil(accessToken)
      ? null
      : { name: 'authorization', value: `Bearer ${accessToken}` }
  }

  get appVersion (): undefined {
    return undefined
  }

  set appVersion (value: string) {
    this.appVersionSubject.next({ name: 'x-client-version', value })
  }

  get appClass (): undefined {
    return undefined
  }

  set appClass (value: string) {
    this.appClassSubject.next({ name: 'x-client-class', value })
  }

  get apiMediaTypeJsonV2 (): Header {
    return this.buildMediaTypeHeader('application/com.staffnow.api.v2+json')
  }

  private buildMediaTypeHeader (mediaType: string): Header {
    return { name: 'Content-type', value: mediaType }
  }
}
