<staffnow-go-back-button
    [currentPath]="['AGENCY.DETAILS.MY_JO' | translate,
    'AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS' | translate]"
></staffnow-go-back-button>
<staff-loader *ngIf="isLoading" [customClass]="'splash-screen-loader'"></staff-loader>
<staffnow-offer-details
    *ngIf="!isLoading"
    [offer]="offer"
    [title]="'AGENCY.PACKAGE_OFFER.DETAILS.FIXED_PRICE_JO_DESCRIPTION' | translate: {offerDescription}"
>
    <ng-container actions>
        <general-button
            (onClick)="initiateMessageWithAgency()"
            [isDisabled]="
                !canCreateConversationWithAgency &&
                !canGetPreviousConversationWithAgency
            "
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_AGENCY' | translate"
            color="secondary"
        ></general-button>
    </ng-container>
</staffnow-offer-details>
