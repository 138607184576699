import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { SimpleProfile } from '@libs/shared/models/simple-profile.model';

export const STATE_FEATURE_KEY = 'agenciesMain';

export interface TechnicianListingState extends Resource {
  page: number;
  pageSize: number;
  totalElements: number;
}

export interface AgenciesMainState {
  ownTechnicians: SimpleProfile[];
  ownTechniciansListing: TechnicianListingState;
}

export const initialState: AgenciesMainState = {
  ownTechnicians: [],
  ownTechniciansListing: null
};
