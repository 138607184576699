<ng-container *ngIf="_value">
    <div class="wrapper">
        <input
            #benefit
            type="checkbox"
            [(ngModel)]="_value.isSelected"
            (click)="emitChangeInBenefits()"
            id="{{ _value.type }}"
            [disabled]="isDisabled"
        />
        <label for="{{ _value.type }}">{{ benefitLabel | translate }}</label>
        <div class="details" [ngClass]="{ show: benefit.checked }">
            <div class="input-group detail">
                <input
                    *ngIf="requiresAmount"
                    class="form-control"
                    type="number"
                    min="0"
                    step="0.1"
                    style="max-width: 200px"
                    autocomplete="off"
                    placeholder="{{ 'Enter amount (Required)' | translate }}"
                    [(ngModel)]="_value.amount"
                    [ngClass]="{
                        'is-invalid': _value.isSelected && !isValid
                    }"
                    (keydown)="emitChangeInBenefits()"
                    [disabled]="isDisabled"
                />
                <div class="input-group-append" *ngIf="requiresAmount">
                    <span class="input-group-text"> {{ currencySymbol }} </span>
                </div>
                <input
                    class="form-control"
                    type="text"
                    autocomplete="off"
                    (keydown)="emitChangeInBenefits()"
                    placeholder="{{
                        'Write a description (Optional)' | translate
                    }}"
                    [(ngModel)]="_value.description"
                    [disabled]="isDisabled"
                />
            </div>
        </div>
    </div>
</ng-container>
