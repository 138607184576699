<div class="start-date">
    <strong>{{ 'GENERAL.START_DATE' | translate }}</strong>
    <span *ngIf="contractHasStartDate; else emptyDate" [ngClass]="{ 'text-danger': !datePhaseIsAccepted }">
        {{ contract.startDate | absoluteDateFormat }}
    </span>
    <span *ngIf="datePhaseIsProposed" class="small">
        {{ proposedByLabel | translate }}
    </span>
</div>
<div *ngIf="!isPermanent || (isPermanent && contract.endDate)" class="end-date">
    <strong>{{ 'GENERAL.FINISH_DATE' | translate }}</strong>
    <span *ngIf="contractHasStartDate; else emptyDate">
        {{ contract.endDate | absoluteDateFormat }}
    </span>
</div>
<staffnow-contract-date-actions
    [isPermanent]="isPermanent"
    [contract]="contract"
    [offerStartDate]="offerStartDate"
></staffnow-contract-date-actions>
<ng-template #emptyDate>
    <span class="empty-date"> -- </span>
</ng-template>
