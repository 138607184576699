import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { hasLink } from '../bms-common/rest/resource.utils';
import { AbstractAccessGuardService } from './abstract-access-guard.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getFilteredApiRoot } from '../bms-common/api-root/api-root.selectors';

@Injectable()
export class HasAccessGuardService extends AbstractAccessGuardService {
  constructor(router: Router, private store: Store<any>) {
    super(router);
  }

  guardCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      getFilteredApiRoot,
      map(apiRoot => hasLink(apiRoot, route.data.accessLinkRel))
    );
  }
}
