import { Component, Input } from '@angular/core';

import { Contract } from '@libs/shared/models/contract.model';
import { StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'staffnow-facility-contract-date',
  templateUrl: './facility-contract-date.component.html',
  styleUrls: ['./facility-contract-date.component.scss']
})
export class FacilityContractDateComponent {
  @Input() public contract: Contract = null;
  @Input() public offerStartDate: string = null;
  @Input() isPermanent: boolean = false;

  constructor() {}

  get contractHasStartDate(): boolean {
    return !isNil(this.contract.startDate);
  }

  get proposedByLabel(): string {
    if (StartDatePhaseUtils.isProposedByMRO(this.contract.startDatePhase)) {
      return 'GENERAL.PROPOSED_BY_AVIATION_COMPANY';
    } else if (StartDatePhaseUtils.isProposedByTechnician(this.contract.startDatePhase)) {
      return 'GENERAL.PROPOSED_BY_TECHNICIAN';
    }
    return 'GENERAL.PROPOSED_BY_AGENCY';
  }

  get datePhaseIsAccepted(): boolean {
    return StartDatePhaseUtils.isAccepted(this.contract.startDatePhase);
  }

  get datePhaseIsProposed(): boolean {
    return StartDatePhaseUtils.isProposed(this.contract.startDatePhase);
  }
}
