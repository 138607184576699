import { Component, HostBinding, Input } from '@angular/core';

export enum CORNER_CARD_TEXT_TYPE_ENUM {
  UNSEEN = 'UNSEEN',
  EXPIRED = 'EXPIRED',
  CLOSED = 'CLOSED'
}

@Component({
  selector: 'corner-card',
  templateUrl: './corner-card.component.html',
  styleUrls: ['./corner-card.component.scss']
})
export class CornerCardComponent {
  @Input()
  type: CORNER_CARD_TEXT_TYPE_ENUM = null;
  CORNER_CARD_TEXT_TYPE_ENUM = CORNER_CARD_TEXT_TYPE_ENUM;

  @HostBinding('class.unseen')
  get unseen(): boolean {
    return this.type === CORNER_CARD_TEXT_TYPE_ENUM.UNSEEN;
  }

  @HostBinding('class.expired')
  get expired(): boolean {
    return this.type === CORNER_CARD_TEXT_TYPE_ENUM.EXPIRED;
  }

  @HostBinding('class.closed')
  get closed(): boolean {
    return this.type === CORNER_CARD_TEXT_TYPE_ENUM.CLOSED;
  }

  get iconType(): string {
    return this.unseen
      ? 'visibility'
      : this.expired
      ? 'warning'
      : this.closed
      ? 'lock'
      : null;
  }
}
