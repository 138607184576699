<div class="message-list-container">
    <staff-loader
        *ngIf="loading"
        [customClass]="'purple-loader message-scroll-loader'"
    ></staff-loader>
    <div
        class="empty-message-list"
        *ngIf="!loading && messageList.length === 0"
    >
        {{ 'You can now start your conversation.' | translate }}
    </div>
    <message
        *ngFor="let message of messageList.slice().reverse()"
        [ownerPictureUrl]="
            message.isMine
                ? ownPicUrl
                : conversation?._links?.partnerProfilePicture?.href
        "
        [message]="message"
        (onAvatarClicked)="navigateToProfile(conversation?.partnerUserUuid)"
    >
    </message>
</div>
