import { Component, OnInit } from '@angular/core';
import { AbstractOfferDetailsComponent } from '../abstract-offer-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestOverviewState } from '../../../state/request-overview-state.model';
import { ConversationService } from '../../../services/conversation.service';
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils';
import {
  getEmbeddedResource,
  getLink,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { PackageContract } from '@libs/shared/models/package-contract.model';
import {
  AcceptContract,
  AcceptNewPriceForPackageContract,
  AddTechniciansToPackageContract,
  AgencyAcceptsPackageContractInvitation,
  AgencyEditsPackageContract,
  TechniciansForPackageContractLoaded,
  DeclineContract,
  LoadTechniciansForPackageContract,
  LoadPackageOfferWithContracts,
  ProposeNewPriceForPackageContract,
  RemoveTechnicianFromPackageContract
} from '@libs/request-overview-common/state/requests-overview.actions';
import { PackageOfferDto } from '@libs/shared/models/offer.model';
import { isEmpty } from 'lodash-es';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { TechniciansListModalComponent } from '@libs/common-ui/technicians-list-modal/technicians-list-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { PackageContractRejectionComponent } from '@libs/common-ui/package-contract-rejection/package-contract-rejection.component';
import { PackageContractLinkRel } from '@libs/shared/linkrels/package-contract.linkrel';

@Component({
  selector: 'staffnow-agency-package-offer-details',
  templateUrl: './agency-package-offer-details.component.html',
  styleUrls: [
    '../facility-job-offer-details.component.scss',
    './agency-package-offer-details.component.scss'
  ]
})
export class AgencyPackageOfferDetailsComponent
  extends AbstractOfferDetailsComponent
  implements OnInit {
  public contract: PackageContract = null;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private conversationService: ConversationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadPackageOfferWithContracts({
            refNumber: params['offerRefNumber']
          })
        );
      })
    );
    this.onAction(TechniciansForPackageContractLoaded, action =>
      this.openAddTechnicianModal(action.technicians)
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contract = (state.contracts.length > 0 ? state.contracts[0] : null) as any;
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.packageOffer?.periodTo);
  }

  get canCreateConversationWithMro(): boolean {
    return this.conversationService.canCreateConversationWithMro(this.contract);
  }

  get canGetPreviousConversationWithMro(): boolean {
    return this.conversationService.canGetPreviousConversationWithMro(
      this.contract
    );
  }

  get canDeclineContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineContract);
  }

  get canAcceptContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContract);
  }

  get canEditContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.Edit);
  }

  get canProposePrice(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ProposePrice);
  }

  get canAcceptPrice(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptPrice);
  }

  get canAddTechnicians(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AddTechnicians);
  }

  get isAgencyPending(): boolean {
    return this.canAcceptContract && isEmpty(this.contract.agencyOfferedPrices);
  }

  public initiateMessageWithMro(): void {
    this.conversationService.initiateMessageWithMroForPackageContract(
      this.contract
    );
  }

  public acceptOffer(): void {
    if (this.isAgencyPending) {
      this.modalService.openCreatePackageContractForm(
        this.offer as PackageOfferDto,
        payload =>
          this.store.dispatch(
            AgencyAcceptsPackageContractInvitation({
              contract: this.contract,
              payload
            })
          )
      );
    } else {
      this.store.dispatch(AcceptContract({ contract: this.contract }));
    }
  }

  public declineContract(): void {
    if (this.contractHasWorkers()) {
      this.bsModalService.show(PackageContractRejectionComponent, {
        ignoreBackdropClick: true,
        initialState: {
          onRejectCallback: reason => this.declineContractInternal(reason)
        }
      });
    } else {
      this.declineContractInternal();
    }
  }

  private declineContractInternal(reason?: string): void {
    this.store.dispatch(
      DeclineContract({
        contract: this.contract,
        reasons: [reason]
      })
    );
  }

  public addTechnicians(): void {
    this.store.dispatch(
      LoadTechniciansForPackageContract({ contract: this.contract })
    );
  }

  private openAddTechnicianModal(technicians) {
    this.bsModalService.show(TechniciansListModalComponent, {
      class: 'technicians-list-modal modal-center',
      initialState: {
        techniciansList: technicians,
        onConfirmCallback: selectedTechnicians => {
          this.store.dispatch(
            AddTechniciansToPackageContract({
              contract: this.contract,
              technicians: selectedTechnicians
            })
          );
        }
      }
    });
  }

  get canRemoveTechnician(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.RemoveTechnician);
  }

  public contractHasWorkers(): boolean {
    return this.workers.length > 0;
  }

  get workers(): Array<Resource> {
    return getEmbeddedResource(this.contract, RequestOverviewLinkRel.Workers);
  }

  public openTechnicianProfile(uuid: string): void {
    this.customNavigationService.openNewTab(['profile', uuid]);
  }

  public removeTechnician(worker: Resource): void {
    const profile = getEmbeddedResource<any>(
      worker,
      UserProfileLinkRel.Profile
    );
    this.modalService.openConfirmModal(
      this.translateService.instant('AGENCY.PACKAGE_OFFER.DETAILS.REMOVE_TECHNICIAN_CONFIRMATION', {
        firstName: profile?.firstName,
        lastName: profile?.lastName
      }),
      () =>
        this.store.dispatch(
          RemoveTechnicianFromPackageContract({
            technician: profile.userUuid,
            contract: this.contract
          })
        )
    );
  }

  public proposePrice(): void {
    this.modalService.openNumberPickerDialog(
      this.translateService.instant('GENERAL.COUNTEROFFER'),
      this.translateService.instant('GENERAL.INPUT_NEW_PRICE'),
      number =>
        this.store.dispatch(
          ProposeNewPriceForPackageContract({
            newPrice: number,
            contract: this.contract
          })
        )
    );
  }

  public acceptPrice(): void {
    const price = this.contract.mroOfferedPrice;
    this.modalService.openConfirmModal(
      this.translateService.instant('AGENCY.PACKAGE_OFFER.DETAILS.ACCEPT_COMPANY_PRICE_CONFIRMATION', {
        currency: price?.currencySymbol,
        amount: price?.amount
      }),
      () =>
        this.store.dispatch(
          AcceptNewPriceForPackageContract({
            contract: this.contract
          })
        )
    );
  }

  public editContract(): void {
    this.modalService.openCreatePackageContractForm(
      this.offer as PackageOfferDto,
      payload =>
        this.store.dispatch(
          AgencyEditsPackageContract({
            contract: this.contract,
            payload
          })
        ),
      this.contract
    );
  }

  get canCreateAgencyOffer(): boolean {
    return hasLink(this.contract, PackageContractLinkRel.CreateAgencyOffer);
  }

  public openCreateAgencyOfferPage(): void {
    this.router.navigate(['/agency-offer', this.contract.id], {
      state: {
        preselectedFilters: {},
        location: this.offer.location,
        isEdit: false,
        startDate: this.contract.startDate,
        endDate: this.contract.endDate,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.MY_JO'),
          this.translateService.instant('AGENCY.PACKAGE_OFFER.DETAILS.MY_AGENCY_FIXED_PRICE_JO'),
          this.translateService.instant('AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS'),
          this.translateService.instant('GENERAL.CREATE_AGENCY_JO'),
        ],
        mroUuid: this.contract.mroUuid,
        packageContractId: this.contract.id,
        createUrl: getLink(
          this.contract,
          PackageContractLinkRel.CreateAgencyOffer
        ),
        getMroFacilityUrl: getLink(
          this.contract,
          PackageContractLinkRel.GetMroFacility
        )
      }
    });
  }
}
