import {createAction, props} from "@ngrx/store";
import {AutomaticReply} from "./automatic-reply.model";

export const SaveAutomaticReply = createAction(
  '[automatic reply] SaveAutomaticReply',
  props<{ payload: AutomaticReply, url: string }>()
);
export const LoadAutomaticReply = createAction(
  '[automatic reply] LoadAutomaticReply',
  props<{  url: string }>()
);
export const AutomaticReplyLoaded = createAction(
  '[automatic reply] AutomaticReplyLoaded',
  props<{  automaticReply: AutomaticReply }>()
);
export const AutomaticReplyFailedToLoad = createAction(
  '[automatic reply] AutomaticReplyFailedToLoad'
);
export const AutomaticReplySuccessfullySaved = createAction(
  '[automatic reply] AutomaticReplySuccessfullySaved'
);
export const AutomaticReplyFailedToSave = createAction(
  '[automatic reply] AutomaticReplyFailedToSave'
);

