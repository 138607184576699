import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import {
  COUNTRY_STATE_FEATURE_KEY,
  CountrySelectorStateModel
} from '@libs/common-ui/country/state/country-selector.state.model';

const selectFeature: Selector<any, CountrySelectorStateModel> =
  createFeatureSelector<CountrySelectorStateModel>(COUNTRY_STATE_FEATURE_KEY);

export const selectCountries = createSelector(selectFeature, countryState => countryState.countries);
