import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetMyTasksList, MyTasksListLoaded, TaskIgnoredSuccessfully } from '../../../../state/app.actions';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import PendingTask, { PendingTaskTypeEnum } from '../../models/pending-task.model';
import { cloneDeep, isNil, uniq } from 'lodash-es';
import { UserRolesUtils } from '@libs/shared/models/roles.enum';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { StorageService } from '@libs/shared/services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'TasksViewComponent',
  templateUrl: 'tasks-view.component.html',
  styleUrls: ['tasks-view.component.scss']
})
export default class TasksViewComponent implements OnInit, OnDestroy {
  public myTasks: PendingTask[] = [];
  public filteredTasks: PendingTask[] = [];
  public isLoading: boolean = false;
  public getItemValue: (item: any) => any = item => item;
  private subscriptions: Subscription = new Subscription();
  public taskTypes: string[] = [];
  public offers: string[] = [];
  public selectedOffer: string = null;
  public selectedType: string = null;

  private tasksDescriptions = {
    COMPLETE_PROFILE: 'TASKS.VIEW.DESCRIPTION.COMPLETE_PROFILE',
    ANSWER_INVITATION_TO_OFFER: 'TASKS.VIEW.DESCRIPTION.ANSWER_INVITATION_TO_OFFER',
    APPLICATION_IN_DOCUMENTS_NEEDED: 'TASKS.VIEW.DESCRIPTION.APPLICATION_IN_DOCUMENTS_NEEDED',
    TECHNICIAN_FOR_REVIEW: 'TASKS.VIEW.DESCRIPTION.TECHNICIAN_FOR_REVIEW',
    TECHNICIAN_UPLOADED_DOCUMENTS: 'TASKS.VIEW.DESCRIPTION.TECHNICIAN_UPLOADED_DOCUMENTS',
    PROPOSE_START_DATE: 'TASKS.VIEW.DESCRIPTION.PROPOSE_START_DATE',
    FINISH_CONTRACT: 'TASKS.VIEW.DESCRIPTION.FINISH_CONTRACT',
    AGENCY_CAN_REJECT: 'TASKS.VIEW.DESCRIPTION.AGENCY_CAN_REJECT',
    PREPARE_NEXT_STEPS: 'TASKS.VIEW.DESCRIPTION.PREPARE_NEXT_STEPS',
    SELECT_NEW_AGENCY: 'TASKS.VIEW.DESCRIPTION.SELECT_NEW_AGENCY',
    REVIEW_START_DATE: 'TASKS.VIEW.DESCRIPTION.REVIEW_START_DATE',
    APPROVE_OFFER: 'TASKS.VIEW.DESCRIPTION.APPROVE_OFFER',
    REVIEW_CONTRACT_EXTENSION: 'TASKS.VIEW.DESCRIPTION.REVIEW_CONTRACT_EXTENSION',
    REVIEW_AGENCY: 'TASKS.VIEW.DESCRIPTION.REVIEW_AGENCY',
    REVIEW_PROPOSED_PRICE: 'TASKS.VIEW.DESCRIPTION.REVIEW_PROPOSED_PRICE'
  };

  mode: string = 'Rows';

  private userRole: string;

  constructor(
    private store: Store,
    private actions: Actions,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.store.dispatch(GetMyTasksList());
    this.subscribeToTasksLoaded();
    this.subscribeToUserProfile();
    this.subscribeToTaskIgnored();
    this.storageService.getItem('DISPLAY_MODE').then(item => {
      this.mode = item ?? 'Rows';
    });
  }

  getItemDescription(taskDescriptionKey: string): string {
    return this.translateService.instant(this.tasksDescriptions[taskDescriptionKey]);
  }

  changeMode(mode: string) {
    this.storageService.setItem('DISPLAY_MODE', mode);
    this.mode = mode;
  }

  onTypeChangeHandler(newValue: string) {
    this.selectedType = newValue;
    if (isNil(newValue) && isNil(this.selectedOffer)) {
      this.filteredTasks = cloneDeep(this.myTasks);
    } else {
      this.filteredTasks = cloneDeep(this.myTasks);
      if (!isNil(this.selectedType)) {
        this.filteredTasks = this.filteredTasks.filter(task => task.type === newValue);
      }
      if (!isNil(this.selectedOffer)) {
        this.filteredTasks = this.filteredTasks.filter(task => task.offerRefNumber === this.selectedOffer);
      }
    }
  }

  onOfferChangeHandler(newValue: string) {
    this.selectedOffer = newValue;
    if (isNil(newValue) && isNil(this.selectedType)) {
      this.filteredTasks = cloneDeep(this.myTasks);
    } else {
      this.filteredTasks = cloneDeep(this.myTasks);
      if (!isNil(this.selectedOffer)) {
        this.filteredTasks = this.filteredTasks.filter(task => task.offerRefNumber === newValue);
      }
      if (!isNil(this.selectedType)) {
        this.filteredTasks = this.filteredTasks.filter(task => task.type === this.selectedType);
      }
    }
  }

  private subscribeToTasksLoaded() {
    this.subscriptions.add(
      this.actions.pipe(ofType(MyTasksListLoaded)).subscribe(action => {
        this.taskTypes = uniq(action.myTasksList.map((pendingTasks: PendingTask) => pendingTasks.type));
        this.offers = uniq(action.myTasksList.map((pendingTasks: PendingTask) => pendingTasks.offerRefNumber));
        this.myTasks = cloneDeep(action.myTasksList);
        this.filteredTasks = cloneDeep(action.myTasksList);
        this.isLoading = false;
      })
    );
  }

  private subscribeToUserProfile() {
    this.subscriptions.add(
      this.store.pipe(getLoggedInUserRole).subscribe(loggedInUserRole => {
        this.userRole = loggedInUserRole;
      })
    );
  }

  private subscribeToTaskIgnored() {
    this.subscriptions.add(
      this.actions.pipe(ofType(TaskIgnoredSuccessfully)).subscribe(() => this.store.dispatch(GetMyTasksList()))
    );
  }

  public isTechnician(): boolean {
    return UserRolesUtils.isTechnician(this.userRole);
  }

  public getApplicationColumnTasks() {
    return [
      PendingTaskTypeEnum.AGENCY_CAN_REJECT,
      PendingTaskTypeEnum.TECHNICIAN_FOR_REVIEW,
      PendingTaskTypeEnum.TECHNICIAN_UPLOADED_DOCUMENTS,
      PendingTaskTypeEnum.REVIEW_AGENCY,
      PendingTaskTypeEnum.REVIEW_PROPOSED_PRICE
    ];
  }

  public getPendingStartColumnTasks() {
    return [PendingTaskTypeEnum.PROPOSE_START_DATE, PendingTaskTypeEnum.REVIEW_START_DATE];
  }

  public getOtherColumnTasks() {
    return [
      PendingTaskTypeEnum.FINISH_CONTRACT,
      PendingTaskTypeEnum.PREPARE_NEXT_STEPS,
      PendingTaskTypeEnum.APPROVE_OFFER
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
