<div *ngIf="(technicians$ | async) as technicians">
    <ng-select
        bindLabel="name"
        bindValue="uuid"
        [(ngModel)]="selectedTechnicianUuid"
        [items]="technicians"
        [multiple]="multiple"
        [loading]="loading"
        (close)="onSearch('')"
        (clear)="onSearch('')"
        (scrollToEnd)="loadMore(technicians)"
        (search)="onSearch($event.term)"
        [closeOnSelect]="!multiple"
        [searchable]="true"
        (change)="multiple ? onChangeForMultipleSelection() : onChange()"
        [placeholder]="placeholder || 'GENERAL.SEARCH_BY_NAME' | translate"
    >
        @if (multiple) {
            <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                    <label class="ng-option-label custom-control-label" for="item-{{index}}" (click)="toggleItem(item$)">
                        {{ item.name }}
                    </label>
                </div>
            </ng-template>
        }
    </ng-select>
</div>
