import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Resource } from './resource.model';
import {
  getEmbeddedResource,
  getUrl,
  hasEmbeddedResource
} from './resource.utils';

export class ResourceWrapper {
  constructor(private http: HttpClient, private resource: Resource) {}

  get<T>(linkRelOrParams?: string | any, params?: any): Observable<T> {
    const args = Array.from(arguments);
    const {
      linkRel,
      urlParams
    } = ResourceWrapper.extractArgumentsWithoutResourcePayload(args);
    if (this.hasEmbeddedResource(linkRel)) {
      return this.getEmbeddedResource<T>(linkRel);
    }
    return this.getLinkedResource<T>(linkRel, urlParams);
  }

  private hasEmbeddedResource(linkRel) {
    return hasEmbeddedResource(this.resource, linkRel);
  }

  private getEmbeddedResource<T>(linkRel): Observable<T> {
    return of(getEmbeddedResource(this.resource, linkRel));
  }

  private getLinkedResource<T>(linkRel, urlParams): Observable<T> {
    const url = getUrl(this.resource, linkRel);
    return this.http.get<T>(url, {
      params: this.parseUrlParams(urlParams),
      headers: this.parseHeaders({
        Accept: 'application/hal+json'
      })
    });
  }

  private static extractArgumentsWithoutResourcePayload(args) {
    let [first, second] = args;
    return typeof first === 'string'
      ? {
          linkRel: first,
          urlParams: second
        }
      : {
          linkRel: undefined,
          urlParams: first
        };
  }

  private parseUrlParams(urlParams) {
    return Object.keys(urlParams || {}).reduce(
      (httpParamsAcc, key) => httpParamsAcc.append(key, urlParams[key]),
      new HttpParams()
    );
  }

  put(body: any) {
    return this.http.put(getUrl(this.resource), body, {
      headers: this.parseHeaders({
        Accept: 'application/hal+json',
        'Content-Type': 'application/json'
      })
    });
  }

  private parseHeaders(headers) {
    return Object.keys(headers || {}).reduce(
      (httpHeadersAcc, key) => httpHeadersAcc.append(key, headers[key]),
      new HttpHeaders()
    );
  }

  delete() {
    return this.http.delete(getUrl(this.resource), {
      headers: this.parseHeaders({ Accept: 'application/hal+json' })
    });
  }

  patch(body: any) {
    return this.http.patch(getUrl(this.resource), body, {
      headers: this.parseHeaders({
        Accept: 'application/hal+json',
        'Content-Type': 'application/json'
      })
    });
  }
}
