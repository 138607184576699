import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgencyAcceptContractAsTechnician,
  EntityUuidSet,
  SetActingAs
} from '@libs/request-overview-common/state/requests-overview.actions';
import { LoadOwnTechnicianOfferWithContracts } from '../../../state/request-overview.actions';
import { UserRoles } from '@libs/shared/models/roles.enum';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { AbstractTechnicianOfferDetailsComponent } from './abstract-technician-offer-details.component';

@Component({
  selector: 'staffnow-own-technician-offer-details',
  templateUrl: './own-technician-offer-details.component.html',
  styleUrls: ['./technician-offer-details.component.scss']
})
export class OwnTechnicianOfferDetailsComponent extends AbstractTechnicianOfferDetailsComponent implements OnInit, OnDestroy {
  private technicianUuid: string = null;

  constructor(private activatedRoute: ActivatedRoute) {
    super();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(SetActingAs({ role: null }));
    this.store.dispatch(EntityUuidSet({ uuid: null }));
    super.ngOnDestroy();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.technicianUuid = params['technicianUuid'];
        this.store.dispatch(SetActingAs({ role: UserRoles.ROLE_TECHNICIAN }));
        this.store.dispatch(EntityUuidSet({ uuid: this.technicianUuid }));
        this.store.dispatch(
          LoadOwnTechnicianOfferWithContracts({
            refNumber: params['offerRefNumber'],
            technicianUuid: this.technicianUuid
          })
        );
      })
    );
  }

  get canAcceptContract(): boolean {
    return (
      this.contract?.technicianStatus != ContractStatus.accepted &&
      hasLink(this.contract, RequestOverviewLinkRel.AcceptContract)
    );
  }

  public acceptContract() {
    this.store.dispatch(
      AgencyAcceptContractAsTechnician({ contract: this.contract })
    );
  }
}
