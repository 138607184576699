import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Conversation } from '@libs/mailbox/state/mailbox-state.model';

@Component({
  selector: 'staffnow-conversation-item',
  templateUrl: './conversation-item.component.html',
  styleUrls: ['./conversation-item.component.scss']
})
export class ConversationItemComponent {
  @Input() public conversation: Conversation;
  @Input() public selectedConversationId;
  @Output() public loadConversation: EventEmitter<any> = new EventEmitter();

  @HostBinding('class.selected-conversation') get isSelected(): boolean {
    return this.conversation.conversationId === this.selectedConversationId;
  }

  @HostBinding('class.unseen') get isUnseen(): boolean {
    return this.conversation.hasUnseenMessages;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  @HostListener('click', ['$event'])
  public handleLoadConversation(): void {
    this.conversation = {
      ...this.conversation,
      hasUnseenMessages: false,
      amountOfUnseenMessages: 0
    };
    this.loadConversation.emit(this.conversation);
  }

  public navigateToProfile(event, partnerUserUuid: string) {
    event.stopPropagation();
    if (this.isAdminUser()) {
      this.router.navigate(['technicians', 'profile', partnerUserUuid]);
    }
  }

  public isAdminUser(): boolean {
    return this.activatedRoute.snapshot.data.isAdmin;
  }

  public getAvatarStyle(): any {
    if (this.conversation._links.partnerProfilePicture) {
      return {
        'background-image':
          "url('" + this.conversation._links.partnerProfilePicture.href + "')"
      };
    }
    return null;
  }
}
