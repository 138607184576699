import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  AdminOrModeratorProfileUpdated,
  ApiRootLoaded,
  LoadApiRoot,
  LoadLocationList,
  ReloadApiRoot
} from './api-root.actions';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ApiRootResource } from './api-root.model';
import { Store } from '@ngrx/store';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { AppRegionEnum, EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { AuthenticationService } from '@libs/auth/services/authentication.service';
import { LocationListLoaded } from '@libs/shared/bms-common/environment/environment.actions';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ListDtoWrapper } from '@libs/shared/models/responses/list-wrapper.model';
import { LocationPO } from '@libs/shared/models/location.model';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';

@Injectable()
export class BmsApiRootEffects {
  loadApiRoot$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadApiRoot, ReloadApiRoot),
      withLatestFrom(this.store.pipe(getStaticEnvironment)),
      switchMap(([{ region }, environment]) => this.getApiRoot(region, environment))
    )
  );

  AdminOrModeratorProfileUpdated = createEffect(() =>
    this.actions.pipe(
      ofType(AdminOrModeratorProfileUpdated),
      withLatestFrom(this.store.pipe(getStaticEnvironment), this.store.pipe(getFilteredApiRoot)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([action, environment, apiRoot]) => this.getApiRoot(apiRoot.apiRegion, environment))
    )
  );

  loadLocations$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadLocationList),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([action, apiRoot]) => {
        return this.httpClient.get(getUrl(apiRoot, 'getAvailableLocations')).pipe(
          map((response: ListDtoWrapper<LocationPO>) =>
            LocationListLoaded({
              locations: response.list
            })
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
            return EMPTY;
          })
        );
      })
    )
  );

  private getApiRoot(region: string, envData: EnvironmentState) {
    const apiUrl = this.getApiUrl(region, envData);
    return this.httpClient.get<ApiRootResource>(apiUrl + envData.remoteEndPoint).pipe(
      map(response =>
        ApiRootLoaded({
          payload: {
            ...response
          }
        })
      ),
      catchError(response => {
        console.error(response);
        if (response.status === 401 || response.status === 403) {
          this.authenticationService.handleUnauthorizedAccess();
        }
        return EMPTY;
      })
    );
  }

  private getApiUrl(region: string, envData: EnvironmentState) {
    switch (region) {
      case AppRegionEnum.USA:
        return envData.usaApiUrl;
      case AppRegionEnum.EU:
        return envData.europeApiUrl;
      default:
        return envData.europeApiUrl;
    }
  }

  constructor(
    private actions: Actions,
    private httpClient: HttpClient,
    private store: Store<any>,
    private errorMessageService: ErrorMessageService,
    private authenticationService: AuthenticationService
  ) {}
}
