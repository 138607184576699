import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-label-setter-dialog',
  templateUrl: './label-setter-dialog.component.html',
  styleUrls: ['./label-setter-dialog.component.scss']
})
export class LabelSetterDialogComponent {
  @Input() public onAcceptCallback: Function;
  @Input() public label: string = null;
  @Input() public warningMessage: string = null;
  @Input() public title: string = null;

  constructor(public bsModalRef: BsModalRef) {}

  public submit() {
    this.onAcceptCallback(this.label);
    this.bsModalRef.hide();
  }

  get isDisabled(): boolean {
    return !this.label || this.label.length === 0;
  }
}
