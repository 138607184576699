import { Component, Input, OnInit } from '@angular/core';
import { PackageContract } from '@libs/shared/models/package-contract.model';

@Component({
  selector: 'staffnow-package-contract-status',
  templateUrl: './package-contract-status.component.html',
  styleUrls: ['./package-contract-status.component.scss']
})
export class PackageContractStatusComponent implements OnInit {
  @Input() public contract: PackageContract = null;

  constructor() {}

  ngOnInit(): void {}
}
