<textarea
    [(ngModel)]="value"
    [disabled]="isDisabled"
    [maxlength]="textAreaMaxLength"
    [ngModelOptions]="{ standalone: true }"
    class="form-control"
    [placeholder]="placeholder"
    [rows]="rows"
></textarea>
<staffnow-text-counter
    *ngIf="textAreaMaxLength != null"
    [maxLength]="textAreaMaxLength"
    [textLength]="value?.length ?? 0"
    size="medium"
></staffnow-text-counter>
