import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PackageOfferAgency } from '@libs/shared/models/facility.model';

@Component({
  selector: 'staffnow-package-agency-card',
  templateUrl: './package-agency-card.component.html',
  styleUrls: ['./package-agency-card.component.scss']
})
export class PackageAgencyCardComponent {
  @Input() public agency: PackageOfferAgency = null;
  @Output() public onClick = new EventEmitter<any>();

  public isSelected: boolean = false;

  public handleAgencyClick(): void {
    this.isSelected = !this.isSelected;
    this.onClick.emit();
  }
}
