<div class="modal-wrapper">
    <div class="content-wrapper">
        <div class="text-wrapper">
            <div class="text">{{ question | translate }}</div>
        </div>
    </div>
    <div class="controls-container">
        <general-button
            (onClick)="declineHandler()"
            borderStraight="true"
            text="NO"
            color="secondary-low"
        ></general-button>
        <general-button
            (onClick)="acceptHandler()"
            borderStraight="true"
            text="YES"
            color="primary"
        ></general-button>
    </div>
</div>
