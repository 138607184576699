import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { Rate } from '@libs/shared/models/rate.model';
import { StaffnowDocument } from '@libs/user-profile/model/documents.model';

export enum PackageContractStatus {
  pending = 'PENDING',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  negotiation = 'NEGOTIATION'
}

export interface PackageContract extends Resource {
  id: number;
  mroUuid: string;
  agencyName: string;
  agencyStatus: PackageContractStatus;
  mroStatus: PackageContractStatus;
  startDate: string;
  endDate: string;
  agencyNotes: string;
  priceEstimate: boolean;
  agencyOfferedPrices: Rate[];
  mroOfferedPrice: Rate;
  agencyApprovals: Array<StaffnowDocument>;
}

export class PackageContractStatusUtils {
  private static getStatuses(
    contract: PackageContract
  ): PackageContractStatus[] {
    return [contract.mroStatus, contract.agencyStatus];
  }

  public static isPending(contract: PackageContract): boolean {
    const statuses = PackageContractStatusUtils.getStatuses(contract);
    return (
      !statuses.includes(PackageContractStatus.rejected) &&
      (statuses.includes(PackageContractStatus.pending) ||
        statuses.includes(PackageContractStatus.negotiation))
    );
  }

  public static isRejected(contract: PackageContract): boolean {
    return PackageContractStatusUtils.getStatuses(contract).includes(
      PackageContractStatus.rejected
    );
  }

  public static isAccepted(contract: PackageContract): boolean {
    return PackageContractStatusUtils.getStatuses(contract).every(
      status => status === PackageContractStatus.accepted
    );
  }
}
