import { RouterReducerState } from '@ngrx/router-store';
import { ActivatedView, NavigationState } from '@libs/shared/bms-common/navigation/navigation.model';
import { ApiRootState } from '@libs/shared/bms-common/api-root/api-root.model';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { FacilityStatus } from '@libs/shared/models/facility.model';
import { MroRate } from '@libs/shared/models/mro-rate.model';
import { EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';

export interface AppState {
  navigation: NavigationState;
  apiRoot: ApiRootState;
  environment?: EnvironmentState;
  router: RouterReducerState<ActivatedView>;
  facilityProfile?: FacilityProfileState;
  mroFacilities: MroFacilitySimple[];
  customerSupportTopicTypes: CustomerSupportTopicTypeState[];
}

export interface CustomerSupportTopicTypeState {
  key: string;
  value: string;
}

export interface FacilityProfileState extends Resource {
  location: string;
  name: string;
  shortPresentation: string | null;
  uuid: string;
  type: 'AGENCY' | 'MRO';
  slogan?: string;
  markUp?: string;
  checklist: string;
  documentsNeededTemplate?: string;
  groupId?: string;
  groupName?: string;
  rates: MroRate[];
  allowPrivateOffers?: boolean;
  allowExperienceLetterRequest?: boolean;
  offerDoubleCheck?: boolean;
  offersHandledCentrally?: boolean;
  requiresAgencyAcceptance?: boolean;
  requiresTechnicianAcceptance?: boolean;
  requiresProductionAcceptance?: boolean;
  requiresQualityAcceptance?: boolean;
  requiresHumanResourcesAcceptance?: boolean;
  requiresAgencySelectionForOfferApplication?: boolean;
  allowsPackageOffers?: boolean;
  allowsPermanentJobOffers?: boolean;
  allowsHideRateInJobOffers?: boolean;
  hideMroInformationInJobOffers?: boolean;
  allowTcnApplicationsToggle?: boolean;
  status?: FacilityStatus;
  approvedAgenciesStatus: 'IDLE' | 'LOADING' | 'DIRTY';
  mroRatesStatus: 'IDLE' | 'LOADING' | 'DIRTY';
  approvedAgencies: {
    selectedFacilities: any[];
    availableFacilities: any[];
  };
  mroApprovals: any[];
  daysBeforeAutoReject: number;
  services: string;
  workingCurrency?: string;
}

export interface MroFacilitySimple {
  name: string;
  uuid: string;
}

export const initialState: AppState = {
  apiRoot: {
    apiRoot: null
  },
  navigation: {
    activatedView: null,
    routeResources: {}
  },
  router: {
    navigationId: 1,
    state: {
      url: '',
      data: {},
      params: {},
      routePath: ['/'],
      routes: [],
      viewName: 'root',
      root: '/'
    }
  },
  facilityProfile: {
    location: '',
    name: '',
    shortPresentation: null,
    uuid: '',
    type: 'AGENCY',
    checklist: '',
    documentsNeededTemplate: '',
    approvedAgenciesStatus: 'IDLE',
    mroRatesStatus: 'IDLE',
    rates: [],
    approvedAgencies: {
      selectedFacilities: [],
      availableFacilities: []
    },
    mroApprovals: [],
    daysBeforeAutoReject: 0,
    _links: null,
    services: ''
  },
  mroFacilities: [],
  customerSupportTopicTypes: []
};
