import { Injectable } from '@angular/core';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNil } from 'lodash-es';
import { StaffnowErrorResponse } from '@libs/shared/exceptions/staffnow-error-response.model';
import { isBlank } from '@libs/shared/helpers/help-functions';
import { TranslateService } from '@ngx-translate/core';

interface NonIgnorableErrorData {
  httpStatus: number;
  apiCode: string;
}

class NonIgnorableErrors {
  public static readonly DELETED_FEATURE: NonIgnorableErrorData = { httpStatus: 410, apiCode: 'DELETED_FEATURE' };
  private static readonly ALL_ERRORS: NonIgnorableErrorData[] = [NonIgnorableErrors.DELETED_FEATURE];

  static getApiCode(httpStatus: number): string {
    return NonIgnorableErrors.ALL_ERRORS.find(error => error.httpStatus == httpStatus).apiCode;
  }
}

const errorMessages: Map<string, string> = new Map();
errorMessages.set(NonIgnorableErrors.DELETED_FEATURE.apiCode, 'ERROR_MESSAGES.DELETED_FEATURE');
errorMessages.set('RESET_CONTRACT_WRONG_ACTING_AS', 'ERROR_MESSAGES.RESET_CONTRACT_WRONG_ACTING_AS');
errorMessages.set('RESET_CONTRACT_STATUS_CONFLICT', 'ERROR_MESSAGES.RESET_CONTRACT_STATUS_CONFLICT');
errorMessages.set('CONTRACT_CONFLICT', 'ERROR_MESSAGES.CONTRACT_CONFLICT');
errorMessages.set(
  'TCN_TECHNICIAN_APPLYING_TO_NON_TCN_OFFER',
  'ERROR_MESSAGES.TCN_TECHNICIAN_APPLYING_TO_NON_TCN_OFFER'
);
errorMessages.set(
  'TECHNICIAN_DID_NOT_ACKNOWLEDGE_LABOUR_REGULATIONS',
  'ERROR_MESSAGES.TECHNICIAN_DID_NOT_ACKNOWLEDGE_LABOUR_REGULATIONS'
);
errorMessages.set(
  'AGENCY_DID_NOT_ACKNOWLEDGE_LABOUR_REGULATIONS_ON_BEHALF_OF_TECHNICIANS',
  'ERROR_MESSAGES.AGENCY_DID_NOT_ACKNOWLEDGE_LABOUR_REGULATIONS_ON_BEHALF_OF_TECHNICIANS'
);
errorMessages.set(
  'INCOMPLETE_TECHNICIAN_TRIED_TO_APPLY_TEMPORARY_JOB',
  'ERROR_MESSAGES.INCOMPLETE_TECHNICIAN_TRIED_TO_APPLY_TEMPORARY_JOB'
);
errorMessages.set('CONTRACT_END_DATE_IS_MISSING', 'ERROR_MESSAGES.CONTRACT_END_DATE_IS_MISSING');
errorMessages.set('CONTRACT_END_DATE_BEFORE_START_DATE', 'ERROR_MESSAGES.CONTRACT_END_DATE_BEFORE_START_DATE');
errorMessages.set('CONTRACT_END_DATE_IS_IN_THE_FUTURE', 'ERROR_MESSAGES.CONTRACT_END_DATE_IS_IN_THE_FUTURE');
errorMessages.set('CONTRACT_PHASE_IS_NOT_OK', 'ERROR_MESSAGES.CONTRACT_PHASE_IS_NOT_OK');
errorMessages.set('DOCUMENTS_NEEDED_STRING_EMPTY', 'ERROR_MESSAGES.DOCUMENTS_NEEDED_STRING_EMPTY');
errorMessages.set('DOCUMENTS_NEEDED_STRING_TOO_LONG', 'ERROR_MESSAGES.DOCUMENTS_NEEDED_STRING_TOO_LONG');
errorMessages.set('START_DATE_IS_AFTER_END_DATE', 'ERROR_MESSAGES.START_DATE_IS_AFTER_END_DATE');
errorMessages.set('AUTOMATIC_REPLY_MISSING_CONFIGURATION', 'ERROR_MESSAGES.AUTOMATIC_REPLY_MISSING_CONFIGURATION');
errorMessages.set(
  'DUPLICATED_FILES_ON_PACKAGE_OFFER_EXCEPTION',
  'ERROR_MESSAGES.FILES_CANNOT_BE_ADDED_TO_EXPIRED_PACKAGE_OFFER'
);
errorMessages.set(
  'FILES_CANNOT_BE_ADDED_TO_EXPIRED_PACKAGE_OFFER',
  'ERROR_MESSAGES.ONE_OR_MORE_PACKAGE_OFFER_FILE_TYPES_ARE_NOT_SUPPORTED_EXCEPTION'
);
errorMessages.set(
  'ONE_OR_MORE_PACKAGE_OFFER_FILE_TYPES_ARE_NOT_SUPPORTED_EXCEPTION',
  'ERROR_MESSAGES.THE_LIMIT_FOR_PACKAGE_OFFER_FILES_HAS_BEEN_REACHED_EXCEPTION'
);
errorMessages.set(
  'THE_LIMIT_FOR_PACKAGE_OFFER_FILES_HAS_BEEN_REACHED_EXCEPTION',
  'ERROR_MESSAGES.USER_CANNOT_DO_FILE_OPERATIONS_TO_PACKAGE_OFFER'
);
errorMessages.set(
  'USER_CANNOT_DO_FILE_OPERATIONS_TO_PACKAGE_OFFER',
  'ERROR_MESSAGES.USER_CANNOT_DO_FILE_OPERATIONS_TO_PACKAGE_OFFER'
);

@Injectable()
export class ErrorMessageService {
  constructor(
    private modalService: ModalService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslateService
  ) {}

  public handleErrorResponseWithoutWarningTheUser(response: HttpErrorResponse): void {
    this.printTraceIntoTheConsole(response);
    if (this.isNonIgnorableError(response)) {
      this.displayToastFailureMessage(this.getErrorMessageFromResponseStatus(response));
    }
  }

  public handleErrorResponseWithCustomMessage(response: HttpErrorResponse, message: string): void {
    this.printTraceIntoTheConsole(response);
    if (this.isNonIgnorableError(response)) {
      message = this.getErrorMessageFromResponseStatus(response);
    }
    this.displayToastFailureMessage(message);
  }

  public handleErrorResponse(response: HttpErrorResponse, defaultMessage?: string): void {
    this.printTraceIntoTheConsole(response);
    const message: string = this.getErrorMessage(response);
    this.displayToastFailureMessage(isBlank(message) ? this.translateService.instant(defaultMessage) : message);
  }

  public handleErrorResponseWithCustomerSupportModal(response: HttpErrorResponse, modalTitle: string): void {
    this.printTraceIntoTheConsole(response);
    this.modalService.openCustomerSupportErrorModal({
      title: modalTitle,
      message: this.getErrorMessage(response)
    });
  }

  private printTraceIntoTheConsole(response: HttpErrorResponse): void {
    console.trace('Received this error response from server: ', response);
  }

  private getErrorMessage(response: HttpErrorResponse): string {
    const staffnowError: StaffnowErrorResponse = response.error;
    const errorCode: string = staffnowError?.errorCode;
    let message: string;
    if (isNil(errorCode) && this.isNonIgnorableError(response)) {
      message = this.getErrorMessageFromResponseStatus(response);
    } else {
      message = errorMessages.get(errorCode) && this.translateService.instant(errorMessages.get(errorCode));
    }
    return isNil(message) ? staffnowError?.message : message;
  }

  private isNonIgnorableError(response: HttpErrorResponse): boolean {
    return response.status == NonIgnorableErrors.DELETED_FEATURE.httpStatus;
  }

  private getErrorMessageFromResponseStatus(response: HttpErrorResponse): string {
    return this.translateService.instant(errorMessages.get(NonIgnorableErrors.getApiCode(response.status)));
  }

  private displayToastFailureMessage(message: string): void {
    this.toastMessageService.fail(message);
  }
}
