import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityUuidSet, SetActingAs } from '@libs/request-overview-common/state/requests-overview.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserRoles } from '@libs/shared/models/roles.enum';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';

@Component({
  selector: 'staffnow-request-overview-as-technician',
  templateUrl: './request-overview-as-technician.component.html',
  styleUrls: ['./request-overview-as-technician.component.scss']
})
export class RequestOverviewAsTechnician implements OnInit, OnDestroy {
  public USER_ROLES = UserRoles;

  public selectedValue: string;
  private subs: Array<Subscription> = [];
  techniciansRetrievalUrl: string;

  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.techniciansRetrievalUrl = ApiRootLinkRel.GetOwnTechniciansSimple
    this.watchQueryParams();
  }

  private watchQueryParams(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.updateSelectedValue(params.uuid);
      })
    );
  }

  private updateSelectedValue(newSelectedUuid) {
    this.selectedValue = newSelectedUuid;
    this.store.dispatch(SetActingAs({ role: UserRoles.ROLE_TECHNICIAN }));
    this.store.dispatch(EntityUuidSet({ uuid: this.selectedValue }));
  }

  public updateUuidQueryParam(uuid: string, replaceUrl: boolean = false): void {
    this.router.navigate([], {
      replaceUrl,
      queryParamsHandling: 'merge',
      queryParams: { uuid }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.store.dispatch(SetActingAs({ role: null }));
    this.store.dispatch(EntityUuidSet({ uuid: null }));
  }
}
