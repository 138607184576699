import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectOffer} from '../../state/request-overview.selectors';
import {take, tap} from 'rxjs/operators';
import {isFixedPrice, isTemporary, isPermanent, OfferOutDto} from '@libs/shared/models/offer.model';
import {getLoggedInUserRole} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {UserRolesUtils} from '@libs/shared/models/roles.enum';

@Component({
  selector: 'staffnow-collapsible-contracts-list',
  templateUrl: './collapsible-contracts-list.component.html',
  styleUrls: ['./collapsible-contracts-list.component.scss']
})
export class CollapsibleContractsListComponent implements OnInit, OnDestroy {
  @Input() heading = '';
  @Input() contracts = [];

  offer: OfferOutDto;
  offerEndDate: string = null;
  offerStartDate: string = null;

  private offerSub: Subscription;
  private userRole: string = null;

  constructor(private store: Store<any>) {
  }

  ngOnInit() {
    this.offerSub = this.store
      .select(selectOffer)
      .pipe(tap(offer => this.updateOffer(offer)))
      .subscribe();
    this.store
      .pipe(getLoggedInUserRole, take(1))
      .subscribe(userRole => (this.userRole = userRole));
  }

  get isJobOffer(): boolean {
    return isTemporary(this.offer);
  }

  get isPermanentJobOpening(): boolean {
    return isPermanent(this.offer);
  }

  get isFixedPrice(): boolean {
    return isFixedPrice(this.offer);
  }

  get contractEntity(): string {
    return this.isFixedPrice ? 'agencies' : 'technicians';
  }

  get isMro(): boolean {
    return UserRolesUtils.isMro(this.userRole);
  }

  get isAgency(): boolean {
    return UserRolesUtils.isAgency(this.userRole);
  }

  private updateOffer(offer) {
    this.offer = offer;
    if (offer) {
      this.offerEndDate = this.offer.periodTo;
      this.offerStartDate = this.offer.periodFrom;
    }
  }

  ngOnDestroy(): void {
    this.offerSub.unsubscribe();
  }
}
