<div class="d-flex flex-column">
    <div class="mb-3">
        <h3 class="text-center">The following changes will be applied to {{ brand }}</h3>
    </div>
    <div>
        <table class="table table-borderless" aria-label="Changes in personal documents">
            <thead>
                <tr>
                    <th></th>
                    <th>Document Type</th>
                    <th>Mandatory</th>
                    <th>Expiration Date Required</th>
                </tr>
            </thead>
            <tbody>
                @for (change of changes; track change.id) {
                <tr>
                    <td [ngClass]="iconColor(change.operation)">
                        @if (hasChanges(change.operation)) {
                        <i class="material-icons">{{ documentVisibility(change.operation) }}</i>
                        }
                    </td>
                    <td>{{ change.title }}</td>
                    <td class="text-center" [ngClass]="iconColor(change.mandatory)">
                        @if (hasChanges(change.mandatory)) {
                        <i class="material-icons">{{ iconChange(change.mandatory) }}</i>
                        }
                    </td>
                    <td class="text-center" [ngClass]="iconColor(change.expiration)">
                        @if (hasChanges(change.expiration)) {
                        <i class="material-icons">{{ iconChange(change.expiration) }}</i>
                        }
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    <div class="mb-3">
        <p class="mb-2">
            If you are sure to proceed, please confirm the action by writing "Confirm" on the field below.
        </p>
        <input type="text" class="form-control" placeholder="Confirm" [(ngModel)]="confirmation" />
    </div>
    <div class="controls-container">
        <general-button
            (onClick)="cancelHandler()"
            text="Cancel"
            color="secondary-low"
            id="cancel-button"
        ></general-button>
        <general-button
            (onClick)="agreedHandler()"
            text="Accept"
            color="primary"
            [isDisabled]="!userConfirmed()"
            id="confirmation-button"
        ></general-button>
    </div>
</div>
