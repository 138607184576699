<staffnow-go-back-button
    [currentPath]="['AGENCY.DETAILS.MY_JO' | translate, 'AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS' | translate]"
></staffnow-go-back-button>
@if(isLoading) {
<staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
} @else {
<staffnow-panel [title]="'AGENCY.DETAILS.APPLICATION_STATUS.LABEL' | translate : { refNumber: jobOffer?.refNumber }">
    <ng-container data>
        <div class="technician-details">
            <staffnow-technician-contract-details
                [contract]="contract"
                [isPermanent]="false"
            ></staffnow-technician-contract-details>
            <staffnow-technician-guidelines
                [offer]="offer"
                [contract]="contract"
                [requiresAgencySelectionForOfferApplication]="requiresAgencySelectionForOfferApplication"
                [brandName]="brandName"
                [labourRegulationsAcknowledgmentClicked]="labourRegulationsAcknowledgmentClicked"
                (onLabourAcknowledgmentChanged)="labourAcknowledgmentChanged($event)"
            ></staffnow-technician-guidelines>
        </div>
    </ng-container>
    <ng-container actions>
        @if(shouldDisplayChooseNewAgency) {
        <div class="contract-action">
            <general-button
                (onClick)="acceptOffer()"
                [isDisabled]="isOfferEnded"
                size="small"
                title="{{ 'SYSTEM.INFO.NEW_AGENCY_BUTTON' | translate }}"
                [text]="'TECHNICIAN_OFFER_DETAILS.CHOOSE_NEW_AGENCY' | translate"
            ></general-button>
        </div>
        }
        <staffnow-technician-contract-actions
            [contract]="contract"
            [offer]="offer"
            (acceptDocument)="acceptOffer()"
            [isDisabledAcceptDocumentButton]="!canAccept"
            [shouldDisplayAcceptButton]="shouldDisplayAcceptButton"
        ></staffnow-technician-contract-actions>
        <general-button
            (onClick)="initiateMessageWithMro()"
            [isDisabled]="!canCreateConversationWithMro && !canGetPreviousConversationWithMro"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_AVIATION_COMPANY' | translate"
            title="{{ 'SYSTEM.INFO.MESSAGE_MRO_AFTER_ACCEPTING_JO' | translate }}"
            color="secondary"
        ></general-button>
        @if(contract?.requiresAgencyAcceptance) {
        <general-button
            (onClick)="initiateMessageWithAgency()"
            [isDisabled]="!canCreateConversationWithAgency && !canGetPreviousConversationWithAgency"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_AGENCY' | translate"
            title="{{ 'SYSTEM.INFO.MESSAGE_AGENCY_AFTER_ACCEPTING_JO' | translate }}"
            color="secondary"
        ></general-button>
        }
    </ng-container>
</staffnow-panel>
<staffnow-offer-details [offer]="jobOffer" [title]="'OWN_TECHNICIAN.JO_DETAILS' | translate : { offerDescription }">
    @if(!jobOffer.hasHiddenRate) {
    <ng-container data>
        <staffnow-contract-info [label]="'GENERAL.PAY_RATE' | translate">
            <staffnow-technician-offer-pay-rate [jobOffer]="jobOffer"> </staffnow-technician-offer-pay-rate>
        </staffnow-contract-info>
    </ng-container>
    }
</staffnow-offer-details>
}
