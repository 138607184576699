import { NgModule } from '@angular/core';
import { ReportPlatformComponent } from './report-platform/report-platform.component';
import { ReportsPlatformListComponent } from './reports-platform-list/reports-platform-list.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from '@libs/translation/translation.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CommonUiModule,
    TranslationModule
  ],
  declarations: [ReportPlatformComponent, ReportsPlatformListComponent],
  exports: [ReportsPlatformListComponent]
})
export class ReportsPlatformModule {}
