import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '@libs/shared/helpers/abstract-value-accessor';

@Component({
  selector: 'staffnow-textarea',
  templateUrl: './staffnow-textarea.component.html',
  styleUrls: ['./staffnow-textarea.component.scss'],
  providers: [MakeProvider(StaffnowTextareaComponent)]
})
export class StaffnowTextareaComponent extends AbstractValueAccessor {
  @Input() textAreaMaxLength: number = null;
  @Input() isDisabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() rows: number;

  @Input() set text(text: string) {
    this.writeValue(text);
  }
}
