<div [class]="['platform-login-wrapper', brandName.toLowerCase()]">
    <div class="log-box-container">
        <div class="log-form-column">
            <div class="logo-container">
                <div [class]="['logo', brandName.toLowerCase()]"></div>
            </div>
            <div class="log-form-content">
                <form [formGroup]="form" *ngIf="!submitted">
                    <!-- password -->
                    <div class="form-group">
                        <label for="password" class="control-label font-weight-bold">Password</label>
                        <input
                            id="password"
                            type="password"
                            formControlName="password"
                            class="form-control"
                            name="password"
                            placeholder="Choose a password"
                            [ngClass]="{
                                'is-valid': (f.password.dirty || submitted) && passwordValid,
                                'is-invalid': (f.password.dirty || submitted) && !passwordValid
                            }"
                        />
                        <div class="invalid-feedback">
                            <span class="text-danger" *ngIf="(f.password.dirty || submitted) && !requiredValid"
                                >Password is required</span
                            >
                            <!-- password validation rules -->
                            <div id="validation-rules" class="mt-3" *ngIf="requiredValid">
                                <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                                    Must be at least 8 characters long
                                </div>

                                <div
                                    [ngClass]="{
                                        'text-success': requiresDigitValid,
                                        'text-danger': !requiresDigitValid
                                    }"
                                >
                                    Must contain at least 1 number
                                </div>

                                <div
                                    [ngClass]="{
                                        'text-success': requiresUppercaseValid,
                                        'text-danger': !requiresUppercaseValid
                                    }"
                                >
                                    Must contain at least 1 uppercase Latin character (A-Z)
                                </div>

                                <div
                                    [ngClass]="{
                                        'text-success': requiresLowercaseValid,
                                        'text-danger': !requiresLowercaseValid
                                    }"
                                >
                                    Must contain at least 1 lowercase Latin character (a-z)
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- confirm password -->
                    <div class="form-group">
                        <label for="confirmPassword" class="control-label font-weight-bold">Confirm password</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            formControlName="confirmPassword"
                            class="form-control"
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            [ngClass]="{
                                'is-valid': (submitted || f.confirmPassword.dirty) && !f.confirmPassword.errors,
                                'is-invalid': (submitted || f.confirmPassword.dirty) && f.confirmPassword.errors
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            *ngIf="(submitted || f.confirmPassword.dirty) && f.confirmPassword.errors"
                        >
                            <div *ngIf="f.confirmPassword.errors.required">Confirm your password</div>
                            <div *ngIf="f.confirmPassword.errors.mismatch">Passwords do not match</div>
                        </div>
                    </div>

                    <!-- update password button -->
                    <div class="form-group">
                        <general-button
                            type="submit"
                            class="btn-block"
                            (onClick)="onSubmit()"
                            [isDisabled]="!form.valid || isWorking"
                            text="{{ isWorking ? 'Sending request' : 'Update password' }}"
                        ></general-button>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-block btn-outline-primary" routerLink="/register">
                            {{ 'GO BACK TO HOME' | translate }}
                        </button>
                    </div>
                </form>
                <div *ngIf="submitted && success">
                    <div class="form-group">
                        <h2><b>Password updated successfully</b></h2>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-block btn-primary" routerLink="/register">
                            {{ 'GO BACK TO HOME' | translate }}
                        </button>
                    </div>
                </div>
                <div *ngIf="submitted && !success">
                    <div class="form-group">
                        <h2>
                            <b>The password was not updated.</b><br />
                            Please remember the link has an expiration time.<br />
                            Please contact our support team in case you have<br />
                            any doubts at <a [href]="'mailto:' + customerSupportEmail">{{ customerSupportEmail }}</a
                            >.
                        </h2>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-block btn-primary" routerLink="/register">
                            {{ 'GO BACK TO HOME' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
