import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BulletPointItem} from '@libs/shared/models/bullet-point.model';

@Component({
  selector: 'staffnow-bullet-points',
  templateUrl: './bullet-points.component.html',
  styleUrls: ['./bullet-points.component.scss']
})
export class BulletPointsComponent {
  @Input() bulletPoints: BulletPointItem[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  public sanitizedHtmlDescription(description: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

}
