<input
    type="text"
    autocomplete="off"
    class="form-control date-picker"
    bsDatepicker
    [bsConfig]="{
        showWeekNumbers: false,
        dateInputFormat: dateFormat
    }"
    [bsValue]="_initialValue"
    (bsValueChange)="emitChange($event)"
    [placeholder]="placeholder"
/>
<p class="important">
    <strong>IMPORTANT</strong>: The date format is "{{dateFormat}}".
</p>

