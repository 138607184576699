import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() public minutesInterval: 15 | 30 = 30;
  @Input() public value: string = null;
  @Output() public change = new EventEmitter<string>();
  public options: string[] = [];
  private startHour: number = 0;
  private endHour: number = 23;

  ngOnInit(): void {
    const minutes: string[] = this.createListOfMinutes();
    for (let hour: number = this.startHour; hour <= this.endHour; ++hour) {
      const paddedHour = this.padWithZero(hour);
      for (let minute of minutes) {
        this.options.push(`${paddedHour}:${minute}`);
      }
    }
  }

  private padWithZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  private createListOfMinutes(): string[] {
    const result: string[] = [];
    let minuteOption: number = 0;
    while (minuteOption < 60) {
      result.push(this.padWithZero(minuteOption));
      minuteOption += this.minutesInterval;
    }
    return result;
  }

  public handleChange(selectedTime: string): void {
    this.change.emit(selectedTime);
  }

}
