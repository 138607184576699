import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentArea, StaffnowDocument } from '../model/documents.model';
import { select, Store } from '@ngrx/store';
import { isEmpty, isNil } from 'lodash-es';
import { selectUserProfile } from '@libs/user-profile/state/user-profile.selectors';
import {
  ChangeApprovedDocumentStatus,
  ChangeDocumentStatus
} from '@libs/user-profile/state/user-profile.actions';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import {
  MissingData,
  PendingChange
} from '@libs/shared/models/technician-profile.model';
import { getDocumentAreasURL } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private missingData: any = {};
  private rejectedData: any = {};
  private pendingChanges: Array<any> = [];
  private userProfile: UserProfileType;
  private documentsUrl: string = null;

  constructor(private store: Store<any>, private httpClient: HttpClient) {
    this.store
      .pipe(getDocumentAreasURL)
      .subscribe(url => (this.documentsUrl = url));
    this.store.pipe(select(selectUserProfile)).subscribe(userProfile => {
      this.userProfile = userProfile;
      const embedded = userProfile._embedded;
      if ('pendingChanges' in embedded && 'missingData' in embedded) {
        this.missingData = this.convertArrayDataToObject(embedded.missingData);
        this.rejectedData = this.getRejectedData(embedded.pendingChanges);
        this.pendingChanges = embedded.pendingChanges;
      }
    });
  }

  private getRejectedData(pendingChanges: Array<PendingChange>): any {
    const data = {};
    if (pendingChanges && pendingChanges.length > 0) {
      pendingChanges.forEach(dataEntry => {
        if (dataEntry.rejected) {
          data[dataEntry.subject] = data[dataEntry.subject] || {};
          data[dataEntry.subject][dataEntry.subjectUuid] = true;
        }
      });
    }
    return data;
  }

  private convertArrayDataToObject(missingData: Array<MissingData>): any {
    const data = {};
    if (missingData && missingData.length > 0) {
      missingData.forEach(entry => {
        data[entry.subject] = true;
      });
    }
    return data;
  }

  public getDocumentAreas(
    technicianUuid: string
  ): Observable<Resource> {
    const params = new HttpParams({
      fromObject: {
        technicianUuid: technicianUuid
      }
    });
    return this.httpClient.get<Resource>(
      this.documentsUrl.split('?')[0],
      { params }
    );
  }

  public changeDocumentStatus(singleDocument): void {
    const userUrl = this.userProfile._links.self.href;
    const pendingChangeId = this.pendingChanges.find(
      obj => obj.subjectUuid === singleDocument.uuid
    );

    const payload: any = {
      userUrl,
      changes: {}
    };
    if (pendingChangeId) {
      if (singleDocument.status) {
        payload.changes.verifiedChanges = [pendingChangeId.id.toString()];
      } else {
        payload.changes.rejectedChanges = [pendingChangeId.id.toString()];
      }
      this.store.dispatch(ChangeDocumentStatus({ payload }));
    } else {
      this.store.dispatch(
        ChangeApprovedDocumentStatus({
          fileUrl: singleDocument.fileUrl
        })
      );
    }
  }

  public isDocumentRejected(context): boolean {
    return !isEmpty(this.rejectedData[context]);
  }

  public isDocumentMissing(context) {
    return !isNil(this.missingData[context]);
  }

  public isFileRejected(file: StaffnowDocument): boolean {
    if (!file) {
      return false;
    }
    return (
      this.rejectedData[file.context] &&
      this.rejectedData[file.context][file.uuid]
    );
  }

  public isFilePendingChange(file: StaffnowDocument): boolean {
    if (!file) {
      return false;
    }
    if (this.pendingChanges?.find(change => change.subjectUuid === file.uuid)) {
      return true;
    }
  }

  public fillRejectedAndPending(
    documents: StaffnowDocument[]
  ): StaffnowDocument[] {
    return documents.map(document => ({
      ...document,
      rejected: this.isFileRejected(document),
      pendingChange: this.isFilePendingChange(document)
    }));
  }
}
