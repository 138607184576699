<div class="categories-container">

    <!--Guidelines-->
    <div class="guidelines">
        <p>
            The information in this section is collected for statistical purposes in order to comply
            with certain federal regulations relating to Equal Employment Opportunity and Affirmative
            Action requirements. The information you provide is on a voluntary basis, and the content
            will not affect your eligibility for employment.
        </p>
        <p>
            If you choose not to provide this information, this will not affect your eligibility for employment.
            If at any time in the future your status changes, please modify the entries in this menu.
        </p>
    </div>

    <!--Gender Self ID Category-->
    <div class="category">
        <div class="category-title">
            <h1 class="h1">
                {{ 'Gender*' | translate }}
            </h1>
        </div>
        <div class="custom-controls-stacked">
            <div class="category-radio custom-control custom-radio">
                <input
                    id="male-gender"
                    type="radio"
                    name="gender"
                    class="custom-control-input"
                    value="MALE"
                    [checked]="isGenderSelected('MALE')"
                    (change)="setGender($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="male-gender" class="custom-control-label">{{ 'Male' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="female-gender"
                    type="radio"
                    name="gender"
                    class="custom-control-input"
                    value="FEMALE"
                    [checked]="isGenderSelected('FEMALE')"
                    (change)="setGender($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="female-gender" class="custom-control-label">{{ 'Female' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="non-binary-gender"
                    type="radio"
                    name="gender"
                    class="custom-control-input"
                    value="NON_BINARY"
                    [checked]="isGenderSelected('NON_BINARY')"
                    (change)="setGender($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="non-binary-gender" class="custom-control-label">{{ 'Non Binary' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="decline-to-answer-gender"
                    type="radio"
                    name="gender"
                    class="custom-control-input"
                    value="DECLINE_TO_ANSWER"
                    [checked]="isGenderSelected('DECLINE_TO_ANSWER')"
                    (change)="setGender($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="decline-to-answer-gender" class="custom-control-label">{{ 'Decline to answer' | translate }}</label>
            </div>
        </div>
    </div>

    <!--Ethnicity Self ID Category-->
    <div class="category">
        <div class="category-title">
            <h1 class="h1">
                {{ 'Ethnicity*' | translate }}
            </h1>
            <span *ngIf="canUpdateTechnicianSelfIdentification" (click)="openModal(ethnicityInformation)" class="category-info material-icons md-24">
                info
            </span>
        </div>
        <div class="custom-controls-stacked">
            <div class="category-radio custom-control custom-radio">
                <input
                    id="american-indian-alaskan-native-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="AMERICAN_INDIAN_ALASKAN_NATIVE"
                    [checked]="isEthnicitySelected('AMERICAN_INDIAN_ALASKAN_NATIVE')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="american-indian-alaskan-native-ethnicity" class="custom-control-label">{{ 'American Indian/Alaskan Native' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="asian-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="ASIAN"
                    [checked]="isEthnicitySelected('ASIAN')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="asian-ethnicity" class="custom-control-label">{{ 'Asian' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="black-african-american-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="BLACK_AFRICAN_AMERICAN"
                    [checked]="isEthnicitySelected('BLACK_AFRICAN_AMERICAN')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="black-african-american-ethnicity" class="custom-control-label">{{ 'Black/African American' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="hipanic-latino-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="HISPANIC_LATINO"
                    [checked]="isEthnicitySelected('HISPANIC_LATINO')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="hipanic-latino-ethnicity" class="custom-control-label">{{ 'Hispanic/Latino' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="native-hawaiian-other-pacific-islander-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER"
                    [checked]="isEthnicitySelected('NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="native-hawaiian-other-pacific-islander-ethnicity" class="custom-control-label">{{ 'Native Hawaiian/Other Pacific Islander' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="white-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="WHITE"
                    [checked]="isEthnicitySelected('WHITE')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="white-ethnicity" class="custom-control-label">{{ 'White' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="two-or-more-races-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="TWO_OR_MORE_RACES"
                    [checked]="isEthnicitySelected('TWO_OR_MORE_RACES')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="two-or-more-races-ethnicity" class="custom-control-label">{{ 'Two or more races' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="decline-to-answer-ethnicity"
                    type="radio"
                    name="ethnicity"
                    class="custom-control-input"
                    value="DECLINE_TO_ANSWER"
                    [checked]="isEthnicitySelected('DECLINE_TO_ANSWER')"
                    (change)="setEthnicity($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="decline-to-answer-ethnicity" class="custom-control-label">{{ 'Decline to answer' | translate }}</label>
            </div>
        </div>
    </div>

    <!--Disability Self ID Category-->
    <div class="category">
        <div class="category-title">
            <h1 class="h1">
                {{ 'Disability*' | translate }}
            </h1>
            <span *ngIf="canUpdateTechnicianSelfIdentification" (click)="openModal(disabilityInformation)" class="category-info material-icons md-24">
                info
            </span>
        </div>
        <div class="custom-controls-stacked">
            <div class="category-radio custom-control custom-radio">
                <input
                    id="yes-disability"
                    type="radio"
                    name="disability"
                    class="custom-control-input"
                    value="YES"
                    [checked]="isDisabilitySelected('YES')"
                    (change)="setDisability($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="yes-disability" class="custom-control-label">{{ 'Yes' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="no-disability"
                    type="radio"
                    name="disability"
                    class="custom-control-input"
                    value="NO"
                    [checked]="isDisabilitySelected('NO')"
                    (change)="setDisability($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="no-disability" class="custom-control-label">{{ 'No' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="decline-to-answer-disability"
                    type="radio"
                    name="disability"
                    class="custom-control-input"
                    value="DECLINE_TO_ANSWER"
                    [checked]="isDisabilitySelected('DECLINE_TO_ANSWER')"
                    (change)="setDisability($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="decline-to-answer-disability" class="custom-control-label">{{ 'Decline to answer' | translate }}</label>
            </div>
        </div>
    </div>

    <!--Protected veteran status Self ID Category-->
    <div class="category">
        <div class="category-title">
            <h1 class="h1">
                {{ 'Protected veteran status*' | translate }}
            </h1>
            <span *ngIf="canUpdateTechnicianSelfIdentification" (click)="openModal(protectedVeteranStatusInformation)" class="category-info material-icons md-24">
                info
            </span>
        </div>
        <div class="custom-controls-stacked">
            <div class="category-radio custom-control custom-radio">
                <input
                    id="yes-protected-veteran-status"
                    type="radio"
                    name="protected-veteran-status"
                    class="custom-control-input"
                    value="YES"
                    [checked]="isProtectedVeteranStatusSelected('YES')"
                    (change)="setProtectedVeteranStatus($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="yes-protected-veteran-status" class="custom-control-label">{{ 'Yes' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="no-protected-veteran-status"
                    type="radio"
                    name="protected-veteran-status"
                    class="custom-control-input"
                    value="NO"
                    [checked]="isProtectedVeteranStatusSelected('NO')"
                    (change)="setProtectedVeteranStatus($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="no-protected-veteran-status" class="custom-control-label">{{ 'No' | translate }}</label>
            </div>
            <div class="category-radio custom-control custom-radio">
                <input
                    id="decline-to-answer-protected-veteran-status"
                    type="radio"
                    name="protected-veteran-status"
                    class="custom-control-input"
                    value="DECLINE_TO_ANSWER"
                    [checked]="isProtectedVeteranStatusSelected('DECLINE_TO_ANSWER')"
                    (change)="setProtectedVeteranStatus($event.target.value)"
                    [disabled]="!canUpdateTechnicianSelfIdentification"
                />
                <label for="decline-to-answer-protected-veteran-status" class="custom-control-label">{{ 'Decline to answer' | translate }}</label>
            </div>
        </div>
    </div>

    <!--Submit-->
    <div *ngIf="canUpdateTechnicianSelfIdentification" class="save-button-container">
        <general-button
            text="Submit"
            [isLoading]="isLoading"
            [isDisabled]="!canSubmit"
            (onClick)="udateTechnicianSelfIdentification()"
        ></general-button>
    </div>
</div>

<!--Ethnicity information-->
<ng-template #ethnicityInformation>
    <ng-container *ngTemplateOutlet="modalHeader"></ng-container>
    <div class="modal-body">
        <p>
        <b>American Indian and Alaska Native (not Hispanic or Latino):</b> A person of origins in any of the original peoples of North and South America (including Central America), and who maintains tribal affiliation or community attachment.<br>
        <b>Asian (not Hispanic or Latino):</b> A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian Subcontinent, including for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam.<br>
        <b>Black or African American (not Hispanic or Latino):</b> A person having origins in any of the black racial groups of Africa.<br>
        <b>Hispanic/Latino:</b> A person of Cuban, Mexican, Puerto Rican, South or Central American or other Spanish culture or origin, regardless of race.<br>
        <b>Native Hawaiian or Other Pacific Islander (nor Hispanic or Latino):</b> A person having origins in any of the peoples in Hawaii, Guam, Samoa, or other Pacific Islands.<br>
        <b>White (not Hispanic or Latino):</b> A person having origins in any of the people of Europe, the Middle East, or North Africa.<br>
        <b>Two or more races (not Hispanic or Latino):</b> All persons who identify with more than one of the above five races.
        </p>
    </div>
    <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
</ng-template>

<!--Disability information-->
<ng-template #disabilityInformation>
    <ng-container *ngTemplateOutlet="modalHeader"></ng-container>
    <div class="modal-body">
        <p>
        <b>Why are you being asked to fill in this form?</b><br>
        We are a federal contractor or subcontractor required by law to provide equal employment opportunity to qualified people with disabilities. We are also required to measure our progress towards having at least 7% of our workforce to be individuals with disabilities. To do this, we must ask applicants and employees if they have a disability or have ever had a disability.<br>
        Identifying yourself as an individual with a disability is voluntary, and we hope you choose to do so. Your answer will be maintained confidentially and will not be seen by selecting officials or anyone else involved in making personal decisions. Completing the form will not negatively impact you in any way regardless of whether you have self-identified in the past. For more information about this form or the equal employment obligations of federal contractors under Section 503 of the Rehabilitation Act, visit the US department or Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at: www.dol.gov/ofccp.<br><br>

        <b>How do you know if you have a disability?</b><br>
        You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limit a major life activity, or if you have a history or record of such an impairment or medical condition. Disabilities include but are nor limited to: <br>
        Autism <br>
        Autoimmune disorder, for example lupus, fibromyalgia, rheumatoid arthritis or HIV/AIDS<br>
        Cancer<br>
        Cardiovascular or heart disease <br>
        Cerebral palsy<br>
        Deaf or heard of hearing<br>
        Depression or anxiety<br>
        Diabetes<br>
        Epilepsy<br>
        Gastrointestinal, disorders, for example Crohn’s disease, or Multiple Sclerosis (MS)<br>
        Psychiatric condition, for example, bipolar disorder, schizophrenia, PTSD, or major depression
        </p>
    </div>
    <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
</ng-template>

<!--Protected veteran status information-->
<ng-template #protectedVeteranStatusInformation>
    <ng-container *ngTemplateOutlet="modalHeader"></ng-container>
    <div class="modal-body">
        <p>
        <b>Why are you being asked to fill in this form?</b><br>
        This employer is a government contractor subject to the Vietnam Era Veteran’s Readjustment Assistance Act of 1974, as amended by the Jobs of Veteran’s Act of 2008, 38 U.S.C. 4212 (VEVRAA). VEVRAA requires government contractors to take affirmative action to employ and advance in employment protected veterans. To help us measure the effectiveness of our outreach and recruitment efforts of veterans, we are asking you to tell us if you are a veteran covered by VEVRAA. Completing this form is completely voluntary. Any answer that you will give will be kept private and will not be used against you in any way.<br><br>

        <b>How do you know if you are a veteran protected by VEVRAA?</b><br>
        Contrary to the name, VEVRAA does not just cover the Vietnam Era Veterans. It covers several category of veterans from World War II, the Korean conflict, the Vietnam era and the Persian Gulf War which is defined as occurring from August 2, 1990 to the present.<br><br>

        A “disabled veteran” is one of the following:<br>
        a veteran of the U.S. military, ground, naval or air service, who is entitled to compensation (or who but for the receipt of military retired pay would be entitled of compensation) under laws administered by the Secretary of Veterans Affairs; or<br>
        A person who was discharged or released from active duty because of a service-connected disability.<br><br>

        A “recently separated veteran” any veteran during the three-year period beginning on the date of such veteran’s discharge or release from active duty in the U.S. military, ground, naval or air service.<br><br>

        An “active duty wartime or campaign badge veteran” during the three-year period beginning on the date of such veteran’s discharge or release from active duty in the U.S. military, ground, naval, or air service.<br><br>

        An “Armed Forces service medal veteran” means a veteran who, while on service on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985.
        </p>
    </div>
    <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
</ng-template>
<ng-template #modalHeader>
    <div class="modal-header">
        <h1 class="modal-title h1">Information</h1>
        <borderless-button
            (onClick)="modalRef.hide()"
            aria-label="Close"
            color="dark"
            icon="close"
        ></borderless-button>
    </div>
</ng-template>
<ng-template #modalFooter>
    <div class="modal-footer">
        <general-button
            (onClick)="modalRef.hide()"
            backgroundStyle="outline"
            text="Close"
        ></general-button>
    </div>
</ng-template>
