export enum FilterTypes {
  Number = 'number',
  Text = 'text',
  Date = 'date',
  Enum = 'enum',
  Range = 'range',
  AmeTypeLicense = 'AmeTypeLicense',
  BreakLine = 'breakLine',
  Boolean = 'boolean'
}
