<div class="conversation-info-mobile">
    <span (click)="handleClose()" class="material-icons-outlined close-icon">
        close
    </span>
    <img
        *ngIf="!partnerProfilePicError"
        [src]="conversation?._links?.partnerProfilePicture?.href"
        (error)="partnerProfilePicError = true"
        alt="Partner profile picture"
    />
    <div *ngIf="partnerProfilePicError" class="default-avatar">
        <i class="material-icons"> person </i>
    </div>
    <div class="partner-info">
        <span class="partner-name">
            {{ partnerFirstName }}
            {{ partnerLastName }}
        </span>
        <p class="working-title">{{ conversation.title || '' }}</p>
    </div>
</div>

<div *ngIf="conversation?.partnerOutOfOffice" class="out-of-office-banner">
    {{ partnerFirstName }}
    {{ partnerLastName }}
    is Out of the Office and may not respond.
</div>
