<h1 class="h1">Set reference number</h1>
<div class="form-group">
    <label for="ref-number">
        Ref. number
    </label>
    <input
        [(ngModel)]="refNumber"
        [maxlength]="LENGTH"
        class="form-control"
        id="ref-number"
        placeholder="Write a reference number here"
        type="text"
    />
    <p class="important">
        <strong>IMPORTANT</strong>: The reference number must contain exactly 7 digits.
    </p>
</div>
<div>
    <general-button
        (onClick)="handleRefNumberChange()"
        [isDisabled]="isLoading || !refNumberHasChanged || !refNumberHasRequiredLength"
        [isLoading]="isLoading"
        text="Change ref. number"
    ></general-button>
    <general-button
        (onClick)="handleRefNumberDeletion()"
        [isDisabled]="isLoading || userProfile.refNumber == null"
        [isLoading]="isLoading"
        text="Delete"
    ></general-button>
</div>
