export enum StartDatePhase {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  NOT_INITIATED = 'NOT_INITIATED',
  PROPOSED_BY_MRO = 'PROPOSED_BY_MRO',
  PROPOSED_BY_AGENCY = 'PROPOSED_BY_AGENCY',
  PROPOSED_BY_TECHNICIAN = 'PROPOSED_BY_TECHNICIAN'
}

export class StartDatePhaseUtils {
  private static phaseCheck = (phases: Array<StartDatePhase>) => (phase: StartDatePhase) => phases.includes(phase);

  public static isAccepted = StartDatePhaseUtils.phaseCheck([StartDatePhase.ACCEPTED]);

  public static isProposed = StartDatePhaseUtils.phaseCheck([
    StartDatePhase.PROPOSED_BY_AGENCY,
    StartDatePhase.PROPOSED_BY_MRO,
    StartDatePhase.PROPOSED_BY_TECHNICIAN
  ]);

  public static isPendingOrUnset = StartDatePhaseUtils.phaseCheck([
    StartDatePhase.PENDING,
    StartDatePhase.NOT_INITIATED
  ]);

  public static isProposedByAgency = StartDatePhaseUtils.phaseCheck([StartDatePhase.PROPOSED_BY_AGENCY]);

  public static isProposedByMRO = StartDatePhaseUtils.phaseCheck([StartDatePhase.PROPOSED_BY_MRO]);

  public static isProposedByTechnician = StartDatePhaseUtils.phaseCheck([StartDatePhase.PROPOSED_BY_TECHNICIAN]);
}
