import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { ModalService } from '@libs/common-ui/services/modal.service';
import {
  DisableExperienceLetter,
  EnableExperienceLetter
} from '../../state/request-overview.actions';
import { Contract } from '@libs/shared/models/contract.model';
import { selectContract } from '../../state/request-overview.selectors';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';

@Component({
  selector: 'experience-letter-edition',
  templateUrl: './experience-letter-edition.component.html',
  styleUrls: ['./experience-letter-edition.component.scss']
})
export class ExperienceLetterEditionComponent implements OnInit, OnDestroy {
  public contract: Contract = null;
  private subscriptions = new Subscription();

  constructor(
    public store: Store,
    public actions: Actions,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.store
        .select(selectContract)
        .pipe(tap(contract => (this.contract = contract as Contract)))
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get isExperienceLetterAllowed(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ChangeExperienceLetter);
  }

  public changeExperienceLetter(): void {
    this.modalService.openExperienceLetterQuestionModal(
      () => this.enableExperienceLetter(),
      () => this.disableExperienceLetter()
    );
  }

  private enableExperienceLetter() {
    this.store.dispatch(EnableExperienceLetter({ contract: this.contract }));
  }

  private disableExperienceLetter() {
    this.store.dispatch(DisableExperienceLetter({ contract: this.contract }));
  }
}
