import { isEmpty } from 'lodash-es';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { PendingReason, RejectReason } from '@libs/shared/models/reasons.model';
import { PendingReasonType, RejectReasonType } from '@libs/shared/models/reasons-types.enum';
import { StartDatePhase } from '@libs/shared/models/start-date-phase.model';
import { getEmbeddedResource, hasEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { ContractLinkRel } from '@libs/shared/linkrels/contract.linkrel';
import { EndDateExtensionPhase } from '@libs/shared/models/end-date-extension-phase.enum';

export enum CustomIconCode {
  ICON_000 = 'ICON_000'
}

export interface Contract extends Resource {
  id: number;
  isSeen: boolean;
  isExpired?: boolean;
  isFinished?: boolean;
  endDate?: string;
  startDate: string;
  provisionalEndDate?: string;
  phase: string;
  mroUserUuid: string;
  agencyUserUuid: string;
  acceptanceNotes: string;
  technicianUserUuid: string;
  agencyTimerStartedOn: string;
  technicianTitles: string[];
  mroQualityStatusByUserUuid?: string;
  mroProductionStatusByUserUuid?: string;
  customMroStatusIcon?: CustomIconCode;
  customMroQualityStatusIcon?: CustomIconCode;
  customMroHumanResourcesStatusIcon?: CustomIconCode;
  mroStatus: ContractStatus;
  agencyStatus: ContractStatus;
  mroQualityStatus: ContractStatus;
  mroHumanResourcesStatus: ContractStatus;
  technicianStatus: ContractStatus;
  rejectedReasons: RejectReason[];
  pendingReasons: PendingReason[];
  technicianWantsExperienceLetter: boolean;
  startDatePhase: StartDatePhase;
  extensionPhase: EndDateExtensionPhase;
  requiresProductionAcceptance: boolean;
  requiresQualityAcceptance: boolean;
  requiresHumanResourcesAcceptance: boolean;
  requiresAgencyAcceptance: boolean;
  doesUserHaveAccessToTechnician: boolean;
}

export interface AgencyContractDto extends Contract {
  agencyDeclineDateLimit: string;
}

export function addUtils(contract: Contract): ContractWithUtils {
  return {
    ...contract,
    hasRejectedReasons(type?: RejectReasonType): boolean {
      if (type) {
        return !isEmpty(this.getRejectedReasons(type));
      }
      return !isEmpty(this.rejectedReasons);
    },
    hasPendingReasons(): boolean {
      return !isEmpty(this.pendingReasons);
    },
    getRejectedReason(type: RejectReasonType): RejectReason {
      return this.rejectedReasons?.find(reason => reason.type === type);
    },
    getPendingReason(): PendingReason {
      return this.pendingReasons?.find(reason => reason.type === PendingReasonType.DOCUMENTS_NEEDED);
    },
    getRejectedReasons(type: RejectReasonType): RejectReason[] {
      return this.rejectedReasons?.filter(reason => reason.type === type);
    },
    getQualityRejectedReasons(): RejectReason[] {
      return this.getRejectedReasons(RejectReasonType.QUALITY_REASON).concat(
        this.getRejectedReasons(RejectReasonType.MISSING_DOCUMENTS)
      );
    },
    getProductionReasons(): RejectReason[] {
      return this.getRejectedReasons(RejectReasonType.PRODUCTION_REASON).concat(
        this.getRejectedReasons(RejectReasonType.OFFER_CLOSED)
      );
    },
    getAgencyRejectedReasons(): RejectReason[] {
      return this.getRejectedReasons(RejectReasonType.AGENCY_REASON);
    },
    hasProductionReasons(): boolean {
      return (
        this.hasRejectedReasons(RejectReasonType.PRODUCTION_REASON) ||
        this.hasRejectedReasons(RejectReasonType.OFFER_CLOSED)
      );
    },
    hasAgencyRejectedReasons(): boolean {
      return this.hasRejectedReasons(RejectReasonType.AGENCY_REASON);
    },
    hasQualityRejectedReasons(): boolean {
      return (
        this.hasRejectedReasons(RejectReasonType.QUALITY_REASON) ||
        this.hasRejectedReasons(RejectReasonType.MISSING_DOCUMENTS)
      );
    },
    hasAcceptanceNotes(): boolean {
      return hasEmbeddedResource(this, ContractLinkRel.AcceptanceNotes);
    },
    getAcceptanceNotes(): string {
      return getEmbeddedResource(this, ContractLinkRel.AcceptanceNotes);
    }
  };
}

export interface ContractWithUtils extends Contract {
  hasAgencyRejectedReasons: () => boolean;
  hasQualityRejectedReasons: () => boolean;
  hasProductionReasons: () => boolean;
  hasRejectedReasons: (type?: RejectReasonType) => boolean;
  getRejectedReason: (type: RejectReasonType) => RejectReason;
  getRejectedReasons: (type: RejectReasonType) => RejectReason[];
  getAgencyRejectedReasons: () => RejectReason[];
  getQualityRejectedReasons: () => RejectReason[];
  getProductionReasons: () => RejectReason[];
  hasPendingReasons: () => boolean;
  getPendingReason: () => PendingReason;
  hasAcceptanceNotes: () => boolean;
  getAcceptanceNotes: () => string;
}
