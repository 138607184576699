<div class="manage-package-offer-files">
    <div class="mb-5">
        <general-button
            text="Download all documents"
            [isLoading]="isDownloadingAllDocuments"
            [isDisabled]="areFilesEmpty || isDownloadingAllDocuments"
            (onClick)="downloadAllDocuments()"
        ></general-button>
    </div>
    <div class="mb-5">
        <div>
            <staffnow-file-upload-2
                [canEdit]="canEdit"
                [documents]="packageOfferFiles"
                (fileDeleted)="removePackageOfferFile($event)"
                (uploadSucceeded)="onSuccessfulFileUpload()"
                (downloaded)="downloadPackageOfferFile($event)"
                [uploadUrl]="uploadPackageOfferFileUrl"
                documentName="Attachments"
                [allowedFileTypes]="allowedFileTypes"
                [maximumFilesLimit]="20"
                [multiple]="true"
                noDocumentsUploadedDisclaimer="No documents were uploaded to this section yet."
                fileListHeight="inherit"
                tooltip="Here you can upload files related to the fixed-price job opening, so that agencies can see them"
            >
            </staffnow-file-upload-2>
        </div>
    </div>
</div>
