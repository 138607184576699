import { CountrySelectorStateModel, EnrichedCountry } from '@libs/common-ui/country/state/country-selector.state.model';
import { createReducer, on } from '@ngrx/store';
import { LoadCountry, StatesLoaded } from '@libs/common-ui/country/state/country-selector.actions';

export const initialState: CountrySelectorStateModel = {
  countries: []
};

export const countrySelectorReducer = createReducer(
  initialState,
  on(LoadCountry, (state, { country }) => {
    const countries = [...state.countries];
    insertNewCountryIfNotCached(countries, { ...country, states: [] });
    return { ...state, countries: countries };
  }),
  on(StatesLoaded, (state, { states, country }) => {
    const countries = [...state.countries];
    updateCountryStates(countries, { ...country, states });
    return { ...state, countries: countries };
  })
);

const insertNewCountryIfNotCached = (countries: EnrichedCountry[], countryToInsert: EnrichedCountry): void => {
  const selectedCountry = countries?.find(country => country.countryCode === countryToInsert.countryCode);
  if (!selectedCountry) {
    countries.push(countryToInsert);
  }
};

const updateCountryStates = (countries: EnrichedCountry[], countryToInsert: EnrichedCountry): void => {
  const selectedCountryIndex = countries?.findIndex(country => country.countryCode === countryToInsert.countryCode);
  if (selectedCountryIndex != -1) {
    countries[selectedCountryIndex] = countryToInsert;
  } else {
    insertNewCountryIfNotCached(countries, countryToInsert);
  }
};
