<h1 class="h1">Markups</h1>
<div class="guidelines" [innerHTML]="'MARKUPS.GUIDELINES' | translate">
</div>
<div class="mb-3 markup-mobile">
    <label for="markup">{{ 'MARKUPS.DEFAULT' | translate }}</label>
    <div class="input-group markup-value">
        <input
            id="markup"
            type="number"
            min="0"
            max="100"
            class="form-control default-markup"
            step="0.02"
            placeholder="0-100"
            [(ngModel)]="facilityProfile.markUp"
            (ngModelChange)="markupChanged()"
        />
        <div class="input-group-append">
            <span class="input-group-text">%</span>
        </div>
    </div>
</div>
<div class="specific-markups-container">
    <label for="markupSettings">{{ 'MARKUPS.SPECIFIC' | translate }}</label>
    <div id="markupSettings" class="markup-settings">
        <div id="markupMro" class="setting">
            <ng-select
                [items]="mroApprovals$ | async"
                [(ngModel)]="newSpecificMarkup.mro"
                [ngModelOptions]="{ standalone: true }"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="name"
                [placeholder]="'REQUEST_LISTING.SELECT_AVIATION_COMPANY' | translate"
            ></ng-select>
        </div>
        <div id="markupTypeLevel" class="setting">
            <ng-select
                [items]="ameTitles"
                [(ngModel)]="newSpecificMarkup.ameTitle"
                [ngModelOptions]="{ standalone: true }"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [placeholder]="'TITLES.SELECT_TECHNICIAN' | translate"
            ></ng-select>
        </div>
        <div>
            <div class="input-group markup-value">
                <input
                    type="number"
                    class="form-control"
                    placeholder="0-100"
                    [(ngModel)]="newSpecificMarkup.markup"
                    (ngModelChange)="validateEntry(newSpecificMarkup)"
                />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
        </div>
        <general-button
            [isDisabled]="!canAddNewMarkupEntry()"
            (onClick)="addNewMarkupEntry()"
            class="add-button"
            [text]="'GENERAL.ADD' | translate"
        ></general-button>
    </div>
    <div class="new-markup-entities">
        <ng-container *ngFor="let mroName of specificMarkupsMros">
            <div class="markup-entity">
                <div class="markup-name">
                    <span class="mro-name">{{ mroName }}</span>
                </div>
                <div
                    class="markup-list"
                    *ngFor="let markupEntry of getMarkupsForMro(mroName)"
                >
                    <div class="markup-name">
                        <span>{{ markupEntry.ameTitle?.label }}</span>
                    </div>
                    <div class="markup-actions">
                        <div class="input-group markup-value">
                            <input
                                type="number"
                                class="form-control"
                                placeholder="0-100"
                                [(ngModel)]="markupEntry.markup"
                                (ngModelChange)="
                                    validateMarkupInEntries(markupEntry)
                                "
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <borderless-button
                            (onClick)="deleteEntry(markupEntry)"
                            color="green"
                            icon="delete"
                            actionType="danger"
                        ></borderless-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
