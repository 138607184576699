import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { GetOwnTechniciansListing } from '../../state/agency-request-overview-wrapper.actions';
import {
  AgenciesMainState,
  STATE_FEATURE_KEY,
  TechnicianListingState
} from '../../state/agency-request-overview-wrapper.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { identity, isEmpty, isNil, omitBy, pickBy } from 'lodash-es';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';
import { FilterTypes } from '@libs/staff-filters/constants/FilterTypes';
import { UsersService } from '@libs/shared/services/users.service';
import { getAirplanesSortedByName, getSkillsSortedByName } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { TechnicianProfileModel, UserProfile } from '@libs/shared/models/technician-profile.model';
import { Skill } from '@libs/shared/models/skills.model';
import { Aircraft } from '@libs/shared/models/aircraft.model';
import { UsersFilterValueModel } from '../../../../../../../back-office/src/app/modules/users/state/users-state.model';

interface SearchFilters {
  term: string;
  ameType: string;
  ameLicense: string;
  aircraft: Aircraft[];
  skills: Skill[];
}

interface SearchValues {
  term: string;
  ameTypeLicense: {
    ameType: string;
    ameLicense: string;
  };
  aircraft: Aircraft[];
  skills: Skill[];
}

const SKILLS: string = 'skills';
const AIRCRAFT: string = 'aircraft';
const AME_TYPE_LICENSE: string = 'ameTypeLicense';

@Component({
  selector: 'staffnow-own-technicians',
  templateUrl: './own-technicians.component.html',
  styleUrls: ['./own-technicians.component.scss']
})
export class OwnTechniciansComponent implements OnInit, OnDestroy {
  public usersList: TechnicianProfileModel[];
  public currentPage: number = 0;
  public pageSize: number;
  public isLoading: boolean = false;
  private subs: Subscription[] = [];
  public totalElements: number;
  public filtersConfig: FilterConfigModel[] = [
    {
      type: FilterTypes.Text,
      key: 'term',
      className: 'term-filter',
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.NAME_EMAIL.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.NAME_EMAIL.PLACEHOLDER'
    },
    {
      type: FilterTypes.AmeTypeLicense,
      key: AME_TYPE_LICENSE,
      initialValue: {}
    },
    {
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.AIRCRAFT.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.AIRCRAFT.PLACEHOLDER',
      key: AIRCRAFT,
      className: 'aircrafts-filter',
      type: FilterTypes.Enum,
      multiple: true,
      labelForId: 'id',
      bindLabel: 'name'
    },
    {
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.SKILLS.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.SKILLS.PLACEHOLDER',
      key: SKILLS,
      className: 'skills-filter',
      type: FilterTypes.Enum,
      multiple: true,
      labelForId: 'id',
      bindLabel: 'name'
    }
  ];

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService
  ) {
    this.subs.push(
      this.store.pipe(getAirplanesSortedByName, take(1)).subscribe(aircraftList => {
        this.filtersConfig.find(obj => obj.key === AIRCRAFT).items = aircraftList;
      })
    );
    this.subs.push(
      this.store.pipe(getSkillsSortedByName, take(1)).subscribe((skillsList: Skill[]) => {
        this.filtersConfig.find(obj => obj.key === SKILLS).items = skillsList;
      })
    );
    this.subs.push(
      this.store
        .pipe(select(state => (state[STATE_FEATURE_KEY] as AgenciesMainState).ownTechniciansListing))
        .subscribe((state: TechnicianListingState) => {
          if (state !== null) {
            const { page, pageSize, totalElements } = state;
            this.usersList = getEmbeddedResource(state, 'results');
            this.currentPage = page + 1;
            this.pageSize = pageSize;
            this.totalElements = totalElements;
          }
        })
    );
  }

  public ngOnInit() {
    this.watchQueryParams();
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private watchQueryParams(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
        const filters: SearchFilters = omitBy(this.parseQueryParamsToFiltersObject(params), isNil) as SearchFilters;
        this.setFiltersValues(filters);
        this.currentPage = Number(params.page) + 1 || 1;
        this.store.dispatch(new GetOwnTechniciansListing(filters, this.currentPage - 1));
      })
    );
  }

  private parseQueryParamsToFiltersObject(params: Params): SearchFilters {
    return {
      term: params.term as string,
      ameType: params.ameType as string,
      ameLicense: params.ameLicense as string,
      aircraft: this.usersService.convertAircraftStringToArray(params.aircraft as string),
      skills: this.convertSkillStringToArray(params.skills as string)
    };
  }

  private setFiltersValues(filters: SearchFilters): void {
    this.filtersConfig.forEach(config => {
      config.initialValue = filters[config.key] || null;
    });
    if (!isEmpty(filters.aircraft)) {
      const ids = filters.aircraft.map(aircraft => aircraft.id);
      const aircraftConfig = this.filtersConfig.find(config => config.key === AIRCRAFT);
      aircraftConfig.initialValue = aircraftConfig.items.filter((aircraft: Aircraft) => ids.includes(aircraft.id));
    }
    const ameTitleConfig = this.filtersConfig.find(config => config.key === AME_TYPE_LICENSE);
    if (ameTitleConfig) {
      ameTitleConfig.initialValue = filters.ameType
        ? { ameType: filters.ameType, ameLicense: filters.ameLicense || null }
        : {};
    }
  }

  public searchUsers(newValues: SearchValues): void {
    this.router.navigate([], {
      queryParams: {
        ...this.usersService.createFilterValueObject(this.prepareFilter(newValues), null, this.currentPage - 1)
      }
    });
  }

  public clearFilters(): void {
    this.router.navigate([], { queryParams: {} });
  }

  private prepareFilter(values: SearchValues): UsersFilterValueModel {
    const result = {
      ...values,
      ameType: values.ameTypeLicense?.ameType,
      ameLicense: values.ameTypeLicense?.ameLicense,
      aircraft: values.aircraft,
      skills: values.skills
    };
    delete result.ameTypeLicense;
    return pickBy(result, identity) as unknown as UsersFilterValueModel;
  }

  public pageChanged($event: { page: number; itemsPerPage: number }): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: { page: $event.page - 1 }
    });
  }

  public openProfile(technician: TechnicianProfileModel): void {
    const profile: UserProfile = getEmbeddedResource(technician, 'profile');
    this.router.navigate([`../profile/${profile.userUuid}`], {
      relativeTo: this.activatedRoute
    });
  }

  private convertSkillStringToArray(skillsString: string): Skill[] {
    const ids = skillsString?.split(',').map(id => Number(id));
    const skills: Skill[] = this.filtersConfig.find(config => config.key === SKILLS).items as Skill[];
    const filteredSkills = skills?.filter(skill => ids?.includes(skill.id));
    return filteredSkills || null;
  }
}
