@if (needsCornerCard) {
<corner-card [type]="cornerCardType"> </corner-card>
} @if (privateOffer) {
<card-tab-text [text]="'OFFER_DETAILS.PRIVATE_JOB_OPENING' | translate"></card-tab-text>
}
<div class="offer-card-head">
    @if (needsCornerCard) {
    <div [class]="['offer-alert', offerAlertClass]">
        {{ offerAlertText | translate }}
    </div>
    }
    <h3 class="h3">
        {{ facility?.name }}
    </h3>
    <h3 class="h3">{{ offer.refNumber }}</h3>
    <h3 class="h3">{{ offer.title }}</h3>
    @if (offer?.priority) {
    <staffnow-offer-priority-info [priority]="offer?.priority"></staffnow-offer-priority-info>
    }
</div>
<div class="offer-card-body">
    <staffnow-contract-info [icon]="'location_on'">
        {{ offer?.location.name }}
    </staffnow-contract-info>
    <staffnow-contract-info [icon]="'date_range'">
        {{ offer?.periodFrom | absoluteDateFormat }}
        -
        {{ offer?.periodTo | absoluteDateFormat }}
    </staffnow-contract-info>
    <ng-container *ngIf="isTemporaryJobOpening || isAgencyJobOpening">
        <staffnow-contract-info [icon]="'airplanemode_active'" [overflowElipsis]="true">
            <staffnow-offer-aircrafts-field [offer]="offerOutDto()"></staffnow-offer-aircrafts-field>
        </staffnow-contract-info>
        <staffnow-contract-info [icon]="'build'" [overflowElipsis]="true">
            {{ offer.ameTitle | ameTitleName }}
        </staffnow-contract-info>
        <staffnow-contract-info [icon]="'person'">
            {{'REQUEST_CARD.OPEN_POSITIONS' | translate: {count: offer?.vacancies} }}
        </staffnow-contract-info>
        @if (!offer.hasHiddenRate) { @if (isTemporaryJobOpening) {
        <staffnow-contract-info [iconTooltip]="'SYSTEM.INFO.CHARGE_RATE_TOOLTIP' | translate" [icon]="'money'">
            @if (hasIndicativeRateForOffer(offer)) {
            {{ offer?.chargeRate | rate }}
            {{ ' - ' }}
            {{ offer?.maxChargeRate | rate }}
            {{ ' per hour' | translate }}
            } @else {
            {{ offer?.chargeRate | rate }}
            {{ ' per hour' | translate }}
            }
        </staffnow-contract-info>
        }
        <staffnow-contract-info [icon]="'money'" [iconTooltip]="'SYSTEM.INFO.PAY_RATE_TOOLTIP' | translate">
            @if (hasIndicativeRateForOffer(offer)) { @if (isAgencyJobOpening) {
            {{ offer?.payRate | rate }}
            {{ ' - ' }}
            {{ offer?.maxPayRate | rate }}
            {{ ' per hour' | translate }}
            } @else {
            {{ offer?.minPayRate | rate }}
            {{ ' - ' }}
            {{ offer?.maxPayRate | rate }}
            {{ ' per hour' | translate }}
            } } @else {
            {{ offer?.payRate | rate }}
            {{ ' per hour' | translate }}
            }
        </staffnow-contract-info>
        }
    </ng-container>
</div>
<div class="open-contracts">
    <button class="btn btn-primary" (click)="openProcess()">
        {{ 'GENERAL.VIEW_DETAILS' | translate }}
    </button>
</div>
