import { Component, Input } from '@angular/core';
import { Reminder } from '@libs/common-ui/models/reminder.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-number-picker-dialog',
  templateUrl: './number-picker-dialog.component.html',
  styleUrls: ['./number-picker-dialog.component.scss']
})
export class NumberPickerDialogComponent {
  @Input() public onAcceptCallback: Function;
  @Input() public title: string = null;
  @Input() public placeholder: string = null;
  @Input() public warningMessage: string = null;
  @Input() public reminders: Array<Reminder> = [];
  public value: number = 0;

  constructor(public bsModalRef: BsModalRef) {}

  public submit() {
    this.onAcceptCallback(this.value);
    this.close();
  }

  public close() {
    this.bsModalRef.hide();
  }

  public shouldDisable(): boolean {
    return isNaN(this.value) || this.value <= 0;
  }
}
