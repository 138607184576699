import { InjectionToken } from '@angular/core';
import { ActionReducerMap, Action } from '@ngrx/store';
import { feedbackFormReducer } from '../components/feedback-form/feedback-form.reducer';
import { FormGroupState } from 'ngrx-forms';
import {
  FeedbackFormModel,
  initialFormState
} from '../components/feedback-form/feedback-form.model';
import { FeedbackActions } from './feedback.actions';

export const FEEDBACK_STATE_KEY = 'feedback';

export interface FeedbackState {
  feedbackForm: FormGroupState<FeedbackFormModel>;
  feedbackList: Array<any>;
}

export const feedbackInitialState = {
  feedbackForm: initialFormState,
  feedbackList: []
};

export const feedbackListReducer = (state: Array<any>, action: Action) => {
  switch (action.type) {
    case FeedbackActions.FeedbackListLoaded:
      return action['feedbackList'];
  }
  return state;
};

export function getFeedbackReducers(): ActionReducerMap<FeedbackState> {
  return {
    feedbackForm: feedbackFormReducer,
    feedbackList: feedbackListReducer
  };
}

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<FeedbackState>
>('[Feedback] Registered Reducers');
