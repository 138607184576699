import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { FilterConfigModel } from './models/filter-config.model';
import { isArray, isDate, isEmpty, isNil, isObject, pickBy } from 'lodash-es';
import { FilterTypes } from './constants/FilterTypes';
import { AbstractFilterChangedValue } from '@libs/staff-filters/components/abstract-filter/abstract-filter.component';
import { UsersFilterValueModel } from 'apps/back-office/src/app/modules/users/state/users-state.model';

@Component({
  selector: 'staff-filters',
  templateUrl: './staff-filters.component.html',
  styleUrls: ['./staff-filters.component.scss']
})
export class StaffFiltersComponent implements OnChanges {
  @Input() public disableFilters: boolean = false;
  @Input() public filterListConfig: Array<FilterConfigModel> = [];

  @Output() public clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() search: EventEmitter<object | UsersFilterValueModel> = new EventEmitter();
  @Output() enterPressed: EventEmitter<object | UsersFilterValueModel> = new EventEmitter();

  public FILTER_TYPES = FilterTypes;
  private filterValue: object = {};
  public error: boolean = false;
  private errorMessage: string = 'Unsuccessful configuration of filters';

  ngOnChanges() {
    this.generateInitialFilterValue();
  }

  public onChangeHandler(updatedValue: AbstractFilterChangedValue): void {
    if (updatedValue.filter?.key) {
      this.filterValue[updatedValue.filter.key] = updatedValue.value;
    }
  }

  public isEnterPressed(): void {
    this.enterPressed.emit({ ...this.filterValue });
  }

  private generateInitialFilterValue(): void {
    const filterValue = {};
    try {
      if (this.filterListConfig && this.filterListConfig.length > 0) {
        this.filterListConfig.forEach(filter => {
          if (filter.key) {
            filter.disabled = this.disableFilters;
            filterValue[filter.key] = filter.initialValue || null;
          } else {
            if (filter.type !== this.FILTER_TYPES.BreakLine) {
              throw new Error(this.errorMessage);
            }
          }
        });
      } else {
        throw new Error(this.errorMessage);
      }
      this.filterValue = filterValue;
    } catch (error) {
      this.error = true;
      console.error(error);
    }
  }

  public doSearch(): void {
    this.search.emit({ ...this.filterValue });
  }

  public clearSearch(): void {
    this.filterListConfig = this.filterListConfig.map(config => ({ ...config, initialValue: null }));
    this.filterValue = {};
    this.clear.emit();
  }

  public isFilterEmpty() {
    return isEmpty(
      pickBy(
        this.filterValue,
        f => ((isArray(f) || isObject(f)) && !isEmpty(f)) || isDate(f) || (!isArray(f) && !isObject(f) && !isNil(f))
      )
    );
  }
}
