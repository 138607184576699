<nav class="staff-tab-navigation" *ngIf="menuItems.length > 0">
    <ul>
        <li
            class="single-tab"
            *ngFor="let item of menuItems"
            (click)="writeValue(item)"
            [ngClass]="{ active: val?.label === item.label }"
        >
            {{ item.label | translate }}
        </li>
    </ul>
</nav>
