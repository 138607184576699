import { InjectionToken } from '@angular/core';
import { Action, ActionReducerMap } from '@ngrx/store';
import { AgenciesMainState } from './agency-request-overview-wrapper.model';
import { AgencyRequestOverviewActionTypes } from './agency-request-overview-wrapper.actions';

export function getReducers(): ActionReducerMap<AgenciesMainState> {
  return {
    ownTechnicians: ownTechniciansReducer,
    ownTechniciansListing: ownTechniciansListingReducer
  };
}

export const ownTechniciansReducer = (state: any, action: Action) => {
  switch (action.type) {
    case AgencyRequestOverviewActionTypes.OwnTechniciansLoaded:
      return action['ownTechniciansList'];
  }
  return state;
};

export const ownTechniciansListingReducer = (state: any, action: Action) => {
  switch (action.type) {
    case AgencyRequestOverviewActionTypes.OwnTechniciansListingLoaded:
      return action['payload'];
  }
  return state;
};

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<AgenciesMainState>
>('[Agency] Registered Reducers');
