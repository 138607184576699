<div [class]="'header ' + brandName?.toLowerCase() ">
    <div class="logo-container">
    </div>
    @if (brandName === BrandName.StaffNow) {
        <div class="buttons-container">
            <general-button
                (onClick)="goToContactForTechniciansPage()"
                backgroundStyle="outline"
                color="black-white"
                lowerCase="true"
                class="reg-btn"
                [text]="'GENERAL.CONTACT_FOR_TECHNICIANS' | translate"
            ></general-button>
            <general-button
                (onClick)="goToContactForCompaniesPage()"
                backgroundStyle="outline"
                lowerCase="true"
                color="black-white"
                class="reg-btn"
                [text]="'GENERAL.CONTACT_FOR_COMPANIES' | translate"
            ></general-button>
        </div>
    }
</div>
