import {Pipe, PipeTransform} from '@angular/core';
import {DATE_FORMAT_OPTIONS, TIME_FORMAT_OPTIONS} from "@libs/shared/constants/date-format.constants";
import {DateTime, DateTimeFormatOptions} from "luxon";

@Pipe({name: 'messageDate'})
export class MessageDatePipe implements PipeTransform {
  /*
  * The aim of this pipe is to display a UTC timestamp as local date-time.
  * If the message was received in the current day, the "day-month-year" info is not displayed.
  * */
  transform(value: any, args?: any): any {
    const dateTime = DateTime.fromMillis(value);
    const formatOpts: DateTimeFormatOptions = {
      hour: TIME_FORMAT_OPTIONS.hour,
      minute: TIME_FORMAT_OPTIONS.minute
    };
    if (!MessageDatePipe.isToday(dateTime)) {
      formatOpts.day = DATE_FORMAT_OPTIONS.day;
      formatOpts.year = DATE_FORMAT_OPTIONS.year;
      formatOpts.month = DATE_FORMAT_OPTIONS.month;
    }
    return dateTime.toLocaleString(formatOpts);
  }

  private static isToday(date: DateTime): boolean {
    const today = DateTime.now();
    return date.hasSame(today, "day") && date.hasSame(today, "month") && date.hasSame(today, "year");
  }
}
