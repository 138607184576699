import {UserRoles} from '@libs/shared/models/roles.enum';

export const MRO_ROLES = [
  {name: 'PRODUCTION', serverName: UserRoles.ROLE_MRO_PRODUCTION},
  {name: 'QUALITY', serverName: UserRoles.ROLE_MRO_QUALITY},
  {name: 'CENTRAL QUALITY', serverName: UserRoles.ROLE_MRO_CENTRAL_QUALITY},
  {name: 'HUMAN RESOURCES', serverName: UserRoles.ROLE_MRO_HUMAN_RESOURCES}
];
const ADMIN_ROLE = {name: 'ADMIN', serverName: UserRoles.ROLE_ADMIN};
const MODERATOR_ROLE = {name: 'MODERATOR', serverName: UserRoles.ROLE_MODERATOR};
export const ALL_ADMIN_ROLES = [ADMIN_ROLE, MODERATOR_ROLE];
export const BO_CREATE_USER_ROLES = [
  ADMIN_ROLE,
  MODERATOR_ROLE,
  {name: 'STAFFIE', serverName: UserRoles.ROLE_TECHNICIAN},
  {name: 'AGENCY TECHNICIAN', serverName: UserRoles.ROLE_AGENCY_TECHNICIAN}
];
