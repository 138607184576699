import { Action, createAction, props } from '@ngrx/store';

import { Conversation, Message } from './mailbox-state.model';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';

export enum MailboxActions {
  LoadConversations = '[Mailbox] Load conversations',
  UpdateConversations = '[Mailbox] Update conversations list',
  ConversationsUpdated = '[Mailbox] Conversations updated',
  ConversationListLoaded = '[Mailbox] Conversation list loaded',
  FailedToLoadConversations = '[Mailbox] Conversation list failed to load',
  LoadMessages = '[Mailbox] Load messages',
  FailedToLoadMessages = '[Mailbox] Failed to load messages',
  MessagesLoaded = '[Mailbox] Messages loaded',
  LoadConversation = '[Mailbox] Load conversation',
  LoadNextPage = '[Mailbox] Load next page',
  NextPageLoaded = '[Mailbox] Next page loaded',
  FailedToLoadNextPage = '[Mailbox] Failed to load next page',
  SetCurrentPageNumber = '[Mailbox] Set current page number',
  ConversationLoaded = '[Mailbox] Conversation loaded',
  FailedToLoadConversation = '[Mailbox] Conversation failed to load',
  SendMessage = '[Mailbox] Send new message',
  MessageSent = '[Mailbox] Message successfully sent',
  FailedToSendMessage = '[Mailbox] Failed to send message',
  CreateConversation = '[Mailbox] Create new conversation',
  ConversationCreated = '[Mailbox] Conversation successfully created',
  FailedToCreateConversation = '[Mailbox] Failed to create conversation',
  ResetMailboxState = '[Mailbox] Reset Mailbox State',
  DownloadConversationFile = '[Mailbox] Download Conversation File',
  SuccessfulConversationFileDownload = '[Mailbox] Successful Conversation File Download',
  PromptForDeleteConversationMessage = '[Mailbox] Prompt for Delete Conversation Message',
  DeleteConversationMessage = '[Mailbox] Delete Conversation Message',
  SuccessfulConversationMessageDelete = '[Mailbox] Successful Conversation Message Delete'
}

export const SetCurrentPageNumber = createAction(
  MailboxActions.SetCurrentPageNumber,
  props<{ payload: number }>()
);
export const LoadConversations = createAction(MailboxActions.LoadConversations);

export const UpdateConversations = createAction(
  MailboxActions.UpdateConversations,
  props<{ lastUpdatedSince: number }>()
);

export const ConversationsUpdated = createAction(
  MailboxActions.ConversationsUpdated,
  props<{ updatedConversations: Conversation[] }>()
);

export class LoadNextPage implements Action {
  public readonly type = MailboxActions.LoadNextPage;
  public readonly lastUpdatedSince: number = null;

  constructor(lastUpdatedSince: number) {
    this.lastUpdatedSince = lastUpdatedSince;
  }
}

export const NextPageLoaded = createAction(
  MailboxActions.NextPageLoaded,
  props<{
    conversations: Conversation[];
    updatedConversations: Conversation[];
  }>()
);

export class FailedToLoadNextPage implements Action {
  public readonly type = MailboxActions.FailedToLoadNextPage;
}

export class ResetMailboxState implements Action {
  public readonly type = MailboxActions.ResetMailboxState;
}

export const ConversationListLoaded = createAction(
  MailboxActions.ConversationListLoaded,
  props<{ conversationList: Conversation[] }>()
);

export const FailedToLoadConversations = createAction(
  MailboxActions.FailedToLoadConversations
);

export class LoadMessages implements Action {
  public readonly type = MailboxActions.LoadMessages;
  public readonly since?: number = null;
  public readonly before?: number = null;
  public readonly conversation?: Resource = null;
  public readonly loadUpdatedMessages: boolean = null;

  constructor(
    conversation?: Resource,
    since?: number,
    before?: number,
    loadUpdatedMessages: boolean = false
  ) {
    this.conversation = conversation;
    this.since = since;
    this.before = before;
    this.loadUpdatedMessages = loadUpdatedMessages;
  }
}

export class FailedToLoadMessages implements Action {
  public readonly type = MailboxActions.FailedToLoadMessages;
}

export class MessagesLoaded implements Action {
  public readonly type: MailboxActions.MessagesLoaded =
    MailboxActions.MessagesLoaded;
  public readonly postConversationMessageTemporaryFileUrl: string;
  public readonly messageList: { _embedded: { messages: Array<Message> } };
  public readonly isMessageListOld: boolean = null;
  public readonly loadUpdatedMessages: boolean;
  public readonly since?: number = null;
  public readonly before?: number = null;

  constructor(
    postConversationMessageTemporaryFileUrl: string,
    messageList: { _embedded: { messages: Array<Message> } },
    loadUpdatedMessages?: boolean,
    since?: number,
    before?: number
  ) {
    this.postConversationMessageTemporaryFileUrl = postConversationMessageTemporaryFileUrl;
    this.messageList = messageList;
    this.loadUpdatedMessages = loadUpdatedMessages;
    this.since = since;
    this.before = before;
    if (before || since) {
      this.isMessageListOld = !!before;
    }
  }
}
export const ResetMessagesList = createAction('[Mailbox] Reset messages list');
export const LoadConversation = createAction(
  MailboxActions.LoadConversation,
  props<{
    conversationId?: number;
    conversationUrl?: string;
  }>()
);
export const ConversationLoaded = createAction(
  MailboxActions.ConversationLoaded,
  props<{ conversation: Conversation }>()
);

export const ResetSelectedConversation = createAction(
  '[Mailbox] Reset selected conversation'
);

export class FailedToLoadConversation implements Action {
  public readonly type = MailboxActions.FailedToLoadConversation;
}

export class SendMessage implements Action {
  public readonly type = MailboxActions.SendMessage;
  public readonly text: string = '';
  public readonly lastMessageTimestamp: number = null;

  constructor(text: string, lastMessageTimestamp: number) {
    this.text = text;
    this.lastMessageTimestamp = lastMessageTimestamp;
  }
}

export class MessageSent implements Action {
  public readonly type = MailboxActions.MessageSent;
}

export class FailedToSendMessage implements Action {
  public readonly type = MailboxActions.FailedToSendMessage;
}

export class CreateConversation implements Action {
  public readonly type = MailboxActions.CreateConversation;
  public readonly otherUserUuid: string = '';
  public readonly contractId: string = null;
  public readonly packageContractId: string = null;
  public readonly agencyOfferContractId: string = null;

  constructor(
    otherUserUuid: string,
    contractId?: string,
    packageContractId?: string,
    agencyOfferContractId?: string
  ) {
    this.otherUserUuid = otherUserUuid;
    this.contractId = contractId;
    this.packageContractId = packageContractId;
    this.agencyOfferContractId = agencyOfferContractId;
  }
}

export class ConversationCreated implements Action {
  public readonly type = MailboxActions.ConversationCreated;
}

export class FailedToCreateConversation implements Action {
  public readonly type = MailboxActions.FailedToCreateConversation;
}

export class DownloadConversationFile implements Action {
  public readonly type = MailboxActions.DownloadConversationFile;
  public readonly filename: string;
  public readonly getConversationMessageTemporaryFileUrl: string;

  constructor(
    filename: string,
    getConversationMessageTemporaryFileUrl: string
  ) {
    this.filename = filename;
    this.getConversationMessageTemporaryFileUrl = getConversationMessageTemporaryFileUrl;
  }
}

export class SuccessfulConversationFileDownload implements Action {
  public readonly type = MailboxActions.SuccessfulConversationFileDownload;
}

export class PromptForDeleteConversationMessage implements Action {
  public readonly type = MailboxActions.PromptForDeleteConversationMessage;
  public readonly deleteConversationMessageUrl: string;

  constructor(deleteConversationMessageUrl: string) {
    this.deleteConversationMessageUrl = deleteConversationMessageUrl;
  }
}

export class DeleteConversationMessage implements Action {
  public readonly type = MailboxActions.DeleteConversationMessage;
  public readonly deleteConversationMessageUrl: string;
  public readonly lastUpdatedMessageTimestamp: number;

  constructor(
    deleteConversationMessageUrl: string,
    lastUpdatedMessageTimestamp: number
  ) {
    this.deleteConversationMessageUrl = deleteConversationMessageUrl;
    this.lastUpdatedMessageTimestamp = lastUpdatedMessageTimestamp;
  }
}

export class SuccessfulConversationMessageDelete implements Action {
  public readonly type = MailboxActions.SuccessfulConversationMessageDelete;
}
