import { isNil } from 'lodash-es';
import {
  AdditionalBenefit,
  BenefitType
} from '@libs/shared/models/additional-benefit.model';

export interface AdditionalBenefitForm extends AdditionalBenefit {
  isSelected: boolean;
}

export class AdditionalBenefitFormUtils {
  public static getDefault(): Array<AdditionalBenefitForm> {
    return [
      BenefitType.PER_DIEMS,
      BenefitType.TRAVELING_COST,
      BenefitType.OVERTIME_RATE,
      BenefitType.DAILY_COMMUTING,
      BenefitType.ACCOMMODATION,
      BenefitType.OTHER
    ].map(type => ({
      type,
      amount: null,
      description: null,
      isSelected: false
    }));
  }

  public static mapToFormObject(benefits: Array<AdditionalBenefit>) {
    return benefits.map(benefit => ({
      ...benefit,
      isSelected: true
    }));
  }

  public static requiresAmount(type: BenefitType): boolean {
    const typesThatRequireAmount = [
      BenefitType.DAILY_COMMUTING,
      BenefitType.TRAVELING_COST,
      BenefitType.OVERTIME_RATE,
      BenefitType.PER_DIEMS
    ];
    return typesThatRequireAmount.includes(type);
  }

  public static isValid(benefit: AdditionalBenefitForm): boolean {
    if (AdditionalBenefitFormUtils.requiresAmount(benefit.type)) {
      return !isNil(benefit.amount);
    }
    return true;
  }
}
