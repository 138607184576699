import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { RoleWrapper } from '@libs/shared/models/roles.enum';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { AbstractRequestCardComponent } from './abstract-request-card.component';

@Component({
  selector: 'staffnow-mro-request-card',
  templateUrl: './mro-request-card.component.html',
  styleUrls: [
    './request-card-common-styles.scss',
    './mro-request-card.component.scss'
  ]
})
export class MroRequestCardComponent
  extends AbstractRequestCardComponent
  implements OnChanges {
  @Input() public userRole: RoleWrapper = null;

  public facility: any = null;

  constructor(store: Store<any>, private router: Router) {
    super(store);
  }

  get closed() {
    return !this.expired && !this.unseen && super.closed;
  }

  ngOnChanges(): void {
    this.facility = getEmbeddedResource(this.offer, JobOfferLinkRel.Facility);
  }

  public isCentralQualityOrHumanResources() {
    return this.userRole?.isHumanResourcesOrCentralQuality();
  }

  public openProcess(): void {
    this.router.navigate([this.getRoute(), this.offer.refNumber]);
  }

  private getRoute(): string {
    if (this.isPackageJobOpening) {
      return 'package-contracts';
    }
    return this.isPermanentJobOpening ? 'permanent-contracts' : 'contracts';
  }
}
