<staffnow-offer-info-section
    *ngIf="!isRejectedByAnyMro && (generalGuidelines || unsetAgencyGuidelines)"
    [title]="'GENERAL.INFORMATION' | translate"
    [icon]="'lightbulb'"
>
    <staffnow-contract-info [displayBlock]="true">
        <span [innerHtml]="generalGuidelines"></span>
    </staffnow-contract-info>
    <staffnow-contract-info [displayBlock]="true" *ngIf="shouldShowLabourAcknowledgment">
        <span>
            <div class="labour-regulations-component">
                <input
                    [(ngModel)]="labourRegulationsAcknowledgmentClicked"
                    [ngModelOptions]="{ standalone: true }"
                    class="pointer"
                    id="labour-acknowledge"
                    type="checkbox"
                    (change)="labourAcknowledgmentChanged()"
                >
                <label for="labour-acknowledge" class="pointer">
                    {{ 'SYSTEM.INFO.TECHNICIAN_LABOUR_ACKNOWLEDGMENT' | translate}}
                </label>
            </div>
            <div *ngIf="displayAcknowledgeErrorMessage" class="text-danger text-center">
                {{ 'SYSTEM.WARNING.TECHNICIAN_LABOUR_ACKNOWLEDGMENT' | translate }}
            </div>
        </span>
    </staffnow-contract-info>
    <staffnow-contract-info [displayBlock]="true" *ngIf="unsetAgencyGuidelines">
        <span [innerHtml]="unsetAgencyGuidelines"></span>
    </staffnow-contract-info>
</staffnow-offer-info-section>
<staffnow-offer-info-section
    *ngIf="!isRejectedByAnyMro && contractCanBeExtended"
    [title]="'GENERAL.INFORMATION' | translate"
    [icon]="'lightbulb'"
>
    <staffnow-contract-info [displayBlock]="true">
        <span>
             {{ 'TECHNICIAN_GUIDELINES.CONTRACT_EXTENDED_DATE' | translate: {newDate: offer.periodTo | absoluteDateFormat} }}.
        </span>
    </staffnow-contract-info>
</staffnow-offer-info-section>
<staffnow-technician-reasons-details
    *ngIf="
        contractWithUtils.hasPendingReasons() ||
        contractWithUtils.hasProductionReasons() ||
        contractWithUtils.hasQualityRejectedReasons() ||
        contractWithUtils.hasAgencyRejectedReasons()
    "
    [contractWithUtils]="contractWithUtils"
></staffnow-technician-reasons-details>
