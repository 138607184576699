<div [class]="'reg-form-container register-container mb-4 ' + brandName.toLowerCase()">
    <div [class]="'panel-header register ' + brandName.toLowerCase()">
        <h1>{{'GENERAL.REGISER' | translate}}</h1>
        <div class="logo"></div>
    </div>
    <form
        *ngIf="registrationForm"
        novalidate
        [ngrxFormState]="registrationForm"
    >
        <div>
            <label class="control-label font-weight-bold">{{ 'GENERAL.EMAIL' | translate }}</label>
            <input
                class="form-control"
                type="email"
                [placeholder]="'GENERAL.ENTER.EMAIL' | translate"
                [ngrxFormControlState]="registrationForm.controls.email"
            />
            <small class="validation-error">{{
                registrationForm.controls.email | validationError | translate
            }}</small>
        </div>
        <div>
            <label class="control-label font-weight-bold">{{ 'GENERAL.PASSWORD' | translate }}</label>
            <input
                class="form-control"
                type="password"
                [placeholder]="'GENERAL.ENTER.PASSWORD' | translate"
                autocomplete="off"
                [tooltip]="
                    FormValidationErrorMessages.pattern.message | translate
                "
                [ngrxFormControlState]="registrationForm.controls.password"
            />
            <small class="validation-error">{{
                registrationForm.controls.password | validationError | translate
            }}</small>
        </div>
        <div>
            <label class="control-label font-weight-bold">{{ 'GENERAL.PASSWORD.REENTER' | translate }}</label>
            <input
                class="form-control"
                type="password"
                [placeholder]="'GENERAL.PASSWORD.AGAIN' | translate"
                autocomplete="off"
                [ngrxFormControlState]="
                    registrationForm.controls.confirmPassword
                "
            />
            <small class="validation-error">{{
                registrationForm.controls.confirmPassword
                    | validationError
                    | translate
            }}</small>
        </div>
        <div class="custom-control custom-checkbox">
            <input
                class="custom-control-input"
                id="terms-checkbox"
                type="checkbox"
                [ngrxFormControlState]="
                    registrationForm.controls.termsAgreement
                "
            />
            <label
                class="custom-control-label font-weight-bold"
                for="terms-checkbox"
            >
                <span class="font-weight-bold"
                      [innerHTML]="'REGISTRATION.STEPS.DISPLAY_OPTIONS.T&C' | translate: {brandName, privacyPolicyLink, termsAndConditionsLink}">
                </span>
            </label>
            <small class="validation-error">
                {{
                    registrationForm.controls.termsAgreement
                        | validationError
                        | translate
                }}
            </small>
        </div>
        <div *ngIf="isRegionUsa()" class="custom-control custom-checkbox">
            <input
                class="custom-control-input"
                id="us-checkbox"
                type="checkbox"
                [ngrxFormControlState]="
                    registrationForm.controls.canWorkInUsaAgreement
                "
            />
            <label
                class="custom-control-label font-weight-bold"
                for="us-checkbox"
            >
                {{
                    'REGISTRATION.TECHNICIAN.USA_ELIGIBLE'
                        | translate
                }}
            </label>
            <small class="validation-error">
                {{
                    registrationForm.controls.canWorkInUsaAgreement
                        | validationError
                        | translate
                }}
            </small>
        </div>
        <div class="custom-control custom-checkbox">
            <input
                class="custom-control-input"
                id="newsletter-checkbox"
                type="checkbox"
                [ngrxFormControlState]="
                    registrationForm.controls.newsletterAgreement
                "
            />
            <label
                class="custom-control-label font-weight-bold"
                for="newsletter-checkbox"
            >
                {{
                    'REGISTRATION.TECHNICIAN.RELATED_JO'
                        | translate
                }}</label
            >
            <small class="validation-error">
                {{
                    registrationForm.controls.newsletterAgreement
                        | validationError
                        | translate
                }}
            </small>
        </div>
    </form>

    <general-button
        (onClick)="submitForm()"
        [isDisabled]="!registrationForm.isValid || !registrationForm.isTouched"
        class="reg-btn"
        size="large"
        [text]="'GENERAL.REGISER' | translate"
    ></general-button>
</div>
