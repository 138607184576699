import { Rate } from './rate.model';

export interface AdditionalBenefit {
  type: BenefitType;
  amount?: Rate | number;
  description?: string;
}

export enum BenefitType {
  DAILY_COMMUTING = 'DAILY_COMMUTING',
  TRAVELING_COST = 'TRAVELING_COST',
  ACCOMMODATION = 'ACCOMMODATION',
  OVERTIME_RATE = 'OVERTIME_RATE',
  PER_DIEMS = 'PER_DIEMS',
  OTHER = 'OTHER'
}

export function benefitTypeToReadable(type: BenefitType): string {
  switch (type) {
    case BenefitType.DAILY_COMMUTING:
      return 'Daily commuting to work';
    case BenefitType.TRAVELING_COST:
      return 'Traveling cost';
    case BenefitType.ACCOMMODATION:
      return 'Accommodation';
    case BenefitType.OVERTIME_RATE:
      return 'Overtime special rate';
    case BenefitType.PER_DIEMS:
      return 'Per diems';
    case BenefitType.OTHER:
      return 'Other benefits';
  }
}
