export const AccountFormConfig = {
  platform: {
    ROLE_AGENCY: {
      readonlyFields: ['firstName', 'lastName']
    },
    ROLE_MRO_PRODUCTION: {
      readonlyFields: ['firstName', 'lastName']
    },
    ROLE_MRO_QUALITY: {
      readonlyFields: ['firstName', 'lastName']
    },
    ROLE_MRO_CENTRAL_QUALITY: {
      readonlyFields: ['firstName', 'lastName']
    },
    ROLE_MRO_HUMAN_RESOURCES: {
      readonlyFields: ['firstName', 'lastName']
    }
  }
};

export enum NavigationTabs {
  Skills = 'Skills',
  SelfIdentification = 'Self ID',
  General = 'General',
  Settings = 'Settings',
  Documents = 'Documents',
  Availability = 'Availability',
  Backoffice = 'Backoffice',
  PerformanceFeedbacks = 'Feedback'
}
