<div class="loader-wrapper" *ngIf="isLoadingDocumentAreas$ | async; else areasLoaded">
    <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
</div>
<ng-template #areasLoaded>
    <div class="section-description" *ngIf="isUserTechnician">
        <div>
            <p class="guidelines" *ngIf="documentAreas.length < 2">
                Keep in mind that depending on your job title, you may have to upload more documents. Please, don't
                forget to fill in this information in the "Account" tab.
            </p>
        </div>
    </div>
    <div class="documents-form" *ngIf="!areasIsEmpty">
        <staffnow-document-area
            class="item"
            *ngFor="let area of documentAreas; index as areaIndex; last as isLastArea"
            [technicianPendingChanges]="technicianPendingChanges"
            [documentArea]="area"
            [documents]="documents"
            [isLastArea]="isLastArea"
            (openArea)="this.openDocumentArea(areaIndex)"
            (openNextArea)="this.openDocumentArea(areaIndex + 1)"
            (successfulFileUpload)="this.onSuccessfulFileUpload($event)"
        ></staffnow-document-area>
        <general-button
            *ngIf="isPlatformWeb"
            text="Download All Documents"
            [isLoading]="isDownloadingAllDocuments"
            [isDisabled]="areDocumentsEmpty || isDownloadingAllDocuments"
            (onClick)="downLoadAllDocuments()"
        ></general-button>
        <button
            class="btn"
            *ngIf="isUserTechnician && hasCertificatesArea"
            [ngClass]="{
                'expiry-danger': isExpired,
                'expiry-warning': isAboutToExpire
            }"
            (click)="openTrainingWebsite()"
        >
            Renew your certificates
        </button>
        <staffnow-document-summaries *ngIf="!areasIsEmpty" [documentSummaries]="documentSummaries">
        </staffnow-document-summaries>
    </div>
</ng-template>
