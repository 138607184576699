import {Pipe, PipeTransform} from '@angular/core';
import {DATE_FORMAT_OPTIONS} from '@libs/shared/constants/date-format.constants';
import {DateTime} from 'luxon';
import {dateToUTCString} from "@libs/shared/helpers/date-utils";

@Pipe({name: 'absoluteDateFormat'})
export class AbsoluteDateFormatPipe implements PipeTransform {
  /*
  * The aim of this pipe is to display a UTC date as it is, without time.
  * */
  public transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    let result: DateTime;
    if (typeof value === 'string') {
      result = this.getAbsoluteDateTimeFromString(value);
    } else if (value instanceof Date) {
      result = this.getAbsoluteDateTimeFromDate(value);
    }
    return result.toLocaleString(DATE_FORMAT_OPTIONS);
  }

  private getAbsoluteDateTimeFromString(text: string): DateTime {
    // This is done to ignore time before converting the string to an object, otherwise there will be precision errors since days can flip.
    const dateString = text.split('T')[0];
    return DateTime.fromISO(dateString, {zone: 'utc'});
  }

  private getAbsoluteDateTimeFromDate(date: Date): DateTime {
    return this.getAbsoluteDateTimeFromString(dateToUTCString(date));
  }

}
