import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceWrapper } from './resource-wrapper.model';
import { Resource } from './resource.model';

@Injectable()
export class ResourceFactory {
  constructor(private http: HttpClient) {}

  from(resource: Resource): ResourceWrapper {
    return new ResourceWrapper(this.http, resource);
  }

  fromId(id: string): ResourceWrapper {
    return this.from({
      _links: {
        self: { href: id }
      }
    });
  }
}
