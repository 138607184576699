import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CreateConversation,
  LoadConversation
} from '@libs/mailbox/state/mailbox.actions';
import { Router } from '@angular/router';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {
  constructor(private router: Router, private store: Store<any>) {}

  public canCreateConversationWithMro(contract: any): boolean {
    return hasLink(contract, RequestOverviewLinkRel.CreateConversationMRO);
  }

  public canGetPreviousConversationWithMro(contract: any): boolean {
    return hasLink(contract, RequestOverviewLinkRel.GetConversationMRO);
  }

  public canCreateConversationWithAgency(contract: any): boolean {
    return hasLink(contract, RequestOverviewLinkRel.CreateConversationAgency);
  }

  public canGetPreviousConversationWithAgency(contract: any): boolean {
    return hasLink(contract, RequestOverviewLinkRel.GetConversationAgency);
  }

  public canCreateConversationWithTechnician(contract: any): boolean {
    return hasLink(
      contract,
      RequestOverviewLinkRel.CreateConversationTechnician
    );
  }

  public canGetPreviousConversationWithTechnician(contract: any): boolean {
    return hasLink(contract, RequestOverviewLinkRel.GetConversationTechnician);
  }

  public initiateMessageWithMro(contract: any): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationMRO,
      RequestOverviewLinkRel.CreateConversationMRO,
      contract.mroUserUuid,
      contract
    );
  }

  public initiateMessageWithMroForPackageContract(packageContract: any): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationMRO,
      RequestOverviewLinkRel.CreateConversationMRO,
      packageContract.mroUserUuid,
      null,
      packageContract
    );
  }

  public initiateMessageWithAgency(contract: any): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationAgency,
      RequestOverviewLinkRel.CreateConversationAgency,
      contract.agencyUserUuid,
      contract
    );
  }

  public initiateMessageWithAgencyForAgencyOfferContract(agencyOfferContract: any): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationAgency,
      RequestOverviewLinkRel.CreateConversationAgency,
      agencyOfferContract.agencyUserUuid,
      null,
      null,
      agencyOfferContract
    );
  }

  public initiateMessageWithAgencyForPackageContract(
    packageContract: any
  ): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationAgency,
      RequestOverviewLinkRel.CreateConversationAgency,
      packageContract.agencyUserUuid,
      null,
      packageContract
    );
  }

  public initiateMessageWithTechnicianBeforeOffer(technician: any): void {
    this.initiateMessageTechnician(
      RequestOverviewLinkRel.GetConversationTechnician,
      technician._embedded.profile.userUuid,
      technician
    );
  }

  public initiateMessageWithTechnician(contract: any): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationTechnician,
      RequestOverviewLinkRel.CreateConversationTechnician,
      contract.technicianUserUuid,
      contract
    );
  }

  public initiateMessageWithTechnicianFromAgencyOfferContract(agencyOfferContract: any): void {
    this.initiateMessage(
      RequestOverviewLinkRel.GetConversationTechnician,
      RequestOverviewLinkRel.CreateConversationTechnician,
      agencyOfferContract.technicianUserUuid,
      null,
      null,
      agencyOfferContract
    );
  }

  private initiateMessage(
    getConversationLink,
    createConversationLink,
    userUuid,
    contract,
    packageContract = null,
    agencyOfferContract = null
  ): void {
    this.router.navigate(['mailbox']);
    if (hasLink(contract ?? packageContract ?? agencyOfferContract, getConversationLink)) {
      this.store.dispatch(
        LoadConversation({
          conversationUrl: getUrl(
            contract ?? packageContract ?? agencyOfferContract,
            getConversationLink
          )
        })
      );
    } else if (hasLink(contract ?? packageContract ?? agencyOfferContract, createConversationLink)) {
      this.store.dispatch(
        new CreateConversation(userUuid, contract?.id, packageContract?.id, agencyOfferContract?.id)
      );
    }
  }

  private initiateMessageTechnician(
    getConversationLink,
    userUuid,
    technician
  ): void {
    this.router.navigate(['mailbox']);
    if (hasLink(technician, getConversationLink)) {
      this.store.dispatch(
        LoadConversation({
          conversationUrl: getUrl(technician, getConversationLink)
        })
      );
    } else {
      this.store.dispatch(new CreateConversation(userUuid, null));
    }
  }
}
