import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNavigationService } from "@libs/shared/services/custom-navigation.service";

@Component({
  selector: 'customer-support-modal',
  templateUrl: './customer-support-modal.component.html',
  styleUrls: ['./customer-support-modal.component.scss']
})
export class CustomerSupportModalComponent {
  @Input() message: string;
  @Input() title: string;

  constructor(
      private bsModalRef: BsModalRef,
      private customNavigationService: CustomNavigationService) {}

  public accept(): void {
    this.bsModalRef.hide();
  }

  public contactCustomerSupport(): void {
    this.bsModalRef.hide();
    this.customNavigationService.goToCustomerSupportForm();
  }
}
