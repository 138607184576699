import { Injectable } from '@angular/core';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { MenuItem } from '@libs/shared/models/menu-item.model';
import { isEmpty } from 'lodash-es';
import { Link } from '@libs/shared/bms-common/rest/resource.model';

interface MenuConfiguration {
  label: string;
  route: string;
  enablingLinks?: string[];
}

const profile: MenuConfiguration = {
  label: 'Profile',
  route: 'profile',
  enablingLinks: [ApiRootLinkRel.MyProfile]
};
const reports: MenuConfiguration = {
  label: 'Reports',
  route: 'reports',
  enablingLinks: [ApiRootLinkRel.Reports]
};
const help: MenuConfiguration = {
  label: 'Help',
  route: 'help'
};
const myJobOpeningsForTechnicians: MenuConfiguration = {
  label: 'My Job Openings',
  route: 'vacancy-overview',
  enablingLinks: [ApiRootLinkRel.GetPersonalOffers]
};
const jobOpeningsSearch: MenuConfiguration = {
  label: 'Job Openings Search',
  route: 'job-overview',
  enablingLinks: [ApiRootLinkRel.SearchJobOpenings]
};
const matchingJobOpeningsSearch: MenuConfiguration = {
  label: 'Matching Job Openings',
  route: 'job-overview/matching',
  enablingLinks: [ApiRootLinkRel.SearchJobOpenings]
};
const pendingTasks: MenuConfiguration = {
  label: 'Pending Tasks',
  route: 'my-tasks',
  enablingLinks: [ApiRootLinkRel.GetPendingTasks]
};
const jobOpeningsAndApplications: MenuConfiguration = {
  label: 'Job Openings and Applications',
  route: 'request-overview',
  enablingLinks: [ApiRootLinkRel.Offers]
};
const techniciansSearch: MenuConfiguration = {
  label: 'Technicians Search',
  route: 'technician-overview',
  enablingLinks: [ApiRootLinkRel.GetTechnicians]
};
const myJobOpeningsForAgencies: MenuConfiguration = {
  label: 'My Job Openings',
  route: 'agency-request-overview',
  enablingLinks: [ApiRootLinkRel.AviationCompaniesJobOpenings]
};
const ownTechnicians: MenuConfiguration = {
  label: 'Own Technicians',
  route: 'own-technicians',
  enablingLinks: [ApiRootLinkRel.GetOwnTechnicians]
};
const aviationCompanies: MenuConfiguration = {
  label: 'Aviation Companies',
  route: 'mros',
  enablingLinks: [ApiRootLinkRel.GetAviationCompanyListForAdvertisement]
};
const company: MenuConfiguration = {
  label: 'Company',
  route: 'facility',
  enablingLinks: [ApiRootLinkRel.MyFacility]
};
const dashboards: MenuConfiguration = {
  label: 'Dashboards',
  route: 'dashboards'
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PlatformNavbarService {
  private wrappedRole: RoleWrapper;

  constructor(private store: Store) {
    this.store.pipe(getLoggedInUserRole, untilDestroyed(this)).subscribe(userRole => {
      this.wrappedRole = role(userRole);
    });
  }

  public convertRoutesToMenuItems(routes: { [key: string]: Link }): MenuItem[] {
    if (routes) {
      const routePaths: string[] = Object.keys(routes);
      const menus: MenuConfiguration[] = isPlatformWeb() ? this.getWebMenu() : this.getTechniciansMobileAppMenu();
      return menus
        .filter(it => this.isMenuAccessible(it, routePaths))
        .map(it => ({ route: it.route, label: it.label }));
    }
    return [help];
  }

  private isMenuAccessible(config: MenuConfiguration, routePaths: string[]): boolean {
    return isEmpty(config.enablingLinks) || config.enablingLinks.some(link => routePaths.includes(link));
  }

  private getWebMenu(): MenuConfiguration[] {
    if (this.wrappedRole.isTechnician()) {
      return this.getTechnicianWebMenu();
    }
    if (this.wrappedRole.isMro()) {
      return this.getAviationCompanyMenu();
    }
    if (this.wrappedRole.isAgency()) {
      return this.getAgencyMenu();
    }
    return [help];
  }

  private getTechniciansMobileAppMenu(): MenuConfiguration[] {
    return [profile, matchingJobOpeningsSearch, myJobOpeningsForTechnicians, jobOpeningsSearch, help];
  }

  private getTechnicianWebMenu(): MenuConfiguration[] {
    return [
      pendingTasks,
      profile,
      matchingJobOpeningsSearch,
      myJobOpeningsForTechnicians,
      jobOpeningsSearch,
      aviationCompanies,
      reports,
      help
    ];
  }

  private getAviationCompanyMenu(): MenuConfiguration[] {
    return [pendingTasks, jobOpeningsAndApplications, techniciansSearch, company, profile, reports, dashboards, help];
  }

  private getAgencyMenu(): MenuConfiguration[] {
    return [pendingTasks, jobOpeningsSearch, myJobOpeningsForAgencies, ownTechnicians, company, profile, reports, help];
  }
}
