import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter } from 'rxjs/operators';

/* eslint @typescript-eslint/no-unsafe-call: 0 */
/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  constructor(
    private cookieBotService: NgxCookiebotService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  init(): void {
    this.cookieBotService.onServiceReady$
      .pipe(filter((ready: boolean) => ready))
      .subscribe(() => {
        this.cookieBotService.onAccept$.subscribe(() =>
          this.enableSubscriptions()
        );
      });
  }

  changeSettings(): void {
    this.cookieBotService.cookiebot.renew();
  }

  enableSubscriptions(): void {
    this.googleAnalyticsService.enableCookie();
  }
}
