import { Component, Input, OnChanges } from '@angular/core';
import { DayObject } from '@libs/shared/models/availability.model';
import { dateToUTCString } from '@libs/shared/helpers/date-utils';

@Component({
  selector: 'staffnow-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnChanges {
  @Input() public yearMonth: string = '';
  @Input() public mappedPeriods?: Map<string, DayObject>;
  @Input() public infoSection?: boolean;

  public MONTH_INDEX: number;
  public MONTH_NAME: string;
  public WEEK_LENGTH: number = 7;
  public WEEKS_IN_MONTH: number = 6;
  public YEAR: number;

  public DATES: Date[] = [];
  public MONTH: DayObject[][] = [];

  ngOnChanges() {
    this.DATES = [];
    this.MONTH = [];
    if (this.yearMonth) {
      this.convertMonthInput();
      this.generateDays();
      this.generateMonth();
    }
  }

  public convertMonthInput() {
    const convertedYearMonth = this.yearMonth.split('-');

    this.YEAR = Number(convertedYearMonth[0]);
    this.MONTH_INDEX = Number(convertedYearMonth[1]) - 1;
    this.MONTH_NAME = new Date(this.YEAR, this.MONTH_INDEX).toLocaleString('en-us', { month: 'long' });
  }

  public generateMonth(): void {
    for (let i = 0; i < this.WEEKS_IN_MONTH; i++) {
      this.MONTH.push(this.generateWeek());
    }
  }

  /**
   * METHOD: generateWeek()
   *
   * 1. We generate 'week' array with length of 7 (days);
   * 2. Then we check if the first element of DATES array is the first day of the month
   *    and if it is, we check what day of the week is it. And by that we set from which
   *    index of the WEEK array the first week of the month should start;
   * 3. Then we start iterate the week days (from previously selected first day index to the length of week);
   *    For each index of the array we do the following:
   *    - Get the first element from DATES array.
   *    - Get the DayObject from the mappedPeriods table via hash of the current date (if the date is part of a period).
   *    - Then if the date is part of a period, we set this DayObject to current week day index, else we manually generate DayObject.
   * 4. * If the date is part of a period it's 'period' property is the whole range with 'fromDate', 'toDate' and 'type' properties.
   *      else we set the 'period' property to DateISOString.
   *
   * @returns Array of DayObjects and empty strings (if the week day is part of the prev/next month it is set to empty string)
   */
  //TODO: change how the calendar renders events (SN-1559)
  public generateWeek(): Array<DayObject> {
    let dayIndex = 0;
    if (this.DATES.length > 0 && this.DATES[0].getDate() === 1) {
      const firstDayIndex = new Date(this.YEAR, this.MONTH_INDEX, 1).getDay();
      dayIndex = firstDayIndex === 0 ? 6 : firstDayIndex - 1;
    }
    const week: DayObject[] = new Array(this.WEEK_LENGTH).fill('');

    while (dayIndex < week.length && this.DATES.length > 0) {
      const date = this.DATES.shift();
      const hash = dateToUTCString(date);
      const dayObject: DayObject = this.mappedPeriods.get(hash);

      if (dayObject) {
        week[dayIndex] = dayObject;
      } else {
        week[dayIndex] = {
          date,
          isPartOfPeriod: false,
          isStartOrEndDate: false,
          period: dateToUTCString(date)
        };
      }
      dayIndex++;
    }
    return week;
  }

  public generateDays(): void {
    const monthLength = new Date(this.YEAR, this.MONTH_INDEX + 1, 0).getDate();
    // We need to set hour to 01:00 instead of 00:00 because, when it is "hour change day",
    // the created date goes back one day, for example, 29/Mar/2021 00:00 becomes 28/Mar/2021 23:00.
    // Setting the hour to 01:00 means, for example, 29/Mar/2021 01:00 becomes 29/Mar/2021 00:00.
    const hour = 1;
    for (let day = 1; day <= monthLength; day++) {
      this.DATES.push(new Date(this.YEAR, this.MONTH_INDEX, day, hour));
    }
  }
}
