import { Component, OnInit } from '@angular/core';
import {
  AddStaffiesToOffer,
  ApproveOffer,
  CloseOffer,
  DeleteOffer,
  LoadOfferWithContracts,
  ReopenOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { cloneDeep, isNil } from 'lodash-es';
import { RequestOverviewState } from '../../../state/request-overview-state.model';
import { Contract } from '@libs/shared/models/contract.model';
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { AbstractOfferDetailsComponent } from '../abstract-offer-details.component';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { FacilityStatus, MroFacility } from '@libs/shared/models/facility.model';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { getReqUrl } from '@libs/shared/helpers/get-url-from-resource';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import {
  SelectTechnicianFromListModalComponent
} from '@libs/common-ui/select-technician-from-list-modal/select-technician-from-list-modal.component';

@Component({
  selector: 'staffnow-mro-job-offer-details',
  templateUrl: './mro-job-offer-details.component.html',
  styleUrls: [
    '../facility-job-offer-details.component.scss',
    './mro-job-offer-details.component.scss'
  ]
})
export class MroJobOfferDetailsComponent
  extends AbstractOfferDetailsComponent
  implements OnInit {
  public contracts: Array<Contract> = [];

  get isMro(): boolean {
    return this.userRole.isMro();
  }

  get disabledActionOnEndedOrClosedOfferTooltip(): string {
    return this.jobOffer?.isClosed
      ? this.translateService.instant('SYSTEM.INFO.DISABLED_ACTION_CLOSED_JO')
      : this.translateService.instant('SYSTEM.INFO.DISABLED_ACTION_ENDED_JO');
  }

  get isOfferClosedOrEnded(): boolean {
    return this.jobOffer?.isClosed || this.isOfferEnded;
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.jobOffer?.periodTo);
  }

  get isInviteTechniciansDisabled(): boolean {
    return (
      !this.jobOffer?.isApproved || this.isOfferClosedOrEnded || this.isMroPending
    );
  }

  get isMroPending(): boolean {
    const mroFacility = this.facilityProfile();
    return mroFacility?.status == FacilityStatus.PENDING_REVIEW;
  }

  private facilityProfile(): MroFacility {
    return getEmbeddedResource(this.jobOffer, JobOfferLinkRel.Facility);
  }

  get inviteTechniciansDisabledTooltip(): string {
    if (this.isOfferClosedOrEnded) {
      return this.disabledActionOnEndedOrClosedOfferTooltip;
    }
    return !this.jobOffer?.isApproved
      ? this.translateService.instant('AGENCY_JO_DISABLE_NOT_APPROVED')
      : this.translateService.instant('AGENCY_JO_DISABLE_NOT_VERIFIED');
  }

  get calculateRemainingPositions(): number {
    const remaining =
      this.jobOffer?.vacancies - this.jobOffer?.fullyMatchedSize;
    return remaining < 0 ? 0 : remaining;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private confirmService: ModalService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadOfferWithContracts({
            refNumber: params['offerRefNumber']
          })
        );
      })
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contracts = state.contracts.map(contract => this.adjustContractEndDate(contract));
  }

  private adjustContractEndDate(contract): any {
    return {
      ...contract,
      endDate: contract.endDate ? contract.endDate : contract.provisionalEndDate
    };
  }

  get canAddStaffies(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.AddStaffies]);
  }

  get offerHasPayRate(): boolean {
    return !isNil(this.jobOffer.payRate);
  }

  public handleInviteTechniciansToOffer(): void {
    if (!this.jobOffer.isApproved) {
      return;
    }
    const offer = this.jobOffer;
    this.router.navigate(['technician-overview'], {
      queryParams: {
        aircraft: offer.airplanes.join(','),
        offer: offer.refNumber,
        availability: `${offer.periodFrom}:${offer.periodTo}`,
        ameType: offer.ameTitle.type,
        ameLicense: offer.ameTitle.license,
        experience: offer.minExperience
      }
    });
  }

  public addStaffies(): void {
    this.openAddStaffiesModal();
  }

  private openAddStaffiesModal(): void {
    this.store.pipe(getFilteredApiRoot).pipe(take(1)).subscribe(apiRoot => {
      const getTechniciansUrl = getReqUrl(apiRoot, ApiRootLinkRel.GetStaffies).replace('offerUuid=', `offerUuid=${this.jobOffer?.uuid}`);
      const onConfirmCallback = technicians =>
        this.store.dispatch(
          AddStaffiesToOffer({
            selectedTechniciansUuids: technicians,
            offer: cloneDeep(this.offer)
          })
        );
      this.modalService.show(SelectTechnicianFromListModalComponent, {
        ignoreBackdropClick: true,
        class: 'technician-profile-modal modal-center',
        initialState: {
          placeholder: 'Select own employees',
          retrieverUrl: getTechniciansUrl,
          multiple: true,
          onConfirmCallback
        }
      });
    })
  }

  get canDeleteOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.Delete]);
  }

  get canEditOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.Edit]);
  }

  get canApprove(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.ApproveOffer]);
  }

  get canCloseOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.CloseOffer]);
  }

  get canReopenOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.ReopenOffer]);
  }

  public openEditOfferForm(): void {
    const offer = this.jobOffer;
    this.router
      .navigate(['offer'], {
        state: {
          preselectedFilters: offer,
          isPrivate: !offer.isPublic,
          selectedTechnicians: [],
          isEdit: true,
          breadcrumbs: [
            this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
            this.translateService.instant('AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS'),
            this.translateService.instant('AGENCY.DETAILS.EDIT_JOB_OPENING'),
          ]
        }
      })
      .then();
  }

  public handleDeleteOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.DELETE_JO_CONFIRMATION'),
      () => {
        this.store.dispatch(DeleteOffer({url: getUrl(this.jobOffer, 'delete')}));
      }
    );
  }

  public approveOffer(): void {
    const message = this.jobOffer.isPublic
      ? this.translateService.instant('SYSTEM.INFO.APPROVE_PUBLIC_JO')
      : this.translateService.instant('SYSTEM.INFO.APPROVE_PRIVATE_JO');
    this.confirmService.openConfirmModal(message, () => {
      this.store.dispatch(ApproveOffer({offer: this.jobOffer}));
    });
  }

  public closeOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.CLOSE_JO'),
      () => this.store.dispatch(CloseOffer({offer: this.jobOffer}))
    );
  }

  public reopenOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.REOPEN_JO'),
      () => this.store.dispatch(ReopenOffer({offer: this.jobOffer}))
    );
  }
}
