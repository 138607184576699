import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {getBrandName} from "@libs/shared/bms-common/environment/environment.selector";

@Component({
  selector: 'staffnow-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss', '../../../common.scss']
})
export class SuccessComponent {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  public brandName$: Observable<string>;

  constructor(private store: Store<any>) {
    this.brandName$ = this.store.pipe(getBrandName);
  }

  close() {
    this.onClose.emit();
  }
}
