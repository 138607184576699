import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SetProfileUrl } from './state/user-profile.actions';
import { AppState } from '@libs/shared/bms-common/api-root/api-root.model';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';

@Injectable()
export class UserProfileResolver  {
  constructor(
    private httpService: HttpClient,
    private store: Store<AppState>
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<string> {
    return this.store.pipe(
      getFilteredApiRoot,
      take(1),
      switchMap(apiRoot => {
        if (route.params.userUuid) {
          const profileUrl =
            getUrl(apiRoot, ApiRootLinkRel.Discover) + route.params.userUuid;
          return this.httpService
            .get(profileUrl)
            .pipe(map(response => getUrl(<Resource>response, 'profile')));
        } else {
          return of(getUrl(apiRoot, ApiRootLinkRel.MyProfile));
        }
      }),
      tap(profileUrl => this.store.dispatch(SetProfileUrl({ profileUrl })))
    );
  }
}
