import {NgModule} from "@angular/core";
import {SharedModule} from "@libs/shared/shared.module";
import {CommonUiModule} from "@libs/common-ui/common-ui.module";
import {NgrxFormsModule} from "ngrx-forms";
import {TranslationModule} from "@libs/translation/translation.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {MatInputModule} from "@angular/material/input";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {CreatePermanentOfferPageComponent} from "./create-permanent-offer-page.component";
import {ReactiveFormsModule} from "@angular/forms";
import {EffectsModule} from "@ngrx/effects";
import {CreatePermanentOfferEffects} from "./create-permanent-offer.effects";

@NgModule({
    imports: [
        SharedModule,
        CommonUiModule,
        NgrxFormsModule,
        EffectsModule.forFeature([CreatePermanentOfferEffects]),
        TranslationModule,
        TooltipModule.forRoot(),
        MatInputModule,
        TypeaheadModule,
        ReactiveFormsModule
    ],
  declarations: [CreatePermanentOfferPageComponent],
  exports: [CreatePermanentOfferPageComponent]
})
export class CreatePermanentOfferPageModule {}