import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RequestsOverviewModule } from '../request-overview/requests-overview.module';

import { SharedModule } from '../shared/shared.module';
import { AgencyRequestOverviewWrapperComponent } from './components/agency-request-overview-wrapper/agency-request-overview-wrapper.component';
import { AgencyRequestOverviewWrapperRoutingModule } from './agency-request-overview-wrapper-routing.module';
import { AgencyRequestOverviewEffects } from './state/agency-request-overview-wrapper.effects';
import { OwnTechniciansComponent } from './components/own-technicians/own-technicians.component';
import {
  AgenciesMainState,
  initialState as agenciesInitialState,
  STATE_FEATURE_KEY
} from './state/agency-request-overview-wrapper.model';
import {
  reducersInjectionToken,
  getReducers as getAgencyMainReducers
} from './state/agency-request-overview-wrapper.reducer';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { StaffFiltersModule } from '@libs/staff-filters/staff-filters.module';

export function getInitialState(): Partial<AgenciesMainState> {
  return {
    ...agenciesInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RequestsOverviewModule,
    AgencyRequestOverviewWrapperRoutingModule,
    CommonUiModule,
    StaffFiltersModule,
    PaginationModule.forRoot(),
    EffectsModule.forFeature([AgencyRequestOverviewEffects]),
    StoreModule.forFeature(STATE_FEATURE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    })
  ],
  declarations: [
    AgencyRequestOverviewWrapperComponent,
    OwnTechniciansComponent
  ],
  providers: [
    { provide: reducersInjectionToken, useFactory: getAgencyMainReducers }
  ]
})
export class AgencyRequestOverviewWrapperModule {}
