import {Component, EventEmitter, HostBinding, Input, OnChanges, Output} from '@angular/core';

import {FilterConfigModel} from '../../models/filter-config.model';

@Component({
  selector: 'enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss']
})
export class EnumFilterComponent implements OnChanges {
  @Input() public size: 'small' | 'normal' = 'normal';
  @Input() public filterConfig: FilterConfigModel = null;
  @Output() public onChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @HostBinding('class.small') get isSmall(): boolean {
    return this.size === 'small';
  }

  public value: any = null;

  ngOnChanges() {
    this.value = this.filterConfig?.initialValue;
  }

  public handleChange(newValue: any): void {
    this.value = newValue;
    if (typeof newValue === 'string' && !this.filterConfig.isIdText) {
      newValue = Number(newValue);
    }
    const shouldReturnNull = Number.isNaN(newValue) || newValue === 'null';
    this.onChange.emit(shouldReturnNull ? null : newValue);
  }
}
