<div class="bo-container">
    <entity-nav
        [activeItem]="activeItem"
        [menuItems]="menuItems"
        (selectedItem)="handleSelectItem($event)"
    >
    </entity-nav>

    <router-outlet></router-outlet>
</div>
