import { endsWith, mapValues, pickBy } from 'lodash-es';

export function getRouteResourceIds(params: { [key: string]: string }) {
  return mapValues(getResourceIdParams(params), decodeResourceId);
}

function getResourceIdParams(params: { [key: string]: string }) {
  return pickBy(params, (value, key) => isIdParam(key));
}

function isIdParam(paramName: string) {
  return endsWith(paramName, 'Id');
}

function decodeResourceId(encodedResourceId: string): string {
  return atob(encodedResourceId);
}
