<div class="mro-registration-header">
    <div class="d-flex flex-row mb-3">
        <div
            class="flex-grow-1 step-element"
            [ngClass]="{ active: i == currentStep, inactive: i != currentStep }"
            [style.min-width]="100 / steps.length"
            *ngFor="let step of steps; let i = index"
        >
            <div>
                <div class="d-flex align-content-center flex-row">
                    <div
                        class="flex-grow-1 d-flex flex-column justify-content-center"
                    >
                        <div class="line"></div>
                    </div>
                    <div>
                        <div>
                            <i class="material-icons stepper-icon">
                                {{ step.icon }}
                            </i>
                        </div>
                    </div>
                    <div
                        class="flex-grow-1 d-flex flex-column justify-content-center"
                    >
                        <div class="line"></div>
                    </div>
                </div>
            </div>
            <div class="text-center pt-1">
                <span class="font-weight-bold title">{{ step.title }}</span
                ><br />
                <span class="counter">({{ i + 1 }}/{{ steps.length }})</span>
            </div>
        </div>
    </div>
</div>
<div class="step-content">
    <ng-template stepElement></ng-template>
</div>
