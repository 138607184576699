@if (closed) {
<corner-card [type]="CORNER_CARD_TEXT_TYPE_ENUM.CLOSED"> </corner-card>
} @if (privateOffer) {
<card-tab-text [text]="'OFFER_DETAILS.PRIVATE_JOB_OPENING' | translate"></card-tab-text>
}
<div class="offer-card-head">
    <div [class]="['offer-alert', 'closed']" *ngIf="closed">
        {{ 'REQUEST_CARD.OFFER_ALERT.TEXT.CLOSED_FOR_APPLICATIONS' | translate }}
    </div>
    <h3 class="h3">
        {{ facility?.name }}
    </h3>
    <h3 class="h3">{{ offer.refNumber }}</h3>
    <h3 class="h3">{{ offer.title }}</h3>
</div>
<div class="offer-card-body">
    <staffnow-contract-info [icon]="'location_on'">
        {{ offer?.location.name }}
    </staffnow-contract-info>
    <staffnow-contract-info *ngIf="isPermanentJobOpening; else datePeriod" [icon]="'date_range'">
        {{ offer?.startDate | absoluteDateFormat }}
    </staffnow-contract-info>
    <ng-template #datePeriod>
        <staffnow-contract-info [icon]="'date_range'">
            {{ offer?.periodFrom | absoluteDateFormat }}
            -
            {{ offer?.periodTo | absoluteDateFormat }}
        </staffnow-contract-info>
    </ng-template>
    <ng-container *ngIf="isTemporaryJobOpening || isAgencyJobOpening || isPermanentJobOpening">
        <staffnow-contract-info [icon]="'airplanemode_active'" [overflowElipsis]="true">
            <staffnow-offer-aircrafts-field [offer]="offerOutDto()"></staffnow-offer-aircrafts-field>
        </staffnow-contract-info>
        <staffnow-contract-info [icon]="'build'" [overflowElipsis]="true">
            {{ offer.ameTitle | ameTitleName }}
        </staffnow-contract-info>
        @if (!offer.hasHiddenRate) { @if (isPermanentJobOpening) {
        <staffnow-contract-info [icon]="'settings_ethernet'">
            {{ offer?.monthlySalary | rate }}
            {{ ' per month' | translate }}
        </staffnow-contract-info>
        } @else {
        <staffnow-contract-info [icon]="'settings_ethernet'">
            @if (hasIndicativeRateForOffer(offer)) { @if(isAgencyJobOpening) {
            <span>
                {{ offer?.payRate | rate }}
                {{ ' - ' }}
                {{ offer?.maxPayRate | rate }}
                {{ ' per hour' | translate }}
            </span>
            } @else {
            <ng-container *ngTemplateOutlet="minMaxRange"></ng-container>
            } } @else if (offerHasPayRate){
            <ng-container>
                {{ offer?.payRate | rate }}
                {{ ' per hour' | translate }}
            </ng-container>
            } @else {
            <ng-container *ngTemplateOutlet="minMaxRange"></ng-container>
            }
        </staffnow-contract-info>
        } }
    </ng-container>
</div>
<div class="open-contracts">
    <button class="btn btn-primary" (click)="openProcess()">
        {{ 'GENERAL.VIEW_DETAILS' | translate }}
    </button>
</div>
<ng-template #minMaxRange>
    {{ offer?.minPayRate | rate }}
    {{ ' - ' }}
    {{ offer?.maxPayRate | rate }}
    {{ ' per hour' | translate }}
</ng-template>
