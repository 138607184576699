<staffnow-agency-offer-contract-card-with-head
    [contract]="contract"
    (openPublicProfile)="onOpenPublicProfile($event)"
>
    <div class="agency-offer-contract-card-body">
        <contract-status
            (openPublicProfile)="onOpenPublicProfile($event)"
            [contract]="contract"
            [isAgencyOfferContract]="true"
        >
        </contract-status>
        <staffnow-agency-offer-contract-reasons-list
            *ngIf="hasReasons"
            [contract]="contract"
        ></staffnow-agency-offer-contract-reasons-list>
        <div>
            <div class="start-date">
                <strong>{{ 'GENERAL.START_DATE' | translate }}</strong>
                <span
                >
                    {{ contract.startDate | absoluteDateFormat }}
                </span>
            </div>
            <div class="end-date">
                <strong>{{ 'GENERAL.FINISH_DATE' | translate }}</strong>
                <span>
                    {{ contract.endDate | absoluteDateFormat }}
                </span>
            </div>
        </div>
    </div>
    <div class="agency-offer-contract-card-actions">
        <div *ngIf="!hasAgencyAcceptedAlready" class="button-with-help">
            <general-button
                (onClick)="acceptContract()"
                [isDisabled]="isOfferEndedOrClosed"
                [text]="'GENERAL.ACCEPT' | translate | uppercase"
                size="small"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEndedOrClosed"
                [tooltipMessage]="offerEndedOrClosedTooltip"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <div *ngIf="!hasAgencyDeclinedAlready" class="button-with-help">
            <general-button
                (onClick)="declineContract()"
                [isDisabled]="isOfferEndedOrClosed"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.DECLINE' | translate | uppercase"
                title="{{declineOfferTooltip}}"
                color="danger"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEndedOrClosed"
                [tooltipMessage]="offerEndedOrClosedTooltip"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <div class="button-with-help">
            <general-button
                (onClick)="markContractAsDocumentsNeeded()"
                [isDisabled]="isOfferEndedOrClosed"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.MORE_DOCS' | translate"
                title="{{documentsNeededTooltip}}"
                color="dark"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEndedOrClosed"
                [tooltipMessage]="offerEndedOrClosedTooltip"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <general-button
            (onClick)="initiateMessageWithTechnician()"
            [isDisabled]="
                !canCreateConversationWithTechnician &&
                !canGetPreviousConversationWithTechnician
            "
            backgroundStyle="outline"
            class="chat-button"
            size="small"
            [text]="'GENERAL.CONTACT_TECHNICIAN' | translate"
            title="{{applicantConversationMessageTooltip}}"
            color="secondary"
        ></general-button>
    </div>
</staffnow-agency-offer-contract-card-with-head>
