import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { Conversation } from '@libs/mailbox/state/mailbox-state.model';

const CHAT_BOTTOM_OFFSET: number = 10;

@UntilDestroy()
@Component({
  selector: 'conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss']
})
export class ConversationListComponent {
  @Input() conversationList: Array<Conversation>;
  @Input() selectedConversationId: number;
  @Output() loadConversation: EventEmitter<any> = new EventEmitter();

  @Output() loadNextPage: EventEmitter<any> = new EventEmitter();
  loggedInUserRole: RoleWrapper = null;

  constructor(
    private store: Store<any>,
    private elemRef: ElementRef,
    private customNavigationService: CustomNavigationService
  ) {
    this.store
      .pipe(getLoggedInUserRole, untilDestroyed(this))
      .subscribe(userRole => (this.loggedInUserRole = role(userRole)));
  }

  handleLoadConversation($event: any): void {
    this.loadConversation.emit($event);
  }

  goToCustomerSupportForm(): void {
    this.customNavigationService.goToCustomerSupportForm();
  }

  @HostListener('scroll', ['$event'])
  private onScroll($event: Event): void {
    const scrollHeight = this.elemRef.nativeElement.scrollHeight;
    const clientHeight = this.elemRef.nativeElement.clientHeight;
    const scrollTop: number = ($event.target as HTMLElement).scrollTop;

    if (scrollHeight - clientHeight - scrollTop < CHAT_BOTTOM_OFFSET) {
      this.loadNextPage.emit();
    }
  }
}
