<staffnow-go-back-button
    [currentPath]="[
        'AGENCY.DETAILS.MY_JO' | translate,
        'AGENCY.PACKAGE_OFFER.DETAILS.MY_AGENCY_FIXED_PRICE_JO' | translate,
        'AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS' | translate
    ]"
></staffnow-go-back-button>
<staff-loader *ngIf="isLoading" [customClass]="'splash-screen-loader'"></staff-loader>
<staffnow-panel
    *ngIf="!isLoading"
    [title]="'AGENCY.DETAILS.APPLICATION_STATUS.LABEL' | translate : { refNumber: offer?.refNumber }"
>
    <ng-container data>
        <div>
            <staffnow-package-contract-status [contract]="contract"></staffnow-package-contract-status>
            <staffnow-contract-info label="{{ 'GENERAL.PRIORITY' | translate }}">
                <staffnow-offer-priority-info
                    [displayLabel]="false"
                    [priority]="offer?.priority"
                ></staffnow-offer-priority-info>
            </staffnow-contract-info>
            <staffnow-contract-info label="{{ 'GENERAL.PRICE' | translate }}">
                <ng-container *ngIf="!isAgencyPending; else pending">
                    <staffnow-rates-history [rates]="contract.agencyOfferedPrices"></staffnow-rates-history>
                    <template [ngTemplateOutlet]="priceEstimate"></template>
                </ng-container>
            </staffnow-contract-info>
            <staffnow-contract-info
                label="{{ 'GENERAL.COUNTEROFFER_FROM_AVIATION_COMPANY' | translate }}"
                *ngIf="contract.mroOfferedPrice"
            >
                {{ contract.mroOfferedPrice | rate }}
                <template [ngTemplateOutlet]="priceEstimate"></template>
            </staffnow-contract-info>
            <staffnow-contract-info label="{{ 'GENERAL.DATES' | translate }}">
                <ng-container *ngIf="!isAgencyPending; else pending">
                    {{ contract.startDate | absoluteDateFormat }} -
                    {{ contract.endDate | absoluteDateFormat }}
                </ng-container>
            </staffnow-contract-info>
            <div class="notes-wrapper" *ngIf="contract.agencyNotes">
                <staffnow-contract-info label="{{ 'GENERAL.NOTES' | translate }}:" displayBlock="true">
                    {{ contract.agencyNotes }}
                </staffnow-contract-info>
            </div>
        </div>
    </ng-container>

    <ng-container actions>
        <div *ngIf="canCreateAgencyOffer" class="contract-action">
            <general-button
                (onClick)="openCreateAgencyOfferPage()"
                [isDisabled]="isOfferEnded"
                size="small"
                [text]="'GENERAL.CREATE_AGENCY_JO' | translate | uppercase"
            ></general-button>
            <ng-container *ngTemplateOutlet="disabledActionOnOfferEnded"></ng-container>
        </div>
        <div *ngIf="canAcceptContract" class="contract-action">
            <general-button
                (onClick)="acceptOffer()"
                [isDisabled]="isOfferEnded"
                size="small"
                [text]="'GENERAL.ACCEPT' | translate"
            ></general-button>
            <ng-container *ngTemplateOutlet="disabledActionOnOfferEnded"></ng-container>
        </div>
        <div *ngIf="canDeclineContract" class="contract-action">
            <general-button
                (onClick)="declineContract()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                title="{{ 'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate }}"
                [text]="'GENERAL.DECLINE' | translate"
                color="danger"
            ></general-button>
            <ng-container *ngTemplateOutlet="disabledActionOnOfferEnded"></ng-container>
        </div>

        <div *ngIf="canEditContract" class="contract-action">
            <general-button
                (onClick)="editContract()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.EDIT' | translate"
            ></general-button>
            <ng-container *ngTemplateOutlet="disabledActionOnOfferEnded"></ng-container>
        </div>
        <div *ngIf="canAcceptPrice" class="button-with-help">
            <general-button
                (onClick)="acceptPrice()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.ACCEPT_PRICE' | translate | uppercase"
            ></general-button>
            <ng-container *ngTemplateOutlet="disabledActionOnOfferEnded"></ng-container>
        </div>
        <div *ngIf="canProposePrice" class="button-with-help">
            <general-button
                (onClick)="proposePrice()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.PROPOSE_PRICE' | translate | uppercase"
            ></general-button>
            <ng-container *ngTemplateOutlet="disabledActionOnOfferEnded"></ng-container>
        </div>
        <div *ngIf="canAddTechnicians" class="contract-action">
            <general-button (onClick)="addTechnicians()" size="small" text="ADD TECHNICIANS"></general-button>
        </div>
        <general-button
            (onClick)="initiateMessageWithMro()"
            [isDisabled]="!canCreateConversationWithMro && !canGetPreviousConversationWithMro"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_AVIATION_COMPANY' | translate"
            title="{{ 'SYSTEM.INFO.MESSAGE_MRO_AFTER_ACCEPTING_JO' | translate }}"
            color="secondary"
        ></general-button>
    </ng-container>

    <ng-container footer>
        <staffnow-manage-package-offer-files [packageOffer]="packageOffer" (onActionSuccessful)="reloadOffer()">
        </staffnow-manage-package-offer-files>
    </ng-container>
</staffnow-panel>
<staffnow-panel
    *ngIf="!isLoading && contractHasWorkers()"
    [title]="'AGENCY.PACKAGE_OFFER.DETAILS.TECHNICIANS_ADDED' | translate"
>
    <div class="workers" data>
        <staffnow-chip *ngFor="let worker of workers">
            <div class="package-contract-technician">
                <staffnow-technician-picture-and-name
                    [resource]="worker"
                    (onClick)="openTechnicianProfile($event)"
                ></staffnow-technician-picture-and-name>
                <div class="actions">
                    <borderless-button
                        (onClick)="removeTechnician(worker)"
                        *ngIf="canRemoveTechnician"
                        actionType="danger"
                        color="dark"
                        icon="delete"
                    ></borderless-button>
                </div>
            </div>
        </staffnow-chip>
    </div>
</staffnow-panel>
<staffnow-offer-details
    *ngIf="!isLoading"
    [offer]="offer"
    [title]="'AGENCY.PACKAGE_OFFER.DETAILS.FIXED_PRICE_JO_DESCRIPTION' | translate : { offerDescription }"
></staffnow-offer-details>
<ng-template #pending>Pending</ng-template>
<ng-template #priceEstimate>
    <span *ngIf="contract.priceEstimate">{{ 'AGENCY.PACKAGE_OFFER.DETAILS.BEST_ESTIMATION' | translate }}</span>
</ng-template>
<ng-template #disabledActionOnOfferEnded>
    <staffnow-info-icon-with-tooltip
        *ngIf="isOfferEnded"
        [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
    ></staffnow-info-icon-with-tooltip>
</ng-template>
