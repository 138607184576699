import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile.service';
import { Availability } from '@libs/shared/models/availability.model';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { accountStatusConstants } from '@libs/shared/constants/statuses.constants';
import { Store } from '@ngrx/store';
import { AbstractProfileComponent } from '@libs/user-profile/components/abstract-profile-component';
import { DocumentsService } from '@libs/user-profile/services/documents.service';
import { ActivatedRoute } from '@angular/router';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { FullCalendarComponent } from '@fullcalendar/angular';

export const MONTHS_TO_DISPLAY_PLATFORM = 6;
export const MONTHS_TO_DISPLAY_MOBILE = 2;
type Direction = 'backward' | 'forward';

@Component({
  selector: 'staffnow-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent extends AbstractProfileComponent {
  @Input() public availability: Availability;
  @Output() public fetchAvailability: EventEmitter<any> = new EventEmitter();
  @Output() public loadMonths: EventEmitter<any> = new EventEmitter();

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  public mappedPeriods: EventInput[] = [];
  months: string[] = [];

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  calendarOptions: CalendarOptions = {
    plugins: [multiMonthPlugin],
    initialView: 'multiMonthSixMonth',
    contentHeight: 'auto',
    multiMonthMinWidth: 300,
    handleWindowResize: true,
    customButtons: {
      prev: {
        text: '<',
        click: this.loadPast.bind(this)
      },
      next: {
        text: '>',
        click: this.loadNext.bind(this)
      }
    },
    headerToolbar: {
      right: 'today prev,next'
    },
    firstDay: 1,
    views: {
      multiMonthSixMonth: {
        type: 'multiMonth',
        duration: { months: this.monthsToDisplay() }
      }
    },
    events: this.mappedPeriods
  };

  constructor(
    store: Store<any>,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute,
    private userProfileService: UserProfileService
  ) {
    super(store, documentsService, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fetchAvailability.emit();
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  private getMonthBeforeOrAfterCurrent(months: number, direction: Direction) {
    const convertedYearMonth = this.availability.months[0].split('-');
    const year = Number(convertedYearMonth[0]);
    const month = Number(convertedYearMonth[1]) - 1;
    const current = new Date(year, month, 1);

    let monthAfterCurrent: Date;
    let monthBeforeCurrent: Date;

    if (direction === 'forward') {
      monthAfterCurrent = new Date(current.setMonth(current.getMonth() + months));
      const year = new Date(monthAfterCurrent).getFullYear();
      const nextMonth = new Date(monthAfterCurrent).getMonth() + 1;

      return `${year}-${nextMonth.toString().padStart(2, '0')}`;
    } else {
      monthBeforeCurrent = new Date(current.setMonth(current.getMonth() - months));
      const year = new Date(monthBeforeCurrent).getFullYear();
      const prevMonth = new Date(monthBeforeCurrent).getMonth() + 1;

      return `${year}-${prevMonth.toString().padStart(2, '0')}`;
    }
  }

  private loadPast(): void {
    this.calendarApi.prev();
    const monthBeforeCurrent = this.getMonthBeforeOrAfterCurrent(this.monthsToDisplay(), 'backward');
    this.loadMonths.emit(monthBeforeCurrent);
  }

  private loadNext(): void {
    this.calendarApi.next();
    const monthAfterCurrent = this.getMonthBeforeOrAfterCurrent(this.monthsToDisplay(), 'forward');
    this.loadMonths.emit(monthAfterCurrent);
  }

  private get calendarApi() {
    return this.calendarComponent.getApi();
  }

  private monthsToDisplay() {
    if (this.isPlatformWeb) {
      return MONTHS_TO_DISPLAY_PLATFORM;
    }
    return MONTHS_TO_DISPLAY_MOBILE;
  }

  private update() {
    this.mappedPeriods = this.userProfileService.computeEventMap(this.availability._embedded.calendarEvents);
    this.calendarOptions.events = this.mappedPeriods;
  }

  get isUserDeleted() {
    return getEmbeddedResource(this.userProfile, 'profile')['accountStatus'] === accountStatusConstants.deleted;
  }
}
