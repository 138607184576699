import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEmbeddingContext, DashboardExperience } from "amazon-quicksight-embedding-sdk";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  dashboard: DashboardExperience;

  constructor(private httpClient: HttpClient) {}

  public getDashboardURL(url): Observable<any> {
    if (url) {
      return this.requestAPI(url).pipe(map(data => data['url']));
    }
  }

  public async embedDashboard(embeddedURL: string, containerDiv: HTMLElement) {
    const embeddingContext = await createEmbeddingContext();

    let options = {
      url: embeddedURL,
      container: containerDiv,
      width: '100%',
      resizeHeightOnSizeChangedEvent: true
    };
    this.dashboard = await embeddingContext.embedDashboard(options);
  }

  private requestAPI(apiURL) {
    return this.httpClient.post<Observable<DashboardExperience>>(apiURL, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    });
  }
}
