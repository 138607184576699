<div class="contacts-container">
    <div class="contacts-form">
        @if (submitSuccess) {
        <div class="success-submit">
            <success-animation>
                <ng-container message>
                    {{ 'SYSTEM.SUCCESS.CUSTOMER_SUPPORT_FORM_SUCCESS' | translate}}
                </ng-container>
            </success-animation>
        </div>
        } @if (!submitSuccess) {
        <div class="form-body">
            <h3 class="h3">{{ 'SYSTEM.OTHER.CONTACT' | translate }}</h3>
            <div class="form">
                @if (hasCustomerSupportTopics) {
                <div>
                    <label for="subject">Topic*:</label>
                    <ng-select
                        class="topic-select"
                        bindLabel="value"
                        [items]="topicTypes"
                        [(ngModel)]="contactsForm.contactTopic"
                        [multiple]="false"
                        [closeOnSelect]="true"
                        [searchable]="false"
                        [placeholder]="'CONTACTS.TOPIC' | translate"
                    ></ng-select>
                </div>
                }
                <div>
                    <label for="subject">{{ 'CONTACTS.SUBJECT.LABEL' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="subject"
                        autocomplete="off"
                        [placeholder]="'CONTACTS.SUBJECT.PLACEHOLDER' | translate"
                        [disabled]="isLoading"
                        [maxlength]="contactsFormRestrictions.subject.maxLength"
                        [(ngModel)]="contactsForm.subject"
                        (ngModelChange)="checkFieldsValidity()"
                    />
                </div>
                <div>
                    <label for="message">Message*:</label>
                    <textarea
                        class="form-control"
                        id="message"
                        cols="50"
                        rows="10"
                        autocomplete="off"
                        [placeholder]="'CONTACTS.MESSAGE.PLACEHOLDER' | translate"
                        [disabled]="isLoading"
                        [maxlength]="contactsFormRestrictions.message.maxLength"
                        [(ngModel)]="contactsForm.message"
                        (ngModelChange)="checkFieldsValidity()"
                    >
                    </textarea>
                </div>
            </div>
            <general-button
                [text]="'GENERAL.SUBMIT' | translate"
                [isLoading]="isLoading"
                [isDisabled]="!contactsFormRestrictions.isFormValid"
                [customClass]="'btn-loader'"
                (onClick)="submitForm()"
            ></general-button>
        </div>
        }
    </div>
</div>
