import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { RequestsOverviewEffects } from './state/requests-overview.effects';
import {
  REQUESTS_OVERVIEW_COMMON_FEATURE_KEY,
  getReducers
} from './state/requests-overview.reducer';
import { initialState } from './state/requests-overview.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      REQUESTS_OVERVIEW_COMMON_FEATURE_KEY,
      getReducers(),
      {
        initialState
      }
    ),
    EffectsModule.forFeature([RequestsOverviewEffects])
  ]
})
export class RequestOverviewCommonModule {}
