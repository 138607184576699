import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MailboxComponent } from './mailbox.component';
import { ConversationListComponent } from './components/conversation-list/conversation-list.component';
import { MessageListComponent } from './components/message-list/message-list.component';
import {
  FEATURE_STATE_KEY,
  initialState,
  MailboxStateModel
} from './state/mailbox-state.model';
import { getReducers, reducersInjectionToken } from './state/mailbox.reducers';
import { MailboxEffects } from './state/mailbox.effects';
import { MailboxService } from './mailbox.service';
import { MessageComponent } from './components/message/message.component';
import { ConversationItemComponent } from './components/conversation-item/conversation-item.component';
import { MessageDatePipe } from './pipes/message-date/message-date.pipe';
import { UrlDetectorPipe } from './url-detector.pipe';
import { metaReducers } from './state/mailbox.reducers';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { TranslationModule } from '@libs/translation/translation.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ConversationHeaderComponent } from './components/conversation-header/conversation-header.component';
import { MessageInputComponent } from './components/message-input/message-input.component';
import { ConversationViewerComponent } from './components/conversation-viewer/conversation-viewer.component';
import { WebsocketModule } from 'libs/websocket/src/lib/websocket.module';

export function getInitialState(): Partial<MailboxStateModel> {
  return {
    ...initialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_STATE_KEY, reducersInjectionToken, {
      initialState: getInitialState,
      metaReducers
    }),
    EffectsModule.forFeature([MailboxEffects]),
    TranslationModule,
    FormsModule,
    HttpClientModule,
    CommonUiModule,
    FileUploadModule,
    WebsocketModule
  ],
  declarations: [
    MailboxComponent,
    ConversationListComponent,
    MessageListComponent,
    MessageComponent,
    MessageDatePipe,
    UrlDetectorPipe,
    ConversationItemComponent,
    ConversationHeaderComponent,
    MessageInputComponent,
    ConversationViewerComponent
  ],
  providers: [
    MailboxService,
    { provide: reducersInjectionToken, useFactory: getReducers }
  ],
  exports: [MailboxComponent]
})
export class MailboxModule {}
