import {AppState, CustomerSupportTopicTypeState, FacilityProfileState, initialState, MroFacilitySimple} from './app-state.model'

import {InjectionToken} from '@angular/core'
import {routerReducer} from '@ngrx/router-store'
import {ActionReducerMap, createReducer, on} from '@ngrx/store'
import {CustomerSupportTopicsSuccessfullyReturned, MroFacilitiesForReportsLoaded, SimpleMroListLoaded} from './app.actions'
import {cloneDeep, isNil} from 'lodash-es'
import {UserProfileActions} from '@libs/user-profile/state/user-profile.actions'
import {environmentReducer} from '@libs/shared/bms-common/environment/environment.reducer'
import {getAuthorizedProfileFromState, getEmbeddedResource, getLink, hasLink} from '@libs/shared/bms-common/rest/resource.utils'
import {ApiRootState} from '@libs/shared/bms-common/api-root/api-root.model'
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel'
import {bmsApiRootReducer} from '@libs/shared/bms-common/api-root/api-root.reducers'
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel'
import {
  ApprovedAgenciesLoaded,
  FacilityProfileLoaded,
  FailedToGetApprovedAgencies,
  FailedToGetMroRates,
  FailedToSubmitApprovedAgencies,
  FailToSubmitMroRates,
  GetApprovedAgencies,
  GetMroRates,
  MroApprovalsLoaded,
  MroRatesLoaded,
  ResetFacilityProfile,
  SubmitApprovedAgencies,
  SubmitMroRates
} from '@libs/common-ui/facility-profile/facility-profile.actions'
import {GlobalReset} from '@libs/shared/bms-common/api-root/api-root.actions'

export function getReducers(): ActionReducerMap<AppState> {
  return {
    apiRoot: apiRootReducer,
    navigation: state => state,
    router: routerReducer,
    environment: environmentReducer,
    facilityProfile: facilityProfileReducer,
    mroFacilities: mroFacilitiesReducer,
    customerSupportTopicTypes: customerSupportTopicTypesReducer
  }
}

export function mroFacilitiesReducer(
  state: MroFacilitySimple[] = initialState.mroFacilities,
  action
): MroFacilitySimple[] {
  switch (action.type) {
    case MroFacilitiesForReportsLoaded.type:
      return getEmbeddedResource(action.response, 'facilities')
    case GlobalReset.type:
      return initialState.mroFacilities
  }
  return state
}

const facilityProfileReducer = createReducer<FacilityProfileState>(
  initialState.facilityProfile,
  on(ResetFacilityProfile, GlobalReset, () => initialState.facilityProfile),
  on(FacilityProfileLoaded, (state, {payload}) => ({
    ...state,
    ...payload
  })),
  on(SubmitApprovedAgencies, GetApprovedAgencies, state => ({
    ...state,
    approvedAgenciesStatus: 'LOADING'
  })),
  on(SubmitMroRates, GetMroRates, state => ({
    ...state,
    mroRatesStatus: 'LOADING'
  })),
  on(FailedToGetApprovedAgencies, ApprovedAgenciesLoaded, state => ({
    ...state,
    approvedAgenciesStatus: 'IDLE'
  })),
  on(FailedToGetMroRates, MroRatesLoaded, state => ({
    ...state,
    mroRatesStatus: 'IDLE'
  })),
  on(FailedToSubmitApprovedAgencies, state => ({
    ...state,
    approvedAgenciesStatus: 'DIRTY'
  })),
  on(FailToSubmitMroRates, state => ({
    ...state,
    mroRatesStatus: 'DIRTY'
  })),
  on(ApprovedAgenciesLoaded, (state, {payload}) => ({
    ...state,
    approvedAgencies: payload
  })),
  on(MroRatesLoaded, (state, {rates}) => ({
    ...state,
    rates
  })),
  on(MroApprovalsLoaded, (state, {payload}) => ({
    ...state,
    mroApprovals: payload
  }))
)

export function apiRootReducer(state: ApiRootState, action: any): ApiRootState {
  const updatedState = cloneDeep(state)
  switch (action.type) {
    case UserProfileActions.UpdateAuthorizedUserPicture.type: {
      const authorizedUser = getAuthorizedProfileFromState(state)
      if (!hasLink(authorizedUser, UserProfileLinkRel.GetProfilePicture)) {
        return state
      }
      const picLink = cloneDeep(
        getLink(authorizedUser, UserProfileLinkRel.GetProfilePicture)
      )
      if (!isNil(picLink)) {
        picLink.href = action.updatedPicUrl
        getAuthorizedProfileFromState(updatedState)._links[
          UserProfileLinkRel.GetProfilePicture
          ] = picLink
        return updatedState
      } else {
        return state
      }
    }
    case UserProfileActions.UpdateAuthorizedUserInfo.type:
      if (!isNil(action.payload)) {
        const updatedProfile: any = getEmbeddedResource(action.payload, 'profile')
        const authorizedUserProfile = updatedState.apiRoot._embedded.authorizedUserProfile as any
        authorizedUserProfile.firstName = updatedProfile.firstName
        authorizedUserProfile.lastName = updatedProfile.lastName
        return updatedState
      } else {
        return state
      }
    case SimpleMroListLoaded.type:
      updatedState.apiRoot._embedded[ApiRootLinkRel.MroFacilityList] =
        action.facilities
      return updatedState
  }
  return bmsApiRootReducer(state, action)
}

const customerSupportTopicTypesReducer = createReducer<CustomerSupportTopicTypeState[]>(
  initialState.customerSupportTopicTypes,
  on(CustomerSupportTopicsSuccessfullyReturned, (state, {topicTypes}) => (topicTypes))
)

export const reducersInjectionToken =
  new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers')
