<div *ngIf="currentFeedback" class="alert alert-info fade show alert-dismissible" role="alert">
    <div class="feedback-wrapper">
        <strong>
            {{
                feedbackAlertMessage
                    | translate : { refNumber: currentFeedback?.refNumber, mroName: currentFeedback?.mroName }
            }}</strong
        >
        <div class="feedback-buttons">
            <general-button
                (onClick)="openFeedbackForm()"
                size="small"
                [text]="'GENERAL.FEEDBACK' | translate"
            ></general-button>
            <general-button
                (onClick)="cancelMroFeedback()"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.CANCEL' | translate"
            ></general-button>
        </div>
    </div>
</div>
