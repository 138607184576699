<i class="material-icons close-icon" (click)="bsModalRef.hide()"> clear </i>
<div class="header">
    <h1 class="heading">{{ 'Reject Technician' | translate }}</h1>
    <p class="text-muted sub-heading">
        {{
            'You must write an explanation for this rejection. It will be visible in the contract card and in the notification email of both the Technician and the selected Agency.'
                | translate
        }}
    </p>
</div>
<div class="content">
    <form
        [formGroup]="form"
        *ngIf="reasonsContainQualityReasons()"
        class="mb-4"
    >
        <div
            class="custom-control custom-checkbox"
            *ngFor="let reason of reasonsGetter.controls; let i = index"
        >
            <input
                type="checkbox"
                class="custom-control-input"
                id="reasonsArray{{ i }}"
                [formControl]="reason"
                (change)="getSelectedReasonsValue(); isFormValid()"
            />
            <label class="custom-control-label" for="reasonsArray{{ i }}">
                {{ reasonsArray[i].name | translate }}
            </label>

            <div
                class="reason-description"
                [ngClass]="{ show: form.value.reasons[i] }"
            >
                <input
                    class="form-control"
                    type="text"
                    placeholder="{{
                        'Enter Decline Reason explanation' | translate
                    }}"
                    (keydown)="validateDescriptionLength($event)"
                    (input)="
                        setSelectedReasonDescription($event, reasonsArray[i].id)
                    "
                />
            </div>
        </div>
    </form>
    <div class="notes-container" *ngIf="!reasonsContainQualityReasons()">
        <label class="notes-label" for="notes">Rejection reason:</label>
        <textarea
            id="notes"
            name="notes"
            class="form-control notes"
            rows="8"
            autofocus
            [(ngModel)]="explanation"
            [maxlength]="textAreaMaxLength"
            (input)="charactersCounter($event)"
        >
        </textarea>
        <small class="counter">
            <span>{{ charactersCount }}</span> / {{ textAreaMaxLength }}
        </small>
    </div>
    <general-button
        (onClick)="submit()"
        [isDisabled]="isDisabled"
        text="Submit"
    ></general-button>
</div>
