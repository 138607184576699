import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { RequestsOverviewModule } from './modules/request-overview/requests-overview.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationModule } from './modules/registration/registration.module';
import { SharedModule } from './modules/shared/shared.module';
import { AppState, initialState as appInitialState } from './state/app-state.model';
import { AppEffects } from './state/app.effects';
import { getReducers, reducersInjectionToken } from './state/app.reducer';

import { ApiConfiguration } from '../config/api.config';
import { AgencyRequestOverviewWrapperModule } from './modules/agency-request-overview-wrapper/agency-request-overview-wrapper.module';
import { FeedbackModule } from './modules/feedback/feedback.module';
import { HelpWrapperModule } from './modules/help-wrapper/help-wrapper.module';
import { MailboxModule } from '@libs/mailbox/mailbox.module';
import { NotificationsModule } from '@libs/notifications/notifications.module';
import { UserProfileModule } from '@libs/user-profile/user-profile.module';
import { getEnvironmentInitialState } from '@libs/shared/bms-common/environment/environment.loader';
import { BmsApiRootEffects } from '@libs/shared/bms-common/api-root/api-root.effects';
import { ReportsResolverService } from '@libs/shared/services/reports-resolver.service';
import { SharedModule as SharedLibModule } from '@libs/shared/shared.module';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from '../config/cookiebot.config';
import { ReportsPlatformModule } from './modules/reports-platform/reports-platform.module';
import { CreateOfferPageModule } from '@libs/create-offer-page/create-offer-page.module';
import { MyTasksModule } from './modules/my-tasks/my-tasks.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PackageOfferModule } from '@libs/package-offer/package-offer.module';
import { AuthenticationModule } from '@libs/auth/authentication.module';
import { VerifyEmailModule } from './modules/verify-email/verify-email.module';
import { CreateAgencyOfferPageModule } from '@libs/create-agency-offer-page/create-agency-offer-page.module';
import { DashboardsModule } from './modules/dashboards/dashboards.module';
import { CreatePermanentOfferPageModule } from '../../../../libs/offer-management/create-permanent-offer/src/lib/create-permanent-offer-page.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export function getInitialState(): Partial<AppState> {
  return {
    ...appInitialState,
    environment: getEnvironmentInitialState()
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    AuthenticationModule.forRoot(),
    SharedLibModule.forRoot(ApiConfiguration),
    AppRoutingModule,
    NotificationsModule,
    RequestsOverviewModule,
    CreateOfferPageModule,
    CreatePermanentOfferPageModule,
    CreateAgencyOfferPageModule,
    PackageOfferModule,
    FeedbackModule,
    AgencyRequestOverviewWrapperModule,
    StoreModule.forRoot(reducersInjectionToken, {
      initialState: getInitialState
    }),
    EffectsModule.forRoot([AppEffects, BmsApiRootEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      //TODO(SN-970): constant
      maxAge: 50,
      connectInZone: true
    }),
    RegistrationModule,
    VerifyEmailModule,
    UserProfileModule,
    MailboxModule,
    BrowserAnimationsModule,
    HelpWrapperModule,
    MyTasksModule,
    NgxCookiebotModule.forRoot(CookiebotConfig),
    CarouselModule.forRoot(),
    ReportsPlatformModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    DashboardsModule
  ],
  providers: [
    { provide: reducersInjectionToken, useFactory: getReducers },
    ReportsResolverService,
    provideEnvironmentNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
