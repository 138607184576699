import { Component, Input } from '@angular/core';
import { FacilityProfileState } from '../../../../../apps/staffnow-platform/src/app/state/app-state.model';
import { FacilityStatus } from '@libs/shared/models/facility.model';

@Component({
  selector: 'staffnow-create-offer-banner',
  templateUrl: './create-offer-banner.component.html'
})
export class CreateOfferBannerComponent {
  @Input() public facilityProfile: FacilityProfileState;
  @Input() public invitationTarget: 'technicians' | 'agencies' = 'technicians';

  public facilityStatusIsPending(): boolean {
    return this.facilityProfile?.status == FacilityStatus.PENDING_REVIEW;
  }
}
