import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export const FEEDBACK_FORM_ID = 'feedbackForm';

export interface FeedbackQuestion {
  id: number;
  feedbackId: number;
  question: string;
  answer: number;
}

export interface FeedbackFormModel {
  id: number;
  feedbackQuestions: FeedbackQuestion[];
}

export interface FeedbackFormState extends FormGroupState<FeedbackFormModel> {}

export const initialFormState: FeedbackFormState = createFormGroupState<FeedbackFormModel>(
  FEEDBACK_FORM_ID,
  {
    id: null,
    feedbackQuestions: [
      {
        id: null,
        feedbackId: null,
        question: '',
        answer: null
      },
      {
        id: null,
        feedbackId: null,
        question: '',
        answer: null
      },
      {
        id: null,
        feedbackId: null,
        question: '',
        answer: null
      },
      {
        id: null,
        feedbackId: null,
        question: '',
        answer: null
      },
      {
        id: null,
        feedbackId: null,
        question: '',
        answer: null
      },
      {
        id: null,
        feedbackId: null,
        question: '',
        answer: null
      }
    ]
  }
);
