import {Injectable, OnDestroy} from '@angular/core';
import { FileUploader, FileUploaderOptions } from "ng2-file-upload";
import {MathService} from '@libs/shared/services/math.service';
import {fileUploadConstants} from '@libs/shared/constants/fileupload.constants';
import {Header, HeaderProviderService} from "@libs/auth/services/header-provider.service";
import { Subscription } from 'rxjs';
import {StorageService} from "@libs/shared/services/storage.service";
import {HttpClient} from "@angular/common/http";
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";

@Injectable()
export class FileUploadService implements OnDestroy {
  public uploader: FileUploader;
  private appVersionHeader: Header;
  private appClassHeader: Header;

  private subscription: Subscription = new Subscription();

  constructor(
    private mathService: MathService,
    private storageService: StorageService,
    private headerProviderService: HeaderProviderService,
    private httpClient: HttpClient
  ) {
    this.subscription.add(this.headerProviderService.appVersionObservable.subscribe((newAppVersion: Header) => this.appVersionHeader = newAppVersion));
    this.subscription.add(this.headerProviderService.appClassObservable.subscribe((newAppClass: Header) => this.appClassHeader = newAppClass));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async configUploader(
      URL: string,
      fileSizeLimit: number,
      fileTypesList: Array<string>
  ) {
    const uploader = new FileUploader({} as FileUploaderOptions);

    const setAllowedMimeTypes = (
      allowedFileTypesList: Array<string>
    ): Array<string> => {
      const allowedMimeTypesList: Array<string> = [];
      allowedFileTypesList.forEach(fileType => {
        allowedMimeTypesList.push(
          fileUploadConstants.fileTypes[fileType].mimeType
        );
      });
      return allowedMimeTypesList;
    };

    uploader.setOptions({
      url: URL,
      autoUpload: true,
      removeAfterUpload: false,
      authToken: (await this.headerProviderService.getBearerAccessToken()).value,
      allowedMimeType: setAllowedMimeTypes(fileTypesList),
      maxFileSize: this.mathService.convertMBToByte(fileSizeLimit),
      queueLimit: 10,
      headers: [
        { name: this.appVersionHeader.name, value: this.appVersionHeader.value },
        { name: this.appClassHeader.name, value: this.appClassHeader.value }
      ]
    });

    return uploader;
  }

  public fileListToExtensionCSV(acceptedFileFormats: Array<string>): string {
    const fileFormats: Array<string> = [];

    acceptedFileFormats.forEach(fileFormat => {
      fileFormats.push(fileUploadConstants.fileTypes[fileFormat].extention);
    });
    return fileFormats.join(', ');
  }

  public downsizeImage(fileItem: any, apiUrl: string, downsizeRate: number, documentInfo: any, handleResponse: Function) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let imageData = fileReader.result;
      const img = document.createElement('img');
      img.src = imageData as string;
      img.addEventListener('load', (e) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = e.target as HTMLImageElement;
        canvas.width = img.width * downsizeRate;
        canvas.height = img.height * downsizeRate;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
          const form = new FormData();
          if (!isPlatformWeb()) {
            fileItem.name = new Date().toISOString() + fileItem.name;
          }
          form.append(
            'file', blob, fileItem.name
          );
          form.append(
            'document',
            new Blob([JSON.stringify(documentInfo)], { type: 'application/json' })
          );
          const response = this.httpClient.post<any>(
            apiUrl,
            form
          );
          handleResponse(response);
        }, 'image/jpeg');
      })
    }
    fileReader.readAsDataURL(fileItem.rawFile);
  }
}
