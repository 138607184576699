import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChangeNewsletterSubscription } from '@libs/user-profile/state/user-profile.actions';
import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { Observable } from 'rxjs';
import { getBrandName } from '@libs/shared/bms-common/environment/environment.selector';

@Component({
  selector: 'staffnow-news-letter-subscription',
  templateUrl: 'news-letter-subscription.component.html',
  styleUrls: ['news-letter-subscription.component.scss']
})
export class NewsLetterSubscriptionComponent implements OnChanges {
  @Input() public userProfile: TechnicianProfileModel;
  @HostBinding('class.isSubscribed')
  public isSubscribedToNewsletter: boolean = false;
  public isClicked: boolean = false;
  public apiBrandName$: Observable<string>;

  constructor(private store: Store<any>) {
    this.apiBrandName$ = this.store.pipe(getBrandName);
  }

  ngOnChanges(): void {
    if (
      this.isSubscribedToNewsletter != this.userProfile.isSubscribedToNewsletter
    ) {
      this.isClicked = false;
      this.isSubscribedToNewsletter = this.userProfile.isSubscribedToNewsletter;
    }
  }

  public handleChange(): void {
    this.isClicked = true;
    this.store.dispatch(
      ChangeNewsletterSubscription({
        url: getUrl(
          this.userProfile,
          UserProfileLinkRel.ChangeNewsletterSubscription
        )
      })
    );
  }
}
