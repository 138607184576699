import {Component} from "@angular/core";
import {AbstractValueAccessor, MakeProvider} from "@libs/shared/helpers/abstract-value-accessor";

@Component({
  selector: 'staffnow-toggle',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [MakeProvider(ToggleSwitchComponent)]
})
export class ToggleSwitchComponent extends AbstractValueAccessor {

}
