import { createSelector } from '@ngrx/store';
import { FEEDBACK_STATE_KEY, FeedbackState } from './feedback.reducer';

export const selectFeatureFromAppState = (state: any) =>
  state[FEEDBACK_STATE_KEY] as FeedbackState;

export const selectFeedbackList = createSelector(
  selectFeatureFromAppState,
  state => state.feedbackList
);

export const selectFeedbackForm = createSelector(
  selectFeatureFromAppState,
  state => state.feedbackForm
);
