import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  Notification,
  NotificationType
} from '@libs/notifications/notification.model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'staffnow-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() public notification: Notification = null;
  @HostBinding('class.is-clickable') get isClickable(): boolean {
    return !isNil(this.notification.payload.extendedLinkTo);
  }
  public NOTIFICATION_TYPES = NotificationType;

  constructor(private router: Router) {}

  public handleNotificationClick() {
    if (this.isClickable) {
      this.router.navigateByUrl(this.notification.payload.extendedLinkTo);
    }
  }
}
