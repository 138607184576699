<p class="tooltip-container">
    <span
        #tooltipElement="bs-tooltip"
        [tooltip]="tooltipMessage"
        [placement]="placement"
        triggers=""
    ></span>
</p>
<span
    class="material-icons-outlined info-button help"
    [ngClass]="{ clicked: clicked }"
    (mouseenter)="showTooltip(tooltipElement)"
    (mouseleave)="hideTooltip(tooltipElement)"
    (click)="handleClick(tooltipElement)"
>
    help_outline
</span>
<span
    *ngIf="displayHelpLabel"
    class="help label"
    (mouseenter)="showTooltip(tooltipElement)"
    (mouseleave)="hideTooltip(tooltipElement)"
    (click)="handleClick(tooltipElement)"
    >Help</span
>
