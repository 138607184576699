<div
    *ngIf="areDocumentsNeeded"
    class="reasons-group warning"
>
    <label>{{ 'AGENCY_OFFER_CONTRACT_REASONS_LIST.AGENCY_PENDING' | translate }}</label>
    <staffnow-info-icon-with-tooltip
        placement="left"
        [big]="true"
        [tooltipMessage]="documentsNeededExplanation | translate"
    ></staffnow-info-icon-with-tooltip>
</div>
<div
    *ngIf="isRejectedByAgency"
    class="reasons-group"
>
    <label>{{ 'AGENCY_OFFER_CONTRACT_REASONS_LIST.AGENCY_REJECTION' | translate }}</label>
    <staffnow-info-icon-with-tooltip
        placement="left"
        [big]="true"
        [tooltipMessage]="rejectReason | translate"
    ></staffnow-info-icon-with-tooltip>
</div>
