import {Component, Input} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {isNil} from "lodash-es";
import {ExperienceConfirmationRequestPayload} from "./experience-confirmation-request.model";
import {TechnicianRequestGenericComponent} from '../technician-request-generic.component';

@UntilDestroy()
@Component({
  selector: 'experience-confirmation-request',
  templateUrl: './experience-confirmation-request.component.html',
  styleUrls: ['./../technician-request-generic.component.scss']
})
export class ExperienceConfirmationRequestComponent extends TechnicianRequestGenericComponent<ExperienceConfirmationRequestPayload> {
  @Input() public requesterName: string = null;
  @Input() public technicianName: string = null;
  @Input() public requesterPosition: string = null;
  @Input() public onSendCallback: Function = null;
  public toDate: string = null;
  public fromDate: string = null;
  public recipientName: string = null;
  public recipientAviationCompany: string = null;
  public requestReason: string = null;
  public recipientEmail: string = null;
  public additionalRequest: string = null;

  get requesterNameAndPosition(): string {
    return `${this.requesterName}, ${this.requesterPosition}`;
  }

  public isDisabled(): boolean {
    return (
      isNil(this.toDate) ||
      isNil(this.fromDate) ||
      !this.isValidEmail(this.recipientEmail) ||
      this.isMissing(this.requesterName) ||
      this.isMissing(this.technicianName) ||
      this.isMissing(this.requesterPosition) ||
      this.isMissing(this.recipientName) ||
      this.isMissing(this.recipientAviationCompany) ||
      this.isMissing(this.requestReason) ||
      this.isMissing(this.additionalRequest)
    );
  }

  protected get sendCallback(): Function {
    return this.onSendCallback;
  }

  public updateFromDate(value: string): void {
    this.fromDate = value;
  }

  public updateToDate(value: string): void {
    this.toDate = value;
  }

  protected preparePayload(): ExperienceConfirmationRequestPayload {
    return {
      requesterName: this.requesterName.trim(),
      technicianName: this.technicianName.trim(),
      requesterPosition: this.requesterPosition.trim(),
      recipientName: this.recipientName.trim(),
      recipientAviationCompany: this.recipientAviationCompany.trim(),
      recipientEmail: this.recipientEmail.trim(),
      requestReason: this.requestReason.trim(),
      fromDate: this.fromDate,
      toDate: this.toDate,
      additionalRequest: this.additionalRequest.trim(),
    };
  }

}
