<div class="modal-header">
    <h3 class="modal-title h3 pull-left">
        {{ 'FEEDBACK.FORM.TITLE' | translate : { mroName: currentFeedback.mroName } }}
    </h3>
    <borderless-button
        (onClick)="closeAndResetForm()"
        [attr.aria-label]="'GENERAL.CLOSE' | translate"
        class="pull-right"
        size="small"
        icon="close"
        color="dark"
    ></borderless-button>
</div>
<form autocomplete="off" [ngrxFormState]="feedbackFormState.controls" novalidate>
    <div class="modal-body">
        <h4 style="margin-bottom: 10px">
            {{ 'FEEDBACK.FORM.BODY' | translate }}
        </h4>
        <div
            *ngFor="let formControl of feedbackFormState.controls.feedbackQuestions.controls; let i = index"
            class="custom-control custom-radio"
        >
            <table class="table sv_q_matrix">
                <thead>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width: 50%">
                            {{ formControl.value.question }}
                        </td>
                        <td>
                            <input
                                type="radio"
                                class="sv_q_m_label"
                                name="answer{{ formControl.value.id }}"
                                value="1"
                                [ngrxFormControlState]="formControl.controls.answer"
                            />
                        </td>
                        <td>
                            <input
                                type="radio"
                                class="sv_q_m_label"
                                name="answer{{ formControl.value.id }}"
                                value="2"
                                [ngrxFormControlState]="formControl.controls.answer"
                            />
                        </td>
                        <td>
                            <input
                                type="radio"
                                class="sv_q_m_label"
                                name="answer{{ formControl.value.id }}"
                                value="3"
                                [ngrxFormControlState]="formControl.controls.answer"
                            />
                        </td>
                        <td>
                            <input
                                type="radio"
                                class="sv_q_m_label"
                                name="answer{{ formControl.value.id }}"
                                value="4"
                                [ngrxFormControlState]="formControl.controls.answer"
                            />
                        </td>
                        <td>
                            <input
                                type="radio"
                                class="sv_q_m_label"
                                name="answer{{ formControl.value.id }}"
                                value="5"
                                [ngrxFormControlState]="formControl.controls.answer"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <general-button
            (onClick)="submitFeedback()"
            [isDisabled]="!feedbackFormState.isValid"
            text="{{ 'GENERAL.SUBMIT' | translate }}"
        ></general-button>
    </div>
</form>
