import { Component, Input } from '@angular/core';
import { FacilityStatus } from '@libs/shared/models/facility.model';

@Component({
  selector: 'staffnow-facility-status',
  templateUrl: './facility-status.component.html',
  styleUrls: ['./facility-status.component.scss']
})
export class FacilityStatusComponent {
  @Input() public status: FacilityStatus = null;

  public handleStatusColoring(): string {
    switch (this.status) {
      case FacilityStatus.VERIFIED:
        return 'text-success';
      case FacilityStatus.DELETED:
        return 'text-danger';
      default:
        return 'text-warning';
    }
  }

  public humanReadableFacilityStatus(): string {
    switch (this.status) {
      case FacilityStatus.VERIFIED:
        return 'FACILITY_STATUS.VERIFIED';
      case FacilityStatus.DELETED:
        return 'FACILITY_STATUS.DELETED';
      default:
        return 'FACILITY_STATUS.PENDING_TO_REVIEW';
    }
  }
}
