<staffnow-go-back-button
    [currentPath]="['Job Openings and Applications' | translate,
    'AGENCY.DETAILS.AGENCY_JO_DETAILS' | translate]"
></staffnow-go-back-button>
@if (isLoading) {
<staff-loader customClass="splash-screen-loader"></staff-loader>
} @else {
<staffnow-offer-details
    *ngIf="!isLoading"
    [offer]="offer"
    [title]="'AGENCY.DETAILS.CONTRACTS_AGENCY_JOB' | translate: {offerDescription}"
>
    <ng-container data>
        <staffnow-contract-info [label]="'GENERAL.PRIORITY' | translate">
            <staffnow-offer-priority-info
                [displayLabel]="false"
                [priority]="offer?.priority"
            ></staffnow-offer-priority-info>
        </staffnow-contract-info>
        <staffnow-contract-info [label]="'GENERAL.OPEN_POSITIONS' | translate">
            {{ offer?.vacancies }}
        </staffnow-contract-info>
        <staffnow-contract-info [label]="'GENERAL.FILLED_POSITIONS' | translate">
            {{ offer?.fullyMatchedSize }}
            <small class="text-muted"> ({{ calculateRemainingPositions + ' remaining' | translate }}) </small>
        </staffnow-contract-info>
        @if (!offer.hasHiddenRate) {
        <staffnow-contract-info [label]="'GENERAL.PAY_RATE' | translate">
            @if (hasIndicativeRateForOffer(offer)) {
            {{ offer?.payRate | rate }}
            {{ ' - ' }}
            {{ offer?.maxPayRate | rate }}
            {{ ' per hour' | translate }}
            } @else {
            {{ offer?.payRate | rate }}
            {{ ' per hour' | translate }}
            }
        </staffnow-contract-info>
        }
    </ng-container>
    <ng-container actions>
        @if (canDeleteAgencyOffer) {
        <general-button
            (onClick)="handleDeleteAgencyOffer()"
            backgroundStyle="outline"
            size="small"
            [text]="'AGENCY.DETAILS.DELETE_AGENCY_JO' | translate"
            color="danger"
            tooltip="{{ 'SYSTEM.INFO.DELETE_JO' | translate}}"
        ></general-button>
        } @if(canCloseAgencyOffer) {
        <general-button
            (onClick)="closeAgencyOffer()"
            backgroundStyle="outline"
            size="small"
            [text]="'AGENCY.DETAILS.CLOSE_FOR_APPLICATIONS' | translate"
            color="danger"
            tooltip="{{'SYSTEM.INFO.CLOSE_JO_TOOLTIP' | translate}}"
        ></general-button>
        } @if (canReopenAgencyOffer) {
        <div class="contract-action">
            <general-button
                (onClick)="reopenAgencyOffer()"
                [isDisabled]="isAgencyOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.REOPEN' | translate"
                tooltip="{{ 'SYSTEM.INFO.REOPEN_JO_TOOLTIP' | translate}}"
            ></general-button>
            @if(isAgencyOfferEnded) {
            <staffnow-info-icon-with-tooltip
                *ngIf="isAgencyOfferEnded"
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
            }
        </div>
        } @if (canEditAgencyOffer) {
        <general-button
            (onClick)="openEditAgencyOfferForm()"
            backgroundStyle="outline"
            size="small"
            [text]="'AGENCY.DETAILS.EDIT_JOB_OPENING' | translate | uppercase"
            tooltip="{{'SYSTEM.INFO.EDIT_JO' | translate}}"
        ></general-button>
        }
    </ng-container>
</staffnow-offer-details>
<staffnow-collapsible-agency-offer-contracts-container [contracts]="contracts">
    <ng-container no-data>
        <h2 class="h2">
            {{ 'AGENCY.DETAILS.NO_APPLICANTS' | translate }}
        </h2>
    </ng-container>
</staffnow-collapsible-agency-offer-contracts-container>
}
