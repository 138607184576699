import { Component } from '@angular/core';
import { AuthenticationService } from '@libs/auth/services/authentication.service';

@Component({
  selector: 'staffnow-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss']
})
export class UnauthorizedPageComponent {
  private readonly REDIRECT_TIMEOUT_MILLISECONDS: number = 6000;

  constructor(authenticationService: AuthenticationService) {
    authenticationService.clearAuthentication();
    setTimeout(() => {
      authenticationService.goToLoginPage();
    }, this.REDIRECT_TIMEOUT_MILLISECONDS)
  }
}
