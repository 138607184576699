import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {ConversationService} from '../../services/conversation.service';
import {
  AcceptAgencyOfferContract,
  DeclineAgencyOfferContract,
  LoadPublicProfile,
  MarkAgencyOfferContractAsDocumentsNeeded
} from '@libs/request-overview-common/state/requests-overview.actions';
import {RequestOverviewLinkRel} from '@libs/shared/linkrels/request-overview.linkrel';
import {getLink} from '@libs/shared/bms-common/rest/resource.utils';
import {EntityTypes} from '@libs/shared/models/entity-types.enum';
import {AgencyOfferContractOutDto} from '@libs/shared/models/agency-offer-contract.model';
import {ContractStatus} from '@libs/shared/models/contract-status.enum';
import {ModalService} from '@libs/common-ui/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-agency-offer-contract-card',
  templateUrl: './agency-offer-contract-card.component.html',
  styleUrls: ['./agency-offer-contract-card.component.scss']
})
export class AgencyOfferContractCardComponent {
  @Input() public contract: AgencyOfferContractOutDto = null;
  @Input() public offerRefNumber: string = null;

  constructor(
    private store: Store,
    private conversationService: ConversationService,
    private modalService: ModalService,
    private translateService: TranslateService
  ) {
  }

  public acceptContract() {
    this.store.dispatch(AcceptAgencyOfferContract({
      refNumber: this.contract.offerRefNumber,
      url: getLink(this.contract, RequestOverviewLinkRel.AcceptAgencyOfferContract).href,
      isBackoffice: false
    }));
  }

  get declineOfferTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.DECLINE_JOB_OPENING');
  }

  public declineContract() {
    this.modalService.openTextAreaDialog(
      this.translateService.instant('CONTRACTS.DECLINE'),
      this.translateService.instant('CONTRACTS.DECLINE.REASON'),
      null,
      (text) => {
        this.store.dispatch(DeclineAgencyOfferContract({
          refNumber: this.contract.offerRefNumber,
          url: getLink(this.contract, RequestOverviewLinkRel.DeclineAgencyOfferContract).href,
          reason: text,
          isBackoffice: false
        }));
      }
    );
  }

  get documentsNeededTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.DOCUMENTS_NEEDED');
  }

  public markContractAsDocumentsNeeded() {
    this.modalService.openTextAreaDialog(
      this.translateService.instant('CONTRACTS.REQUEST_MORE_DOCS'),
      this.translateService.instant('CONTRACTS.REQUEST_MORE_DOCS.BODY'),
      null,
      (text) => {
        this.store.dispatch(MarkAgencyOfferContractAsDocumentsNeeded({
          refNumber: this.contract.offerRefNumber,
          url: getLink(this.contract, RequestOverviewLinkRel.MarkAgencyOfferContractAsDocumentsNeeded).href,
          explanation: text,
          isBackoffice: false
        }));
      }
    );
  }

  public onOpenPublicProfile(entityType: EntityTypes): void {
    this.store.dispatch(
      LoadPublicProfile({contract: this.contract, entityType})
    );
  }

  get hasReasons(): boolean {
    return this.contract.isRejected || this.contract.areDocumentsNeeded
  }

  get isOfferEndedOrClosed(): boolean {
    return this.contract.isAgencyOfferFinished || this.contract.isAgencyOfferExpired;
  }

  get offerEndedOrClosedTooltip(): string {
    return this.translateService.instant(this.contract.isAgencyOfferFinished ?
      'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' : 'SYSTEM.INFO.DISABLED_ACTION_CLOSED_JO');
  }

  get hasAgencyAcceptedAlready(): boolean {
    return this.contract.agencyStatus === ContractStatus.accepted;
  }

  get hasAgencyDeclinedAlready(): boolean {
    return this.contract.agencyStatus === ContractStatus.rejected;
  }

  get canCreateConversationWithTechnician(): boolean {
    return this.conversationService.canCreateConversationWithTechnician(
      this.contract
    );
  }

  get canGetPreviousConversationWithTechnician(): boolean {
    return this.conversationService.canGetPreviousConversationWithTechnician(
      this.contract
    );
  }

  public initiateMessageWithTechnician(): void {
    this.conversationService.initiateMessageWithTechnicianFromAgencyOfferContract(this.contract);
  }

  get applicantConversationMessageTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.MESSAGE_ON_MATCH');
  }
}
