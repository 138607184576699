import { isPlatformWeb } from '@libs/shared/helpers/capacitor';

export const fileUploadConstants = {
  fileTypes: {
    // Allowed document formats below.
    pdf: {
      extention: '.pdf',
      title: 'pdf',
      mimeType: 'application/pdf'
    },
    doc: {
      extention: '.doc',
      title: 'doc',
      mimeType: 'application/msword'
    },
    docx: {
      extention: '.docx',
      title: 'docx',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    // Allowed image formats below.
    png: {
      extention: '.png',
      title: 'png',
      mimeType: 'image/png'
    },
    jpg: {
      extention: '.jpg',
      title: 'jpg',
      mimeType: 'image/jpeg'
    },
    jpeg: {
      extention: '.jpeg',
      title: 'jpeg',
      mimeType: 'image/jpeg'
    },
    tif: {
      extention: '.tif',
      title: 'tif',
      mimeType: 'image/tiff'
    },
    tiff: {
      extention: '.tiff',
      title: 'tiff',
      mimeType: 'image/tiff'
    }
  },
  shortListLimit: 4,
  noDocumentsFoundText: isPlatformWeb() ? 'FILES.NO_DOCUMENTS_FOUND_TEXT_WEB' : 'FILES.NO_DOCUMENTS_FOUND_TEXT_MOBILE',
  addingFileFailed: 'FILES.ADDING_FILE_FAILED',
  fileHasUnsupportedFormat: 'FILES.UNSUPPORTED_FORMAT',
  fileExceededLimit: 'FILES.EXCEEDED_LIMIT',
  uploaderQueueLimitExceeded: 'FILES.UPLOAD_QUEUE_LIMIT_EXCEEDED',
  limit: 'The limit of files in this section is ',
  successfullFileUpload: 'FILES.SUCCESSFULL_FILE_UPLOAD'
};
