import { Store } from '@ngrx/store';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AbstractAccessGuardService } from './abstract-access-guard.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLoggedInUserRole } from '../bms-common/api-root/api-root.selectors';
import { role, RoleWrapper, UserRoles } from '../models/roles.enum';

export abstract class AbstractRoleGuardService extends AbstractAccessGuardService {
  protected constructor(router: Router, private store: Store<any>) {
    super(router);
  }

  guardCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      getLoggedInUserRole,
      map((r: UserRoles) => this.roleCheck(role(r)))
    );
  }

  abstract roleCheck(r: RoleWrapper): boolean;
}
