import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'staffnow-package-contract-rejection',
  templateUrl: './package-contract-rejection.component.html',
  styleUrls: ['./package-contract-rejection.component.scss']
})
export class PackageContractRejectionComponent {
  @Input() public onRejectCallback: Function;

  public explanation: string = null;
  public charactersCount: number = 0;
  public textAreaMaxLength: number = 450;

  constructor(public bsModalRef: BsModalRef) {}

  get isDisabled(): boolean {
    return isEmpty(this.getExplanation());
  }

  private getExplanation(): string {
    return this.explanation?.trim();
  }

  public charactersCounter(event: any): void {
    this.charactersCount = event.target.value.length;
  }

  public submit(): void {
    if (!this.isDisabled) {
      this.onRejectCallback(this.getExplanation());
      this.close();
    }
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}
