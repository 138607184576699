<span>
    @if (daysOn > 0 && daysOff > 0) {
        {{ 'GENERAL.DAYS_ON' | translate : {count: daysOn} }}
        -
        {{ 'GENERAL.DAYS_OFF' | translate : {count: daysOff} }}
    } @else {
        {{ 'GENERAL.WORKING_DAYS' | translate }}
        {{ 'GENERAL.TO_BE_DISCUSSED' | translate | lowercase }}
    }
</span>
