import { Component, Input } from '@angular/core';
import { OfferOutDto, hasIndicativeRate } from '@libs/shared/models/offer.model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'staffnow-technician-offer-pay-rate',
  templateUrl: './technician-offer-pay-rate.component.html'
})
export class TechnicianOfferPayRateComponent {

  @Input()
  jobOffer: OfferOutDto;

  get offerHasPayRate(): boolean {
    return !isNil(this.jobOffer.payRate);
  }

  public hasIndicativeRateForOffer(offer): boolean {
    return hasIndicativeRate(offer);
  }
}
