<div *ngIf="canSelectRegion()">
    <label for="region-switch">{{ 'GENERAL.REGION' | translate}}</label>
    <select
        id="region-switch"
        [(ngModel)]="selectedRegion"
        (ngModelChange)="load()"
    >
        <option *ngFor="let region of userRegions" [ngValue]="region">
            {{ region | uppercase }}
        </option>
    </select>
</div>
