<div class="logo-wrapper">
    <div
        *ngIf="facilityLogo"
        class="facility-logo"
        [ngStyle]="{
            'background-image': 'url(\'' + facilityLogo + '\')'
        }"
    ></div>
    <div
        class="no-logo"
        *ngIf="!facilityLogo"
        [ngClass]="{ green: greenBackground }"
    >
        <i class="material-icons"> airplanemode_active </i>
    </div>
</div>
