import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { OperatorFunction } from 'rxjs';
import { MroRate } from '@libs/shared/models/mro-rate.model';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import {
  GetMroRates,
  SubmitMroRates
} from '@libs/common-ui/facility-profile/facility-profile.actions';
import {
  selectMroRates,
  selectMroRatesStatus
} from '../../../../../../apps/staffnow-platform/src/app/state/app.selectors';
import { cloneDeep, isNil } from 'lodash-es';
import { role, RoleWrapper, UserRoles } from '@libs/shared/models/roles.enum';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';
import { AmeLicenseLevel } from '@libs/shared/models/ame-license-level.model';
import { MroFacility } from '@libs/shared/models/facility.model';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { BrandLocalizedTextsEnum } from '@libs/shared/constants/brandLocalizedTexts.constants';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'staffnow-rates-table',
  templateUrl: './rates-table.component.html',
  styleUrls: ['./rates-table.component.scss']
})
export class RatesTableComponent implements OnInit {
  @Input() public facilityProfile: MroFacility = null;
  public currencySymbol: string = null;
  public mroRates: MroRate[] = [];
  public ameNomenclatureOptions: AmeLicenseType[] = [];
  public ameLicenseTypeOptions: AmeLicenseLevel[] = [];
  public manualUpdateControlValues = {
    ameLicense: '',
    ameType: '',
    value: null,
    id: null
  };
  public isSingletonTitle: boolean = false;
  public await = false;
  private status = 'IDLE';
  private role: RoleWrapper;

  constructor(
    private store: Store<any>,
    private modalService: ModalService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslateService
  ) {}

  private storeSubscribe<T, S>(
    pipedSelector: OperatorFunction<T, S>,
    subscribeFn: (a: S) => void
  ) {
    this.store.pipe(pipedSelector, untilDestroyed(this)).subscribe(subscribeFn);
  }

  ngOnInit(): void {
    this.currencySymbol = this.facilityProfile?.workingCurrency;
    this.ameNomenclatureOptions = getEmbeddedResource(
      this.facilityProfile,
      FacilityProfileLinkRel.AmeNomenclature
    );
    this.store.dispatch(GetMroRates());
    this.storeSubscribe(
      getLoggedInUserRole,
      (userRole: UserRoles) => (this.role = role(userRole))
    );
    this.storeSubscribe(selectMroRatesStatus, status => (this.status = status));
    this.storeSubscribe(
      selectMroRates,
      rates => (this.mroRates = cloneDeep(rates))
    );
  }

  public canEditRates(): boolean {
    return !this.role.isMroAnyQuality();
  }

  private cleanForm(): void {
    this.ameLicenseTypeOptions = [];
    this.manualUpdateControlValues = {
      ameLicense: '',
      ameType: '',
      value: null,
      id: null
    };
    this.isSingletonTitle = false;
  }

  public onAmeTypeChange(ameType) {
    if (!this.ameNomenclatureOptions) {
      return;
    }
    const selectedAmeType = this.ameNomenclatureOptions.find(
      option => option.name === ameType
    );
    this.ameLicenseTypeOptions = selectedAmeType
      ? selectedAmeType.licenses
      : [];
    this.isSingletonTitle = selectedAmeType?.isSingleton;
    if (selectedAmeType.isSingleton) {
      this.manualUpdateControlValues.id = selectedAmeType.licenses[0].id;
      this.onAmeLevelChange();
    }
  }

  public onAmeLevelChange() {
    const type = this.ameNomenclatureOptions.find(
      option => option.name === this.manualUpdateControlValues.ameType
    );
    const license = type.licenses.find(
      option => Number(option.id) === Number(this.manualUpdateControlValues.id)
    );
    this.manualUpdateControlValues.ameLicense = license.name;
  }

  get specificAmeType(): string[] {
    return [...new Set(this.mroRates.map(entry => entry.ameTitle.type).sort())];
  }

  public getRateForAmeType(ameType: string): MroRate[] {
    return this.mroRates.filter(entry => entry.ameTitle.type === ameType);
  }

  canAddNewRate() {
    return (
      this.manualUpdateControlValues.ameType !== '' &&
      !isNil(this.manualUpdateControlValues.id) &&
      !isNil(this.manualUpdateControlValues.value) &&
      this.manualUpdateControlValues.value >= 0
    );
  }

  private getRateIndex(mroRate: MroRate) {
    return this.mroRates.findIndex(
      entry => Number(entry.ameTitle.id) === Number(mroRate.ameTitle.id)
    );
  }

  public addNewRate() {
    const mroRate: MroRate = {
      ameTitle: {
        id: this.manualUpdateControlValues.id,
        type: this.manualUpdateControlValues.ameType,
        license: this.manualUpdateControlValues.ameLicense
      },
      rate: this.manualUpdateControlValues.value,
      disable: true
    };
    const entryIndex = this.getRateIndex(mroRate);
    if (entryIndex != -1) {
      this.toastMessageService.fail(
        this.translateService.instant('RATES_TABLE.DUPLICATE_RATE')
      );
      this.cleanForm();
    } else {
      this.mroRates.push(mroRate);
      this.status = 'DIRTY';
      this.cleanForm();
    }
  }

  public deleteRate(mroRate: MroRate) {
    this.modalService.openConfirmModal(
      'SYSTEM.INFO.DELETE_MRO_RATE',
      () => {
        this.mroRates.splice(this.getRateIndex(mroRate), 1);
        this.status = 'DIRTY';
      }
    );
  }

  public validateRateInEntries(mroRate: MroRate) {
    // to prevent sending empty charge rate per hour.
    if (mroRate.rate !== null) {
      this.validateEntry(mroRate);
    }
  }

  public validateEntryRate(rate: number) {
    if (rate && rate <= 0) {
      this.manualUpdateControlValues.value = 0;
    }
  }

  public validateEntry(mroRate: MroRate) {
    if (mroRate.rate && mroRate.rate <= 0) {
      mroRate.rate = 0;
    }
  }

  public toggleEdit(mroRate: MroRate) {
    mroRate.disable = !mroRate.disable;
  }

  public changeRate(mroRate: MroRate) {
    this.validateEntry(mroRate);
    this.toggleEdit(mroRate);
    this.status = 'DIRTY';
  }

  get isDirty(): boolean {
    return this.status === 'DIRTY';
  }

  get isLoading(): boolean {
    return this.status === 'LOADING';
  }

  public submitMroRates(): void {
    this.store.dispatch(
      SubmitMroRates({
        rates: this.mroRates
      })
    );
  }

  protected readonly BrandLocalizedTextsEnum = BrandLocalizedTextsEnum;
}
