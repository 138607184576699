import {Component, Input, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {DisableAction, EnableAction, FormGroupState, SetValueAction} from "ngrx-forms";
import {CUSTOM_REFERENCE_NUMBER_MAX_LENGTH, CUSTOM_REFERENCE_NUMBER_MIN_LENGTH} from "@libs/shared/models/offer.model";

@Component({
  selector: 'custom-reference-input',
  templateUrl: './custom-reference-input.component.html',
  styleUrls: ['./custom-reference-input.component.scss']
})
export class CustomReferenceInputComponent implements OnInit {
  protected readonly CUSTOM_REFERENCE_MAX_LENGTH = CUSTOM_REFERENCE_NUMBER_MAX_LENGTH;
  protected readonly CUSTOM_REFERENCE_MIN_LENGTH = CUSTOM_REFERENCE_NUMBER_MIN_LENGTH;

  @Input() public formState: FormGroupState<{ refNumber: string; useCustomReference: boolean; [key: string]: any; }> = null;
  @Input() public isEditMode: boolean = false;
  @Input() public isRestrictedMode: boolean = true;

  constructor(private store: Store<any>) {
  }

  ngOnInit(): void {
    this.disableCustomRefNumber();
  }

  get customReferenceLabel(): string {
    return (this.isEditMode ? 'Change' : 'Custom') + ' reference ';
  }

  public toUpperCaseWithoutWhiteSpaces(): void {
    const refNumber = this.formState.controls.refNumber.value?.trim();
    if (refNumber) {
      this.store.dispatch(
        new SetValueAction(
          this.formState.controls.refNumber.id,
          refNumber.toUpperCase()
        )
      );
    }
  }

  public toggleCustomReference($event): void {
    if ($event.target.checked && !this.isRestrictedMode) {
      this.enableCustomRefNumber();
    } else {
      this.disableCustomRefNumber();
    }
  }

  private enableCustomRefNumber(): void {
    this.store.dispatch(new EnableAction(this.formState.controls.refNumber.id));
    this.store.dispatch(new SetValueAction(this.formState.controls.useCustomReference.id, true));
  }

  private disableCustomRefNumber(): void {
    this.store.dispatch(new DisableAction(this.formState.controls.refNumber.id));
    this.store.dispatch(new SetValueAction(this.formState.controls.useCustomReference.id, false));
  }

}
