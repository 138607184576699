import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap, throttleTime, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ToastMessageService} from '@libs/toast-messages/toast-message.service';
import {
  FailedToSubmitPackageOffer,
  SubmitPackageOffer,
  SucceededToSubmitPackageOffer,
} from './package-offer.actions';
import {getFilteredApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {DURATION_1000_MILLISECONDS} from "@libs/shared/constants/duration.constants";
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Injectable()
export class PackageOfferEffects {
  public submitPackageOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitPackageOffer),
      throttleTime(DURATION_1000_MILLISECONDS),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        const request: Observable<any> = action.editUrl
          ? this.httpService.put(action.editUrl, action.payload)
          : this.httpService.post(
            getUrl(apiRoot, ApiRootLinkRel.PackageOffers),
            action.payload
          );
        return request.pipe(
          map((response: any) => {
            this.toastMessageService.success(response.message);
            return SucceededToSubmitPackageOffer({
              refNumber: response.textPayload
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToSubmitPackageOffer());
          })
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private toastMessageService: ToastMessageService,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {
  }
}
