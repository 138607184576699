import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { Contract } from '@libs/shared/models/contract.model';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { Store } from '@ngrx/store';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';

@UntilDestroy()
@Component({
  selector: 'contract-status',
  templateUrl: './contract-status.component.html',
  styleUrls: ['./contract-status.component.scss']
})
export class ContractStatusComponent implements OnChanges {
  public ENTITY_TYPES = EntityTypes;

  @Input() public isAgencyOfferContract: boolean = false;
  @Input() public contract: Contract | AgencyOfferContractOutDto = null;
  @Output()
  public openPublicProfile: EventEmitter<any> = new EventEmitter<any>();

  public isAgencySelected: boolean = false;

  private userRole: RoleWrapper = null;

  constructor(private store: Store<any>) {
    this.store.pipe(getLoggedInUserRole, untilDestroyed(this)).subscribe(r => {
      this.userRole = role(r);
    });
  }

  ngOnChanges() {
    this.isAgencySelected = hasLink(this.contract, RequestOverviewLinkRel.GetAgency);
  }

  get isUserTechnician() {
    return this.userRole.isTechnician();
  }

  get hasTechnicianProfileLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.GetTechnician);
  }

  public viewProfile(entityType: EntityTypes) {
    this.openPublicProfile.emit(entityType);
  }

  public isAccepted(status: string): boolean {
    return status === ContractStatus.accepted;
  }

  get temporaryJobOfferContract(): Contract {
    return this.contract as Contract;
  }
}
