import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@libs/auth/services/authentication.service';
import { isPlatformIos, isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { Store } from '@ngrx/store';
import { AuthenticationState } from '@libs/auth/state/authentication-state.model';
import { Header, HeaderProviderService } from '@libs/auth/services/header-provider.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'staffnow-update-app',
  templateUrl: './update-app.component.html',
  styleUrls: ['./update-app.component.scss']
})
export class UpdateAppComponent implements OnDestroy {
  appVersionHeader: Header;
  brandName: string = '';
  appUrl: string = '';
  deviceStore: string = isPlatformIos() ? 'App Store' : 'Play Store';
  protected readonly isPlatformWeb: boolean = isPlatformWeb();
  private subscription: Subscription = new Subscription();

  constructor(
    authenticationService: AuthenticationService,
    private router: Router,
    private store: Store<AuthenticationState>,
    private headerProviderService: HeaderProviderService
  ) {
    authenticationService.clearAuthentication();
    this.subscription.add(
      this.headerProviderService.appVersionObservable.subscribe(
        (newAppVersion: Header) => (this.appVersionHeader = newAppVersion)
      )
    );
    this.store.pipe(getStaticEnvironment).subscribe((envData: EnvironmentState) => {
      this.brandName = envData.brandConfig.brandName;
      this.appUrl = envData.brandConfig.appUrl;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reloadWebsite(): void {
    this.router.navigate(['login']).then(() => {
      window.location.reload();
    });
  }
}
