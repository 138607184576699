<nav class="navigation">
    <ul>
        <li
            *ngFor="let item of menuItems"
            [routerLinkActiveOptions]="{
                paths: 'exact',
                queryParams: 'ignored',
                matrixParams: 'ignored',
                fragment: 'ignored'
            }"
            [routerLinkActive]="['active']"
        >
            <a [routerLink]="['/' + item.route]" href="#">{{ item.label | translate }}</a>
        </li>
    </ul>
</nav>
