import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'facility-cover-placeholder',
  templateUrl: './facility-cover-placeholder.component.html'
})
export class FacilityCoverPlaceholderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
