import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {FilterConfigModel} from '../../models/filter-config.model';
import {FilterTypes} from '../../constants/FilterTypes';
import {AmeLicenseType, FullAmeNomenclature} from '@libs/shared/models/ame-license-type.model';
import {isBlank} from '@libs/shared/helpers/help-functions';
import {AmeTitleService} from '@libs/shared/services/ame-title.service';
import {cloneDeep} from "lodash-es";

@Component({
  selector: 'ame-type-license-filter',
  templateUrl: './ame-type-license-filter.component.html',
  styleUrls: ['./ame-type-license-filter.component.scss']
})
export class AmeTypeLicenseComponent implements OnChanges {
  @Input() public initialValue: any = {};
  @Input() public disableType = false;
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
  public typeFilter: FilterConfigModel = {
    type: FilterTypes.Enum,
    key: 'ameType',
    className: 'tech-type-filter',
    label: 'Technicians Type',
    placeholder: 'Technicians type',
    isIdText: true
  };
  public licenseFilter: FilterConfigModel = {
    type: FilterTypes.Enum,
    key: 'ameLicense',
    className: 'tech-level-filter',
    label: 'Technicians Level',
    placeholder: 'Technicians license level',
    isIdText: true
  };
  private ameNomenclature: FullAmeNomenclature;

  constructor(private ameTitleService: AmeTitleService) {
    this.ameNomenclature = this.ameTitleService.getFullNomenclature();
  }

  ngOnChanges(): void {
    this.typeFilter.disabled = this.disableType;
    this.typeFilter = cloneDeep(this.typeFilter);
    this.licenseFilter = cloneDeep(this.licenseFilter);
    this.typeFilter.initialValue = this.initialValue?.ameType;
    this.licenseFilter.initialValue = this.initialValue?.ameLicense;
    this.setUpNomenclature();
  }

  private setUpNomenclature(): void {
    this.typeFilter.items = this.ameNomenclature.list;
    this.setLicenseOptions(this.getAmeLicenseType(this.typeFilter.initialValue));
  }

  private setLicenseOptions(selectedType: AmeLicenseType): void {
    this.licenseFilter.items = selectedType ? selectedType.licenses : [];
    this.licenseFilter.disabled = selectedType?.isSingleton;
  }

  public handleTypeChange(newAmeType: string): void {
    if (this.typeFilter.initialValue !== newAmeType) {
      this.typeFilter.initialValue = newAmeType;
      this.setLicenseOptions(this.getAmeLicenseType(newAmeType));
      this.licenseFilter.initialValue = null;
    }
    this.onChange.emit({
      ameType: newAmeType,
      ameLicense: null
    });
  }

  public handleLicenseChange(newLicense: string): void {
    this.onChange.emit({
      ameType: this.typeFilter.initialValue,
      ameLicense: isBlank(newLicense) ? null : newLicense
    });
  }

  private getAmeLicenseType(ameTypeName: string): AmeLicenseType {
    return this.typeFilter.items?.find(ameType => ameType.name == ameTypeName);
  }
}
