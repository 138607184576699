<loading-container
    [statePath]="['userProfile', 'profileLoading']"
    [loaderConfig]="{ customLoaderClass: 'switch-loader' }"
>
    <div [class]="'switch ' + selectedOption">
        <div class="options-container">
            <span
                title="APPROVE"
                class="yes"
                [ngClass]="{ active: selectedOption === 'yes' }"
                (click)="setAsActive('yes')"
            >
                <i class="material-icons"> check_circle </i>
            </span>
            <span
                *ngIf="selectedOption !== 'no' && selectedOption !== 'yes'"
                title="TO REVIEW"
                class="null"
                [ngClass]="{ active: selectedOption === 'null' }"
                (click)="setAsActive('null')"
            >
                <i class="material-icons"> fiber_manual_record </i>
            </span>
            <span
                title="DISAPPROVE"
                class="no"
                [ngClass]="{ active: selectedOption === 'no' }"
                (click)="setAsActive('no')"
            >
                <i class="material-icons"> cancel </i>
            </span>
        </div>
    </div>
    <!-- <div *ngIf="!pendingChange" class="already-approved">
    <span><i class="material-icons"> check_circle </i></span>
    </div> -->
</loading-container>
