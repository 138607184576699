import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-quality-accept',
  templateUrl: './quality-accept.component.html',
  styleUrls: ['./quality-accept.component.scss']
})
export class QualityAcceptComponent {
  @Input() public onAcceptCallback: Function;

  public notes: string;
  public textAreaMaxLength: string = '500';
  public textAreaCharacterCounter = 0;

  constructor(public bsModalRef: BsModalRef) {}

  public charactersCounter(event: any) {
    this.textAreaCharacterCounter = event.target.value.length;
  }

  public submit() {
    this.onAcceptCallback(this.notes);
    this.bsModalRef.hide();
  }
}
