import { Component, Input } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';

@Component({
  selector: 'staffnow-go-back-button',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['go-back-button.component.scss']
})
export class GoBackButtonComponent {
  @Input() public currentPath: Array<string> = []; //

  constructor(private customNavigationService: CustomNavigationService) {}

  get mergedPath(): string {
    return isEmpty(this.currentPath) ? null : this.currentPath.join(' > ');
  }

  public goBack(): void {
    this.customNavigationService.goBack();
  }
}
