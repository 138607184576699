<div class="select-label">
    <h1 class="h1">
        {{ 'RATES_TABLE.HEADER' | translate }}
    </h1>
    <p class="guidelines">
        {{ 'RATES_TABLE.GUIDELINES' | translate }}
    </p>
</div>
<div class="rate-container">
    <div class="rate-settings" *ngIf="canEditRates()">
        <div id="rateType" class="setting">
            <label>{{ 'RATES_TABLE.TECHNICIAN_TYPE' | translate }}</label>
            <select
                class="custom-select"
                name="type"
                (change)="onAmeTypeChange($event.target.value)"
                [(ngModel)]="manualUpdateControlValues.ameType"
                [ngModelOptions]="{ standalone: true }"
            >
                <option value="">
                    {{ 'GENERAL.SELECT_AN_OPTION' | translate }}
                </option>
                <option
                    *ngFor="let option of ameNomenclatureOptions"
                    [value]="option.name"
                >
                    {{ option.name }}
                </option>
            </select>
        </div>
        <div id="rateLevel" class="setting">
            <label for="level">
                {{
                    'RATES_TABLE.TECHNICIAN_LEVEL' | translate: {singleTitle: isSingletonTitle}
                }}
            </label>
            <select
                class="custom-select"
                [(ngModel)]="manualUpdateControlValues.id"
                [ngModelOptions]="{ standalone: true }"
                (change)="onAmeLevelChange()"
                [disabled]="isSingletonTitle"
            >
                <option [value]="null">
                    {{ 'GENERAL.SELECT_AN_OPTION' | translate }}
                </option>
                <option
                    *ngFor="let license of ameLicenseTypeOptions"
                    [value]="license.id"
                >
                    {{ license.name }}
                </option>
            </select>
        </div>
        <div>
            <div class="input-group rate-value">
                <label for="chargeRate">
                    {{
                        'RATES_TABLE.RATE_PER_HOUR' | translate
                    }}*
                </label>
                <div class="input-group">
                    <input
                        id="chargeRate"
                        class="form-control"
                        type="number"
                        min="0"
                        autocomplete="off"
                        placeholder="{{'RATES_TABLE.ENTER_RATE_PER_HOUR' | translate}}"
                        [(ngModel)]="manualUpdateControlValues.value"
                        (ngModelChange)="
                            validateEntryRate(manualUpdateControlValues.value)
                        "
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            {{ currencySymbol }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <general-button
            class="add-button"
            [isDisabled]="!canAddNewRate()"
            (onClick)="addNewRate()"
            [text]="'GENERAL.ADD' | translate"
        ></general-button>
    </div>
    <div class="new-rate-entities">
        <ng-container *ngFor="let ameType of specificAmeType" class="rate-list">
            <div class="rate-entity">
                <div class="rate-name">
                    <span class="mro-name">{{ ameType }}</span>
                </div>
                <div
                    class="rate-list"
                    *ngFor="let mroRate of getRateForAmeType(ameType)"
                >
                    <div class="rate-name">
                        <span>{{ mroRate.ameTitle?.license }}</span>
                    </div>
                    <div class="rate-actions">
                        <div class="input-group rate-value">
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                autocomplete="off"
                                [(ngModel)]="mroRate.rate"
                                (ngModelChange)="validateRateInEntries(mroRate)"
                                [disabled]="mroRate.disable"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    {{ currencySymbol }}
                                </span>
                            </div>
                        </div>
                        <div class="rate-buttons" *ngIf="canEditRates()">
                            <div class="action-button" *ngIf="mroRate.disable">
                                <general-button
                                    (onClick)="toggleEdit(mroRate)"
                                    backgroundStyle="outline"
                                    icon="edit"
                                    size="small"
                                ></general-button>
                            </div>
                            <div class="action-button" *ngIf="!mroRate.disable">
                                <general-button
                                    (onClick)="changeRate(mroRate)"
                                    backgroundStyle="outline"
                                    icon="check"
                                    size="small"
                                ></general-button>
                            </div>
                            <div class="action-button" *ngIf="!mroRate.disable">
                                <general-button
                                    (onClick)="toggleEdit(mroRate)"
                                    backgroundStyle="outline"
                                    icon="cancel"
                                    color="danger"
                                    size="small"
                                ></general-button>
                            </div>
                            <div class="action-button">
                                <general-button
                                    (onClick)="deleteRate(mroRate)"
                                    backgroundStyle="outline"
                                    icon="delete"
                                    color="danger"
                                    size="small"
                                ></general-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <general-button
        text="Confirm rate changes"
        [isLoading]="isLoading"
        [isDisabled]="!isDirty"
        *ngIf="canEditRates()"
        (onClick)="submitMroRates()"
    ></general-button>
</div>
