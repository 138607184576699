import { createSelector } from '@ngrx/store';
import {
  REGISTRATION_FEATURE_KEY,
  RegistrationState
} from './registration-state.model';

export const selectRegistrationState = (state: any) =>
  state[REGISTRATION_FEATURE_KEY] as RegistrationState;

export const registrationComponentStatus = createSelector(
  selectRegistrationState,
  registration => registration.registerComponent
);

export const technicianRegistrationForm = createSelector(
  selectRegistrationState,
  registration => registration.technicianRegistrationForm
);

export const mroCompanyDetailsForm = createSelector(
  selectRegistrationState,
  registration => registration.mroCompanyDetails
);

export const mroSelectedAgenciesForm = createSelector(
  selectRegistrationState,
  registration => registration.mroSelectedAgencies
);

export const mroDisplayOptionsForm = createSelector(
  selectRegistrationState,
  registration => registration.mroDisplayOptions
);
