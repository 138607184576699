import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { LibStateModel, ToastMessage } from './lib-state.model';
import { ToastAction, ToastActionTypes } from './lib.actions';

export function getReducers(): ActionReducerMap<LibStateModel> {
  return {
    queue: messageQueueReducer
  };
}

function messageQueueReducer(
  state: Array<ToastMessage>,
  action: ToastAction
): Array<ToastMessage> {
  switch (action.type) {
    case ToastActionTypes.NewToast:
      const messageId = `${action.text}_${new Date().getTime()}`;
      let queue = [...state];
      queue.push({
        type: action.toastType,
        text: action.text,
        id: messageId,
        dismissTimeout: action.dismissTimeout
      });
      return queue;

    case ToastActionTypes.DeleteToast:
      let filteredQue = state.filter(
        message => message.id !== action.messageId
      );
      return filteredQue;
  }

  return state;
}

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<LibStateModel>
>('[Toast] Registered Reducers');
