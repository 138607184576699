import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['../modal.styles.scss']
})
export class QuestionModalComponent {
  @Input() onConfirmedCallback: Function = () => {};
  @Input() onDeclinedCallback: Function = () => {};
  @Input() question: string;

  constructor(public bsModalRef: BsModalRef) {}

  public acceptHandler(): void {
    this.onConfirmedCallback();
    this.bsModalRef.hide();
  }

  public declineHandler(): void {
    this.onDeclinedCallback();
    this.bsModalRef.hide();
  }
}
