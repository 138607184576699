import {createReducer, on} from '@ngrx/store';
import {EnvironmentLoaded, LocationListLoaded} from './environment.actions';
import { EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import {cloneDeep} from "lodash-es";


const initialState: EnvironmentState = null;

export const environmentReducer = createReducer<EnvironmentState>(
    initialState,
    on(EnvironmentLoaded, (state, { payload }) => ({ ...state, ...payload })),
    on(LocationListLoaded, (state, action) => {
      const updatedState = cloneDeep(state);
      updatedState._embedded = {
            ...updatedState._embedded,
            locations: action.locations as any
      };
      return updatedState;
    })
);
