<div class="own-technicians-wrapper">
    <div class="filter-controls-container">
        <staff-filters
            class="filters-container"
            (enterPressed)="searchUsers($event)"
            [filterListConfig]="filtersConfig"
            (search)="searchUsers($event)"
            (clear)="clearFilters()"
        >
        </staff-filters>
    </div>
    <div class="technicians-list-wrapper">
        <div *ngIf="usersList?.length > 0; else noTechnicians">
            <div class="technicians-list">
                <technician-card
                    *ngFor="let technician of usersList"
                    [technician]="technician"
                    [isOwnTechnicianListing]="true"
                    (onClickEvent)="openProfile($event)"
                >
                </technician-card>
            </div>
            <pagination
                class="pagination justify-content-center"
                previousText="Prev"
                firstText="&laquo;"
                lastText="&raquo;"
                [maxSize]="15"
                [totalItems]="totalElements"
                [(ngModel)]="currentPage"
                [itemsPerPage]="pageSize"
                [boundaryLinks]="true"
                (pageChanged)="pageChanged($event)"
            >
            </pagination>
        </div>
        <ng-template #noTechnicians>
            <div class="no-technicians">
                <div class="h3">{{ 'OWN_TECHNICIANS.EMPTY.SEARCH' | translate }}</div>
            </div>
        </ng-template>
    </div>
</div>
