import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DASHBOARDS_MENU_ITEMS} from '@libs/shared/constants/dashboardMenuItems.constants';

@Component({
  selector: 'staffnow-rejections-dashboard-page',
  templateUrl: './rejections-dashboard-page.component.html'
})
export class RejectionsDashboardPageComponent {
  get dashboardUrl(): string {
    return DASHBOARDS_MENU_ITEMS.REJECTIONS.url;
  }

  get environment(): any {
    return environment;
  }
}
