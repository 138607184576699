/*
 * Use this configuration to store application specific settings/constants
 * regarding the communication with the backend
 *
 */
export const ApiConfiguration = {
  httpHeaders: {
    'x-client-name': 'platform'
  }
};
