<div class="profile-card">
    <div class="profile-picture">
        <div class="upload-cover-wrapper">
            <ng-content select="[upload-cover-photo]"></ng-content>
        </div>
        <div
            *ngIf="facilityCoverImage"
            class="cover"
            [ngStyle]="{
                'background-image': 'url(\'' + facilityCoverImage + '\')'
            }"
        ></div>
        <facility-cover-placeholder
            *ngIf="!facilityCoverImage"
        ></facility-cover-placeholder>
    </div>
    <facility-logo [resource]="facilityProfile" size="large"></facility-logo>
    <div class="upload-logo-wrapper">
        <ng-content select="[upload-logo]"></ng-content>
    </div>
    <ng-content select="[name-and-slogan]"></ng-content>
    <ng-content select="[status]"></ng-content>
    <div class="main-info" *ngIf="displayText">
        <div class="title">
            <h1 class="h1">{{ facilityProfile.name }}</h1>
        </div>
        <div class="title" *ngIf="slogan">
            <h2 class="h2">{{ slogan }}</h2>
        </div>
        <div class="presentation">
            <label>{{ 'GENERAL.SHORT_PRESENTATION' | translate }}:</label>
            <p>{{ facilityProfile.shortPresentation }}</p>
        </div>
    </div>
</div>
