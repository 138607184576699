import {Injectable} from "@angular/core";
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";
import {Preferences} from "@capacitor/preferences";

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    public async getItem(key: string): Promise<string> {
        if (isPlatformWeb()) {
            return localStorage.getItem(key);
        } else {
            const {value} = await Preferences.get({key: key});
            return value;
        }
    }

    public setItem(key: string, value: string) {
        if (isPlatformWeb()) {
            localStorage.setItem(key, value);
        } else {
            Preferences.set({key: key, value: value});
        }
    }

    public removeItem(key: string) {
        if(isPlatformWeb()) {
            localStorage.removeItem(key);
        } else {
            Preferences.remove({ key: key});
        }
    }
}