<div
    [ngClass]="typeFilter.className || ''"
    style="text-align: start"
    class="abstract-filter"
>
    <label>{{ typeFilter.label }} </label>
    <enum-filter
        class="filter-control"
        (onChange)="handleTypeChange($event)"
        [filterConfig]="typeFilter"
    ></enum-filter>
</div>
<div
    [ngClass]="licenseFilter.className || ''"
    style="text-align: start"
    class="abstract-filter"
>
    <label>{{ licenseFilter.label }} </label>
    <enum-filter
        class="filter-control"
        (onChange)="handleLicenseChange($event)"
        [filterConfig]="licenseFilter"
    ></enum-filter>
</div>
