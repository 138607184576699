import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { ButtonsModule } from '@libs/common-ui/buttons/buttons.module';

@Component({
  selector: 'read-more',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ButtonsModule],
  templateUrl: './read-more.component.html',
  styleUrl: './read-more.component.scss'
})
export class ReadMoreComponent implements OnInit {
  @Input() content: string;
  @Input() limit: number = 250;
  @Input() completeWords: boolean = true;

  displayFullText: boolean;
  featureIsDisabled: boolean = false;
  shortenedContent: string;

  ngOnInit() {
    const contentLength = this.content?.length;
    if (contentLength > this.limit) {
      this.displayFullText = false;
      this.shortenedContent = this.truncateContent();
    } else {
      this.displayFullText = true;
      this.featureIsDisabled = true;
    }
  }

  toggleContent() {
    this.displayFullText = !this.displayFullText;
  }

  private truncateContent(): string {
    if (this.completeWords) {
      this.limit = this.content.substring(0, this.limit).lastIndexOf(' ');
    }
    return `${this.content.substring(0, this.limit)} [...]`;
  }
}
