<date-picker-single
    (dateUpdated)="handleSingleDateUpdate($event)"
    [minDate]="minimumDate"
    [initialValue]="date"
    placeholder="Select a date"
></date-picker-single>
<time-picker
    [value]="time"
    [minutesInterval]="30"
    (change)="handleTimeChange($event)"
></time-picker>
