import { createAction, props } from '@ngrx/store';

export const ResetOfferForm = createAction('[Create Offer] Reset offer form');
export const FailedToSubmitOffer = createAction(
  '[Create Offer] Failed to submit offer'
);
export const OfferSubmitSuccess = createAction(
  '[Create Offer] Success offer submit',
  props<{ refNumber?: string }>()
);
export const SubmitOffer = createAction(
  '[Create Offer] Submit public/private offer',
  props<{ payload: any; editUrl?: string }>()
);
