<div class="agency-request-overview-wrapper">
    <tab-navigation
        [menuItems]="AGENCY_REQUEST_OVERVIEW_TABS"
        [(ngModel)]="activeTab"
        (ngModelChange)="updateUrl()"
    ></tab-navigation>

    <staffnow-request-listing
        *ngIf="!isOwnTechniciansView"
        [isMyAgencyTab]="true"
    ></staffnow-request-listing>

    <staffnow-request-overview-as-technician
        *ngIf="isOwnTechniciansView"
    ></staffnow-request-overview-as-technician>
</div>
