import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BsModalService } from 'ngx-bootstrap/modal'
import {
  AcceptContract,
  LoadOfferWithContracts
} from '@libs/request-overview-common/state/requests-overview.actions'
import { AgenciesListModalComponent } from '@libs/agencies-list-modal/agencies-list-modal.component'
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel'
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils'
import { ContractStatus } from '@libs/shared/models/contract-status.enum'
import { AbstractTechnicianOfferDetailsComponent } from './abstract-technician-offer-details.component'
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BrandName } from '@libs/shared/bms-common/environment/environment.model'

@UntilDestroy()
@Component({
  selector: 'staffnow-technician-offer-details',
  templateUrl: './technician-offer-details.component.html',
  styleUrls: ['./technician-offer-details.component.scss']
})
export class TechnicianOfferDetailsComponent extends AbstractTechnicianOfferDetailsComponent implements OnInit {
  public brandName: string = null;
  public labourRegulationsAcknowledgmentClicked: boolean = false;

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly bsModalService: BsModalService) {
    super();

    this.store.pipe(getStaticEnvironment, untilDestroyed(this)).subscribe(envData => {
      this.brandName = envData.brandConfig.brandName;
    });
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadOfferWithContracts({
            refNumber: params.offerRefNumber
          })
        );
      })
    );
  }


  get shouldDisplayChooseNewAgency(): boolean {
    return this.requiresAgencySelectionForOfferApplication && this.canAcceptContract() && this.agencyHasRejected();
  }

  get shouldDisplayAcceptButton(): boolean {
    return this.canAcceptContract() && !this.shouldDisplayChooseNewAgency;
  }

  get canCreateConversationWithAgency(): boolean {
    return this.conversationService.canCreateConversationWithAgency(this.contract);
  }

  get canGetPreviousConversationWithAgency(): boolean {
    return this.conversationService.canGetPreviousConversationWithAgency(this.contract);
  }

  private canAcceptContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContract);
  }

  private agencyHasRejected(): boolean {
    return this.contract.agencyStatus === ContractStatus.rejected;
  }

  public acceptOffer(): void {
    if (this.contract.requiresAgencyAcceptance && this.requiresAgencySelectionForOfferApplication) {
      this.bsModalService.show(AgenciesListModalComponent, {
        class: 'agencies-list-modal modal-center',
        initialState: {
          offer: this.jobOffer,
          contract: this.contract,
          onConfirmCallback: agency => {
            this.store.dispatch(
              AcceptContract({
                contract: this.contract,
                agencyUuid: agency.uuid,
                labourRegulationsAcknowledgment: this.isStaffnowBrand
                  ? this.labourRegulationsAcknowledgmentClicked
                  : null
              })
            );
            this.labourRegulationsAcknowledgmentClicked = false;
          }
        }
      });
    } else {
      this.store.dispatch(
        AcceptContract({
          contract: this.contract,
          agencyUuid: null,
          labourRegulationsAcknowledgment: this.isStaffnowBrand ? this.labourRegulationsAcknowledgmentClicked : null
        })
      );
      this.labourRegulationsAcknowledgmentClicked = false;
    }
  }

  public initiateMessageWithAgency(): void {
    this.conversationService.initiateMessageWithAgency(this.contract);
  }

  public labourAcknowledgmentChanged(labourAcknowledgment: boolean): void {
    this.labourRegulationsAcknowledgmentClicked = labourAcknowledgment;
  }

  public get canAccept(): boolean {
    return !this.isOfferEnded && (this.brandName !== BrandName.StaffNow || this.labourRegulationsAcknowledgmentClicked);
  }

  private get isStaffnowBrand(): boolean {
    return this.brandName === BrandName.StaffNow;
  }
}
