import { Component, HostBinding, Input } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import {ToastMessageService} from "@libs/toast-messages/toast-message.service";
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";

@Component({
  selector: 'staffnow-info-icon-with-tooltip',
  templateUrl: './info-icon-with-tooltip.component.html',
  styleUrls: ['./info-icon-with-tooltip.component.scss']
})
export class InfoIconWithTooltipComponent {
  @Input() public tooltipMessage: string = null;
  @Input() public displayHelpLabel: boolean = false;
  @Input() @HostBinding('class.big') public big: boolean = false;
  @Input() @HostBinding('class') public placement: 'top' | 'left' | 'right' = 'top';

  public clicked: boolean = false;

  constructor(private toastMessageService: ToastMessageService) {
  }

  public handleClick(tooltipElement: TooltipDirective): void {
    if (isPlatformWeb()) {
      if (this.clicked) {
        tooltipElement.hide();
      } else {
        tooltipElement.show();
      }
      this.clicked = !this.clicked;
    } else {
      this.toastMessageService.info(this.tooltipMessage);
    }
  }

  public showTooltip(tooltipElement: TooltipDirective): void {
    if (!this.clicked && isPlatformWeb()) {
      tooltipElement.show();
    }
  }

  public hideTooltip(tooltipElement: TooltipDirective): void {
    if (!this.clicked && isPlatformWeb()) {
      tooltipElement.hide();
    }
  }
}
