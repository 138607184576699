import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';

@UntilDestroy()
@Injectable()
export class VerifyEmailService {
  private brandName: string;
  
  constructor(
    private httpClient: HttpClient,
    private store: Store<any>
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.brandName = env.brandConfig.brandName;
    });
  }

  public resendVerificationEmail(
    grecaptchaToken: string,
    email: string
  ): Observable<any> {
    const url = this.getBackendUrl() + '/api/platform/request/email?grecaptchaToken={grecaptchaToken}';
    return this.httpClient.post(
      url.replace('{grecaptchaToken}', grecaptchaToken),
      email
    );
  }

  public verifyEmail(grecaptchaToken: string, uuid: string): Observable<any> {
    const url = this.getBackendUrl() + '/api/platform/request/verify?grecaptchaToken={grecaptchaToken}';
    return this.httpClient.post(
      url.replace('{grecaptchaToken}', grecaptchaToken),
      uuid
    );
  }

  private getBackendUrl(): string {
    let apiUrl;
    if (this.brandName === BrandName.eLAUNCHNow) {
      apiUrl = environment.usaApiUrl;
    } else apiUrl = environment.europeApiUrl;
    return apiUrl;
  }
}
