import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MroFacility, PublicAgency } from '@libs/shared/models/facility.model';

@Component({
  selector: 'staffnow-facility-profile-modal',
  templateUrl: './facility-profile-modal.component.html',
  styleUrls: ['./facility-profile-modal.component.scss']
})
export class FacilityProfileModalComponent {
  @Input() public displayCloseButton: boolean = true;
  @Input() public facilityProfile: PublicAgency | MroFacility = null;

  constructor(private bsModalRef: BsModalRef) {}

  close() {
    this.bsModalRef.hide();
  }
}
