<div class="page-container">
    <div>
        <div class="row mb-3">
            <div class="col-md-7 col-lg-5 d-flex align-items-center filters">
                <h4 style="width: 220px; color: black">{{'TASKS.VIEW.QUICK_FILTERS.LABEL' | translate}}</h4>
                <simple-select
                    (ngModelChange)="onTypeChangeHandler($event)"
                    [disabled]="isLoading"
                    [getLabel]="getItemDescription.bind(this)"
                    [getValue]="getItemValue.bind(this)"
                    [items]="taskTypes"
                    [ngModel]="selectedType"
                    class="mr-2"
                    [placeholder]="'TASKS.VIEW.QUICK_FILTERS.TYPE.PLACEHOLDER' | translate"
                >
                </simple-select>
                <simple-select
                    (ngModelChange)="onOfferChangeHandler($event)"
                    [disabled]="isLoading"
                    [getLabel]="getItemValue"
                    [getValue]="getItemValue"
                    [items]="offers"
                    [ngModel]="selectedOffer"
                    [placeholder]="'TASKS.VIEW.QUICK_FILTERS.JOB_OPENING.PLACEHOLDER' | translate"
                >
                </simple-select>
            </div>
            <div
                *ngIf="!isTechnician()"
                class="col-md-1 offset-md-4 offset-lg-6 d-flex align-items-center justify-content-end"
            >
                <button
                    (click)="changeMode('Rows')"
                    [ngClass]="{ 'selected-mode': mode === 'Rows' }"
                    class="btn btn-secondary btn-sm btn-mode list"
                    style="width: 30px"
                >
                    <span class="material-icons-outlined btn-icon">list</span>
                </button>
                <button
                    (click)="changeMode('Columns')"
                    [ngClass]="{ 'selected-mode': mode === 'Columns' }"
                    class="btn btn-secondary btn-sm btn-mode grid"
                    style="width: 30px"
                >
                    <span class="material-icons-outlined btn-icon"
                        >grid_view</span
                    >
                </button>
            </div>
        </div>
    </div>

    <staff-loader *ngIf="isLoading; else pendingTasks" [customClass]="'splash-screen-loader'"></staff-loader>

    <ng-template #pendingTasks>
        <div
            *ngIf="isTechnician()"
            [ngClass]="
                mode == 'Columns'
                    ? 'grid-container-columns'
                    : 'grid-container-rows'
            "
        >
            <task-column
                [filtersSelected]="myTasks.length !== filteredTasks.length"
                [mode]="mode"
                [tasks]="filteredTasks"
                title="Pending"
            ></task-column>
        </div>
        <div
            *ngIf="!isTechnician()"
            [ngClass]="
                mode == 'Columns'
                    ? 'grid-container-columns'
                    : 'grid-container-rows'
            "
        >
            <task-column
                [filtersSelected]="myTasks.length !== filteredTasks.length"
                [filters]="getApplicationColumnTasks()"
                [mode]="mode"
                [tasks]="filteredTasks"
                [title]="'TASKS.VIEW.TASK_COLUMN.APPLICATIONS.LABEL' | translate"
            >
            </task-column>
            <task-column
                [filtersSelected]="myTasks.length !== filteredTasks.length"
                [filters]="getPendingStartColumnTasks()"
                [mode]="mode"
                [tasks]="filteredTasks"
                [title]="'TASKS.VIEW.TASK_COLUMN.PENDING.LABEL' | translate"
            >
            </task-column>
            <task-column
                [filtersSelected]="myTasks.length !== filteredTasks.length"
                [filters]="getOtherColumnTasks()"
                [mode]="mode"
                [tasks]="filteredTasks"
                [title]="'TASKS.VIEW.TASK_COLUMN.OTHERS.LABEL' | translate"
            >
            </task-column>
        </div>
    </ng-template>
</div>
