import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { MroFacilitySimple } from '../../../state/app-state.model';
import { selectMroFacilities } from '../../../state/app.selectors';
import {
  FailedToGenerateReport,
  GenerateReport,
  LoadMroFacilitiesForReports,
  MroFacilitiesForReportsLoaded,
  ReportGenerated
} from '../../../state/app.actions';
import { ReportsHelper } from '@libs/shared/helpers/reports.helper';
import { PlatformReport } from '@libs/shared/models/reports.model';
import { dateTimeFromString } from '@libs/shared/helpers/date-utils';
import { Interval } from 'luxon';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';

@UntilDestroy()
@Component({
  selector: 'staffnow-report-platform',
  templateUrl: './report-platform.component.html',
  styleUrls: ['./report-platform.component.scss']
})
export class ReportPlatformComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public report: PlatformReport;
  public mroFacilities: MroFacilitySimple[] = [];

  public isLoading: boolean;
  public range: string[] = [];
  public uuid: string = null;
  private brandName: string = null;
  private subs: Subscription[] = [];
  getFacilityUuid = facility => facility.uuid;

  constructor(private store: Store<any>, private actionsSubject: ActionsSubject) {
    this.store.pipe(getStaticEnvironment, untilDestroyed(this)).subscribe(envData => {
      this.brandName = envData.brandConfig.brandName;
    });
  }

  public ngOnInit(): void {
    this.setupLoadingSubs();
    this.subscribeToMroFacilities();
  }

  public ngOnChanges(): void {
    this.range = [];
  }

  private setupLoadingSubs(): void {
    this.subs.push(
      this.actionsSubject
        .pipe(
          ofType(GenerateReport, LoadMroFacilitiesForReports),
          tap(() => (this.isLoading = true))
        )
        .subscribe()
    );
    this.subs.push(
      this.actionsSubject
        .pipe(
          ofType(ReportGenerated, FailedToGenerateReport, MroFacilitiesForReportsLoaded),
          tap(() => (this.isLoading = false))
        )
        .subscribe()
    );
  }

  private subscribeToMroFacilities(): void {
    if (this.report.key)
      this.subs.push(
        this.store
          .pipe(
            selectMroFacilities,
            tap(facilities => (this.mroFacilities = facilities))
          )
          .subscribe()
      );
  }

  public ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public generateReport(url?: string): void {
    const payload = {
      href: url || this.report.url,
      range: ReportsHelper.dateRangeConverter(this.range),
      uuid: this.uuid,
      platform: this.report.includePlatformInUrl ? this.platform() : null
    };
    this.store.dispatch(GenerateReport({ payload }));
    this.range = [];
    this.uuid = null;
  }

  public isRangeSelectAvailable(): boolean {
    return ReportsHelper.isRangeSelectAvailable(this.report.key);
  }

  public isMroFacilitySelectAvailable(): boolean {
    return !isEmpty(this.mroFacilities) && this.report.canSelectMroFacility;
  }

  public isGenerateDisabled(): boolean {
    return (
      this.isMissingRangeSelection() ||
      this.isMissingMroSelection() ||
      this.isLoading ||
      this.isSelectedRangeGreaterThanAllowed()
    );
  }

  public isGenerateGroupDisabled(): boolean {
    return this.isMissingRangeSelection() || this.isLoading;
  }

  private isMissingRangeSelection(): boolean {
    return this.isRangeSelectAvailable() && isEmpty(this.range);
  }

  private isSelectedRangeGreaterThanAllowed() {
    return (
      this.report.maxDays &&
      Interval.fromDateTimes(dateTimeFromString(this.range[0]), dateTimeFromString(this.range[1])).length('days') >
        this.report.maxDays
    );
  }

  private isMissingMroSelection(): boolean {
    return this.isMroFacilitySelectAvailable() && this.uuid === null;
  }

  public handleRangeChange(dates: string[]): void {
    this.range = dates;
  }

  private platform(): string {
    if (this.brandName === BrandName.StaffNow) return 'STAFFNOW';
    return 'ELAUNCHNOW';
  }
}
