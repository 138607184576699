<i class="material-icons close-icon" (click)="close()"> clear </i>
<div class="header">
    <h1 class="heading">{{ title | translate }}</h1>
</div>
<div class="content">
    <table class="table" *ngIf="!documentsIsEmpty">
        <thead>
            <tr>
                <th class="small-column">#</th>
                <th>{{ 'FILES.FILENAME' | translate }}</th>
                <th *ngIf="showLabel">{{ 'FILES.LABEL' | translate }}</th>
                <th width="150">{{ 'FILES.UPLOAD_DATE' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let item of documents; let i = index"
                class="uploadedItem"
            >
                <td>{{ i + 1 }}</td>
                <td class="item-filename">
                    <a (click)="downloadDocument(item)" [title]="item?.name">
                        {{ item?.name }}
                    </a>
                </td>
                <td class="item-filename" *ngIf="showLabel">
                    <a (click)="downloadDocument(item)" [title]="item?.label">
                        {{ item?.label }}
                    </a>
                </td>
                <td
                    class="item-filename"
                    [title]="item?.createdOn | absoluteDateFormat"
                >
                    {{ item?.createdOn | absoluteDateFormat }}
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="documentsIsEmpty" class="empty-documents-section">
        <div>
            <p class="text-muted">
                {{
                    'FILES.NO_DOCS_UPLOADED_YET' | translate
                }}
            </p>
        </div>
    </div>
</div>
