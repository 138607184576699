import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfileStatus } from '../../state/user-profile.selectors';
import { profileStatusConstants } from '@libs/shared/constants/statuses.constants';
import { isUsaDeployment } from '@libs/shared/bms-common/environment/environment.loader';

@Component({
  selector: 'staffnow-profile-status',
  templateUrl: './profile-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./profile-status.component.scss']
})
export class ProfileStatusComponent implements OnInit {
  protected readonly profileStatusConstants = profileStatusConstants;

  private profileSubscription = null;
  private isApiRegionInUsa: boolean = isUsaDeployment();
  profileStatus = null;
  showCompleteStatus: boolean = false;

  constructor(private store: Store<any>, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.profileSubscription = this.store.select(selectProfileStatus).subscribe(profileStatus => {
      this.profileStatus = profileStatus;
      this.showCompleteStatus =
        this.isApiRegionInUsa &&
        [profileStatusConstants.pending, profileStatusConstants.inReview].includes(profileStatus);
      this.cdRef.detectChanges();
    });
  }

  handleStatusColoring(): string {
    switch (this.profileStatus) {
      case profileStatusConstants.verified:
        return 'text-success';
      case profileStatusConstants.pending:
        return this.isApiRegionInUsa ? 'text-success' : 'text-warning';
      case profileStatusConstants.inReview:
        return this.isApiRegionInUsa ? 'text-success' : 'text-primary';
      case profileStatusConstants.incomplete:
        return 'text-secondary';
      default:
        return '';
    }
  }
}
