import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { AlertModule } from 'ngx-bootstrap/alert';

import { TranslationModule } from '@libs/translation/translation.module';

import { reducersInjectionToken, getReducers } from '../state/lib.reducer';
import { initialState, LIB_FEATURE_KEY } from '../state/lib-state.model';
import { ToastMessageService } from './toast-message.service';
import { ToastMessageListComponent } from './toast-message-list/toast-message-list.component';

@NgModule({
  imports: [
    CommonModule,
    AlertModule.forRoot(),
    TranslationModule,
    StoreModule.forFeature(LIB_FEATURE_KEY, reducersInjectionToken, {
      initialState: initialState
    })
  ],
  declarations: [ToastMessageListComponent],
  providers: [
    ToastMessageService,
    { provide: reducersInjectionToken, useFactory: getReducers }
  ],
  exports: [ToastMessageListComponent]
})
export class ToastMessagesModule {}
