<div class="mailbox-container" [ngClass]="{ admin: isAdmin }">
    <conversation-list
        [ngClass]="{ hidden: activeView === MAILBOX_VIEWS.Messages }"
        [conversationList]="conversationList"
        [selectedConversationId]="selectedConversationId"
        (loadConversation)="loadConversation($event)"
        (loadNextPage)="loadNextPage()"
    >
    </conversation-list>
    <staffnow-conversation-viewer
        [ngClass]="{ hidden: activeView === MAILBOX_VIEWS.Conversations }"
        (closeChat)="goToConversations()"
    ></staffnow-conversation-viewer>
</div>
