import { createSelector } from '@ngrx/store';
import {
  FEATURE_STATE_KEY,
  WebSocketStateModel
} from './websocket-state.model';

const selectFeatureFromAppState = (state: any) =>
  state[FEATURE_STATE_KEY] as WebSocketStateModel;

export const selectQueueList = createSelector(
  selectFeatureFromAppState,
  state => state.queueList
);
