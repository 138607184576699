export const profileStatusConstants = {
  verified: 'VERIFIED',
  pending: 'PENDING',
  inReview: 'IN_REVIEW',
  incomplete: 'INCOMPLETE',
  complete: 'COMPLETE',
  serverLangToHumanLang: {
    VERIFIED: 'VERIFIED',
    PENDING: 'PENDING VERIFICATION',
    IN_REVIEW: 'IN REVIEW',
    INCOMPLETE: 'INCOMPLETE',
    COMPLETE: 'COMPLETE'
  }
};

export const accountStatusConstants = {
  active: 'ACTIVE',
  deleted: 'DELETED',
  deactivated: 'DEACTIVATED',
  serverLangToHumanLang: {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
    DEACTIVATED: 'DEACTIVATED'
  }
};
