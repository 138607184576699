import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {EnvironmentState} from '@libs/shared/bms-common/environment/environment.model';
import {TechnicianRegistrationFormModel} from '../components/technician-registration-form/technician-registration-form.model';
import {MroRegistrationData} from '../state/registration-state.model';
import {Store} from '@ngrx/store';
import {getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Injectable()
export class RegistrationService {
  private environment: EnvironmentState = null;

  constructor(
    private store: Store<any>,
    private errorMessageService: ErrorMessageService,
    private httpClient: HttpClient
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.environment = env;
    });
  }

  public registerTechnician(grecaptchaToken: string, regForm: TechnicianRegistrationFormModel): Observable<any> {
    const url = getUrl(this.environment, 'registerTechnician');
    return this.httpClient.post(url.replace('{grecaptchaToken}', grecaptchaToken), {...regForm});
  }

  public registerMro(grecaptchaToken: string, regInformation: MroRegistrationData): Observable<any> {
    const url = getUrl(this.environment, 'registerMroFacility');
    return this.httpClient.post(url.replace('{grecaptchaToken}', grecaptchaToken), {...regInformation});
  }

  public uploadMroFacilityLogo(facilityUuid: string, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', image);
    const url = getUrl(this.environment, 'uploadMroFacilityLogo');
    return this.httpClient.post(
      url.replace('{facilityUuid}', facilityUuid).replace('?file={file}', ''),
      formData
    );
  }

  public searchCompany(companyName: string): Observable<any> {
    return this.httpClient.post(getUrl(this.environment, 'mroExists'), companyName);
  }

  public getSharedOfferData(refNumber: string, linkRel: string): Observable<Object> {
    const apiUrl = getUrl(this.environment, linkRel);
    return this.httpClient.get(`${apiUrl}${refNumber}`);
  }

  public getAvailableAgencies() {
    const url = getUrl(this.environment, 'agenciesForMroRegistration');
    return this.httpClient.get(url).pipe(
      map(response => response),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return of();
      })
    );
  }

}
