import {Component, Input} from '@angular/core';

import {MenuItem} from "@libs/shared/models/menu-item.model";

@Component({
  selector: 'staffnow-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() public menuItems: Array<MenuItem> = [];

}
