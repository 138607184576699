import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import {
  ListLoaded,
  ContractLoaded,
  EntityUuidSet,
  OfferLoaded,
  ResetRequestsOverviewState,
  SelectedOfferContractsLoaded,
  SetActiveFilter,
  SetActingAs,
  SetSort,
  SetFilterPage,
  SetRefNumber,
  SetFacilityUuid,
  SetOfferType
} from './requests-overview.actions';
import {
  getEmbeddedResource,
  hasEmbeddedResource
} from '@libs/shared/bms-common/rest/resource.utils';
import { SimpleFacilityWithId } from '@libs/shared/models/facility.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export const REQUESTS_OVERVIEW_COMMON_FEATURE_KEY = 'requestsOverviewCommon';

export function getReducers(): ActionReducerMap<RequestsOverviewState> {
  return {
    page: currentPageReducer,
    pageSize: pageSizeReducer,
    totalElements: totalElementsReducer,
    resultList: resultListReducer,
    selectedOfferContracts: selectedOfferReducer,
    filters: filtersReducer,
    mroList: mroListReducer
  };
}

export interface RequestsOverviewState {
  page: number;
  pageSize: number;
  totalElements: number;
  resultList: Array<any>;
  filters: RequestOverviewFiltersState;
  selectedOfferContracts: any;
  mroList: Array<any>;
}

export interface RequestOverviewFiltersState {
  sort: string;
  page: number;
  activeFilter: string;
  refNumber: string;
  actingAs: string;
  selectedEntityUuid: string;
  facilityId: number;
  offerType: string;
}

export const initialState: RequestsOverviewState = {
  page: 0,
  pageSize: 0,
  totalElements: 0,
  resultList: [],
  mroList: [],
  selectedOfferContracts: null,
  filters: null
};

const currentPageReducer = createReducer(
  initialState.page,
  on(ListLoaded, (state, { response }) => response.page),
  on(ResetRequestsOverviewState, GlobalReset, () => initialState.page)
);

const filtersReducer = createReducer(
  initialState.filters,
  on(SetSort, (state, { sort }) => ({ ...state, sort })),
  on(SetFilterPage, (state, { page }) => ({ ...state, page })),
  on(SetActiveFilter, (state, { activeFilter }) => ({
    ...state,
    activeFilter
  })),
  on(SetRefNumber, (state, { refNumber }) => ({
    ...state,
    refNumber
  })),
  on(SetFacilityUuid, (state, { facilityUuid }) => ({
    ...state,
    facilityUuid
  })),
  on(SetActingAs, (state, { role }) => ({ ...state, actingAs: role })),
  on(SetOfferType, (state, { offerType }) => ({ ...state, offerType })),
  on(EntityUuidSet, (state, { uuid }) => ({
    ...state,
    selectedEntityUuid: uuid
  })),
  on(ResetRequestsOverviewState, GlobalReset, () => initialState.filters)
);

const pageSizeReducer = createReducer(
  initialState.pageSize,
  on(ListLoaded, (state, { response }) => response.pageSize),
  on(ResetRequestsOverviewState, GlobalReset, () => initialState.pageSize)
);

const totalElementsReducer = createReducer(
  initialState.totalElements,
  on(ListLoaded, (state, { response }) => response.totalElements),
  on(ResetRequestsOverviewState, GlobalReset, () => initialState.totalElements)
);

const resultListReducer = createReducer(
  initialState.resultList,
  on(ListLoaded, (state, { response }) => {
    const results = getEmbeddedResource<Array<any>>(response, 'results');
    return [...results];
  }),
  on(OfferLoaded, (state, { offer }) => {
    let offerList = [...state];
    offerList = offerList.map(o => (o.id == offer.id ? offer : o));
    return offerList;
  }),
  on(ResetRequestsOverviewState, GlobalReset, () => [
    ...initialState.resultList
  ])
);

const mroListReducer = createReducer(
  initialState.mroList,
  on(ListLoaded, (state, { response }) => {
    if (hasEmbeddedResource(response, ApiRootLinkRel.MroFacilityList)) {
      const facilitiesInGroup = getEmbeddedResource<
        Array<SimpleFacilityWithId>
      >(response, ApiRootLinkRel.MroFacilityList);
      return [...facilitiesInGroup];
    }
    return [];
  }),
  on(ResetRequestsOverviewState, GlobalReset, () => [...initialState.mroList])
);

const selectedOfferReducer = createReducer(
  initialState.selectedOfferContracts,
  on(GlobalReset, () => initialState.selectedOfferContracts),
  on(SelectedOfferContractsLoaded, (state, { response }) => ({
    ...response._embedded,
    ...response._links
  })),
  on(ContractLoaded, (state, { contract }) => {
    if (!state) {
      return state;
    }
    let contractList = [...state.contracts];
    contractList = contractList.map(c => (c.id == contract.id ? contract : c));
    return {
      ...state,
      contracts: contractList
    };
  })
);
