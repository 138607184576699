<div class="container">
    <h2>{{'REGISTRATION.UNAUTHORIZED.APP_NOT_AVAILABLE.TITLE' | translate}}</h2>
    <p>{{'REGISTRATION.UNAUTHORIZED.APP_NOT_AVAILABLE.APP_FOR_TECHNICIANS' | translate: {brandName} }}</p>
    <p>{{'REGISTRATION.UNAUTHORIZED.APP_NOT_AVAILABLE.USE_WEB_VERSION' | translate: {url: appUrl} }}</p>
    <general-button
        (onClick)="goToLoginPage()"
        backgroundStyle="outline"
        class="login-page-button"
        [text]="'GENERAL.GO_TO_LOGIN' | translate"
    ></general-button>
</div>
