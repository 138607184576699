import { Component, Input } from '@angular/core';
import { MroFacility, PublicAgency } from '@libs/shared/models/facility.model';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { FacilityProfileState } from '../../../../../../apps/staffnow-platform/src/app/state/app-state.model';

@Component({
  selector: 'staffnow-facility-profile-preview',
  templateUrl: './facility-profile-preview.component.html',
  styleUrls: ['./facility-profile-preview.component.scss']
})
export class FacilityProfilePreviewComponent {
  @Input() public facilityProfile:
    | PublicAgency
    | MroFacility
    | FacilityProfileState = null;
  @Input() public displayText: boolean = true;

  get facilityCoverImage(): string {
    return hasLink(this.facilityProfile, JobOfferLinkRel.FacilityImage)
      ? getUrl(this.facilityProfile, JobOfferLinkRel.FacilityImage)
      : null;
  }

  get slogan(): string {
    return 'slogan' in this.facilityProfile
      ? this.facilityProfile.slogan
      : null;
  }
}
