import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isEmpty} from 'lodash-es';
import {of} from 'rxjs';
import {DownloadService} from '@libs/shared/services/download.service';
import {catchError, map} from 'rxjs/operators';
import {downloadFileBlob} from '@libs/shared/helpers/download-blob-file';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Component({
  selector: 'staffnow-promotional-list',
  templateUrl: './promotional-agency-list.component.html',
  styleUrls: ['./promotional-agency-list.component.scss']
})
export class PromotionalAgencyListComponent {
  @Input() public isDisabled: boolean = false;
  @Input() public availableAgencies: Array<any> = [];
  @Input() public selectedAgencies: Array<string> = [];
  @Output() public onSelectionUpdate: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  public openedAgency: string = null;
  public isDownloading: boolean = false;

  get isLoadingAgencies(): boolean {
    return isEmpty(this.availableAgencies);
  }

  constructor(
    private downloadService: DownloadService,
    private errorMessageService: ErrorMessageService
  ) {
  }

  public handleAgencyClick(newUuid: string): void {
    if (this.isDisabled) {
      return;
    }
    if (this.isSelected(newUuid)) {
      this.selectedAgencies = this.selectedAgencies.filter(
        uuid => uuid != newUuid
      );
    } else {
      this.selectedAgencies.push(newUuid);
    }
    this.onSelectionUpdate.emit(this.selectedAgencies);
  }

  public isSelected(uuid: string): boolean {
    return this.selectedAgencies.includes(uuid);
  }

  public handleOpenDetails(newUuid: string): void {
    this.openedAgency = this.isOpened(newUuid) ? null : newUuid;
  }

  public isOpened(uuid: string): boolean {
    return this.openedAgency == uuid;
  }

  public downLoadDocuments(downloadUrl: string): void {
    this.isDownloading = true;
    this.downloadService
      .doGetRequest(downloadUrl)
      .pipe(
        map(response => {
          downloadFileBlob(response);
          return of();
        }),
        catchError(response => {
          
          this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'Unexpected error creating Zip file');
          return of();
        })
      )
      .subscribe(() => {
        this.isDownloading = false;
      });
  }
}
