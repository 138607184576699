import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss']
})
export class LoadingContainerComponent implements OnChanges {
  @Input() statePath: Array<string> = [];
  @Input() loaderConfig: any = null;

  private stateSubscription: Subscription = null;
  public isLoading: boolean = false;

  constructor(private store: Store<any>) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges['statePath'] &&
      simpleChanges['statePath'].currentValue !==
        simpleChanges['statePath'].previousValue
    ) {
      this.stateSubscription ? this.stateSubscription.unsubscribe() : null;
      //TODO(SN-984) This 'solution' is unacceptable. Upgrade the version of the cli and check for support for the new typescript, where the ...this.test syntax is valid
      this.stateSubscription = this.store
        .pipe(
          select(
            this.statePath[0],
            this.statePath[1],
            this.statePath[2],
            this.statePath[3],
            this.statePath[4],
            this.statePath[5]
          )
        )
        .subscribe(isLoading => {
          this.isLoading = isLoading;
        });
    }
  }

  ngOnDestroy() {
    this.stateSubscription ? this.stateSubscription.unsubscribe() : null;
  }
}
