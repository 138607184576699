<div
    *ngIf="pictureUrl != null"
    class="facility-image"
    [ngStyle]="{ 'background-image': 'url(\'' + pictureUrl + '\')' }"
></div>
<facility-cover-placeholder *ngIf="pictureUrl == null"></facility-cover-placeholder>
<facility-logo [resource]="facility" size="large"></facility-logo>
<p class="facility-title">{{ facilityName }}</p>

<staffnow-offer-priority-info *ngIf="offerPriority" [priority]="offerPriority"></staffnow-offer-priority-info>
<staffnow-share-offer [offer]="offer"></staffnow-share-offer>
<div class="banner-wrapper" *ngIf="applicationExists">
    <staffnow-information-banner
        direction="vertical"
        [message]="applicationExistsMessage"
    ></staffnow-information-banner>
</div>
<div class="other-info">
    <!--Location-->
    <i class="material-icons" [tooltip]="'SYSTEM.INFO.LOCATION' | translate"> location_on </i>
    <span>{{ offer?.location.name }}</span>
    <ng-container *ngIf="offer.periodFrom && offer.periodTo">
        <!--Period-->
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.PREFERRED_TIME_FRAME' | translate">date_range</i>
        <span> {{ offer.periodFrom | absoluteDateFormat }} to {{ offer.periodTo | absoluteDateFormat }} </span>
    </ng-container>
    <!--Specification-->
    <ng-container *ngIf="offer.specification">
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.SPECIFICATION' | translate">speaker_notes </i>
        <read-more [content]="offer.specification"></read-more>
    </ng-container>
</div>
