import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Actions, ofType } from '@ngrx/effects';
import {
  SuccessfullyLoadedAgencies,
  GetAgenciesList
} from './agency-modal.actions';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { OfferOutDto, hasIndicativeRate } from '@libs/shared/models/offer.model';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { PublicAgency } from '@libs/shared/models/facility.model';

@Component({
  selector: 'staffnow-agencies-list-modal',
  templateUrl: './agencies-list-modal.component.html',
  styleUrls: ['./agencies-list-modal.component.scss']
})
export class AgenciesListModalComponent implements OnInit {
  @Input() public offer: OfferOutDto = null;
  @Input() public onConfirmCallback: Function = null;
  @Input() public contract: any = null;

  private subs: Array<Subscription> = [];
  public agenciesList: Array<PublicAgency> = null;
  public selectedAgency: any = null;
  public readingProfile: PublicAgency = null;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private bsModalRef: BsModalRef
  ) {
    this.subs.push(
      this.actions
        .pipe(ofType(SuccessfullyLoadedAgencies))
        .subscribe(action => (this.agenciesList = action.agenciesList))
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.store.dispatch(
      GetAgenciesList({
        getAgenciesUrl: getUrl(this.offer, JobOfferLinkRel.GetAgencies),
        contract: this.contract
      })
    );
  }

  public selectAgency(agency): void {
    if (
      !this.selectedAgency ||
      (this.selectedAgency && this.selectedAgency.uuid != agency.uuid)
    ) {
      this.selectedAgency = { ...agency };
    } else {
      this.selectedAgency = null;
    }
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public confirm(): void {
    if (this.onConfirmCallback && this.onConfirmCallback instanceof Function) {
      this.onConfirmCallback(this.selectedAgency);
    }
    this.close();
  }

  public setReadingProfile(agency: PublicAgency): void {
    this.readingProfile = agency;
  }

  get hasIndicativeRateForOffer(): boolean {
    return hasIndicativeRate(this.offer as any);
  }
}
