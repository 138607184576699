import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../apps/staffnow-platform/src/app/state/app-state.model';
import {
  selectApprovedAgencies,
  selectApprovedAgenciesStatus
} from '../../../../../../apps/staffnow-platform/src/app/state/app.selectors';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  GetApprovedAgencies,
  SubmitApprovedAgencies
} from '../facility-profile.actions';

@Component({
  selector: 'staffnow-approved-agencies-select',
  templateUrl: './approved-agencies-select.component.html',
  styleUrls: ['./approved-agencies-select.component.scss']
})
export class ApprovedAgenciesSelectComponent implements OnInit, OnDestroy {
  @Input() public isDisabled: boolean = false;
  public approvedAgencies = {
    selectedFacilities: [],
    availableFacilities: []
  };
  private status = 'IDLE';
  private subscription: Subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(GetApprovedAgencies());
    this.subscription.add(
      this.store
        .pipe(selectApprovedAgencies)
        .subscribe(
          approvedAgencies =>
            (this.approvedAgencies = cloneDeep(approvedAgencies))
        )
    );
    this.subscription.add(
      this.store
        .pipe(selectApprovedAgenciesStatus)
        .subscribe(status => (this.status = status))
    );
  }

  get isDirty(): boolean {
    return this.status === 'DIRTY';
  }

  get isLoading(): boolean {
    return this.status === 'LOADING';
  }

  public updateApprovedAgencies($event: any): void {
    this.status = 'DIRTY';
    this.approvedAgencies.selectedFacilities = $event;
  }

  public submitApprovedAgencies(): void {
    if (!this.isDisabled) {
      this.store.dispatch(
        SubmitApprovedAgencies({
          facilities: this.approvedAgencies.selectedFacilities
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
