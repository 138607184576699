import { Component } from '@angular/core';
import { Store, ActionsSubject } from '@ngrx/store';

import {
  SubmitFormToCustomerSupport,
  CustomerSupportFormSubmitted,
  FailedToSubmitCustomerSupportForm,
  GetCustomerSupportTopics
} from '../../../../../apps/staffnow-platform/src/app/state/app.actions'
import { ofType } from '@ngrx/effects'
import { selectCustomerSupportTopics } from 'apps/staffnow-platform/src/app/state/app.selectors'
import type { CustomerSupportTopicTypeState } from 'apps/staffnow-platform/src/app/state/app-state.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors'
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils'
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel'
import type { Resource } from '@libs/shared/bms-common/rest/resource.model'



interface ContactForm {
  subject: string;
  message: string;
  contactTopic: CustomerSupportTopicTypeState;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {
  public isLoading: boolean = false;
  public submitSuccess: boolean = false;
  public contactsFormRestrictions = {
    subject: {
      maxLength: 100
    },
    message: {
      maxLength: 1000
    },
    isFormValid: false
  };

  public contactsForm: ContactForm = {
    subject: '',
    message: '',
    contactTopic: null
  };

  public topicTypes: CustomerSupportTopicTypeState[] = [];

  constructor(private readonly store: Store, private readonly actionsSubject: ActionsSubject) {
    this.actionsSubject.pipe(ofType(CustomerSupportFormSubmitted)).subscribe(() => {
      this.isLoading = false;
      this.submitSuccess = true;
    });

    this.actionsSubject.pipe(ofType(FailedToSubmitCustomerSupportForm)).subscribe(() => {
      this.isLoading = false;
    });

    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      if (hasLink(apiRoot as Resource, ApiRootLinkRel.GetCustomerSupportTopics)) {
        this.getCustomerSupportTopics();
      }
    });
  }

  checkFieldsValidity(): void {
    this.contactsFormRestrictions.isFormValid =
      this.contactsForm.subject.trim() &&
      this.contactsForm.message.trim() &&
      (!this.hasCustomerSupportTopics || !!this.contactsForm.contactTopic);
  }

  submitForm(): void {
    this.isLoading = true;
    this.store.dispatch(
      SubmitFormToCustomerSupport({
        payload: {
          message: this.contactsForm.message,
          subject: this.contactsForm.subject,
          contactTopic: this.hasCustomerSupportTopics ? this.contactsForm.contactTopic.key : null
        }
      })
    );
  }

  get hasCustomerSupportTopics(): boolean {
    return this.topicTypes.length > 0;
  }

  private getCustomerSupportTopics(): void {
    this.store
      .select(selectCustomerSupportTopics)
      .pipe(untilDestroyed(this))
      .subscribe(topicTypes => {
        this.topicTypes = topicTypes;
        if (topicTypes.length === 0) {
          this.store.dispatch(GetCustomerSupportTopics());
        }
      });
  }
}
