import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'staff-logo',
  templateUrl: './staff-logo.component.html'
})
export class StaffLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
