<select
    class="custom-select type-select"
    [(ngModel)]="selectedTitleType"
    (ngModelChange)="onAmeTypeChange()"
    [disabled]="isDisabled"
>
    <option [ngValue]="null">
        {{ 'Select Type' | translate }}
    </option>
    <option *ngFor="let item of ameNomenclature" [ngValue]="item.name">
        {{ item.name }}
    </option>
</select>
<select
    *ngIf="!selectedTypeIsSingleton"
    class="custom-select level-select"
    [(ngModel)]="selectedTitleId"
    (ngModelChange)="onAmeLicenseChange()"
    [disabled]="isDisabled"
>
    <option [ngValue]="null">
        {{ 'Select Level' | translate }}
    </option>
    <option *ngFor="let item of levels" [ngValue]="item.id">
        {{ item.name }}
    </option>
</select>
