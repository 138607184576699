import { Component, Input } from '@angular/core';
import { ContractWithUtils } from '@libs/shared/models/contract.model';

@Component({
  selector: 'staffnow-technician-reasons-details',
  templateUrl: './technician-reasons-details.component.html',
  styleUrls: ['./technician-reasons-details.component.scss']
})
export class TechnicianReasonsDetailsComponent {
  @Input() public contractWithUtils: ContractWithUtils = null;
}
