<i class="material-icons close" (click)="close()">close</i>
<div class="technician-card">
    <div
        class="profile-pic shadow"
        *ngIf="technicianPicture"
        [ngStyle]="{ 'background-image': 'url(\'' + technicianPicture + '\')' }"
    ></div>
    <div class="profile-pic shadow" *ngIf="!technicianPicture">
        <i class="material-icons"> person </i>
    </div>
    <div class="profile-names">
        {{ technicianProfile?.firstName }} {{ technicianProfile?.lastName }}
    </div>
    <div class="profile-title">
        <technician-title-list [titleList]="technician?.ameTitles">
        </technician-title-list>
    </div>
    <div class="other-info">
        <!--Country-->
        <ng-container *ngIf="technician?.country">
            <i class="material-icons">location_on</i>
            <span class="overflow-elipsis">
                {{ technician?.country }}
            </span>
        </ng-container>
        <!--Experience-->
        <i class="material-icons">av_timer</i>
        <span class="overflow-elipsis"
        >{{
                (technician?.experience || 0) + ' Years experience' | translate
            }}
        </span>
        <!--Aircrafts-->
        <i class="material-icons">airplanemode_active</i>
        <span
        >{{ technician?.airplanes | selectedAircraft }}
            <span class="aircraft" *ngIf="technician?.otherAirplanes">
                {{ technician?.otherAirplanes }}
            </span>
        </span>
        <!--Skills-->
        <i class="material-icons">grade</i>
        <span style="margin-left: 15px">
            <ul *ngFor="let skill of getTechnicianSkills()">
                <li>{{ skill }}</li>
            </ul>
        </span>
        <!--Presentation-->
        <i class="material-icons">speaker_notes</i>
        <div class="presentation">{{ technician?.presentation }}</div>
    </div>
    <general-button
        *ngIf="showSendOfferButton"
        (onClick)="onSend()"
        text="Send job opening"
    ></general-button>
    <general-button
        *ngIf="isBackoffice"
        (onClick)="viewFullProfile()"
        text="Open technician profile"
    ></general-button>
    <staffnow-information-banner
        *ngIf="dataProtectionBanner"
        [message]="dataProtectionBanner"
    ></staffnow-information-banner>
</div>
