import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {EMPTY, of} from 'rxjs';
import {
  AgencyRequestOverviewActionTypes,
  FailedToGetTechniciansListing,
  GetOwnTechniciansListing,
  LoadOwnTechnicians,
  OwnTechniciansListingLoaded,
  OwnTechniciansLoaded
} from './agency-request-overview-wrapper.actions';
import {getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {UsersService} from '@libs/shared/services/users.service';
import {getFilteredApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {CustomURLEncoder} from '@libs/shared/helpers/custom-url-encoder';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Injectable()
export class AgencyRequestOverviewEffects {
  public getOwnTechnicians$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgencyRequestOverviewActionTypes.LoadOwnTechnicians),
      switchMap((action: LoadOwnTechnicians) => {
        return this.httpService
          .get(getUrl(this.apiRoot, ApiRootLinkRel.GetOwnTechniciansSimple))
          .pipe(
            map((res: any) => new OwnTechniciansLoaded(res)),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public getOwnTechniciansListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgencyRequestOverviewActionTypes.GetOwnTechniciansListing),
      switchMap((action: GetOwnTechniciansListing) => {
        const params = new HttpParams({
          fromObject: this.usersService.createFilterValueObject(action.filterValue, null, action.page),
          encoder: new CustomURLEncoder()
        });

        const url = getUrl(this.apiRoot, ApiRootLinkRel.GetOwnTechniciansFull);
        return this.httpService.get(url, {params})
          .pipe(
            switchMap((res: any) => {
              return [new OwnTechniciansListingLoaded(res)];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(new FailedToGetTechniciansListing());
            })
          );
      })
    )
  );

  private apiRoot: any = null;

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService,
    private usersService: UsersService
  ) {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      this.apiRoot = apiRoot;
    });
  }
}
