import { Component, Input } from '@angular/core';
import { Contract } from '@libs/shared/models/contract.model';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { LoadPublicProfile } from '@libs/request-overview-common/state/requests-overview.actions';
import { Store } from '@ngrx/store';
import { StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model';
import { isNil } from 'lodash-es';


@Component({
  selector: 'staffnow-technician-contract-details',
  templateUrl: './technician-contract-details.component.html',
  styleUrls: ['./technician-contract-details.component.scss']
})
export class TechnicianContractDetailsComponent {
  @Input() public contract: Contract = null;
  @Input() public isPermanent: boolean;

  constructor(private store: Store) {}

  public onOpenPublicProfile(entityType: EntityTypes): void {
    this.store.dispatch(LoadPublicProfile({ contract: this.contract, entityType }));
  }

  get hasStartDate(): boolean {
    const isPendingOrUnset = StartDatePhaseUtils.isPendingOrUnset(this.contract?.startDatePhase);
    return !isPendingOrUnset && !isNil(this.contract?.startDate);
  }

  get proposedByLabel(): string {
    if (StartDatePhaseUtils.isProposedByMRO(this.contract.startDatePhase)) {
      return 'GENERAL.PROPOSED_BY_AVIATION_COMPANY';
    } else if (StartDatePhaseUtils.isProposedByTechnician(this.contract.startDatePhase)) {
      return 'GENERAL.PROPOSED_BY_TECHNICIAN';
    }
    return 'GENERAL.PROPOSED_BY_AGENCY';
  }

  get datePhaseIsAccepted(): boolean {
    return StartDatePhaseUtils.isAccepted(this.contract.startDatePhase);
  }

  get datePhaseIsProposed(): boolean {
    return StartDatePhaseUtils.isProposed(this.contract.startDatePhase);
  }
}
