export default interface PendingTask {
  type: string;
  linkToAction: string;
  title: string;
  hoursLeft?: number;
  offerTitle?: string;
  offerRefNumber: string;
  offerLevel?: string;
  technicianName?: string;
  mroName: string;
  agencyName?: string;
  links: Link[];
}

export interface Link {
  ref: string;
  href: string;
}

export enum PendingTaskTypeEnum {
  SELECT_NEW_AGENCY = 'SELECT_NEW_AGENCY',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  ANSWER_INVITATION_TO_OFFER = 'ANSWER_INVITATION_TO_OFFER',
  APPLICATION_IN_DOCUMENTS_NEEDED = 'APPLICATION_IN_DOCUMENTS_NEEDED',
  TECHNICIAN_FOR_REVIEW = 'TECHNICIAN_FOR_REVIEW',
  TECHNICIAN_UPLOADED_DOCUMENTS = 'TECHNICIAN_UPLOADED_DOCUMENTS',
  PROPOSE_START_DATE = 'PROPOSE_START_DATE',
  REVIEW_START_DATE = 'REVIEW_START_DATE',
  FINISH_CONTRACT = 'FINISH_CONTRACT',
  AGENCY_CAN_REJECT = 'AGENCY_CAN_REJECT',
  PREPARE_NEXT_STEPS = 'PREPARE_NEXT_STEPS',
  APPROVE_OFFER = 'APPROVE_OFFER',
  REVIEW_CONTRACT_EXTENSION = 'REVIEW_CONTRACT_EXTENSION',
  REVIEW_AGENCY = 'REVIEW_AGENCY',
  REVIEW_PROPOSED_PRICE = 'REVIEW_PROPOSED_PRICE'
}
