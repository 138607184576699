import { Pipe, PipeTransform } from '@angular/core'
import { brandLocalizedTextsConstants } from '@libs/shared/constants/brandLocalizedTexts.constants'
import { getBrandName } from "@libs/shared/bms-common/environment/environment.loader";

@Pipe({
  name: 'brandLocalizationTexts'
})
export class BrandLocalizationTextsPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return brandLocalizedTextsConstants[getBrandName()][value];
  }

}
