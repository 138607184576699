import { Component, Input } from '@angular/core';

@Component({
  selector: 'technician-title-list',
  templateUrl: './technician-title-list.component.html'
})
export class TechnicianTitleListComponent {
  @Input() titleList = [];

  constructor() {}
}
