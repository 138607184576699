import { Component, Input } from '@angular/core';
import {
  isTemporary,
  isFixedPrice,
  isAgencyOffer,
  OfferOutDto,
  PackageOfferDto,
  AgencyOfferOutDto,
  hasIndicativeRate,
  isPermanent
} from '@libs/shared/models/offer.model';
import { CORNER_CARD_TEXT_TYPE_ENUM } from '@libs/common-ui/corner-card-text/corner-card.component';
import { isNil } from 'lodash-es';
import { RoleWrapper, UserRoles, role } from '@libs/shared/models/roles.enum';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';

@Component({ template: '' })
export abstract class AbstractRequestCardComponent {
  @Input() public offer: OfferOutDto | PackageOfferDto | AgencyOfferOutDto = null;

  protected userRole: RoleWrapper = null;
  protected subs: Array<Subscription> = [];

  constructor(private store: Store) {
    this.subs.push(
      this.store.pipe(getLoggedInUserRole).subscribe((r: UserRoles) => {
        this.userRole = role(r);
      })
    );
  }

  get unseen(): boolean {
    return !this.expired && !this.offer?.isSeen;
  }

  get expired(): boolean {
    return this.offerOutDto()?.isExpired;
  }

  get closed(): boolean {
    return this.offer?.isClosed;
  }

  get privateOffer() {
    const isPublic = this.offerOutDto()?.isPublic;
    return !isNil(isPublic) && !isPublic;
  }

  public offerOutDto() {
    return this.offer as OfferOutDto;
  }

  public packageOffer() {
    return this.offer as PackageOfferDto;
  }

  get isFixedPriceJobOpening(): boolean {
    return isFixedPrice(this.offer);
  }

  get isTemporaryJobOpening(): boolean {
    return isTemporary(this.offer);
  }

  get isPermanentJobOpening(): boolean {
    return isPermanent(this.offer);
  }
  get isPackageJobOpening(): boolean {
    return isFixedPrice(this.offer);
  }

  get isAgencyJobOpening(): boolean {
    return isAgencyOffer(this.offer);
  }

  get closedForAgenciesAndTechnicians(): boolean {
    return this.offer?.isClosed;
  }

  get needsCornerCard(): boolean {
    return (this.unseen && this.shouldAlertWhenUnseen) || this.expired || this.closed || this.privateOffer;
  }

  get shouldAlertWhenUnseen(): boolean {
    return (this.userRole.isMro() && !this.isAgencyJobOpening) || (this.userRole.isAgency() && this.isAgencyJobOpening);
  }

  get cornerCardType(): CORNER_CARD_TEXT_TYPE_ENUM {
    return this.unseen && this.shouldAlertWhenUnseen
      ? CORNER_CARD_TEXT_TYPE_ENUM.UNSEEN
      : this.expired
      ? CORNER_CARD_TEXT_TYPE_ENUM.EXPIRED
      : this.closed
      ? CORNER_CARD_TEXT_TYPE_ENUM.CLOSED
      : null;
  }

  get offerAlertText(): string {
    return this.unseen && this.shouldAlertWhenUnseen
      ? 'REQUEST_CARD.OFFER_ALERT.TEXT.UNSEEN'
      : this.expired
      ? 'REQUEST_CARD.OFFER_ALERT.TEXT.EXPIRED'
      : this.closed
      ? 'REQUEST_CARD.OFFER_ALERT.TEXT.CLOSED_FOR_APPLICATIONS'
      : null;
  }

  get offerAlertClass(): string {
    return this.unseen && this.shouldAlertWhenUnseen
      ? 'unseen'
      : this.expired
      ? 'expired'
      : this.closed
      ? 'closed'
      : null;
  }

  public hasIndicativeRateForOffer(offer): boolean {
    return hasIndicativeRate(offer);
  }
}
