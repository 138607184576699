import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { StepElementDirective } from '../step-element.directive';
import { AppState } from '../../../../../state/app-state.model';

@Component({
  selector: 'staffnow-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss', '../../common.scss']
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input() public steps: any[] = [];
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onReset = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onComplete = new EventEmitter();

  @ViewChild(StepElementDirective, { static: true })
  step!: StepElementDirective;

  public currentStep: number = 0;

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.loadComponent(this.currentStep);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onNext(): void {
    this.currentStep = this.currentStep + 1;
    this.loadComponent(this.currentStep);
  }

  onPrevious(): void {
    this.currentStep = this.currentStep - 1;
    this.loadComponent(this.currentStep);
  }

  private loadComponent(index: number): void {
    const component = this.steps[index];

    //@ts-ignore
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component.component
    );

    const viewContainerRef = this.step.viewContainerRef;

    viewContainerRef.clear();

    const reference = viewContainerRef.createComponent<any>(componentFactory);

    reference.instance?.onNext?.subscribe(() => this.onNext());
    reference.instance?.onPrevious?.subscribe(() => this.onPrevious());
    reference.instance?.onReset?.subscribe(() => this.onReset.emit());
    reference.instance?.onComplete?.subscribe(() => this.onComplete.emit());
  }
}
