<div *ngIf="canToggleWeeklyJoSummarySubscription">
    <div class="horizontal-divider"></div>
    <div class="notification-subscription-container">
        <h1 class="h1">Weekly Matching Job Openings subscription</h1>
        <div class="guidelines">
            <p *ngIf="isSubscribedToWeeklyJoSummary">
                You are currently subscribed to the Weekly Matching Job Openings notifications
                and you will receive the latest matching job openings every Tuesday. If you don't
                want to receive them anymore, click the button below.
            </p>
            <p *ngIf="!isSubscribedToWeeklyJoSummary">
                You are not currently subscribed to the Weekly Matching Job Openings notifications.
                If you want to receive the latest matching job openings every Tuesday, click the
                button below.
            </p>
        </div>
        <div class="footer">
            <general-button
                (onClick)="toggleTechnicianWeeklyJoSummarySubscription()"
                [isDisabled]="isLoading"
                text="{{ isSubscribedToWeeklyJoSummary? 'Unsubscribe' : 'Subscribe' }}"
            ></general-button>
        </div>
    </div>
</div>
