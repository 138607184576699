import {Component, Input, OnChanges} from "@angular/core";
import {UserProfileType} from "@libs/shared/models/user-profile.type";
import {ActionsSubject, Store} from "@ngrx/store";
import {getEmbeddedResource, getUrl} from "@libs/shared/bms-common/rest/resource.utils";
import {UserProfileLinkRel} from "@libs/shared/linkrels/user-profile.linkrel";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthorizedUserProfileModel} from "@libs/shared/models/authorized-user-profile.model";
import {getLoggedInUserProfile} from "@libs/shared/bms-common/api-root/api-root.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import { SendExperienceConfirmationRequest, SendExperienceConfirmationRequestFail, SendExperienceConfirmationRequestSuccess } from "@libs/user-profile/components/technician-request/experience-confirmation-request/experience-confirmation-request.actions";
import { ExperienceConfirmationRequestComponent } from "@libs/user-profile/components/technician-request/experience-confirmation-request/experience-confirmation-request.component";
import { ExperienceConfirmationRequestPayload } from "@libs/user-profile/components/technician-request/experience-confirmation-request/experience-confirmation-request.model";

@UntilDestroy()
@Component({
  selector: 'experience-confirmation',
  templateUrl: 'experience-confirmation.component.html',
  styleUrls: ['../backoffice-common-components.scss']
})
export class ExperienceConfirmationComponent implements OnChanges {
  @Input() public userProfile: UserProfileType = null;
  public isLoading: boolean = false;
  private loggedInUserProfile: AuthorizedUserProfileModel = null;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private bsModalService: BsModalService,
    private router: Router
  ) {
    this.setUpLoadingSpinnerSubscription();
    this.store.pipe(
      getLoggedInUserProfile,
      untilDestroyed(this)
    ).subscribe(loggedInUserProfile => this.loggedInUserProfile = loggedInUserProfile)
  }

  private setUpLoadingSpinnerSubscription() {
    this.actionsSubject
      .pipe(
        ofType(
          SendExperienceConfirmationRequestFail,
          SendExperienceConfirmationRequestSuccess
        ),
        untilDestroyed(this)
      ).subscribe(() => (this.isLoading = false));
  }

  ngOnChanges(): void {
    if (this.userProfile._embedded) {
      this.isLoading = false;
    }
  }

  public openSendExperienceConfirmationRequest(): void {
    const {firstName: requesterFirstName, lastName: requesterLastName, additionalData: requesterPosition} = this.loggedInUserProfile;
    const {firstName: technicianFirstName, lastName: technicianLastName} = getEmbeddedResource<any>(this.userProfile, UserProfileLinkRel.Profile);
    this.bsModalService.show(ExperienceConfirmationRequestComponent, {
      ignoreBackdropClick: true,
      initialState: {
        requesterName: `${requesterFirstName} ${requesterLastName}`,
        technicianName: `${technicianFirstName} ${technicianLastName}`,
        requesterPosition: requesterPosition,
        onSendCallback: data => this.sendExperienceConfirmationRequest(data),
      }
    });
  }

  public goToExperienceConfirmationRequestsPage(): void {
    const {email} = getEmbeddedResource<any>(this.userProfile, UserProfileLinkRel.Profile);
    this.router.navigate(['/experience-confirmation'], {queryParams: {'technician': email}});
  }

  private sendExperienceConfirmationRequest(payload: ExperienceConfirmationRequestPayload): void {
    this.isLoading = true;
    this.store.dispatch(SendExperienceConfirmationRequest({
      payload,
      url: getUrl(this.userProfile, UserProfileLinkRel.SendExperienceConfirmationRequest)
    }))
  }

}
