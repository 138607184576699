<staffnow-contract-card-with-head [contract]="contract" (openPublicProfile)="onOpenPublicProfile($event)">
    <div class="contract-card-body">
        <contract-status (openPublicProfile)="onOpenPublicProfile($event)" [contract]="contract"> </contract-status>
        <staffnow-reasons-list *ngIf="hasReasons" [contract]="contract"></staffnow-reasons-list>
        <staffnow-facility-contract-date
            [isPermanent]="offer.offerType === OfferType.PERMANENT"
            [contract]="contract"
            [offerStartDate]="offerStartDate"
            *ngIf="needsToPickADate()"
        ></staffnow-facility-contract-date>
    </div>
    <div class="contract-card-actions">
        <div *ngIf="canAcceptContract" class="button-with-help">
            <general-button
                (onClick)="acceptContractMro()"
                [isDisabled]="hasAlreadyMroAccepted || isOfferEnded"
                size="small"
                [text]="'GENERAL.ACCEPT' | translate"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEnded"
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <div *ngIf="canDeclineContract" class="button-with-help">
            <general-button
                (onClick)="declineContractMro()"
                [isDisabled]="hasAlreadyMroRejected || isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.DECLINE' | translate"
                title="{{ 'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate }}"
                color="danger"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEnded"
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <div *ngIf="canMarkDocumentsNeeded" class="button-with-help">
            <general-button
                (onClick)="markContractAsDocumentsNeeded()"
                [isDisabled]="hasAlreadyMroRejected || isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.MORE_DOCS' | translate"
                title="{{ 'SYSTEM.INFO.DOCUMENTS_NEEDED' | translate }}"
                color="dark"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEnded"
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <general-button
            (onClick)="initiateMessageWithTechnician()"
            [isDisabled]="!canCreateConversationWithTechnician && !canGetPreviousConversationWithTechnician"
            backgroundStyle="outline"
            class="chat-button"
            size="small"
            [text]="'GENERAL.CONTACT_TECHNICIAN' | translate"
            title="{{ 'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate }}"
            color="secondary"
        ></general-button>
        <general-button
            (onClick)="initiateMessageWithAgency()"
            *ngIf="contract.requiresAgencyAcceptance"
            [isDisabled]="!canCreateConversationWithAgency && !canGetPreviousConversationWithAgency"
            backgroundStyle="outline"
            class="chat-button"
            size="small"
            text="Contact agency"
            title="{{ 'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate }}"
            color="secondary"
        ></general-button>
    </div>
</staffnow-contract-card-with-head>
