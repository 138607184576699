import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country } from '@libs/shared/models/country.model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCountries } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { LoadCountry } from '@libs/common-ui/country/state/country-selector.actions';
import { FormControlState } from 'ngrx-forms';

@Component({
  selector: 'country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectorComponent),
      multi: true
    }
  ]
})
export class CountrySelectorComponent implements ControlValueAccessor, OnInit {
  @Input() ngrxFormControlState: FormControlState<string>;
  @Output()
  countryChanged: EventEmitter<string> = new EventEmitter();
  selectedCountryName: string | undefined;
  countries$: Observable<Country[]>;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.countries$ = this.store.pipe(getCountries);
  }

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.selectedCountryName = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onCountryChange(selectedCountryOption: Country): void {
    this.selectedCountryName = selectedCountryOption ? selectedCountryOption.name : '';
    this.onChange(this.selectedCountryName);
    this.onTouched();
    if (selectedCountryOption) {
      this.store.dispatch(LoadCountry({ country: selectedCountryOption }));
    }
    this.countryChanged.emit(this.selectedCountryName);
  }
}
