<div *ngIf="isLoadingAgencies" class="loader-wrapper">
    <staff-loader customClass="splash-screen-loader"></staff-loader>
</div>
<div class="agencies-container" *ngIf="!isLoadingAgencies">
    <staffnow-promotional-agency
        *ngFor="let agency of availableAgencies"
        [agency]="agency"
        [isDownloading]="isDownloading"
        [isOpened]="isOpened(agency.uuid)"
        [isSelected]="isSelected(agency.uuid)"
        [isDisabled]="isDisabled"
        (onClick)="handleAgencyClick(agency.uuid)"
        (onOpenDetails)="handleOpenDetails(agency.uuid)"
        (onDownloadClick)="downLoadDocuments($event)"
    ></staffnow-promotional-agency>
</div>
