import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { isNil } from 'lodash-es';
import { RejectReason } from '@libs/shared/models/reasons.model';
import { Contract } from '@libs/shared/models/contract.model';

@Component({
  selector: 'staffnow-quality-reject',
  templateUrl: './quality-reject.component.html',
  styleUrls: ['./quality-reject.component.scss']
})
export class QualityRejectComponent implements OnInit {
  @Input() public onRejectCallback: Function;
  @Input() public reasonsArray: Array<RejectReason> = [];
  @Input() public contract: Contract;
  @ViewChild('textarea') private textArea: ElementRef;

  public form: UntypedFormGroup;
  public formValid: boolean = false;
  private selectedReasonsValue: Array<RejectReason> = [];
  private readonly explanationMaxLength: number = 250;

  constructor(private bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {}

  public ngOnInit() {
    this.reasonsFormInit();
  }

  get reasonsGetter() {
    return <UntypedFormArray>this.form.get('reasons');
  }

  get canSubmit(): boolean {
    return this.formValid;
  }

  get hasReasonDescription(): boolean {
    return this.selectedReasonsValue.every(
      reason => reason.explanation !== '' && !isNil(reason.explanation)
    );
  }

  public addReasonsControls() {
    const arr = this.reasonsArray.map(() => {
      this.fb.control(false);
    });

    return this.fb.array(arr);
  }

  public setSelectedReasonDescription(event: any, id: number) {
    const reason = this.selectedReasonsValue.find(rsn => rsn.id === id);
    if (reason) {
      reason.explanation = event.target.value.trim();
    }
  }

  public getSelectedReasonsValue() {
    this.selectedReasonsValue = [];

    this.reasonsGetter.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedReasonsValue.push(this.reasonsArray[i]);
      }
    });
  }

  public validateDescriptionLength(event: any) {
    if (event.target.value.length >= this.explanationMaxLength) {
      event.preventDefault();
    }
  }

  public isFormValid() {
    if (this.selectedReasonsValue.length !== 0 && this.hasReasonDescription) {
      this.formValid = true;
      return;
    }

    this.formValid = false;
  }

  public submit() {
    this.onRejectCallback(this.selectedReasonsValue);
    this.close();
  }

  public close() {
    this.bsModalRef.hide();
  }

  private reasonsFormInit(): void {
    this.form = this.fb.group({
      reasons: this.addReasonsControls()
    });
  }
}
