import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { TranslationModule } from '@libs/translation/translation.module';
import { SharedModule as SharedLibModule } from '@libs/shared/shared.module';

import { ErrorMessagePipe } from './pipes/error-message.pipe';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { ToastMessagesModule } from '@libs/toast-messages/toast-messages.module';

@NgModule({
  declarations: [ErrorMessagePipe],
  imports: [
    HttpClientModule,
    TranslationModule,
    CommonUiModule,
    ToastMessagesModule,
    SharedLibModule
  ],
  exports: [
    CommonUiModule,
    ErrorMessagePipe,
    TranslationModule,
    ToastMessagesModule,
    SharedLibModule
  ]
})
export class SharedModule {}
