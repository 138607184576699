import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { addUtils, Contract } from '@libs/shared/models/contract.model';
import { Store } from '@ngrx/store';
import { AppState } from '@capacitor/app';
import { selectDocumentsNeededTemplate } from 'apps/staffnow-platform/src/app/modules/request-overview/state/request-overview.selectors';

@Component({
  selector: 'staffnow-documents-needed-modal',
  templateUrl: './documents-needed-modal.component.html',
  styleUrls: ['./documents-needed-modal.component.scss']
})
export class DocumentsNeededModalComponent implements OnInit {
  @Input() onDocumentsNeededCallback: (pendingMoreDocumentDescription: string) => void;
  @Input() contract: Contract;

  form: UntypedFormGroup;
  pendingMoreDocumentDescription: string = '';
  documentsNeededTemplate: string = '';
  readonly textAreaMaxLength: number = 1000;

  constructor(private bsModalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.pendingMoreDocumentDescription = this.getDocumentsNeededExplanation();
    this.store.select(selectDocumentsNeededTemplate).subscribe(documentsNeededTemplate => {
      this.documentsNeededTemplate = documentsNeededTemplate;
    });
  }

  get canSubmit(): boolean {
    return this.pendingMoreDocumentDescription !== '';
  }

  getDocumentsNeededExplanation(): string {
    return addUtils(this.contract).getPendingReason()?.explanation ?? '';
  }

  submit(): void {
    this.onDocumentsNeededCallback(this.pendingMoreDocumentDescription);
    this.close();
  }

  close(): void {
    this.bsModalRef.hide();
  }

  setPendingMoreDocumentDescription(): void {
    this.pendingMoreDocumentDescription = this.documentsNeededTemplate;
  }
}
