import { Component, Input } from '@angular/core';
import {
  AgencyOfferOutDto,
  isAgencyOffer,
  isFixedPrice,
  isTemporary,
  isPermanent,
  hasIndicativeRate,
  OfferOutDto,
  PackageOfferDto,
  PermanentOfferDto
} from '@libs/shared/models/offer.model';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { MroFacility } from '@libs/shared/models/facility.model';

@Component({
  selector: 'staffnow-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent {
  @Input() public title: string = null;
  @Input() public offer: OfferOutDto | PackageOfferDto | AgencyOfferOutDto | PermanentOfferDto = null;
  @Input() public displayOfferLocation = false;

  get isJobOffer(): boolean {
    return isTemporary(this.offer);
  }

  get isPackageOffer(): boolean {
    return isFixedPrice(this.offer);
  }

  get isAgencyOffer(): boolean {
    return isAgencyOffer(this.offer);
  }

  get isPermanentOffer(): boolean {
    return isPermanent(this.offer);
  }

  hasIndicativeRateForOffer(offer): boolean {
    return hasIndicativeRate(offer);
  }

  get facilityLocation(): string {
    if (this.offer.location && this.displayOfferLocation) {
      return this.offer.location.name;
    }
    return this.facilityProfile.location;
  }

  get facilityPresentation(): string {
    return this.facilityProfile.shortPresentation;
  }

  get facilityChecklist(): string {
    return this.facilityProfile.checklist;
  }

  get isPrivateOffer() {
    return this.isJobOffer && !(this.offer as OfferOutDto)?.isPublic;
  }

  get facilityName(): string {
    return this.facilityProfile.name;
  }

  get facilityProfile(): MroFacility {
    return getEmbeddedResource(this.offer, JobOfferLinkRel.Facility);
  }

  get certifications(): string[] {
    return (this.offer as OfferOutDto).certifications;
  }
}
