import { Component, forwardRef, Input, input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, of, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@libs/common-ui/country/state/country-selector.state.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { selectCountries } from '@libs/common-ui/country/state/country-selector.selectors';
import { DisableAction, EnableAction, FormControlState } from 'ngrx-forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateSelectorComponent),
      multi: true
    }
  ]
})
export class StateSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() ngrxFormControlState: FormControlState<string>;
  country = input.required<string>();
  selectedStateName: string | undefined;
  states$: Observable<State[]>;
  currentCountry$ = toObservable(this.country);

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.states$ = combineLatest([this.currentCountry$, this.store.select(selectCountries)]).pipe(
      switchMap(([currentCountryName, countries]) =>
        of(countries?.find(country => country.name?.toLowerCase() === currentCountryName?.toLowerCase())?.states)
      ),
      tap(states => {
        if (states?.length) {
          this.store.dispatch(new EnableAction(this.ngrxFormControlState.id));
        } else {
          this.store.dispatch(new DisableAction(this.ngrxFormControlState.id));
        }
      })
    );
  }

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.selectedStateName = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onStateChange(selectedState: State): void {
    this.selectedStateName = selectedState ? selectedState.name : '';
    this.onChange(this.selectedStateName);
    this.onTouched();
  }
}
