<i class="material-icons close-icon" (click)="bsModalRef.hide()"> clear </i>
<div class="header">
    <h1 class="heading">{{ title | translate }}</h1>
</div>
<div class="content">
    <div class="body">
        <p>{{ body | translate }}</p>
    </div>
    <div class="text-area-wrapper">
        <textarea
            [(ngModel)]="text"
            class="form-control"
            type="text"
        ></textarea>
    </div>
    <div *ngIf="!!disclaimer" class="disclaimer">
        <p>{{ disclaimer | translate }}</p>
    </div>
    <general-button
        (onClick)="submit()"
        text="Submit"
    ></general-button>
</div>
