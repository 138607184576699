import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { OperatorFunction, pipe } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Conversation,
  FEATURE_STATE_KEY,
  Message
} from '@libs/mailbox/state/mailbox-state.model';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import {
  DeleteConversationMessage,
  LoadMessages,
  MailboxActions
} from '@libs/mailbox/state/mailbox.actions';
import { ofType } from '@ngrx/effects';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { WebSocketService } from '@libs/websocket/websocket.service';

@UntilDestroy()
@Component({
  selector: 'staffnow-conversation-viewer',
  templateUrl: './conversation-viewer.component.html',
  styleUrls: ['./conversation-viewer.component.scss']
})
export class ConversationViewerComponent implements OnDestroy {
  @Output() public closeChat = new EventEmitter<void>();

  public conversation: Conversation = null;
  public ownPicUrl: string = '';
  public messageList: Message[] = null;
  public messageListLoading: boolean = true;
  public automaticMessageLoading: boolean = false;

  private lastMessageTimestamp: number = 0;
  private lastUpdatedMessageTimestamp: number = 0;
  private previousOldestMessageTimestamp: number = null;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private toastMessageService: ToastMessageService,
    private modalService: ModalService,
    private webSocketService: WebSocketService
  ) {
    this.setupStoreSubscriptions();
    this.setupActionsSubscriptions();
  }

  ngOnDestroy(): void {
    if (!!this.conversation.conversationId) {
      this.stopListeningToNewMessages(this.conversation.conversationId);
    }
  }

  public loadOlderMessages(oldestMessageTimestamp: number) {
    if (this.previousOldestMessageTimestamp !== oldestMessageTimestamp) {
      this.previousOldestMessageTimestamp = oldestMessageTimestamp;
      this.store.dispatch(new LoadMessages(null, null, oldestMessageTimestamp));
    }
  }

  public handleClose() {
    this.closeChat.emit();
  }

  private setupStoreSubscriptions() {
    this.storeSubscribe(
      select(state => state[FEATURE_STATE_KEY].selectedConversation),
      selectedConversation => {
        if (
          this.conversation === null ||
          this.conversation.conversationId !==
            selectedConversation.conversationId
        ) {
          this.conversation = selectedConversation;
          this.previousOldestMessageTimestamp = null;
          if (this.conversation?._links) {
            this.store.dispatch(new LoadMessages(this.conversation));
            window.history.replaceState(
              {},
              '',
              `./mailbox/${this.conversation.conversationId}`
            );
          }

          if (!!this.conversation.conversationId) {
            this.listenToNewMessages(this.conversation.conversationId);
          }
        }
      }
    );
    this.storeSubscribe(
      select(state => state[FEATURE_STATE_KEY].messageListLoading),
      messageListLoading => (this.messageListLoading = messageListLoading)
    );
    this.storeSubscribe(pipe(getFilteredApiRoot), apiRoot => {
      if (apiRoot._links.getProfilePicture) {
        this.ownPicUrl = apiRoot._links.getProfilePicture.href;
      }
    });
    this.storeSubscribe(
      select(state => state[FEATURE_STATE_KEY].messageList),
      messageList => {
        this.messageList = messageList.messages;
        this.lastMessageTimestamp = messageList.lastMessageTimestamp;
        this.lastUpdatedMessageTimestamp =
          messageList.lastUpdatedMessageTimestamp;
        this.automaticMessageLoading = false;
      }
    );
  }

  private listenToNewMessages(conversationId: number) {
    const onMessageReceived = (messagePayload: any) => {
      switch (messagePayload.body) {
        case 'NEW_MESSAGE_ADDED_TO_THE_CONVERSATION':
          this.loadNewMessagesOfConversation();
          break;
        case 'MESSAGE_EDITED_FROM_THE_CONVERSATION':
          this.loadUpdatedMessagesOfConversation();
          break;
      }
    };

    this.webSocketService.startListeningToWebSocketQueue(
      `/user/selected-conversation${conversationId}/queue/messages`,
      onMessageReceived
    );
  }

  private stopListeningToNewMessages(conversationId: number) {
    this.webSocketService.stopListeningToWebSocketQueue(
      `/user/selected-conversation${conversationId}/queue/messages`
    );
  }

  private loadNewMessagesOfConversation() {
    this.store.dispatch(
      new LoadMessages(null, this.lastMessageTimestamp, null)
    );
    this.automaticMessageLoading = true;
  }

  private loadUpdatedMessagesOfConversation() {
    if (this.messageList && this.messageList.length > 0) {
      this.store.dispatch(
        new LoadMessages(
          null,
          this.lastUpdatedMessageTimestamp >= 0
            ? this.lastUpdatedMessageTimestamp
            : this.lastMessageTimestamp,
          null,
          true
        )
      );
      this.automaticMessageLoading = true;
    }
  }

  private onAction(...args: any[]) {
    const subscribeFn = args.pop();
    this.actionsSubject
      .pipe(ofType(...args), untilDestroyed(this))
      .subscribe(subscribeFn);
  }

  private setupActionsSubscriptions(): void {
    this.onAction(MailboxActions.SuccessfulConversationFileDownload, () =>
      this.toastMessageService.success(
        'Conversation file successfully downloaded'
      )
    );
    this.onAction(
      MailboxActions.PromptForDeleteConversationMessage,
      (response: any) =>
        this.openDeleteMessageDialog(response.deleteConversationMessageUrl)
    );
    this.onAction(MailboxActions.SuccessfulConversationMessageDelete, () => {
      this.toastMessageService.success(
        'Conversation message successfully deleted'
      );
    });
  }

  private storeSubscribe<T, S>(
    pipedSelector: OperatorFunction<T, S>,
    subscribeFn: (a: S) => void
  ) {
    this.store.pipe(pipedSelector, untilDestroyed(this)).subscribe(subscribeFn);
  }

  private openDeleteMessageDialog(deleteConversationMessageUrl: string) {
    this.modalService.openConfirmModal(
      'Are you sure you want to delete this message?',
      () => {
        this.store.dispatch(
          new DeleteConversationMessage(
            deleteConversationMessageUrl,
            this.lastUpdatedMessageTimestamp
          )
        );
      }
    );
  }
}
