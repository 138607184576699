import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {RegionSelectorService} from '@libs/auth/services/region-selector.service';
import {AppRegionEnum} from '@libs/shared/bms-common/environment/environment.model';
import {DashboardsService} from '@libs/shared/services/dashboards.service';

@Component({
  selector: 'staffnow-quicksight-viewer',
  templateUrl: './quicksight-viewer.component.html',
  styleUrls: ['./quicksight-viewer.component.scss']
})
export class QuicksightViewer implements AfterViewInit {
  @Input()
  private environment: any;
  @Input()
  private dashboardUrl: string;
  @ViewChild('dashboardContainer')
  dashboardContainer: ElementRef;
  isLoading: boolean;

  constructor(
    private regionSelectorService: RegionSelectorService,
    private dashboardService: DashboardsService
  ) {
  }

  ngAfterViewInit(): void {
    this.isLoading = true;

    this.regionSelectorService.getSelectedRegion().then(region => {
      let url;
      if (region == AppRegionEnum.EU) {
        url = `${this.environment.euDashboardsEndpoint}${this.dashboardUrl}/embed-url`;
      } else if (region == AppRegionEnum.USA) {
        url = `${this.environment.usaDashboardsEndpoint}${this.dashboardUrl}/embed-url`;
      }

      this.dashboardService.getDashboardURL(url).subscribe(internalUrl => {
        this.dashboardService
          .embedDashboard(internalUrl, this.dashboardContainer.nativeElement)
          .then(() => (this.isLoading = false));
      });
    });
  }
}
