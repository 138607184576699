import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { StorageService } from "@libs/shared/services/storage.service";

@Injectable()
export class AuthenticationGuardService  {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private storageService: StorageService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (!await this.authenticationService.hasValidAccessToken()) {
      if (!!await this.storageService.getItem("access_token")) {
        this.authenticationService.saveCurrentUrl(AuthenticationGuardService.getCurrentUrl(route));
      }
      this.authenticationService.handleUnauthorizedAccessFromGuard();
      return false;
    }
    return true;
  }

  private static getCurrentUrl(route: ActivatedRouteSnapshot): string {
    return route['_routerState'].url;
  }
}
