<i class="material-icons close pointer" (click)="close()">close</i>
    <div class="add-entity-modal-wrapper">
        <h2> {{ placeholder }} </h2>
        <technician-paginated-selector
            (selectedTechnicianUuidChange)="onSelectedTechnicianSelect($event)"
            [placeholder]="'GENERAL.SELECT_OPTION' | translate"
            [multiple]="multiple"
            [technicialRetrieverUrl]="retrieverUrl">
        </technician-paginated-selector>
        <general-button
            (onClick)="onConfirm()"
            [isDisabled]="!isFormValid()"
            class="mx-auto"
            text="{{'GENERAL.CONFIRM' | translate}}"
        ></general-button>
    </div>

