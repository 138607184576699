export enum RejectReasonType {
  AGENCY_REASON = 'AGENCY_REASON',
  QUALITY_REASON = 'QUALITY_REASON',
  OFFER_CLOSED = 'OFFER_CLOSED',
  PRODUCTION_REASON = 'PRODUCTION_REASON',
  MISSING_DOCUMENTS = 'MISSING_DOCUMENTS'
}

export enum PendingReasonType {
  DOCUMENTS_NEEDED = 'DOCUMENTS_NEEDED'
}
