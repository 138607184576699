import { createAction, props } from '@ngrx/store';

export const ResetAgencyOfferForm = createAction('[Create Agency Offer] Reset agency offer form');
export const FailedToSubmitAgencyOffer = createAction(
  '[Create Agency Offer] Failed to submit agency offer'
);
export const AgencyOfferSubmitSuccess = createAction(
  '[Create Agency Offer] Success agency offer submit',
  props<{ refNumber?: string }>()
);
export const SubmitAgencyOffer = createAction(
  '[Create Agency Offer] Submit public/private agency offer',
  props<{ payload: any; createUrl?: string, editUrl?: string }>()
);
