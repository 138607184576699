<h1 class="h1">Aviation Companies I work with</h1>
<ng-container *ngIf="itemsPerSlide == 0">
    <p class="guidelines">
        {{ 'MRO.APPROVALS.AGENCY_NOT_YET_SELECTED' | translate}}
    </p>
</ng-container>
<ng-container *ngIf="itemsPerSlide > 0">
    <p class="guidelines">
        {{ 'MRO.APPROVALS.ABLE_TO_WORK_WITH' | translate}}
    </p>
    <carousel
        [interval]="4500"
        [itemsPerSlide]="itemsPerSlide"
        [noPause]="true"
    >
        <slide *ngFor="let mroApproval of mroApprovals">
            <div class="approval-wrapper">
                <div class="approval">
                    <facility-logo [resource]="mroApproval"></facility-logo>
                    <span class="mro-name">{{ mroApproval.name }}</span>
                </div>
            </div>
        </slide>
    </carousel>
</ng-container>
