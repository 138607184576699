import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export interface TechnicianRegistrationFormModel {
  email: string;
  password: string;
  confirmPassword: string;
  termsAgreement: boolean;
  canWorkInUsaAgreement: boolean;
  newsletterAgreement: boolean;
  emailVerify: boolean;
}

export interface FormState
  extends FormGroupState<TechnicianRegistrationFormModel> {}

export const initialTechnicianFormState: FormState =
  createFormGroupState<TechnicianRegistrationFormModel>('registrationForm', {
    email: '',
    password: '',
    confirmPassword: '',
    termsAgreement: false,
    canWorkInUsaAgreement: false,
    newsletterAgreement: false,
    emailVerify: true
  });
