import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getFullAmeNomenclature} from '../bms-common/api-root/api-root.selectors';
import {AmeLicenseType, FullAmeNomenclature} from '../models/ame-license-type.model';
import {cloneDeep} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AmeTitleService {
  private nomenclature: FullAmeNomenclature = null;

  constructor(private store: Store<any>) {
    this.store
      .pipe(getFullAmeNomenclature)
      .subscribe(nomenclature => (this.nomenclature = nomenclature));
  }

  public getFullNomenclature(): FullAmeNomenclature {
    return cloneDeep(this.nomenclature);
  }

  public getLicenseId(ameTypeName: string, ameLicense: string) {
    const ameType: AmeLicenseType = this.nomenclature.list.find(
      type => type.name === ameTypeName
    );
    if (ameType.isSingleton) {
      return ameType.licenses[0].id;
    }
    return ameType.licenses.find(license => license.name === ameLicense)?.id;
  }
}
