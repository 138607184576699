import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FailedToLoadAgencies, GetAgenciesList, SuccessfullyLoadedAgencies} from './agency-modal.actions';
import {catchError, switchMap, withLatestFrom} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getFilteredApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {AuthorizedUserProfileModel} from '@libs/shared/models/authorized-user-profile.model';
import {getEmbeddedResource} from '@libs/shared/bms-common/rest/resource.utils';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {UserRolesUtils} from '@libs/shared/models/roles.enum';
import {JobOfferLinkRel} from '@libs/shared/linkrels/job-offer.linkrel';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Injectable()
export class AgencyModalEffects {
  public getAgenciesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetAgenciesList),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        let queryParams;

        const authorizedUserProfile: AuthorizedUserProfileModel = getEmbeddedResource(
          apiRoot,
          ApiRootLinkRel.AuthorizedUserProfile
        );
        const isAnyAdminOrModerator = UserRolesUtils.isAdminOrModerator(
          authorizedUserProfile.role
        );

        const getAgenciesUrl = isAnyAdminOrModerator
          ? action.getAgenciesUrl.replace('&technicianUuid=', '')
          : action.getAgenciesUrl;

        if (action.contract && isAnyAdminOrModerator) {
          queryParams = new HttpParams({
            fromObject: {technicianUuid: action.contract.technicianUserUuid}
          });
        }

        return this.httpService
          .get(getAgenciesUrl, {params: queryParams})
          .pipe(
            switchMap((response: any) => {
              return [
                SuccessfullyLoadedAgencies({
                  agenciesList: getEmbeddedResource(
                    response,
                    JobOfferLinkRel.Facilities
                  )
                })
              ];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(FailedToLoadAgencies());
            })
          );
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private errorMessageService: ErrorMessageService,
    private httpService: HttpClient
  ) {
  }
}
