import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment as builtEnv } from './environments/environment';
import {
  loadEnvironment,
  saveBrandName,
  saveCustomerSupportEmail,
  saveSecondCustomerSupportEmail,
  saveInfoEmail,
  saveEnvironment
} from '@libs/shared/bms-common/environment/environment.loader';
import {
  ApiEnvironmentState,
  BrandName
} from '@libs/shared/bms-common/environment/environment.model';

if (builtEnv.production) {
  enableProdMode();
}

/*
 * Creating this wrapper function is necessary, because otherwise Angular doesn't
 * detect the bootstrap code correctly and lazy loaded modules are left unbundled
 */
function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      preserveWhitespaces: true
    })
    .catch(err => console.log(err));
}


const brandConfig = builtEnv.brandConfig;
const apiUrl = brandConfig.brandName == BrandName.eLAUNCHNow ? builtEnv.usaApiUrl : builtEnv.europeApiUrl;

loadEnvironment(apiUrl + builtEnv.environmentEndpoint).then((remoteData: ApiEnvironmentState) => {
  saveBrandName(brandConfig.brandName);
  saveCustomerSupportEmail(brandConfig.customerSupportEmail);
  saveSecondCustomerSupportEmail(brandConfig.secondCustomerSupportEmail);
  saveInfoEmail(brandConfig.infoEmail);
  saveEnvironment({
    ...remoteData,
    ...builtEnv,
    remoteEndPoint: remoteData.apiPlatformEndpoint
  });

  if (builtEnv.production) {
    enableProdMode();
  }
  bootstrap();
});
