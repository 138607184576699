import {Directive, EventEmitter, Input, Output} from "@angular/core";
import {Subject} from "rxjs";
import {throttleTime} from "rxjs/operators";
import {DURATION_1000_MILLISECONDS} from "@libs/shared/constants/duration.constants";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Directive()
export class AbstractButton {
  @Input() public text: string = null;
  @Input() public isDisabled: boolean = false;
  @Input() public highlight: boolean = false;
  @Input() public throttle: boolean = true;
  @Output() public onClick: EventEmitter<any> = new EventEmitter();

  private clickThrottler: Subject<void> = new Subject();

  constructor() {
    this.clickThrottler
      .pipe(throttleTime(DURATION_1000_MILLISECONDS), untilDestroyed(this))
      .subscribe(() => {
        this.onClick.emit();
      });
  }

  public handleClick($event: MouseEvent): void {
    $event.stopPropagation();
    if (!this.isDisabled) {
      this.emitEvent();
    }
  }

  private emitEvent(): void {
    if (this.throttle === true) {
      this.clickThrottler.next();
    } else {
      this.onClick.emit();
    }
  }

}
