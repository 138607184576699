import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import Step from '../../stepper/step.interface';
import {Store} from '@ngrx/store';
import {mroSelectedAgenciesForm} from '../../../../state/registration.selectors';
import {MroSelectedAgencies} from '../../../../state/registration-state.model';
import {Subscription} from 'rxjs';
import {UpdateSelectedAgenciesList} from '../../../../state/registration.actions';
import {Resource} from '@libs/shared/bms-common/rest/resource.model';
import {extractListOfPromotionalAgencies} from '@libs/shared/helpers/extract-promotional-agencies';
import {RegistrationService} from '../../../../services/registration.service';


@Component({
  selector: 'staffnow-agency-selection',
  templateUrl: './agency-selection.component.html',
  styleUrls: ['./agency-selection.component.scss', '../../../common.scss']
})
export class AgencySelectionComponent implements OnInit, OnDestroy, Step {
  @Output() onNext = new EventEmitter();
  @Output() onPrevious = new EventEmitter();
  @Output() onReset = new EventEmitter();

  public selectedAgencies: Array<string> = [];
  public availableAgencies: Array<any> = [];
  public formState: MroSelectedAgencies;
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<any>,
    private registrationService: RegistrationService
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(mroSelectedAgenciesForm)
        .subscribe((state) => {
          this.formState = state;
          this.selectedAgencies = [...state.selectedAgencies];
          this.subscription.add(
            this.registrationService
              .getAvailableAgencies()
              .subscribe((agenciesDto: Resource) => {
                this.availableAgencies = extractListOfPromotionalAgencies(
                  agenciesDto
                );
              })
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public updateSelection(selected: string[]): void {
    this.selectedAgencies = selected;
  }

  next(): void {
    this.store.dispatch(
      UpdateSelectedAgenciesList({agencies: this.selectedAgencies})
    );
    this.onNext.emit();
  }

  previous(): void {
    this.onPrevious.emit();
  }

  reset(): void {
    this.onReset.emit();
  }
}
