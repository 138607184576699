<div class="contract-action" *ngIf="shouldDisplayAcceptButton">
    <general-button
        id="accept-document-button"
        (onClick)="acceptDocumentEvent()"
        [isDisabled]="isDisabledAcceptDocumentButton"
        size="small"
        [text]="'GENERAL.ACCEPT_CONTRACT' | translate"
    ></general-button>
    <staffnow-info-icon-with-tooltip
        *ngIf="isOfferEnded"
        [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
    ></staffnow-info-icon-with-tooltip>
</div>
<div class="contract-action" *ngIf="canAcceptExtendedDate">
    <general-button
        id="accept-extended-date"
        (onClick)="acceptExtendedDate()"
        [isDisabled]="isOfferEnded"
        backgroundStyle="outline"
        size="small"
        [text]="'GENERAL.ACCEPT_EXTENSION' | translate | uppercase"
    ></general-button>
    <staffnow-info-icon-with-tooltip
        *ngIf="isOfferEnded"
        [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
    ></staffnow-info-icon-with-tooltip>
</div>
<div class="contract-action" *ngIf="canDeclineExtendedDate">
    <danger-button
        id="decline-extended-date"
        (onClick)="declineExtendedDate()"
        [isDisabled]="isOfferEnded"
        [text]="'GENERAL.DECLINE_EXTENSION' | translate | uppercase"
    ></danger-button>
    <staffnow-info-icon-with-tooltip
        *ngIf="isOfferEnded"
        [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
    ></staffnow-info-icon-with-tooltip>
</div>
<div class="contract-action" *ngIf="canDeclineContract">
    <danger-button
        id="decline-contract-button"
        (onClick)="declineContract()"
        [isDisabled]="isOfferEnded"
        title="{{ 'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate }}"
        [text]="'GENERAL.DECLINE_CONTRACT' | translate | uppercase"
    ></danger-button>
    <staffnow-info-icon-with-tooltip
        *ngIf="isOfferEnded"
        [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
    ></staffnow-info-icon-with-tooltip>
</div>
