import { Contract } from '@libs/shared/models/contract.model';

export enum EndDateExtensionPhase {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  IGNORED = 'IGNORED',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED'
}

export class EndDateExtensionPhaseUtils {
  private static phaseCheck = (phases: EndDateExtensionPhase[]) => (
    contract: Contract
  ) => phases.includes(contract?.extensionPhase);

  public static isPendingOrIgnored = EndDateExtensionPhaseUtils.phaseCheck([
    EndDateExtensionPhase.PENDING,
    EndDateExtensionPhase.IGNORED
  ]);
}
