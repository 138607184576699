import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import {
  RegistrationState,
  mroDisplayOptionsReducer,
  mroRegistrationCompanyDetailsFormReducer,
  mroSelectedAgenciesReducer
} from './registration-state.model';
import { technicianRegistrationFormReducer } from '../components/technician-registration-form/technician-registration-form.reducer';
import { registrationComponentReducer } from '../components/registration.component.reducer';

export function getReducers(): ActionReducerMap<RegistrationState> {
  return {
    technicianRegistrationForm: technicianRegistrationFormReducer,
    registerComponent: registrationComponentReducer,
    mroCompanyDetails: mroRegistrationCompanyDetailsFormReducer,
    mroSelectedAgencies: mroSelectedAgenciesReducer,
    mroDisplayOptions: mroDisplayOptionsReducer
  };
}

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<RegistrationState>
>('[Registration] Registered Reducers');
