import { Injectable } from '@angular/core';

@Injectable()
export class MathService {
  constructor() {}

  public convertMBToByte(size: number) {
    const multiplier = 1024;

    return size * multiplier * multiplier;
  }
}
