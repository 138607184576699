import {createAction, props} from "@ngrx/store";
import {ExperienceConfirmationRequestPayload} from "./experience-confirmation-request.model";

export const SendExperienceConfirmationRequest = createAction(
  '[User Profile] SendExperienceConfirmationRequest',
  props<{ url: string, payload: ExperienceConfirmationRequestPayload }>()
);

export const SendExperienceConfirmationRequestFail = createAction(
  '[User Profile] SendExperienceConfirmationRequestFail'
);

export const SendExperienceConfirmationRequestSuccess = createAction(
  '[User Profile] SendExperienceConfirmationRequestSuccess'
);

