import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { ContentChange } from 'ngx-quill';
import { UntypedFormControl } from '@angular/forms';

export interface TextEditorEmittedValue {
  html: string;
  isValid: boolean;
}

@Component({
  selector: 'staffnow-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnChanges {
  @Input() public value: string = '';
  @Input() public maxLength: number = null;
  @Output() public onChange = new EventEmitter<TextEditorEmittedValue>();

  public readonly config = {
    toolbar: [['bold', 'italic', 'underline']]
  };
  public control: UntypedFormControl = new UntypedFormControl();
  public isValid: boolean = true;
  public charactersCounter: number = 0;

  ngOnChanges(): void {
    if (this.value != this.control.value) {
      this.control.setValue(this.value);
      this.setCharactersCounter(
        this.value
          .replace(/<br>/g, '')
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '')
          .replace(/<strong>/g, '')
          .replace(/<\/strong>/g, '')
          .replace(/<em>/g, '')
          .replace(/<\/em>/g, '')
          .replace(/<u>/g, '')
          .replace(/<\/u>/g, '')
      );
    }
  }

  public handleContentChange($event: ContentChange): void {
    this.setCharactersCounter($event.text);
    this.isValid = this.charactersCounter <= this.maxLength;
    this.onChange.emit({
      html: $event.html,
      isValid: this.isValid
    });
  }

  private setCharactersCounter(text: string) {
    const sanitizedText = text.replace(/(\r\n|\r|\n)/g, '');
    this.charactersCounter = sanitizedText?.length ?? 0;
  }
}
