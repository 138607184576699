export enum EntityTypes {
  MRO = 'mro',
  MRO_GROUPS = 'mroGroups',
  MRO_FACILITIES = 'mroFacilities',
  AGENCY = 'agency',
  AGENCY_ORDERING = 'agencyOrdering',
  AGENCY_FACILITIES = 'agencyFacilities',
  TECHNICIAN = 'technician',
  STAFFIE = 'staffie',
  ADMIN = 'admin'
}

export const HumanReadableEntities = {
  [EntityTypes.MRO]: 'Aviation Company',
  [EntityTypes.AGENCY]: 'Agency',
  [EntityTypes.ADMIN]: 'Admin',
  [EntityTypes.TECHNICIAN]: 'Technician',
  [EntityTypes.MRO_FACILITIES]: 'Aviation Company',
  [EntityTypes.AGENCY_FACILITIES]: 'Agency Company',
  [EntityTypes.MRO_GROUPS]: 'Aviation Company Group',
  [EntityTypes.STAFFIE]: 'Staffie'
};

export class EntityTypeUtils {
  private static entityCheck = (entities: Array<EntityTypes>) => (
    entity: EntityTypes
  ) => entities.includes(entity);

  public static isMroOrAgencyOrAgencyFacility = EntityTypeUtils.entityCheck([
    EntityTypes.MRO,
    EntityTypes.AGENCY,
    EntityTypes.AGENCY_FACILITIES
  ]);

  public static isMroOrAgency = EntityTypeUtils.entityCheck([
    EntityTypes.MRO,
    EntityTypes.AGENCY
  ]);

  public static isMroFacilities = EntityTypeUtils.entityCheck([
    EntityTypes.MRO_FACILITIES
  ]);

  public static isTechnician = EntityTypeUtils.entityCheck([
    EntityTypes.TECHNICIAN
  ]);

  public static isMroFacilityOrAgencyFacility = EntityTypeUtils.entityCheck([
    EntityTypes.MRO_FACILITIES,
    EntityTypes.AGENCY_FACILITIES
  ]);

  public static isAdminOrTechnician = EntityTypeUtils.entityCheck([
    EntityTypes.ADMIN,
    EntityTypes.TECHNICIAN
  ]);

  public static isAdmin = EntityTypeUtils.entityCheck([EntityTypes.ADMIN]);

  public static isMro = EntityTypeUtils.entityCheck([EntityTypes.MRO]);

  public static isAgency = EntityTypeUtils.entityCheck([EntityTypes.AGENCY]);

  public static isMroRelated = EntityTypeUtils.entityCheck([
    EntityTypes.MRO,
    EntityTypes.MRO_GROUPS,
    EntityTypes.MRO_FACILITIES
  ]);

  public static isAgencyRelated = EntityTypeUtils.entityCheck([
    EntityTypes.AGENCY,
    EntityTypes.AGENCY_FACILITIES
  ]);

  public static isProfileRelated = EntityTypeUtils.entityCheck([
    EntityTypes.MRO,
    EntityTypes.ADMIN,
    EntityTypes.AGENCY,
    EntityTypes.TECHNICIAN
  ]);

  public static isMroGroup = EntityTypeUtils.entityCheck([
    EntityTypes.MRO_GROUPS
  ]);

  public static isAgencyOrdering = EntityTypeUtils.entityCheck([
    EntityTypes.AGENCY_ORDERING
  ]);
}
