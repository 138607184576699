import {Component, Input, OnChanges} from "@angular/core";
import {FailedToSetTemporaryPassword, SetTemporaryPassword} from "@libs/user-profile/state/user-profile.actions";
import {getUrl} from "@libs/shared/bms-common/rest/resource.utils";
import {UserProfileLinkRel} from "@libs/shared/linkrels/user-profile.linkrel";
import {UserProfileType} from "@libs/shared/models/user-profile.type";
import {ActionsSubject, Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ofType} from "@ngrx/effects";

@UntilDestroy()
@Component({
  selector: 'set-temporary-password',
  templateUrl: 'set-temporary-password.component.html',
  styleUrls: ['../backoffice-common-components.scss']
})
export class SetTemporaryPasswordComponent implements OnChanges {
  @Input() public userProfile: UserProfileType = null;
  public userPassword: string = null;
  public isLoading: boolean = false;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject
  ) {
    this.setUpLoadingSpinnerSubscription();
  }

  private setUpLoadingSpinnerSubscription() {
    this.actionsSubject
      .pipe(
        ofType(FailedToSetTemporaryPassword),
        untilDestroyed(this)
      ).subscribe(() => (this.isLoading = false));
  }

  ngOnChanges(): void {
    if (this.userProfile._embedded) {
      this.isLoading = false;
    }
  }

  handlePasswordChange() {
    this.isLoading = true;
    this.store.dispatch(
      SetTemporaryPassword({
        password: this.userPassword,
        url: getUrl(this.userProfile, UserProfileLinkRel.SetTemporaryPassword)
      })
    );
  }

}