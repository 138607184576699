import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DASHBOARDS_MENU_ITEMS } from '@libs/shared/constants/dashboardMenuItems.constants';

@Component({
  selector: 'staffnow-aviation-dashboard-page',
  templateUrl: './aviation-dashboard-page.component.html'
})
export class AviationDashboardPageComponent {
  get dashboardUrl(): string {
    return DASHBOARDS_MENU_ITEMS.AVIATION_COMPANIES.url;
  }

  get environment(): any {
    return environment;
  }
}
