import { Component, Input, OnInit } from '@angular/core';
import { Rate } from '@libs/shared/models/rate.model';

@Component({
  selector: 'staffnow-rates-history',
  templateUrl: './rates-history.component.html',
  styleUrls: ['./rates-history.component.scss']
})
export class RatesHistoryComponent implements OnInit {
  @Input() public rates: Rate[] = [];

  constructor() {}

  ngOnInit(): void {}
}
