type LocalizedText = {
  StaffNow: AppText,
  eLAUNCHNow: AppText
}

type AppText = {
  ratePerHour: string,
  enterRatePerHour: string
}

export enum BrandLocalizedTextsEnum {
  RatePerHour = 'ratePerHour',
  EnterRatePerHour = 'enterRatePerHour'
}

export const brandLocalizedTextsConstants : LocalizedText = {
  StaffNow: {
    ratePerHour: 'Charge rate per hour',
    enterRatePerHour: 'Enter charge rate per hour'
  },
  eLAUNCHNow: {
    ratePerHour: 'Rate per hour',
    enterRatePerHour: 'Enter rate per hour'
  }
}


