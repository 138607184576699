import {Component, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {isEmpty} from 'lodash-es';
import {RejectReason} from '@libs/shared/models/reasons.model';

@Component({
  selector: 'staffnow-agency-rejects-dialog',
  templateUrl: './agency-reject.component.html',
  styleUrls: ['./agency-reject.component.scss']
})
export class AgencyRejectComponent {
  @Input() public onRejectCallback: Function;
  @Input() public reason: RejectReason = null;

  public explanation: string = null;
  public textAreaMaxLength: number = 500;

  constructor(public bsModalRef: BsModalRef) {
  }

  get isDisabled(): boolean {
    return isEmpty(this.getExplanation());
  }

  private getExplanation(): string {
    return this.explanation?.trim();
  }

  public submit(): void {
    if (!this.isDisabled) {
      this.reason.explanation = this.getExplanation();
      this.onRejectCallback(this.reason);
      this.bsModalRef.hide();
    }
  }

}
