import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import PendingTask from '../../models/pending-task.model';
import { cloneDeep, includes, isEmpty } from 'lodash-es';

@Component({
  selector: 'task-column',
  templateUrl: './task-column.component.html',
  styleUrls: ['./task-column.component.scss']
})
export default class TaskColumnComponent implements OnChanges {
  @Input()
  public title: string;

  @Input()
  public tasks: PendingTask[];

  @Input()
  public filters: string[] = [];

  @Input()
  public mode: string;

  @Input()
  public filtersSelected = false;

  public displayedTasks: PendingTask[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tasks) {
      if (!isEmpty(this.filters)) {
        this.displayedTasks = this.tasks.filter(task =>
          includes(this.filters, task.type)
        );
      } else {
        this.displayedTasks = cloneDeep(this.tasks);
      }
    }
  }
}
