import { Component, Input } from '@angular/core';
import {
  OfferPriority,
  PackageOfferDto
} from '@libs/shared/models/offer.model';
import {
  getEmbeddedResource,
  getUrl,
  hasEmbeddedResource,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';

@Component({
  selector: 'staffnow-package-offer-info',
  templateUrl: './package-offer-info.component.html',
  styleUrls: ['./package-offer-info.component.scss']
})
export class PackageOfferInfoComponent {
  @Input() public offer: PackageOfferDto = null;
  @Input() public applicationExists: boolean = false;
  @Input() public applicationExistsMessage: string = '';

  get facility(): any {
    return getEmbeddedResource(
      this.offer as Resource,
      JobOfferLinkRel.Facility
    );
  }

  private get hasFacility(): boolean {
    return hasEmbeddedResource(
      this.offer as Resource,
      JobOfferLinkRel.Facility
    );
  }

  get pictureUrl(): string {
    return hasLink(this.facility, JobOfferLinkRel.FacilityImage)
      ? getUrl(this.facility, JobOfferLinkRel.FacilityImage)
      : null;
  }

  get facilityName(): string {
    return this.hasFacility ? this.facility.name : null;
  }

  get offerPriority(): OfferPriority | null {
    return 'priority' in this.offer ? this.offer.priority : null;
  }
}
