<label for="mro-facility-select">
    Aviation Group Facility*
</label>
<ng-select
    (change)="handleChange($event)"
    [clearSearchOnAdd]="true"
    [closeOnSelect]="true"
    [disabled]="isDisabled"
    [items]="facilityList"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="selectedFacility"
    [searchable]="true"
    bindLabel="name"
    id="mro-facility-select"
    name="mro-facility-select"
    placeholder="Select the Aviation Company facility"
></ng-select>
<div *ngIf="selectedFacility == null" class="validation-error">
    <span>This field is required to create the job opening.</span>
</div>
