import { Country } from '@libs/shared/models/country.model';

export const COUNTRY_STATE_FEATURE_KEY = 'countryState';

export interface CountrySelectorStateModel {
  countries: EnrichedCountry[];
}

export interface EnrichedCountry extends Country {
  states: State[];
}

export interface State {
  name: string;
  code: string;
}
