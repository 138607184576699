import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'staffnow-panel',
  templateUrl: './information-panel.component.html',
  styleUrls: ['./information-panel.component.scss']
})
export class InformationPanelComponent {
  @Input() public title: string = null;
  @HostBinding('class.shadow-sm') shadow = true;
}
