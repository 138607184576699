<div>
    <label class="h1">{{ 'TEXT_EDITOR.SERVICES_DESCRIPTION' | translate }}</label>
    <span class="text-muted">{{
        'TEXT_EDITOR.VISIBLE_TO_AC_ONLY' | translate
    }}</span>
    <quill-editor
        theme="snow"
        [modules]="config"
        [formControl]="control"
        (onContentChanged)="handleContentChange($event)"
        [styles]="{
            'font-family':
                'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif'
        }"
    ></quill-editor>
    <staffnow-text-counter
        [textLength]="charactersCounter"
        [maxLength]="maxLength"
    ></staffnow-text-counter>
    <div *ngIf="!isValid" class="validation-error text-danger">
        {{ 'TEXT_EDITOR.DESCRIPTION_TOO_LONG' | translate }}
    </div>
</div>
