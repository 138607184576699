import { createAction, props } from '@ngrx/store';
import { ApiRootResource } from './api-root.model';

export interface StaffnowClaims {
  email: string;
  regions: string[];
}

export const LoadApiRoot = createAction('[API Root] LoadApiRoot', props<{ region: string }>());

export const ReloadApiRoot = createAction('[API Root] ReloadApiRoot', props<{ region: string }>());

export const AdminOrModeratorProfileUpdated = createAction('[API Root] AdminOrModeratorProfileUpdated');

export const ApiRootLoaded = createAction('[API Root] ApiRootLoaded', props<{ payload: ApiRootResource }>());

export const LoadLocationList = createAction('[API Root] LoadLocationList');

export const GlobalReset = createAction('[API Root] GlobalReset');
