<div class="contract-head-wrapper">
    <div class="contract-card-head">
        <facility-logo [resource]="contract" size="small"></facility-logo>
        <div class="agency-data">
            <h3 class="agency-name">{{ contract?.agencyName }}</h3>
        </div>
    </div>
    <div class="contract-card-head">
        <div class="agency-data-see-approvals">
            <h3 class="see-approvals" (click)="openSeeAgencyApprovalsModal()">
                {{'GENERAL.SEE_APPROVALS' | translate}}
            </h3>
        </div>
    </div>
</div>
<div class="contract-content-wrapper">
    <div class="contract-card-body">
        <staffnow-package-contract-status
            [contract]="contract"
        ></staffnow-package-contract-status>
        <div>
            <strong>{{ 'GENERAL.PRICE' | translate }} </strong>
            <ng-container
                *ngIf="
                    contract.agencyOfferedPrices.length > 0;
                    else waitingForAgency
                "
            >
                <staffnow-rates-history
                    [rates]="contract.agencyOfferedPrices"
                ></staffnow-rates-history>
                <template [ngTemplateOutlet]="priceEstimate"></template>
            </ng-container>
        </div>
        <div *ngIf="contract.mroOfferedPrice">
            <strong>{{'GENERAL.COUNTEROFFER_FROM_AVIATION_COMPANY' | translate}}</strong>
            <ng-container>
                {{ contract.mroOfferedPrice | rate }}
                <template [ngTemplateOutlet]="priceEstimate"></template>
            </ng-container>
        </div>
        <div>
            <strong>Start Date: </strong>
            <ng-container *ngIf="contract.startDate; else waitingForAgency">
                {{ contract.startDate | absoluteDateFormat }}
            </ng-container>
        </div>
        <div>
            <strong>Finish Date: </strong>
            <ng-container *ngIf="contract.endDate; else waitingForAgency">
                {{ contract.endDate | absoluteDateFormat }}
            </ng-container>
        </div>
        <div *ngIf="contract.agencyNotes">
            <strong>Notes: </strong>
            {{ contract.agencyNotes }}
        </div>
        <collapsible
            *ngIf="contractHasWorkers()"
            [heading]="'PACKAGE_CONTRACT_CARD.WORKERS' | translate: {count: workers.length}"
        >
            <staffnow-chip *ngFor="let worker of workers" [shadow]="false">
                <div class="package-contract-technician">
                    <staffnow-technician-picture-and-name
                        [resource]="worker"
                        (onClick)="openTechnicianProfile($event)"
                    ></staffnow-technician-picture-and-name>
                </div>
            </staffnow-chip>
        </collapsible>
    </div>
    <div class="contract-card-actions">
        <div *ngIf="canAcceptContract" class="button-with-help">
            <general-button
                (onClick)="acceptContractMro()"
                [isDisabled]="isOfferEnded"
                size="small"
                [text]="'GENERAL.ACCEPT' | translate"
            ></general-button>
            <ng-container
                *ngTemplateOutlet="disabledActionOnOfferEnded"
            ></ng-container>
        </div>
        <div *ngIf="canDeclineContract" class="button-with-help">
            <general-button
                (onClick)="declineContractMro()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.DECLINE' | translate"
                color="danger"
            ></general-button>
            <ng-container
                *ngTemplateOutlet="disabledActionOnOfferEnded"
            ></ng-container>
        </div>
        <div *ngIf="canAcceptPrice" class="button-with-help">
            <general-button
                (onClick)="acceptPrice()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.ACCEPT_PRICE' | translate"
            ></general-button>
            <ng-container
                *ngTemplateOutlet="disabledActionOnOfferEnded"
            ></ng-container>
        </div>
        <div *ngIf="canProposePrice" class="button-with-help">
            <general-button
                (onClick)="proposePrice()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.PROPOSE_PRICE' | translate"
            ></general-button>
            <ng-container
                *ngTemplateOutlet="disabledActionOnOfferEnded"
            ></ng-container>
        </div>
        <general-button
            (onClick)="initiateMessageWithAgency()"
            [isDisabled]="
                !canCreateConversationWithAgency &&
                !canGetPreviousConversationWithAgency
            "
            backgroundStyle="outline"
            class="chat-button"
            size="small"
            [text]="'GENERAL.CONTACT_AGENCY' | translate"
            color="secondary"
        ></general-button>
    </div>
</div>
<ng-template #waitingForAgency>{{ 'PACKAGE_CONTRACT_CARD.WAITING_FOR_AGENCY' | translate }}</ng-template>
<ng-template #priceEstimate>
    <span *ngIf="contract.priceEstimate">
        {{'AGENCY.PACKAGE_OFFER.DETAILS.BEST_ESTIMATION' | translate}}
    </span>
</ng-template>
<ng-template #disabledActionOnOfferEnded>
    <staffnow-info-icon-with-tooltip
        *ngIf="isOfferEnded"
        [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
    ></staffnow-info-icon-with-tooltip>
</ng-template>
