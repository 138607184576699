import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {role, RoleWrapper, UserRolesUtils} from '@libs/shared/models/roles.enum';
import {profileStatusConstants} from '@libs/shared/constants/statuses.constants';
import {OfferType} from '@libs/shared/models/offer.model';
import {AbstractCustomNavigationService} from "@libs/shared/services/abstract-custom-navigation.service";

@Injectable({
  providedIn: 'root'
})
export class CustomNavigationService extends AbstractCustomNavigationService {

  constructor(store: Store<any>, router: Router) {
    super(store, router);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public goToDefaultView(): void {
    this.router.navigate(
      CustomNavigationService.getMainViewRoute(this.authorizedUser)
    );
  }

  public gotoMailbox(): void {
    this.router.navigate(['mailbox']);
  }

  public goToCustomerSupportForm(): void {
    this.router.navigate(['help'], {
      queryParams: {
        tab: 'contact'
      }
    });
  }

  public goToRouteAfterError(
    routeProvider: (role: RoleWrapper) => string[]
  ): void {
    this.popHistory();
    this.router.navigate(routeProvider(role(this.authorizedUser.role)), {
      replaceUrl: true
    });
  }

  public goToOfferDetails(refNumber: string, offerType: OfferType, replaceUrl: boolean = false): void {
    this.popHistory();
    if (replaceUrl) {
      this.popHistory();
    }
    const contractsPath: string =
      offerType === OfferType.AGENCY
        ? 'agency-offer-contracts'
        : offerType === OfferType.FIXED_PRICE
          ? 'package-contracts'
          : offerType === OfferType.PERMANENT
            ? 'permanent-contracts'
            : 'contracts';
    this.router.navigate([contractsPath, refNumber], {replaceUrl});
  }

  private static getMainViewRoute({role, profileStatus}): string[] {
    if (UserRolesUtils.isTechnician(role)) {
      const isProfileIncomplete =
        profileStatus === profileStatusConstants.incomplete;
      return isProfileIncomplete ? ['/profile'] : ['/job-overview/matching'];
    } else if (UserRolesUtils.isAgency(role)) {
      return ['agency-request-overview'];
    } else if (UserRolesUtils.isMro(role)) {
      return ['request-overview'];
    } else if (UserRolesUtils.isAdminOrModerator(role)) {
      return ['technicians'];
    }
    return ['/profile'];
  }

  public static getOffersRoute(role: RoleWrapper): string[] {
    if (role.isTechnician()) {
      return ['vacancy-overview'];
    } else if (role.isAgency()) {
      return ['agency-request-overview'];
    } else if (role.isMro()) {
      return ['request-overview'];
    }
    return ['/profile'];
  }

}
