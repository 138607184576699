import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { AdditionalBenefit } from '@libs/shared/models/additional-benefit.model';
import { OfferPriority } from '@libs/shared/models/offer.model';

export interface CreateAgencyOfferFormModel {
  refNumber?: string;
  useCustomReference: boolean;
  title?: string;
  ameType: string;
  ameTitleId: string;
  workPattern: string;
  otherAirplanes: string;
  shortPresentation: string;
  vacancies: number;
  hasIndicativeRate: boolean;
  payRate: number;
  maxPayRate: number;
  workDaysOn: number;
  workDaysOff: number;
  minExperience: number;
  airplanes: Array<any>;
  additionalBenefits: Array<AdditionalBenefit>;
  priority: OfferPriority;
  packageContractId: number;
}

export interface FormState extends FormGroupState<CreateAgencyOfferFormModel> {}

export const createAgencyOfferInitialFormState: FormState = createFormGroupState<CreateAgencyOfferFormModel>(
  'createAgencyOffer',
  {
    refNumber: null,
    useCustomReference: false,
    title: '',
    shortPresentation: '',
    vacancies: null,
    ameType: null,
    ameTitleId: null,
    airplanes: [],
    otherAirplanes: '',
    minExperience: null,
    hasIndicativeRate: false,
    payRate: null,
    maxPayRate: null,
    workPattern: '',
    workDaysOn: null,
    workDaysOff: null,
    additionalBenefits: [],
    priority: OfferPriority.MEDIUM,
    packageContractId: null
  }
);
