import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Store } from '@ngrx/store';
import {
  GlobalReset,
  ReloadApiRoot
} from '@libs/shared/bms-common/api-root/api-root.actions';
import { RegionSelectorService } from '@libs/auth/services/region-selector.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'staffnow-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss']
})
export class RegionSelectorComponent {
  public selectedRegion: string;
  userRegions: string [] = [];

  constructor(
    private store: Store<any>,
    private authenticationService: AuthenticationService,
    private regionSelectorService: RegionSelectorService
  ) {
    this.regionSelectorService
      .getSubject()
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(region => {
        this.selectedRegion = region;
      });
      this.getIdentityClaims().then(staffnowClaims => {
          this.userRegions = staffnowClaims.regions;
      });
  }

  public canSelectRegion(): boolean {
      return this.userRegions.length > 1;
  }

  public load(): void {
    this.store.dispatch(GlobalReset());
    this.store.dispatch(
      ReloadApiRoot({
        region: this.selectedRegion
      })
    );
  }

  private getIdentityClaims() {
    return this.authenticationService.getIdentityClaims();
  }
}
