import {Component, Input} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'staff-loader',
  templateUrl: './staff-loader.component.html'
})
export class StaffLoaderComponent {
  @Input() public customClass: string = '';
}
