<change-user-email
    [userProfile]="userProfile"
></change-user-email>

<div class="horizontal-divider"></div>

<set-temporary-password
    [userProfile]="userProfile"
></set-temporary-password>

<ng-container *ngIf="canSendExperienceConfirmationRequest">
    <div class="horizontal-divider"></div>
    <experience-confirmation
        [userProfile]="userProfile"
    ></experience-confirmation>
</ng-container>

<ng-container *ngIf="canSendPerformanceFeedbackRequest">
    <div class="horizontal-divider"></div>
    <performance-feedback
        (previousRequests)="goToPreviousRequests()"
        [userProfile]="userProfile"
    ></performance-feedback>
</ng-container>

<ng-container *ngIf="canUpdateTechniciansRefNumber">
    <div class="horizontal-divider"></div>
    <set-technician-reference-number
        [userProfile]="userProfile"
    ></set-technician-reference-number>
</ng-container>
