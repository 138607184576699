import {Component, Input} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {BsModalRef} from "ngx-bootstrap/modal";
import {PerformanceFeedbackRequestPayload} from "./performance-feedback-request.model";
import { TechnicianRequestGenericComponent } from '../technician-request-generic.component';

@UntilDestroy()
@Component({
  selector: 'performance-feedback-request',
  templateUrl: './performance-feedback-request.component.html',
  styleUrls: ['./../technician-request-generic.component.scss']
})
export class PerformanceFeedbackRequestComponent extends TechnicianRequestGenericComponent<PerformanceFeedbackRequestPayload> {
  @Input() public requesterName: string = null;
  @Input() public technicianName: string = null;
  @Input() public requesterPosition: string = null;
  @Input() public onSendCallback: Function = null;
  public recipientAviationCompany: string = null;
  public recipientName: string = null;
  public requestReason: string = null;
  public recipientEmail: string = null;

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  get requesterNameAndPosition(): string {
    return `${this.requesterName}, ${this.requesterPosition}`;
  }

  public isDisabled(): boolean {
    return (
      !this.isValidEmail(this.recipientEmail) ||
      this.isMissing(this.requesterName) ||
      this.isMissing(this.technicianName) ||
      this.isMissing(this.requesterPosition) ||
      this.isMissing(this.recipientAviationCompany) ||
      this.isMissing(this.recipientName) ||
      this.isMissing(this.requestReason)
    );
  }

  protected get sendCallback(): Function {
    return this.onSendCallback;
  }

  protected preparePayload(): PerformanceFeedbackRequestPayload {
    return {
      requesterName: this.requesterName.trim(),
      technicianName: this.technicianName.trim(),
      requesterPosition: this.requesterPosition.trim(),
      recipientAviationCompany: this.recipientAviationCompany.trim(),
      recipientName: this.recipientName.trim(),
      recipientEmail: this.recipientEmail.trim(),
      requestReason: this.requestReason.trim()
    };
  }
}
