import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { findKey } from 'lodash-es';
import { DASHBOARDS_MENU_ITEMS } from '@libs/shared/constants/dashboardMenuItems.constants';

@UntilDestroy()
@Component({
  selector: 'staffnow-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})
export class DashboardsComponent implements OnInit {
  public menuItems: Array<string> = [
    DASHBOARDS_MENU_ITEMS.REJECTIONS.label,
    DASHBOARDS_MENU_ITEMS.AVIATION_COMPANIES.label
  ];
  public activeItem: string;
  constructor(private router: Router) {}

  ngOnInit(): any {
    const KEY = findKey(DASHBOARDS_MENU_ITEMS, {
      url: this.router.url.split('/')[2]
    });
    this.activeItem = DASHBOARDS_MENU_ITEMS[KEY].label;
  }

  public handleSelectItem(item: string): void {
    const KEY = findKey(DASHBOARDS_MENU_ITEMS, { label: item });
    this.activeItem = item;
    this.router.navigateByUrl(`/dashboards/${DASHBOARDS_MENU_ITEMS[KEY].url}`);
  }
}
