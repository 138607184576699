<div class="tasks-column">
    <div class="header">
        {{ title }}
    </div>
    <div class="content">
        <ng-container *ngIf="mode === 'Rows'">
            <task-row *ngFor="let task of displayedTasks" [task]="task"></task-row>
        </ng-container>
        <ng-container *ngIf="mode === 'Columns'">
            <task *ngFor="let task of displayedTasks" [task]="task"></task>
        </ng-container>
        <span *ngIf="displayedTasks.length === 0 && !filtersSelected" class="empty-tasks-message w-100 text-center">
            {{ 'TASKS.EMPTY.LABEL' | translate }}
        </span>
    </div>
</div>
