import { Component, OnInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { getFilteredApiRoot, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { role } from '@libs/shared/models/roles.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil, isNull } from 'lodash-es';
import {
  FaqsListLoaded,
  GetFaqsList,
  GetTitlesRequirementList,
  TitlesRequirementListLoaded
} from '../../state/app.actions';
import { BulletPointItem } from '@libs/shared/models/bullet-point.model';
import { ofType } from '@ngrx/effects';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';

export interface MenuItem {
  value: string;
  label: string;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-help-wrapper',
  templateUrl: './help-wrapper.component.html',
  styleUrls: ['./help-wrapper.component.scss']
})
export class HelpWrapperComponent implements OnInit {
  public menuItems: MenuItem[] = [
    /*
    THIS TAB IS TEMPORARILY DISABLED, it may return in the future
    { value: 'guidelines', label: 'Platform Guidelines' },
    */
    { value: 'contact', label: 'Customer Support' }
  ];

  public activeTab: MenuItem;
  public apiRegion: string;
  public bulletPoints: BulletPointItem[] = [];

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(
    private readonly store: Store<any>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly actionsSubject: ActionsSubject
  ) {
    this.store.pipe(getFilteredApiRoot, untilDestroyed(this)).subscribe(apiRoot => {
      this.apiRegion = apiRoot.apiRegion;
      this.store
        .pipe(
          getLoggedInUserRole,
          take(1),
          map(r => role(r))
        )
        .subscribe(loggedInUserRole => {
          if (loggedInUserRole.isTechnician() && this.isPlatformWeb) {
            this.menuItems.unshift({
              value: 'faq',
              label: 'GENERAL.FAQ'
            });
            this.menuItems.push({
              value: 'titles-requirements',
              label: 'HELP.TITLE_REQUIREMENTS_ITEM_MENU.LABEL'
            });
          }
          this.openDefaultSelectedTab();
        });
    });

    this.actionsSubject.pipe(ofType(FaqsListLoaded)).subscribe(action => {
      this.bulletPoints = action.faqsList;
    });

    this.actionsSubject.pipe(ofType(TitlesRequirementListLoaded)).subscribe(action => {
      this.bulletPoints = action.titlesRequirementList;
    });

    if (isNil(this.activeTab)) {
      this.activeTab = this.menuItems[0];
    }
  }

  ngOnInit(): void {
    this.handleTabChange();
  }

  private openDefaultSelectedTab(): void {
    if (!isNull(this.queryParams.tab)) {
      this.activeTab = this.getMenuItem(this.queryParams.tab);
    }
  }

  private getMenuItem(value): MenuItem {
    return this.menuItems.find(menuItem => menuItem.value === value);
  }

  private get queryParams(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  public handleTabChange(): void {
    switch (this.activeTab?.value) {
      case 'faq':
        this.store.dispatch(GetFaqsList());
        break;
      case 'titles-requirements':
        this.store.dispatch(GetTitlesRequirementList());
        break;
    }
  }
}
