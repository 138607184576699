import {Component, EventEmitter, Input, Output} from '@angular/core';
import {getEmbeddedResource, getUrlNullsafe, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PackageOfferDto, PackageOfferFileDto} from '@libs/shared/models/offer.model';
import {PackageOfferLinkRel} from '@libs/shared/linkrels/package-offer.linkrel';
import {PackageOfferFileService} from './package-offer-file.service';
import {ToastMessageService} from '@libs/toast-messages/toast-message.service';
import {downloadFileBlob} from '@libs/shared/helpers/download-blob-file';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@UntilDestroy()
@Component({
  selector: 'staffnow-manage-package-offer-files',
  templateUrl: './manage-package-offer-files.component.html',
  styleUrls: ['./manage-package-offer-files.component.scss']
})
export class ManagePackageOfferFilesComponent {

  @Input()
  packageOffer: PackageOfferDto;

  @Output()
  onActionSuccessful: EventEmitter<any> = new EventEmitter<any>();

  public allowedFileTypes = ['pdf', 'png', 'jpg', 'jpeg', 'doc'];
  public isDownloadingAllDocuments: boolean = false;

  constructor(
    private packageOfferFileService: PackageOfferFileService,
    private toastMessageService: ToastMessageService,
    private errorMessageService: ErrorMessageService
  ) {
  }

  get canEdit(): boolean {
    const offerFiles = getEmbeddedResource(this.packageOffer, PackageOfferLinkRel.OfferFiles);
    return hasLink(this.packageOffer, PackageOfferLinkRel.UploadPackageOfferFiles) ||
      hasLink(offerFiles[0], PackageOfferLinkRel.DeletePackageOfferFile);
  }

  get areFilesEmpty(): boolean {
    return this.packageOfferFiles.length === 0;
  }

  get uploadPackageOfferFileUrl(): string {
    return getUrlNullsafe(this.packageOffer, PackageOfferLinkRel.UploadPackageOfferFiles);
  }

  get packageOfferFiles(): any[] {
    return getEmbeddedResource(this.packageOffer, PackageOfferLinkRel.OfferFiles);
  }

  public onSuccessfulFileUpload() {
    this.onActionSuccessful.emit();
  }

  public downloadAllDocuments() {
    this.isDownloadingAllDocuments = true;
    this.packageOfferFileService
      .downloadPackageOfferFilesAsZip(this.packageOffer)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          downloadFileBlob(response);
          this.toastMessageService.success("Package offer files successfully downloaded");
        },
        (response) => {
          this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'Failed to download package offer files')
        },
        () => {
          this.isDownloadingAllDocuments = false;
        }
      );
  }

  public removePackageOfferFile(packageOfferFile: PackageOfferFileDto) {
    if (this.canEdit) {
      this.packageOfferFileService
        .deletePackageOfferFile(packageOfferFile)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.toastMessageService.success("Package offer file successfully deleted");
            this.onActionSuccessful.emit();
          },
          (response) => {this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'Failed to delete package offer file');
          }
        );
    }
  }

  public downloadPackageOfferFile(packageOfferFile: PackageOfferFileDto) {
    this.packageOfferFileService
      .downloadPackageOfferFile(packageOfferFile)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          downloadFileBlob(response);
          this.toastMessageService.success("Package offer file successfully downloaded");
        },
        (response) => {
          this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'Failed to download package offer file');
        }
      );
  }
}
