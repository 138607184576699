import { createSelector, select, Selector } from '@ngrx/store';
import { ApiRootResource, AppState } from './api-root.model';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getEmbeddedResource, getUrl, hasLink } from '../rest/resource.utils';
import { ApiRootLinkRel } from '../../linkrels/api-root.linkrel';
import { AuthorizedUserProfileModel } from '../../models/authorized-user-profile.model';
import { FullAmeNomenclature } from '../../models/ame-license-type.model';
import { sortBy } from 'lodash-es';
import { Country } from '@libs/shared/models/country.model';
import { Skill } from '@libs/shared/models/skills.model';
import { Aircraft } from '@libs/shared/models/aircraft.model';
import { License } from '@libs/shared/models/license.model';

export const getApiRoot: Selector<AppState, ApiRootResource | null> = state => state.apiRoot.apiRoot;

export const getFilteredApiRoot = pipe(
  select(getApiRoot),
  filter(apiRoot => !!apiRoot)
);

export const getAirplanes = pipe(
  getFilteredApiRoot,
  select(apiRoot => getEmbeddedResource<Aircraft[]>(apiRoot, ApiRootLinkRel.Airplanes))
);

export const getAirplanesSortedByName = pipe(
  getFilteredApiRoot,
  select(apiRoot => sortBy(getEmbeddedResource<Aircraft[]>(apiRoot, ApiRootLinkRel.Airplanes), ['name']))
);

export const getSkillsSortedByName = pipe(
  getFilteredApiRoot,
  select(apiRoot => getEmbeddedResource<Skill[]>(apiRoot, ApiRootLinkRel.Skills))
);

export const getLanguagesSortedByName = pipe(
  getFilteredApiRoot,
  select(apiRoot => sortBy(getEmbeddedResource<any[]>(apiRoot, ApiRootLinkRel.Languages), ['name']))
);

export const getMroFacilitiesSortedByName = pipe(
  getFilteredApiRoot,
  select(apiRoot => sortBy(getEmbeddedResource<any[]>(apiRoot, ApiRootLinkRel.MroFacilityList), ['name']))
);

export const getFullAmeNomenclature = pipe(
  getFilteredApiRoot,
  select(apiRoot => getEmbeddedResource<FullAmeNomenclature>(apiRoot, ApiRootLinkRel.AmeNomenclatures))
);

export const getCountries = pipe(
  getFilteredApiRoot,
  select(apiRoot => getEmbeddedResource<Country[]>(apiRoot, ApiRootLinkRel.Countries))
);

export const getLicenses = pipe(
  getFilteredApiRoot,
  select(apiRoot => getEmbeddedResource<License[]>(apiRoot, ApiRootLinkRel.Licenses))
);

export const getLoggedInUserProfile = pipe(
  getFilteredApiRoot,
  select(apiRoot => getEmbeddedResource<AuthorizedUserProfileModel>(apiRoot, ApiRootLinkRel.AuthorizedUserProfile))
);

export const getOwnProfileUrl = pipe(
  getFilteredApiRoot,
  select(apiRoot => getUrl(apiRoot, ApiRootLinkRel.MyProfile))
);

export const getLoggedInUserRole = createSelector(
  getLoggedInUserProfile,
  select(userProfile => userProfile.role)
);

export const getDocumentAreasURL = pipe(
  getFilteredApiRoot,
  select(apiRoot => {
    return getUrl(apiRoot, ApiRootLinkRel.DocumentAreasURL);
  })
);

export const getPendingTasksUrl = pipe(
  getFilteredApiRoot,
  select(apiRoot => {
    if (hasLink(apiRoot, ApiRootLinkRel.GetPendingTasks)) {
      return getUrl(apiRoot, ApiRootLinkRel.GetPendingTasks);
    }
    return null;
  })
);
