import { Injectable } from '@angular/core'

import { LoadBadges } from './state/notifications.actions'
import { WebSocketService } from '@libs/websocket/websocket.service'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'

@UntilDestroy()
@Injectable()
export class NotificationService {
  constructor (
    private readonly store: Store<any>,
    private readonly webSocketService: WebSocketService
  ) {}

  public listenToBadgesNotifications (userAccountId: number): void {
    const onMessageReceived = (messagePayload: any): void => {
      switch (messagePayload.body) {
        case 'BADGES_UPDATED':
          this.store.dispatch(new LoadBadges())
          break
      }
    }

    this.webSocketService.startListeningToWebSocketQueue(
      `/user/badges${userAccountId}/queue/messages`,
      onMessageReceived
    )
  }

  public stopListeningToAllWebSocketQueues (): void {
    this.webSocketService.stopListeningToAllWebSocketQueues()
  }
}
