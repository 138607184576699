import { Resource } from '../bms-common/rest/resource.model';
import { getLink, hasLink } from '../bms-common/rest/resource.utils';

export const getReqUrl = (resource: Resource, linkRel: string) => {
  let reqUrl = '';

  if (hasLink(resource, linkRel)) {
    reqUrl = getLink(resource, linkRel).href;
  } else {
    console.warn(`The resource doesn't have ${linkRel} link relation.`);
  }

  return reqUrl;
};

export const getReqUrlWithoutQueryParameters = (
  resource: Resource,
  linkRel: string
) => {
  let reqUrl = getReqUrl(resource, linkRel);
  reqUrl = reqUrl.split('?')[0];
  reqUrl = reqUrl.endsWith('{') ? reqUrl.slice(0, -1) : reqUrl;
  return reqUrl;
};
