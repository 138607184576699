import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'staffnow-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent {
  @Input() public message: string = null;
  @Input() public color: 'blue' | 'green' = 'green';
  @Input() public size: 'small' | 'medium' = 'medium';
  @Input() public direction: 'vertical' | 'horizontal' = 'horizontal';

  @HostBinding('class.small') get isSmall(): boolean {
    return this.size === 'small';
  }
  @HostBinding('class.horizontal') get isHorizontal(): boolean {
    return this.direction === 'horizontal';
  }
  @HostBinding('class.blue') get isBlue(): boolean {
    return this.color === 'blue';
  }
}
