import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Conversation} from '@libs/mailbox/state/mailbox-state.model';

@Component({
  selector: 'staffnow-conversation-header',
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.scss']
})
export class ConversationHeaderComponent {
  @Input() public conversation: Conversation = null;
  @Output() public closeChat = new EventEmitter<void>();

  public partnerProfilePicError: boolean = false;

  get partnerFirstName(): string {
    return this.conversation?.firstName || 'Unnamed';
  }

  get partnerLastName(): string {
    return this.conversation?.lastName || 'User';
  }

  public handleClose() {
    this.closeChat.emit();
  }
}
