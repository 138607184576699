import { Component, Input } from '@angular/core';
import {SavePassword} from "capacitor-ios-autofill-save-password";
import {isPlatformIos, isPlatformWeb} from "@libs/shared/helpers/capacitor";
import {Store} from "@ngrx/store";
import { AuthenticationErrorType, AuthenticationService } from '@libs/auth/services/authentication.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { Router } from '@angular/router';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy } from '@ngneat/until-destroy';
import {EnvironmentState} from "@libs/shared/bms-common/environment/environment.model";
import {AuthenticateOptions, BiometricAuth} from "@aparajita/capacitor-biometric-auth";
import {SecureStorage} from "@aparajita/capacitor-secure-storage";
import {isEmpty} from "lodash-es";

@UntilDestroy()
@Component({
  selector: 'staffnow-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Input()
  public privacyPolicyUrl: string;

  @Input()
  public termsAndConditionsUrl: string;

  public wrongCredentials = false;
  public unverifiedEmail = false;

  private appUrl = '';

  constructor(
    private authenticationService: AuthenticationService,
    private toastMessageService: ToastMessageService,

  private router: Router,
  private store: Store<any>
  ) {
    this.store.pipe(getStaticEnvironment).subscribe((env: EnvironmentState) => {
      this.googleRecaptchaSiteKey = env.googleRecaptchaSiteKey;
      this.appUrl = env.brandConfig.appUrl;
    });

    if (!isPlatformWeb()) {
      BiometricAuth.checkBiometry().then(result => {
        if (result.isAvailable) {
          SecureStorage.getItem(this.appUrl).then(item => {
            const credentials = item.split(' ');
            if (!isEmpty(credentials) && credentials.length === 2) {
              const authenticateOptions: AuthenticateOptions = {
                reason: 'For easier login',
                iosFallbackTitle: 'Login',
                androidTitle: 'Login',
                allowDeviceCredential: true
              };
              BiometricAuth.authenticate(authenticateOptions).then(() => {
                this.email = credentials[0];
                this.password = credentials[1];
                this.login();
              });
            }
          });
        }
      });
    }
  }

  public loading: boolean = false;
  public email: string;
  public password: string;

  public googleRecaptchaSiteKey: string;

  public get canLogin(): boolean {
    return !!this.email && this.email.length > 0 &&
      !!this.password && this.password.length > 0
  }

  public login() {
    this.loading = true;
    this.authenticationService.login(
      this.email, this.password
    ).subscribe(async () => {
      if (!isPlatformWeb()) {
        SecureStorage.setItem(this.appUrl, `${this.email} ${this.password}`).then();
      }
      if (isPlatformIos()) {
        await SavePassword.promptDialog({
          username: this.email,
          password: this.password
        });
      }
    }, (error: AuthenticationErrorType) => {
      if (error === AuthenticationErrorType.USERNAME_OR_PASSWORD_INCORRECT) {
        this.wrongCredentials = true;
      } else if(error === AuthenticationErrorType.VERIFICATION_NEEDED) {
        this.unverifiedEmail = true;
      } else {
        this.toastMessageService.fail("Error trying to login.")
      }
      this.loading = false;
    });
  }

  public requestNewPassword() {
    this.router.navigate(['request-new-password']);
  }

  public resendVerificationEmail() {
    this.router.navigate(['resend-verification-email'], { queryParams: { email: this.email }});
  }
}
