<staffnow-contract-info
    *ngIf="isExperienceLetterAllowed"
    [label]="'GENERAL.EXPERIENCE_LETTER_REQUEST' | translate"
>
    <div class="wrapper">
        <span class="text">
            {{ contract.technicianWantsExperienceLetter | yesNo | translate }}
        </span>
        <borderless-button
            (onClick)="changeExperienceLetter()"
            [isDisabled]="contract.endDate != null"
            size="small"
            [text]="'GENERAL.CHANGE' | translate"
            textDecoration="underline"
        ></borderless-button>
    </div>
</staffnow-contract-info>
