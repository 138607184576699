import {Pipe, PipeTransform} from '@angular/core';
import {DATE_FORMAT_OPTIONS, TIME_FORMAT_OPTIONS} from "@libs/shared/constants/date-format.constants";
import {DateTime} from "luxon";

@Pipe({name: 'localDateWithTimeFormat'})
export class LocalDateWithTimeFormatPipe implements PipeTransform {
  /*
  * The aim of this pipe is to display any date-time as local date-time
  * */
  public transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    if (value instanceof Date) {
      console.error('[LocalDateWithTimeFormatPipe] Date object instead of string')
      return null;
    }
    return DateTime.fromISO(value).toLocaleString({...DATE_FORMAT_OPTIONS, ...TIME_FORMAT_OPTIONS});
  }

}
