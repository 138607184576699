import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {
  BadgesLoaded,
  FailedToLoadBadges,
  FailedToLoadNotifications,
  LoadBadges,
  NotificationListLoaded,
  NotificationsActions,
  UpdateNotificationsCount
} from './notifications.actions';
import {BADGES_LINK_REL, FEATURE_STATE_KEY, NOTIFICATION_LIST_LINK_REL} from './notifications-state.model';
import {MailboxActions, MessagesLoaded} from '@libs/mailbox/state/mailbox.actions';
import {NotificationTargets} from '../notification-targets.enum';
import {Resource} from '@libs/shared/bms-common/rest/resource.model';
import {ResourceFactory} from '@libs/shared/bms-common/rest/resource.factory';
import {getFilteredApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@Injectable()
export class NotificationsEffects {
  private apiRoot: Resource = null;
  private notificationsPage: number = 0;
  private notificationsListUrl: string = '';

  public loadBadges$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationsActions.LoadBadges),
      filter(() => !!this.apiRoot),
      switchMap(() =>
        this.resourceFactory
          .from(this.apiRoot)
          .get(BADGES_LINK_REL)
          .pipe(
            map((response: any) => new BadgesLoaded(response.badges)),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
              return of(new FailedToLoadBadges());
            })
          )
      )
    )
  );

  public loadMessages$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.MessagesLoaded),
      filter((action: MessagesLoaded) => !action.since && !action.before),
      map(() => new LoadBadges())
    )
  );

  public loadNotificationList$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationsActions.LoadNotificationList),
      switchMap((action: any) => {
        return this.resourceFactory
          .fromId(this.notificationsListUrl)
          .get({page: this.notificationsPage})
          .pipe(
            switchMap((response: any) => {
              return [
                new NotificationListLoaded(response),
                new UpdateNotificationsCount(
                  NotificationTargets.NotificationBell,
                  0
                )
              ];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
              return of(new FailedToLoadNotifications());
            })
          );
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private errorMessageService: ErrorMessageService,
    private resourceFactory: ResourceFactory
  ) {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      const notificationsBaseUrl = getUrl(apiRoot, NOTIFICATION_LIST_LINK_REL);
      this.apiRoot = apiRoot;
      this.notificationsListUrl = notificationsBaseUrl.split('?')[0] || '';
    });

    this.store
      .pipe(select(state => state[FEATURE_STATE_KEY].notificationsPage))
      .subscribe(page => {
        this.notificationsPage = page;
      });
  }
}
