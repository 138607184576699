<h3 class="py-2" (click)="onOpenArea()">
    <a
        class="avi-collapse-toggle"
        [ngClass]="{ collapsed: !documentArea.isExpanded }"
    >
        {{ documentArea.title | translate }}
        {{ hasMandatoryDocuments() ? '*' : '' }}
    </a>
</h3>
<div
    class="collapse"
    [ngClass]="{ show: documentArea.isExpanded }"
    role="tabpanel"
>
    <div class="collapse-body">
        <staffnow-file-upload
            *ngFor="let documentType of documentArea.documentEntities"
            [documentType]="documentType"
            [documents]="getDocumentsByContext(documentType.context)"
            [userProfile]="userProfile"
            [isRejected]="isDocumentRejected(documentType)"
            [isMissing]="isDocumentMissing(documentType)"
            [canEdit]="canEdit"
            [isAdmin]="isAdmin"
            [filesAddedForWarning]="filesAdded(documentType)"
            [filesRemovedForWarning]="filesRemoved(documentType)"
            (deleteFile)="removeFile($event)"
            (downloadFile)="downloadFile($event)"
            (successfulFileUpload)="onSuccessfulFileUpload($event)"
            (singleDocumentStatus)="handleDocumentStatusChange($event)"
            (onSetExpirationDate)="setExpirationDate($event, documentType)"
            (onSetPossession)="setPossession($event)"
        >
        </staffnow-file-upload>
    </div>
    <div class="collapse-footer" *ngIf="!isLastArea">
        <general-button
            (onClick)="onOpenNextArea()"
            backgroundStyle="outline"
            size="small"
            text="NEXT"
        ></general-button>
    </div>
</div>
