<h1 class="h1 title">
    {{ title | translate }}
</h1>
<div class="information-body">
    <div class="information-wrapper flex-grow-1">
        <ng-content select="[data]"></ng-content>
    </div>
    <div class="user-actions">
        <ng-content select="[actions]"></ng-content>
    </div>
</div>
<ng-content select="[footer]"></ng-content>
