<div class="buttons">
    <i (click)="close()" class="material-icons close">close</i>
</div>
<div class="header">
    <h1 class="h1">Schedule Out of the Office</h1>
</div>
<div class="content">
    <form autocomplete="off" novalidate>
        <div class="form-group">
            <label for="period">Out of the Office period*</label>
            <div id="period">
                <div class="value-wrapper">
                    <div class="date-picker-wrapper">
                        <label for="from-date">From: </label>
                        <single-date-and-time-picker
                            (change)="handleStartDateChange($event)"
                            [minimumDate]="minimumDate"
                            [value]="fromDate"
                            id="from-date"
                        ></single-date-and-time-picker>
                    </div>
                    <template
                        *ngIf="!fromDate"
                        [ngTemplateOutlet]="missingField">
                    </template>
                </div>
                <div class="value-wrapper">
                    <div class="date-picker-wrapper">
                        <label for="to-date">To:</label>
                        <single-date-and-time-picker
                            (change)="handleEndDateChange($event)"
                            [minimumDate]="minimumDate"
                            [value]="toDate"
                            id="to-date"
                        ></single-date-and-time-picker>
                    </div>
                    <template
                        *ngIf="!toDate"
                        [ngTemplateOutlet]="missingField">
                    </template>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="toggle-wrapper">
                <label for="turn-on-replies">
                    Turn on automatic replies
                </label>
                <staffnow-toggle
                    [(ngModel)]="isEnabled"
                    [ngModelOptions]="{ standalone: true }"
                    id="turn-on-replies"
                ></staffnow-toggle>
            </div>
        </div>
        <div class="form-group">
            <label for="automatic-message">
                Automatic reply message*
            </label>
            <div class="value-wrapper">
                <staffnow-textarea
                    [(ngModel)]="automaticMessage"
                    [ngModelOptions]="{ standalone: true }"
                    id="automatic-message"
                    placeholder="Enter your automatic reply message"
                    textAreaMaxLength="960"
                ></staffnow-textarea>
                <template
                    *ngIf="isMissing(automaticMessage)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
    </form>
</div>
<div class="actions">
    <general-button (onClick)="close()" text="Cancel" color="secondary"></general-button>
    <general-button (onClick)="send()" text="Confirm"></general-button>
</div>
<ng-template #missingField>
    <div class="validation-error">This field is required!</div>
</ng-template>
