import { Pipe, PipeTransform } from '@angular/core';
import { AmeTitle } from '@libs/shared/models/ame-title.model';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'ameTitleName'
})
export class AmeTitleNamePipe implements PipeTransform {
  transform(title: AmeTitle): string {
    if (isNil(title.license)) {
      return title.type;
    }
    return title.type + ' ' + title.license;
  }
}
