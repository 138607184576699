import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  CERTIFICATE_CONTEXTS,
  DocumentArea,
  DocumentSummary,
  RELATED_CONTEXT,
  StaffnowDocument
} from '../../../model/documents.model';
import { cloneDeep, isEmpty, isNil, sortBy } from 'lodash-es';
import { Store } from '@ngrx/store';
import {
  selectDocumentAreas,
  selectIsDownloadingAllDocuments,
  selectIsLoadingDocumentAreas,
  selectUserProfile
} from '../../../state/user-profile.selectors';
import {
  GetDocumentAreas,
  InitiateAllDocumentsDownload,
  ResetDocumentAreas
} from '../../../state/user-profile.actions';
import { map, tap } from 'rxjs/operators';
import { DocumentsService } from '../../../services/documents.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { role, RoleWrapper, UserRoles } from '@libs/shared/models/roles.enum';
import { isDateInThePastOrToday, subtractDaysFromDate } from '@libs/shared/helpers/date-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';

@UntilDestroy()
@Component({
  selector: 'staffnow-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  private readonly UPLOAD_HELP = 'Upload help';

  @Input() public technicianPendingChanges: Array<any> = [];

  public documents: StaffnowDocument[] = [];
  public documentAreas: Array<DocumentArea> = [];
  public documentSummaries: Array<DocumentSummary> = [];
  public isDownloadingAllDocuments: boolean = false;
  public role: RoleWrapper = null;
  public isExpired: boolean = false;
  public isAboutToExpire: boolean = false;
  public isFirstLoad: boolean = true;
  public isLoadingDocumentAreas$: Observable<boolean>;
  private downLoadAllDocumentsUrl: string = null;
  private isOwnTechnician: boolean = false;
  private openedAreaIndex$ = new BehaviorSubject<number>(0);

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(private store: Store<any>, private documentsService: DocumentsService) {}

  get areasIsEmpty(): boolean {
    return isEmpty(this.documentAreas);
  }

  get areDocumentsEmpty(): boolean {
    return isEmpty(this.documents);
  }

  public setRenewCertificate(): void {
    if (this.isUserTechnician) {
      this.validateDocumentExpiration();
    }
  }

  get isUserTechnician(): boolean {
    return (
      this.role?.isTechnician() || (this.role?.isAgency() && this.isOwnTechnician) || this.role?.isAdminOrModerator()
    );
  }

  get hasCertificatesArea(): boolean {
    return this.documentAreas.some(area =>
      area.documentEntities.some(documentType => CERTIFICATE_CONTEXTS.some(context => context === documentType.context))
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(ResetDocumentAreas());
  }

  ngOnInit() {
    this.isLoadingDocumentAreas$ = this.store.select(selectIsLoadingDocumentAreas);
    this.store
      .pipe(
        untilDestroyed(this),
        getLoggedInUserRole,
        map((r: UserRoles) => {
          this.role = role(r);
        })
      )
      .subscribe();

    this.store
      .select(selectUserProfile)
      .pipe(
        untilDestroyed(this),
        tap((userProfile: any) => {
          this.isOwnTechnician = userProfile.isOwnTechnician;
          this.downLoadAllDocumentsUrl = getUrl(userProfile, UserProfileLinkRel.DownloadAllDocuments);
          if (this.isFirstLoad) {
            this.store.dispatch(
              GetDocumentAreas({
                technicianUuid: userProfile._embedded.profile.userUuid
              })
            );
          }
          this.documents = this.documentsService.fillRejectedAndPending(cloneDeep(userProfile._embedded.documents));
          this.setRenewCertificate();
        })
      )
      .subscribe();

    this.store
      .select(selectDocumentAreas)
      .pipe(
        untilDestroyed(this),
        tap(documentAreas => {
          this.isFirstLoad = false;
          this.documentAreas = cloneDeep(documentAreas);
          this.buildDocumentSummaries();
          this.setRenewCertificate();
        })
      )
      .subscribe();

    this.store
      .select(selectIsDownloadingAllDocuments)
      .pipe(
        untilDestroyed(this),
        tap(isDownloadingAllDocuments => (this.isDownloadingAllDocuments = isDownloadingAllDocuments))
      )
      .subscribe();

    this.openedAreaIndex$.pipe(untilDestroyed(this)).subscribe(openedIndex => {
      this.documentAreas.forEach((area, index) => {
        if (index === openedIndex) {
          area.isExpanded = !area.isExpanded;
        } else {
          area.isExpanded = false;
        }
      });
    });
  }

  private buildDocumentSummaries() {
    this.documentSummaries = this.documentAreas
      .filter(it => it.title !== this.UPLOAD_HELP)
      .map(it => ({
        title: it.title,
        isExpanded: it.isExpanded,
        documentTypes: it.documentEntities
      }));
  }

  public openDocumentArea(areaIndex: number): void {
    this.openedAreaIndex$.next(areaIndex);
  }

  public downLoadAllDocuments(): void {
    this.store.dispatch(
      InitiateAllDocumentsDownload({
        documentDownloadUrl: this.downLoadAllDocumentsUrl
      })
    );
  }

  private validateDocumentExpiration(): void {
    const groupedCertificates = this.groupCertificatesThatCanExpireByContext();
    const expires: boolean[] = [];
    const warnings: boolean[] = [];
    groupedCertificates.forEach(listOfCertificates => {
      if (listOfCertificates.length > 0) {
        const [isExpired, isAboutToExpire] = this.checkCertificateExpiredOrAboutToExpire(listOfCertificates);
        expires.push(isExpired);
        warnings.push(isAboutToExpire);
      }
    });
    this.isExpired = expires.includes(true);
    this.isAboutToExpire = warnings.includes(true);
  }

  private checkCertificateExpiredOrAboutToExpire(certificates: StaffnowDocument[]): boolean[] {
    const orderedExpirationDates = sortBy(
      certificates.map(certificate => certificate.expirationDate),
      dateString => new Date(dateString)
    );
    const lastCertificateToExpire = orderedExpirationDates.pop();
    return [this.documentIsExpired(lastCertificateToExpire), this.documentIsAboutToExpire(lastCertificateToExpire)];
  }

  private groupCertificatesThatCanExpireByContext(): StaffnowDocument[][] {
    const groupedCertificates = [];
    RELATED_CONTEXT.forEach(listOfContexts => {
      const certificateGroup = [];
      listOfContexts.forEach(context =>
        certificateGroup.push(
          ...this.documents.filter(document => document.context === context && !isNil(document.expirationDate))
        )
      );
      groupedCertificates.push(certificateGroup);
    });
    return groupedCertificates;
  }

  public documentIsAboutToExpire(expiration: string): boolean {
    return this.atLeastOneMonthLeftToDate(expiration);
  }

  public documentIsExpired(expiration: string): boolean {
    return isDateInThePastOrToday(expiration);
  }

  private atLeastOneMonthLeftToDate(expiration: string): boolean {
    const oneMonthBeforeExpiration = subtractDaysFromDate(expiration, 31);
    return isDateInThePastOrToday(oneMonthBeforeExpiration);
  }

  public openTrainingWebsite(): void {
    window.open('https://oat.aero/all-courses/', '_blank');
  }

  public onSuccessfulFileUpload(event) {
    if (event) {
      this.documentAreas
        .filter(it => it.id === event.documentAreaId)[0]
        .documentEntities.filter(it => it.context === event.context)[0].hasPossession = true;
      if (event.context === 'LICENSE') {
        this.documentAreas.forEach(it => {
          it.documentEntities.filter(it => it.context === 'DIPLOMA').forEach(doc => (doc.hasPossession = true));
        });
      }
    }
  }
}
