export enum UserRoles {
  ROLE_TECHNICIAN = 'ROLE_TECHNICIAN',
  ROLE_AGENCY = 'ROLE_AGENCY',
  ROLE_ANONYMOUS = 'ROLE_ANONYMOUS',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MODERATOR = 'ROLE_MODERATOR',
  ROLE_MRO_PRODUCTION = 'ROLE_MRO_PRODUCTION',
  ROLE_MRO_QUALITY = 'ROLE_MRO_QUALITY',
  ROLE_MRO_CENTRAL_QUALITY = 'ROLE_MRO_CENTRAL_QUALITY',
  ROLE_MRO_HUMAN_RESOURCES = 'ROLE_MRO_HUMAN_RESOURCES',
  ROLE_AGENCY_TECHNICIAN = 'ROLE_AGENCY_TECHNICIAN'
}

export class UserRolesUtils {
  private static roleCheck = roles => userRole => roles.includes(userRole);

  public static isAdminOrModerator = UserRolesUtils.roleCheck([
    UserRoles.ROLE_ADMIN,
    UserRoles.ROLE_MODERATOR
  ]);

  public static isMro = UserRolesUtils.roleCheck([
    UserRoles.ROLE_MRO_PRODUCTION,
    UserRoles.ROLE_MRO_QUALITY,
    UserRoles.ROLE_MRO_CENTRAL_QUALITY,
    UserRoles.ROLE_MRO_HUMAN_RESOURCES
  ]);

  public static isMroHumanResources = UserRolesUtils.roleCheck([UserRoles.ROLE_MRO_HUMAN_RESOURCES]);

  public static isMroProduction = UserRolesUtils.roleCheck([UserRoles.ROLE_MRO_PRODUCTION]);

  public static isMroQuality = UserRolesUtils.roleCheck([UserRoles.ROLE_MRO_QUALITY]);

  public static isModerator = UserRolesUtils.roleCheck([UserRoles.ROLE_MODERATOR]);

  public static isAdmin = UserRolesUtils.roleCheck([UserRoles.ROLE_ADMIN]);

  public static isTechnician = UserRolesUtils.roleCheck([UserRoles.ROLE_TECHNICIAN]);

  public static isAgency = UserRolesUtils.roleCheck([UserRoles.ROLE_AGENCY]);
}

export function toReadable(userRole: UserRoles): string {
  switch (userRole) {
    case UserRoles.ROLE_TECHNICIAN:
      return 'Technician';
    case UserRoles.ROLE_AGENCY:
      return 'Agency';
    case UserRoles.ROLE_ANONYMOUS:
      return 'Anonymous';
    case UserRoles.ROLE_ADMIN:
      return 'Admin';
    case UserRoles.ROLE_MODERATOR:
      return 'Moderator';
    case UserRoles.ROLE_MRO_PRODUCTION:
      return 'Production';
    case UserRoles.ROLE_MRO_QUALITY:
      return 'Quality';
    case UserRoles.ROLE_MRO_CENTRAL_QUALITY:
      return 'Central Quality';
    case UserRoles.ROLE_MRO_HUMAN_RESOURCES:
      return 'Human Resources';
  }
}

export interface RoleWrapper {
  isMro(): boolean;

  isAgency(): boolean;

  isTechnician(): boolean;

  isHumanResources(): boolean;

  isCentralQuality(): boolean;

  isHumanResourcesOrCentralQuality(): boolean;

  isAdmin(): boolean;

  isModerator(): boolean;

  isAdminOrModerator(): boolean;

  isMroAnyQuality(): boolean;

  isMroQuality(): boolean;

  isMroProduction(): boolean;

  getRole(): UserRoles | string;
}

export function role(role: UserRoles | string): RoleWrapper {
  return {
    getRole: (): UserRoles | string => role,
    isMro: (): boolean => UserRolesUtils.isMro(role),
    isAgency: (): boolean => UserRoles.ROLE_AGENCY === role,
    isTechnician: (): boolean => UserRoles.ROLE_TECHNICIAN === role,
    isHumanResources: (): boolean => UserRoles.ROLE_MRO_HUMAN_RESOURCES === role,
    isCentralQuality: (): boolean => UserRoles.ROLE_MRO_CENTRAL_QUALITY === role,
    isAdmin: (): boolean => UserRoles.ROLE_ADMIN === role,
    isModerator: (): boolean => UserRoles.ROLE_MODERATOR === role,
    isMroQuality: (): boolean => UserRoles.ROLE_MRO_QUALITY === role,
    isMroProduction: (): boolean => UserRoles.ROLE_MRO_PRODUCTION === role,
    isHumanResourcesOrCentralQuality: function (): boolean {
      return this.isHumanResources() || this.isCentralQuality();
    },
    isAdminOrModerator: function (): boolean {
      return this.isAdmin() || this.isModerator();
    },
    isMroAnyQuality: function (): boolean {
      return this.isCentralQuality() || this.isMroQuality();
    }
  };
}
