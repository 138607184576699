import { ActionReducerMap, createReducer, on } from '@ngrx/store';

import { AccountState, initialState } from './user-profile-state.model';
import { accountFormReducer } from '../components/views/account/account-form.reducer';
import {
  BlocklistLoaded,
  ErrorGetDocumentAreas,
  FailedToLoadUser,
  FinishAllDocumentsDownload,
  GetDocumentAreas,
  InitiateAllDocumentsDownload,
  InitiateUpdateRequest,
  ResetDocumentAreas,
  ResetLoading,
  ResetUserProfileState,
  SetProfileUrl,
  SuccessGetDocumentAreas,
  UpdateBackofficeNotes,
  UpdateTechnicianSkills,
  UpdateNewsletterSubscription,
  UpdateUserProfile,
  UserAvailabilityLoaded,
  UserProfileUpdated,
  FileSuccessfullyDeleted,
  DocumentPossessionSuccessfullySet,
  TechnicianPerformanceFeedbacksLoaded
} from './user-profile.actions';
import { DocumentArea, DocumentType } from '../model/documents.model';

export function getReducers(): ActionReducerMap<AccountState> {
  return {
    accountForm: accountFormReducer,
    userProfile: userProfileReducer,
    availability: availabilityReducer,
    profileUrl: profileUrlReducer,
    profileLoading: profileLoadingReducer,
    availabilityStart: availabilityStartReducer,
    blacklist: blacklistReducer,
    documentAreas: documentAreasReducer,
    isDownloadingAllDocuments: isDownloadingAllDocumentsReducer,
    isLoadingDocumentAreas: isLoadingDocumentAreasReducer,
    feedbacks: technicianPerformanceFeedbackReducer
  };
}

const profileUrlReducer = createReducer(
  initialState.profileUrl,
  on(SetProfileUrl, (state, { profileUrl }) => profileUrl)
);

const userProfileReducer = createReducer(
  initialState.userProfile,
  on(UserProfileUpdated, (state, { payload }) => ({
    ...payload
  })),
  on(UpdateNewsletterSubscription, (state, { isSubscribed }) => ({
    ...state,
    isSubscribedToNewsletter: isSubscribed
  })),
  on(ResetUserProfileState, () => ({
    ...initialState.userProfile
  }))
);

const availabilityReducer = createReducer(
  initialState.availability,
  on(UserAvailabilityLoaded, (state, { payload }) => ({
    ...state,
    ...payload
  })),
  on(ResetUserProfileState, () => ({ ...initialState.availability }))
);

const profileLoadingReducer = createReducer(
  initialState.profileLoading,
  on(UserProfileUpdated, ResetLoading, FailedToLoadUser, () => false),
  on(
    InitiateUpdateRequest,
    UpdateUserProfile,
    UpdateBackofficeNotes,
    UpdateTechnicianSkills,
    () => true
  )
);

const availabilityStartReducer = createReducer(
  initialState.availabilityStart,
  on(UserAvailabilityLoaded, (_, { payload }) => payload.months[0]),
  on(ResetUserProfileState, () => initialState.availabilityStart)
);

const blacklistReducer = createReducer(
  initialState.blacklist,
  on(BlocklistLoaded, (state, { payload }) => ({
    selectedFacilities: payload.selectedFacilities,
    availableFacilities: payload.availableFacilities
  }))
);

const documentAreasReducer = createReducer(
  initialState.documentAreas,
  on(SuccessGetDocumentAreas, (state, { documentAreas }) => documentAreas),
  on(ResetDocumentAreas, state => initialState.documentAreas),
  on(FileSuccessfullyDeleted, (state, action) => {
    return updateHasPossessionOnFileRemoval(state, action);
  }),
  on(DocumentPossessionSuccessfullySet, (state, action) => {
    return updateHasPossessionOnUpdate(state, action);
  })
);

const updateHasPossessionOnFileRemoval = (
  documentAreas: DocumentArea[],
  action: {context: string, documentAreaId: number, documentContextsEmptyAfterRemoval: string[]}
): DocumentArea[] => {
  if(action.documentContextsEmptyAfterRemoval.length > 0) {
    return documentAreas.map(documentArea => {
      return {...documentArea, isExpanded: (documentArea.id === action.documentAreaId), documentEntities: documentArea.documentEntities.map(documentEntity => {
        return (action.documentContextsEmptyAfterRemoval.some(it => documentEntity.context === it)) ? { ...documentEntity, hasPossession: false } : documentEntity
      })}
    });
  }
  return documentAreas;
}

const updateHasPossessionOnUpdate = (
  documentAreas: DocumentArea[],
  action: {documentAreaId: number, document: DocumentType}
): DocumentArea[] => {
  return documentAreas.map(documentArea => {
    return {...documentArea, isExpanded: (documentArea.id === action.documentAreaId), documentEntities: documentArea.documentEntities.map(documentEntity => {
      return (documentEntity.context === action.document.context) ? { ...documentEntity, hasPossession: !documentEntity.hasPossession } : documentEntity
    })}
  });
}

const isLoadingDocumentAreasReducer = createReducer(
  initialState.isLoadingDocumentAreas,
  on(GetDocumentAreas, () => true),
  on(SuccessGetDocumentAreas, ErrorGetDocumentAreas, () => false)
);

const isDownloadingAllDocumentsReducer = createReducer(
  initialState.isDownloadingAllDocuments,
  on(FinishAllDocumentsDownload, () => false),
  on(InitiateAllDocumentsDownload, () => true)
);

const technicianPerformanceFeedbackReducer = createReducer(
  initialState.feedbacks,
  on(TechnicianPerformanceFeedbacksLoaded, (state, { feedbacks }) => feedbacks),
);
