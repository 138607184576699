import {
  BrandName,
  EnvironmentParameters,
  staffNowCustomerSupportEmail,
  staffNowInfoEmail,
  staffNowPrivacyPolicyUrl,
  staffNowSecondCustomerSupportEmail,
  staffNowTermsAndConditionsUrl
} from '@libs/shared/bms-common/environment/environment.model';

export const environment: EnvironmentParameters = {
  googleTrackingId: 'G-5SNQ0TBERN',
  production: false,
  environmentEndpoint: '/api/environment',
  usaApiUrl: 'https://api.test.app.elaunchnow.com',
  europeApiUrl: 'https://api.test.app.staffnow.aero',
  auth: {
    initialUrl: 'https://backoffice.test.staffnow.aero',
    jwtIssuer: 'https://keycloak.test.app.staffnow.aero/realms/staffnow-rc',
    keycloakLoginUrl:
      'https://keycloak.test.app.staffnow.aero/realms/staffnow-rc/protocol/openid-connect/token',
    clientId: 'staffnow-rc-login'
  },
  brandConfig: {
    brandName: BrandName.StaffNow,
    privacyPolicy: staffNowPrivacyPolicyUrl(),
    termsAndConditions: staffNowTermsAndConditionsUrl(),
    customerSupportEmail: staffNowCustomerSupportEmail(),
    secondCustomerSupportEmail: staffNowSecondCustomerSupportEmail(),
    infoEmail: staffNowInfoEmail(),
    appUrl: 'https://test.staffnow.aero/',
    partnerAppUrl: 'https://test.app.elaunchnow.com/'
  },
  euDashboardsEndpoint:
    'https://avc0fkmzk5.execute-api.eu-west-1.amazonaws.com/default/reports/',
  usaDashboardsEndpoint:
    'https://rtvdkospod.execute-api.us-east-1.amazonaws.com/default/reports/',
  legalNotice: 'https://staffnow.aero/legal-notice/',
  privacyPolicyUrl: 'https://staffnow.aero/wp-content/uploads/Privacy-Policy-EN-ES.pdf',
  termsAndConditionsUrl: 'https://staffnow.aero/wp-content/uploads/Terms-and-Conditions-EN-ES.pdf',
  techniciansContactUrl: 'https://staffnow.aero/#contact-for-technicians',
  companiesContactUrl: 'https://staffnow.aero/contact'
};
