export enum JobOfferLinkRel {
  Facility = 'facility',
  FacilityImage = 'getPicture',
  FacilityLogo = 'getLogo',
  ApplyOffer = 'apply',
  GetAgencies = 'getAgencyFacilities',
  Facilities = 'facilities',
  Share = 'share',
  AddTechnicians = 'addTechnicians',
  Self = 'self',
  GetTechnicians = 'getTechnicians',
  Technicians = 'results'
}
