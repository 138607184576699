<i class="material-icons close-icon" (click)="close()"> clear </i>
<div class="header">
    <h1 class="heading">{{ title | translate }}</h1>
    <div class="text-muted sub-heading">
        <p class="reminder" *ngFor="let reminder of reminders">
            <strong *ngIf="reminder.label">
                {{ reminder.label | translate }}
            </strong>
            {{ reminder.text | translate }}
        </p>
    </div>
</div>
<div class="content">
    <div class="number-picker-wrapper">
        <strong>{{ placeholder | translate }}</strong>
        <input [(ngModel)]="value" class="form-control" type="number" />
    </div>
    <general-button
        (onClick)="submit()"
        [isDisabled]="shouldDisable()"
        text="Submit"
        class="button-custom"
    ></general-button>
</div>
