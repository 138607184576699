<staffnow-go-back-button
    [currentPath]="[
        'AGENCY.DETAILS.TITLE_JO_APPLICATIONS' | translate,
        'MRO.PACKAGE_OFFER.JO_DETAILS' | translate
    ]"
></staffnow-go-back-button>
<staff-loader *ngIf="isLoading" customClass="splash-screen-loader"></staff-loader>
<staffnow-offer-details
    *ngIf="!isLoading"
    [offer]="offer"
    [title]="'MRO.PACKAGE_OFFER.FIXED_PRICE_JO' | translate:{offerDescription}"
    [displayOfferLocation]="true"
>
    <ng-container data>
        <staffnow-contract-info [label]="'GENERAL.PRIORITY' | translate">
            <staffnow-offer-priority-info
                [displayLabel]="false"
                [priority]="offer?.priority"
            ></staffnow-offer-priority-info>
        </staffnow-contract-info>
    </ng-container>

    <ng-container actions>
        <general-button
            (onClick)="handleDeleteOffer()"
            *ngIf="canDeleteOffer"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.DELETE_JO' | translate"
            tooltip="{{'SYSTEM.INFO.DELETE_JO' | translate}}"
            color="danger"
        ></general-button>
        <general-button
            (onClick)="closeOffer()"
            *ngIf="canCloseOffer"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CLOSE' | translate"
            tooltip="{{'SYSTEM.INFO.CLOSE_PACKAGE_JO_TOOLTIP' | translate}}"
            color="danger"
        ></general-button>
        <div class="contract-action" *ngIf="canReopenOffer">
            <general-button
                (onClick)="reopenOffer()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.REOPEN' | translate"
                tooltip="{{'SYSTEM.INFO.REOPEN_PACKAGE_JO_TOOLTIP' | translate}}"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEnded"
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
        </div>
        <general-button
            (onClick)="approveOffer()"
            *ngIf="canApprove"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.APPROVE' | translate"
        ></general-button>
        <general-button
            (onClick)="openEditOfferForm()"
            *ngIf="canEditOffer"
            backgroundStyle="outline"
            size="small"
            [text]="'AGENCY.DETAILS.EDIT_JOB_OPENING' | translate"
            tooltip="{{'SYSTEM.INFO.EDIT_JO' | translate}}"
        ></general-button>
        <div class="contract-action" *ngIf="canInviteAgencies">
            <general-button
                (onClick)="onInviteAgency()"
                [isDisabled]="isOfferEnded"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.INVITE_AGENCIES' | translate"
                tooltip="{{'SYSTEM.INFO.INVITE_AGENCIES_TOOLTIP' | translate}}"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                *ngIf="isOfferEnded"
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
        </div>
    </ng-container>
    <ng-container footer>
        <staffnow-manage-package-offer-files
            [packageOffer]="packageOffer"
            (onActionSuccessful)="reloadOffer()"
        >
        </staffnow-manage-package-offer-files>
    </ng-container>
</staffnow-offer-details>
<staffnow-collapsible-package-contracts-container
    *ngIf="!isLoading"
    [contracts]="contracts"
>
    <ng-container no-data>
        <h2 class="h2">
            {{ 'AGENCY.DETAILS.NO_APPLICANTS' | translate }}
        </h2>
    </ng-container>
</staffnow-collapsible-package-contracts-container>
