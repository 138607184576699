import { Injectable } from '@angular/core'
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { select, Store } from '@ngrx/store'
import { OpenSockQueue, SockQueueClosed } from './state/websocket.actions'
import { selectQueueList } from './state/websocket.selectors'
import { isNil } from 'lodash-es'

@UntilDestroy()
@Injectable()
export class WebSocketService {
  private queueList: any[] = []

  constructor (private readonly store: Store<any>) {
    this.store
      .pipe(select(selectQueueList), untilDestroyed(this))
      .subscribe(queueList => {
        this.queueList = queueList
      })
  }

  public startListeningToWebSocketQueue (
    queue: string,
    onMessageReceived: (messagePayload) => any
  ): void {
    if (!this.areWeAlreadyListeningToThisQueue(queue)) {
      this.store
        .pipe(getFilteredApiRoot, untilDestroyed(this))
        .subscribe(apiRoot => {
          const socketUrls = apiRoot.webSocketUrls.split(',')
          const webSocketSecret = apiRoot.webSocketSecret
          socketUrls.forEach(url => {
            this.store.dispatch(
              OpenSockQueue({
                webSocketSecret,
                socketUrl: url,
                queue,
                onMessageReceived
              })
            )
          })
        })
    }
  }

  public stopListeningToWebSocketQueue (queue: string): void {
    const selectedQueueListItem = this.queueList.filter(
      it => it.uri === queue
    )[0]
    if (!isNil(selectedQueueListItem)) {
      selectedQueueListItem.onSockClose()
      this.store.dispatch(SockQueueClosed({ queue }))
    }
  }

  public stopListeningToAllWebSocketQueues (): void {
    this.queueList.forEach(it => {
      const queue = it.uri
      it.onSockClose()
      this.store.dispatch(SockQueueClosed({ queue }))
    })
  }

  private areWeAlreadyListeningToThisQueue (queue: string): boolean {
    return this.queueList.some(it => it.uri === queue)
  }
}
