import {Injectable, OnDestroy} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, from, Subscription} from 'rxjs';
import {Header, HeaderProviderService} from "@libs/auth/services/header-provider.service";
import {Router} from "@angular/router";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor, OnDestroy {
  private appVersionHeader: Header;
  private appClassHeader: Header;

  private subscription: Subscription = new Subscription();

  constructor(
    private headerProviderService: HeaderProviderService,
    private router: Router
  ) {
    this.subscription.add(this.headerProviderService.appVersionObservable.subscribe((newAppVersion: Header) => this.appVersionHeader = newAppVersion));
    this.subscription.add(this.headerProviderService.appClassObservable.subscribe((newAppClass: Header) => this.appClassHeader = newAppClass));
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const headers = {};

    const accessTokenHeader: Header = await this.headerProviderService.getBearerAccessToken();
    if (
      accessTokenHeader &&
      !req.url.match(/\/api\/environment.*$/) &&
      !req.url.match(/\/assets\/i18n\/.*$/) &&
      !req.url.match(/\/api\/platform\/registration.*$/) &&
      !req.url.match(/\/api\/public.*$/)
    ) {
      headers[accessTokenHeader.name] = accessTokenHeader.value;
    }

    if (!req.url.match(/keycloak|\/realms\/|openid/) && !!this.appVersionHeader && !!this.appClassHeader) {
      headers[this.appVersionHeader.name] = this.appVersionHeader.value;
      headers[this.appClassHeader.name] = this.appClassHeader.value;
    }

    if (Object.keys(headers).length > 0) {
      req = req.clone({
        setHeaders: headers
      });
    }

    return next.handle(req).toPromise().catch(error => {
      if (error.status === 418) {
        this.router.navigate(['update-app']);
      }
      // IMPORTANT: we must rethrow the exception in order to NOT hide them in other places
      throw error;
    });
  }
}
