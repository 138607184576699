import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import {
  getEmbeddedResource,
  getUrl,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';

@Component({
  selector: 'technician-card',
  templateUrl: './technician-card.component.html',
  styleUrls: ['./technician-card.component.scss']
})
export class TechnicianCardComponent implements OnChanges {
  @Input() public technician: TechnicianProfileModel = null;
  @Input() public isAbleToSeeTechnicianFullProfile: boolean = true;
  @Input() public isOwnTechnicianListing: boolean = false;
  @Output()
  public openProfileModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() public toggleSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public updateMroFavorites: EventEmitter<any> = new EventEmitter<any>();

  public technicianProfile: any = null;
  public technicianPicture: string = null;

  ngOnChanges() {
    if (this.technician) {
      this.technicianProfile = getEmbeddedResource(
        this.technician,
        UserProfileLinkRel.Profile
      );
      if (hasLink(this.technician, UserProfileLinkRel.GetProfilePicture)) {
        this.technicianPicture = getUrl(
          this.technician,
          UserProfileLinkRel.GetProfilePicture
        );
      }
    }
  }

  openModalProfileView(): void {
    this.openProfileModal.emit({ ...this.technician });
  }

  toggleSelectedTechnician(event) {
    this.toggleSelected.emit();
  }

  @HostListener('click', ['$event'])
  handleOnclick(event) {
    event.stopPropagation();
    this.onClickEvent.emit({ ...this.technician });
  }

  public handleUpdateMroFavorites(payload): void {
    this.updateMroFavorites.emit(payload);
  }

  get canEditFavoriteTechniciansList(): boolean {
    return (
      hasLink(this.technician, UserProfileLinkRel.MroAddToFavorites) ||
      hasLink(this.technician, UserProfileLinkRel.MroRemoveFromFavorites)
    );
  }
}
