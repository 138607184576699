<div class="buttons">
    <i (click)="close()" class="material-icons close">close</i>
</div>
<div class="header">
    <h1 class="h1">Performance Feedback Request</h1>
</div>
<div class="content">
    <form autocomplete="off" novalidate>
        <div class="form-group">
            <label for="recipient-names">Recipient aviation company</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="recipientAviationCompany"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="recipient-names"
                    type="text"
                />
                <template
                    *ngIf="isMissing(recipientAviationCompany)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="recipient-names">Recipient name</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="recipientName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="recipient-names"
                    type="text"
                />
                <template
                    *ngIf="isMissing(recipientName)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="recipient-email">Recipient email address</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="recipientEmail"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="recipient-email"
                    type="text"
                />
                <template
                    *ngIf="isMissing(recipientEmail)"
                    [ngTemplateOutlet]="missingField">
                </template>
                <div *ngIf="!emailFormatIsCorrect(recipientEmail)" class="validation-error">
                    The email address is not valid!
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="technician-name">Person for whom feedback is requested</label>
            <div class="value-wrapper">
                <input
                    [value]="technicianName"
                    class="form-control"
                    disabled="disabled"
                    id="technician-name"
                    type="text"
                />
                <template
                    *ngIf="isMissing(technicianName)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="request-reason">Reason for request</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="requestReason"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="request-reason"
                    type="text"
                />
                <template
                    *ngIf="isMissing(requestReason)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="requester-name-and-position">Requester name and position</label>
            <div class="value-wrapper">
                <input
                    [value]="requesterNameAndPosition"
                    class="form-control"
                    disabled="disabled"
                    id="requester-name-and-position"
                    type="text"
                />
                <template
                    *ngIf="isMissing(requesterName) || isMissing(requesterPosition)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
    </form>
</div>
<div class="actions">
    <general-button (onClick)="close()" color="secondary" text="Cancel"></general-button>
    <general-button (onClick)="send()" [isDisabled]="isDisabled()" text="Confirm"></general-button>
</div>
<ng-template #missingField>
    <div class="validation-error">This field is required!</div>
</ng-template>
