import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  getEmbeddedResource,
  getUrl,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { isEmpty } from 'lodash-es';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';

@Component({
  selector: 'staffnow-promotional-agency',
  templateUrl: './promotional-agency-card.component.html',
  styleUrls: ['./promotional-agency-card.component.scss']
})
export class PromotionalAgencyCardComponent {
  @Input() public agency: any = null;
  @Input() public isOpened: boolean = false;
  @Input() public isSelected: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Input() public isDownloading: boolean = false;
  @Output() public onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onOpenDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDownloadClick: EventEmitter<any> = new EventEmitter<any>();

  private readonly downloadRel: string = 'download';
  private readonly picturesRel: string = 'pictures';

  public handleAgencyClick(): void {
    this.onClick.emit();
  }

  public handleOpenDetails(): void {
    this.onOpenDetails.emit();
  }

  public downLoadDocuments(): void {
    this.onDownloadClick.emit(getUrl(this.agency, this.downloadRel));
  }

  public hasServicesDescription(): boolean {
    return this.agency.services;
  }

  public canDownloadPromotionalMaterial(): boolean {
    return hasLink(this.agency, this.downloadRel);
  }

  public hasPictures(): boolean {
    return !isEmpty(getEmbeddedResource(this.agency, this.picturesRel));
  }

  public getPicturesUrls(): Array<string> {
    return this.getPictures().map(picture => getUrl(picture, 'self'));
  }

  private getPictures(): Array<Resource> {
    return getEmbeddedResource(this.agency, this.picturesRel);
  }
}
