import { Action } from '@ngrx/store';

import { ToastTypes } from '../lib/toast-types.enum';

export enum ToastActionTypes {
  NewToast = '[Toasts] New toast message',
  DeleteToast = '[Toasts] Delete toast message'
}

export class NewToast implements Action {
  public readonly type = ToastActionTypes.NewToast;
  public readonly text: string;
  public readonly toastType: ToastTypes;
  public readonly dismissTimeout?: number | null;

  constructor(
    text: string,
    toastType: ToastTypes,
    dismissTimeout: number | null
  ) {
    this.text = text;
    this.toastType = toastType;
    this.dismissTimeout = dismissTimeout;
  }
}

export class DeleteToast implements Action {
  public readonly type = ToastActionTypes.DeleteToast;
  public readonly messageId: string;

  constructor(messageId: string) {
    this.messageId = messageId;
  }
}

export type ToastAction = NewToast | DeleteToast;

export const authActions = {
  NewToast,
  DeleteToast
};
