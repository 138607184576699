import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {UserProfileType} from "@libs/shared/models/user-profile.type";
import {ActionsSubject, Store} from "@ngrx/store";
import {getEmbeddedResource, getUrl} from "@libs/shared/bms-common/rest/resource.utils";
import {UserProfileLinkRel} from "@libs/shared/linkrels/user-profile.linkrel";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthorizedUserProfileModel} from "@libs/shared/models/authorized-user-profile.model";
import {getLoggedInUserProfile} from "@libs/shared/bms-common/api-root/api-root.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import { SendPerformanceFeedbackRequest, SendPerformanceFeedbackRequestFail, SendPerformanceFeedbackRequestSuccess } from "@libs/user-profile/components/technician-request/performance-feedback-request/performance-feedback-request.actions";
import { PerformanceFeedbackRequestComponent } from "@libs/user-profile/components/technician-request/performance-feedback-request/performance-feedback-request.component";
import { PerformanceFeedbackRequestPayload } from "@libs/user-profile/components/technician-request/performance-feedback-request/performance-feedback-request.model";

@UntilDestroy()
@Component({
  selector: 'performance-feedback',
  templateUrl: 'performance-feedback.component.html',
  styleUrls: ['../backoffice-common-components.scss']
})
export class PerformanceFeedbackComponent implements OnChanges {
  @Input() public userProfile: UserProfileType = null;
  @Output() public previousRequests: EventEmitter<any> = new EventEmitter<any>();
  
  public isLoading: boolean = false;
  private loggedInUserProfile: AuthorizedUserProfileModel = null;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private bsModalService: BsModalService,
    private router: Router
  ) {
    this.setUpLoadingSpinnerSubscription();
    this.store.pipe(
      getLoggedInUserProfile,
      untilDestroyed(this)
    ).subscribe(loggedInUserProfile => this.loggedInUserProfile = loggedInUserProfile)
  }

  private setUpLoadingSpinnerSubscription() {
    this.actionsSubject
      .pipe(
        ofType(
          SendPerformanceFeedbackRequestFail,
          SendPerformanceFeedbackRequestSuccess
        ),
        untilDestroyed(this)
      ).subscribe(() => (this.isLoading = false));
  }

  ngOnChanges(): void {
    if (this.userProfile._embedded) {
      this.isLoading = false;
    }
  }

  public openSendPerformanceFeedbackRequest(): void {
    const {firstName: requesterFirstName, lastName: requesterLastName, additionalData: requesterPosition} = this.loggedInUserProfile;
    const {firstName: technicianFirstName, lastName: technicianLastName} = getEmbeddedResource<any>(this.userProfile, UserProfileLinkRel.Profile);
    this.bsModalService.show(PerformanceFeedbackRequestComponent, {
      ignoreBackdropClick: true,
      initialState: {
        requesterName: `${requesterFirstName} ${requesterLastName}`,
        technicianName: `${technicianFirstName} ${technicianLastName}`,
        requesterPosition: requesterPosition,
        onSendCallback: data => this.sendPerformanceFeedbackRequest(data),
      }
    });
  }

  public goToPreviousRequestsPage(): void {
    this.previousRequests.emit();
  }

  private sendPerformanceFeedbackRequest(payload: PerformanceFeedbackRequestPayload): void {
    this.isLoading = true;
    this.store.dispatch(SendPerformanceFeedbackRequest({
      payload,
      url: getUrl(this.userProfile, UserProfileLinkRel.SendPerformanceFeedbackRequest)
    }))
  }

}