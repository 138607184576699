<h1 class="h1">Experience confirmation</h1>
<div class="actions">
    <general-button
        (onClick)="openSendExperienceConfirmationRequest()"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
        text="New request"
    ></general-button>
    <general-button
        id="go-to-experiences-confirmation-requests-page-button"
        (onClick)="goToExperienceConfirmationRequestsPage()"
        [isDisabled]="isLoading"
        text="Previous requests"
    ></general-button>
</div>
