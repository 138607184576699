import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PictureTypes } from '../constants/picture-types.enum';
import { AppState, FacilityProfileState } from '../../../../../apps/staffnow-platform/src/app/state/app-state.model';
import { USERS_STATE_KEY } from 'apps/back-office/src/app/modules/users/state/users-state.model';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { selectFacilityProfile } from '../../../../../apps/staffnow-platform/src/app/state/app.selectors';
import { ofType } from '@ngrx/effects';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { getEmbeddedResource, getUrl, hasEmbeddedResource, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import {
  DeleteAgencyApprovals,
  DeleteAgencyApprovalsSuccessful,
  DeleteAgencyDefaultContract,
  DeleteAgencyDefaultContractSuccessful,
  DownloadAgencyApprovals,
  DownloadAgencyDefaultContract,
  FacilityProfileUpdated,
  FailedToUpdateFacilityProfile,
  GetFacilityProfile,
  MarkFacilityAsPending,
  MarkFacilityAsVerified,
  ResetFacilityProfile,
  SetAgencyApprovalsLabel,
  SetAgencyApprovalsLabelSuccessful,
  UpdateFacilityProfile
} from './facility-profile.actions';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { TextEditorEmittedValue } from './text-editor/text-editor.component';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { AgencyFacility, MroFacility } from '@libs/shared/models/facility.model';

interface MenuItem {
  label: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-facility-profile',
  templateUrl: './facility-profile-page.component.html',
  styleUrls: ['./facility-profile-page.component.scss']
})
export class FacilityProfilePageComponent implements OnInit, OnDestroy {
  @Input() initialFacilityProfile: FacilityProfileState;
  @Input() isBackOffice: boolean = false;
  @Input() mroGroupsList: Array<any> = [];

  selectedGroup: { id: string; name: string } = null;
  facilityProfile: FacilityProfileState;
  facilityPictureUploadUrl: string;
  allowedFileTypesPicture = ['png', 'jpg', 'jpeg'];
  allowedFileTypesDocument = ['pdf', 'doc', 'docx'];
  fileSizeLimit: number = 3;
  isFormCompleted: boolean = false;
  isBackofficeFormCompleted: boolean = false;
  isServicesDescriptionUpdated: boolean = false;
  isEditMode: boolean = false;
  textAreaMaxLength: number = 0;
  checklistMaxLength: number = 0;
  isLoading = false;
  pictureTypes = {
    cover: 'cover',
    logo: 'logo'
  };

  defaultContractUrl: string = '';
  agencyApprovalsUrl: string = '';
  shortPresentationCounter: number = 0;
  checklistCounter: number = 0;
  documentsNeededTemplateCounter: number = 0;

  private apiRoot: any = null;

  pictures: Resource[] = [];
  defaultContract: unknown[] = [];
  agencyApprovals: unknown[] = [];
  menuItems: Array<MenuItem> = [];
  activeTab = null;

  get facilityProfileAsAgency(): AgencyFacility {
    return this.facilityProfile as unknown as AgencyFacility;
  }

  get facilityProfileAsMro(): MroFacility {
    return this.facilityProfile as unknown as MroFacility;
  }

  constructor(
    private store: Store<AppState>,
    private actionsSubject: ActionsSubject,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) {
    if (this.activatedRoute.snapshot.data.application === 'backoffice') {
      this.isBackOffice = true;
      this.store
        .pipe(
          select(state => state[USERS_STATE_KEY]),
          untilDestroyed(this)
        )
        .subscribe(state => {
          this.mroGroupsList = state.mroGroupsList;
        });
    }

    this.store
      .pipe(selectFacilityProfile, untilDestroyed(this))
      .subscribe(profile => this.handleUpdateProfile(profile));
    this.store.pipe(getFilteredApiRoot, untilDestroyed(this)).subscribe(apiRoot => (this.apiRoot = apiRoot));
    this.actionsSubject.pipe(ofType(FacilityProfileUpdated), untilDestroyed(this)).subscribe(() => {
      this.isFormCompleted = false;
      this.isBackofficeFormCompleted = false;
      this.isEditMode = false;
      this.isLoading = false;
    });
    this.actionsSubject.pipe(ofType(FailedToUpdateFacilityProfile), untilDestroyed(this)).subscribe(() => {
      this.isFormCompleted = false;
      this.isBackofficeFormCompleted = false;
      this.isLoading = false;
    });
    this.actionsSubject
      .pipe(ofType(DeleteAgencyDefaultContractSuccessful), untilDestroyed(this))
      .subscribe(() => this.getFacilityProfile());
    this.actionsSubject
      .pipe(ofType(DeleteAgencyApprovalsSuccessful), untilDestroyed(this))
      .subscribe(() => this.getFacilityProfile());
    this.actionsSubject
      .pipe(ofType(SetAgencyApprovalsLabelSuccessful), untilDestroyed(this))
      .subscribe(() => this.getFacilityProfile());
  }

  ngOnDestroy(): void {
    this.store.dispatch(ResetFacilityProfile());
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.data.application === 'backoffice') {
      if (!this.facilityProfile.uuid) {
        this.router.navigate(['manage'], {
          relativeTo: this.activatedRoute.parent
        });
      }
    } else if (!this.initialFacilityProfile) {
      this.getFacilityProfile();
    }
  }

  charactersCounter(fieldToCount: string): void {
    switch (fieldToCount) {
      case 'shortPresentation':
        this.shortPresentationCounter = this.facilityProfile.shortPresentation.length;
        break;
      case 'checklist':
        this.checklistCounter = this.facilityProfile.checklist.length;
        break;
      case 'documentsNeededTemplate':
        this.documentsNeededTemplateCounter = this.facilityProfile.documentsNeededTemplate.length;
        break;
      default:
        break;
    }
  }

  checkProfileFormIsComplete(): void {
    const { shortPresentation, name } = this.facilityProfile;
    this.isFormCompleted = shortPresentation?.trim().length > 0 && name?.trim().length > 0;
    if (this.isAgency) {
      const { markUp } = this.facilityProfile;
      this.isFormCompleted = this.isFormCompleted && markUp != null && Number(markUp) >= 0 && Number(markUp) <= 100;
    }
  }

  checkBackofficeFormIsComplete(): void {
    if (this.isMro) {
      this.isBackofficeFormCompleted = this.mroWorkFlowIsValid() && this.daysBeforeAutoRejectIsValid();
    } else if (this.isAgency) {
      this.isBackofficeFormCompleted = this.daysBeforeAutoRejectIsValid();
    }
  }

  daysBeforeAutoRejectIsValid(): boolean {
    const { daysBeforeAutoReject } = this.facilityProfile;
    return !isNil(daysBeforeAutoReject) && daysBeforeAutoReject >= 0;
  }

  handlePictureChange(url: string): void {
    this.updateImageLink(url, JobOfferLinkRel.FacilityImage);
  }

  handleLogoChange(url: string): void {
    this.updateImageLink(url, JobOfferLinkRel.FacilityLogo);
  }

  saveChanges(): void {
    this.saveChangesInternal(FacilityProfileLinkRel.UpdateFacilityProfile);
  }

  saveAdminPropertiesChanges(): void {
    this.saveChangesInternal(FacilityProfileLinkRel.UpdateFacilityAdminProperties);
  }

  canEditProfile(): boolean {
    return hasLink(this.facilityProfile, FacilityProfileLinkRel.UpdateFacilityProfile);
  }

  isProfileLoaded(): boolean {
    return !isEmpty(this.facilityProfile.uuid);
  }

  canEditLogo(): boolean {
    return hasLink(this.facilityProfile, FacilityProfileLinkRel.UploadFacilityPicture);
  }

  onGroupChangeHandler(newValue: any): void {
    if (newValue) {
      this.facilityProfile.groupId = newValue.id;
      this.facilityProfile.groupName = newValue.name;
    }
    this.checkBackofficeFormIsComplete();
  }

  get facilityCoverUrl(): string {
    return this.getImageUrl(JobOfferLinkRel.FacilityImage);
  }

  get facilityLogoUrl(): string {
    return this.getImageUrl(JobOfferLinkRel.FacilityLogo);
  }

  get facilityCoverType(): string {
    if (this.facilityProfile.type === EntityTypes.MRO.toUpperCase()) {
      return PictureTypes.MroFacilityPicture;
    }
    if (this.facilityProfile.type === EntityTypes.AGENCY.toUpperCase()) {
      return PictureTypes.AgencyFacilityPicture;
    }
    return null;
  }

  get facilityLogoType(): string {
    if (this.facilityProfile.type === EntityTypes.MRO.toUpperCase()) {
      return PictureTypes.MroFacilityLogo;
    }
    if (this.facilityProfile.type === EntityTypes.AGENCY.toUpperCase()) {
      return PictureTypes.AgencyFacilityLogo;
    }
    return null;
  }

  get isAgency(): boolean {
    return this.facilityProfile.type.toLowerCase() === EntityTypes.AGENCY.toString();
  }

  get isMro(): boolean {
    return this.facilityProfile.type.toLowerCase() === EntityTypes.MRO.toString();
  }

  hasApprovedAgenciesLink(): boolean {
    return hasLink(this.facilityProfile, FacilityProfileLinkRel.ApprovedAgencies);
  }

  hasMroApprovalsLink(): boolean {
    return hasLink(this.facilityProfile, FacilityProfileLinkRel.MroApprovals);
  }

  updateServices(newValue: TextEditorEmittedValue): void {
    if (!newValue.isValid) {
      this.isServicesDescriptionUpdated = false;
    } else {
      this.isServicesDescriptionUpdated = true;
      this.facilityProfile.services = newValue.html;
    }
  }

  saveServices(): void {
    if (this.isServicesDescriptionUpdated) {
      this.isServicesDescriptionUpdated = false;
      this.isLoading = true;
      this.store.dispatch(
        UpdateFacilityProfile({
          payload: { text: this.facilityProfile.services },
          endpointUrl: getUrl(this.facilityProfile, FacilityProfileLinkRel.UpdateAgencyServices),
          facilityUrl: getUrl(this.facilityProfile, ApiRootLinkRel.Self)
        })
      );
    }
  }

  onSuccessfulFileUpload(): void {
    this.getFacilityProfile();
  }

  removeFile($event: any): void {
    this.store.dispatch(DeleteAgencyDefaultContract({ document: $event }));
  }

  removeAgencyApprovals($event: any): void {
    this.store.dispatch(DeleteAgencyApprovals({ document: $event }));
  }

  handleSetLabel($event: any): void {
    this.store.dispatch(
      SetAgencyApprovalsLabel({
        document: $event.document,
        newLabel: $event.label
      })
    );
  }

  downloadFile($event: any): void {
    this.store.dispatch(DownloadAgencyDefaultContract({ document: $event }));
  }

  downloadAgencyApprovals($event: any): void {
    this.store.dispatch(DownloadAgencyApprovals({ document: $event }));
  }

  hasVerifyLink(): boolean {
    return hasLink(this.facilityProfile, FacilityProfileLinkRel.Verify);
  }

  hasPendingLink(): boolean {
    return hasLink(this.facilityProfile, FacilityProfileLinkRel.MarkAsPending);
  }

  verifyFacility(): void {
    this.modifyMroStatus('FACILITY_PROFILE.VERIFY_FACILITY_MESSAGE', () =>
      this.store.dispatch(
        MarkFacilityAsVerified({
          actionUrl: getUrl(this.facilityProfile, FacilityProfileLinkRel.Verify)
        })
      )
    );
  }

  markAsPending(): void {
    this.modifyMroStatus('FACILITY_PROFILE.MARK_AS_PENDING_FACILITY_MESSAGE', () =>
      this.store.dispatch(
        MarkFacilityAsPending({
          actionUrl: getUrl(this.facilityProfile, FacilityProfileLinkRel.MarkAsPending)
        })
      )
    );
  }

  isEmptyText(text: string): boolean {
    return !text || text.length === 0;
  }

  private mroWorkFlowIsValid(): boolean {
    const { requiresProductionAcceptance, requiresQualityAcceptance, requiresHumanResourcesAcceptance } =
      this.facilityProfile;
    const allMrosAreSelected =
      requiresProductionAcceptance && requiresQualityAcceptance && requiresHumanResourcesAcceptance;
    return (
      !allMrosAreSelected &&
      (requiresProductionAcceptance || requiresQualityAcceptance || requiresHumanResourcesAcceptance)
    );
  }

  private updateImageLink(url: string, linkRel: string): void {
    if (this.facilityProfile._links[linkRel]) {
      this.facilityProfile._links[linkRel].href = url;
    } else {
      this.facilityProfile._links[linkRel] = { href: url };
    }
  }

  private saveChangesInternal(linkRel: string): void {
    const profile = { ...this.facilityProfile };
    this.isLoading = true;
    this.store.dispatch(
      UpdateFacilityProfile({
        payload: profile,
        endpointUrl: getUrl(profile, linkRel),
        facilityUrl: getUrl(profile, ApiRootLinkRel.Self)
      })
    );
  }

  private handleUpdateProfile(profile: FacilityProfileState): void {
    this.facilityProfile = cloneDeep(profile);
    if (this.isMro) {
      this.textAreaMaxLength = 1000;
      this.checklistMaxLength = 1500;
      this.selectedGroup = {
        id: this.facilityProfile.groupId,
        name: this.facilityProfile.groupName
      };
      this.menuItems = this.buildNavigation();
    } else if (this.isAgency) {
      this.textAreaMaxLength = 500;
      this.menuItems = this.buildNavigation([
        {
          label: 'Promotional Settings',
          value: 'promotional'
        },
        {
          label: 'Agency Approvals',
          value: 'approvals'
        }
      ]);
      if (this.facilityProfile._embedded) {
        this.pictures = getEmbeddedResource(this.facilityProfile, FacilityProfileLinkRel.AgencyEmbeddedPictures);

        this.setUpAgencyDefaultContract();
        this.setUpAgencyApprovals();
      }
    }
    const tabIsSelected = this.menuItems.some(item => item.value == this.activeTab?.value);
    if (!tabIsSelected) {
      this.activeTab = this.menuItems[0];
    }

    if (profile._links) {
      if (hasLink(profile, FacilityProfileLinkRel.UploadAgencyDefaultContract)) {
        this.defaultContractUrl = getUrl(profile, FacilityProfileLinkRel.UploadAgencyDefaultContract);
      }
      if (hasLink(profile, FacilityProfileLinkRel.UploadAgencyApprovals)) {
        this.agencyApprovalsUrl = getUrl(profile, FacilityProfileLinkRel.UploadAgencyApprovals);
      }
      if (hasLink(profile, FacilityProfileLinkRel.UploadFacilityPicture)) {
        this.facilityPictureUploadUrl = getUrl(profile, FacilityProfileLinkRel.UploadFacilityPicture);
      }
    }

    if (profile.shortPresentation) {
      this.shortPresentationCounter = profile.shortPresentation.length;
    }

    if (profile.checklist) {
      this.checklistCounter = profile.checklist.length;
    }
  }

  private setUpAgencyDefaultContract() {
    if (hasEmbeddedResource(this.facilityProfile, FacilityProfileLinkRel.AgencyEmbeddedDefaultContract)) {
      this.defaultContract = [
        getEmbeddedResource(this.facilityProfile, FacilityProfileLinkRel.AgencyEmbeddedDefaultContract)
      ];
    } else {
      this.defaultContract = [];
    }
  }

  private setUpAgencyApprovals() {
    if (hasEmbeddedResource(this.facilityProfile, FacilityProfileLinkRel.AgencyEmbeddedAgencyApprovals)) {
      this.agencyApprovals = getEmbeddedResource(
        this.facilityProfile,
        FacilityProfileLinkRel.AgencyEmbeddedAgencyApprovals
      );
    } else {
      this.agencyApprovals = [];
    }
  }

  private buildNavigation(facilityTab?: Array<any>): Array<MenuItem> {
    const navigationItems = [{ label: 'Profile Settings', value: 'profile' }];
    if (facilityTab) {
      facilityTab.forEach(it => {
        navigationItems.push(it);
      });
    }
    if (this.isBackOffice) {
      navigationItems.push({
        label: 'BackOffice Settings',
        value: 'backoffice'
      });
    }
    return navigationItems;
  }

  private getImageUrl(linkRel: string): string {
    if (!hasLink(this.facilityProfile, linkRel)) {
      return '';
    }
    return getUrl(this.facilityProfile, linkRel);
  }

  private getFacilityProfile(): void {
    const facilityUrl = this.facilityProfile?._links
      ? getUrl(this.facilityProfile, ApiRootLinkRel.Self)
      : getUrl(this.apiRoot, ApiRootLinkRel.MyFacility);
    this.store.dispatch(GetFacilityProfile({ facilityUrl }));
  }

  private modifyMroStatus(message: string, onConfirmCallback: () => void): void {
    this.modalService.openConfirmModal(message, onConfirmCallback);
  }
}
