import { Component, Input } from '@angular/core';

@Component({
  selector: 'collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss']
})
export class CollapsibleComponent {
  @Input() public heading: string = '';
  @Input() public highlight?: boolean = false;
  @Input() public isCollapsed: boolean = true;

  public toggleCollapsible(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
