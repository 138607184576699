import {Component, inject} from '@angular/core';
import {AbstractOfferDetailsComponent} from '../abstract-offer-details.component';
import {Contract} from '@libs/shared/models/contract.model';
import {RequestOverviewState} from '../../../state/request-overview-state.model';
import {isNil} from 'lodash-es';
import {ConversationService} from '../../../services/conversation.service';
import {isDateInThePastOrToday} from '@libs/shared/helpers/date-utils';

@Component({template: ''})
export abstract class AbstractTechnicianOfferDetailsComponent extends AbstractOfferDetailsComponent {
  public contract: Contract = null;
  public requiresAgencySelectionForOfferApplication: boolean = true;

  protected conversationService: ConversationService;

  protected constructor() {
    super();
    this.conversationService = inject(ConversationService);
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.jobOffer?.periodTo);
  }

  get mroMessageTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.MESSAGE_MRO_AFTER_ACCEPTING_JO');
  }

  get messageTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.MESSAGE_ON_MATCH');
  }

  get canCreateConversationWithMro(): boolean {
    return this.conversationService.canCreateConversationWithMro(this.contract);
  }

  get canGetPreviousConversationWithMro(): boolean {
    return this.conversationService.canGetPreviousConversationWithMro(
      this.contract
    );
  }

  get canCreateConversationWithTechnician(): boolean {
    return this.conversationService.canCreateConversationWithTechnician(
      this.contract
    );
  }

  get canGetPreviousConversationWithTechnician(): boolean {
    return this.conversationService.canGetPreviousConversationWithTechnician(
      this.contract
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    /*
    * 'RequestOverviewState' is a state definition that is shared between Aviation Companies, Agencies and Technicians, but Technicians
    * cannot have more than one contract in the same offer, that's why we access only to the first element of the list (state.contracts[0]).
    * */
    this.contract = state.contracts.length > 0 ? this.adjustContractEndDate(state.contracts[0]) : null;
    if (!isNil(this.offer)) {
      this.requiresAgencySelectionForOfferApplication = this.jobOffer.requiresAgencySelectionForOfferApplication;
    }
  }

  private adjustContractEndDate(contract): any {
    return {
      ...contract,
      endDate: contract.endDate ? contract.endDate : contract.provisionalEndDate
    };
  }

  public initiateMessageWithMro(): void {
    this.conversationService.initiateMessageWithMro(this.contract);
  }

  public initiateMessageWithTechnician(): void {
    this.conversationService.initiateMessageWithTechnician(this.contract);
  }
}
