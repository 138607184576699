import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";

@UntilDestroy()
@Component({
  selector: 'staffnow-request-overview-wrapper',
  templateUrl: './request-overview-wrapper.component.html',
  styleUrls: ['./request-overview-wrapper.component.scss']
})
export class RequestOverviewWrapperComponent implements OnInit {
  public availableTabs = [];
  public activeTab: any = null;
  private lastActiveType: string = null;

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data
        .pipe(untilDestroyed(this))
        .subscribe(data => this.availableTabs = data.tabs);
  }

  ngOnInit(): void {
    this.watchQueryParams();
  }

  private watchQueryParams() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => this.updateFromParams(params));
  }

  private updateFromParams(params: Params) {
    this.activeTab = this.availableTabs.find(tab => tab.offerType == params.offerType) ?? this.availableTabs[0];
    this.lastActiveType = this.activeTab.offerType;
    if (isNil(params.offerType)) {
      this.updateUrl();
    }
  }

  public updateUrl(): void {
    const currentOfferType = this.activeTab.offerType;
    const queryParamsHandling =
      currentOfferType === this.lastActiveType ? 'merge' : null;
    this.router.navigate([], {
      queryParamsHandling,
      queryParams: { offerType: currentOfferType }
    });
  }
}
