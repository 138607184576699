import {Component} from "@angular/core";
import {isPlatformIos} from "@libs/shared/helpers/capacitor";
import {Store} from "@ngrx/store";
import {getStaticEnvironment} from "@libs/shared/bms-common/environment/environment.selector";
import {BrandName} from "@libs/shared/bms-common/environment/environment.model";
import {AuthenticationService} from "@libs/auth/services/authentication.service";

@Component({
  selector: 'staffnow-wrong-app',
  templateUrl: './wrong-app.component.html',
  styleUrls: ['../common.scss']
})
export class WrongAppComponent {

  brandName: string = '';
  otherAppBrandName: string = '';
  deviceStore: string = isPlatformIos() ? 'App Store' : 'Play Store';

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store<any>
  ) {
    authenticationService.clearAuthentication();
    this.store.pipe(getStaticEnvironment).subscribe(envData => {
      this.brandName = envData.brandConfig.brandName;
      this.otherAppBrandName = this.brandName === BrandName.StaffNow ? BrandName.eLAUNCHNow : BrandName.StaffNow;
    });
  }

  goToLoginPage() {
    this.authenticationService.goToLoginPage();
  }
}
