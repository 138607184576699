import { Component, Input, OnChanges } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { ToggleTechnicianOffersSubscription, UserProfileActions } from '@libs/user-profile/state/user-profile.actions';
import { cloneDeep } from 'lodash-es';
import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { SubscriptionItem, SubscriptionNomenclature, SubscriptionType } from '@libs/shared/models/technician-subscription.model';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'staffnow-notification-subscription',
  templateUrl: './notification-subscription.component.html',
  styleUrls: ['./notification-subscription.component.scss'],
  providers: []
})
export class NotificationSubscriptionComponent implements OnChanges {
  @Input() public userProfile: TechnicianProfileModel;
  @Input() public facilities: any[];
  public ameNomenclatures: SubscriptionNomenclature[];

  public technicianSubscriptions: SubscriptionItem[] = [];
  public preselectedSubs: { name: string; uuid: string }[] = [];
  public customClass = 'customClass';
  public hasChanges = false;
  public isLoading = false;
  private subsConfig: SubscriptionItem[] = [];
  protected customChecked = false;
  protected readonly SubscriptionType = SubscriptionType;

  get defaultSubscriptionType() {
    return this.userProfile.ameTitles.map(title => title.type + ' ' + (title.license ? title.license : '')).join(' ');
  }

  constructor(private store: Store<any>, private actionsSubject: ActionsSubject) {
    this.actionsSubject
      .pipe(ofType(UserProfileActions.NotificationSubscriptionsLoaded), untilDestroyed(this))
      .subscribe(action => {
        this.subsConfig = action.subscriptions;
        this.handleCustomSubscriptionListChange();
        this.isLoading = false;
      });
  }

  private handleCustomSubscriptionListChange() {
    if (this.subsConfig !== null && this.subsConfig.length > 0) {
      this.technicianSubscriptions = cloneDeep(this.subsConfig);
      this.preselectedSubs = this.subsConfig.map(sub => {
        return {
          name: sub.name,
          uuid: sub.uuid
        };
      });
      this.hasChanges = false;
    } else {
      this.technicianSubscriptions = [];
      this.preselectedSubs = [];
    }
  }

  public ngOnChanges() {
    if (this.userProfile) {
      this.customChecked = this.userProfile.technicianOfferSubscription === SubscriptionType.CUSTOM;
      this.ameNomenclatures = getEmbeddedResource(this.userProfile, UserProfileLinkRel.AmeNomenclature);
    }
  }

  public generateSubscriptionItemTitle(subscription: SubscriptionItem): string {
    const { ameNomenclatures } = subscription;
    let isCustom = false;
    ameNomenclatures.forEach(nomenclature => {
      nomenclature.licenses.forEach(license => {
        if (!this.userHasTitle(license.id) && license.checked) {
          isCustom = true;
        }
      });
    });
    return isCustom ? 'Custom' : this.defaultSubscriptionType;
  }

  public handleMroSelection(selectedItems: any[]): void {
    this.setHasChanges();
    this.removeUnselectedItems(selectedItems);
    this.addNewItems(selectedItems);
  }

  private removeUnselectedItems(selectedItems: any[]) {
    const selectedUuids = selectedItems.map(item => item.uuid);
    this.technicianSubscriptions = this.technicianSubscriptions.filter(sub => selectedUuids.includes(sub.uuid));
  }

  private addNewItems(selectedItems: any[]) {
    const existingUuids: string[] = this.technicianSubscriptions.map(sub => sub.uuid);
    const itemsToAdd = selectedItems.filter(item => !existingUuids.includes(item.uuid));
    this.technicianSubscriptions.push(...itemsToAdd.map(item => this.generateSubscriptionItem(item.name, item.uuid)));
  }

  public generateUniqueId(uuid: string, ameTypeName: string, ameLicenseName: string): string {
    return btoa(uuid + ameTypeName + ameLicenseName);
  }

  public submitCustomSubscription(): void {
    this.isLoading = true;
    this.store.dispatch(
      ToggleTechnicianOffersSubscription({
        url: getUrl(this.userProfile, UserProfileLinkRel.Subscriptions),
        subscriptionType: SubscriptionType.CUSTOM,
        subscriptions: cloneDeep(this.technicianSubscriptions)
      })
    );
  }

  public changeTechnicianOfferSubscriptionToCustom(): void {
    if (this.userProfile.technicianOfferSubscription !== SubscriptionType.NONE && this.customChecked) {
      this.dispatchTechnicianOffersSubscription(SubscriptionType.NONE);
    } else {
      this.customChecked = !this.customChecked;
    }
  }

  public changeTechnicianOffersSubscription(newSubscriptionType: SubscriptionType): void {
    if (this.customChecked && this.userProfile.technicianOfferSubscription === newSubscriptionType) {
      this.customChecked = false;
    } else {
      this.dispatchTechnicianOffersSubscription(
        this.userProfile.technicianOfferSubscription === newSubscriptionType ? SubscriptionType.NONE : newSubscriptionType
      );
    }
  }

  private dispatchTechnicianOffersSubscription(type: SubscriptionType, subscriptions = []): void {
    this.isLoading = true;
    this.store.dispatch(
      ToggleTechnicianOffersSubscription({
        url: getUrl(this.userProfile, UserProfileLinkRel.Subscriptions),
        subscriptionType: type,
        subscriptions: subscriptions
      })
    );
  }

  public setHasChanges() {
    if (this.hasChanges) {
      return;
    } else {
      this.hasChanges = true;
    }
  }

  private generateSubscriptionItem(name: string, uuid: string): SubscriptionItem {
    return {
      name,
      uuid,
      ameNomenclatures: this.ameNomenclatures.map(nomenclature => this.preselectUserTitles(cloneDeep(nomenclature)))
    };
  }

  private preselectUserTitles(nomenclatureDeepCopy: SubscriptionNomenclature) {
    nomenclatureDeepCopy.licenses.forEach(license => {
      license.checked = this.userHasTitle(license.id);
    });
    return nomenclatureDeepCopy;
  }

  private userHasTitle(id: number): boolean {
    return this.userProfile.ameTitles.some(title => title.id === id);
  }

  protected get disableCustomSubmitButton(): boolean {
    return !this.hasChanges || this.isLoading || this.technicianSubscriptions?.length < 1 || this.noLicenseIsChecked;
  }

  private get noLicenseIsChecked(): boolean {
    return !this.technicianSubscriptions
      .flatMap(title => title.ameNomenclatures)
      .flatMap(ame => ame.licenses)
      .some(license => license.checked === true);
  }
}
