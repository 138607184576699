<staffnow-favorite-button
    *ngIf="canEditFavoriteTechniciansList"
    [isFavorite]="technician?.isFavoriteTechnician"
    (updateFavorites)="handleUpdateMroFavorites($event)"
></staffnow-favorite-button>
<div class="technician-card-head">
    <div
        class="profile-pic"
        *ngIf="technicianPicture"
        [ngStyle]="{
            'background-image': 'url(\'' + technicianPicture + '\')'
        }"
    ></div>
    <div class="profile-pic" *ngIf="!technicianPicture">
        <i class="material-icons"> person </i>
    </div>

    <h3 class="h3">
        {{ technicianProfile?.firstName }} {{ technicianProfile?.lastName }}
    </h3>
    <technician-title-list [titleList]="technician?.ameTitles">
    </technician-title-list>
    <div *ngIf="isOwnTechnicianListing" class="own-technician-info">
        <p class="email">{{ technicianProfile?.email }}</p>
        <p>
            {{ 'Profile:' | translate }}
            {{ technicianProfile?.profileStatus | humanReadableProfileStatus }}
        </p>
        <p>
            {{ 'Account:' | translate }}
            {{ technicianProfile?.accountStatus }}
        </p>
    </div>
</div>

<div class="technician-card-body" *ngIf="!isOwnTechnicianListing">
    <p class="info">
        <i class="material-icons">av_timer</i>
        <span class="overflow-elipsis"
            >{{
                (technician?.experience || 0) +
                    (technician?.experience == 1 ? ' year' : ' years') +
                    ' experience' | translate
            }}
        </span>
    </p>
    <p class="info" *ngIf="technician?.airplanes | selectedAircraft">
        <i class="material-icons">airplanemode_active</i>
        <span class="overflow-elipsis">{{
            technician?.airplanes | selectedAircraft
        }}</span>
    </p>
</div>

<div
    *ngIf="!isOwnTechnicianListing"
    class="overlay"
    (click)="toggleSelectedTechnician($event)"
>
    <i class="material-icons touch-icon">touch_app</i>
    <span class="add-label">{{ 'add to selection' | translate }}</span>
    <span *ngIf="isAbleToSeeTechnicianFullProfile" class="or-label">{{
        'or' | translate
    }}</span>
    <general-button
        *ngIf="isAbleToSeeTechnicianFullProfile"
        (onClick)="openModalProfileView()"
        text="View profile"
    ></general-button>
</div>
<span
    *ngIf="technician['isExactMatch'] && isAbleToSeeTechnicianFullProfile"
    class="exact-match-stamp"
    [tooltip]="'SYSTEM.INFO.TECHNICIAN_SEARCH_FIT' | translate"
    >{{ '100% fit' | translate }}</span
>
