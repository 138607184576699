import { Component, OnInit } from '@angular/core';
import { AbstractTechnicianOfferDetailsComponent } from '../technician-offer-details/abstract-technician-offer-details.component';
import { ActivatedRoute } from '@angular/router';
import {
  AcceptContract,
  AcceptStartDate,
  LoadPermanentOfferWithContracts,
  ProposeStartDate
} from '@libs/request-overview-common/state/requests-overview.actions';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import { isNil } from 'lodash-es';
import { StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model';
import { BsModalService } from 'ngx-bootstrap/modal';

@UntilDestroy()
@Component({
  selector: 'staffnow-technician-permanent-offer-details',
  templateUrl: './technician-permanent-offer-details.component.html',
  styleUrls: ['./technician-permanent-offer-details.component.scss']
})
export class TechnicianPermanentOfferDetailsComponent extends AbstractTechnicianOfferDetailsComponent implements OnInit {
  public brandName: string = null;

  constructor(private readonly activatedRoute: ActivatedRoute, private modalService: BsModalService) {
    super();
    this.store.pipe(getStaticEnvironment, untilDestroyed(this)).subscribe(envData => {
      this.brandName = envData.brandConfig.brandName;
    });
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadPermanentOfferWithContracts({
            refNumber: params.offerRefNumber
          })
        );
      })
    );
  }

  get canAcceptContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContract);
  }

  public acceptOffer(): void {
    this.store.dispatch(
      AcceptContract({
        contract: this.contract
      })
    );
  }

  get hasProposeStartDateLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ProposeContractStartDate);
  }

  get hasAcceptStartDateLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContractStartDate);
  }

  public acceptStartDate(): void {
    this.store.dispatch(
      AcceptStartDate({
        contract: this.contract
      })
    );
  }

  public handleStartDateProposal(): void {
    this.modalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (startDate: string) => this.proposeStartDate(startDate),
        warningMessage: this.warningMessage(),
        title: 'TASKS.VIEW.DESCRIPTION.PROPOSE_START_DATE',
        reminders: [{
          label: 'SYSTEM.INFO.START_DATE_PERMANENT_TECHNICIAN_REMINDER.LABEL',
          text: 'SYSTEM.INFO.START_DATE_PERMANENT_TECHNICIAN_REMINDER.TEXT'
        }]
      }
    });
  }

  private proposeStartDate(startDate: string): void {
    this.store.dispatch(
      ProposeStartDate({
        startDate,
        contract: this.contract
      })
    );
  }

  private warningMessage(): string {
    if (this.datePhaseIsAccepted) {
      return this.translateService.instant('TECHNICIAN.PERMANENT_OFFER_DETAILS.ALREADY_START_DATE');
    }
    return null;
  }

  get proposeDateLabel(): string {
    if (isNil(this.contract.startDate)) {
      return 'PROPOSE';
    }
    return this.datePhaseIsAccepted ? 'CHANGE' : 'PROPOSE';
  }

  get datePhaseIsAccepted(): boolean {
    return StartDatePhaseUtils.isAccepted(this.contract.startDatePhase);
  }
}
