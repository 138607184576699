import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { isNull, last, omit } from 'lodash-es';
import { getRouteResourceIds } from './route-resources';
import { ActivatedView, ViewRoute } from './navigation.model';

export class ActivatedViewRouterStateSerializer
  implements RouterStateSerializer<ActivatedView> {
  serialize(routerStateSnapshot: RouterStateSnapshot): ActivatedView {
    const viewRoutes = getViewRoutes(routerStateSnapshot);
    const activatedViewRoute = last(viewRoutes);
    return {
      routes: viewRoutes,
      viewName: activatedViewRoute!.viewName,
      params: viewRoutes.reduce(
        (mergedParams, viewRoute) => ({
          ...mergedParams,
          ...viewRoute.params
        }),
        {}
      ),
      data: viewRoutes.reduce(
        (mergedData, viewRoute) => ({
          ...mergedData,
          ...viewRoute.data
        }),
        {}
      ),
      routePath: viewRoutes.reduce(
        (mergedRoutePaths, viewRoute) => [
          ...mergedRoutePaths,
          ...viewRoute.routePath
        ],
        []
      ),
      url: '' //TODO(SN-987)? generate proper url
    };
  }
}

function getViewRoutes(routerStateSnapshot: RouterStateSnapshot): ViewRoute[] {
  return toViewRoutes(routerStateSnapshot.root.firstChild);
}

function toViewRoutes(
  activatedRouteSnapshot: ActivatedRouteSnapshot | null,
  viewRoutes: ViewRoute[] = []
) {
  if (isNull(activatedRouteSnapshot)) {
    return viewRoutes;
  }

  return toViewRoutes(activatedRouteSnapshot!.firstChild, [
    ...viewRoutes,
    toViewRoute(activatedRouteSnapshot!)
  ]);
}

function toViewRoute(
  activatedRouteSnapshot: ActivatedRouteSnapshot
): ViewRoute {
  return {
    viewName: activatedRouteSnapshot.data.view,
    routePath: activatedRouteSnapshot.url.map(urlSegment => urlSegment.path),
    data: omit(activatedRouteSnapshot.data, ['view']),
    params: {
      ...activatedRouteSnapshot.params,
      ...getRouteResourceIds(activatedRouteSnapshot.params)
    },
    queryParams: activatedRouteSnapshot.queryParams
  };
}
