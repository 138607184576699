import { Component } from '@angular/core';
import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  GetListOfSkills,
  ListOfSkillsLoaded,
  UpdateTechnicianSkills
} from '@libs/user-profile/state/user-profile.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Skill } from '@libs/shared/models/skills.model';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { selectProfileLoading, selectUserProfile } from '@libs/user-profile/state/user-profile.selectors';

@UntilDestroy()
@Component({
  selector: 'staffnow-technician-skills',
  templateUrl: './technician-skills.component.html',
  styleUrls: ['./technician-skills.component.scss']
})
export class TechnicianSkillsComponent {
  public listOfSkills: Skill[] = [];
  public isEnabled: boolean = false;
  public isLoading: boolean = false;
  public otherSkills: string = '';

  private technicianProfile: TechnicianProfileModel = null;

  constructor(private store: Store<any>, private actions: Actions) {
    this.store.dispatch(GetListOfSkills());
    this.store
      .select(selectUserProfile)
      .pipe(untilDestroyed(this))
      .subscribe(userProfile => {
        this.technicianProfile = userProfile as TechnicianProfileModel;
        this.otherSkills = this.technicianProfile.otherSkills;
        this.setSelectSkills();
      });
    this.store
      .select(selectProfileLoading)
      .pipe(untilDestroyed(this))
      .subscribe(loading => {
        this.isLoading = loading;
      });
    this.actions.pipe(ofType(ListOfSkillsLoaded), untilDestroyed(this)).subscribe(action => {
      this.listOfSkills = cloneDeep(action.listOfSkills);
      this.setSelectSkills();
    });
  }

  get canUpdateTechnicianSkills(): boolean {
    return hasLink(this.technicianProfile, UserProfileLinkRel.UpdateTechnicianSkills);
  }

  public updateTechnicianSkills(): void {
    this.store.dispatch(
      UpdateTechnicianSkills({
        skillsIds: this.getSelectedIds(),
        otherSkills: this.otherSkills,
        hasSetSkills: true,
        url: getUrl(this.technicianProfile, UserProfileLinkRel.UpdateTechnicianSkills)
      })
    );
  }

  private getSelectedIds(): number[] {
    return this.listOfSkills.filter(skill => skill.isSelected).map(skill => skill.id);
  }

  private setSelectSkills(): void {
    if (!isEmpty(this.technicianProfile.skills) || !this.technicianHasFilledSkillMenu()) {
      this.listOfSkills.forEach(skill => (skill.isSelected = this.technicianHasSkill(skill.id)));
      this.isEnabled = this.canUpdateTechnicianSkills;
    }
  }

  private technicianHasSkill(skillId: number): boolean {
    return !isNil(this.technicianProfile.skills.find(skill => skill.id === skillId));
  }

  public technicianHasAnySkill() {
    return !isEmpty(this.technicianProfile.skills) || !isEmpty(this.getSelectedIds());
  }

  public technicianHasFilledSkillMenu() {
    return this.technicianProfile.hasSetSkills;
  }

  public enableSkillsSelection(value: boolean) {
    this.isEnabled = value;
    this.listOfSkills.forEach(skill => (skill.isSelected = false));
  }

  public shouldEnableUpdateButton() {
    return this.canUpdateTechnicianSkills && !this.isLoading && (!isEmpty(this.getSelectedIds()) || !this.isEnabled);
  }
}
