import { Action } from '@ngrx/store';
import {
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate,
  updateArray,
} from 'ngrx-forms';
import {
  required,
  greaterThanOrEqualTo,
  lessThanOrEqualTo
} from 'ngrx-forms/validation';

import {
  FeedbackFormModel,
  FeedbackFormState,
  initialFormState,
  FeedbackQuestion
} from './feedback-form.model';
import { FeedbackActions } from '../../state/feedback.actions';

const updateMyFormGroup = updateGroup<FeedbackFormModel>({
  id: validate(required),
  feedbackQuestions: updateArray(
    updateGroup<FeedbackQuestion>({
      answer: validate(required, greaterThanOrEqualTo(1), lessThanOrEqualTo(5))
    })
  )
});

const formReducer = createFormStateReducerWithUpdate<FeedbackFormModel>(
  updateMyFormGroup
);

export function feedbackFormReducer(
  state: FeedbackFormState = initialFormState,
  action: Action
): FormGroupState<FeedbackFormModel> {
  const form = formReducer(state, action);

  switch (action.type) {
    case FeedbackActions.ResetFeedbackForm:
      return {
        ...initialFormState
      };
  }

  return {
    ...form
  };
}
