import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroupState, SetValueAction } from 'ngrx-forms';
import { FeedbackResource } from '../feedback-alert/feedback-alert.component';
import { FeedbackFormModel, FEEDBACK_FORM_ID } from './feedback-form.model';
import {
  ResetFeedbackForm,
  SendFeedbackForm
} from '../../state/feedback.actions';
import { selectFeedbackForm } from '../../state/feedback.selectors';
import {
  getEmbeddedResource,
  getUrl,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';

@Component({
  selector: 'staffnow-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  @Input() public currentFeedback: FeedbackResource;
  public feedbackFormState: FormGroupState<FeedbackFormModel>;

  constructor(private store: Store<any>, public bsModalRef: BsModalRef) {
    this.store.select(selectFeedbackForm).subscribe(value => {
      this.feedbackFormState = value;
    });
  }

  getFeedbackQuestions() {
    return getEmbeddedResource(this.currentFeedback, 'feedbackQuestions');
  }

  ngOnInit() {
    this.store.dispatch(
      new SetValueAction(FEEDBACK_FORM_ID, {
        id: this.currentFeedback.id,
        feedbackQuestions: this.getFeedbackQuestions()
      })
    );
  }

  submitFeedback() {
    const feedbackQuestions = this.feedbackFormState.value.feedbackQuestions;
    if (hasLink(this.currentFeedback, 'addFeedbackAnswers')) {
      this.store.dispatch(
        new SendFeedbackForm(
          getUrl(this.currentFeedback, 'addFeedbackAnswers'),
          feedbackQuestions
        )
      );
      this.bsModalRef.hide();
    }
  }

  closeAndResetForm() {
    this.store.dispatch(new ResetFeedbackForm());
    this.bsModalRef.hide();
  }
}
