import {BsModalRef} from "ngx-bootstrap/modal";
import {isNil} from "lodash-es";
import {email} from "ngrx-forms/validation";
import {Directive} from "@angular/core";

@Directive()
export abstract class TechnicianRequestGenericComponent <T> {

  constructor(private bsModalRef: BsModalRef) {
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public abstract isDisabled(): boolean;

  public isMissing(value: string): boolean {
    const trimmedValue = value?.trim();
    return isNil(trimmedValue) || trimmedValue.length == 0;
  }

  protected isValidEmail(recipientEmail: string): boolean {
    return !this.isMissing(recipientEmail) && this.emailFormatIsCorrect(recipientEmail);
  }

  public emailFormatIsCorrect(recipientEmail: string): boolean {
    const trimmedEmail = recipientEmail?.trim();
    const validationErrors = email(trimmedEmail);
    return isNil(validationErrors.email);
  }

  public send(): void {
    this.sendCallback(this.preparePayload());
    this.close();
  }

  protected abstract get sendCallback(): Function;

  protected abstract preparePayload(): T;
}
