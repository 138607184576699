import {Component, Input, OnDestroy} from '@angular/core';
import {isEmpty} from 'lodash-es';
import {copyTextToClipboard} from '@libs/shared/helpers/copy-to-clipboard';
import {AgencyOfferOutDto, isAgencyOffer, isFixedPrice, OfferOutDto, PackageOfferDto, SharedOfferOutDto} from '@libs/shared/models/offer.model';

@Component({
  selector: 'staffnow-share-offer',
  templateUrl: './share-offer-component.html',
  styleUrls: ['share-offer-component.scss']
})
export class ShareOfferComponent implements OnDestroy {
  @Input() public offer: OfferOutDto | SharedOfferOutDto | AgencyOfferOutDto | PackageOfferDto = null;
  public shareClicked = false;
  private interval = null;

  get shareUrl(): string {
    return this.offer?.shareLink;
  }

  get canShare(): boolean {
    if (isAgencyOffer(this.offer) || isFixedPrice(this.offer)) {
      const offer = this.offer as any;
      return (
        !offer?.isClosed &&
        !isEmpty(offer?.shareLink)
      );
    } else {
      const offer = this.offer as OfferOutDto | SharedOfferOutDto;
      return (
        offer?.isPublic &&
        !this.offer?.isClosed &&
        !isEmpty(offer?.shareLink)
      );
    }
  }

  public copyShareOfferUrlToClipboard(): void {
    clearInterval(this.interval);
    copyTextToClipboard(this.shareUrl);
    this.shareClicked = true;
    this.interval = setInterval(() => (this.shareClicked = false), 2000);
  }

  public ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
