import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../../../../../apps/staffnow-platform/src/app/state/app-state.model';
import { selectMroApprovals } from '../../../../../../apps/staffnow-platform/src/app/state/app.selectors';
import { ModalService } from '../../services/modal.service';
import { AmeTitle } from '@libs/shared/models/ame-title.model';
import { map } from 'rxjs/operators';
import { cloneDeep, flatten } from 'lodash-es';
import { GetMroApprovals } from '../facility-profile.actions';
import {
  AgencyFacility,
  SimpleFacilityWithUuid
} from '@libs/shared/models/facility.model';
import { SpecificMarkup } from '@libs/shared/models/specific-markup.model';
import {
  getEmbeddedResource,
  hasEmbeddedResource
} from '@libs/shared/bms-common/rest/resource.utils';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';

@Component({
  selector: 'staffnow-markups',
  templateUrl: './markups.component.html',
  styleUrls: ['./markups.component.scss']
})
export class MarkupsComponent implements OnInit, OnChanges {
  @Input() facilityProfile: AgencyFacility = null;
  @Output() markupsChanged = new EventEmitter<void>();

  mroApprovals$: Observable<SimpleFacilityWithUuid[]>;
  ameTitles: AmeTitle[];
  newSpecificMarkup: SpecificMarkup;

  private ameLicenseTypes: AmeLicenseType[] = [];

  get specificMarkups(): SpecificMarkup[] {
    return this.facilityProfile?.specificMarkups || [];
  }

  get specificMarkupsMros(): string[] {
    return [
      ...new Set(this.specificMarkups.map(entry => entry.mro.name).sort())
    ];
  }

  public getMarkupsForMro(mroName: string): SpecificMarkup[] {
    return this.specificMarkups.filter(entry => entry.mro.name == mroName);
  }

  private static newMarkupEntry(): SpecificMarkup {
    return { mro: null, ameTitle: null, markup: 0 };
  }

  private static allMroOption(): SimpleFacilityWithUuid {
    return { uuid: null, name: 'All Aviation Companies', _links: null };
  }

  private static anyTitleOption(): AmeTitle {
    return { id: null, type: 'Any', license: 'Any', label: 'Any' };
  }

  constructor(
    private store: Store<AppState>,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.clearNewMarkupEntry();
    this.store.dispatch(GetMroApprovals());
  }

  ngOnChanges() {
    this.initSelectOptions();
    this.initMarkupEntries();
  }

  private clearNewMarkupEntry() {
    this.newSpecificMarkup = MarkupsComponent.newMarkupEntry();
  }

  private initSelectOptions() {
    this.mroApprovals$ = this.store.pipe(selectMroApprovals).pipe(
      map(mroApprovals => {
        const options = [MarkupsComponent.allMroOption(), ...mroApprovals];
        return options.sort((a, b) => a.name.localeCompare(b.name));
      })
    );
    this.ameTitles = [
      MarkupsComponent.anyTitleOption(),
      ...this.getAmeTitlesForAgencyMarkup()
    ];
  }

  private initMarkupEntries() {
    this.facilityProfile.specificMarkups = cloneDeep(
      this.facilityProfile.specificMarkups
    );
    this.specificMarkups.forEach(specificMarkup => {
      if (specificMarkup.ameTitle) {
        specificMarkup.ameTitle.label =
          specificMarkup.ameTitle.type +
          ' ' +
          (specificMarkup.ameTitle.license
            ? specificMarkup.ameTitle.license
            : '');
      } else {
        specificMarkup.ameTitle = MarkupsComponent.anyTitleOption();
      }
      if (!specificMarkup.mro) {
        specificMarkup.mro = MarkupsComponent.allMroOption();
      }
    });
  }

  public addNewMarkupEntry() {
    const entryIndex = this.getMarkupIndex(this.newSpecificMarkup);
    if (entryIndex != -1) {
      this.specificMarkups[entryIndex] = this.newSpecificMarkup;
    } else {
      this.specificMarkups.push(this.newSpecificMarkup);
    }
    this.clearNewMarkupEntry();
    this.notifyMarkupChange();
  }

  private getMarkupIndex(specificMarkup: SpecificMarkup) {
    return this.specificMarkups.findIndex(
      entry =>
        entry.mro.uuid === specificMarkup.mro.uuid &&
        entry.ameTitle?.label === specificMarkup.ameTitle.label
    );
  }

  public validateMarkupInEntries(entry: SpecificMarkup) {
    this.validateEntry(entry);
    this.notifyMarkupChange();
  }

  public validateEntry(entry: SpecificMarkup) {
    if (!entry.markup && entry.markup !== 0) {
      return;
    }
    // I don't know why, but this timeout is necessary for this to work
    setTimeout(() => {
      if (Number(entry.markup) < 0) {
        entry.markup = 0;
      }
      if (Number(entry.markup) > 100) {
        entry.markup = 100;
      }
    }, 0);
  }

  public deleteEntry(entry: SpecificMarkup) {
    this.modalService.openConfirmModal('SYSTEM.INFO.DELETE_MARKUP', () => {
      this.specificMarkups.splice(this.getMarkupIndex(entry), 1);
      this.notifyMarkupChange();
    });
  }

  public notifyMarkupChange() {
    this.markupsChanged.emit();
  }

  markupChanged() {
    if (!this.facilityProfile.markUp) {
      return;
    }

    setTimeout(() => {
      if (this.facilityProfile.markUp >= 100) {
        this.facilityProfile = { ...this.facilityProfile, markUp: 100 };
        return;
      } else if (Number(this.facilityProfile.markUp) < 0) {
        this.facilityProfile = { ...this.facilityProfile, markUp: 0 };
        return;
      }

      const test = this.facilityProfile.markUp.toString();
      const decimals = test.split('.');
      if (decimals[0].length !== 0 && decimals[1] && decimals[1].length > 2) {
        this.facilityProfile.markUp = Number(
          `${decimals[0]}.${decimals[1].substring(0, 2)}`
        );
      }
    }, 0);
    this.markupsChanged.emit();
  }

  canAddNewMarkupEntry() {
    return (
      this.newSpecificMarkup.mro !== null &&
      this.newSpecificMarkup.ameTitle !== null &&
      !(
        this.newSpecificMarkup.mro.uuid === null &&
        this.newSpecificMarkup.ameTitle.label === 'Any'
      )
    );
  }

  private getAmeTitlesForAgencyMarkup(): AmeTitle[] {
    if (
      !hasEmbeddedResource(
        this.facilityProfile,
        FacilityProfileLinkRel.AmeNomenclature
      )
    ) {
      return [];
    }
    this.ameLicenseTypes = getEmbeddedResource(
      this.facilityProfile,
      FacilityProfileLinkRel.AmeNomenclature
    );
    return flatten(
      this.ameLicenseTypes.map(ameLicenseType =>
        ameLicenseType.licenses.map(license => ({
          id: license.id,
          type: ameLicenseType.name,
          license: license.name,
          label: ameLicenseType.name + ' ' + (license.name ? license.name : '')
        }))
      )
    );
  }
}
