// Export to clipboard functionality.
// It is implemented like this in order to
// cover all browsers supported by this software
// If navigator.clipboard is not present in the window object
// a fallback method is called.
export function copyTextToClipboard(text: string) {
  if (navigator.hasOwnProperty('clipboard')) {
    navigator['clipboard'].writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  } else {
    fallbackCopyTextToClipboard(text);
  }
}
function fallbackCopyTextToClipboard(text: string) {
  let textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed'; //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    let msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
