import {createAction, props} from "@ngrx/store";
import {PerformanceFeedbackRequestPayload} from "./performance-feedback-request.model";

export const SendPerformanceFeedbackRequest = createAction(
  '[User Profile] SendPerformanceFeedbackRequest',
  props<{ url: string, payload: PerformanceFeedbackRequestPayload }>()
);

export const SendPerformanceFeedbackRequestFail = createAction(
  '[User Profile] SendPerformanceFeedbackRequestFail'
);

export const SendPerformanceFeedbackRequestSuccess = createAction(
  '[User Profile] SendPerformanceFeedbackRequestSuccess'
);

