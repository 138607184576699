import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { getEmbeddedResource, getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';
import { UserProfile } from '@libs/shared/models/technician-profile.model';
import { CORNER_CARD_TEXT_TYPE_ENUM } from '@libs/common-ui/corner-card-text/corner-card.component';

@Component({
  selector: 'staffnow-agency-offer-contract-card-with-head',
  templateUrl: './agency-offer-contract-card-with-head.component.html',
  styleUrls: ['./agency-offer-contract-card-with-head.component.scss']
})
export class AgencyOfferContractCardWithHeadComponent {
  @Input() public contract: AgencyOfferContractOutDto = null;

  @Output() public openPublicProfile: EventEmitter<string> = new EventEmitter();
  protected readonly CORNER_CARD_TEXT_TYPE_ENUM = CORNER_CARD_TEXT_TYPE_ENUM;


  @HostBinding('class.expired') get expired() {
    return this.contract?.isAgencyOfferExpired;
  }

  constructor(private customNavigationService: CustomNavigationService) {}

  get technicianName(): string {
    const profile = getEmbeddedResource(this.contract, UserProfileLinkRel.Profile) as UserProfile;
    return profile?.firstName + ' ' + profile?.lastName;
  }

  get technicianTitles(): string {
    return this.contract?.technicianTitles.join(', ');
  }

  get technicianHasProfilePicture(): boolean {
    return hasLink(this.contract, UserProfileLinkRel.ProfilePicture);
  }

  get technicianPictureUrl(): string {
    return `url('${getUrl(this.contract, UserProfileLinkRel.ProfilePicture)}')`;
  }

  get doesAgencyUserHaveAccessToTechnician(): boolean {
    return !!this.contract.doesAgencyUserHaveAccessToTechnician;
  }

  public viewProfile() {
    if (this.doesAgencyUserHaveAccessToTechnician) {
      this.customNavigationService.openNewTab([
        'profile',
        this.contract.technicianUserUuid
      ]);
    } else {
      this.openPublicProfile.emit(EntityTypes.TECHNICIAN);
    }
  }
}
