import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizedUserProfileModel } from '@libs/shared/models/authorized-user-profile.model';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { getLoggedInUserProfile } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { filter } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

@Directive()
export abstract class AbstractCustomNavigationService implements OnDestroy {
  private history: string[] = [];
  private subscriptions: Subscription = new Subscription();
  protected authorizedUser: AuthorizedUserProfileModel = null;

  protected constructor(private store: Store<any>, protected router: Router) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.authorizedUser = null;
    this.history = [];
  }

  init(): void {
    this.subscriptions.add(
      this.store.pipe(getLoggedInUserProfile).subscribe(authorizedUser => (this.authorizedUser = authorizedUser))
    );
    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => this.history.push(event.urlAfterRedirects))
    );
  }

  openNewTab(path: string[]): void {
    const baseUrl: string = window.location.href.replace(this.router.url, '');
    const relativeUrl: string = this.router.serializeUrl(this.router.createUrlTree(path));
    window.open(baseUrl + relativeUrl, '_blank');
  }

  protected popHistory(): string {
    return this.history.pop();
  }

  resetHistory(): void {
    this.history = [];
  }

  public goBack(): void {
    let currentPath = this.popHistory();
    if (isEmpty(this.history)) {
      this.goToDefaultView();
    } else {
      if (currentPath && currentPath.includes('/contracts')) {
        while (!currentPath.includes('request-overview')) {
          currentPath = this.popHistory();
        }
      }
      this.router.navigateByUrl(this.popHistory());
    }
  }

  abstract goToDefaultView(): void;

  private isReturningToJOAndApp(currentPath: Array<string>): boolean {
    return currentPath[currentPath.length - 2]?.includes('Job Openings and Applications');
  }
}
