import { Component, HostBinding, Input } from '@angular/core';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';

@Component({
  selector: 'facility-logo',
  templateUrl: './facility-logo.component.html',
  styleUrls: ['./facility-logo.component.scss']
})
export class FacilityLogoComponent {
  @Input() public resource: Resource = null;
  @Input() @HostBinding('class') public size: 'small' | 'medium' | 'large' =
    'medium';
  @Input() public greenBackground: boolean = false;

  get facilityLogo(): string {
    return hasLink(this.resource, JobOfferLinkRel.FacilityLogo)
      ? getUrl(this.resource, JobOfferLinkRel.FacilityLogo)
      : null;
  }
}
