import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';

import { OverviewEntries } from '@platform/shared/constants/overview-entries.enum';
import { RegistrationComponent } from './modules/registration/components/registration.component';
import { RoleRedirectComponent } from '@libs/auth/components/role-redirect.component';
import { AgencyRequestOverviewWrapperComponent } from './modules/agency-request-overview-wrapper/components/agency-request-overview-wrapper/agency-request-overview-wrapper.component';
import { OwnTechniciansComponent } from './modules/agency-request-overview-wrapper/components/own-technicians/own-technicians.component';
import { HelpWrapperComponent } from './modules/help-wrapper/help-wrapper.component';
import { TechnicianOfferDetailsComponent } from './modules/request-overview/components/offer-details/technician-offer-details/technician-offer-details.component';
import { OwnTechnicianOfferDetailsComponent } from './modules/request-overview/components/offer-details/technician-offer-details/own-technician-offer-details.component';
import { AuthenticationGuardService } from '@libs/auth/services/authentication-guard.service';
import { FacilityProfilePageComponent } from '@libs/common-ui/facility-profile/facility-profile-page.component';
import { NotFoundPageComponent } from '@libs/common-ui/not-found-page/not-found-page.component';
import { MailboxComponent } from '@libs/mailbox/mailbox.component';
import { UserProfileComponent } from '@libs/user-profile/user-profile.component';
import { UserProfileResolver } from '@libs/user-profile/user-profile.resolver';
import { LooseChangesGuard } from '@libs/shared/services/loose-changes-guard.service';
import { HasAccessGuardService } from '@libs/shared/services/has-access-guard.service';
import { IsAgencyGuardService } from '@libs/shared/services/is-agency-guard.service';
import { ReportsResolverService } from '@libs/shared/services/reports-resolver.service';
import { IsMroGuardService } from '@libs/shared/services/is-mro-guard.service';
import { IsTechnicianGuardService } from '@libs/shared/services/is-technician-guard.service';
import { ActivatedViewRouterStateSerializer } from '@libs/shared/bms-common/navigation/activated-view-router-state-serializer';
import { ReportsPlatformListComponent } from './modules/reports-platform/reports-platform-list/reports-platform-list.component';
import { CreateOfferPageComponent } from '../../../../libs/offer-management/create-offer-page/src/lib/create-offer-page.component';
import TasksViewComponent from './modules/my-tasks/components/task-view/tasks-view.component';
import { CreatePackageOfferComponent } from '@libs/package-offer/components/create-package-offer/create-package-offer.component';
import { RequestOverviewWrapperComponent } from './modules/request-overview/components/request-overview-wrapper/request-overview-wrapper.component';
import { MroPackageOfferDetailsComponent } from './modules/request-overview/components/offer-details/mro-package-offer-details/mro-package-offer-details.component';
import { AgencyPackageOfferDetailsComponent } from './modules/request-overview/components/offer-details/agency-package-offer-details/agency-package-offer-details.component';
import { TechnicianPackageOfferDetailsComponent } from './modules/request-overview/components/offer-details/technician-package-offer-details/technician-package-offer-details.component';
import { ResendVerificationEmailComponent } from './modules/verify-email/components/resend-verification-email/resend-verification-email.component';
import { VerifyEmailComponent } from './modules/verify-email/components/verify-email/verify-email.component';
import { CreateAgencyOfferPageComponent } from '@libs/create-agency-offer-page/create-agency-offer-page.component';
import { MroJobOfferDetailsComponent } from './modules/request-overview/components/offer-details/facility-job-offer-details/mro-job-offer-details.component';
import { AgencyOfferDetailsComponent } from './modules/request-overview/components/offer-details/agency-offer-details/agency-offer-details.component';
import { AgencyJobOfferDetailsComponent } from './modules/request-overview/components/offer-details/facility-job-offer-details/agency-job-offer-details.component';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { OfferType } from '@libs/shared/models/offer.model';
import { TechnicianAgencyOfferDetailsComponent } from './modules/request-overview/components/offer-details/agency-offer-details/technician-agency-offer-details.component';
import { UnauthorizedPageComponent } from '@libs/common-ui/unauthorized-page/unauthorized-page.component';
import { RequestChangePasswordComponent } from '@libs/common-ui/password-management/request-change-password/request-change-password.component';
import { ChangePasswordComponent } from '@libs/common-ui/password-management/change-password/change-password.component';
import { UnauthorizedAppComponent } from './modules/registration/components/unauthorized-app/unauthorized-app.component';
import { WrongAppComponent } from './modules/registration/components/wrong-app/wrong-app.component';
import { UpdateAppComponent } from '@libs/common-ui/update-app/update-app.component';
import { CreatePermanentOfferPageComponent } from '../../../../libs/offer-management/create-permanent-offer/src/lib/create-permanent-offer-page.component';
import { MroPermanentJobOpeningDetailsComponent } from './modules/request-overview/components/offer-details/mro-permanent-job-opening-details/mro-permanent-job-opening-details.component';
import { TechnicianPermanentOfferDetailsComponent } from './modules/request-overview/components/offer-details/mro-permanent-job-opening-details/technician-permanent-offer-details.component';

export const routes: Routes = [
  {
    path: '',
    component: RoleRedirectComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'platform'
    },
    pathMatch: 'full'
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthenticationGuardService],
    canDeactivate: [LooseChangesGuard],
    data: {
      application: 'platform'
    },
    resolve: {
      profile: UserProfileResolver
    }
  },
  {
    path: 'profile/:userUuid',
    component: UserProfileComponent,
    canActivate: [AuthenticationGuardService],
    canDeactivate: [LooseChangesGuard],
    data: {
      application: 'platform'
    },
    resolve: {
      profile: UserProfileResolver
    }
  },
  {
    path: 'technician-overview',
    loadChildren: () => import('./modules/search-overview/search-overview.module').then(m => m.SearchOverviewModule),
    canActivate: [AuthenticationGuardService, HasAccessGuardService],
    data: {
      accessLinkRel: ApiRootLinkRel.GetTechnicians,
      entry: OverviewEntries.Technicians
    }
  },
  {
    path: 'job-overview',
    loadChildren: () => import('./modules/search-overview/search-overview.module').then(m => m.SearchOverviewModule),
    canActivate: [AuthenticationGuardService, HasAccessGuardService],
    data: {
      accessLinkRel: ApiRootLinkRel.SearchJobOpenings,
      entry: OverviewEntries.Jobs
    }
  },
  {
    path: 'request-overview',
    component: RequestOverviewWrapperComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService],
    data: {
      tabs: [
        {
          label: `OFFER.TYPES.TEMPORARY.JOB.OPENING.LABEL`,
          offerType: OfferType.TEMPORARY
        },
        {
          label: 'OFFER.TYPES.PERMANENT.JOB.OPENING.LABEL',
          offerType: OfferType.PERMANENT
        },
        {
          label: 'OFFER.TYPES.FIXED_PRICE.JOB.OPENING.LABEL',
          offerType: OfferType.FIXED_PRICE
        }
      ]
    }
  },
  {
    path: 'vacancy-overview',
    component: RequestOverviewWrapperComponent,
    canActivate: [AuthenticationGuardService, IsTechnicianGuardService],
    data: {
      tabs: [
        {
          label: `OFFER.TYPES.TEMPORARY.JOB.OPENING.LABEL`,
          offerType: OfferType.TEMPORARY
        },
        {
          label: 'OFFER.TYPES.PERMANENT.JOB.OPENING.LABEL',
          offerType: OfferType.PERMANENT
        },
        {
          label: 'OFFER.TYPES.FIXED_PRICE.JOB.OPENING.LABEL',
          offerType: OfferType.FIXED_PRICE
        },
        {
          label: 'OFFER.TYPES.AGENCY.JOB.OPENING.LABEL',
          offerType: OfferType.AGENCY
        }
      ]
    }
  },
  {
    path: 'agency-request-overview',
    component: AgencyRequestOverviewWrapperComponent,
    //TODO(SN-982): This module should be loaded lazily but there was a bug with it
    // A routing module for it is present in agency-request-overview-wrapper
    // but does not behave as it should
    // loadChildren:
    //   './modules/agency-request-overview-wrapper/agency-request-overview-wrapper.module#AgencyRequestOverviewWrapperModule',
    canActivate: [AuthenticationGuardService, IsAgencyGuardService],
    data: {
      accessLinkRel: ApiRootLinkRel.AviationCompaniesJobOpenings
    }
  },
  {
    path: 'own-technicians',
    component: OwnTechniciansComponent,
    canActivate: [AuthenticationGuardService, IsAgencyGuardService]
  },
  {
    path: 'mros',
    loadChildren: () => import('./modules/facilities/facilities.module').then(m => m.FacilitiesModule),
    canActivate: [AuthenticationGuardService, HasAccessGuardService],
    data: {
      accessLinkRel: ApiRootLinkRel.GetAviationCompanyListForAdvertisement
    }
  },
  {
    path: 'register',
    component: RegistrationComponent
  },
  {
    path: 'redirectPlatform',
    redirectTo: '/register?redirectToOtherPlatform=true'
  },
  {
    path: 'request-new-password',
    component: RequestChangePasswordComponent
  },
  {
    path: 'reset-password',
    component: ChangePasswordComponent
  },
  {
    path: 'resend-verification-email',
    component: ResendVerificationEmailComponent
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent
  },
  {
    path: 'mailbox/:id',
    component: MailboxComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'mailbox',
    component: MailboxComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'help',
    component: HelpWrapperComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'facility',
    component: FacilityProfilePageComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'offer',
    component: CreateOfferPageComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'permanent-offer',
    component: CreatePermanentOfferPageComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'package-offer',
    component: CreatePackageOfferComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'package-offer/edit/:refNumber',
    component: CreatePackageOfferComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'agency-offer/:packageContractId',
    component: CreateAgencyOfferPageComponent,
    canActivate: [AuthenticationGuardService, IsAgencyGuardService]
  },
  {
    path: 'reports',
    component: ReportsPlatformListComponent,
    canActivate: [AuthenticationGuardService],
    resolve: {
      links: ReportsResolverService
    }
  },
  {
    path: 'sharing/offers/:region/:offerRef',
    component: RegistrationComponent
  },
  {
    path: 'sharing/offers/agency/:region/:offerRef',
    component: RegistrationComponent
  },
  {
    path: 'sharing/offers/fixed-price/:region/:offerRef',
    component: RegistrationComponent
  },
  {
    path: 'platform/sharing/offers/:region/:offerRef', //this was added because old offers were published with /platform
    component: RegistrationComponent
  },
  {
    path: 'contracts/:offerRefNumber',
    component: MroJobOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'permanent-contracts/:offerRefNumber',
    component: MroPermanentJobOpeningDetailsComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'agency-offer-contracts/:agencyOfferRefNumber',
    component: AgencyOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsAgencyGuardService]
  },
  {
    path: 'package-contracts/:offerRefNumber',
    component: MroPackageOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'agency-contracts/:offerRefNumber',
    component: AgencyJobOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsAgencyGuardService]
  },
  {
    path: 'agency-package-contracts/:offerRefNumber',
    component: AgencyPackageOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsAgencyGuardService]
  },
  {
    path: 'own-technician-process',
    component: OwnTechnicianOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsAgencyGuardService]
  },
  {
    path: 'process/:offerRefNumber',
    component: TechnicianOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsTechnicianGuardService]
  },
  {
    path: 'permanent-offer/process/:offerRefNumber',
    component: TechnicianPermanentOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsTechnicianGuardService]
  },
  {
    path: 'package-offer/process/:offerRefNumber',
    component: TechnicianPackageOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsTechnicianGuardService]
  },
  {
    path: 'agency-offer/process/:agencyOfferRefNumber',
    component: TechnicianAgencyOfferDetailsComponent,
    canActivate: [AuthenticationGuardService, IsTechnicianGuardService]
  },
  {
    path: 'my-tasks',
    component: TasksViewComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'platform',
    redirectTo: '/register'
  },
  {
    path: 'dashboards',
    loadChildren: () =>
      import('apps/staffnow-platform/src/app/modules/dashboards/dashboards.module').then(m => m.DashboardsModule),
    canActivate: [AuthenticationGuardService, IsMroGuardService]
  },
  {
    path: 'experience-confirmation/:requestId',
    loadChildren: () =>
      import('./modules/public-platform-requests/experience-confirmation/experience-confirmation.module').then(
        m => m.ExperienceConfirmationModule
      )
  },
  {
    path: 'technician-performance-feedback/:requestId',
    loadChildren: () =>
      import('./modules/public-platform-requests/technician-performance-feedback/technician-performance.module').then(
        m => m.TechnicianPerformanceModule
      )
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent
  },
  {
    path: 'unauthorized-app',
    component: UnauthorizedAppComponent
  },
  {
    path: 'wrong-app',
    component: WrongAppComponent
  },
  {
    path: 'update-app',
    component: UpdateAppComponent
  },
  {
    path: 'unauthorized-app',
    component: UnauthorizedAppComponent
  },
  {
    path: 'wrong-app',
    component: WrongAppComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  },
  {
    path: '404',
    component: NotFoundPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      useHash: false,
      scrollPositionRestoration: 'enabled'
    }),
    StoreRouterConnectingModule
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: ActivatedViewRouterStateSerializer
    }
  ]
})
export class AppRoutingModule {}
