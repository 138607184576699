<div class="quality-accept-container">
    <i class="material-icons close-icon" (click)="bsModalRef.hide()"> clear </i>
    <div class="header">
        <h1 class="heading">Accept Technician</h1>
        <p class="text-muted sub-heading">
            You can choose to leave a note for this approval. It will be visible
            in the notification email of both the Technician and the selected
            Agency.
        </p>
    </div>
    <div class="content">
        <div class="notes-container">
            <label class="notes-label" for="notes">Notes:</label>
            <textarea
                id="notes"
                name="notes"
                class="form-control notes"
                rows="8"
                autofocus
                [(ngModel)]="notes"
                [maxlength]="textAreaMaxLength"
                (input)="charactersCounter($event)"
            >
            </textarea>
            <small class="counter"
                ><span>{{ textAreaCharacterCounter }}</span> /
                {{ textAreaMaxLength }}</small
            >
        </div>
        <general-button
            (onClick)="submit()"
            text="Submit"
            class="button-custom"
        ></general-button>
    </div>
</div>
