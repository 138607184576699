import {Component, EventEmitter, Input, Output} from "@angular/core";
import {DateTime} from "luxon";
import {DateAndTimeConfig} from "@libs/user-profile/components/automatic-reply/automatic-reply.model";
import {dateTimeFromString} from "@libs/shared/helpers/date-utils";


@Component({
  selector: 'single-date-and-time-picker',
  templateUrl: './single-date-and-time-picker.component.html',
  styleUrls: ['./single-date-and-time-picker.component.scss']
})
export class SingleDateAndTimePickerComponent {
  @Input() set value(value: DateAndTimeConfig) {
    if (value) {
      this.time = value.time;
      this.date = DateTime.fromISO(value.date, {zone: value.ianaZone}).toSQLDate();
    }
  };

  @Input() public minimumDate: string = null;
  @Output() public change = new EventEmitter<DateAndTimeConfig>();

  public date: string = null;
  public time: string = null;
  private ianaZone: string = null;

  public handleSingleDateUpdate(date: string): void {
    this.date = date;
    this.ianaZone = dateTimeFromString(date).toFormat('z');
    this.emit();
  }

  public handleTimeChange(time: string): void {
    this.time = time;
    this.emit();
  }

  private emit() {
    this.change.emit(this.preparePayload());
  }

  private preparePayload(): DateAndTimeConfig {
    return this.time && this.date
      ? {date: this.date, time: this.time, ianaZone: this.ianaZone}
      : null;
  }

}
