<div class="content-wrapper">
    @if (pictureUrl) {
    <div class="facility-image" [ngStyle]="{ 'background-image': 'url(\'' + pictureUrl + '\')' }"></div>
    } @else {
    <facility-cover-placeholder></facility-cover-placeholder>
    }

    <facility-logo [resource]="facility" size="large"></facility-logo>
    <p class="facility-title">{{ facilityName }}</p>
    @if (offerPriority) {
    <staffnow-offer-priority-info [priority]="offerPriority"></staffnow-offer-priority-info>
    }
    <staffnow-share-offer [offer]="offer"></staffnow-share-offer>
    @if (applicationExists) {
    <div class="banner-wrapper">
        <staffnow-information-banner
            direction="vertical"
            [message]="applicationExistsMessage"
        ></staffnow-information-banner>
    </div>
    }
    <div class="other-info">
        <!--Location-->
        <div>
            <i class="material-icons" [tooltip]="'SYSTEM.INFO.LOCATION' | translate"> location_on </i>
            <span>{{ facilityLocation }}</span>
        </div>
        <!--Aircrafts-->
        <div>
            <i class="material-icons" [tooltip]="'SYSTEM.INFO.AIRCRAFTS' | translate">airplanemode_active</i>
            <staffnow-offer-aircrafts-field
                [offer]="offer"
                [isSharedOfferDto]="isSharedOfferDto"
            ></staffnow-offer-aircrafts-field>
        </div>
        <!--Period-->
        @if (offer.periodFrom && offer.periodTo) {
        <div>
            <i class="material-icons" [tooltip]="'SYSTEM.INFO.TIMEFRAME' | translate">date_range</i>
            {{ offer.periodFrom | absoluteDateFormat }} to {{ offer.periodTo | absoluteDateFormat }}
        </div>
        } @if (offer?.startDate) {
        <!--Start date-->
        <div>
            <i class="material-icons" [tooltip]="'SYSTEM.INFO.START_DATE' | translate">date_range</i>
            {{ offer.startDate | absoluteDateFormat }}
        </div>
        } @if (isFixedPriceOffer) { @if (offer.specification) {
        <div>
            <i class="material-icons" tooltip="Technical specification">speaker_notes</i>
            <read-more [content]="offer.specification"></read-more>
        </div>
        } } @else {
        <ng-template *ngTemplateOutlet="temporaryAndPermanentAndAgencyJobsTemplate"></ng-template>
        }
    </div>
    <!--Presentation-->
    @if (!isFixedPriceOffer) {
    <div class="presentation-info">
        <staffnow-offer-info-section
            [title]="'SYSTEM.INFO.SHORT_DESCRIPTION' | translate"
            [icon]="'add_circle_outline'"
        >
            <read-more [content]="offer.shortPresentation"></read-more>
        </staffnow-offer-info-section>
    </div>
    } @if(offer?.certifications?.length > 0) {
    <div class="certifications-info">
        <staffnow-offer-info-section
            [title]="'GENERAL.CERTIFICATION_REQUIREMENTS' | translate"
            [icon]="'add_circle_outline'"
        >
            @for (certification of offer?.certifications; track certification) {
            {{ certification }} <br />
            }
        </staffnow-offer-info-section>
    </div>
    } @if (!isFixedPriceOffer && !offer?.hasIndicativeRate) {
    <div class="benefits-info">
        <staffnow-offer-info-section [title]="'GENERAL.ADDITIONAL_BENEFITS' | translate" [icon]="'add_circle_outline'">
            <staffnow-offer-benefit-details [benefits]="offer?.additionalBenefits"></staffnow-offer-benefit-details>
        </staffnow-offer-info-section>
    </div>
    }
</div>

<div class="additional-content">
    <ng-content select="[additional-content]"></ng-content>
</div>

<ng-template #temporaryAndPermanentAndAgencyJobsTemplate>
    <!--Work pattern-->
    <div>
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.WORK_PATTERN' | translate">work</i>
        <work-pattern [daysOn]="offer.workDaysOn" [daysOff]="offer.workDaysOff"></work-pattern>
    </div>
    <!--Technician-->
    @if (offer.ameTitle) {
    <div>
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.TECHTYPE' | translate"> build </i>
        {{ offer.ameTitle | ameTitleName }}
    </div>
    }
    <!--Pay rate-->
    @if (!offer.hasHiddenRate) {
    <div>
        @switch (offer.offerType) { @case (OFFER_TYPE.AGENCY) {
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.PAY_RANGE' | translate"> settings_ethernet </i>
        @if (offer.hasIndicativeRate) {
        {{ offer?.payRate | rate }} -
        {{ offer?.maxPayRate | rate }}
        {{ ' per hour' | translate }}
        } @else {
        {{ offer?.payRate | rate }}
        {{ ' per hour' | translate }}
        } } @case (OFFER_TYPE.TEMPORARY) {
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.PAY_RANGE' | translate"> settings_ethernet </i>
        @if (offer.payRate || offer.payRate?.amount === 0) {
        {{ offer?.payRate | rate }}
        {{ ' per hour' | translate }}
        } @else {
        {{ offer?.minPayRate | rate }} -
        {{ offer?.maxPayRate | rate }}
        {{ ' per hour' | translate }}
        } } @default {
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.MONTHLY_SALARY' | translate"> settings_ethernet </i>
        {{ offer.monthlySalary | rate }}
        {{ ' per month' | translate }}
        } }
    </div>
    }
    <!--Experience-->
    <div>
        <i class="material-icons" [tooltip]="'SYSTEM.INFO.EXPERIENCE' | translate">av_timer</i>
        {{ (offer.minExperience || 0) + ' years of experience required' | translate }}
    </div>
</ng-template>
