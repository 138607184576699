import {Component, Input} from '@angular/core';
import {AbstractButton} from "@libs/common-ui/buttons/components/abstract.button";

@Component({
  selector: 'borderless-button',
  templateUrl: './borderless-button.component.html',
  styleUrls: ['./borderless-button.component.scss']
})
export class BorderlessButtonComponent extends AbstractButton {
  @Input() public icon: string = null;
  @Input() public color: 'green' | 'dark' | 'red' | 'green-celadon' | 'white' = 'green';
  @Input() public actionType: 'regular' | 'danger' = 'regular';
  @Input() public size: 'small' | 'regular' = 'regular';
  @Input() public textDecoration: string = 'none';

  public getIconClasses(): string[] {
    const classes: string[] = this.getCommonClasses();
    classes.push('material-icons', 'icon', this.actionType, this.size);
    return classes;
  }

  public getTextClasses(): string[] {
    const classes: string[] = this.getCommonClasses();
    classes.push('text', this.size)
    return classes;
  }

  private getCommonClasses(): string[] {
    const classes: string[] = ['clickable', this.color];
    if (this.isDisabled) {
      classes.push('disabled');
    }
    return classes;
  }

}
