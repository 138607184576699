import {
  TechnicianRegistrationFormModel,
  initialTechnicianFormState
} from '../components/technician-registration-form/technician-registration-form.model';
import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  setErrors,
  updateGroup,
  validate
} from 'ngrx-forms';
import {
  initialState as regComponentInitialState,
  RegistrationComponentState
} from '../components/registration-state.model';
import { email, required } from 'ngrx-forms/validation';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ResetDisplayOptions,
  ResetMroCompanyDetailsForm,
  ResetSelectedAgenciesList,
  UpdateAdvertiseOpenPositions,
  UpdateCompanyImage,
  UpdateCompanyLogo,
  UpdateCompanyPromoMaterials,
  UpdateSelectedAgenciesList,
  UpdateTermsAgreement
} from './registration.actions';

export const REGISTRATION_FEATURE_KEY = 'registration';

export interface MroCompanyDetails {
  companyName: string;
  requesterName: string;
  requesterLastName: string;
  contactPhone: string;
  requesterEmail: string;
  requesterPosition: string;
  locationId: number;
  requiresProductionAcceptance: boolean;
  requiresQualityAcceptance: boolean;
  requiresHumanResourcesAcceptance: boolean;
  userRole: string;
}

export interface MroSelectedAgencies {
  selectedAgencies: string[];
}

export interface MroDisplayOptions {
  allowToAdvertiseOpenPositions: boolean | null;
  allowToDisplayCompanyLogo: boolean | null;
  allowToDisplayPromotionalMaterial: boolean | null;
  termsAgreement: boolean | null;
  image?: File;
}

export interface MroRegistrationData
  extends MroCompanyDetails,
    MroSelectedAgencies,
    MroDisplayOptions {}

export interface RegistrationState {
  technicianRegistrationForm?: FormGroupState<TechnicianRegistrationFormModel>;
  registerComponent?: RegistrationComponentState;
  mroCompanyDetails?: FormGroupState<MroCompanyDetails>;
  mroSelectedAgencies?: MroSelectedAgencies;
  mroDisplayOptions?: MroDisplayOptions;
}

export const mroRegistrationCompanyDetailsInitialFormState = createFormGroupState<MroCompanyDetails>(
  'mroCompanyDetailsForm',
  {
    companyName: null,
    contactPhone: null,
    requesterName: null,
    requesterLastName: null,
    requesterEmail: null,
    requesterPosition: null,
    locationId: null,
    requiresProductionAcceptance: false,
    requiresQualityAcceptance: false,
    requiresHumanResourcesAcceptance: false,
    userRole: null
  });

const updateMyFormGroup = updateGroup<MroCompanyDetails>({
  companyName: validate<string>(required),
  requesterName: validate<string>(required),
  requesterLastName: validate<string>(required),
  requesterEmail: validate<string>(required, email),
  requesterPosition: validate<string>(required),
  locationId: validate<number>(required),
  userRole: validate<string>(required)
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function mroRegistrationCompanyDetailsFormReducer(
  state: FormGroupState<MroCompanyDetails> = mroRegistrationCompanyDetailsInitialFormState,
  action: Action
): FormGroupState<MroCompanyDetails> {
  if (action.type === ResetMroCompanyDetailsForm.type) {
    state = mroRegistrationCompanyDetailsInitialFormState;
  }

  let myForm = formReducer(state, action);
  myForm = validateWorkflow(myForm);
  return {
    ...myForm
  };
}

export const initialState: RegistrationState = {
  technicianRegistrationForm: initialTechnicianFormState,
  registerComponent: regComponentInitialState,
  mroCompanyDetails: mroRegistrationCompanyDetailsInitialFormState,
  mroSelectedAgencies: { selectedAgencies: [] },
  mroDisplayOptions: {
    allowToAdvertiseOpenPositions: null,
    allowToDisplayCompanyLogo: null,
    allowToDisplayPromotionalMaterial: null,
    termsAgreement: null,
    image: null
  }
};

export const mroSelectedAgenciesReducer = createReducer(
  initialState.mroSelectedAgencies,
  on(UpdateSelectedAgenciesList, (state, action: any) => ({
    ...state,
    selectedAgencies: action.agencies
  })),
  on(ResetSelectedAgenciesList, (state, action: any) => ({
    ...initialState.mroSelectedAgencies
  }))
);

export const mroDisplayOptionsReducer = createReducer(
  initialState.mroDisplayOptions,
  on(UpdateCompanyImage, (state, action: any) => ({
    ...state,
    image: action.images
  })),
  on(UpdateTermsAgreement, (state, action: any) => ({
    ...state,
    termsAgreement: action.value
  })),
  on(UpdateAdvertiseOpenPositions, (state, action: any) => ({
    ...state,
    allowToAdvertiseOpenPositions: action.value
  })),
  on(UpdateCompanyLogo, (state, action: any) => ({
    ...state,
    allowToDisplayCompanyLogo: action.value
  })),
  on(UpdateCompanyPromoMaterials, (state, action: any) => ({
    ...state,
    allowToDisplayPromotionalMaterial: action.value
  })),
  on(ResetDisplayOptions, (state, action: any) => ({
    ...initialState.mroDisplayOptions
  }))
);

const validateWorkflow = (
  state: FormGroupState<MroCompanyDetails> = mroRegistrationCompanyDetailsInitialFormState
): FormGroupState<MroCompanyDetails> => {
  const {
    requiresHumanResourcesAcceptance,
    requiresProductionAcceptance,
    requiresQualityAcceptance
  } = state.controls;

  const allSelected =
    requiresHumanResourcesAcceptance.value &&
    requiresProductionAcceptance.value &&
    requiresQualityAcceptance.value;

  const isValid =
    !allSelected &&
    (requiresHumanResourcesAcceptance.value ||
      requiresProductionAcceptance.value ||
      requiresQualityAcceptance.value);
  if (!isValid) {
    return updateGroup<MroCompanyDetails>({
      requiresHumanResourcesAcceptance: setErrors({
        requiredRolesMissing: true
      })
    })(state);
  } else {
    return updateGroup<MroCompanyDetails>({
      requiresHumanResourcesAcceptance: setErrors(null)
    })(state);
  }
};
