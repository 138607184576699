<div
    class="notification"
    [ngClass]="{ unseen: !notification.isSeen }"
    (click)="handleNotificationClick()"
>
    <div class="notification-icon">
        <ng-container [ngSwitch]="notification.type">
            <ng-container *ngSwitchCase="NOTIFICATION_TYPES.STATUS_CHANGE">
                <i class="material-icons text-primary"> contacts </i>
            </ng-container>
            <ng-container *ngSwitchCase="NOTIFICATION_TYPES.OFFER_CREATED">
                <i class="material-icons text-green"> fiber_new </i>
            </ng-container>
            <ng-container *ngSwitchCase="NOTIFICATION_TYPES.CONTRACT_CHANGE">
                <i class="material-icons text-warning"> new_releases </i>
            </ng-container>
            <ng-container *ngSwitchCase="NOTIFICATION_TYPES.MRO_CREATED">
                <i class="material-icons">flight_takeoff</i>
            </ng-container>
            <ng-container *ngSwitchCase="NOTIFICATION_TYPES.MATCH">
                <i class="material-icons text-green">handshake</i>
            </ng-container>
        </ng-container>
    </div>
    <div>
        <div class="notification-content">
            <span class="notification-text">
                <ng-container
                    *ngIf="
                        notification.type !== NOTIFICATION_TYPES.STATUS_CHANGE;
                        else statusChangeText
                    "
                >
                    {{ notification.payload.text }}
                </ng-container>
                <ng-template #statusChangeText>
                    <div
                        [innerHTML]="
                            notification.payload.text
                                | profileStatusNotificationFormatter
                        "
                    ></div>
                </ng-template>
            </span>
            <span class="notification-text" *ngIf="isClickable">
                <strong>Click here</strong> for details.
            </span>
        </div>

        <div class="notification-date text-muted">
            {{ notification.createdOn | localDateWithTimeFormat }}
        </div>
    </div>
</div>
