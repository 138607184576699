import { Component, NgZone } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { AppState } from './state/app-state.model';
import { AuthenticationService } from '@libs/auth/services/authentication.service';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { AuthorizedUserProfileModel } from '@libs/shared/models/authorized-user-profile.model';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource, getUrlNullsafe, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { CookiesService } from '@libs/shared/services/cookies/cookies.service';
import { NotificationsCount, selectNotificationsCount } from '@libs/notifications/state/notifications.selectors';
import { selectFeedbackList } from './modules/feedback/state/feedback.selectors';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { Actions, ofType } from '@ngrx/effects';
import { ReloadApiRoot } from '@libs/shared/bms-common/api-root/api-root.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectAuthState } from '@libs/auth/state/authentication.selectors';
import { BrandName, EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { timer } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { buildInfo } from '../info/import-extra-json';
import { HeaderProviderService } from '@libs/auth/services/header-provider.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { Dialog } from '@capacitor/dialog';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PlatformNavbarService } from '@platform/shared/services/platform-navbar.service';
import { MenuItem } from '@libs/shared/models/menu-item.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'staffnow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isAsideMenuOpen: boolean = false;

  public apiRoot: ApiRootResource = null;
  public authorizedUserProfile: AuthorizedUserProfileModel = null;
  public user: UserProfileType;

  public menuItems: MenuItem[] = [];
  public isAppLoading: boolean = true;
  public isRedirectingToRightRegionApp: boolean = false;
  public version: string = null;
  public brandName: string = '';

  public bellNotificationsCount: number = null;
  public mailboxNotificationsCount: number = null;
  public notificationListOpened: boolean = false;
  public isFeedBackListEmpty: boolean = true;
  public timeToRedirectInSeconds = 10;

  private isAuthenticated: boolean = undefined;
  private googleRecaptchaSiteKey: string = undefined;
  private grecaptchaLoaded: boolean = false;
  private partnerAppUrl: string = null;

  get otherBrandName(): string {
    return this.brandName == BrandName.StaffNow ? BrandName.eLAUNCHNow : BrandName.StaffNow;
  }

  get otherRegionName(): string {
    return this.brandName == BrandName.StaffNow ? 'US' : 'Europe';
  }

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
    private cookiesService: CookiesService,
    private customNavigationService: CustomNavigationService,
    private activatedRoute: ActivatedRoute,
    private headerProviderService: HeaderProviderService,
    private router: Router,
    private translationService: TranslateService,
    private zone: NgZone,
    private navbarService: PlatformNavbarService
  ) {
    this.initTranslations();
    this.headerProviderService.appVersion = buildInfo.appVersion;
    this.headerProviderService.appClass = buildInfo.appClass;
    this.version = buildInfo.appVersion;
    this.customNavigationService.init();
    this.cookiesService.init();
    this.router.events.subscribe(event => {
      if (
        event instanceof NavigationEnd &&
        (event.url.includes('unauthorized-app') ||
          event.url.includes('update-app') ||
          event.url.includes('unauthorized') ||
          event.url.includes('register'))
      ) {
        this.isAppLoading = false;
        this.authorizedUserProfile = null;
      }
    });
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      if (!isNil(params.redirectToOtherPlatform)) {
        this.isAppLoading = false;
        if (isPlatformWeb()) {
          this.redirectAfterTimeOut();
        } else {
          this.router.navigate(['wrong-app']);
        }
      }
    });
    this.store.pipe(getStaticEnvironment).subscribe((envData: EnvironmentState) => {
      this.authenticationService.initForPlatform();
      this.partnerAppUrl = envData.brandConfig.partnerAppUrl;
      this.googleRecaptchaSiteKey = envData.googleRecaptchaSiteKey;
      this.loadGrecaptchaIfAllVariablesAreInitialized();
    });
    this.actions.pipe(ofType(ReloadApiRoot)).subscribe(() => {
      this.isAppLoading = true;
      this.apiRoot = null;
      this.authorizedUserProfile = null;
      this.menuItems = [];
    });
    this.store.pipe(getFilteredApiRoot, untilDestroyed(this)).subscribe(apiRoot => {
      this.apiRoot = cloneDeep(apiRoot);
      this.isAppLoading = false;
      this.authorizedUserProfile = getEmbeddedResource(this.apiRoot, ApiRootLinkRel.AuthorizedUserProfile);
      this.menuItems = this.navbarService.convertRoutesToMenuItems(this.apiRoot._links);
    });
    this.store.pipe(select(selectAuthState), untilDestroyed(this)).subscribe(authState => {
      this.isAuthenticated = authState.validTokenReceived;
      this.loadGrecaptchaIfAllVariablesAreInitialized();
      if (authState.validTokenReceived === false) {
        this.isAppLoading = false;
      }
    });
    this.store
      .pipe(select(selectFeedbackList), untilDestroyed(this))
      .subscribe(feedbackList => (this.isFeedBackListEmpty = isEmpty(feedbackList)));
    this.store
      .pipe(selectNotificationsCount, untilDestroyed(this))
      .subscribe((notificationsCount: NotificationsCount) => {
        this.bellNotificationsCount = notificationsCount.bellNotificationsCount;
        this.mailboxNotificationsCount = notificationsCount.mailboxNotificationsCount;
      });

    if (!isPlatformWeb()) {
      this.setupMobileAppNotifications();
      this.initializeAppDeepLinking();
    }
  }

  private initializeAppDeepLinking() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        let slug;
        if (this.brandName === BrandName.StaffNow) {
          slug = event.url.split('.aero').pop();
        } else {
          slug = event.url.split('.com').pop();
        }
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private setupMobileAppNotifications() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive !== 'granted') {
        Dialog.alert({
          title: this.translationService.instant('GENERAL.NOTIFICATIONS_ERROR'),
          message: this.translationService.instant('PERMISSION_FOR_NOTIFICATIONS')
        });
      } else {
        PushNotifications.register();
      }
    });
    PushNotifications.addListener('pushNotificationReceived', () => {});
    PushNotifications.addListener('pushNotificationActionPerformed', () => {});
  }

  private redirectAfterTimeOut(): void {
    this.isRedirectingToRightRegionApp = true;
    const ONE_SECOND_IN_MILLIS = 1000;
    timer(ONE_SECOND_IN_MILLIS, ONE_SECOND_IN_MILLIS)
      .pipe(
        takeWhile(() => this.timeToRedirectInSeconds > 0),
        tap(() => this.timeToRedirectInSeconds--),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.timeToRedirectInSeconds <= 1) {
          window.location.href = this.partnerAppUrl;
        }
      });
  }

  get requestTechnicianDeletionLink(): string {
    return getUrlNullsafe(this.apiRoot, ApiRootLinkRel.RequestDeletion);
  }

  private loadGrecaptchaIfAllVariablesAreInitialized() {
    if (!this.grecaptchaLoaded && this.isAuthenticated === false && !!this.googleRecaptchaSiteKey) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.googleRecaptchaSiteKey}`;
      document.head.appendChild(script);
      this.grecaptchaLoaded = true;
    }
  }

  get hasMailboxLink(): boolean {
    return hasLink(this.apiRoot, ApiRootLinkRel.Mailbox);
  }

  public toggleAsideMenu(): void {
    this.isAsideMenuOpen = !this.isAsideMenuOpen;
  }

  public isNotGivenFeedback() {
    return hasLink(this.apiRoot, ApiRootLinkRel.NotGivenFeedback);
  }

  public logoutUser(): void {
    this.authenticationService.logout();
  }

  public toggleNotificationList() {
    this.notificationListOpened = !this.notificationListOpened;
  }

  public openCookieBot(): void {
    this.cookiesService.changeSettings();
  }

  private initTranslations(): void {
    this.translationService.setDefaultLang('en');
    this.translationService.use(environment.brandConfig.brandName === BrandName.eLAUNCHNow ? 'en-us' : 'en');
  }
}
