<input
    type="text"
    [ngClass]="{ 'form-control': true, uppercase: filterConfig.upperCase }"
    [placeholder]="filterConfig.placeholder || '' | translate"
    (keydown)="isEnterPressed($event)"
    (ngModelChange)="handleChange($event)"
    [(ngModel)]="filterConfig.initialValue"
    [disabled]="filterConfig.disabled"
    [maxlength]="filterConfig.max"
/>
