<div class="task-container">
    <div class="body">
        <div class="mb-1">
            <span class="time-left mr-1" *ngIf="task.hoursLeft">
                <span class="material-icons pill-icon"> access_time_filled </span>
                {{ task.hoursLeft }}h left
            </span>
            <span class="documents-needed" *ngIf="isDocumentsUploadedByTechnician()">
                <span class="material-icons pill-icon"> insert_drive_file </span>
                Docs Added
            </span>
        </div>
        <div class="properties">
            <div class="property mr-3">
                <span class="title">
                    {{ task.title }}
                </span>
            </div>

            <div class="property primary mr-3" *ngIf="task.technicianName">
                <span><i class="material-icons">person</i></span>
                <span class="primary font-weight-bold">
                    {{ task.technicianName }}
                </span>
            </div>

            <div class="property primary mr-3" *ngIf="task.agencyName">
                <i class="material-icons mr-1">store</i>
                <span class="primary font-weight-bold">
                    {{ task.agencyName }}
                </span>
            </div>

            <div class="property secondary" *ngIf="task.offerRefNumber">
                <span><i class="material-icons">airplanemode_active</i></span>
                <span>{{ offerDescription }}</span>
            </div>

            <div class="property secondary" *ngIf="task.offerLevel">
                <span><i class="material-icons" style="font-size: 20px">build</i></span>
                <span>{{ task.offerLevel }}</span>
            </div>
        </div>
    </div>
    <div class="actions d-flex flex-column justify-content-end">
        <div class="mb-3 align-self-end calendar-icon-card" *ngIf="isStartDateProposal()">
            <span class="material-icons calendar-icon primary">date_range</span>
        </div>
        <div>
            <general-button
                *ngIf="task.links.length > 0"
                (onClick)="ignoreTask()"
                backgroundStyle="outline"
                class="mb-2 action-button"
                color="secondary"
                size="small"
                icon="visibility_off"
                iconOutlined="true"
                text="{{ 'GENERAL.HIDE' | translate }}"
            ></general-button>
        </div>
        <div class="align-self-end">
            <general-button
                backgroundStyle="outline"
                class="action-button"
                size="small"
                (onClick)="visitActionLink()"
                text="{{ 'GENERAL.VIEW' | translate }}"
            ></general-button>
        </div>
    </div>
</div>
