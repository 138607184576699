import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { AbstractRequestCardComponent } from './abstract-request-card.component';
import { Store } from '@ngrx/store';
import { CORNER_CARD_TEXT_TYPE_ENUM } from '@libs/common-ui/corner-card-text/corner-card.component';

@Component({
  selector: 'staffnow-technician-request-card',
  templateUrl: './technician-request-card.component.html',
  styleUrls: ['./request-card-common-styles.scss']
})
export class TechnicianRequestCardComponent extends AbstractRequestCardComponent implements OnChanges {
  protected readonly CORNER_CARD_TEXT_TYPE_ENUM = CORNER_CARD_TEXT_TYPE_ENUM;
  @Input() public agencyTechnicianUuid: string = null;

  public facility: any = null;

  constructor(private router: Router, store: Store) {
    super(store);
  }

  ngOnChanges(): void {
    this.facility = getEmbeddedResource(this.offer, JobOfferLinkRel.Facility);
  }

  get offerHasPayRate(): boolean {
    return !isNil(this.offerOutDto().payRate);
  }

  public openProcess(): void {
    if (this.agencyTechnicianUuid) {
      this.router.navigate(['own-technician-process'], {
        queryParams: {
          offerRefNumber: this.offer.refNumber,
          technicianUuid: this.agencyTechnicianUuid
        }
      });
    } else {
      this.router.navigate([this.getRoute(), this.offer.refNumber]);
    }
  }

  private getRoute(): string {
    if (this.isTemporaryJobOpening) {
      return 'process';
    }
    if (this.isPermanentJobOpening) {
      return 'permanent-offer/process';
    }
    return this.isAgencyJobOpening ? 'agency-offer/process' : 'package-offer/process';
  }
}
