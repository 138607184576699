import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { REQUEST_OVERVIEW_FEATURE_KEY, RequestOverviewState } from './request-overview-state.model';

export const selectFeature: Selector<any, RequestOverviewState> =
  createFeatureSelector<RequestOverviewState>(REQUEST_OVERVIEW_FEATURE_KEY);

export const selectOffer = createSelector(selectFeature, requestOverviewState => requestOverviewState.offer);

export const selectContract = createSelector(selectFeature, state =>
  state.contracts.length > 0 ? state.contracts[0] : null
);

export const selectDocumentsNeededTemplate = createSelector(selectFeature, state =>
  state?.offer ? state.offer._embedded.facility['documentsNeededTemplate'] : ''
);
