<div class="availability-container">
    <div class="page-description">
        <div *ngIf="canEdit && !isUserDeleted">
            <p>
                This calendar automatically reflects the contract periods for the job openings where you have been
                accepted.
            </p>
        </div>
    </div>
    <div *ngIf="isPlatformWeb; else appCalendar" class="calendar-wrapper">
        <div class="calendar">
            <ng-container *ngTemplateOutlet="staffnowCalendar"> </ng-container>
        </div>
    </div>
    <ng-template #appCalendar>
        <div class="app-calendar-wrapper">
            <div class="calendar app-calendar-element">
                <ng-container *ngTemplateOutlet="staffnowCalendar"> </ng-container>
            </div>
        </div>
    </ng-template>
</div>
<ng-template #staffnowCalendar>
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
</ng-template>
