import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MailboxService {
  private scrollToBottomSource = new BehaviorSubject('');
  public scrollToBottomObservable = this.scrollToBottomSource.asObservable();

  public scrollMessageListToBottom() {
    this.scrollToBottomSource.next('');
  }
}
