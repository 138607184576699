import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterConfigModel } from '../../models/filter-config.model';

@Component({
  selector: 'text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent {
  @Input() public filterConfig: FilterConfigModel = null;
  @Output() public onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onEnterPressed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public handleChange(newValue: string): void {
    this.onChange.emit(newValue?.trim());
  }

  public isEnterPressed($event) {
    if ($event.keyCode === 13) {
      this.onEnterPressed.emit();
    }
  }
}
