import { Action } from '@ngrx/store';
import { FeedbackQuestion } from '../components/feedback-form/feedback-form.model';

export enum FeedbackActions {
  LoadFeedbackList = '[Feedback] Load feedback list',
  FeedbackListLoaded = '[Feedback] Feedback list loaded',
  ResetFeedbackForm = '[Feedback] Reset form',
  SendFeedbackForm = '[Feedback] Send feedback form',
  FeedbackFormSentSuccess = '[Feedback] Feedback form sent',
  RejectFeedback = '[Feedback] Reject Feedback',
  FeedbackRejected = '[Feedback] Feedback rejected'
}

export class LoadFeedbackList implements Action {
  public readonly type = FeedbackActions.LoadFeedbackList;
  public accessLinkRel?: string = '';

  constructor(accessLinkRel?: string) {
    this.accessLinkRel = accessLinkRel;
  }
}

export class FeedbackListLoaded implements Action {
  public readonly type = FeedbackActions.FeedbackListLoaded;
  constructor(public readonly feedbackList: Array<any> = null) {}
}

export class ResetFeedbackForm implements Action {
  public readonly type = FeedbackActions.ResetFeedbackForm;
}

export class SendFeedbackForm implements Action {
  public readonly type = FeedbackActions.SendFeedbackForm;
  public patchLinkRel?: string = '';
  public payload?: FeedbackQuestion[] = null;

  constructor(patchLinkRel?: string, payload?: FeedbackQuestion[]) {
    this.patchLinkRel = patchLinkRel;
    this.payload = payload;
  }
}

export class FeedbackFormSentSuccess implements Action {
  public readonly type = FeedbackActions.FeedbackFormSentSuccess;
}

export class RejectFeedback implements Action {
  public readonly type = FeedbackActions.RejectFeedback;
  public rejectLinkRel?: string = '';

  constructor(rejectLinkRel?: string) {
    this.rejectLinkRel = rejectLinkRel;
  }
}

export class FeedbackRejected implements Action {
  public readonly type = FeedbackActions.FeedbackRejected;
}

export type FeedbackAction =
  | LoadFeedbackList
  | FeedbackListLoaded
  | ResetFeedbackForm
  | SendFeedbackForm
  | FeedbackFormSentSuccess
  | RejectFeedback
  | FeedbackRejected;

export const fromFeedbackActions = {
  LoadFeedbackList,
  FeedbackListLoaded,
  ResetFeedbackForm,
  SendFeedbackForm,
  FeedbackFormSentSuccess,
  RejectFeedback,
  FeedbackRejected
};
