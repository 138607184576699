import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { RoleWrapper } from '../models/roles.enum';
import { AbstractRoleGuardService } from './abstract-role-guard.service';

@Injectable()
export class IsTechnicianGuardService extends AbstractRoleGuardService {
  constructor(router: Router, store: Store<any>) {
    super(router, store);
  }

  roleCheck(role: RoleWrapper): boolean {
    return role.isTechnician();
  }
}
