<div class="select-container">
    <div class="select">
        <technician-paginated-selector
            (selectedTechnicianUuidChange)="updateUuidQueryParam($event)"
            [placeholder]="'GENERAL.SELECT_OPTION' | translate"
            [technicianRetrieverRel]="techniciansRetrievalUrl"
        >
    </technician-paginated-selector>
    </div>
</div>
<staffnow-request-listing
    [actingAs]="USER_ROLES.ROLE_TECHNICIAN"
></staffnow-request-listing>
