<div class="status-wrapper flex-grow-1" (click)="viewProfile(ENTITY_TYPES.TECHNICIAN)">
    <status-circle [status]="contract?.technicianStatus"></status-circle>
    <span class="view-contr-profile" [ngClass]="{ disabled: !hasTechnicianProfileLink }">
        {{ 'GENERAL.TECHNICIAN' | translate }}
    </span>
</div>
<div *ngIf="!isAgencyOfferContract" class="status-wrapper flex-grow-1" (click)="viewProfile(ENTITY_TYPES.MRO)">
    <div class="mro-statuses">
        <div class="status-with-label" *ngIf="temporaryJobOfferContract?.requiresProductionAcceptance">
            <span class="label"
                ><strong>{{ 'GENERAL.PRODUCTION' | translate }}</strong></span
            >
            <status-circle
                [status]="temporaryJobOfferContract?.mroStatus"
                [customIcon]="temporaryJobOfferContract?.customMroStatusIcon"
            ></status-circle>
        </div>
        <div class="status-with-label" *ngIf="temporaryJobOfferContract?.requiresQualityAcceptance">
            <span class="label"
                ><strong>{{ 'GENERAL.QUALITY' | translate }}</strong></span
            >
            <status-circle
                [status]="temporaryJobOfferContract?.mroQualityStatus"
                [customIcon]="temporaryJobOfferContract?.customMroQualityStatusIcon"
                [shouldSoftenWords]="isUserTechnician"
            ></status-circle>
        </div>
        <div class="status-with-label" *ngIf="temporaryJobOfferContract?.requiresHumanResourcesAcceptance">
            <span class="label"
                ><strong>{{ 'GENERAL.HR' | translate }}</strong></span
            >
            <status-circle
                [status]="temporaryJobOfferContract?.mroHumanResourcesStatus"
                [customIcon]="temporaryJobOfferContract?.customMroHumanResourcesStatusIcon"
            ></status-circle>
        </div>
    </div>
    <span class="view-mro-profile">
        {{ 'GENERAL.AVIATION_COMPANY' | translate }}
    </span>
</div>
<div
    class="status-wrapper flex-grow-1"
    (click)="(isAgencyOfferContract || isAgencySelected) && viewProfile(ENTITY_TYPES.AGENCY)"
    *ngIf="isAgencyOfferContract || temporaryJobOfferContract?.requiresAgencyAcceptance"
>
    <status-circle [status]="contract?.agencyStatus"></status-circle>
    <span class="view-agency-profile" [ngClass]="{ disabled: !isAgencyOfferContract && !isAgencySelected }">
        {{ 'GENERAL.AGENCY' | translate }}
    </span>
</div>
