<ng-container *ngIf="!displayFullText; else fullTextDisplay">
    <div [innerHTML]="shortenedContent" class="shortened-text-viewport"></div>
</ng-container>
<ng-template #fullTextDisplay>
    <div [innerHTML]="content" class="full-text-viewport"></div>
</ng-template>
<borderless-button
    *ngIf="!featureIsDisabled"
    size="small"
    textDecoration="underline"
    (onClick)="toggleContent()"
    [text]="displayFullText ? 'Read Less' : 'Read More'"
></borderless-button>
