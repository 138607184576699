<div class="options-list">
    <h1 *ngIf="title" class="title">{{ title | translate }}</h1>
    <div
        class="single-option"
        *ngFor="let item of items"
        [ngClass]="{ selected: getValue(item) === value }"
        (click)="setValue(getValue(item))"
    >
        {{ getLabel(item) | translate}}
    </div>
</div>

<div class="component-to-render">
    <ng-content></ng-content>
</div>
