import {Component, Input} from "@angular/core";
import {
  AutomaticReplyFailedToSave,
  AutomaticReplyLoaded,
  AutomaticReplySuccessfullySaved,
  LoadAutomaticReply,
  SaveAutomaticReply
} from "./automatic-reply.actions";
import {BsModalService} from "ngx-bootstrap/modal";
import {ActionsSubject, Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ofType} from "@ngrx/effects";
import {getUrl} from "@libs/shared/bms-common/rest/resource.utils";
import {UserProfileLinkRel} from "@libs/shared/linkrels/user-profile.linkrel";
import {AutomaticReplyFormComponent} from "@libs/user-profile/components/automatic-reply/automatic-reply-form/automatic-reply-form.component";
import {AutomaticReply} from "@libs/user-profile/components/automatic-reply/automatic-reply.model";


@UntilDestroy()
@Component({
  selector: 'staffnow-automatic-reply',
  templateUrl: './automatic-reply.component.html',
  styleUrls: ['./automatic-reply.component.scss']
})
export class AutomaticReplyComponent {
  @Input() public userProfile: any = null;
  public isSavingAutomaticReply: boolean = false;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private bsModalService: BsModalService
  ) {
    this.actionsSubject
      .pipe(ofType(AutomaticReplySuccessfullySaved, AutomaticReplyFailedToSave), untilDestroyed(this))
      .subscribe(() => this.isSavingAutomaticReply = false);
    this.actionsSubject
      .pipe(ofType(AutomaticReplyLoaded), untilDestroyed(this))
      .subscribe(action => this.openAutomaticReplyForm(action.automaticReply));
  }

  public loadExistingAutomaticReply(): void {
    this.store.dispatch(LoadAutomaticReply({url: this.getAutomaticResponseEndpointUrl()}))
  }

  private getAutomaticResponseEndpointUrl(): string {
    return getUrl(this.userProfile, UserProfileLinkRel.ConfigureAutomaticReply);
  }

  private openAutomaticReplyForm(existingReply: AutomaticReply) {
    this.bsModalService.show(AutomaticReplyFormComponent, {
      ignoreBackdropClick: true,
      initialState: {
        existingReply,
        onSendCallback: (payload: AutomaticReply): void => {
          this.isSavingAutomaticReply = true;
          this.store.dispatch(SaveAutomaticReply({
            payload,
            url: this.getAutomaticResponseEndpointUrl()
          }));
        },
      }
    });
  }

}
