<span class="text-muted">Profile Status: </span>
<span [ngClass]="handleStatusColoring()">
    <strong>
        @if(showCompleteStatus){
        {{ profileStatusConstants.complete | humanReadableProfileStatus }}
        }@else{
        {{ profileStatus | humanReadableProfileStatus }}
        }
    </strong>
</span>
