import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  getUrl,
} from '@libs/shared/bms-common/rest/resource.utils';
import { DownloadService } from '@libs/shared/services/download.service';
import { PackageOfferDto, PackageOfferFileDto } from '@libs/shared/models/offer.model';
import { PackageOfferLinkRel } from '@libs/shared/linkrels/package-offer.linkrel';

@Injectable()
export class PackageOfferFileService {
  constructor(
    private httpClient: HttpClient,
    private downloadService: DownloadService
  ) {}

  public deletePackageOfferFile(
    packageOfferFile: PackageOfferFileDto
  ): Observable<any> {
    return this.httpClient.delete(
      getUrl(packageOfferFile, PackageOfferLinkRel.DeletePackageOfferFile)
    )
  }

  public downloadPackageOfferFilesAsZip(
    packageOffer: PackageOfferDto
  ): Observable<any> {
    return this.downloadService.doGetRequest(
      getUrl(packageOffer, PackageOfferLinkRel.DownloadPackageOfferFilesAsZip)
    );
  }

  public downloadPackageOfferFile(
    packageOfferFile: PackageOfferFileDto
  ): Observable<any> {
    return this.downloadService.doGetRequest(
      getUrl(packageOfferFile, PackageOfferLinkRel.DownloadPackageOfferFile)
    );
  }
}
