import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';

import { MailboxService } from '../../mailbox.service';
import { Conversation, Message } from '../../state/mailbox-state.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements AfterViewChecked {
  @Input() conversation: Conversation = null;
  @Input() messageList: Array<Message> = [];
  @Input() ownPicUrl: string = '';
  @Input() loading: boolean = true;
  @Output() loadOlderMessages: EventEmitter<number> = new EventEmitter();

  private readonly SCROLL_TRIGGER_PERCENTAGE = 35;
  private scrollRatio$: Subject<any> = new Subject();
  private scrollToBottom = false;
  private lockScroll = false;

  constructor(
    private mailboxService: MailboxService,
    private elemRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.mailboxService.scrollToBottomObservable
      .pipe(untilDestroyed(this))
      .subscribe(data => (this.scrollToBottom = true));
    /*
      Here the EventEmitter for the scroll position is debounced, in order to prevent 1257219572195 events from being fired instantly one after another
    */
    this.scrollRatio$
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(scrollRatio => {
        if (
          this.messageList &&
          scrollRatio * 100 < this.SCROLL_TRIGGER_PERCENTAGE
        ) {
          this.loadOlderMessages.emit(
            this.messageList[this.messageList.length - 1].createdOn
          );
        }
      });
  }

  ngAfterViewChecked() {
    if (this.lockScroll) {
      this.scrollToBottom = false;
      return;
    }

    if (this.scrollToBottom) {
      const nativeElement = this.elemRef.nativeElement;
      nativeElement.scrollTop =
        nativeElement.scrollHeight - nativeElement.clientHeight;
      this.scrollToBottom = false;
    }
  }

  public navigateToProfile(partnerUserUuid: string) {
    if (this.activatedRoute.snapshot.data.isAdmin) {
      this.router.navigate(['technicians', 'profile', partnerUserUuid]);
    }
  }

  @HostListener('scroll', ['$event'])
  private onScroll($event: Event): void {
    const nativeElement = this.elemRef.nativeElement;
    const scrollRatio =
      nativeElement.scrollTop /
      (nativeElement.scrollHeight - nativeElement.clientHeight);
    this.scrollRatio$.next(scrollRatio);
    /*
      This is needed in case the user scrolls very fast to the top of the container, before he has reached last page.
      In such a case the user is not able to trigger the scroll event anymore, therefore he can't request older messages.
    */
    if (scrollRatio === 0) {
      nativeElement.scrollTop = 1;
    }
    /*
      We 'lock' the scroll when the user has scrolled up his older messages in order to prevent scrolling to bottom when
      he receives a new message
    */
    this.lockScroll =
      nativeElement.scrollTop <
      nativeElement.scrollHeight - nativeElement.clientHeight;
  }
}
