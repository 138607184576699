<corner-card *ngIf="needsCornerCard" [type]="cornerCardType"> </corner-card>
<card-tab-text *ngIf="privateOffer" [text]="'OFFER_DETAILS.PRIVATE_JOB_OPENING' | translate">
</card-tab-text>
<div class="offer-card-head">
    <div [class]="['offer-alert', offerAlertClass]" *ngIf="needsCornerCard">
        {{ offerAlertText | translate }}
    </div>
    <h3 *ngIf="isCentralQualityOrHumanResources()" class="h3">
        {{ facility?.name }}
    </h3>
    <h3 class="h3">{{ offer.refNumber }}</h3>
    <h3 class="h3">{{ offer.title }}</h3>
    <staffnow-offer-priority-info
        *ngIf="offer?.priority"
        [priority]="offer?.priority"
    ></staffnow-offer-priority-info>
</div>
<div class="offer-card-body">
    <staffnow-contract-info
        *ngIf="isCentralQualityOrHumanResources()"
        [icon]="'location_on'"
    >
        {{ offer?.location.name }}
    </staffnow-contract-info>
    <staffnow-contract-info *ngIf="!isPermanentJobOpening; else startDate" [icon]="'date_range'">
        {{ offer?.periodFrom | absoluteDateFormat }}
        -
        {{ offer?.periodTo | absoluteDateFormat }}
    </staffnow-contract-info>
    <ng-template #startDate>
        <staffnow-contract-info [icon]="'date_range'">
            {{ offer?.startDate | absoluteDateFormat }}
        </staffnow-contract-info>
    </ng-template>

    <ng-container *ngIf="isTemporaryJobOpening || isPermanentJobOpening">
        <staffnow-contract-info
            [icon]="'airplanemode_active'"
            [overflowElipsis]="true"
        >
            <staffnow-offer-aircrafts-field
                [offer]="offerOutDto()"
            ></staffnow-offer-aircrafts-field>
        </staffnow-contract-info>
        <staffnow-contract-info [icon]="'person'" [overflowElipsis]="true">
            {{ offer?.vacancies }} x
            {{ offer.ameTitle | ameTitleName }}
        </staffnow-contract-info>
    </ng-container>

    <div class="contracts-count-section">
        <div class="title">
            <ng-container *ngIf="isFixedPriceJobOpening; else technicians">
                {{ 'DASHBOARD.MENU_ITEMS.AGENCIES.LABEL' | translate }}
            </ng-container>
            <ng-template #technicians>
                {{ 'DASHBOARD.MENU_ITEMS.TECHNICIANS.LABEL' | translate }}
            </ng-template>
        </div>
        <div class="contracts-count">
            <div class="count">
                <span class="material-icons-outlined timelapse">timelapse</span>
                <div>
                    <span class="number">{{ offer?.pendingContracts }}</span>
                    <span>&nbsp;{{ 'OFFER.STATUS.PENDING' | translate }}</span>
                </div>
            </div>
            <div class="count">
                <span class="material-icons-outlined">task_alt</span>
                <div>
                    <span class="number">{{ offer?.fullyMatchedSize }}</span>
                    <span>&nbsp;{{ 'OFFER.STATUS.ACCEPTED' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div [class]="['open-contracts', offerAlertClass]">
    <button class="btn btn-primary" (click)="openProcess()">
        {{ 'GENERAL.VIEW_DETAILS' | translate }}
    </button>
</div>
