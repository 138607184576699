import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {ToastMessageService} from '@libs/toast-messages/toast-message.service';

import {FileUploadService} from '../services/file-upload.service';
import {fileUploadConstants} from '@libs/shared/constants/fileupload.constants';
import {isEmpty} from 'lodash-es';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-facility-picture-upload',
  templateUrl: './facility-picture-upload.component.html',
  styleUrls: ['./facility-picture-upload.component.scss']
})
export class FacilityPictureUploadComponent implements OnChanges {
  @Input() public apiUrl: string;
  @Input() public allowedFileTypes: Array<string>;
  @Input() public fileSizeLimit: number;
  @Input() public imageUrl: string;
  @Input() public pictureType: string;
  @Input() public uuid: string = '';
  @Input() public type: string = '';
  @Input() public buttonText: string = 'Image';
  @Input() public isDisabled: boolean = false;
  @Output() public successfulFileUpload: EventEmitter<any> = new EventEmitter();

  public inputAccepts: string;
  public uploader: any;
  public isLoading = false;
  public fileRestrictionsTooltip: string;

  constructor(
    public uploadService: FileUploadService,
    private toastMessageService: ToastMessageService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService,
  ) {
  }

  get buttonLabel(): string {
    return isEmpty(this.imageUrl) ? 'GENERAL.ADD' : 'GENERAL.CHANGE';
  }

  public handleClick(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  ngOnChanges() {
    this.uploadService.configUploader(
      this.apiUrl,
      this.fileSizeLimit,
      this.allowedFileTypes
    ).then(uploader => {
      this.uploader = uploader;
      this.inputAccepts = this.uploadService.fileListToExtensionCSV(
        this.allowedFileTypes
      );

      this.uploader.onBeforeUploadItem = (fileItem: any) => {
        this.isLoading = true;
        fileItem.alias = 'picture';
      };

      this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        const picInfo = {
          type: this.pictureType,
          uuid: this.uuid
        };

        form.append(
          'data',
          new Blob([JSON.stringify(picInfo)], {type: 'application/json'})
        );
      };

      this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any) => {
        switch (filter.name) {
          case 'fileSize':
            const fileSizeLimitBytes = this.fileSizeLimit * 1000000;
            const mime: string = fileItem.rawFile.type;
            if (mime.includes("image")) {
              const downsizeRate = fileSizeLimitBytes / fileItem.rawFile.size;
              this.uploadService.downsizeImage(
                fileItem,
                this.apiUrl,
                downsizeRate,
                {
                  type: this.pictureType,
                  uuid: this.uuid
                },
                (response) => this.handleResizeResponse(response));
            } else {
              this.toastMessageService.fail(
                this.translateService.instant(fileUploadConstants.fileExceededLimit)
              );
            }
            break;
          case 'mimeType':
            this.toastMessageService.fail(
              this.translateService.instant(fileUploadConstants.fileHasUnsupportedFormat)
            );
            break;
          default:
            break;
        }
      };

      this.uploader.onSuccessItem = (
        fileItem: never,
        response: any,
        status: number
      ) => {
        this.successfulFileUpload.emit(JSON.parse(response)._links.self.href);
        this.isLoading = false;
      };

      this.uploader.onErrorItem = () => {
        this.uploader.clearQueue();
      };
    });
  }

  private handleResizeResponse(response) {
    response.subscribe(() => {
      this.successfulFileUpload.emit(JSON.parse(response)._links.self.href);
      this.toastMessageService.success(
        this.translateService.instant(fileUploadConstants.successfullFileUpload)
      );
    }, response => {
      
      this.errorMessageService.handleErrorResponse(response, 'UPLOAD.IMAGE_FAILED');
    });
  }
}
