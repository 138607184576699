import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { TranslationModule } from '@libs/translation/translation.module';
import { SharedModule as SharedLibModule } from '@libs/shared/shared.module';

import { ApiConfiguration } from '../../../config/api.config';
import { EntityNavComponent } from './components/entity-nav/entity-nav.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { StaffFiltersModule } from '@libs/staff-filters/staff-filters.module';
import { ToastMessagesModule } from '@libs/toast-messages/toast-messages.module';

@NgModule({
  declarations: [EntityNavComponent],
  imports: [
    SharedLibModule.forRoot(ApiConfiguration),
    HttpClientModule,
    TranslationModule,
    StaffFiltersModule,
    CommonUiModule,
    ToastMessagesModule,
    CommonModule
  ],
  exports: [
    TranslationModule,
    StaffFiltersModule,
    SharedLibModule,
    CommonUiModule,
    ToastMessagesModule,
    EntityNavComponent
  ]
})
export class SharedModule {}
