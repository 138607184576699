import { AgencyOfferOutDto, OfferOutDto } from '@libs/shared/models/offer.model';
import { Contract } from '@libs/shared/models/contract.model';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';

export const REQUEST_OVERVIEW_FEATURE_KEY = 'requestOverview';

export interface RequestOverviewState {
  loading: boolean;
  offerNotFound: boolean;
  offer: OfferOutDto | AgencyOfferOutDto;
  contracts: Array<Contract | AgencyOfferContractOutDto>;
}

export const initialState: RequestOverviewState = {
  loading: false,
  offer: null,
  contracts: [],
  offerNotFound: null
};
