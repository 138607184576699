import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AuthInitialized, TokenReceived} from '../state/authentication.actions';
import {ApiRootLoaded, LoadApiRoot, StaffnowClaims} from '@libs/shared/bms-common/api-root/api-root.actions';
import {isEmpty, isNil} from 'lodash-es';
import {RegionSelectorService} from '@libs/auth/services/region-selector.service';
import {AppRegionEnum, BrandName, EnvironmentState} from '@libs/shared/bms-common/environment/environment.model';
import {getStaticEnvironment} from "@libs/shared/bms-common/environment/environment.selector";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import jwt_decode from "jwt-decode";
import {Observable, Subject} from 'rxjs';
import {Actions, ofType} from '@ngrx/effects';
import {CustomNavigationService} from '@libs/shared/services/custom-navigation.service';
import {StorageService} from "@libs/shared/services/storage.service";
import {isPlatformIos, isPlatformWeb} from "@libs/shared/helpers/capacitor";
import {PushNotifications, Token} from "@capacitor/push-notifications";
import {Device} from "@capacitor/device";
import {AuthenticateOptions, BiometricAuth} from "@aparajita/capacitor-biometric-auth";
import {SecureStorage} from "@aparajita/capacitor-secure-storage";
import {getLoggedInUserRole} from "@libs/shared/bms-common/api-root/api-root.selectors";
import {UserRoles} from "@libs/shared/models/roles.enum";
import {Dialog} from "@capacitor/dialog";
import { TranslateService } from '@ngx-translate/core';

declare var grecaptcha: any;

export enum AuthenticationErrorType {
  USERNAME_OR_PASSWORD_INCORRECT = 'USERNAME_OR_PASSWORD_INCORRECT',
  VERIFICATION_NEEDED = 'VERIFICATION_NEEDED',
  NEW_PASSWORD_NEEDED = 'NEW_PASSWORD_NEEDED',
  OTHER = 'OTHER'
}

enum UserStatusType {
  VERIFICATION_NEEDED = 'VERIFICATION_NEEDED',
  NEW_PASSWORD_NEEDED = 'NEW_PASSWORD_NEEDED',
}

interface UserStatusOutDto {
  userStatusType: UserStatusType;
  requestId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly REFRESH_TIME_IN_SECONDS_PRIOR_TO_TOKEN_EXPIRATION = 60;
  private readonly CURRENT_DEVICE_REGISTRATION_VERSION = 2;

  private brandName: string = null;
  private silentRefreshTimeout: any = null;
  private isBackoffice: boolean = false;
  private jwtIssuer: string = null;
  private keycloakLoginUrl: string = null;
  private clientId: string = null;
  private appUrl: string = null;
  private googleRecaptchaSiteKey: string = null;
  private backendUrl: string = null;
  public shouldRedirectAfterLogin = true;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private actions: Actions,
    private store: Store,
    private regionSelectorService: RegionSelectorService,
    private customNavigationService: CustomNavigationService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    this.store.pipe(getStaticEnvironment).subscribe((envData: EnvironmentState) => {
      this.brandName = envData.brandConfig.brandName;
      this.jwtIssuer = envData.auth.jwtIssuer;
      this.keycloakLoginUrl = envData.auth.keycloakLoginUrl;
      this.clientId = envData.auth.clientId;
      this.appUrl = envData.brandConfig.appUrl;
      this.googleRecaptchaSiteKey = envData.googleRecaptchaSiteKey;
      this.setBackendUrl(envData);
    });
    this.store.pipe(getLoggedInUserRole).subscribe(userRole => {
      if (!isPlatformWeb() && userRole !== UserRoles.ROLE_TECHNICIAN) {
        this.shouldRedirectAfterLogin = false;
        this.router.navigate(['unauthorized-app']);
      }
    });
    this.actions.pipe(ofType(ApiRootLoaded))
      .subscribe(() => {
        this.hideGrecaptcha();
        this.redirectAfterLogin();
      });
    this.resumeSessionInCaseItsActive();
  }

  private async resumeSessionInCaseItsActive() {
    const savedDeviceRegistrationVersion = +await this.storageService.getItem("device_registration_version");
    if (savedDeviceRegistrationVersion === null ||
      savedDeviceRegistrationVersion === undefined ||
      savedDeviceRegistrationVersion < this.CURRENT_DEVICE_REGISTRATION_VERSION) {
      this.storageService.setItem("device_registration_version", this.CURRENT_DEVICE_REGISTRATION_VERSION.toString());
      this.logout();
    } else if (await this.hasValidAccessToken()) {
      const secondsToExpire = await this.getSecondsForTokenToExpire();
      this.doSilentRefresh(Math.max(secondsToExpire - this.REFRESH_TIME_IN_SECONDS_PRIOR_TO_TOKEN_EXPIRATION, 0));
    } else if (!isPlatformWeb() && !(await this.hasValidAccessToken())) {
      this.autoLoginFromBiometrics();
    } else {
      this.logout();
    }
  }

  private setBackendUrl(environment: EnvironmentState) {
    this.backendUrl = (this.brandName === BrandName.eLAUNCHNow) ?
      environment.usaApiUrl : environment.europeApiUrl;
  }


  private hideGrecaptcha() {
    const element = (document.getElementsByClassName('grecaptcha-badge')[0] as any);
    if (!!element) {
      element.style.visibility = 'hidden';
    }
  }

  public initForPlatform() {
    this.isBackoffice = false;
    this.initInternal();
  }

  public initForBackoffice() {
    this.isBackoffice = true;
    this.initInternal();
  }

  private async initInternal() {
    this.store.dispatch(AuthInitialized());
    const isTokenValid = await this.hasValidAccessToken();
    this.store.dispatch(TokenReceived({isValid: isTokenValid}));
    if (isTokenValid) {
      const {regions} = await this.getIdentityClaims();
      this.performActionDependingOnRegions(regions);
    }
    this.router.initialNavigation();
  }

  public login(
    username: string,
    password: string
  ): Observable<any> {
    let subject = new Subject<void>();
    this.doPostOnKeycloak(
      `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
    ).subscribe(async response => {

      this.setAuthentication(response);
      this.store.dispatch(AuthInitialized());
      const isTokenValid = await this.hasValidAccessToken();
      this.store.dispatch(TokenReceived({isValid: isTokenValid}));
      if (isTokenValid) {
        const {regions} = await this.getIdentityClaims();
        this.performActionDependingOnRegions(regions);
      }
      subject.next();
      subject.complete();
      if (!isPlatformWeb()) {
        this.sendNotificationInfo(username);
      }
    }, error => {
      if (error.status === 401) {
        subject.error(AuthenticationErrorType.USERNAME_OR_PASSWORD_INCORRECT);
        subject.complete();
      } else if (error.status === 400) {
        if (error.error['error_description'] === 'Account is not fully set up') {
          this.actOnAccountNotFullySetUp(subject, username, password);
        } else {
          subject.error(AuthenticationErrorType.OTHER);
          subject.complete();
        }
      } else {
        subject.error(AuthenticationErrorType.OTHER);
        subject.complete();
      }
    });
    return subject;
  }

  private actOnAccountNotFullySetUp(subject: Subject<void>, username: string, password: string) {
    grecaptcha.ready((): void => {
      grecaptcha
        .execute(this.googleRecaptchaSiteKey, {action: 'submit'})
        .then((token: string): void => {
          this.getNotFullySetUserStatus(token, username, password).subscribe(userStatus => {
            switch (userStatus.userStatusType) {
              case UserStatusType.NEW_PASSWORD_NEEDED:
                this.router.navigateByUrl(`/reset-password?key=${encodeURIComponent(userStatus.requestId)}`)
                subject.error(AuthenticationErrorType.NEW_PASSWORD_NEEDED);
                subject.complete();
                break;
              case UserStatusType.VERIFICATION_NEEDED:
                subject.error(AuthenticationErrorType.VERIFICATION_NEEDED);
                subject.complete();
                break;
            }
          }, error => {
            console.log(error);
            subject.error(AuthenticationErrorType.OTHER);
            subject.complete();
          });
        });
    });
  }

  public logout() {
    this.clearAuthentication();
    if (this.router.url !== this.getLoginRoute() && this.router.url !== '/') {
      this.goToLoginPage();
    }
  }

  public goToLoginPage() {
    this.router.navigate([this.getLoginRoute()]);
  }

  public handleUnauthorizedAccess() {
    this.clearAuthentication();
    if (isPlatformWeb()) {
      this.goToUnauthorizedPage();
    } else {
      this.autoLoginFromBiometrics();
    }
  }

  public handleUnauthorizedAccessFromGuard() {
    if (this.router.url === '/') {
      this.goToLoginPage()
    } else if (this.router.url !== this.getLoginRoute()) {
      this.handleUnauthorizedAccess()
    }
  }

  private autoLoginFromBiometrics() {
    BiometricAuth.checkBiometry().then(result => {
      if (result.isAvailable) {
        SecureStorage.getItem(this.appUrl).then(item => {
          const credentials = item.split(' ');
          if (!isEmpty(credentials) && credentials.length === 2) {
            const authenticateOptions: AuthenticateOptions = {
              reason: 'For easier login',
              iosFallbackTitle: 'Login',
              androidTitle: 'Login',
              allowDeviceCredential: true
            };
            BiometricAuth.authenticate(authenticateOptions).then(() =>
              this.login(credentials[0], credentials[1])
            ).catch(() => {
              this.logout();
            });
          } else {
            this.logout();
          }
        });
      } else {
        this.logout();
      }
    }).catch(() => {
      this.logout();
    });
  }

  private goToUnauthorizedPage() {
    this.router.navigate(['/unauthorized']);
  }

  private doSilentRefresh(timeSeconds: number) {
    this.silentRefreshTimeout = setTimeout(async () => {
      if (!!await this.storageService.getItem("refresh_token")) {
        this.storageService.removeItem("access_token");
        this.doPostOnKeycloak(
          `grant_type=refresh_token&refresh_token=${await this.storageService.getItem("refresh_token")}`
        ).subscribe(response => {
          this.setAuthentication(response);
        }, error => {
          console.log(error);
          this.logout();
        });
      }
    }, timeSeconds * 1000);
  }

  private doPostOnKeycloak(body: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient
      .post<any>(this.keycloakLoginUrl, `client_id=${this.clientId}&${body}`, {headers});
  };

  public async hasValidAccessToken(): Promise<boolean> {
    if (!await this.storageService.getItem("access_token")) {
      return false;
    }
    const token = await this.storageService.getItem("access_token");
    const decodedJwt = token ? jwt_decode(token) as any : null;
    return token && (decodedJwt.iss === this.jwtIssuer) &&
      !this.isDecodedJwtExpired(decodedJwt);
  }

  private isDecodedJwtExpired(decodedJwt: any) {
    return decodedJwt.exp * 1000 <= (new Date()).getTime()
  }

  private async getSecondsForTokenToExpire(): Promise<number> {
    const decodedJwt = jwt_decode(await this.storageService.getItem("access_token")) as any;
    return decodedJwt.exp - ((new Date()).getTime() / 1000);
  }

  public saveCurrentUrl(currentUrl: string): void {
    sessionStorage.setItem("login_redirect_url", currentUrl);
  }

  public redirectAfterLogin(): void {
    if (
      (this.shouldRedirectAfterLogin &&
        (this.isBackoffice && document.location.pathname === '/login')) ||
      (this.shouldRedirectAfterLogin &&
        (!this.isBackoffice && document.location.pathname === '/register'))
    ) {
      const redirectionUrl = sessionStorage.getItem("login_redirect_url");
      sessionStorage.removeItem("login_redirect_url");
      if (this.isRedirectionUrlValid(redirectionUrl)) {
        this.router.navigate([redirectionUrl]);
      } else {
        if (this.isBackoffice) {
          this.router.navigate(['technicians']);
        } else {
          this.customNavigationService.goToDefaultView();
        }
      }
    }
  }

  private isRedirectionUrlValid(redirectionUrl: string): boolean {
    return !!redirectionUrl && redirectionUrl !== '' && redirectionUrl !== '/';
  }

  public async getIdentityClaims(): Promise<StaffnowClaims> {
    const accessToken = await this.storageService.getItem("access_token");
    return accessToken ? jwt_decode(accessToken) as StaffnowClaims : null;
  }

  private performActionDependingOnRegions(regions: string[]): void {
    if (isEmpty(regions)) {
      this.logout();
    } else if (this.isBrandAndRegionMismatch(regions)) {
      this.redirectToOtherPlatform();
    } else {
      this.loadApiRoot(regions);
    }
  }

  private isBrandAndRegionMismatch(regions: string[]): boolean {
    if (this.isBackoffice) {
      return false;
    }
    const isUsaBrand = this.brandName === BrandName.eLAUNCHNow;
    return this.isBrandMismatchOnSingleRegionUser(regions, isUsaBrand) || this.isBrandMismatchOnMultipleRegionUser(regions, isUsaBrand);
  }

  private isBrandMismatchOnSingleRegionUser(regions: string[], isUsaBrand: boolean) {
    return regions.length == 1 && (
      (regions[0] === AppRegionEnum.EU && isUsaBrand) ||
      (regions[0] === AppRegionEnum.USA && !isUsaBrand)
    );
  }

  private isBrandMismatchOnMultipleRegionUser(regions: string[], isUsaBrand: boolean) {
    return regions.length == 2 && isUsaBrand;
  }

  private redirectToOtherPlatform() {
    this.clearAuthentication();
    this.router.navigateByUrl('redirectPlatform');
  }

  private setAuthentication(response: any) {
    this.storageService.setItem("access_token", response.access_token);
    this.storageService.setItem("refresh_token", response.refresh_token);
    this.doSilentRefresh(response.expires_in - this.REFRESH_TIME_IN_SECONDS_PRIOR_TO_TOKEN_EXPIRATION);
  }

  public clearAuthentication() {
    this.storageService.removeItem("access_token");
    this.storageService.removeItem("refresh_token");
    clearTimeout(this.silentRefreshTimeout);
    this.regionSelectorService.clear();
  }

  private async loadApiRoot(regions: string[]) {
    const selectedRegion: string = await this.regionSelectorService.getSelectedRegion();
    const regionIsAlreadySelected = !isNil(selectedRegion) && regions.includes(selectedRegion);
    this.store.dispatch(
      LoadApiRoot({
        region: regionIsAlreadySelected ? selectedRegion : AuthenticationService.getDefaultRegion(regions)
      })
    );
  }

  private static getDefaultRegion(regions: string[]): string {
    return regions.includes(AppRegionEnum.EU)
      ? AppRegionEnum.EU
      : regions[0];
  }

  private getLoginRoute(): string {
    return this.isBackoffice ? '/login' : '/register';
  }

  private getNotFullySetUserStatus(
    grecaptchaToken: string,
    username: string,
    password: string
  ): Observable<UserStatusOutDto> {
    const url = this.backendUrl + '/api/public-request/user-setup-status?grecaptchaToken={grecaptchaToken}';
    return this.httpClient.post<UserStatusOutDto>(
      url.replace('{grecaptchaToken}', grecaptchaToken),
      {
        username,
        password
      }
    );
  }

  private sendNotificationInfo(username: string) {
    PushNotifications.checkPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else if (result.receive === 'prompt') {
        PushNotifications.requestPermissions().then(result => {
          if (result.receive !== 'granted') {
            Dialog.alert({
              title: this.translateService.instant('GENERAL.NOTIFICATIONS_ERROR'),
              message: this.translateService.instant('GENERAL.PERMISSION_FOR_NOTIFICATIONS')
            })
          } else {
            PushNotifications.register();
          }
        });
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      Device.getId().then(value => {
        const url = this.backendUrl + '/api/platform/users/register-device-notifications';
        this.storageService.getItem("device_registration_version").then(version => {
          this.httpClient.post(url, {
            deviceId: value.identifier,
            registrationToken: token.value,
            isIos: isPlatformIos(),
            email: username,
            deviceRegistrationVersion: +version
          }).subscribe();
        }, () => {
          this.httpClient.post(url, {
            deviceId: value.identifier,
            registrationToken: token.value,
            isIos: isPlatformIos(),
            email: username
          }).subscribe();
        })
      })
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      alert(this.translateService.instant('GENERAL.ERROR_ON_REGISTRATION') + JSON.stringify(error));
    });
  }
}
