export function downloadFileBlob(response): void {
  downloadRawBlob(response.body, decodeURI(response.headers.get('X-File-Name')));
}

export function downloadRawBlob(object: any, filename: string) {
  const downloadUrl = window.URL.createObjectURL(object);
  const downloadLink = document.createElement('a');

  downloadLink.href = downloadUrl;
  downloadLink.download = filename;
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();

  setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100);
}
