import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import PendingTask, {
  PendingTaskTypeEnum
} from '../../models/pending-task.model';
import { Store } from '@ngrx/store';
import { IgnoreTask } from '../../../../state/app.actions';

@Component({
  selector: 'task-row',
  templateUrl: './task-row.component.html',
  styleUrls: ['./task-row.component.scss']
})
export default class TaskRowComponent {
  @Input()
  public task: PendingTask;

  get offerDescription(): string {
    return (
      this.task.offerRefNumber +
      (this.task.offerTitle ? ` ― ${this.task.offerTitle}` : '')
    );
  }

  constructor(private router: Router, private store: Store<any>) {}

  public visitActionLink() {
    this.router.navigateByUrl(this.task.linkToAction).then();
  }

  public ignoreTask() {
    this.store.dispatch(
      IgnoreTask({ payload: { url: this.task.links[0].href } })
    );
  }

  public isDocumentsUploadedByTechnician() {
    return this.task.type === PendingTaskTypeEnum.TECHNICIAN_UPLOADED_DOCUMENTS;
  }

  public isStartDateProposal() {
    return this.task.type === PendingTaskTypeEnum.PROPOSE_START_DATE;
  }
}
