<collapsible [heading]="heading + ' (' + contracts.length + ')'">
    <div *ngIf="contracts.length > 0; else noContracts" class="cards-list-grid">
        <ng-container *ngIf="isMro && (isJobOffer || isPermanentJobOpening)">
            <staffnow-mro-contract-card
                *ngFor="let contract of contracts"
                [contract]="contract"
                [offerEndDate]="offerEndDate"
                [offerStartDate]="offerStartDate"
                [offer]="offer"
                [reasonsArray]="offer?._embedded?.reasons"
            >
            </staffnow-mro-contract-card>
        </ng-container>
        <ng-container *ngIf="isMro && isFixedPrice">
            <staffnow-package-contract-card
                *ngFor="let contract of contracts"
                [offer]="offer"
                [contract]="contract"
            ></staffnow-package-contract-card>
        </ng-container>
        <ng-container *ngIf="isAgency">
            <staffnow-agency-contract-card
                *ngFor="let contract of contracts"
                [contract]="contract"
                [offerRefNumber]="offer?.refNumber"
                [offerStartDate]="offerStartDate"
                [reasonsArray]="offer?._embedded?.reasons"
            ></staffnow-agency-contract-card>
        </ng-container>
    </div>
    <ng-template #noContracts>
        <div class="h3">
            {{ 'CONTRACTS.LIST.NO_CONTRACTS' | translate: {heading: heading.toLowerCase(),
            entity: contractEntity} }}
        </div>
    </ng-template>
</collapsible>
