<i class="material-icons close pointer" (click)="close()">close</i>
<div class="add-entities-modal-wrapper">
    <h2>{{ 'PACKAGE_AGENCY_SELECTION.TITLE' | translate }}</h2>
    <div class="agency-list">
        <staffnow-package-agency-card
            *ngFor="let agency of agencyList"
            [agency]="agency"
            (onClick)="handleAgencyClick(agency)"
        ></staffnow-package-agency-card>
    </div>
    <general-button
        style="width: 120px"
        (onClick)="onAdd()"
        [isDisabled]="!isFormValid()"
        class="d-block mx-auto"
        [text]="'GENERAL.CONFIRM' | translate"
    ></general-button>
</div>
