import { createAction, props } from '@ngrx/store';
import { MroFacility } from '@libs/shared/models/facility.model';

export const GenerateReport = createAction(
  '[App - Reports] Generate report',
  props<{ payload: any }>()
);
export const ReportGenerated = createAction(
  '[App - Reports] Report generated successfully'
);
export const FailedToGenerateReport = createAction(
  '[App - Reports] Failed to generate report'
);
export const GetAgencyOrderingList = createAction(
  '[App - Agency Ordering] Get list of agencies'
);
export const AgencyOrderingListLoaded = createAction(
  '[App - Agency Ordering] Agencies list loaded',
  props<{ payload: any }>()
);
export const FailedToLoadAgencyOrderingList = createAction(
  '[App - Agency Ordering] Failed to get agencies list'
);
export const SubmitOrderedAgenciesList = createAction(
  '[App - Agency Ordering] Submit ordered agencies list',
  props<{ payload: any }>()
);
export const OrderedAgenciesListSubmitted = createAction(
  '[App - Agency Ordering] Ordered agencies list submitted'
);
export const FailedToSubmitOrderedAgenciesList = createAction(
  '[App - Agency Ordering] Failed to submit ordered agencies list'
);
export const AgencyOrderingResetState = createAction(
  '[App - Agency Ordering] Reset to initial state'
);
export const GetMroList = createAction('[App - MRO List] Get list of MROs');
export const SearchForMro = createAction('[App - MRO List] Search For MROs',
  props<{ term: string }>()
  );
export const GetMroListForApiUrl = createAction(
  '[App - MRO List] Get list of MROs for a specific apiUrl',
  props<{ apiUrl: string }>()
);
export const MroListLoaded = createAction(
  '[App - MRO List] MRO list loaded',
  props<{ payload: any; fromOtherRegion?: boolean }>()
);
export const MroSearchSuccess = createAction(
  '[App - MRO List] MRO Search Success',
  props<{ payload: MroFacility[]; fromOtherRegion?: boolean }>()
);
export const FailedToLoadMroList = createAction(
  '[App - MRO List] Failed to get MRO list'
);

export const AppActionTypes = {
  MroListLoaded
};
