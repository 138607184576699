<ng-container *ngIf="formState">
    <div class="custom-control custom-checkbox">
        <input
            (change)="toggleCustomReference($event)"
            [disabled]="isRestrictedMode"
            class="custom-control-input"
            id="custom-reference-number"
            name="custom-reference-number"
            type="checkbox"
        />
        <label
            class="custom-control-label"
            for="custom-reference-number"
        >
            {{ customReferenceLabel }}
            <small class="text-muted optional">
                (optional)
            </small>
        </label>
    </div>
    <div class="value-wrapper">
        <input
            (keyup)="toUpperCaseWithoutWhiteSpaces()"
            [disabled]="!formState.controls.useCustomReference"
            [ngrxFormControlState]="formState.controls.refNumber"
            autocomplete="off"
            class="form-control"
            placeholder="Type {{ CUSTOM_REFERENCE_MIN_LENGTH }} to {{ CUSTOM_REFERENCE_MAX_LENGTH }} characters to identify your job opening"
            type="text"
        />
        <ng-container *ngIf="formState.errors?._refNumber as formErrors">
            <div class="validation-error">
                <ul>
                    <li *ngIf="formErrors.minLength">
                        Cannot have less than
                        {{ CUSTOM_REFERENCE_MIN_LENGTH }} characters.
                    </li>
                    <li *ngIf="formErrors.maxLength">
                        Cannot have more than
                        {{ CUSTOM_REFERENCE_MAX_LENGTH }} characters.
                    </li>
                    <li *ngIf="formErrors.customReferenceFormat?.atLeastOneLetterOrNumber">
                        Must have at least one letter (A-Z) or digit (0-9)
                    </li>
                    <li *ngIf="formErrors.customReferenceFormat?.allowedCharacters">
                        Allowed characters:
                        letters (A-Z), digits (0-9), minus (-) and underscore (_)
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
</ng-container>
