import { InjectionToken } from '@angular/core';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createReducer,
  MetaReducer,
  on
} from '@ngrx/store';

import {
  Conversation,
  initialState,
  MailboxStateModel,
  Message
} from './mailbox-state.model';
import {
  ConversationListLoaded,
  ConversationLoaded,
  ConversationsUpdated,
  MailboxActions,
  MessagesLoaded,
  NextPageLoaded,
  ResetMessagesList,
  ResetSelectedConversation,
  SetCurrentPageNumber
} from './mailbox.actions';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export function getReducers(): ActionReducerMap<MailboxStateModel> {
  return {
    currentPage: currentPageReducer,
    conversationList: conversationListReducer,
    messageList: messageListReducer,
    messageListLoading: messageListLoadingReducer,
    selectedConversation: selectedConversationReducer,
    sendingMessageInProgress: sendingMessageInProgressReducer
  };
}

export const currentPageReducer = createReducer<number>(
  initialState.currentPage,
  on(GlobalReset, () => initialState.currentPage),
  on(SetCurrentPageNumber, (state, { payload }) => payload)
);

export const selectedConversationReducer = createReducer<Conversation>(
  initialState.selectedConversation,
  on(GlobalReset, () => initialState.selectedConversation),
  on(ConversationLoaded, (state, { conversation }) => ({ ...conversation })),
  on(ResetSelectedConversation, () => initialState.selectedConversation)
);

function removeDuplicatedConversations(
  originalList: Conversation[],
  newList: Conversation[]
) {
  return originalList.filter(c1 => {
    return !newList.find(c2 => c2.conversationId === c1.conversationId);
  });
}

export const conversationListReducer = createReducer<Conversation[]>(
  initialState.conversationList,
  on(GlobalReset, () => initialState.conversationList),
  on(ConversationLoaded, (state, { conversation }) => {
    const conversationIndex = state.findIndex(
      c => c.conversationId === conversation.conversationId
    );
    if (conversationIndex >= 0) {
      const updatedState = state.slice();
      updatedState[conversationIndex] = conversation;
      return updatedState;
    }
    return state;
  }),
  on(ConversationListLoaded, (state, { conversationList }) => [
    ...conversationList
  ]),
  on(
    NextPageLoaded,
    (state, { conversations: olderConversations, updatedConversations }) => {
      const stateFiltered = removeDuplicatedConversations(
        state,
        updatedConversations
      );
      return updatedConversations.concat(stateFiltered, olderConversations);
    }
  ),
  on(ConversationsUpdated, (state, { updatedConversations }) => {
    const stateFiltered = removeDuplicatedConversations(
      state,
      updatedConversations
    );
    return updatedConversations.concat(stateFiltered);
  })
);

export const messageListReducer = (
  state: {
    postConversationMessageTemporaryFileUrl: string;
    messages: Array<Message>;
    lastMessageTimestamp: number;
    lastUpdatedMessageTimestamp: number;
  },
  action: MessagesLoaded | typeof ResetMessagesList
): {
  postConversationMessageTemporaryFileUrl: string;
  messages: Array<Message>;
  lastMessageTimestamp: number;
  lastUpdatedMessageTimestamp: number;
} => {
  switch (action.type) {
    case ResetMessagesList.type:
      return {
        postConversationMessageTemporaryFileUrl: null,
        messages: [],
        lastMessageTimestamp: null,
        lastUpdatedMessageTimestamp: null
      };
    case MailboxActions.MessagesLoaded:
      if (!action.loadUpdatedMessages) {
        let messages: Message[];
        if (action.isMessageListOld === null) {
          messages = action.messageList._embedded.messages.map(it => ({
            ...it,
            getConversationMessageTemporaryFileUrl: getUrl(
              it,
              'getConversationMessageTemporaryFile'
            ),
            deleteConversationMessageUrl: getUrl(
              it,
              'deleteConversationMessage'
            )
          }));
        } else if (action.isMessageListOld === false) {
          messages = action.messageList._embedded.messages
            .map(it => ({
              ...it,
              getConversationMessageTemporaryFileUrl: getUrl(
                it,
                'getConversationMessageTemporaryFile'
              ),
              deleteConversationMessageUrl: getUrl(
                it,
                'deleteConversationMessage'
              )
            }))
            .concat(...state.messages);
        } else if (action.isMessageListOld === true) {
          messages = [...state.messages].concat(
            action.messageList._embedded.messages.map(it => ({
              ...it,
              getConversationMessageTemporaryFileUrl: getUrl(
                it,
                'getConversationMessageTemporaryFile'
              ),
              deleteConversationMessageUrl: getUrl(
                it,
                'deleteConversationMessage'
              )
            }))
          );
        }
        return {
          ...state,
          postConversationMessageTemporaryFileUrl:
            action.postConversationMessageTemporaryFileUrl,
          messages,
          lastMessageTimestamp: messages[0]?.createdOn
        };
      } else {
        const messages = [...state.messages].map(message => {
          const messageMatch = action.messageList._embedded.messages.filter(
            it => it.id === message.id
          )[0];
          return messageMatch
            ? {
                ...messageMatch,
                getConversationMessageTemporaryFileUrl: getUrl(
                  messageMatch,
                  'getConversationMessageTemporaryFile'
                ),
                deleteConversationMessageUrl: getUrl(
                  messageMatch,
                  'deleteConversationMessage'
                )
              }
            : message;
        });
        return {
          ...state,
          messages,
          lastUpdatedMessageTimestamp:
            action.messageList._embedded.messages
              .map(it => (!!it.lastEditedOn ? it.lastEditedOn : it.deletedOn))
              .sort((a, b) => b - a)[0] ?? 0
        };
      }
  }

  return state;
};
export const messageListLoadingReducer = (
  state: boolean = false,
  action: Action
): boolean => {
  switch (action.type) {
    case MailboxActions.LoadMessages:
    case MailboxActions.LoadConversation:
      return true;
    case MailboxActions.MessagesLoaded:
    case MailboxActions.FailedToLoadMessages:
    case MailboxActions.ConversationLoaded:
    case MailboxActions.FailedToLoadConversation:
      return false;
  }
  return state;
};

export const sendingMessageInProgressReducer = (
  state: boolean = false,
  action: Action
): boolean => {
  switch (action.type) {
    case MailboxActions.SendMessage:
      return true;
    case MailboxActions.FailedToSendMessage:
    case MailboxActions.MessageSent:
      return false;
  }
  return state;
};

export function clearState(
  reducer: ActionReducer<MailboxStateModel>
): ActionReducer<MailboxStateModel> {
  return (state: MailboxStateModel, action: Action): MailboxStateModel => {
    if (
      action.type === MailboxActions.ResetMailboxState ||
      action.type === GlobalReset.type
    ) {
      state = { ...initialState };
    }
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<MailboxStateModel>[] = [clearState];

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<MailboxStateModel>
>('[Mailbox] Registered Reducers');
