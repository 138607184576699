<div class="contract-status-and-date">
    <contract-status [contract]="contract" (openPublicProfile)="onOpenPublicProfile($event)"></contract-status>
    <staffnow-contract-info [label]="'GENERAL.START_DATE' | translate">
        <span *ngIf="hasStartDate" [ngClass]="{ 'text-danger': !datePhaseIsAccepted }">
            {{ contract?.startDate | absoluteDateFormat }}
        </span>
        <span *ngIf="!hasStartDate">
            {{ 'OFFER.STATUS.PENDING' | translate }}
        </span>
        <span *ngIf="datePhaseIsProposed" class="small">
            {{ proposedByLabel | translate }}
        </span>
    </staffnow-contract-info>
    <staffnow-contract-info
        *ngIf="!isPermanent || (isPermanent && contract.endDate)"
        [label]="'GENERAL.FINISH_DATE' | translate"
    >
        <span *ngIf="hasStartDate">
            {{ contract?.endDate | absoluteDateFormat }}
        </span>
        <span *ngIf="!hasStartDate">
            {{ 'GENERAL.PENDING_START_DATE' | translate }}
        </span>
    </staffnow-contract-info>
    <experience-letter-edition></experience-letter-edition>
</div>
