import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpWrapperComponent } from './help-wrapper.component';
import { FormsModule } from '@angular/forms';
import { BulletPointsComponent } from './components/bullet-points/bullet-points.component';
import { TranslationModule } from '@libs/translation/translation.module';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule,
    FormsModule,
    TranslationModule
  ],
  declarations: [
    HelpWrapperComponent,
    BulletPointsComponent,
  ]
})
export class HelpWrapperModule {}
