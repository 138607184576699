<div class="mro-registration-form">
    <form *ngIf="form" novalidate [ngrxFormState]="form">
        <h1>{{ 'REGISTRATION.STEPS.COMPANY_DETAILS.TITLE' | translate }}</h1>
        <!-- COMPANY NAME -->
        <div class="content">
            <div>
                <input
                    class="form-control"
                    type="text"
                    [placeholder]="'REGISTRATION.STEPS.COMPANY_DETAILS.NAME.PLACEHOLDER' | translate"
                    [ngrxFormControlState]="form.controls.companyName"
                    (blur)="searchCompany()"
                />
                <small class="validation-error">
                    {{
                        form.controls.companyName | validationError | translate
                    }}
                </small>
            </div>
            <!-- COMPANY LOCATION -->
            <div>
                <ng-select
                    bindLabel="name"
                    [placeholder]="'REGISTRATION.STEPS.COMPANY_DETAILS.LOCATION.PLACEHOLDER' | translate"
                    [ngModelOptions]="{ standalone: true }"
                    [closeOnSelect]="true"
                    [clearSearchOnAdd]="true"
                    [searchable]="true"
                    [items]="locationList"
                    [(ngModel)]="selectedLocation"
                    (change)="writeLocationIdToForm()"
                ></ng-select>
                <small class="validation-error">
                    {{ form.controls.locationId | validationError | translate }}
                </small>
            </div>
            <!-- REQUESTER NAME -->
            <div>
                <input
                    class="form-control"
                    type="text"
                    [placeholder]="'REGISTRATION.STEPS.COMPANY_DETAILS.YOUR_NAME.PLACEHOLDER' | translate"
                    [ngrxFormControlState]="form.controls.requesterName"
                />
                <small class="validation-error">
                    {{
                        form.controls.requesterName
                            | validationError
                            | translate
                    }}
                </small>
            </div>
            <!-- REQUESTER LAST NAME -->
            <div>
                <input
                    class="form-control"
                    type="text"
                    [placeholder]="'REGISTRATION.STEPS.COMPANY_DETAILS.YOUR_LAST_NAME.PLACEHOLDER' | translate"
                    [ngrxFormControlState]="form.controls.requesterLastName"
                />
                <small class="validation-error">
                    {{
                        form.controls.requesterLastName
                            | validationError
                            | translate
                    }}
                </small>
            </div>
            <!-- REQUESTER POSITION IN THE COMPANY -->
            <div>
                <input
                    class="form-control"
                    type="text"
                    [placeholder]="'REGISTRATION.STEPS.COMPANY_DETAILS.POSITION.PLACEHOLDER' | translate"
                    [ngrxFormControlState]="form.controls.requesterPosition"
                />
                <small class="validation-error">
                    {{
                        form.controls.requesterPosition
                            | validationError
                            | translate
                    }}
                </small>
            </div>
            <!-- REQUESTER EMAIL -->
            <div>
                <input
                    class="form-control"
                    type="email"
                    [placeholder]="'REGISTRATION.STEPS.COMPANY_DETAILS.EMAIL.PLACEHOLDER' | translate"
                    [ngrxFormControlState]="form.controls.requesterEmail"
                />
                <small class="validation-error">
                    {{
                        form.controls.requesterEmail
                            | validationError
                            | translate
                    }}
                </small>
            </div>
            <!-- CONTACT PHONE NUMBER -->
            <div>
                <input
                    class="form-control"
                    type="text"
                    minlength="6"
                    maxlength="20"
                    [placeholder]="
                        'REGISTRATION.STEPS.COMPANY_DETAILS.PHONE.PLACEHOLDER' | translate
                    "
                    [ngrxFormControlState]="form.controls.contactPhone"
                />
                <small class="validation-error">
                    {{
                        form.controls.contactPhone | validationError | translate
                    }}
                </small>
            </div>
            <h1>
                {{
                    'REGISTRATION.STEPS.COMPANY_DETAILS.ROLES'
                        | translate
                }}
            </h1>
            <div class="d-flex justify-content-center">
                <div
                    class="custom-control staffnow-checkbox custom-checkbox d-inline-block mr-4"
                >
                    <input
                        class="custom-control-input"
                        id="production"
                        type="checkbox"
                        [ngrxFormControlState]="
                            form.controls.requiresProductionAcceptance
                        "
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="production"
                    >
                        {{ 'Production' | translate }}
                    </label>
                </div>
                <div
                    class="custom-control staffnow-checkbox custom-checkbox d-inline-block mr-4"
                >
                    <input
                        class="custom-control-input"
                        id="quality"
                        type="checkbox"
                        [ngrxFormControlState]="
                            form.controls.requiresQualityAcceptance
                        "
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="quality"
                    >
                        {{ 'Quality' | translate }}
                    </label>
                </div>
                <div
                    class="custom-control staffnow-checkbox custom-checkbox d-inline-block"
                >
                    <input
                        class="custom-control-input"
                        id="hr"
                        type="checkbox"
                        [ngrxFormControlState]="
                            form.controls.requiresHumanResourcesAcceptance
                        "
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="hr"
                    >
                        {{ 'Human Resources' | translate }}
                    </label>
                </div>
            </div>
            <small class="validation-error">
                {{
                    form.controls.requiresHumanResourcesAcceptance.isInvalid &&
                    userHasInteractedWithRoles()
                        ? 'You need to select 1 or 2 roles'
                        : null
                }}
            </small>
            <h1>{{ 'WHICH IS YOUR ROLE?' | translate }}</h1>
            <div class="d-flex justify-content-center">
                <div
                    class="custom-control staffnow-checkbox custom-radio d-inline-block mr-4"
                >
                    <input
                        id="production1"
                        class="custom-control-input"
                        type="radio"
                        [ngrxFormControlState]="form.controls.userRole"
                        [value]="getUserRoleProduction()"
                        [checked]="isProduction()"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="production1"
                    >
                        {{ 'Production' | translate }}
                    </label>
                </div>
                <div
                    class="custom-control staffnow-checkbox custom-radio d-inline-block mr-4"
                >
                    <input
                        id="quality1"
                        class="custom-control-input"
                        type="radio"
                        [ngrxFormControlState]="form.controls.userRole"
                        [value]="getUserRoleQuality()"
                        [checked]="isQuality()"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="quality1"
                    >
                        {{ 'Quality' | translate }}
                    </label>
                </div>
                <div
                    class="custom-control staffnow-checkbox custom-radio d-inline-block"
                >
                    <input
                        id="hr1"
                        class="custom-control-input"
                        type="radio"
                        [ngrxFormControlState]="form.controls.userRole"
                        [value]="getUserRoleHumanResources()"
                        [checked]="isHumanResources()"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="hr1"
                    >
                        {{ 'Human Resources' | translate }}
                    </label>
                </div>
            </div>
            <small class="validation-error">
                {{ form.controls.userRole | validationError | translate }}
            </small>
        </div>
    </form>
    <div class="mro-registration-actions">
        <general-button
            (onClick)="reset()"
            backgroundStyle="outline"
            [text]="'GENERAL.CANCEL' | translate"
        ></general-button>
        <general-button
            (onClick)="next()"
            [isDisabled]="form.isInvalid"
            [text]="'GENERAL.NEXT' | translate"
            icon="arrow_forward"
            iconPosition="right"
        ></general-button>
    </div>
</div>
