import { Resource } from '../bms-common/rest/resource.model';
import { Rate } from './rate.model';
import { SpecificMarkup } from '@libs/shared/models/specific-markup.model';
import { MroRate } from '@libs/shared/models/mro-rate.model';

export enum FacilityStatus {
  PENDING_REVIEW = 'PENDING_REVIEW',
  VERIFIED = 'VERIFIED',
  DELETED = 'DELETED'
}

export interface Facility extends Resource {
  type: string;
  uuid: string;
  name: string;
  location: string;
  createdOn: string;
  shortPresentation: string;
}

export interface PublicAgency extends Facility {
  orderIndex: number;
  slogan: string;
  payRate: Rate;
  maxPayRate: Rate;
}

export interface AgencyFacility extends PublicAgency {
  isFake: boolean;
  markUp: number;
  services: string;
  mroApprovals: Array<SimpleFacilityWithUuid>;
  specificMarkups: Array<SpecificMarkup>;
}

export interface MroGroup {
  id: number;
  name: string;
  createdOn: string;
  isThreeMonthRuleAccepted: boolean;
}


export interface FacilityPage extends Resource {
  term?: string
  page: number;
  pageSize: number;
  totalElements: number;
  _embedded: {
    facilities: MroFacility[];
  };
}


export interface MroFacility extends Facility {
  groupId: number;
  checklist: string;
  documentsNeededTemplate: string;
  groupName: string;
  offerDoubleCheck: boolean;
  allowPrivateOffers: boolean;
  allowExperienceLetterRequest: boolean;
  requiresAgencySelectionForOfferApplication: boolean;
  status: FacilityStatus;
  rates: Array<MroRate>;
  workingCurrency: string;
  hideMroInformationInJobOffers: boolean;
  allowTcnApplicationsToggle: boolean;
}

export interface SimpleFacilityWithId {
  id: number;
  name: string;
}

export interface SimpleFacilityWithUuid extends Resource {
  uuid: string;
  name: string;
}

export interface PackageOfferAgency extends Resource {
  uuid: string;
  fullName: string;
}
