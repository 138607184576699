import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { FormGroupState, SetValueAction } from 'ngrx-forms';
import { Store } from '@ngrx/store';
import { mroCompanyDetailsForm } from '../../../../state/registration.selectors';
import Step from '../../stepper/step.interface';
import { MroCompanyDetails } from '../../../../state/registration-state.model';
import { CompanySearch } from '../../../../state/registration.actions';
import { isEmpty, isNil } from 'lodash-es';
import { UserRoles, UserRolesUtils } from '@libs/shared/models/roles.enum';
import { LocationPO } from "@libs/shared/models/location.model";
import { getLocations } from "@libs/shared/bms-common/environment/environment.selector";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";


@UntilDestroy()
@Component({
  selector: 'staffnow-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss', '../../../common.scss']
})
export class CompanyDetailsComponent implements OnInit, Step {
  @Output() public onNext = new EventEmitter();
  @Output() public onReset = new EventEmitter();

  public locationList: LocationPO[] = [];
  public selectedLocation: LocationPO = null;
  public form: FormGroupState<MroCompanyDetails>;

  constructor(private store: Store<any>) {
    this.store.pipe(getLocations, untilDestroyed(this)).subscribe(locationList => {
      this.locationList = locationList;
    });
  }

  ngOnInit(): void {
    this.store.select(mroCompanyDetailsForm).pipe(untilDestroyed(this)).subscribe(registrationForm => {
      this.form = registrationForm;
      const locationId = this.form.controls.locationId.value;
      if (isNil(this.selectedLocation) && !isNil(locationId)) {
        this.selectedLocation = this.locationList.find(location => location.id == locationId);
      }
    });
  }

  next(): void {
    if (!this.form.isInvalid) {
      this.onNext.emit();
    }
  }

  reset(): void {
    this.onReset.emit();
  }


  public userHasInteractedWithRoles(): boolean {
    const hrDirty = this.form.controls.requiresHumanResourcesAcceptance.isDirty;
    const productionDirty = this.form.controls.requiresProductionAcceptance
      .isDirty;
    const qualityDirty = this.form.controls.requiresQualityAcceptance.isDirty;
    return hrDirty || productionDirty || qualityDirty;
  }

  public searchCompany(): void {
    const { id, value } = this.form.controls.companyName;
    if (!isEmpty(value)) {
      this.store.dispatch(
        CompanySearch({
          controlId: id,
          companyName: value
        })
      );
    }
  }

  public isProduction(): boolean {
    return UserRolesUtils.isMroProduction(this.form.controls.userRole);
  }

  public isQuality(): boolean {
    return UserRolesUtils.isMroQuality(this.form.controls.userRole);
  }

  public isHumanResources(): boolean {
    return UserRolesUtils.isMroHumanResources(this.form.controls.userRole);
  }

  public getUserRoleProduction(): string {
    return UserRoles.ROLE_MRO_PRODUCTION;
  }

  public getUserRoleQuality(): string {
    return UserRoles.ROLE_MRO_QUALITY;
  }

  public getUserRoleHumanResources(): string {
    return UserRoles.ROLE_MRO_HUMAN_RESOURCES;
  }

  public writeLocationIdToForm(): void {
    this.store.dispatch(
        new SetValueAction(
            this.form.controls.locationId.id,
            this.selectedLocation?.id
        )
    );
  }

}
