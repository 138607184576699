import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getAirplanesSortedByName } from '../bms-common/api-root/api-root.selectors';
import { isEmpty } from 'lodash-es';

@Pipe({
  name: 'selectedAircraft'
})
export class SelectedAircraftPipe implements PipeTransform {
  private airplanes: Array<any> = [];

  constructor(private store: Store<any>) {
    this.store
      .pipe(getAirplanesSortedByName, take(1))
      .subscribe((aircraftList: any) => {
        this.airplanes = aircraftList;
      });
  }

  transform(value: Array<any>, args?: any): any {
    if (!isEmpty(value)) {
      return value
        .map(selectedAirplane => {
          if (
            selectedAirplane &&
            (selectedAirplane.id || selectedAirplane.id === 0)
          ) {
            return selectedAirplane.name;
          } else {
            const airplane = this.airplanes.find(
              airplane => airplane.id === selectedAirplane
            );

            return airplane ? airplane.name : null;
          }
        })
        .join(', ');
    }
    return '';
  }
}
