import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '@libs/shared/helpers/abstract-value-accessor';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';
import { AmeLicenseLevel } from '@libs/shared/models/ame-license-level.model';

@Component({
  selector: 'title-selection',
  templateUrl: './title-selection.component.html',
  styleUrls: ['./title-selection.component.scss'],
  providers: [MakeProvider(TitleSelectionComponent)]
})
export class TitleSelectionComponent extends AbstractValueAccessor {
  @Input() public ameNomenclature: AmeLicenseType[] = [];
  public levels: AmeLicenseLevel[] = [];
  public selectedTitleType: string = null;
  public selectedTitleId: number = null;
  public isDisabled = false;

  constructor() {
    super();
  }

  onAmeTypeChange() {
    this.selectedTitleId = null;
    this.updateLevels();
    this.updateValue();
  }

  onAmeLicenseChange() {
    this.updateValue();
  }

  get selectedTypeIsSingleton(): boolean {
    return (
      this.selectedTitleType && this.ameNomenclature.find(type => type.name === this.selectedTitleType)?.isSingleton
    );
  }

  writeValue(value: any) {
    this._value = value;
    if (this._value) {
      this.selectedTitleType = this._value.type;
      this.selectedTitleId = this._value.id;
      this.updateLevels();
    }
  }

  private updateLevels() {
    this.levels = this.selectedTitleType ? this.findLicenseType(this.selectedTitleType)?.licenses : [];
    if (this.selectedTypeIsSingleton) {
      this.selectedTitleId = this.levels[0].id;
    }
  }

  private findLicenseType(ameTypeId): AmeLicenseType {
    return this.ameNomenclature.find(ameType => ameType.name === ameTypeId);
  }

  private updateValue() {
    this.value = {
      type: this.selectedTitleType,
      id: this.selectedTitleId
    };
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
