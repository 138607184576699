import { Contract } from '@libs/shared/models/contract.model';

export enum ContractStatus {
  accepted = 'ACCEPTED',
  pending = 'PENDING',
  rejected = 'REJECTED',
  documentsNeeded = 'DOCUMENTS_NEEDED'
}

export class ContractStatusUtils {
  private static getStatuses(contract: Contract): Array<ContractStatus> {
    const relevantStatuses = [contract?.technicianStatus];
    if (contract?.requiresAgencyAcceptance) {
      relevantStatuses.push(contract?.agencyStatus);
    }
    if (contract?.requiresProductionAcceptance) {
      relevantStatuses.push(contract?.mroStatus);
    }
    if (contract?.requiresQualityAcceptance) {
      relevantStatuses.push(contract?.mroQualityStatus);
    }
    if (contract?.requiresHumanResourcesAcceptance) {
      relevantStatuses.push(contract?.mroHumanResourcesStatus);
    }
    return relevantStatuses;
  }

  public static isPending(contract: Contract): boolean {
    const statuses = ContractStatusUtils.getStatuses(contract);
    return (
      !statuses.includes(ContractStatus.rejected) &&
      (statuses.includes(ContractStatus.pending) ||
        statuses.includes(ContractStatus.documentsNeeded))
    );
  }

  public static isRejected(contract: Contract): boolean {
    return ContractStatusUtils.getStatuses(contract).includes(
      ContractStatus.rejected
    );
  }

  public static isAccepted(contract: Contract): boolean {
    return ContractStatusUtils.getStatuses(contract).every(
      status => status === ContractStatus.accepted
    );
  }

  public static isAcceptedByTechnician(contract: Contract) {
    return contract?.technicianStatus === ContractStatus.accepted;
  }

  public static isPendingByBothMro(contract: Contract) {
    const statuses = [];
    if (contract?.requiresProductionAcceptance) {
      statuses.push(contract.mroStatus);
    }
    if (contract?.requiresQualityAcceptance) {
      statuses.push(contract.mroQualityStatus);
    }
    if (contract?.requiresHumanResourcesAcceptance) {
      statuses.push(contract.mroHumanResourcesStatus);
    }
    return statuses.every(status => status == ContractStatus.pending);
  }

  public static isPendingByMroQuality(contract: Contract) {
    return (
      contract?.requiresQualityAcceptance &&
      contract?.mroQualityStatus === ContractStatus.pending
    );
  }

  public static isPendingByMroProduction(contract: Contract) {
    return (
      contract?.requiresProductionAcceptance &&
      contract?.mroStatus === ContractStatus.pending
    );
  }

  public static isPendingByMroHumanResources(contract: Contract) {
    return (
      contract?.requiresHumanResourcesAcceptance &&
      contract?.mroHumanResourcesStatus === ContractStatus.pending
    );
  }

  public static isPendingByTechnician(contract: Contract): boolean {
    return contract?.technicianStatus === ContractStatus.pending;
  }

  public static isRejectedByAgency(contract: Contract): boolean {
    return (
      contract?.requiresAgencyAcceptance &&
      contract?.agencyStatus === ContractStatus.rejected
    );
  }

  public static isPendingByAgency(contract: Contract): boolean {
    return (
      contract?.requiresAgencyAcceptance &&
      contract?.agencyStatus === ContractStatus.pending
    );
  }

  public static isRejectedByAnyMro(contract: Contract) {
    return [
      contract?.mroStatus,
      contract?.mroQualityStatus,
      contract?.mroHumanResourcesStatus
    ].includes(ContractStatus.rejected);
  }
}
