<ng-template [ngIf]="authorizedUserProfile">
    <staffnow-header
        [notificationsCount]="bellNotificationsCount"
        [messagesCount]="mailboxNotificationsCount"
        [hasMailbox]="hasMailboxLink"
        [version]="version"
        [authorizedUser]="authorizedUserProfile"
        [requestTechnicianDeletionLink]="requestTechnicianDeletionLink"
        [menuItems]="menuItems"
        (toggleMenu)="toggleAsideMenu()"
        (logoutUser)="logoutUser()"
        (toggleNotifications)="toggleNotificationList($event)"
        (changeCookiesSettings)="openCookieBot()"
    >
        <ng-container region-selector>
            <staffnow-region-selector></staffnow-region-selector>
        </ng-container>
        <ng-container notification-list>
            <notification-list
                *ngIf="notificationListOpened"
                [notificationsCount]="bellNotificationsCount"
                closeOnOutside
                [triggerClass]="['notifications-trigger']"
                (close)="toggleNotificationList()"
            ></notification-list>
        </ng-container>
    </staffnow-header>
    <staffnow-navbar-mobile
        [menuItems]="menuItems"
        [isAsideMenuOpen]="isAsideMenuOpen"
        (clickOutside)="toggleAsideMenu()"
    >
    </staffnow-navbar-mobile>
    <staffnow-feedback-alert *ngIf="isNotGivenFeedback()"></staffnow-feedback-alert>
</ng-template>
<main
    [ngClass]="{
        'logged-user': authorizedUserProfile,
        'notifications-opened': notificationListOpened,
        'feedback-displayed': isNotGivenFeedback() && !isFeedBackListEmpty
    }"
>
    <div *ngIf="isAppLoading; else appContent" class="cover-full-height splash-screen-container">
        <div>
            <b>{{ brandName }}</b> is loading
        </div>
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    <ng-template #appContent>
        <router-outlet> </router-outlet>
    </ng-template>
    <div *ngIf="isRedirectingToRightRegionApp" class="redirect-to-other-region-banner">
        <div class="content">
            <div class="loader-wrapper">
                <img class="loader" src="../assets/images/loader.gif" alt="other platform logo" />
            </div>
            <p class="message">
                You will be redirected in
                <span class="highlight timer"> {{ timeToRedirectInSeconds }} sec. </span>
                to
                <span class="highlight">{{ otherBrandName }}</span
                >, our platform operating in
                <span class="highlight">{{ otherRegionName }}</span>
            </p>
            <div class="divider" [ngClass]="brandName | lowercase"></div>
        </div>
    </div>
</main>
<toast-list></toast-list>
