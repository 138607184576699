<div
    bsModal
    #modalContainer="bs-modal"
    [config]="{ ignoreBackdropClick: disableBackdrop }"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    (onHidden)="handleHidden()"
>
    <div
        class="modal-dialog"
        [ngClass]="{
            'modal-sm': smallModal,
            'modal-lg': !smallModal && !autoSize && !largeModal,
            '': autoSize,
            'personal-offer': isOffer
        }"
    >
        <div class="modal-content">
            <i class="material-icons close-icon" (click)="close()"> clear </i>
            <ng-container
                *ngTemplateOutlet="content; context: { isInModal: true }"
            ></ng-container>
        </div>
    </div>
</div>
