import { Pipe, PipeTransform } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { FormErrorMessages, PARAMETER_PLACEHOLDER } from '../constants/FormValidationErrorMessages';
import { isEmpty } from 'lodash-es';

@Pipe({
  name: 'validationError'
})
export class ErrorMessagePipe implements PipeTransform {
  private ERROR_MESSAGES = FormErrorMessages;

  transform(value: FormControlState<any>): string {
    if (value && (value.isTouched || value.isDirty) && !isEmpty(value.errors)) {
      const errorKey: string = Object.keys(value.errors)[0];

      if (value.id.includes('email') && errorKey === 'pattern') {
        const error = this.ERROR_MESSAGES['email'];

        return error.message.replace(PARAMETER_PLACEHOLDER, this.getErrorKeyEmail(value.id));
      } else {
        const error: { message: string; paramName?: string } = this.ERROR_MESSAGES[errorKey];
        if (error) {
          return error.message.replace(PARAMETER_PLACEHOLDER, value.errors[errorKey][error.paramName]).toString();
        }
      }
    }

    return '';
  }

  getErrorKeyEmail(id: string): string {
    return id.split('.')[1];
  }
}
