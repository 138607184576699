import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, debounceTime, Observable, switchMap } from 'rxjs';
import { FacilityPage } from '@libs/shared/models/facility.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { MroFacilitySimple } from '../../../../../apps/staffnow-platform/src/app/state/app-state.model';

@Component({
  selector: 'staffnow-select-entity-from-list-modal',
  templateUrl: './select-entity-from-list-modal.component.html',
  styleUrls: ['./select-entity-from-list-modal.component.scss']
})
export class SelectEntityFromListModalComponent {
  @Input() public title: string = null;
  @Input() public confirmLabel: string = 'Confirm';
  @Input() public entityRetriever: {
    retrieve: (url: string, term: string, pageNumber: number, pageSize: number) => Observable<FacilityPage>,
    linkRel: ApiRootLinkRel,
    url?: string
  }
  @Input() public onConfirmCallback: Function = null;
  @Input() public placeholder: string = null;
  @Input() public bindLabel: string = null;
  entitySearchObservable = new BehaviorSubject({term: '', pageSize: 20, page: 0, facilities: []});
  entityList$: Observable<MroFacilitySimple[]>;
  loading: boolean = false;
  public selectedEntity: any = null;

  constructor(public bsModalRef: BsModalRef,
              private readonly store: Store) {
    this.entityList$ = this.entitySearchObservable
      .pipe(
        debounceTime(250),
        tap(() => this.loading = true),
        withLatestFrom(this.store.pipe(getFilteredApiRoot)),
        switchMap(([{ term, pageSize, page, facilities }, apiRoot]) =>
          this.entityRetriever.retrieve(this.entityRetriever.url || getUrl(apiRoot, this.entityRetriever.linkRel), term, page, pageSize)
            .pipe(
              map((retrievedFacilities) => [
                ...facilities,
                ...retrievedFacilities._embedded.facilities.map(facility => ({uuid: facility.uuid, name: facility.name }))
              ]),
              tap(() => this.loading = false),
            )
        ),
      );
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public onConfirm() {
    this.onConfirmCallback(this.selectedEntity);
    this.close();
  }

  public isFormValid() {
    return !!this.selectedEntity;
  }

  onSearch(term: string) {
    this.entitySearchObservable.next({
      page: 0,
      term,
      pageSize: 20,
      facilities: []
    });
  }

  onClose() {
    this.onSearch('');
  }

  loadMore(facilities: MroFacilitySimple[]) {
    const currentPageableValues = this.entitySearchObservable.getValue();
    this.entitySearchObservable.next({
      ...currentPageableValues,
      facilities,
      page: currentPageableValues.page + 1});
  }
}
