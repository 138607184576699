import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export const REGISTRATION_FORM_ID = 'registrationForm';

export interface RegistrationFormModel {
  facilityUuid?: string;
  position?: string;
  firstName: string;
  lastName: string;
  role?: string;
  email: string;
  defaultUser?: boolean;
  agencyUuid?: string;
  staffieMroUuid?: string;
}

export interface FormState extends FormGroupState<RegistrationFormModel> {}

export const initialFormState: FormState = createFormGroupState<RegistrationFormModel>(
  'registrationForm',
  {
    facilityUuid: '',
    position: '',
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    defaultUser: false,
    agencyUuid: null,
    staffieMroUuid: null
  }
);
