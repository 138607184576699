<div class="registration-component-wrapper">
    <div class="logo-container">
        <div class="logo"></div>
    </div>
    <staffnow-success
        *ngIf="complete"
        (onClose)="handleReset()"
    ></staffnow-success>
    <staffnow-stepper
        *ngIf="!complete"
        [steps]="steps"
        (onReset)="handleReset()"
        (onComplete)="onComplete()"
    ></staffnow-stepper>
</div>
