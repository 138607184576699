import {Component, Input} from '@angular/core';
import {NotifyContractEnd} from '../../state/request-overview.actions';
import {AgencyContractDto} from '@libs/shared/models/contract.model';
import {RequestOverviewLinkRel} from '@libs/shared/linkrels/request-overview.linkrel';
import {hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {AbstractContractCardComponent} from './abstract-contract-card.component';
import {StartDatePhase} from '@libs/shared/models/start-date-phase.model';
import {isFirstDateBeforeTheSecond} from "@libs/shared/helpers/date-utils";
import {DateTime} from "luxon";

@Component({
  selector: 'staffnow-agency-contract-card',
  templateUrl: './agency-contract-card.component.html',
  styleUrls: [
    './mro-contract-card.component.scss',
    './agency-contract-card.component.scss'
  ]
})
export class AgencyContractCardComponent extends AbstractContractCardComponent {
  @Input() public contract: AgencyContractDto = null;
  @Input() public offerRefNumber: string = null;
  @Input() public offerStartDate: string = null;
  @Input() public reasonsArray: Array<any> = [];

  get hasNotifyContractEndLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.NotifyContractEnd);
  }

  get hasDeclineContractLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineContract);
  }

  get declineOfferTooltip(): string {
    return this.isDeclineDateLimitReached()
      ? this.translateService.instant('SYSTEM.INFO.DISABLED_AGENCY_DECLINE_BUTTON')
      : this.translateService.instant('SYSTEM.INFO.DECLINE_JOB_OPENING');
  }

  get canCreateConversationWithMro(): boolean {
    return this.conversationService.canCreateConversationWithMro(this.contract);
  }

  get canGetPreviousConversationWithMro(): boolean {
    return this.conversationService.canGetPreviousConversationWithMro(
      this.contract
    );
  }

  public isDeclineDateLimitReached(): boolean {
    return isFirstDateBeforeTheSecond(this.contract?.agencyDeclineDateLimit, DateTime.utc().toString());
  }

  public declineContract() {
    this.jobOpeningContractDeclinationService.onDeclineAgency(
      this.contract,
      this.reasonsArray[0]
    );
  }

  public initiateMessageWithMro(): void {
    this.conversationService.initiateMessageWithMro(this.contract);
  }

  public notifyContractEnd(): void {
    this.store.dispatch(
      NotifyContractEnd({
        contract: this.contract,
        offerRefNumber: this.offerRefNumber
      })
    );
  }

  public needsToPickADate(): boolean {
    return this.contract.startDatePhase !== StartDatePhase.NOT_INITIATED;
  }
}
