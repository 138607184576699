import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { GenericModalComponent } from '../modals/generic-modal/generic-modal.component';
import { CustomerSupportModalComponent } from '../customer-support-error-modal/customer-support-modal.component';
import { QuestionModalComponent } from '@libs/common-ui/modals/question-modal/question-modal.component';
import { PackageOfferDto } from '@libs/shared/models/offer.model';
import { CreatePackageContractFormComponent } from '@libs/common-ui/create-package-contract-form/create-package-contract-form.component';
import { NumberPickerDialogComponent } from '@libs/common-ui/number-picker-dialog/number-picker-dialog.component';
import { Reminder } from '@libs/common-ui/models/reminder.model';
import { PackageContract } from '@libs/shared/models/package-contract.model';
import { isNil } from 'lodash-es';
import { TextAreaDialogComponent } from '../text-area-dialog/text-area-dialog.component';
import {
  DocumentChange,
  DocumentChangeConfirmationModalComponent
} from '../../../../../apps/back-office/src/app/modules/manage-content/components/document-change-confirmation-modal/document-change-confirmation-modal.component';

@Injectable()
export class ModalService {
    constructor(private bsModalService: BsModalService) {}

    public openExperienceLetterQuestionModal(
        onConfirmedCallback: Function,
        onDeclinedCallback: Function
    ): void {
        this.bsModalService.show(QuestionModalComponent, {
            initialState: {
                question:
                    'Do you want the Service Center to request your experience letter on your behalf if you are accepted in this job opening?',
                onConfirmedCallback,
                onDeclinedCallback
            },
            class: 'modal-center'
        });
    }

  public openCustomerSupportErrorModal(texts: { title: string; message: string }) : void {
    this.bsModalService.show(CustomerSupportModalComponent, {
      initialState: texts,
      class: 'modal-center',
      ignoreBackdropClick: true
    });
  }

    public openConfirmModal(message: string, onConfirmedCallback: Function) : void {
        this.openCustomizableConfirmModal({
            messages: [message],
            onConfirmedCallback: onConfirmedCallback
        });
    }

    public openCustomizableConfirmModal(params: {
        messages: string[];
        icon?: string;
        acceptText?: string;
        cancelText?: string;
        canCancel?: boolean;
        onConfirmedCallback: Function;
        onCancelCallback?: Function;
    }): void {
        this.bsModalService.show(GenericModalComponent, {
            initialState: {
                ...params
            },
            class: 'modal-center',
            ignoreBackdropClick: true
        });
    }

    public openAlertModal(
        texts: { message: string; accept?: string },
        onConfirmedCallback: Function = () => {}
    ): void {
        this.openCustomizableConfirmModal({
            onConfirmedCallback: onConfirmedCallback,
            messages: [texts.message],
            canCancel: false,
            acceptText: texts.accept ?? 'OK'
        });
    }

    public openCreatePackageContractForm(
        offer: PackageOfferDto,
        onClick: (payload) => void,
        contract?: PackageContract
    ): void {
        const mode = !isNil(contract) ? 'EDIT' : 'CREATE';
        this.bsModalService.show(CreatePackageContractFormComponent, {
            class: 'package-offer-application-modal modal-center',
            initialState: { offer, onClick, contract, mode }
        });
    }

    public openNumberPickerDialog(
        title: string,
        placeholder: string,
        onAcceptCallback: Function,
        warningMessage: string = null,
        reminders: Reminder[] = []
    ): void {
        this.bsModalService.show(NumberPickerDialogComponent, {
            class: 'number-picker-modal modal-center',
            initialState: {
                onAcceptCallback,
                title,
                placeholder,
                warningMessage,
                reminders
            }
        });
    }

    public openTextAreaDialog(
        title: string,
        body: string,
        disclaimer: string,
        onAcceptCallback: Function
    ): void {
        this.bsModalService.show(TextAreaDialogComponent, {
            ignoreBackdropClick: true,
            class: 'job-offer-modal',
            initialState: {
                title,
                body,
                disclaimer,
                onAcceptCallback
            }
        });
    }

    public openDocumentChangeDialog(changes: DocumentChange[], brand: string, onAcceptCallback: () => void): void {
      this.bsModalService.show(DocumentChangeConfirmationModalComponent, {
        class: 'modal-center',
        initialState: {
          changes,
          brand,
          onAcceptCallback
        }
      });
    }
}
