import { createAction, props } from '@ngrx/store';
import { Country } from '@libs/shared/models/country.model';
import { State } from '@libs/common-ui/country/state/country-selector.state.model';

export const LoadCountry = createAction('[Country Selector] Load Country', props<{ country: Country }>());

export const StatesLoaded = createAction(
  '[Country Selector] States loaded',
  props<{ states: State[]; country: Country }>()
);

export const StatesAlreadyCached = createAction('[Country Selector] States Already Cached');

export const FailedToLoadStates = createAction('[Country Selector] Failed To Load States');

export const LoadCountryByName = createAction(
  '[Country Selector] Load Country By Name',
  props<{ countryName: string }>()
);
