<div class="conversation-container">
    <ng-container
        *ngIf="conversation?.conversationId; else noConversationSelected"
    >
        <staffnow-conversation-header
            [conversation]="conversation"
            (closeChat)="handleClose()"
        ></staffnow-conversation-header>
        <message-list
            [loading]="messageListLoading && !automaticMessageLoading"
            [messageList]="messageList"
            [conversation]="conversation"
            [ownPicUrl]="ownPicUrl"
            (loadOlderMessages)="loadOlderMessages($event)"
        ></message-list>
        <staffnow-message-input
            [conversation]="conversation"
        ></staffnow-message-input>
    </ng-container>

    <ng-template #noConversationSelected>
        <div class="no-messages">
            <i class="material-icons"> airplanemode_active </i>
            <div class="no-conversation-selected">
                {{
                    'Please, select a conversation to load messages.'
                        | translate
                }}
            </div>
        </div>
    </ng-template>
</div>
