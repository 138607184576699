import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-select-entities-from-list-modal',
  templateUrl: './select-entities-from-list-modal.component.html',
  styleUrls: ['./select-entities-from-list-modal.component.scss']
})
export class SelectEntitiesFromListModalComponent {
  @Input() public entityList: Array<{ fullName: string }> = null;
  @Input() public onConfirmCallback: Function = null;
  @Input() public placeholder: string = null;
  public selectedEntities: Array<any> = [];

  constructor(public bsModalRef: BsModalRef) {}

  public close(): void {
    this.bsModalRef.hide();
  }

  public onAdd(): void {
    this.onConfirmCallback(this.selectedEntities);
    this.close();
  }

  public isFormValid(): boolean {
    return this.selectedEntities.length > 0;
  }

  public handleChange(selectedItems: any[]): void {
    this.selectedEntities = selectedItems;
  }

}
