<h1 class="h1">
    {{
        (isSubscribedToNewsletter
            ? 'GENERAL.USER_ACCOUNT.NEWSLETTER_SUBSCRIBED_TEXT'
            : 'GENERAL.USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBED_TEXT'
        ) | translate
    }}
</h1>
<general-button
    [text]="
        (isSubscribedToNewsletter
            ? 'GENERAL.USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBE'
            : 'GENERAL.USER_ACCOUNT.NEWSLETTER_SUBSCRIBE'
        ) | translate
    "
    [isLoading]="isClicked"
    [isDisabled]="isClicked"
    (onClick)="handleChange()"
></general-button>
