import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {isNil} from "lodash-es";
import {AutomaticReply, DateAndTimeConfig} from "../automatic-reply.model";
import {getCurrentUTCDateString} from "@libs/shared/helpers/date-utils";

@Component({
  selector: 'automatic-reply-form',
  templateUrl: './automatic-reply-form.component.html',
  styleUrls: ['./automatic-reply-form.component.scss']
})
export class AutomaticReplyFormComponent implements OnInit {
  @Input() public existingReply: AutomaticReply = null;
  @Input() public onSendCallback: Function = null;
  public minimumDate: string = getCurrentUTCDateString();
  public automaticMessage: string = null;
  public isEnabled: boolean = false;
  public fromDate: DateAndTimeConfig = null;
  public toDate: DateAndTimeConfig = null;

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
    if (this.existingReply) {
      this.toDate = this.existingReply.toDateConfig;
      this.fromDate = this.existingReply.fromDateConfig;
      this.isEnabled = this.existingReply.isEnabled;
      this.automaticMessage = this.existingReply.message;
    }
  }

  public isDisabled(): boolean {
    return (
      isNil(this.fromDate) ||
      isNil(this.toDate) ||
      this.isMissing(this.automaticMessage)
    );
  }

  public isMissing(value: string): boolean {
    const trimmedValue = value?.trim();
    return isNil(trimmedValue) || trimmedValue.length == 0;
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public send(): void {
    this.onSendCallback(this.preparePayload());
    this.close();
  }

  private preparePayload(): AutomaticReply {
    return {
      fromDateConfig: this.fromDate,
      toDateConfig: this.toDate,
      message: this.automaticMessage,
      isEnabled: this.isEnabled
    };
  }

  public handleStartDateChange(payload: DateAndTimeConfig): void {
    this.fromDate = payload;
  }

  public handleEndDateChange(payload: DateAndTimeConfig): void {
    this.toDate = payload;
  }

}
