import { OfferPriority } from '@libs/shared/models/offer.model';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export interface CreatePackageOfferFormModel {
  title?: string;
  mroUuid?: string;
  refNumber?: string;
  useCustomReference: boolean;
  locationId: number;
  specification: string;
  preferredEndDate: string;
  preferredStartDate: string;
  priority: OfferPriority;
}

export interface FormState
  extends FormGroupState<CreatePackageOfferFormModel> {}

export const initialPackageOfferFormState: FormState = createFormGroupState<CreatePackageOfferFormModel>(
  'packageOffer',
  {
    title: null,
    mroUuid: null,
    refNumber: null,
    useCustomReference: false,
    locationId: null,
    specification: null,
    preferredEndDate: null,
    preferredStartDate: null,
    priority: OfferPriority.MEDIUM
  }
);
