import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TabNavigationComponent),
      multi: true
    }
  ]
})
export class TabNavigationComponent implements ControlValueAccessor {
  @Input() public menuItems: Array<any> = [];

  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  public val = ''; // this is the updated value that the class accesses

  constructor() {}

  public onChange: any = () => {};
  public onTouch: any = () => {};

  // this method sets the value programmatically
  public writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // upon touching the element, this method gets triggered
  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
