import { ActionReducer, createReducer, on } from '@ngrx/store';
import { InjectionToken } from '@angular/core';

import { WebSocketStateModel, initialState } from './websocket-state.model';
import {
  OpenSockQueueSucceeded,
  SockQueueClosed,
  OpenSockQueue,
  OpenSockQueueFailed
} from './websocket.actions';

export function getReducers(): ActionReducer<WebSocketStateModel> {
  return webSocketReducer;
}

const webSocketReducer = createReducer<WebSocketStateModel>(
  initialState,
  on(OpenSockQueue, OpenSockQueueFailed, (state, action) => state),
  on(OpenSockQueueSucceeded, (state, action) => {
    return {
      ...state,
      queueList: state.queueList.concat({
        uri: action.queue,
        onSockClose: action.onSockClose
      })
    };
  }),
  on(SockQueueClosed, (state, action) => {
    const newQueueList = state.queueList.filter(it => it.uri !== action.queue);
    return { ...state, queueList: newQueueList };
  })
);

export const reducersInjectionToken = new InjectionToken<
  ActionReducer<WebSocketStateModel>
>('[WebSocket] Registered Reducers');
