<input
    class="form-control"
    type="number"
    [placeholder]="filterConfig.placeholder || '' | translate"
    [(ngModel)]="value"
    (ngModelChange)="handleChange($event)"
    [min]="filterConfig.min"
    [max]="filterConfig.max"
    [disabled]="filterConfig.disabled"
/>
