import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'staffnow-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent {
  @Input() public message: string = null;
  @Input() public additionalMessage: string = null;
  @Input()
  @HostBinding('class.hide-on-small-screens')
  public hideOnSmallScreens: boolean = true;
}
