import { Component, Input } from '@angular/core';
import { StaffnowDocument } from '@libs/user-profile/model/documents.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-file-list-dialog',
  templateUrl: './file-list-dialog.component.html',
  styleUrls: ['./file-list-dialog.component.scss']
})
export class FileListDialogComponent {
  @Input() public onDownloadCallback: Function;
  @Input() public documents: StaffnowDocument[] = [];
  @Input() public showLabel: boolean = false;
  @Input() public title: string = null;

  constructor(public bsModalRef: BsModalRef) {}

  public close() {
    this.bsModalRef.hide();
  }

  public downloadDocument($event) {
    this.onDownloadCallback($event);
  }

  get documentsIsEmpty(): boolean {
    return this.documents.length === 0;
  }
}
