import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { AgencyModalEffects } from './agency-modal.effects';
import { AgenciesListModalComponent } from './agencies-list-modal.component';
import { TranslationModule } from '@libs/translation/translation.module';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { SharedModule } from '@libs/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslationModule,
    CommonUiModule,
    EffectsModule.forFeature([AgencyModalEffects])
  ],
  declarations: [AgenciesListModalComponent],
  exports: [AgenciesListModalComponent]
})
export class AgencyModalModule {}
