import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'staffnow-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent {
  @Input() public content: any;
  @Input() public smallModal?: boolean;
  @Input() public largeModal?: boolean;
  @Input() public autoSize?: boolean;
  @Input() public isOffer?: boolean;
  @Input() public disableBackdrop?: boolean;

  @Output() public whenHidden: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalContainer') public modalContainer: any;

  constructor() {}

  public open() {
    this.modalContainer.show();
  }

  public close() {
    this.modalContainer.hide();
  }

  public handleHidden() {
    this.whenHidden.emit();
  }
}
