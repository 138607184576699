import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RequestOverviewState} from '../../../state/request-overview-state.model';
import {AgencyContractDto} from '@libs/shared/models/contract.model';
import {AbstractOfferDetailsComponent} from '../abstract-offer-details.component';
import {LoadOfferWithContracts} from '@libs/request-overview-common/state/requests-overview.actions';

@Component({
  selector: 'staffnow-agency-job-offer-details',
  templateUrl: './agency-job-offer-details.component.html',
  styleUrls: ['../facility-job-offer-details.component.scss']
})
export class AgencyJobOfferDetailsComponent extends AbstractOfferDetailsComponent implements OnInit {
  public contracts: Array<AgencyContractDto> = [];

  constructor(private activatedRoute: ActivatedRoute) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params =>
        this.store.dispatch(
          LoadOfferWithContracts({refNumber: params['offerRefNumber']})
        )
      )
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contracts = state.contracts.map(contract => this.adjustEndDate(contract));
  }

  private adjustEndDate(contract): AgencyContractDto {
    return {
      ...contract,
      endDate: contract.endDate ? contract.endDate : contract.provisionalEndDate
    };
  }
}
