import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'staffnow-contract-info',
  templateUrl: './contract-info.component.html',
  styleUrls: ['./contract-info.component.scss']
})
export class ContractInfoComponent {
  @Input() public icon: string = null;
  @Input() public iconTooltip: string = null;
  @Input() public label: string = null;
  @Input() public overflowElipsis: boolean = false;
  @Input()
  @HostBinding('class.displayBlock')
  public displayBlock: boolean = false;
  @Input()
  @HostBinding('class.danger')
  public danger: boolean = false;
}
