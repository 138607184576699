import { NgModule } from '@angular/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { SharedModule } from '@libs/shared/shared.module';
import { CreatePackageOfferComponent } from './components/create-package-offer/create-package-offer.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PackageOfferEffects } from './state/package-offer.effects';
import {
  CREATE_EDIT_PACKAGE_OFFER_FEATURE_KEY,
  createPackageOfferFormReducer
} from './state/create-package-offer.reducer';
import { initialPackageOfferFormState } from './state/create-package-offer.model';
import { TranslationModule } from '@libs/translation/translation.module';
import { ManagePackageOfferFilesComponent } from './components/manage-package-offer-files/manage-package-offer-files.component';
import { PackageOfferFileService } from './components/manage-package-offer-files/package-offer-file.service';
import {OfferManagementSharedModule} from "../../../shared/offer-management-shared.module";

@NgModule({
  imports: [
    SharedModule,
    CommonUiModule,
    NgrxFormsModule,
    StoreModule.forFeature(
      CREATE_EDIT_PACKAGE_OFFER_FEATURE_KEY,
      createPackageOfferFormReducer,
      {
        initialState: initialPackageOfferFormState
      }
    ),
    EffectsModule.forFeature([PackageOfferEffects]),
    TranslationModule,
    OfferManagementSharedModule
  ],
  declarations: [
    CreatePackageOfferComponent,
    ManagePackageOfferFilesComponent
  ],
  exports: [
    CreatePackageOfferComponent,
    ManagePackageOfferFilesComponent
  ],
  providers: [PackageOfferFileService]
})
export class PackageOfferModule {}
