import { Component, Input } from '@angular/core';
import {
  PackageContract,
  PackageContractStatusUtils
} from '@libs/shared/models/package-contract.model';

@Component({
  selector: 'staffnow-collapsible-package-contracts-container',
  templateUrl: './collapsible-package-contracts-container.component.html',
  styleUrls: ['./collapsible-package-contracts-container.component.scss']
})
export class CollapsiblePackageContractsContainerComponent {
  @Input() public contracts: PackageContract[] = [];

  get pendingContracts(): PackageContract[] {
    return this.contracts?.filter(contract =>
      PackageContractStatusUtils.isPending(contract)
    );
  }

  get rejectedContracts(): PackageContract[] {
    return this.contracts?.filter(contract =>
      PackageContractStatusUtils.isRejected(contract)
    );
  }

  get fullyAcceptedContracts(): PackageContract[] {
    return this.contracts?.filter(contract =>
      PackageContractStatusUtils.isAccepted(contract)
    );
  }
}
