import { createFormStateReducerWithUpdate, FormGroupState, setErrors, updateGroup, validate } from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, maxLength, minLength, required } from 'ngrx-forms/validation';
import { CreateAgencyOfferFormModel, createAgencyOfferInitialFormState, FormState } from './create-agency-offer.model';

import { ResetAgencyOfferForm } from './create-agency-offer.actions';
import { getCustomReferenceValidator } from '../../../shared/validators/custom-reference.validator';
import { Action } from '@ngrx/store';

const maxYearsOfExperience: number = 60;
export const AGO_PRESENTATION_MAX_LENGTH: number = 20000;
export const otherAirplanesMaxLength: number = 120;
export const workDaysMaxValue: number = 99;
export const payRateMaxValue: number = 999;

export const CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY = 'createEditAgencyOffer';

const validateAircraft = (
  state: FormState = createAgencyOfferInitialFormState
): FormGroupState<CreateAgencyOfferFormModel> => {
  if (state.controls.airplanes.value.length !== 0) {
    return updateGroup<CreateAgencyOfferFormModel>({
      otherAirplanes: setErrors(null)
    })(state);
  } else if (state.controls.otherAirplanes.value) {
    return updateGroup<CreateAgencyOfferFormModel>({
      airplanes: setErrors(null)
    })(state);
  }

  return state;
};

const validateMaxPayRate = (
  state: FormState = createAgencyOfferInitialFormState
): FormGroupState<CreateAgencyOfferFormModel> => {
  if (state.controls.hasIndicativeRate.value) {
    return updateGroup<CreateAgencyOfferFormModel>({
      maxPayRate: validate(
        required,
        greaterThan(-1),
        lessThanOrEqualTo(payRateMaxValue),
        greaterThan(state.controls.payRate.value ?? 0)
      )
    })(state);
  } else {
    return updateGroup<CreateAgencyOfferFormModel>({
      maxPayRate: setErrors(null)
    })(state);
  }
};

const updateMyFormGroup = updateGroup<CreateAgencyOfferFormModel>({
  refNumber: getCustomReferenceValidator(),
  shortPresentation: validate<string>(required, minLength(2), maxLength(AGO_PRESENTATION_MAX_LENGTH)),
  vacancies: validate(required, greaterThan(0), lessThanOrEqualTo(1000)),
  ameType: validate(required),
  ameTitleId: validate(required),
  airplanes: validate(required),
  minExperience: validate(required, greaterThan(-1), lessThanOrEqualTo(maxYearsOfExperience)),
  payRate: validate(required, greaterThan(-1), lessThanOrEqualTo(payRateMaxValue)),
  workDaysOn: validate(required, greaterThan(0), lessThanOrEqualTo(workDaysMaxValue)),
  workDaysOff: validate(required, greaterThan(-1), lessThanOrEqualTo(workDaysMaxValue)),
  otherAirplanes: validate(required),
  priority: validate(required)
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function createAgencyOfferFormReducer(
  state: FormState = createAgencyOfferInitialFormState,
  action: Action
): FormGroupState<CreateAgencyOfferFormModel> {
  let form = formReducer(state, action);
  form = validateAircraft(form);
  form = validateMaxPayRate(form);

  if (action.type === ResetAgencyOfferForm.type) {
    return {
      ...createAgencyOfferInitialFormState
    };
  }
  return {
    ...form
  };
}
