import { Injectable } from '@angular/core';
import { environment } from '../../../../../../apps/staffnow-platform/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private readonly scriptElementId: string = 'google-analytics-script';
  private isAlreadyInitialized: boolean = false;
  private googleTrackingId: string;

  constructor() {
    this.googleTrackingId = environment.googleTrackingId;
    this.enableCookie();
  }

  enableCookie(): void {
    if (!this.isAlreadyInitialized && this.googleTrackingId) {
      this.loadGtagScript();
      gtag('js', new Date());
      gtag('config', this.googleTrackingId);
      this.isAlreadyInitialized = true;
    }
  }

  trackEvent(eventName: string, params: { [key: string]: string }) {
    gtag('event', eventName, {
      ...params
    });
  }

  private loadGtagScript(): void {
    const gtagScript = document.createElement('script');
    gtagScript.setAttribute('async', '');
    gtagScript.setAttribute('id', this.scriptElementId);
    gtagScript.setAttribute(
      'src',
      'https://www.googletagmanager.com/gtag/js?id=' + this.googleTrackingId
    );
    document
      .getElementById('gtag-function')
      .insertAdjacentElement('beforebegin', gtagScript);
  }
}
