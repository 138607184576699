import { Resource } from '@libs/shared/bms-common/rest/resource.model';

export const FEATURE_STATE_KEY = 'mailbox';

export interface MailboxStateModel {
  currentPage: number;
  conversationList: Array<Conversation>;
  messageList: {
    postConversationMessageTemporaryFileUrl: string;
    messages: Array<Message>;
  };
  messageListLoading: boolean;
  selectedConversation: Conversation;
  sendingMessageInProgress: boolean;
}

export interface Conversation extends Resource {
  conversationId: number;
  partnerUserUuid: string;
  partnerOutOfOffice: boolean;
  firstName: string;
  lastName: string;
  title: string;
  hasUnseenMessages: boolean;
  amountOfUnseenMessages: number;
  lastMessageReceivedOn: number;
  createdOn: number;
}

export interface Message extends Resource {
  conversationId: number;
  createdOn: number;
  lastEditedOn: number;
  deletedOn: number;
  id: number;
  isMine: boolean;
  text: string;
  filename: string;
  fileUuid: string;
  getConversationMessageTemporaryFileUrl: string;
  deleteConversationMessageUrl: string;
  type: MessageType;
  automatic: boolean;
}

export enum MessageType {
  REGULAR = 'REGULAR',
  AUTOMATIC_OUT_OF_OFFICE_V1 = 'AUTOMATIC_OUT_OF_OFFICE_V1',
  AUTOMATIC_DOCUMENTS_NEEDED_V1 = 'AUTOMATIC_DOCUMENTS_NEEDED_V1'
}

export const initialState: MailboxStateModel = {
  currentPage: null,
  conversationList: [],
  messageList: {
    postConversationMessageTemporaryFileUrl: null,
    messages: []
  },
  selectedConversation: {
    conversationId: null,
    partnerUserUuid: '',
    firstName: 'Unnamed',
    lastName: 'User',
    title: '',
    hasUnseenMessages: null,
    amountOfUnseenMessages: null,
    lastMessageReceivedOn: null,
    createdOn: null,
    partnerOutOfOffice: false,
    _links: null
  },
  messageListLoading: false,
  sendingMessageInProgress: false
};
