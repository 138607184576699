<div>
    <label for="promotional-pictures" class="h1">
        {{ 'Promotional material ' | translate }}
    </label>
    <span class="text-muted">{{
        ' (Visible to Aviation Companies only)' | translate
    }}</span>
    <br />
    <div class="mb-2">
        <input
            ng2FileSelect
            #fileInput
            hidden
            type="file"
            id="promotional-pictures"
            name="promotional-pictures"
            class="form-control-file"
            [multiple]="false"
            [uploader]="uploader"
        />
        <general-button
            class="upload-btn"
            text="Upload"
            [isLoading]="isLoading"
            [isDisabled]="isLoading"
            [customClass]="'btn-loader'"
            (onClick)="fileInput.click()"
        ></general-button>
    </div>
    <div class="mb-2">
        <carousel [interval]="0">
            <slide
                *ngFor="
                    let carouselPicture of carouselPictures;
                    let carouselPictureIndex = index
                "
            >
                <div style="height: 350px; display: flex; align-items: center">
                    <div
                        class="overlay"
                        (click)="
                            handlePictureDelete(
                                carouselPicture.deleteUrl,
                                carouselPictureIndex
                            )
                        "
                    >
                        <i class="material-icons delete-icon">delete</i>
                    </div>
                    <img
                        class="carousel-image"
                        [src]="carouselPicture.url"
                        alt="Promotional material"
                    />
                </div>
            </slide>
        </carousel>
    </div>
</div>
