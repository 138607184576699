<div class="modal-wrapper">
    <i *ngIf="canCancel" class="material-icons close" (click)="cancelHandler()">close</i>
    <div class="content-wrapper">
        <div class="icon" *ngIf="icon">
            <span class="material-icons-outlined">
                {{ icon }}
            </span>
        </div>
        <h1 *ngIf="title" class="heading">{{ title | translate }}</h1>
        <div class="text-wrapper">
            <ng-container *ngFor="let message of messages">
                <div class="text">{{ message | translate }}</div>
            </ng-container>
        </div>
        <div class="text-wrapper" *ngIf="requiredUserConfirmation">
            <input
                id="deleteConfirmationInput"
                class="form-control"
                name="deleteConfirmationInput"
                type="text"
                placeholder="Delete"
                [(ngModel)]="deleteConfirmationInput"
            />
        </div>
    </div>
    <div class="controls-container">
        <general-button
            (onClick)="cancelHandler()"
            *ngIf="canCancel"
            borderStraight="true"
            text="{{ cancelText }}"
            color="secondary-low"
        ></general-button>
        <general-button
            (onClick)="agreedHandler()"
            borderStraight="true"
            text="{{ acceptText }}"
            color="primary"
            [isDisabled]="isAgreedHandlerDisabled()"
        ></general-button>
    </div>
</div>
