import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OperatorFunction } from 'rxjs';
import { cloneDeep } from 'lodash-es';

import { Conversation, FEATURE_STATE_KEY } from './state/mailbox-state.model';
import {
  ConversationLoaded,
  LoadConversation,
  LoadConversations,
  LoadNextPage,
  ResetMailboxState,
  ResetMessagesList,
  ResetSelectedConversation,
  UpdateConversations
} from './state/mailbox.actions';
import { MailboxViews } from './mailbox-views.enum';
import { WebSocketService } from '@libs/websocket/websocket.service';

@UntilDestroy()
@Component({
  selector: 'mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.scss']
})
export class MailboxComponent implements OnInit, OnDestroy {
  public conversationList: Array<Conversation> = [];
  public MAILBOX_VIEWS = MailboxViews;
  public isAdmin: boolean = false;
  public activeView: MailboxViews = MailboxViews.Conversations;
  public selectedConversationId: number = null;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private webSocketService: WebSocketService
  ) {
    if (this.activatedRoute && this.activatedRoute.snapshot.data) {
      this.isAdmin = this.activatedRoute.snapshot.data.isAdmin;
    }
    this.store.dispatch(LoadConversations());
    this.setupStoreSubscriptions();
  }

  private setupStoreSubscriptions(): void {
    this.storeSubscribe(
      select(state => state[FEATURE_STATE_KEY].conversationList),
      conversationList => {
        const newConversationList = cloneDeep(conversationList);
        if (this.shouldConversationsListenerBeReset(newConversationList)) {
          this.stopListeningToConversations();
          this.conversationList = cloneDeep(conversationList);
          this.listenToConversations();
        } else {
          this.conversationList = cloneDeep(conversationList);
        }
      }
    );
    this.storeSubscribe(
      select(state => state[FEATURE_STATE_KEY].selectedConversation),
      selectedConversation => {
        if (
          this.selectedConversationId !== selectedConversation.conversationId
        ) {
          this.selectedConversationId = selectedConversation.conversationId;
        }
      }
    );
  }

  private shouldConversationsListenerBeReset(
    newConversationList: Conversation[]
  ) {
    return (
      newConversationList
        .map(it => it.conversationId)
        .sort((n1, n2) => n1 - n2)
        .map(it => it.toString())
        .join() !==
      this.conversationList
        .map(it => it.conversationId)
        .sort((n1, n2) => n1 - n2)
        .map(it => it.toString())
        .join()
    );
  }

  private listenToConversations() {
    this.conversationList.forEach(it => {
      const onMessageReceived = (messagePayload: any) => {
        switch (messagePayload.body) {
          case 'NEW_MESSAGE_ADDED_TO_THE_CONVERSATION':
            if (this.conversationList.length > 0) {
              this.updateConversationsList();
            }
            break;
        }
      };
      this.webSocketService.startListeningToWebSocketQueue(
        `/user/conversation${it.conversationId}/queue/messages`,
        onMessageReceived
      );
    });
  }

  private stopListeningToConversations() {
    this.conversationList.forEach(it => {
      this.webSocketService.stopListeningToWebSocketQueue(
        `/user/conversation${it.conversationId}/queue/messages`
      );
    });
  }

  private updateConversationsList() {
    const lastUpdatedSince = this.conversationList[0].lastMessageReceivedOn;
    this.store.dispatch(UpdateConversations({ lastUpdatedSince }));
  }

  private storeSubscribe<T, S>(
    pipedSelector: OperatorFunction<T, S>,
    subscribeFn: (a: S) => void
  ) {
    this.store.pipe(pipedSelector, untilDestroyed(this)).subscribe(subscribeFn);
  }

  ngOnInit(): void {
    // route params or selected conversation in store
    if (this.activatedRoute.snapshot.params['id']) {
      this.store.dispatch(
        LoadConversation({
          conversationId: this.activatedRoute.snapshot.params['id']
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetMailboxState());
    this.stopListeningToConversations();
  }

  public loadNextPage() {
    const lastUpdatedSince = this.conversationList[0].lastMessageReceivedOn;
    this.store.dispatch(new LoadNextPage(lastUpdatedSince));
  }

  public loadConversation(conversation: Conversation): void {
    this.store.dispatch(ConversationLoaded({ conversation }));
    if (this.selectedConversationId !== conversation.conversationId) {
      this.store.dispatch(ResetMessagesList());
    }
    this.goToMessages();
  }

  public goToMessages() {
    this.activeView = this.MAILBOX_VIEWS.Messages;
  }

  public goToConversations() {
    this.activeView = this.MAILBOX_VIEWS.Conversations;
    this.store.dispatch(ResetSelectedConversation());
    this.store.dispatch(ResetMessagesList());
  }
}
