import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdditionalBenefitFormUtils } from '../additional-benefits-form.model';
import {
  AbstractValueAccessor,
  MakeProvider
} from '@libs/shared/helpers/abstract-value-accessor';
import { benefitTypeToReadable } from '@libs/shared/models/additional-benefit.model';

@Component({
  selector: 'staffnow-offer-benefit',
  templateUrl: './offer-benefit.component.html',
  styleUrls: ['./offer-benefit.component.scss'],
  providers: [MakeProvider(OfferBenefitComponent)]
})
export class OfferBenefitComponent extends AbstractValueAccessor {
  @Input() currencySymbol: string = null;
  @Input() isValid: boolean;
  @Input() isDisabled: boolean = false;
  @Output() changer: EventEmitter<any> = new EventEmitter();

  get benefitLabel(): string {
    return benefitTypeToReadable(this._value?.type);
  }

  get requiresAmount(): boolean {
    return AdditionalBenefitFormUtils.requiresAmount(this._value?.type);
  }

  public emitChangeInBenefits() {
    this.changer.emit(true);
  }
}
