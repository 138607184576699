import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'apps/staffnow-platform/src/app/state/app-state.model';
import { LoadFeedbackList, RejectFeedback } from '../../state/feedback.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { selectFeedbackList } from '../../state/feedback.selectors';
import { TranslateService } from '@ngx-translate/core';

export interface FeedbackResource extends Resource {
  id: number;
  refNumber: string;
  mroName: string;
}

@Component({
  selector: 'staffnow-feedback-alert',
  templateUrl: './feedback-alert.component.html',
  styleUrls: ['./feedback-alert.component.scss']
})
export class FeedbackAlertComponent implements OnInit {
  public currentFeedback: FeedbackResource = null;
  public feedbackAlertMessage = '';
  private bsModalRef: BsModalRef;

  constructor(
    private confirmationService: ModalService,
    private modalService: BsModalService,
    private store: Store<AppState>,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      if (hasLink(apiRoot, ApiRootLinkRel.NotGivenFeedback)) {
        this.store.dispatch(new LoadFeedbackList(getUrl(apiRoot, ApiRootLinkRel.NotGivenFeedback)));
        this.setCurrentFeedback();
      }
    });
  }

  setCurrentFeedback(): void {
    this.store.select(selectFeedbackList).subscribe(feedbackList => {
      this.currentFeedback = feedbackList[0];
      if (this.currentFeedback !== undefined) {
        this.feedbackAlertMessage = 'FEEDBACK.ALERT_MESSAGE';
      }
    });
  }

  openFeedbackForm() {
    const initialState = {
      ignoreBackdropClick: true,
      currentFeedback: this.currentFeedback
    };
    this.bsModalRef = this.modalService.show(FeedbackFormComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = this.translateService.instant('GENERAL.CLOSE');
  }

  cancelMroFeedback() {
    this.confirmationService.openConfirmModal('SYSTEM.INFO.CANCEL_MRO_FEEDBACK', () => {
      if (hasLink(this.currentFeedback, 'rejectFeedback')) {
        this.store.dispatch(new RejectFeedback(getUrl(this.currentFeedback, 'rejectFeedback')));
      }
    });
  }
}
