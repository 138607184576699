<nav class="profile-nav">
    <ul>
        @for (item of menuItems; track item) {
        <li
            [ngClass]="{ active: activeItem === item }"
            (click)="selectNewItem(item)"
            (keydown.enter)="selectNewItem(item)"
        >
            @if(isMissingData?.length) { @for (data of isMissingData; track data.tabName) { @if (data.isMissing &&
            data.tabName == item) { @if(isPlatformWeb) {
            <span
                class="error-icon custom-tooltip"
                tooltip="{{ 'USER_PROFILE.TABS.TOOLTIPS' | translate }}"
                containerClass="customClass"
            >
                error
            </span>
            } @else {
            <span
                class="error-icon custom-tooltip col-xs-6 col-6"
                tooltip="{{ 'USER_PROFILE.TABS.TOOLTIPS' | translate }}"
                containerClass="customClass"
                triggers="click"
                placement="bottom"
            >
                error
            </span>
            } } } }
            {{ item }}
        </li>
        }
    </ul>
</nav>
