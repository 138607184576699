<div class="mro-registration-form">
    <form>
        <h1>
            {{
                'REGISTRATION.STEPS.DISPLAY_OPTIONS.ALLOW' | translate
            }}
        </h1>
        <div class="d-flex flex-column align-items-center section">
            <div>
                <div class="custom-control custom-radio staffnow-checkbox">
                    <input
                        class="custom-control-input mr-1"
                        id="social-yes"
                        type="radio"
                        name="social"
                        [checked]="
                            formState.allowToAdvertiseOpenPositions === true
                        "
                        (click)="toggleAllowAdvertisement(true)"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="social-yes"
                    >
                        {{
                            'REGISTRATION.STEPS.DISPLAY_OPTIONS.ALLOW.YES'
                                | translate
                        }}
                    </label>
                </div>
                <div class="custom-control custom-radio staffnow-checkbox">
                    <input
                        class="custom-control-input mr-1"
                        id="social-no"
                        type="radio"
                        name="social"
                        [checked]="
                            formState.allowToAdvertiseOpenPositions === false
                        "
                        (click)="toggleAllowAdvertisement(false)"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="social-no"
                    >
                        {{ 'REGISTRATION.STEPS.DISPLAY_OPTIONS.ALLOW.NO' | translate }}
                    </label>
                </div>
            </div>
        </div>
        <h1>
            {{
                'REGISTRATION.STEPS.DISPLAY_OPTIONS.ALLOW.COMPANY_LOGO' | translate: {brandName: (brandName$ | async | uppercase)}
            }}
        </h1>
        <div class="d-flex company-logo company-logo-info section">
            <div class="flex-grow-1 image-wrapper">
                <div class="image-display">
                    <div
                        class="empty-logo-wrapper"
                        (click)="open()"
                        *ngIf="!imgURL"
                    >
                        <div class="flex-grow-1">
                            <span
                                class="material-icons-outlined logo-placeholder"
                                *ngIf="!imgURL"
                                >image</span
                            >
                        </div>
                        <div class="flex-grow-1 fake-button-wrapper">
                            <span class="fake-button">{{'REGISTRATION.STEPS.DISPLAY_OPTIONS.ADD.COMPANY_LOGO' | translate}}</span>
                        </div>
                    </div>
                    <input
                        type="file"
                        style="display: none"
                        #file
                        (change)="onPictureChange(file.files)"
                    />
                    <div *ngIf="imgURL" class="logo-wrapper">
                        <img
                            [src]="imgURL"
                            class="logo"
                            (click)="open()"
                            alt="Company Logo"
                        />
                    </div>
                </div>
            </div>
            <div class="flex-grow-1 p-3">
                <div class="custom-control custom-checkbox staffnow-checkbox">
                    <input
                        class="custom-control-input"
                        id="staffnow"
                        type="checkbox"
                        [checked]="formState.allowToDisplayCompanyLogo"
                        (click)="this.toggleCompanyLogo()"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="staffnow"
                        [innerHTML]="'REGISTRATION.STEPS.DISPLAY_OPTIONS.ALLOW.COMPANY_LOGO.WARNING' | translate: {brandName: brandName$ | async}"
                    >
                    </label>
                </div>
                <div class="custom-control custom-checkbox staffnow-checkbox">
                    <input
                        class="custom-control-input"
                        id="promotion"
                        type="checkbox"
                        [checked]="formState.allowToDisplayPromotionalMaterial"
                        (click)="toggleCompanyPromoMaterials()"
                    />
                    <label
                        class="custom-control-label font-weight-bold"
                        for="promotion"
                    >
                        {{
                            'REGISTRATION.STEPS.DISPLAY_OPTIONS.PROMOTIONAL_MATERIALS'
                                | translate
                        }}
                    </label>
                </div>
            </div>
        </div>
        <div class="terms-acceptance">
            <hr />
            <div class="custom-control custom-checkbox staffnow-checkbox">
                <input
                    class="custom-control-input"
                    id="terms"
                    type="checkbox"
                    [checked]="formState.termsAgreement"
                    (click)="toggleTermsAndConditions()"
                />
                <label
                    class="custom-control-label font-weight-bold"
                    for="terms"
                    [innerHTML]="'REGISTRATION.STEPS.DISPLAY_OPTIONS.T&C'
                    | translate: {brandName: (brandName$ | async), privacyPolicyLink: privacyPolicyLink, termsAndConditionsLink: termsAndConditionsLink}"
                >
                </label>
            </div>
        </div>
    </form>
    <div class="mro-registration-actions mt-3">
        <general-button
            (onClick)="reset()"
            [isDisabled]="isLoading"
            backgroundStyle="outline"
            [text]="'GENERAL.CANCEL' | translate"
        ></general-button>
        <general-button
            (onClick)="previous()"
            [isDisabled]="isLoading"
            [text]="'GENERAL.PREVIOUS' | translate"
            icon="arrow_back"
        ></general-button>
        <general-button
            (onClick)="complete()"
            [isDisabled]="!isFormValid() || isLoading"
            [isLoading]="isLoading"
            [text]="'REGISTRATION.SUBMIT.BUTTON' | translate"
            icon="arrow_forward"
            iconPosition="right"
        ></general-button>
    </div>
</div>
