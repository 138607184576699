import { Component, Input, Output, EventEmitter } from '@angular/core';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';

interface TabData {
  tabName: string;
  isMissing: boolean;
}

@Component({
  selector: 'staffnow-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss']
})
export class ProfileNavComponent {
  @Input() menuItems: Array<string>;
  @Input() activeItem: string = 'Account';
  @Input() isMissingData: Array<TabData> = [];

  @Output() selectedItem = new EventEmitter();

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  selectNewItem(item: string): void {
    this.selectedItem.emit(item);
  }
}
