import { Action } from '@ngrx/store';
import { Notification } from '../notification.model';

export enum NotificationsActions {
  LoadBadges = '[Notifications] Load badges',
  BadgesLoaded = '[Notifications] Badges loaded',
  FailedToLoadBadges = '[Notifications] Failed to laod badges',
  UpdateNotificationsCount = '[Notifications] Update notifications count',
  NewMessageNotification = '[Notifications] New message received',
  LoadNotificationList = '[Notifications] Load notification list',
  NotificationListLoaded = '[Notifications] Notification list loaded',
  FailedToLoadNotifications = '[Notifications] Notification list failed to load',
  ResetNotificationList = '[Notifications] Reset notification list',
  NewCommonNotification = '[Notifications] New common notification'
}

export class LoadBadges implements Action {
  public readonly type = NotificationsActions.LoadBadges;
}

export class ResetNotificationList implements Action {
  public readonly type = NotificationsActions.ResetNotificationList;
}

export class BadgesLoaded implements Action {
  public readonly type = NotificationsActions.BadgesLoaded;
  public readonly badgesList: Array<any> = [];

  constructor(badgesList: Array<any>) {
    this.badgesList = badgesList;
  }
}

export class NewCommonNotification implements Action {
  public readonly type = NotificationsActions.NewCommonNotification;
  public readonly data: any = {};

  constructor(data: any) {
    this.data = data;
  }
}

export class FailedToLoadNotifications implements Action {
  public readonly type = NotificationsActions.FailedToLoadNotifications;
}

export class NotificationListLoaded implements Action {
  public readonly type = NotificationsActions.NotificationListLoaded;
  public readonly notificationList: Array<Notification> = [];
  public readonly isLastPage: boolean = false;
  public readonly page: number = 0;
  public readonly pageSize: number = 0;

  constructor(response: any) {
    this.notificationList = response._embedded.notifications.map(
      notification => ({
        ...notification,
        payload: JSON.parse(notification.payload)
      })
    );
    this.isLastPage =
      response._embedded.notifications.length < response.pageSize;
    this.page = response.page;
    this.pageSize = response.pageSize;
  }
}

export class LoadNotificationList implements Action {
  public readonly type = NotificationsActions.LoadNotificationList;
  public readonly page: number = 0;

  constructor(page: number = 0) {
    this.page = page;
  }
}

export class FailedToLoadBadges implements Action {
  public readonly type = NotificationsActions.FailedToLoadBadges;
}

export class UpdateNotificationsCount implements Action {
  public readonly type = NotificationsActions.UpdateNotificationsCount;
  public readonly notificationsTarget: string = null;
  public readonly count: number = null;

  constructor(notificationsTarget: string, count?: number) {
    this.notificationsTarget = notificationsTarget;
    this.count = count;
  }
}

export class NewMessageNotification implements Action {
  public readonly type = NotificationsActions.NewMessageNotification;
  public readonly data: any = {};

  constructor(data: any) {
    this.data = data;
  }
}

export type NotificationAction =
  | LoadBadges
  | BadgesLoaded
  | FailedToLoadBadges
  | ResetNotificationList
  | NewCommonNotification
  | FailedToLoadNotifications
  | NotificationListLoaded
  | LoadNotificationList
  | UpdateNotificationsCount
  | NewMessageNotification;
