import { OfferType } from '@libs/shared/models/offer.model';

export enum AgencyRequestViews {
  AviationCompaniesJobOffers = 'aviation-companies-job-offers',
  MyAgencyPackageOffers = 'my-agency-package-offers',
  OwnJobOffers = 'own-job-offers',
  OwnTechnicians = 'own-technicians'
}

export const agencyRequestOverviewTabs = {
  aviationCompaniesJob: {
    label: 'AGENCY.REQUEST.OVERVIEW.TABS.AVIATION_COMPANIES_JOB.LABEL',
    view: AgencyRequestViews.AviationCompaniesJobOffers,
    offerType: OfferType.TEMPORARY
  },
  myAgencyPackage: {
    label: 'AGENCY.REQUEST.OVERVIEW.TABS.MY_AGENCY_PACKAGE.LABEL',
    view: AgencyRequestViews.MyAgencyPackageOffers,
    offerType: OfferType.FIXED_PRICE
  },
  ownJob: {
    label: 'AGENCY.REQUEST.OVERVIEW.TABS.OWN_JOB.LABEL',
    view: AgencyRequestViews.OwnJobOffers,
    offerType: OfferType.AGENCY
  },
  ownTechnicians: {
    label: 'AGENCY.REQUEST.OVERVIEW.TABS.OWN_TECHNICIANS',
    view: AgencyRequestViews.OwnTechnicians
  }
};
