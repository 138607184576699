import { Component, Input } from '@angular/core';
import { PackageOfferAgency } from '@libs/shared/models/facility.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-package-agency-selection',
  templateUrl: './package-agency-selection.component.html',
  styleUrls: ['./package-agency-selection.component.scss']
})
export class PackageAgencySelectionComponent {
  @Input() public agencyList: PackageOfferAgency[] = [];
  @Input() public onConfirmCallback: Function = null;
  private selectedEntities: PackageOfferAgency[] = [];

  constructor(public bsModalRef: BsModalRef) {}

  public close(): void {
    this.bsModalRef.hide();
  }

  public onAdd() {
    this.onConfirmCallback(this.selectedEntities);
    this.close();
  }

  public isFormValid() {
    return this.selectedEntities.length > 0;
  }

  public handleAgencyClick(agency: PackageOfferAgency): void {
    if (this.agencyIsAdded(agency)) {
      this.selectedEntities = this.selectedEntities.filter(
        a => a.uuid !== agency.uuid
      );
    } else {
      this.selectedEntities.push(agency);
    }
  }

  private agencyIsAdded(agency: PackageOfferAgency) {
    return this.selectedEntities.some(a => a.uuid === agency.uuid);
  }
}
