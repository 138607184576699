import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { LibStateModel } from '../state/lib-state.model';
import { DeleteToast, NewToast } from '../state/lib.actions';
import { ToastTypes } from './toast-types.enum';

@Injectable()
export class ToastMessageService {
  constructor(private store: Store<LibStateModel>) {}

  public success(text: string, dismissTimeout?: number | null): void {
    if (text) {
      this.store.dispatch(
        new NewToast(text, ToastTypes.Success, dismissTimeout)
      );
    }
  }

  public info(text: string, dismissTimeout?: number | null): void {
    if (text) {
      this.store.dispatch(new NewToast(text, ToastTypes.Info, dismissTimeout));
    }
  }

  public fail(text: string, dismissTimeout?: number | null): void {
    if (text) {
      this.store.dispatch(this.failNoDispatch(text, dismissTimeout));
    }
  }

  public successNoDispatch(text: string, dismissTimeout?: number | null) {
    return new NewToast(text, ToastTypes.Success, dismissTimeout);
  }

  private failNoDispatch(text: string, dismissTimeout?: number | null) {
    return new NewToast(text, ToastTypes.Fail, dismissTimeout);
  }

  public deleteToast(messageId: string): void {
    if (messageId) {
      this.store.dispatch(new DeleteToast(messageId));
    }
  }
}
