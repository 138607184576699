import { Component, Input } from '@angular/core';
import {
  OfferOutDto,
  SharedOfferOutDto
} from '@libs/shared/models/offer.model';
import { SelectedAircraftPipe } from '@libs/shared/pipes/selected-aircrafts.pipe';

@Component({
  selector: 'staffnow-offer-aircrafts-field',
  templateUrl: './offer-aircraft-field.component.html'
})
export class OfferAircraftFieldComponent {
  @Input() public offer: OfferOutDto | SharedOfferOutDto = null;
  @Input() public isSharedOfferDto: boolean = false;

  constructor(private aircraftPipe: SelectedAircraftPipe) {}

  get offerAirplanes(): string {
    return this.isSharedOfferDto
      ? this.joinAirplanes()
      : this.aircraftPipe.transform(this.offer?.airplanes);
  }

  private joinAirplanes(): string {
    return this.offer?.airplanes.join(', ');
  }
}
