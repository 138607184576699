<div *ngIf="(hasIndicativeRateForOffer(jobOffer) || !offerHasPayRate) else noIndicativePayRate">
    <span>
        {{ jobOffer?.minPayRate | rate }}
        {{ ' - ' }}
        {{ jobOffer?.maxPayRate | rate }}
        {{ ' per hour' | translate }}
    </span>
</div>
<ng-template #noIndicativePayRate>
    {{ jobOffer?.payRate | rate }}
    {{ ' per hour' | translate }}
</ng-template>