import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { Contract } from '@libs/shared/models/contract.model';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { ContractStatusUtils } from '@libs/shared/models/contract-status.enum';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { CORNER_CARD_TEXT_TYPE_ENUM } from '@libs/common-ui/corner-card-text/corner-card.component';

@Component({
  selector: 'staffnow-contract-card-with-head',
  templateUrl: './contract-card-with-head.component.html',
  styleUrls: ['./contract-card-with-head.component.scss']
})
export class ContractCardWithHeadComponent {
  @Input() public contract: Contract = null;
  @Output()
  public openPublicProfile: EventEmitter<string> = new EventEmitter<string>();
  protected readonly CORNER_CARD_TEXT_TYPE_ENUM = CORNER_CARD_TEXT_TYPE_ENUM;

  @HostBinding('class.expired') get expired() {
    return this.contract?.isExpired;
  }

  constructor(private customNavigationService: CustomNavigationService) {}

  get technicianName(): string {
    const profile = (this.contract?._embedded?.profile as unknown) as {
      firstName: string;
      lastName: string;
    };
    return profile?.firstName + ' ' + profile?.lastName;
  }

  get technicianTitles(): string {
    return this.contract?.technicianTitles.join(', ');
  }

  get technicianHasProfilePicture(): boolean {
    return hasLink(this.contract, UserProfileLinkRel.ProfilePicture);
  }

  get technicianPictureUrl(): string {
    return `url('${getUrl(this.contract, UserProfileLinkRel.ProfilePicture)}')`;
  }

  get doesUserHaveAccessToTechnician(): boolean {
    return !!this.contract.doesUserHaveAccessToTechnician;
  }

  public viewProfile() {
    if (this.doesUserHaveAccessToTechnician) {
      if (ContractStatusUtils.isAcceptedByTechnician(this.contract)) {
        this.customNavigationService.openNewTab([
          'profile',
          this.contract.technicianUserUuid
        ]);
      } else {
        this.openPublicProfile.emit(EntityTypes.TECHNICIAN);
      }
    }
  }
}
