import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Contract } from '@libs/shared/models/contract.model';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import {
  AcceptStartDate,
  EndContract,
  PauseContract,
  ProposeStartDate,
  ResumeContract
} from '@libs/request-overview-common/state/requests-overview.actions';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import { StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model';
import { isNil } from 'lodash-es';
import { getCurrentUTCDateString, isFirstDateBeforeTheSecond } from '@libs/shared/helpers/date-utils';
import { BreakDatesPickerDialogComponent } from '@libs/common-ui/break-dates-picker-dialog/break-dates-picker-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-contract-date-actions',
  templateUrl: './contract-date-actions.component.html',
  styleUrls: ['./contract-date-actions.component.scss']
})
export class ContractDateActionsComponent {
  @Input() public contract: Contract = null;
  @Input() public offerStartDate: string = null;
  @Input() isPermanent: boolean = false;

  constructor(private store: Store, 
              private modalService: BsModalService,
              private translateService: TranslateService) {}

  get hasProposeStartDateLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ProposeContractStartDate);
  }

  get hasAcceptStartDateLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContractStartDate);
  }

  get proposeDateLabel(): string {
    if (isNil(this.contract.startDate)) {
      return 'GENERAL.PROPOSE';
    }
    return this.datePhaseIsAccepted ? 'GENERAL.CHANGE' : 'GENERAL.PROPOSE';
  }

  get datePhaseIsAccepted(): boolean {
    return StartDatePhaseUtils.isAccepted(this.contract.startDatePhase);
  }

  private warningMessage(): string {
    if (this.datePhaseIsAccepted) {
      return 'This contract already has a start date. If you change it, the date selection process will begin again.';
    }
    return null;
  }

  public handleStartDateProposal(): void {
    this.modalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (startDate: string) => this.proposeStartDate(startDate),
        selectedDate: this.getPreselectedDate(),
        warningMessage: this.warningMessage(),
        title: 'TASKS.VIEW.DESCRIPTION.PROPOSE_START_DATE',
        reminders: this.getReminders()
      }
    });
  }

  private getReminders() {
    const startDatePermanentMroReminder = {
      label: 'SYSTEM.INFO.START_DATE_PERMANENT_MRO_REMINDER.LABEL',
      text: 'SYSTEM.INFO.START_DATE_PERMANENT_MRO_REMINDER.TEXT'
    };
    
    const startDateAgencyReminder = {
      label: 'SYSTEM.INFO.START_DATE_AGENCY_REMINDER.LABEL',
      text: 'SYSTEM.INFO.START_DATE_AGENCY_REMINDER.TEXT'
    };

    const startDateMroReminder = {
      label: 'SYSTEM.INFO.START_DATE_MRO_REMINDER.LABEL',
      text: 'SYSTEM.INFO.START_DATE_MRO_REMINDER.TEXT'
    };
    
    return this.isPermanent ? [startDatePermanentMroReminder]
      : [startDateAgencyReminder, startDateMroReminder];
  }

  private proposeStartDate(startDate: string): void {
    this.store.dispatch(
      ProposeStartDate({
        startDate,
        contract: this.contract
      })
    );
  }

  public acceptStartDate(): void {
    this.store.dispatch(
      AcceptStartDate({
        contract: this.contract
      })
    );
  }

  public handleContractEnd(): void {
    this.modalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (endDate: string) =>
          this.store.dispatch(
            EndContract({
              contract: this.contract,
              endDate
            })
          ),
        title: 'GENERAL.END_CONTRACT',
        minDate: this.offerStartDate,
        maxDate: getCurrentUTCDateString(),
        reminders: [{ label: 'SYSTEM.INFO.END_CONTRACT_REMINDER.LABEL',
        text: 'SYSTEM.INFO.END_CONTRACT_REMINDER.TEXT'}]
      }
    });
  }

  get canEndContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.EndContract);
  }

  get canPauseContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.PauseContract);
  }

  get canResumeContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ResumeContract);
  }

  private getPreselectedDate(): string {
    const today = getCurrentUTCDateString();
    return isFirstDateBeforeTheSecond(this.offerStartDate, today) ? today : this.offerStartDate;
  }

  public handlePauseContract(): void {
    this.modalService.show(BreakDatesPickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        minimumDate: this.contract.startDate,
        onAcceptCallback: (fromDate: string, toDate: string) => {
          this.store.dispatch(PauseContract({ contract: this.contract, fromDate, toDate }));
        }
      }
    });
  }

  public handleResumeTechnician(): void {
    this.store.dispatch(ResumeContract({ contract: this.contract }));
  }
}
