import { initialState } from './request-overview-state.model';

import { ActionReducer, createReducer, on } from '@ngrx/store';
import { RequestOverviewState } from './request-overview-state.model';
import {
  AgencyOfferWithContractsLoaded,
  FailedToLoadAgencyOfferWithContracts,
  FailedToLoadOfferWithContracts, FailedToLoadPermanentOfferWithContracts,
  LoadOwnTechnicianOfferWithContracts,
  OfferWithContractsLoaded, PermanentOfferWithContractsLoaded,
  ResetOfferDetails
} from './request-overview.actions';
import {
  ContractLoaded,
  LoadAgencyOfferWithContracts,
  LoadOfferWithContracts,
  LoadPackageOfferAndContractForTechnician,
  LoadPackageOfferWithContracts, LoadPermanentOfferWithContracts,
  SuccessfulDeletedAgencyOffer,
  SuccessfulDeletedOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export function getReducer(): ActionReducer<RequestOverviewState> {
  return createReducer(
    initialState,
    on(GlobalReset, () => initialState),
    on(
      LoadOfferWithContracts,
      LoadPermanentOfferWithContracts,
      LoadOwnTechnicianOfferWithContracts,
      LoadPackageOfferWithContracts,
      LoadPackageOfferAndContractForTechnician,
      LoadAgencyOfferWithContracts,
      () => ({
        loading: true,
        offer: null,
        contracts: [],
        offerNotFound: null
      })
    ),
    on(OfferWithContractsLoaded, PermanentOfferWithContractsLoaded, (_, action) => ({
      loading: false,
      offer: action.response.offer,
      contracts: action.response.contracts._embedded.contracts,
      offerNotFound: null
    })),
    on(FailedToLoadOfferWithContracts, FailedToLoadPermanentOfferWithContracts, SuccessfulDeletedOffer, () => ({
      loading: false,
      offer: null,
      contracts: [],
      offerNotFound: true
    })),
    on(ContractLoaded, (state, action) => ({
      ...state,
      contracts: state.contracts.map(c =>
        c.id == action.contract.id ? action.contract : c
      )
    })),
    on(AgencyOfferWithContractsLoaded, (_, action) => ({
      loading: false,
      offer: action.response,
      contracts: action.response._embedded.agencyOfferContracts,
      offerNotFound: null
    })),
    on(FailedToLoadAgencyOfferWithContracts, SuccessfulDeletedAgencyOffer, () => ({
      loading: false,
      offer: null,
      contracts: [],
      offerNotFound: true
    })),
    on(ResetOfferDetails, (state, action) => ({
      ...state,
      ...initialState
    }))
  );
}
