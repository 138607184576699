import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DownloadConversationFile, PromptForDeleteConversationMessage} from '@libs/mailbox/state/mailbox.actions';
import {Store} from '@ngrx/store';
import {Message} from '@libs/mailbox/state/mailbox-state.model';
import {DateTime} from "luxon";

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  private static readonly THRESHOLD_TO_UPDATE_MESSAGES_MILLIS: number =
    10 * 60 * 1000;

  @Input() ownerPictureUrl: string = '';
  @Input() message: Message = null;
  @Output() onAvatarClicked = new EventEmitter();

  public mobileDateVisible: boolean = false;
  public hidePic: boolean = false;

  constructor(private store: Store<any>) {
  }

  public toggleDate() {
    this.mobileDateVisible = !this.mobileDateVisible;
  }

  public avatarClickedHandler(event): void {
    event.stopPropagation();
    this.onAvatarClicked.emit();
  }

  public isFileMessage(): boolean {
    return !!this.message.fileUuid;
  }

  public isMessageDeleted(): boolean {
    return !!this.message.deletedOn;
  }

  public isMessageTooOld(): boolean {
    return (
      DateTime.now().toMillis() - this.message.createdOn >
      MessageComponent.THRESHOLD_TO_UPDATE_MESSAGES_MILLIS
    );
  }

  public downloadConversationFile(): boolean {
    this.store.dispatch(
      new DownloadConversationFile(
        this.message.filename,
        this.message.getConversationMessageTemporaryFileUrl
      )
    );
    return false;
  }

  public deleteConversationMessage(message: any) {
    this.store.dispatch(
      new PromptForDeleteConversationMessage(
        message.deleteConversationMessageUrl
      )
    );
  }

  ngOnInit() {
  }
}
