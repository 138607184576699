import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  standalone: true
})
export class JoinPipe implements PipeTransform {
  private readonly NAME_FIELD: string = 'name';

  transform(input: Array<string | { name: string }>, separator: string = ', '): string {
    const mappedInput: string[] = input.map(item => {
      return Object.prototype.hasOwnProperty.call(item, this.NAME_FIELD) ? item[this.NAME_FIELD] : item;
    });
    return mappedInput.join(separator);
  }
}
