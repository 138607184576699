<staffnow-go-back-button
    [currentPath]="['AGENCY.DETAILS.MY_JO' | translate,
    'AGENCY.REQUEST.OVERVIEW.TABS.AVIATION_COMPANIES_JOB.LABEL' | translate,
    'AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS' | translate]"
></staffnow-go-back-button>
@if (isLoading) {
<staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
} @else {
<staffnow-offer-details [offer]="jobOffer" [title]="'AGENCY.DETAILS.JO_DETAILS' | translate: {offerDescription}">
    <ng-container data>
        <staffnow-contract-info [label]="'GENERAL.PRIORITY' | translate">
            <staffnow-offer-priority-info
                [displayLabel]="false"
                [priority]="jobOffer?.priority"
            ></staffnow-offer-priority-info>
        </staffnow-contract-info>
        <staffnow-contract-info [label]="'GENERAL.OPEN_POSITIONS' | translate">
            {{ jobOffer?.vacancies }}
        </staffnow-contract-info>
        @if (!jobOffer.hasHiddenRate) {
        <staffnow-contract-info [label]="'GENERAL.CHARGE_RATE' | translate">
            @if(hasIndicativeRateForOffer(jobOffer)) {
            {{ jobOffer?.chargeRate | rate }}
            {{ ' - ' }}
            {{ jobOffer?.maxChargeRate | rate }}
            {{ ' per hour ' | translate }}
            } @else {
            {{ jobOffer?.chargeRate | rate }}
            {{ ' per hour ' | translate }}
            }
            <small class="text-muted"> ({{ 'SYSTEM.INFO.CHARGE_RATE_TOOLTIP' | translate }}) </small>
        </staffnow-contract-info>
        <staffnow-contract-info [label]="'GENERAL.PAY_RATE' | translate">
            @if(hasIndicativeRateForOffer(jobOffer)) {
            {{ jobOffer?.minPayRate | rate }}
            {{ ' - ' }}
            {{ jobOffer?.maxPayRate | rate }}
            {{ ' per hour' | translate }}
            } @else {
            {{ jobOffer?.payRate | rate }}
            {{ ' per hour' | translate }}
            }
            <small class="text-muted"> ({{ 'SYSTEM.INFO.PAY_RATE_TOOLTIP' | translate }}) </small>
        </staffnow-contract-info>
        }
    </ng-container>
</staffnow-offer-details>
<staffnow-collapsible-contracts-container [contracts]="contracts">
    <ng-container no-data>
        <h2>
            {{
                'AGENCY.JO_DETAILS.NO_DATA' | translate
            }}
        </h2>
    </ng-container>
</staffnow-collapsible-contracts-container>
}
