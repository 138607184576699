<h1 class="h1">Change user email</h1>
<div class="form-group">
    <label for="email">{{ 'Email' | translate }}</label>
    <input
        [(ngModel)]="userEmail"
        class="form-control"
        id="email"
        type="text"
    />
</div>
<div>
    <general-button
        (onClick)="handleEmailChange()"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
        text="Change email"
    ></general-button>
</div>
