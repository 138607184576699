<div class="single-benefit" *ngFor="let benefit of benefits">
    <i class="material-icons icon">check</i>
    <div class="description">
        <label>{{ getLabel(benefit) | translate }}</label>
        <div *ngIf="hasDescription(benefit)">
            {{ benefit?.description }}
        </div>
    </div>
</div>
<p *ngIf="benefits?.length === 0">No additional benefits</p>
