import { Component, EventEmitter, Input, Output } from '@angular/core'
import { addUtils, Contract, ContractWithUtils } from '@libs/shared/models/contract.model'
import { ContractStatusUtils } from '@libs/shared/models/contract-status.enum'
import { StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model'
import { EndDateExtensionPhaseUtils } from '@libs/shared/models/end-date-extension-phase.enum'
import { OfferOutDto } from '@libs/shared/models/offer.model'
import { Store } from '@ngrx/store'
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector'
import { UntilDestroy } from '@ngneat/until-destroy'
import { BrandName, EnvironmentState } from '@libs/shared/bms-common/environment/environment.model'
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'staffnow-technician-guidelines',
  templateUrl: './technician-guidelines.component.html',
  styleUrls: ['./technician-guidelines.component.scss']
})
export class TechnicianGuidelinesComponent {
  @Input() public offer: OfferOutDto = null
  @Input() public contract: Contract = null
  @Input() public requiresAgencySelectionForOfferApplication: boolean = true
  @Input() public brandName: string = null
  @Output() onLabourAcknowledgmentChanged = new EventEmitter<boolean>()
  public contractWithUtils: ContractWithUtils = null
  private customerSupportLinkEmailTo: string

  @Input() public labourRegulationsAcknowledgmentClicked: boolean = false
  public isLabourAcknowledgmentCheckboxDirty: boolean = false

  constructor (private readonly store: Store,
               private readonly translateService: TranslateService) {
    this.store
      .pipe(getStaticEnvironment)
      .subscribe((env: EnvironmentState) => {
        const secondCustomerSupportEmail =
          env.brandConfig.secondCustomerSupportEmail
        this.customerSupportLinkEmailTo = `<a href="mailto:${secondCustomerSupportEmail}" target="_blank" rel="noopener noreferrer" title="mailto:${secondCustomerSupportEmail}">${secondCustomerSupportEmail}</a>`
      })
  }

  get isRejectedByAnyMro (): boolean {
    return ContractStatusUtils.isRejectedByAnyMro(this.contract)
  }

  get contractCanBeExtended (): boolean {
    return EndDateExtensionPhaseUtils.isPendingOrIgnored(this.contract)
  }

  get unsetAgencyGuidelines (): string {
    if (
      ContractStatusUtils.isAcceptedByTechnician(this.contract) &&
      ContractStatusUtils.isPendingByAgency(this.contract)
    ) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTACT_SUPPORT', {customerSupportLinkEmailTo: this.customerSupportLinkEmailTo})
    }
    return null
  }

  get generalGuidelines (): string {
    if (ContractStatusUtils.isPendingByTechnician(this.contract)) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_PENDING_BY_TECHNICIAN');
    }
    if (ContractStatusUtils.isRejectedByAgency(this.contract)) {
      return this.requiresAgencySelectionForOfferApplication
        ? this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_REJECTED_BY_AGENCY_REQUIRES_SELECTION')
        : this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_REJECTED_BY_AGENCY', {customerSupportLinkEmailTo: this.customerSupportLinkEmailTo});
    }
    if (ContractStatusUtils.isPendingByBothMro(this.contract)) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_MRO_PENDING', {
        entity: 'Aviation Company'
      });
    }
    if (ContractStatusUtils.isPendingByMroProduction(this.contract)) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_MRO_PENDING', {
        entity: 'Aviation Company Production'
      });
    }
    if (ContractStatusUtils.isPendingByMroQuality(this.contract)) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_MRO_PENDING', {
        entity: 'Aviation Company Quality'
      });
    }
    if (ContractStatusUtils.isPendingByMroHumanResources(this.contract)) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_MRO_PENDING', {
        entity: 'Aviation Company Human Resources'
      });
    }
    if (
      ContractStatusUtils.isAccepted(this.contract) &&
      StartDatePhaseUtils.isPendingOrUnset(this.contract.startDatePhase)
    ) {
      return this.translateService.instant('TECHNICIAN_GUIDELINES.CONTRACT_ACCEPTED');
    }
    return null
  }

  ngOnChanges (): void {
    this.contractWithUtils = addUtils(this.contract)
  }

  public get isStaffnowBrand (): boolean {
    return this.brandName === BrandName.StaffNow
  }

  public get shouldShowLabourAcknowledgment (): boolean {
    return this.isStaffnowBrand && !ContractStatusUtils.isAcceptedByTechnician(this.contract)
  }


  public labourAcknowledgmentChanged (): void {
    this.isLabourAcknowledgmentCheckboxDirty = !this.labourRegulationsAcknowledgmentClicked
    this.onLabourAcknowledgmentChanged.emit(this.labourRegulationsAcknowledgmentClicked)
  }

  public get displayAcknowledgeErrorMessage (): boolean {
    return this.shouldShowLabourAcknowledgment && this.isLabourAcknowledgmentCheckboxDirty && !this.labourRegulationsAcknowledgmentClicked
  }
}
