import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlDetector'
})
export class UrlDetectorPipe implements PipeTransform {
  private URL_REGEX: string =
    '(?:(?:https?):\\/\\/|\\b(?:[a-z\\d]+\\.))(?:(?:[^\\s()<>]+|\\((?:[^\\s()<>]+|(?:\\([^\\s()<>]+\\)))?\\))+(?:\\((?:[^\\s()<>]+|(?:\\(?:[^\\s()<>]+\\)))?\\)|[^\\s`!()\\[\\]{};:\'".,<>?«»“”‘’]))';

  transform(value: string): string {
    if (!value) {
      return value;
    }
    const matches = value.match(new RegExp(this.URL_REGEX, 'gm'));
    if (matches && matches.length > 0) {
      const smartReplaceMatches = matches.map(match => {
        const replaceValue = `<a href="${
          !match.startsWith('http') ? 'https://' + match : match
        }" target="_blank">${match}</a>`;
        return { searchValue: match, replaceValue };
      });
      value = this.smartReplace(value, smartReplaceMatches);
    }
    return value;
  }

  private smartReplace(fullString: string, matchesToReplace: { searchValue: string; replaceValue: string }[]): string {
    let slicedString = fullString;
    let accumulatedString = '';
    matchesToReplace.forEach(({ replaceValue, searchValue }) => {
      const slicedStringForMatch = slicedString.slice(0, slicedString.indexOf(searchValue) + searchValue.length);
      accumulatedString = accumulatedString + slicedStringForMatch.replace(searchValue, replaceValue);
      slicedString = slicedString.slice(slicedString.indexOf(slicedStringForMatch) + slicedStringForMatch.length);
    });
    return accumulatedString + slicedString;
  }
}
