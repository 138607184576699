export interface SubscriptionItem {
  uuid: string;
  name: string;
  ameNomenclatures: SubscriptionNomenclature[];
}

export interface SubscriptionNomenclature {
  name: string;
  isSingleton: boolean;
  licenses: SubscriptionLicense[];
}

export interface SubscriptionLicense {
  id: number;
  name: string;
  checked: boolean;
  singleton: boolean;
}

export enum SubscriptionType {
  ALL = "ALL",
  MATCHING = "MATCHING",
  CUSTOM = "CUSTOM",
  NONE = "NONE"
}

export const technicianSubscriptionMessages = {
  [SubscriptionType.ALL]: 'SYSTEM.INFO.SUCCESSFULLY_SUBSCRIBED_TO_ALL_OFFERS',
  [SubscriptionType.MATCHING]: 'SYSTEM.INFO.SUCCESSFULLY_SUBSCRIBED_TO_ALL_MATCHING_OFFERS',
  [SubscriptionType.CUSTOM]: 'SYSTEM.INFO.SUCCESSFULLY_SUBSCRIBED_TO_CUSTOM_OFFERS',
  [SubscriptionType.NONE]: 'SYSTEM.INFO.SUCCESSFULLY_UNSUBSCRIBED_FROM_OFFERS',
};