export const PARAMETER_PLACEHOLDER = '[param]';

export const FormErrorMessages = {
  required: {
    message: 'This field is required'
  },
  $mroExists: {
    message: `This company already exists, please contact ${PARAMETER_PLACEHOLDER} to arrange your access`,
    paramName: 'infoEmail'
  },
  passwordsDontMatch: {
    message: 'The passwords do not match'
  },
  email: {
    message: 'This is not a valid email'
  },
  lessThan: {
    message: `The value should be less than ${PARAMETER_PLACEHOLDER}`,
    paramName: 'comparand'
  },
  lessThanOrEqualTo: {
    message: `The value should be less or equal to ${PARAMETER_PLACEHOLDER}`,
    paramName: 'comparand'
  },
  greaterThanOrEqualTo: {
    message: `The value should be greater or equal to ${PARAMETER_PLACEHOLDER}`,
    paramName: 'comparand'
  },
  greaterThan: {
    message: `The value should be greater than ${PARAMETER_PLACEHOLDER}`,
    paramName: 'comparand'
  },
  minLength: {
    message: `The input should be at least ${PARAMETER_PLACEHOLDER} characters long`,
    paramName: 'minLength'
  },
  maxLength: {
    message: `The input should not exceed ${PARAMETER_PLACEHOLDER} characters`,
    paramName: 'maxLength'
  },
  pattern: {
    message:
      'Password must be at least 8 characters long and contain at least 1 uppercase Latin letter (A-Z), 1 lowercase Latin letter (a-z) and 1 number',
    paramName: 'pattern'
  },
  requiredRolesMissing: {
    message: 'At least one role needs to be selected'
  },
  tooManyRolesSelected: {
    message: 'Cannot pick 3 roles'
  }
};
