import {getReportConfig} from '@libs/shared/models/reports.model';
import {getCurrentUTCDateString} from "@libs/shared/helpers/date-utils";

export class ReportsHelper {
  public static isRangeSelectAvailable(reportKey: string): boolean {
    return !getReportConfig(reportKey)?.hasFixedDate;
  }

  public static dateRangeConverter(range: string[]): any {
    return {
      fromDate: (range && range[0]) ? range[0] : getCurrentUTCDateString(),
      toDate: (range && range[1]) ? range[1] : getCurrentUTCDateString()
    };
  }
}
