import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';

@Component({
  selector: 'date-range-filter',
  templateUrl: './date-range-filter.component.html'
})
export class DateRangeFilterComponent implements OnChanges {
  @Input() filterConfig: FilterConfigModel = null;
  @Output() public onChange = new EventEmitter<string[]>();

  public rangeValue: string[] = [];

  ngOnChanges(): void {
    if (this.filterConfig) {
      this.rangeValue = this.filterConfig.initialValue || [];
    }
  }

  public handleRangeChange(dates: string[]): void {
    this.rangeValue = dates;
    this.onChange.emit(dates);
  }
}
