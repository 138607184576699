import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserProfileType} from '@libs/shared/models/user-profile.type';
import {hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';

@Component({
  selector: 'staffnow-backoffice-account-management',
  templateUrl: './backoffice-account-management.component.html',
  styleUrls: ['./backoffice-account-management.component.scss']
})
export class BackofficeAccountManagementComponent {
  @Input() public userProfile: UserProfileType = null;
  @Output() public previousPerformanceFeedbackRequests: EventEmitter<any> = new EventEmitter<any>();

  get canSendExperienceConfirmationRequest(): boolean {
    return hasLink(this.userProfile, UserProfileLinkRel.SendExperienceConfirmationRequest);
  }

  get canSendPerformanceFeedbackRequest(): boolean {
    return hasLink(this.userProfile, UserProfileLinkRel.SendPerformanceFeedbackRequest);
  }

  get canUpdateTechniciansRefNumber(): boolean {
    return hasLink(this.userProfile, UserProfileLinkRel.UpdateTechnicianRefNumber);
  }

  public goToPreviousRequests() {
    this.previousPerformanceFeedbackRequests.emit();
  }
}
