<div class="country-selector-container">
    <ng-select
        [items]="countries$ | async"
        bindLabel="name"
        bindValue="name"
        class="country-selector"
        [ngClass]="{ invalid: !ngrxFormControlState.isValid && ngrxFormControlState.isTouched }"
        [disabled]="ngrxFormControlState.isDisabled"
        [clearable]="!!selectedCountryName"
        [(ngModel)]="selectedCountryName"
        (change)="onCountryChange($event)"
    >
    </ng-select>
</div>
