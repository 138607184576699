import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe } from '@angular/common';

@Component({
  selector: 'work-pattern',
  standalone: true,
  imports: [TranslateModule, LowerCasePipe],
  templateUrl: './work-pattern-display.component.html'
})
export class WorkPatternDisplayComponent implements OnInit {
  @Input() daysOn: number = null;
  @Input() daysOff: number = null;

  ngOnInit(): void {
    if (!this.daysOn) {
      this.daysOn = 0;
    }
    if (!this.daysOff) {
      this.daysOff = 0;
    }
  }
}
