import { createAction, props } from '@ngrx/store';

export const FailedToSubmitPermanentOffer = createAction('[Create Permanent Offer] Failed to submit offer');
export const PermanentOfferSubmitSuccess = createAction(
  '[Create Permanent Offer] Success offer submit',
  props<{ refNumber?: string }>()
);
export const SubmitPermanentOffer = createAction(
  '[Create Permanent Offer] Submit offer',
  props<{ payload: any; editUrl?: string }>()
);
