import { ApiRootLoaded, GlobalReset } from './api-root.actions';
import { ApiRootState } from './api-root.model';

const initialState: ApiRootState = {
  apiRoot: null
};

export const bmsApiRootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ApiRootLoaded.type:
      return {
        ...state,
        apiRoot: action.payload
      };
    case GlobalReset.type:
      return initialState;
  }
  return state;
};
