import { NgModule } from '@angular/core';
import TasksViewComponent from './components/task-view/tasks-view.component';
import TaskColumnComponent from './components/task-column/task-column.component';
import TaskComponent from './components/task/task.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@libs/shared/shared.module';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import TaskRowComponent from './components/task-row/task-row.component';
import { TranslationModule } from '@libs/translation/translation.module';

@NgModule({
  imports: [TranslationModule, CommonModule, SharedModule, CommonUiModule],
  declarations: [
    TasksViewComponent,
    TaskColumnComponent,
    TaskRowComponent,
    TaskComponent
  ]
})
export class MyTasksModule {}
