<div class="categories-container">
    <!--Feedback's Overall Score Category-->
    <div class="category">
        <div class="category-title">
            <h1 class="h1">
                <b>
                    Overall feedback score: @if (hasScore) {
                    <span style="font-size: 120%">{{ overallScore | number : '0.1-2' }}</span>
                    } @else {
                    <span style="font-size: 120%">N/A</span>
                    }
                </b>
            </h1>
        </div>
    </div>

    <!--Feedback's Category-->
    @if (hasScore) {
    <div class="category">
        <div class="category-title">
            <h2 class="h2">Individual feedback scores</h2>
        </div>
        <div class="custom-controls-stacked">
            @for (feedback of technicianPerformanceFeedbacks; track feedback.averageScore) {
            <h3 class="py-2" (click)="onToggleFeedback(feedback)" (keyup)="onToggleFeedback(feedback)">
                <a class="avi-collapse-toggle" [ngClass]="{ collapsed: !feedback.isExpanded }">
                    {{ feedback.recipientAviationCompany }}:
                    <b>{{
                        isNaN(feedback.averageScore) ? 'Pending reply' : (feedback.averageScore | number : '0.1-2')
                    }}</b>
                </a>
            </h3>
            <div class="collapse" [ngClass]="{ show: feedback.isExpanded }" role="tabpanel">
                <div class="collapse-body">
                    <div class="feedback-item">
                        <p>
                            @for (criteriaScore of feedback.criteriaScores; track criteriaScore.title) {
                            {{ criteriaScore.title }}: <b>{{ criteriaScore.score | number : '0.1-2' }}</b>
                            }
                        </p>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    }
</div>
