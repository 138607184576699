import {Component, Input, OnInit} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '@libs/shared/helpers/abstract-value-accessor';
import {Country} from "@libs/shared/models/country.model";
import {getFilteredApiRoot} from "@libs/shared/bms-common/api-root/api-root.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Store} from "@ngrx/store";
import {AppRegionEnum} from "@libs/shared/bms-common/environment/environment.model";
import { escapeRegExp, isNil } from 'lodash-es';


@UntilDestroy()
@Component({
  selector: 'phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [MakeProvider(PhoneNumberInputComponent)]
})
export class PhoneNumberInputComponent extends AbstractValueAccessor implements OnInit {
  @Input() public countries: Country[] = [];
  public selectedCountryId: number = null;
  public phoneNumber: string = null;
  private apiRegion: string = null;
  private defaultCountryId: number = null;

  constructor(protected store: Store<any>,) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(getFilteredApiRoot, untilDestroyed(this))
      .subscribe(apiRoot => {
        this.apiRegion = apiRoot.apiRegion;
        if (this.apiRegionIsUsa) {
          this.defaultCountryId = this.countries.find(country => country.phoneCode === 1 && country.name === 'United States').id;
        }
      });
  }

  onPhoneChange() {
    this.value = {
      countryId: this.selectedCountryId,
      phoneNumber: this.phoneNumber
    };
  }


  writeValue(value: any) {
    this._value = value;
    if (isNil(this._value)) {
      this.setSelectedCountryId(null);
      this.phoneNumber = null;
    } else {
      this.setSelectedCountryId(this._value.countryId);
      this.phoneNumber = this._value.phoneNumber;
    }
  }

  private setSelectedCountryId(newCountryId: number): void {
    this.selectedCountryId = isNil(newCountryId) ? this.defaultCountryId : newCountryId;
  }

  private findCountry(countryId: number) {
    return this.countries.find(country => country.id === countryId);
  }

  public optionTitle(countryId: number): string {
    const country = this.findCountry(countryId);
    if (this.selectedCountryId === countryId) {
      return "+" + country.phoneCode;
    } else {
      return country.name + ` (+${country.phoneCode})`;
    }
  }

  get apiRegionIsUsa(): boolean {
    escapeRegExp()
    return this.apiRegion === AppRegionEnum.USA;
  }

}
