<staffnow-go-back-button
    [currentPath]="['AGENCY.DETAILS.TITLE_JO_APPLICATIONS' | translate,
    'MRO.PACKAGE_OFFER.PERMANENT_JO_DETAILS' | translate]"
></staffnow-go-back-button>
@if(isLoading) {
<staff-loader customClass="splash-screen-loader"></staff-loader>
} @else {
<staffnow-offer-details
    *ngIf="!isLoading"
    [offer]="offer"
    [title]="'AGENCY.DETAILS.PERMANENT_JO_DETAILS' | translate:{offerDescription}"
>
    <ng-container data>
        <staffnow-contract-info [label]="'GENERAL.PRIORITY' | translate">
            <staffnow-offer-priority-info
                [displayLabel]="false"
                [priority]="offer?.priority"
            ></staffnow-offer-priority-info>
        </staffnow-contract-info>
        <staffnow-contract-info [label]="'GENERAL.OPEN_POSITIONS' | translate">
            {{ offer?.vacancies }}
        </staffnow-contract-info>
        <staffnow-contract-info [label]="'GENERAL.FILLED_POSITIONS' | translate">
            {{ offer?.fullyMatchedSize }}
            <small class="text-muted"> ({{ calculateRemainingPositions + ' remaining' | translate }}) </small>
        </staffnow-contract-info>
        @if(!offer.hasHiddenRate) {
        <staffnow-contract-info [label]="'GENERAL.NET_SALARY' | translate">
            {{ offer?.monthlySalary | rate }}
            {{ ' per month' | translate }}
        </staffnow-contract-info>
        }
    </ng-container>
    <ng-container actions>
        @if (canDeleteOffer) {
        <general-button
            (onClick)="handleDeleteOffer()"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.DELETE_JO' | translate"
            tooltip="{{'SYSTEM.INFO.DELETE_JO' | translate}}"
            color="danger"
        ></general-button>
        } @if (canCloseOffer) {
        <general-button
            (onClick)="closeOffer()"
            backgroundStyle="outline"
            size="small"
            [text]="'AGENCY.DETAILS.CLOSE_FOR_APPLICATIONS' | translate"
            tooltip="{{'SYSTEM.INFO.CLOSE_JO_TOOLTIP' | translate}}"
            color="danger"
        ></general-button>
        } @if (canReopenOffer) {
        <div class="contract-action">
            <general-button
                (onClick)="reopenOffer()"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.REOPEN' | translate"
                tooltip="{{'SYSTEM.INFO.REOPEN_JO_TOOLTIP' | translate}}"
            ></general-button>
            <staffnow-info-icon-with-tooltip
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
        </div>
        } @if(jobOffer?._links[RequestOverviewLinkRel.ApproveOffer]) {
        <general-button
            (onClick)="approveOffer()"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.APPROVE' | translate"
        ></general-button>
        } @if (canEditOffer) {
        <general-button
            (onClick)="openEditOfferForm()"
            backgroundStyle="outline"
            size="small"
            [text]="'AGENCY.DETAILS.EDIT_JOB_OPENING' | translate"
            tooltip="{{'SYSTEM.INFO.EDIT_JO' | translate}}"
        ></general-button>
        }
    </ng-container>
</staffnow-offer-details>
<staffnow-collapsible-contracts-container [contracts]="contracts">
    <ng-container no-data>
        <h2 class="h2">
            {{ 'AGENCY.DETAILS.NO_APPLICANTS' | translate }}
        </h2>
    </ng-container>
</staffnow-collapsible-contracts-container>
}
