import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgrxFormsModule } from 'ngrx-forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { FeedbackAlertComponent } from './components/feedback-alert/feedback-alert.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import {
  FEEDBACK_STATE_KEY,
  reducersInjectionToken,
  feedbackInitialState as fbInitialState,
  getFeedbackReducers,
  FeedbackState
} from './state/feedback.reducer';
import { FeedbackEffects } from './state/feedback.effects';

export function getFeedbackInitialState(): Partial<FeedbackState> {
  return {
    ...fbInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgrxFormsModule,
    StoreModule.forFeature(FEEDBACK_STATE_KEY, reducersInjectionToken, {
      initialState: getFeedbackInitialState
    }),
    EffectsModule.forFeature([FeedbackEffects])
  ],
  providers: [
    { provide: reducersInjectionToken, useFactory: getFeedbackReducers }
  ],
  declarations: [FeedbackAlertComponent, FeedbackFormComponent],
  exports: [FeedbackAlertComponent]
})
export class FeedbackModule {}
