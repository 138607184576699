import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../../../apps/staffnow-platform/src/app/state/app-state.model';
import { selectMroApprovals } from '../../../../../../apps/staffnow-platform/src/app/state/app.selectors';
import { GetMroApprovals } from '../facility-profile.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {SimpleFacilityWithUuid} from "@libs/shared/models/facility.model";

@UntilDestroy()
@Component({
  selector: 'staffnow-mro-approvals',
  templateUrl: './mro-approvals.component.html',
  styleUrls: ['./mro-approvals.component.scss']
})
export class MroApprovalsComponent {
  public mroApprovals: SimpleFacilityWithUuid[] = [];
  public itemsPerSlide: number = 0;

  constructor(private store: Store<AppState>) {
    this.store.dispatch(GetMroApprovals());
    this.store
      .pipe(selectMroApprovals, untilDestroyed(this))
      .subscribe(list => {
        this.mroApprovals = list;
        this.itemsPerSlide = Math.min(this.mroApprovals.length, 5);
      });
  }

}
