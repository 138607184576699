<i class="material-icons close-icon" (click)="bsModalRef.hide()"> clear </i>
<div class="header">
    <h1 class="heading">{{ title | translate }}</h1>
</div>
<div class="content">
    <div class="label-setter-wrapper">
        <strong>{{ 'Select the label: ' | translate }}</strong>
        <input
            [(ngModel)]="label"
            maxlength="100"
            class="form-control"
            type="text"
        />
    </div>
    <general-button
        class="button-custom"
        (onClick)="submit()"
        [isDisabled]="isDisabled"
        text="Submit"
    ></general-button>
</div>
