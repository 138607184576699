import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@libs/common-ui/buttons/buttons.module';

export class DocumentChange {
  id: string;
  title: string;
  operation: Operation;
  mandatory: Operation;
  expiration: Operation;
}

export enum Operation {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
  NO_OP = 'NO_OP'
}

@Component({
  standalone: true,
  selector: 'staffnow-document-change-confirmation-modal',
  templateUrl: './document-change-confirmation-modal.component.html',
  styleUrls: ['./document-change-confirmation-modal.component.scss'],
  imports: [CommonModule, ButtonsModule, FormsModule]
})
export class DocumentChangeConfirmationModalComponent {
  @Input() changes: DocumentChange[];
  @Input() brand: string;
  @Input() onAcceptCallback: () => void;

  confirmation: string = '';

  constructor(public bsModalRef: BsModalRef) {}

  agreedHandler(): void {
    this.onAcceptCallback();
    this.bsModalRef.hide();
  }

  cancelHandler(): void {
    this.bsModalRef.hide();
  }

  userConfirmed(): boolean {
    return this.confirmation === 'Confirm';
  }

  iconChange(operation: Operation): string {
    switch (operation) {
      case Operation.ADDED:
        return 'check';
      case Operation.REMOVED:
        return 'clear';
      default:
        return '';
    }
  }

  iconColor(operation: Operation): string {
    switch (operation) {
      case Operation.ADDED:
        return 'green';
      case Operation.REMOVED:
        return 'red';
      default:
        return '';
    }
  }

  documentVisibility(operation: Operation): string {
    switch (operation) {
      case Operation.ADDED:
        return 'visibility';
      case Operation.REMOVED:
        return 'visibility_off';
      default:
        return '';
    }
  }

  hasChanges(operation: Operation): boolean {
    return operation !== Operation.NO_OP;
  }
}
