import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'staffnow-text-counter',
  templateUrl: './text-counter.component.html',
  styleUrls: ['./text-counter.component.scss']
})
export class TextCounterComponent {
  @Input() public textLength: number = 0;
  @Input() public maxLength: number = 0;
  @Input() public size: 'small' | 'medium' = 'small';
  @HostBinding('class') get classes(): string {
    return this.size;
  }

}
