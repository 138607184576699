export interface BulletPointItem {
  label: string;
  description: string;
}

export interface BulletPointItemList {
  bulletPoints: BulletPointItem[];
}

export const faqsToBulletPoints = (faqs: Array<any>): BulletPointItem[] => {
  return faqs.map(it => {
    return {
      label: it.question,
      description: it.answer
    };
  });
};

export const bulletPointListToFaqList = (
  bulletPointItemList: BulletPointItemList
) => {
  return {
    list: bulletPointItemList.bulletPoints.map(it => {
      return { question: it.label, answer: it.description };
    })
  };
};

export const titlesRequirementsToBulletPoints = (
  titlesRequirements: Array<any>
): BulletPointItem[] => {
  return titlesRequirements.map(it => {
    return {
      label: it.label,
      description: it.description
    };
  });
};
