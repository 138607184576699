<staffnow-go-back-button
    [currentPath]="[
        'AGENCY.DETAILS.MY_JO' | translate,
        'AGENCY.REQUEST.OVERVIEW.TABS.OWN_TECHNICIANS' | translate,
        'AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS' | translate
    ]"
></staffnow-go-back-button>
@if(isLoading) {
<staff-loader [customClass]="'splash-screen-loader'"></staff-loader>

} @else {
<staffnow-panel [title]="'AGENCY.DETAILS.APPLICATION_STATUS.LABEL' | translate: {refNumber: jobOffer?.refNumber}">
    <ng-container data>
        <div class="technician-details">
            <staffnow-technician-contract-details [contract]="contract"></staffnow-technician-contract-details>
            <staffnow-technician-guidelines
                [offer]="offer"
                [contract]="contract"
                [requiresAgencySelectionForOfferApplication]="requiresAgencySelectionForOfferApplication"
            ></staffnow-technician-guidelines>
        </div>
    </ng-container>
    <ng-container actions>
        <staffnow-technician-contract-actions
            [contract]="contract"
            [offer]="offer"
            (acceptDocument)="acceptContract()"
            [isDisabledAcceptDocumentButton]="isOfferEnded"
            [shouldDisplayAcceptButton]="canAcceptContract"
        ></staffnow-technician-contract-actions>
        <general-button
            (onClick)="initiateMessageWithMro()"
            [isDisabled]="!canCreateConversationWithMro && !canGetPreviousConversationWithMro"
            title="{{ 'SYSTEM.INFO.MESSAGE_MRO_AFTER_ACCEPTING_JO' | translate }}"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_AVIATION_COMPANY' | translate"
            color="secondary"
        ></general-button>
        <general-button
            (onClick)="initiateMessageWithTechnician()"
            [isDisabled]="!canCreateConversationWithTechnician && !canGetPreviousConversationWithTechnician"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_TECHNICIAN' | translate"
            title="{{ messageTooltip }}"
        ></general-button>
    </ng-container>
</staffnow-panel>
<staffnow-offer-details *ngIf="!isLoading" [offer]="jobOffer" [title]="'OWN_TECHNICIAN.JO_DETAILS' | translate: {offerDescription}">
    <ng-container data>
        @if(!offer.hasHiddenRate) {
        <staffnow-contract-info [label]="'Pay Rate: ' | translate">
            <staffnow-technician-offer-pay-rate [jobOffer]="jobOffer"> </staffnow-technician-offer-pay-rate>
        </staffnow-contract-info>
        }
    </ng-container>
</staffnow-offer-details>
}
