import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {EMPTY} from 'rxjs';

import {ToastMessageService} from '@libs/toast-messages/toast-message.service';

import {
  FeedbackActions,
  FeedbackFormSentSuccess,
  FeedbackListLoaded,
  FeedbackRejected,
  LoadFeedbackList,
  RejectFeedback,
  SendFeedbackForm
} from './feedback.actions';
import {getEmbeddedResource, getUrl, hasEmbeddedResource} from '@libs/shared/bms-common/rest/resource.utils';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {getFilteredApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FeedbackEffects {
  public getFeedbackList$ = createEffect(() =>
    this.actions.pipe(
      ofType(FeedbackActions.LoadFeedbackList),
      switchMap((action: LoadFeedbackList) => {
        return this.httpService.get(action.accessLinkRel).pipe(
          switchMap((response: Array<any>) => {
            const technicianFeedbackOutDtoList: Array<any> = hasEmbeddedResource(
              <any>response,
              'technicianFeedbackOutDtoList'
            )
              ? getEmbeddedResource(
                <any>response,
                'technicianFeedbackOutDtoList'
              )
              : [];
            return [new FeedbackListLoaded(technicianFeedbackOutDtoList)];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.ERROR.GET_FEEDBACK_LIST_ERROR'));
            return EMPTY;
          })
        );
      })
    )
  );

  public submitFeedbackForm$ = createEffect(() =>
    this.actions.pipe(
      ofType(FeedbackActions.SendFeedbackForm),
      switchMap((action: SendFeedbackForm) => {
        return this.httpService.patch(action.patchLinkRel, action.payload).pipe(
          switchMap((response: any) => {
            this.toastMessageService.success(
              this.translateService.instant('SYSTEM.INFO.FEEDBACK_SENT_SUCCESSFULLY')
            );
            return [
              new FeedbackFormSentSuccess(),
              new LoadFeedbackList(
                getUrl(this.apiRoot, ApiRootLinkRel.NotGivenFeedback)
              )
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.ERROR.FEEDBACK_FORM_SEND_FAIL'));
            return EMPTY;
          })
        );
      })
    )
  );

  public rejectFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(FeedbackActions.RejectFeedback),
      switchMap((action: RejectFeedback) => {
        return this.httpService.delete(action.rejectLinkRel).pipe(
          switchMap((response: any) => {
            this.toastMessageService.success(
              this.translateService.instant('SYSTEM.INFO.FEEDBACK_REJECT_SUCCESSFULLY')
            );
            return [
              new FeedbackRejected(),
              new LoadFeedbackList(
                getUrl(this.apiRoot, ApiRootLinkRel.NotGivenFeedback)
              )
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.ERROR.REJECT_FEEDBACK_FAIL'));
            return EMPTY;
          })
        );
      })
    )
  );

  private apiRoot: any = null;

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslateService
  ) {
    this.store
      .pipe(getFilteredApiRoot)
      .subscribe(apiRoot => (this.apiRoot = apiRoot));
  }
}
