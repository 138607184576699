import { NgModule } from '@angular/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { TranslationModule } from '@libs/translation/translation.module';

import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { SharedModule } from '@libs/shared/shared.module';
import { CreateAgencyOfferPageComponent } from './create-agency-offer-page.component';
import { OfferBenefitComponent } from './components/offer-benefit/offer-benefit.component';
import { StoreModule } from '@ngrx/store';
import {
  CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY,
  createAgencyOfferFormReducer
} from './create-agency-offer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CreateAgencyOfferEffects } from './create-agency-offer.effects';
import { createAgencyOfferInitialFormState } from './create-agency-offer.model';
import {OfferManagementSharedModule} from "../../../shared/offer-management-shared.module";

@NgModule({
  imports: [
    SharedModule,
    CommonUiModule,
    NgrxFormsModule,
    StoreModule.forFeature(
      CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY,
      createAgencyOfferFormReducer,
      {
        initialState: createAgencyOfferInitialFormState
      }
    ),
    EffectsModule.forFeature([CreateAgencyOfferEffects]),
    TranslationModule,
    TooltipModule.forRoot(),
    OfferManagementSharedModule
  ],
  declarations: [CreateAgencyOfferPageComponent, OfferBenefitComponent],
  exports: [CreateAgencyOfferPageComponent]
})
export class CreateAgencyOfferPageModule {}
