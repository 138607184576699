<div class="message-container" (click)="toggleDate()">
    <div
        *ngIf="message"
        class="single-message"
        [ngClass]="{ own: message.isMine }"
    >
        <span
            class="message-date"
            [ngClass]="{ 'text-right': !message.isMine }"
        >
            {{ message.createdOn | messageDate }}
        </span>
        <div class="sender-picture">
            <img
                *ngIf="!hidePic"
                [src]="ownerPictureUrl"
                (error)="hidePic = true"
                (click)="avatarClickedHandler($event)"
            />
            <div
                *ngIf="hidePic"
                class="default-avatar"
                (click)="avatarClickedHandler($event)"
            >
                <i class="material-icons"> person </i>
            </div>
        </div>

        <div class="content-wrapper">
            <div
                class="message-actions"
                *ngIf="
                    message.isMine && !isMessageTooOld() && !isMessageDeleted()
                "
            >
                <i
                    class="material-icons delete-message"
                    [tooltip]="'SYSTEM.INFO.MESSAGE_DELETION' | translate"
                    (click)="deleteConversationMessage(message)"
                >
                    delete
                </i>
            </div>
            <span
                class="content"
                [ngClass]="{
                    own: message.isMine,
                    deleted: message.deletedOn !== null,
                    automatic: message.automatic
                }"
            >
                <ng-container *ngIf="isFileMessage(); else textMessage">
                    <a href="" (click)="downloadConversationFile()">
                        {{ message.filename }}
                    </a>
                </ng-container>
                <ng-template #textMessage>
                    <div [innerHtml]="message.text | urlDetector">
                    </div>
                    <div class="automatic-message-banner" *ngIf="message.automatic">
                        <span>This is an automated message</span>
                    </div>
                </ng-template>
            </span>
        </div>
    </div>
    <div
        class="message-date-mobile"
        [ngClass]="{ 'text-right': message.isMine, visible: mobileDateVisible }"
    >
        {{ message.createdOn | messageDate }}
    </div>
</div>
