import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';

@Component({
  selector: 'staffnow-collapsible-agency-offer-contracts-list',
  templateUrl: './collapsible-agency-offer-contracts-list.component.html',
  styleUrls: ['./collapsible-agency-offer-contracts-list.component.scss']
})
export class CollapsibleAgencyOfferContractsListComponent {
  @Input() heading = '';
  @Input() contracts: Array<AgencyOfferContractOutDto> = [];
}
