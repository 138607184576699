import { Component, OnInit } from '@angular/core';
import { AbstractOfferDetailsComponent } from '../abstract-offer-details.component';
import { ActivatedRoute } from '@angular/router';
import { ConversationService } from '../../../services/conversation.service';
import { LoadPackageOfferAndContractForTechnician } from '@libs/request-overview-common/state/requests-overview.actions';
import { RequestOverviewState } from '../../../state/request-overview-state.model';
import { PackageContract } from '@libs/shared/models/package-contract.model';

@Component({
  selector: 'staffnow-technician-package-offer-details',
  templateUrl: './technician-package-offer-details.component.html',
  styleUrls: ['./technician-package-offer-details.component.scss']
})
export class TechnicianPackageOfferDetailsComponent
  extends AbstractOfferDetailsComponent
  implements OnInit {
  public contract: PackageContract = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private conversationService: ConversationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadPackageOfferAndContractForTechnician({
            refNumber: params['offerRefNumber']
          })
        );
      })
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contract = (state.contracts.length > 0 ? state.contracts[0] : null) as any;
  }

  get canCreateConversationWithAgency(): boolean {
    return this.conversationService.canCreateConversationWithAgency(
      this.contract
    );
  }

  get canGetPreviousConversationWithAgency(): boolean {
    return this.conversationService.canGetPreviousConversationWithAgency(
      this.contract
    );
  }

  public initiateMessageWithAgency(): void {
    this.conversationService.initiateMessageWithAgencyForPackageContract(
      this.contract
    );
  }
}
