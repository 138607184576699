import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['../modal.styles.scss']
})
export class GenericModalComponent {
  @Input() onConfirmedCallback: Function = () => {};
  @Input() onCancelCallback: Function = () => {};
  @Input() messages: string[];
  @Input() canCancel: boolean = true;
  @Input() acceptText: string = 'yes';
  @Input() cancelText: string = 'cancel';
  @Input() icon: string = null;
  @Input() requiredUserConfirmation: boolean = false;
  @Input() title: string = null;

  public deleteConfirmationInput: string = '';

  constructor(public bsModalRef: BsModalRef) {}

  public agreedHandler(): void {
    this.onConfirmedCallback();
    this.bsModalRef.hide();
  }

  public cancelHandler(): void {
    this.onCancelCallback();
    this.bsModalRef.hide();
  }

  isAgreedHandlerDisabled (): boolean {
    if (!this.requiredUserConfirmation) {
      return false;
    }
    if (this.requiredUserConfirmation && this.deleteConfirmationInput == 'Delete') {
      return false;
    }
    return true;
  }

}
