import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';

@Component({
  selector: 'boolean-filter',
  templateUrl: './boolean-filter.component.html',
  styleUrls: ['./boolean-filter.component.scss']
})
export class BooleanFilterComponent implements OnChanges {
  @Input() filterConfig: FilterConfigModel = null;
  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public value: boolean = false;

  ngOnChanges() {
    this.value = this.filterConfig.initialValue;
  }

  handleChange() {
    this.onChange.emit(this.value);
  }
}
