import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {getBrandName, getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import { RequestNewPasswordService } from '@libs/shared/services/request-new-password.service';

declare var grecaptcha: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './request-change-password.component.html',
  styleUrls: ['./../password-management.scss']
})
export class RequestChangePasswordComponent implements OnInit {
  submitted = false;
  isWorking = false;
  subs: Subscription[] = [];
  googleRecaptchaSiteKey = '';

  form = new FormGroup({
    email: new FormControl(null, [
      Validators.email,
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ])
  });

  public brandName: string = '';

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.isWorking = true;
    this.form.disable();
    grecaptcha.ready((): void => {
      grecaptcha
        .execute(this.googleRecaptchaSiteKey, { action: 'submit' })
        .then((token: string): void => {
          this.requestNewPasswordService
            .requestNewPassword(token, this.form.controls['email'].value)
            .subscribe(
              () => (this.submitted = true),
              () => (this.isWorking = false)
            );
        });
    });
  }

  constructor(
    private requestNewPasswordService: RequestNewPasswordService,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.initEnvironment();
  }

  private initEnvironment(): void {
    this.subs.push(
      this.store.pipe(getBrandName).subscribe(brandName => (this.brandName = brandName.toLowerCase()))
    );
    this.subs.push(
      this.store.pipe(getStaticEnvironment).subscribe(env => {
        this.googleRecaptchaSiteKey = env.googleRecaptchaSiteKey;
      })
    );
  }
}
