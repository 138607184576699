<div class="list-of-items">
    <collapsible
        *ngFor="let item of bulletPoints; let index = index"
        [heading]="item.label"
        [isCollapsed]="index != 0"
        class="item"
    >
        <div class="body">
            <div
                [innerHTML]="sanitizedHtmlDescription(item.description)"
                class="ql-editor"
            ></div>
        </div>
    </collapsible>
</div>
