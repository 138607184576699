import { Pipe, PipeTransform } from '@angular/core';
import { UserName } from '../models/user-name';

@Pipe({
  name: 'nameFormatter'
})
export class NameFormatterPipe implements PipeTransform {
  transform(name: UserName): string {
    if (name) {
      return name.firstName + ' ' + name.lastName;
    }
    return '';
  }
}
