import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FEATURE_STATE_KEY,
  NotificationsStateModel
} from './notifications-state.model';
import { NotificationTargets } from '@libs/notifications/notification-targets.enum';

export interface NotificationsCount {
  bellNotificationsCount: number;
  mailboxNotificationsCount: number;
}

function getBadgeCountByType(badges: Array<any>, filter: string): number {
  return badges?.find(badge => badge.type === filter)?.count || 0;
}

export const selectFeatureFromAppState = (state: any) =>
  state[FEATURE_STATE_KEY] as NotificationsStateModel;

export const selectBadges = createSelector(
  selectFeatureFromAppState,
  state => state.badges
);

export const selectNotificationsCount = pipe(
  select(selectBadges),
  map((badges: Array<any>) => ({
    bellNotificationsCount: getBadgeCountByType(
      badges,
      NotificationTargets.NotificationBell
    ),
    mailboxNotificationsCount: getBadgeCountByType(
      badges,
      NotificationTargets.Mailbox
    )
  }))
);
