<div class="buttons">
    <i (click)="close()" class="material-icons close">close</i>
</div>
<div class="header">
    <h1 class="h1">Experience Confirmation Request</h1>
</div>
<div class="content">
    <form autocomplete="off" novalidate>
        <div class="form-group">
            <label for="recipient-name">Recipient name</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="recipientName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="recipient-name"
                    type="text"
                />
                <template
                    *ngIf="isMissing(recipientName)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="recipient-aviation-company">Recipient aviation company</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="recipientAviationCompany"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="recipient-aviation-company"
                    type="text"
                />
                <template
                    *ngIf="isMissing(recipientAviationCompany)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="recipient-email">Recipient email address</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="recipientEmail"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="recipient-email"
                    type="text"
                />
                <template
                    *ngIf="isMissing(recipientEmail)"
                    [ngTemplateOutlet]="missingField">
                </template>
                <div *ngIf="!emailFormatIsCorrect(recipientEmail)" class="validation-error">
                    The email address is not valid!
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="technician-name">Person for whom confirmation is requested</label>
            <div class="value-wrapper">
                <input
                    [value]="technicianName"
                    class="form-control"
                    disabled="disabled"
                    id="technician-name"
                    type="text"
                />
                <template
                    *ngIf="isMissing(technicianName)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="request-reason">Reason for request</label>
            <div class="value-wrapper">
                <input
                    [(ngModel)]="requestReason"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="request-reason"
                    type="text"
                />
                <template
                    *ngIf="isMissing(requestReason)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="period">Period for which confirmation is requested</label>
            <div id="period">
                <div class="value-wrapper">
                    <date-picker-single
                        (dateUpdated)="updateFromDate($event)"
                        [initialValue]="fromDate"
                        placeholder="From date"
                    ></date-picker-single>
                    <template
                        *ngIf="!fromDate"
                        [ngTemplateOutlet]="missingField">
                    </template>
                </div>
                <div class="value-wrapper">
                    <date-picker-single
                        (dateUpdated)="updateToDate($event)"
                        [initialValue]="toDate"
                        placeholder="To date"
                    ></date-picker-single>
                    <template
                        *ngIf="!toDate"
                        [ngTemplateOutlet]="missingField">
                    </template>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="additional-request">
                <span>Additional information requested</span>
                <span class="text-muted">
                    (please, describe any additional information that you request from the Aviation Company, i.e. tasks
                    performed, area of specialization, projects worked on, etc.)
                </span>
            </label>
            <div class="value-wrapper">
                <div class="textarea-wrapper">
                    <staffnow-textarea
                        [(ngModel)]="additionalRequest"
                        [ngModelOptions]="{ standalone: true }"
                        id="additional-request"
                        textAreaMaxLength="860"
                    ></staffnow-textarea>
                </div>
                <template
                    *ngIf="isMissing(additionalRequest)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
        <div class="form-group">
            <label for="requester-name-and-position">Requester name and position</label>
            <div class="value-wrapper">
                <input
                    [value]="requesterNameAndPosition"
                    class="form-control"
                    disabled="disabled"
                    id="requester-name-and-position"
                    type="text"
                />
                <template
                    *ngIf="isMissing(requesterName) || isMissing(requesterPosition)"
                    [ngTemplateOutlet]="missingField">
                </template>
            </div>
        </div>
    </form>
</div>
<div class="actions">
    <general-button (onClick)="close()" color="secondary" text="Cancel"></general-button>
    <general-button (onClick)="send()" [isDisabled]="isDisabled()" text="Confirm"></general-button>
</div>
<ng-template #missingField>
    <div class="validation-error">This field is required!</div>
</ng-template>
