import { Component, Input } from '@angular/core';

@Component({
  selector: 'fail-animation',
  templateUrl: './fail-animation.component.html',
  styleUrls: ['./fail-animation.component.scss']
})
export class FailAnimationComponent {
  @Input() public message: string = '';
}
