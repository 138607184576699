import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'staffnow-offer-info-section',
  templateUrl: './offer-info-section.component.html',
  styleUrls: ['./offer-info-section.component.scss']
})
export class OfferInfoSectionComponent {
  @Input() public icon: string = null;
  @Input() public title: string = null;
  @Input() public mode: 'danger' | 'warning' | 'notification' = 'notification';

  @HostBinding('class.danger') get isDangerMode(): boolean {
    return this.mode === 'danger';
  }

  @HostBinding('class.warning') get isWarningMode(): boolean {
    return this.mode === 'warning';
  }
}
