<div class="settings-container">
    <staffnow-news-letter-subscription
            [userProfile]="userProfile"
    ></staffnow-news-letter-subscription>
    <staffnow-weekly-jo-summary-subscription
        [userProfile]="userProfile"
        (toggleWeeklyJoSummarySubscription)="toggleWeeklyJoSummarySubscription($event)"
    >
    </staffnow-weekly-jo-summary-subscription>
    <div class="horizontal-divider"></div>
    <staffnow-notification-subscription
        [facilities]="blocklist?.availableFacilities"
        [userProfile]="userProfile"
    >
    </staffnow-notification-subscription>
    <div class="horizontal-divider"></div>
    <div class="blocklist-wrapper">
        <h1 class="h1">
            {{ 'Hide me from Aviation Companies' | translate }}
        </h1>
        <div class="guidelines">
            <p>
                You won't be visible for the selected Aviation Companies. This
                means that you are not going to appear in the public search when
                an Aviation Company is looking for technicians and, therefore,
                they cannot invite you to job openings.
            </p>
        </div>
        <div class="blocklist">
            <div class="select-option">
                <multiple-options-select
                    id="blocklist"
                    placeholder="Select from the list"
                    [searchable]="true"
                    [elements]="blocklist?.availableFacilities"
                    [selectedElements]="blockedEntities"
                    (onChange)="getBlocklist($event)"
                ></multiple-options-select>
            </div>
            <div class="action-button">
                <general-button
                        text="Submit"
                        [isLoading]="isLoading"
                        [isDisabled]="!valueChanged"
                        [customClass]="'btn-loader'"
                        (onClick)="submitBlockedEntities()"
                ></general-button>
            </div>
        </div>
    </div>
</div>
