import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {FileUploadService} from '@libs/common-ui/services/file-upload.service';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {Resource} from '@libs/shared/bms-common/rest/resource.model';
import {DeleteAgencyPicture, GetFacilityProfile} from '../facility-profile.actions';
import {getUrl, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {ToastMessageService} from '@libs/toast-messages/toast-message.service';
import {fileUploadConstants} from '@libs/shared/constants/fileupload.constants';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnChanges {
  @ViewChild('fileInput') public fileInput: any;

  @Input() public facilityProfile;
  @Input() public pictures: any[] = [];

  public uploader: FileUploader;
  public isLoading: boolean = false;
  private apiUrl: string;
  private fileSizeLimit: number = 3;
  private allowedFileTypesList = ['png', 'jpg', 'jpeg', 'pdf'];

  public carouselPictures: any[] = [];

  constructor(
    private store: Store<any>,
    private uploadService: FileUploadService,
    private modalService: ModalService,
    private toastMessageService: ToastMessageService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    if (
      hasLink(this.facilityProfile, 'uploadPicture') ||
      hasLink(this.facilityProfile, 'uploadPromotionalDocument')
    ) {
      this.apiUrl = getUrl(this.facilityProfile, 'uploadPicture')
      this.uploadService.configUploader(
        this.apiUrl,
        this.fileSizeLimit,
        this.allowedFileTypesList
      ).then(uploader => {
        this.uploader = uploader;
        this.uploader.onBeforeUploadItem = (fileItem: FileItem) => {
          this.isLoading = true;
          fileItem.url = this.getUrlAccordingToTypeFile(fileItem);
          fileItem.alias = this.fileToUploadIsPDF(fileItem._file.type)
            ? 'file'
            : 'picture';
        };

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
          const picInfo = {
            type: 'AGENCY_PROMOTIONAL_PICTURE',
            uuid: this.facilityProfile.uuid,
            mime: fileItem._file.type
          };

          form.append(
            this.fileToUploadIsPDF(fileItem._file.type) ? 'document' : 'data',
            new Blob([JSON.stringify(picInfo)], {type: 'application/json'})
          );
        };

        this.uploader.onSuccessItem = () => {
          this.store.dispatch(
            GetFacilityProfile({
              facilityUrl: getUrl(this.facilityProfile, ApiRootLinkRel.Self)
            })
          );
          this.isLoading = false;
          this.fileInput.nativeElement.value = '';
        };

        this.uploader.onErrorItem = (item: any, response: string) => {
          if (!!response && response.length > 0) {
            this.toastMessageService.fail(JSON.parse(response).message);
          }
          this.isLoading = false;
          this.uploader.clearQueue();
        };

        this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any) => {
          this.uploader.queue.find((file: FileItem, index: number) => {
            if (file === fileItem) {
              this.uploader.queue.splice(index, 1);
            }
          });

          switch (filter.name) {
            case 'fileSize':
              const fileSizeLimitBytes = this.fileSizeLimit * 1000000;
              const mime: string = fileItem.rawFile.type;
              if (mime.includes("image")) {
                const downsizeRate = fileSizeLimitBytes / fileItem.rawFile.size;
                this.uploadService.downsizeImage(
                  fileItem,
                  this.apiUrl,
                  downsizeRate,
                  {
                    type: 'AGENCY_PROMOTIONAL_PICTURE',
                    uuid: this.facilityProfile.uuid,
                    mime: fileItem._file.type
                  },
                  (response) => this.handleResizeResponse(response));
              } else {
                this.toastMessageService.fail(
                  this.translateService.instant(fileUploadConstants.fileExceededLimit)
                );
              }
              break;
            case 'mimeType':
              this.toastMessageService.fail(
                this.translateService.instant(fileUploadConstants.fileHasUnsupportedFormat)
              );
              break;
            case 'queueLimit':
              this.toastMessageService.fail(
                this.translateService.instant(fileUploadConstants.uploaderQueueLimitExceeded,
                  {limit: this.uploader.options.queueLimit})
              );
              break;
            default:
              break;
          }

          if (this.uploader.queue.length === 0) {
            this.fileInput.nativeElement.value = '';
          }
        };
        this.prepareCarouselPictures();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.pictures.currentValue &&
      changes.pictures.previousValue &&
      changes.pictures.currentValue.length !==
      changes.pictures.previousValue.length
    ) {
      this.prepareCarouselPictures();
    }
  }

  private prepareCarouselPictures() {
    this.carouselPictures = this.pictures.map(picture => {
      return {
        url: this.getPictureLink(picture),
        deleteUrl: this.getPictureDeleteLink(picture)
      };
    });
  }

  handlePictureDelete(deletePictureUrl: string, carouselPictureIndex: number) {
    this.modalService.openConfirmModal(
      'SYSTEM.INFO.DELETE_SLIDESHOW_PICTURE',
      () => {
        this.carouselPictures.splice(carouselPictureIndex, 1);

        this.store.dispatch(
          DeleteAgencyPicture({
            deletePictureUrl: deletePictureUrl,
            facilityUrl: getUrl(this.facilityProfile, ApiRootLinkRel.Self)
          })
        );
      }
    );
  }

  private getPictureLink(picture: Resource): string {
    return getUrl(picture, 'self');
  }

  private getPictureDeleteLink(picture: Resource): string {
    return getUrl(picture, 'deletePicture');
  }

  private getUrlAccordingToTypeFile(fileItem: any) {
    return this.fileToUploadIsPDF(fileItem._file.type)
      ? getUrl(this.facilityProfile, 'uploadPromotionalDocument')
      : getUrl(this.facilityProfile, 'uploadPicture');
  }

  private fileToUploadIsPDF(type: string) {
    return type === 'application/pdf';
  }

  private handleResizeResponse(response) {
    response.subscribe(() => {
      this.store.dispatch(
        GetFacilityProfile({
          facilityUrl: getUrl(this.facilityProfile, ApiRootLinkRel.Self)
        })
      );
      this.isLoading = false;
      this.fileInput.nativeElement.value = '';
      this.toastMessageService.success(
        this.translateService.instant(fileUploadConstants.successfullFileUpload)
      );
    }, response => {
      
      this.errorMessageService.handleErrorResponse(response, 'UPLOAD.IMAGE_FAILED');
    });
  }
}
