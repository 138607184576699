import { createSelector, Selector } from '@ngrx/store';
import {
  REQUESTS_OVERVIEW_COMMON_FEATURE_KEY,
  RequestsOverviewState
} from './requests-overview.reducer';
import { isEmpty, isNil, isString, pickBy } from 'lodash-es';
import { UserRolesUtils } from '@libs/shared/models/roles.enum';

export const selectFeature: Selector<any, RequestsOverviewState> = state =>
  state[REQUESTS_OVERVIEW_COMMON_FEATURE_KEY];

export const selectPage = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.page
);

export const selectTotalElements = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.totalElements
);

export const selectPageSize = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.pageSize
);

export const selectResultList = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.resultList
);

export const selectMroList = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.mroList
);

export const selectSelectedOfferContracts = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.selectedOfferContracts
);

export const selectActingAs = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.filters?.actingAs
);

export const selectOfferType = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.filters?.offerType
);

export const selectSelectedEntityUuid = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => state.filters?.selectedEntityUuid
);

export const selectFiltersForRequest = createSelector(
  selectFeature,
  (state: RequestsOverviewState) => {
    const result: any = pickBy(
      state.filters,
      filterValue =>
        !isNil(filterValue) && (!isString(filterValue) || !isEmpty(filterValue))
    );
    if (result.activeFilter) {
      result[result.activeFilter] = true;
      delete result.activeFilter;
    }
    if (result.actingAs && result.selectedEntityUuid) {
      result.admin = createAdminParam(
        result.selectedEntityUuid,
        result.actingAs
      );
    }
    delete result.actingAs;
    delete result.selectedEntityUuid;
    return result;
  }
);

function createAdminParam(entityUuid, role) {
  if (UserRolesUtils.isMro(role)) {
    return `mro:${entityUuid}:${role}`;
  } else if (UserRolesUtils.isAgency(role)) {
    return `agency:${entityUuid}`;
  } else {
    return `technician:${entityUuid}`;
  }
}
