import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TechnicianProfileModel} from '@libs/shared/models/technician-profile.model';
import {getEmbeddedResource, getUrl, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';
import {CustomNavigationService} from '@libs/shared/services/custom-navigation.service';

@Component({
  selector: 'staffnow-technician-profile-modal',
  templateUrl: './technician-profile-modal.component.html',
  styleUrls: ['./technician-profile-modal.component.scss']
})
export class TechnicianProfileModalComponent implements OnInit {

  @Input() public isBackoffice: boolean = false;
  @Input() public technician: TechnicianProfileModel = null;
  @Input() public dataProtectionBanner: string = null;
  @Input() public showSendOfferButton = false;
  @Input() public sendOfferCallback: Function = null;

  public technicianProfile: any = null;
  public technicianPicture: string = null;

  constructor(
    public bsModalRef: BsModalRef,
    private customNavigationService: CustomNavigationService
  ) {
  }

  ngOnInit() {
    if (this.technician) {
      this.technicianProfile = getEmbeddedResource(
        this.technician,
        UserProfileLinkRel.Profile
      );
      if (hasLink(this.technician, UserProfileLinkRel.GetProfilePicture)) {
        this.technicianPicture = getUrl(
          this.technician,
          UserProfileLinkRel.GetProfilePicture
        );
      }
    }
  }

  onSend() {
    this.sendOfferCallback(this.technician);
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }

  public getTechnicianSkills(): string[] {
    return this.technician?.skills.map(skill => skill.name);
  }

  public viewFullProfile(): void {
    if (this.isBackoffice) {
      this.customNavigationService.openNewTab([
        'technicians',
        'profile',
        this.technicianProfile.userUuid
      ]);
    }
  }
}
