import { ToastTypes } from '../lib/toast-types.enum';

export const LIB_FEATURE_KEY = 'toast-messages';

export interface ToastMessage {
  text: string;
  type: ToastTypes;
  id: string;
  dismissTimeout?: number | null;
}

export interface LibStateModel {
  queue: Array<ToastMessage>;
}

export interface AppPartialState {
  [LIB_FEATURE_KEY]: LibStateModel;
}

export const initialState: LibStateModel = {
  queue: []
};
