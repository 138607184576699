<i class="material-icons close pointer" (click)="close()">close</i>
<div class="add-entities-modal-wrapper">
    <h2>
        {{ placeholder }}
    </h2>
    <multiple-options-select
        bindLabel="fullName"
        [searchable]="true"
        [placeholder]="placeholder"
        [elements]="entityList"
        [selectedElements]="selectedEntities"
        (onChange)="handleChange($event)"
    ></multiple-options-select>
    <general-button
        (onClick)="onAdd()"
        [isDisabled]="!isFormValid()"
        class="mx-auto"
        text="Add"
    ></general-button>
</div>
