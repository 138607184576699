<div class="skills-group">
    <h1 class="h1">{{ 'OWN_TECHNICIANS.SKILLS.TITLE' | translate }}</h1>
    <div class="select-option">
        <div id="select-skills-yes-no">
            {{ 'OWN_TECHNICIANS.SKILLS.DO_YOU_HAVE_SKILL_EXPERTISE' | translate }}
        </div>
        <div id="select-skills-yes">
            <input
                (click)="enableSkillsSelection(true)"
                [checked]="technicianHasAnySkill() || !technicianHasFilledSkillMenu()"
                type="radio"
                name="yes_no"
                value="yes"
            />
            {{ 'OWN_TECHNICIANS.SKILLS.YES' | translate }}
        </div>
        <div id="select-skills-no">
            <input
                (click)="enableSkillsSelection(false)"
                [checked]="!technicianHasAnySkill() && technicianHasFilledSkillMenu()"
                type="radio"
                name="yes_no"
                value="no"
            />
            {{ 'OWN_TECHNICIANS.SKILLS.NO' | translate }}
        </div>
    </div>
    <div class="skills-container">
        <div
            *ngFor="let skill of listOfSkills"
            class="skill custom-control custom-checkbox"
            [ngClass]="{ enabled: isEnabled }"
        >
            <input
                type="checkbox"
                class="custom-control-input"
                id="{{ 'skill-' + skill.id }}"
                [disabled]="!isEnabled"
                [(ngModel)]="skill.isSelected"
            />
            <label for="{{ 'skill-' + skill.id }}" class="custom-control-label font-weight-bold">
                {{ skill.name }}
            </label>
        </div>
    </div>
</div>
<div class="other-skills">
    <h1 class="h1">Other</h1>
    <textarea
        id="other-skills-textarea"
        maxlength="60"
        style="resize: none"
        [(ngModel)]="otherSkills"
        [disabled]="!isEnabled"
        [placeholder]="isEnabled ? 'Please, specify' : ''"
    ></textarea>
</div>
<div *ngIf="canUpdateTechnicianSkills" class="save-button-container" id="save-button-container">
    <general-button
        text="Update Skills"
        [isLoading]="isLoading"
        [isDisabled]="!shouldEnableUpdateButton()"
        (onClick)="updateTechnicianSkills()"
    ></general-button>
</div>
