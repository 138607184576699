import {validate, ValidationErrors} from "ngrx-forms";
import {maxLength, minLength} from "ngrx-forms/validation";
import {CUSTOM_REFERENCE_NUMBER_MAX_LENGTH, CUSTOM_REFERENCE_NUMBER_MIN_LENGTH} from "@libs/shared/models/offer.model";

function customReferenceFormat(value: string): ValidationErrors {
  const allowedCharacters: RegExp = /^[A-Z0-9_-]+$/;
  const atLeastOneLetterOrNumber: RegExp = /^.*[A-Z0-9]+.*$/;
  if (allowedCharacters.test(value) && atLeastOneLetterOrNumber.test(value)) {
    return {};
  } else {
    const customReferenceFormat = {};
    if (!allowedCharacters.test(value)) {
      customReferenceFormat['allowedCharacters'] = {toMatch: allowedCharacters, actualValue: value};
    }
    if (!atLeastOneLetterOrNumber.test(value)) {
      customReferenceFormat['atLeastOneLetterOrNumber'] = {toMatch: atLeastOneLetterOrNumber, actualValue: value};
    }
    return {customReferenceFormat};
  }
}

export function getCustomReferenceValidator() {
  return validate<string>(
    customReferenceFormat,
    minLength(CUSTOM_REFERENCE_NUMBER_MIN_LENGTH),
    maxLength(CUSTOM_REFERENCE_NUMBER_MAX_LENGTH)
  );
}
