<h1 class="h1">Set temporary password</h1>
<div class="form-group">
    <label for="password">{{ 'Password' | translate }}</label>
    <input
        [(ngModel)]="userPassword"
        class="form-control"
        id="password"
        placeholder="Write the new password here"
        type="text"
    />
</div>
<div>
    <general-button
        (onClick)="handlePasswordChange()"
        [isDisabled]="isLoading || !userPassword"
        [isLoading]="isLoading"
        text="Change password"
    ></general-button>
</div>
