<div class="input-wrapper">
    <input
        ng2FileSelect
        #fileInput
        hidden
        type="file"
        name="picture"
        [accept]="inputAccepts"
        [multiple]="false"
        [uploader]="uploader"
    />
    <general-button
        [tooltip]="'SYSTEM.INFO.FILE_RESTRICTION_TOOLTIP' | translate: {fileTypes: inputAccepts, fileSizeLimit: fileSizeLimit }"
        [text]="(buttonLabel | translate) + ' ' + buttonText"
        [isLoading]="isLoading"
        [isDisabled]="isDisabled || isLoading"
        (onClick)="handleClick(fileInput)"
        backgroundStyle="outline"
    ></general-button>
</div>
