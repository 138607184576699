import { createAction, props } from '@ngrx/store';

export const OpenSockQueue = createAction(
  '[WebSocket] Open sock queue',
  props<{
    webSocketSecret: string;
    socketUrl: string;
    queue: string;
    onMessageReceived: (messagePayload) => any;
  }>()
);

export const OpenSockQueueSucceeded = createAction(
  '[WebSocket] Open sock queue succeeded',
  props<{
    queue: string;
    onSockClose: () => any;
  }>()
);

export const OpenSockQueueFailed = createAction(
  '[WebSocket] Open sock queue failed'
);

export const SockQueueClosed = createAction(
  '[WebSocket] Sock queue closed',
  props<{ queue: string }>()
);
