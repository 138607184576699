<div class="agency-wrapper" [ngClass]="{ selected: isSelected }">
    <div
        class="agency card-with-bottom-button"
        [ngClass]="{ opened: isOpened, disabled: isDisabled }"
    >
        <div class="content" (click)="handleAgencyClick()">
            <span class="material-icons-outlined circle checked"> done </span>
            <span class="material-icons-outlined circle unchecked">
                circle
            </span>
            <facility-logo [resource]="agency"></facility-logo>
            <div class="name">{{ agency.name }}</div>
        </div>
        <button class="btn btn-primary" (click)="handleOpenDetails()">
            {{ 'Details' | translate }}
        </button>
    </div>
    <div class="speech-bubble-wrapper" [ngClass]="{ opened: isOpened }">
        <div class="speech-bubble card-with-bottom-button">
            <div class="data">
                <span class="name">{{ agency.name }}</span>
                <div
                    *ngIf="hasServicesDescription()"
                    [innerHTML]="agency.services"
                ></div>
                <div *ngIf="!canDownloadPromotionalMaterial()">
                    {{
                        'This agency has not uploaded any promotional material yet.'
                            | translate
                    }}
                </div>
            </div>
            <div class="carousel" *ngIf="hasPictures()">
                <carousel [interval]="0">
                    <slide *ngFor="let pictureUrl of getPicturesUrls()">
                        <div class="picture">
                            <img
                                [src]="pictureUrl"
                                alt="Promotional material"
                            />
                        </div>
                    </slide>
                </carousel>
            </div>
            <general-button
                text="Download promotional info"
                customClass="btn-loader"
                icon="cloud_download"
                [isLoading]="isDownloading"
                [isDisabled]="
                    isDownloading || !canDownloadPromotionalMaterial()
                "
                (onClick)="downLoadDocuments()"
            ></general-button>
        </div>
        <div class="space"></div>
    </div>
</div>
