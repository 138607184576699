export enum NotificationType {
  MATCH = 'MATCH',
  MESSAGE = 'MESSAGE',
  MRO_CREATED = 'MRO_CREATED',
  STATUS_CHANGE = 'STATUS_CHANGE',
  OFFER_CREATED = 'OFFER_CREATED',
  CONTRACT_CHANGE = 'CONTRACT_CHANGE'
}

export interface NotificationPayload {
  text: string;
  extendedLinkTo?: string;
}

export interface Notification {
  isSeen: boolean;
  createdOn: string;
  type: NotificationType;
  payload: NotificationPayload;
}
