export interface RegistrationComponentState {
  registerStatus: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS' | 'LOGIN';
  errorMessage: string;
  registerEntity: 'TECHNICIAN' | null;
}

export const initialState: RegistrationComponentState = {
  registerStatus: 'LOGIN',
  errorMessage: '',
  registerEntity: null
};
