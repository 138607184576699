import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from "@libs/auth/services/authentication.service";
import {AppRegionEnum} from "@libs/shared/bms-common/environment/environment.model";
import {dateTimeFromString, dateToUTCString} from "@libs/shared/helpers/date-utils";

@Component({
  selector: 'date-picker-input-writable',
  templateUrl: './date-picker-input-writable.component.html',
  styleUrls: ['./date-picker-input-writable.component.scss']
})
export class DatePickerInputWritableComponent {
  @Input() set initialValue(date: string) {
    this._initialValue = dateTimeFromString(date).toJSDate();
  }

  @Input() public placeholder: string = '';
  @Output() public dateUpdated = new EventEmitter<string>();
  public dateFormat: string = 'MM/DD/YYYY';
  public _initialValue: Date = null;

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.hasUsaRegionOnly().then(usaOnly => {
      this.dateFormat = usaOnly ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
    });
  }

  public emitChange(date: Date): void {
    this._initialValue = date;
    this.dateUpdated.emit(dateToUTCString(date));
  }

  private async hasUsaRegionOnly(): Promise<boolean> {
    const {regions} = await this.authenticationService.getIdentityClaims();
    return regions.length == 1 && regions[0] == AppRegionEnum.USA;
  }
}
