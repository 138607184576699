import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TranslationModule } from '@libs/translation/translation.module';
import { SharedModule as SharedLibModule } from '@libs/shared/shared.module';

import { NotificationService } from './notification.service';
import {
  FEATURE_STATE_KEY,
  initialState,
  NotificationsStateModel
} from './state/notifications-state.model';
import {
  getReducers,
  reducersInjectionToken
} from './state/notifications.reducer';
import { NotificationsEffects } from './state/notifications.effects';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { NotificationComponent } from '@libs/notifications/components/notification/notification.component';

export function getInitialState(): Partial<NotificationsStateModel> {
  return {
    ...initialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule,
    HttpClientModule,
    TranslationModule,
    SharedLibModule,
    StoreModule.forFeature(FEATURE_STATE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    EffectsModule.forFeature([NotificationsEffects])
  ],
  providers: [
    NotificationService,
    { provide: reducersInjectionToken, useFactory: getReducers }
  ],
  declarations: [NotificationListComponent, NotificationComponent],
  exports: [NotificationListComponent]
})
export class NotificationsModule {}
