import { Component } from '@angular/core';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';

@Component({
  selector: 'staffnow-role-redirect',
  template: `<div></div>`
})
export class RoleRedirectComponent {
  constructor(private customNavigationService: CustomNavigationService) {
    this.customNavigationService.goToDefaultView();
  }
}
