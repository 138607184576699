import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebSocketService } from './websocket.service';
import { StoreModule } from '@ngrx/store';
import {
  FEATURE_STATE_KEY,
  initialState,
  WebSocketStateModel
} from './state/websocket-state.model';
import { getReducers, reducersInjectionToken } from './state/websocket.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WebSocketEffects } from './state/websocket.effects';

export function getInitialState(): Partial<WebSocketStateModel> {
  return {
    ...initialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_STATE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    EffectsModule.forFeature([WebSocketEffects])
  ],
  providers: [
    WebSocketService,
    { provide: reducersInjectionToken, useFactory: getReducers }
  ]
})
export class WebsocketModule {}
