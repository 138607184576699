import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  OpenSockQueue,
  OpenSockQueueFailed,
  OpenSockQueueSucceeded
} from './websocket.actions';
import { Action } from '@ngrx/store';

declare var SockJS;
declare var Stomp;

@Injectable()
export class WebSocketEffects {
  public openSockQueue$ = createEffect(() =>
    this.actions.pipe(
      ofType(OpenSockQueue),
      switchMap(action => {
        return new Observable<Action>(observe => {
          const onConnected = frame => {
            stompClient.subscribe(action.queue, action.onMessageReceived);
            observe.next(
              OpenSockQueueSucceeded({
                queue: action.queue,
                onSockClose: () => {
                  stompClient.disconnect();
                  sock.close();
                }
              })
            );
            observe.complete();
          };

          const onError = (e: any) => {
            observe.next(OpenSockQueueFailed());
          };

          const sock = new SockJS(action.socketUrl);

          const stompClient = Stomp.over(sock);
          stompClient.debug = null;
          stompClient.connect(
            {
              authorization: `${action.webSocketSecret}`
            },
            onConnected,
            onError
          );
        });
      })
    )
  );

  constructor(private actions: Actions) {}
}
