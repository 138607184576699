import { AccountFormModel } from '../components/views/account/account-form.model';

abstract class RequestPayload {
  public readonly position: string = '';
  public readonly presentation: string = '';
  public readonly firstName: string = '';
  public readonly lastName: string = '';
  public readonly defaultUser: boolean = null;
  public readonly role: string = '';

  protected constructor(formValue: AccountFormModel) {
    this.position = formValue.position;
    this.presentation = formValue.presentation;
    this.firstName = formValue.firstName;
    this.lastName = formValue.lastName;
    this.defaultUser = formValue.defaultUser;
    this.role = formValue.role;
  }
}

export class AdminRequestPayload extends RequestPayload {
  constructor(formValue: AccountFormModel) {
    super(formValue);
  }
}

export class MroRequestPayload extends RequestPayload {
  public readonly onlyDefaultSet: boolean = false;

  constructor(formValue: AccountFormModel) {
    super(formValue);
    this.onlyDefaultSet = formValue.onlyDefaultSet;
  }
}

export class AgencyRequestPayload extends RequestPayload {
  public readonly onlyDefaultSet: boolean = false;

  constructor(formValue: AccountFormModel) {
    super(formValue);
    this.onlyDefaultSet = formValue.onlyDefaultSet;
  }
}
