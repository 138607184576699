import { createAction, props } from '@ngrx/store';

export const SuccessfullyLoadedAgencies = createAction(
  '[Agency List Modal] agencies list loaded',
  props<{ agenciesList: Array<any> }>()
);
export const GetAgenciesList = createAction(
  '[Agency List Modal] Get list of agencies',
  props<{ getAgenciesUrl: string; contract: any }>()
);
export const FailedToLoadAgencies = createAction(
  '[Agency List Modal] Failed to load agencies list'
);
