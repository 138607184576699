<div class="conversation-container">
    <div
        class="user-avatar"
        [ngStyle]="getAvatarStyle()"
        (click)="
            isAdminUser()
                ? navigateToProfile($event, conversation?.partnerUserUuid)
                : handleLoadConversation()
        "
    >
        <i class="material-icons" *ngIf="!getAvatarStyle()"> person </i>
    </div>
    <div class="user-info">
        <p class="user-name">
            {{ conversation.firstName || 'Unnamed' }}
            {{ conversation.lastName || 'User' }}
        </p>
        <p class="title">{{ conversation.title || '' }}</p>
    </div>

    <div class="unseen-indicator">
        <staffnow-counter-badge
            *ngIf="conversation.hasUnseenMessages"
            size="big"
            color="green"
            [amount]="conversation.amountOfUnseenMessages"
        ></staffnow-counter-badge>
    </div>
</div>
<div class="last-message-timestamp text-muted">
    {{ conversation.lastMessageReceivedOn | messageDate }}
</div>
