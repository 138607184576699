import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-text-area-dialog',
  templateUrl: './text-area-dialog.component.html',
  styleUrls: ['./text-area-dialog.component.scss']
})
export class TextAreaDialogComponent {
  @Input() public onAcceptCallback: Function;
  @Input() public title: string = null;
  @Input() public body: string = null;
  @Input() public disclaimer: string = null;

  public text: string = null;

  constructor(public bsModalRef: BsModalRef) {}

  public submit() {
    this.onAcceptCallback(this.text);
    this.bsModalRef.hide();
  }
}
