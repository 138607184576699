<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1>Oops!</h1>
            <h2>
                {{ "404 - The Page can't be found" | translate }}
            </h2>
        </div>
        <button class="btn btn-outline-primary btn-lg" routerLink="/">
            {{ 'GO BACK TO HOME' | translate }}
        </button>
    </div>
</div>
