<div class="help-wrapper">
    <tab-navigation
        *ngIf="isPlatformWeb"
        [menuItems]="menuItems"
        [(ngModel)]="activeTab"
        (ngModelChange)="handleTabChange()"
    ></tab-navigation>

    <div class="view-container">
        <div [ngSwitch]="activeTab.value">
            <div *ngSwitchCase="'faq'">
                <h1 class="h1 text-center mb-4 font-weight-bold">
                    {{ 'GENERAL.FREQUENTLY_ASKED_QUESTIONS' | translate }}
                </h1>
                <staffnow-bullet-points [bulletPoints]="bulletPoints"></staffnow-bullet-points>
            </div>
            <div *ngSwitchCase="'contact'" class="contact">
                <h1 class="h1 text-center mb-4 font-weight-bold">
                    {{ 'HELP.CONTACT.LABEL' | translate }}
                </h1>
                <staffnow-contacts></staffnow-contacts>
            </div>
            <div *ngSwitchCase="'titles-requirements'">
                <h1 class="h1 text-center mb-4 font-weight-bold">
                    {{ 'HELP.TITLE_REQUIREMENTS_ITEM_MENU.LABEL' | translate }}
                </h1>
                <staffnow-bullet-points [bulletPoints]="bulletPoints"></staffnow-bullet-points>
            </div>
        </div>
    </div>
</div>
