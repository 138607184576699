<ng-container *ngTemplateOutlet="avatarUpload"></ng-container>

<ng-template #avatarUpload>
    <input
        ng2FileSelect
        #fileInput
        hidden
        type="file"
        name="picture"
        [accept]="inputAccepts"
        [multiple]="false"
        [uploader]="uploader"
    />
    <div
        *ngIf="!loading"
        class="avatar"
        [class.user-avatar]="!imageUrl"
        [tooltip]="canEdit && isPlatformWeb ? (fileRestrictionsTooltip | translate) : ''"
        [triggers]="isPlatformWeb ? 'ismouseenter:mouseout' : ''"
        placement="bottom"
        (click)="canEdit ? fileInput.click() : null"
    >
        <img *ngIf="imageUrl" class="user-image" [src]="imageUrl" />
        <i class="material-icons"> person </i>
        <staffnow-favorite-button
            *ngIf="canEditFavoriteTechniciansList"
            [isFavorite]="userProfile?.isFavoriteTechnician"
            (updateFavorites)="handleUpdateMroFavorites($event)"
        ></staffnow-favorite-button>
    </div>
    <div class="avatar loader-container" *ngIf="loading">
        <staff-loader [customClass]="'avatar-loader'"></staff-loader>
    </div>
</ng-template>
