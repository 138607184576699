import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {PasswordValidators} from "./password-validators";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {getBrandName, getStaticEnvironment} from "@libs/shared/bms-common/environment/environment.selector";
import {ActivatedRoute} from "@angular/router";
import { ResetPasswordService } from '@libs/shared/services/reset-password.service';
import {EnvironmentState} from "@libs/shared/bms-common/environment/environment.model";

declare var grecaptcha: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./../password-management.scss']
})
export class ChangePasswordComponent {
  submitted = false;
  isWorking = false;
  subs: Subscription[] = [];
  googleRecaptchaSiteKey = '';
  uuid: string;
  success = false;
  customerSupportEmail: string = '';

  form = new FormGroup(
    {
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
            requiresDigit: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
            requiresUppercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
            requiresLowercase: true
          })
        ])
      ),
      confirmPassword: new FormControl(null,[
        Validators.required
      ])
    },
    {
      validators: PasswordValidators.MatchValidator
    }
  );

  public brandName: string = '';

  get f() {
    return this.form.controls;
  }

  get passwordValid() {
    return this.form.controls["password"].errors === null;
  }

  get requiredValid() {
    return !this.form.controls["password"].hasError("required");
  }

  get minLengthValid() {
    return !this.form.controls["password"].hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.form.controls["password"].hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.form.controls["password"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.form.controls["password"].hasError("requiresLowercase");
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.isWorking = true;
    this.form.disable();
    grecaptcha.ready((): void => {
      grecaptcha
        .execute(this.googleRecaptchaSiteKey, { action: 'submit' })
        .then((token: string): void => {
          this.resetPasswordService.resetPassword(token, this.form.controls['password'].value, this.uuid)
            .subscribe(() => {
                this.submitted = true;
                this.success = true;
              }, () => {
                this.submitted = true;
                this.form.enable();
              });
        });
      }
    );
  }

  constructor(
    private resetPasswordService: ResetPasswordService,
    private store: Store<any>,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.uuid = params['key'];
    });
  }

  ngOnInit(): void {
    this.initEnvironment();
  }

  private initEnvironment(): void {
    this.subs.push(
        this.store.pipe(getBrandName).subscribe(brandName => (this.brandName = brandName.toLowerCase()))
    );
    this.subs.push(
      this.store.pipe(getStaticEnvironment).subscribe((env: EnvironmentState) => {
        this.googleRecaptchaSiteKey = env.googleRecaptchaSiteKey;
        this.customerSupportEmail = env.brandConfig.customerSupportEmail;
      })
    );
  }
}
