import {Component} from '@angular/core';

import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {VerifyEmailService} from '../../service/verify-email.service';
import {getBrandName, getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';

declare var grecaptcha: any;

@Component({
  selector: 'app-resend-verification-email',
  templateUrl: './resend-verification-email.component.html',
  styleUrls: ['./../../../login-common.component.scss']
})
export class ResendVerificationEmailComponent {
  subs: Subscription[] = [];
  email: string;
  success: boolean = false;
  sent: boolean = false;
  googleRecaptchaSiteKey = '';
  public brandName: string = '';

  constructor(
    private verifyEmailService: VerifyEmailService,
    private store: Store<any>,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.email = decodeURIComponent(params['email']);
    });
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.pipe(getBrandName).subscribe(brandName => (this.brandName = brandName.toLowerCase()))
    );
    this.subs.push(
      this.store.pipe(getStaticEnvironment).subscribe(env => {
        this.googleRecaptchaSiteKey = env.googleRecaptchaSiteKey;
        setTimeout(() => this.initEnvironment(), 500);
      })
    );
  }

  private initEnvironment(): void {
    grecaptcha.ready((): void => {
      grecaptcha
        .execute(this.googleRecaptchaSiteKey, 0)
        .then((token: string): void => {
          this.verifyEmailService
            .resendVerificationEmail(token, this.email)
            .subscribe( {
              next: () => {
                this.sent = true;
                this.success = true;
              },
              error: () => {
                  this.sent = true;
                  this.success = false;
                }
            });
        });
    });
  }
}
