import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { TechnicianSelfIdentificationDto } from '@libs/shared/models/technician-profile.model';
import { Store } from '@ngrx/store';
import {
  FinishedUpdatingTechnicianSelfIdentification,
  UpdateTechnicianSelfIdentification,
} from '@libs/user-profile/state/user-profile.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';

@UntilDestroy()
@Component({
  selector: 'staffnow-technician-self-id',
  templateUrl: './technician-self-id.component.html',
  styleUrls: ['./technician-self-id.component.scss']
})
export class TechnicianSelfIdentificationComponent implements OnChanges {
  @Input() technicianSelfIdentification: TechnicianSelfIdentificationDto;
  @Input() technicianSelfIdentificationUpdateUrl: string;

  public isTouched = false;
  public modalRef: BsModalRef;
  public isLoading: boolean = false;
  public technicianSelfIdentificationInput: TechnicianSelfIdentificationDto;

  constructor(private store: Store<any>, private modalService: BsModalService, private actions: Actions) {
    this.actions
      .pipe(ofType(FinishedUpdatingTechnicianSelfIdentification), untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.isTouched = false;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.technicianSelfIdentification) {
      this.technicianSelfIdentificationInput = { ...this.technicianSelfIdentification };
    }
  }

  public isGenderSelected(gender: string): boolean {
    return this.technicianSelfIdentificationInput.gender === gender;
  }

  public setGender(value: string) {
    this.technicianSelfIdentificationInput.gender = value;
    this.isTouched = true;
  }

  public isEthnicitySelected(ethnicity: string): boolean {
    return this.technicianSelfIdentificationInput.ethnicity === ethnicity;
  }

  public setEthnicity(value: string) {
    this.technicianSelfIdentificationInput.ethnicity = value;
    this.isTouched = true;
  }

  public isDisabilitySelected(disability: string): boolean {
    return this.technicianSelfIdentificationInput.disability === disability;
  }

  public setDisability(value: string) {
    this.technicianSelfIdentificationInput.disability = value;
    this.isTouched = true;
  }

  public isProtectedVeteranStatusSelected(protectedVeteranStatus: string): boolean {
    return this.technicianSelfIdentificationInput.protectedVeteranStatus === protectedVeteranStatus;
  }

  public setProtectedVeteranStatus(value: string) {
    this.technicianSelfIdentificationInput.protectedVeteranStatus = value;
    this.isTouched = true;
  }

  get canUpdateTechnicianSelfIdentification(): boolean {
    return !!this.technicianSelfIdentificationUpdateUrl;
  }

  get canSubmit(): boolean {
    return !this.isLoading && this.isTouched && this.canUpdateTechnicianSelfIdentification &&
      !!this.technicianSelfIdentificationInput.gender &&
      !!this.technicianSelfIdentificationInput.ethnicity &&
      !!this.technicianSelfIdentificationInput.disability &&
      !!this.technicianSelfIdentificationInput.protectedVeteranStatus;
  }

  public udateTechnicianSelfIdentification(): void {
    this.isLoading = true;
    this.store.dispatch(
      UpdateTechnicianSelfIdentification({
        technicianSelfIdentification: { ... this.technicianSelfIdentificationInput },
        url: this.technicianSelfIdentificationUpdateUrl
      })
    );
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'app-modal'
    });
  }
}
