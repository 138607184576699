<div
    [ngClass]="{ 'info-section-calendar': infoSection }"
    class="calendar-container"
>
    <h4 class="h4 text-center">{{ MONTH_NAME }} {{ YEAR }}</h4>
    <table>
        <thead>
        <th> Mon</th>
        <th> Tue</th>
        <th> Wed</th>
        <th> Thu</th>
        <th> Fri</th>
        <th> Sat</th>
        <th> Sun</th>
        </thead>
        <tbody>
        <tr *ngFor="let week of MONTH">
            <td
                *ngFor="let day of week"
                [ngClass]="{
                    'period-part-match': day.isPartOfPeriod,
                    'start-end-date-match': day.isStartOrEndDate
                }"
            >
                {{ day.date ? (day.date | date: 'd') : '&nbsp;' }}
            </td>
        </tr>
        </tbody>
    </table>
</div>
