<div class="reg-form-container">
    <form>
        <div class="inputs">
            <div>
                <label for="email" class="control-label font-weight-bold">Email</label>
                <input
                    class="form-control"
                    type="email"
                    name="email"
                    id="email"
                    [placeholder]="'GENERAL.ENTER.EMAIL' | translate"
                    autocomplete="off webauthn"
                    [(ngModel)]="email"
                />
            </div>

            <div>
                <label for="password" class="control-label font-weight-bold">Password</label>
                <input
                    class="form-control"
                    type="password"
                    id="password"
                    name="password"
                    [placeholder]="'GENERAL.ENTER.PASSWORD' | translate"
                    autocomplete="off webauthn"
                    [(ngModel)]="password"
                />
            </div>
            <p class="validation-error" *ngIf="wrongCredentials">Email or password incorrect.</p>
            <p class="validation-error" *ngIf="unverifiedEmail">
                Your account is not fully verified. Click
                <a class="a" (click)="resendVerificationEmail()"> here </a>
                to resend the activation link to your email inbox in case you haven't received it.
            </p>
        </div>

        <div class="reset-password-container">
            <p [innerHTML]="'GENERAL.LOGIN.RESET_PASSWORD_1' | translate"></p>
            <p>
                <a [routerLink]="['/request-new-password']" href="#">{{ 'GENERAL.LOGIN.RESET_PASSWORD_2_LINK' | translate }}</a>
                <span> {{'GENERAL.LOGIN.RESET_PASSWORD_2' | translate}} </span>
            </p>
        </div>

        <div class="buttons">
            <general-button
                (onClick)="login()"
                [isDisabled]="!canLogin || loading"
                class="reg-btn"
                size="large"
                color="alternative"
                text="Log In"
                type="submit"
            ></general-button>
        </div>
    </form>
</div>

<staff-loader *ngIf="loading" customClass="splash-screen-loader"></staff-loader>
