export enum RequestOverviewLinkRel {
  ApproveOffer = 'approve',
  GetTechnician = 'getTechnician',
  GetMRO = 'getMro',
  GetAgency = 'getAgency',
  CreateConversationAgency = 'contactAgency',
  CreateConversationTechnician = 'contactTechnician',
  GetConversationAgency = 'getAgencyConversation',
  GetConversationTechnician = 'getTechnicianConversation',
  Self = 'self',
  CreateConversationMRO = 'contactMro',
  GetConversationMRO = 'getMroConversation',
  AddStaffies = 'addStaffies',
  AddTechnicians = 'addTechnicians',
  RemoveTechnician = 'removeTechnician',
  GetAgenciesToInvite = 'getAgenciesToInvite',
  InviteAgencies = 'inviteAgencies',
  GetContracts = 'getContracts',
  Delete = 'delete',
  Edit = 'edit',
  CloseOffer = 'close',
  ReopenOffer = 'reopen',
  AcceptDateExtension = 'acceptExtension',
  DeclineDateExtension = 'declineExtension',
  GetOwnTechnicians = 'getOwnTechnicians',
  Workers = 'workers',
  ProposePrice = 'proposePrice',
  AcceptPrice = 'acceptPrice',
  EndContract = 'endContract',
  AcceptContract = 'acceptContract',
  DeclineContract = 'declineContract',
  NotifyContractEnd = 'notifyContractEnd',
  MarkAsDocumentsNeeded = 'markAsDocumentsNeeded',
  ChangeExperienceLetter = 'changeExperienceLetter',
  ProposeContractStartDate = 'proposeContractStart',
  AcceptContractStartDate = 'acceptContractStart',
  EditAgencyOffer = 'editAgencyOffer',
  DeleteAgencyOffer = 'deleteAgencyOffer',
  CloseAgencyOffer = 'closeAgencyOffer',
  ReopenAgencyOffer = 'reopenAgencyOffer',
  AcceptAgencyOfferContract = 'acceptAgencyOfferContract',
  DeclineAgencyOfferContract = 'declineAgencyOfferContract',
  MarkAgencyOfferContractAsDocumentsNeeded = 'markAgencyOfferContractAsDocumentsNeeded',
  AgencyOfferContracts = 'agencyOfferContracts',
  PauseContract = 'pauseContract',
  ResumeContract = 'resumeContract',
  ResetContractStatus = 'resetContractStatus'
}
