import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonsModule } from '@libs/common-ui/buttons/buttons.module';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';

@Component({
  selector: 'registration-header',
  templateUrl: './registration-header.component.html',
  styleUrls: ['./registration-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonsModule,
    NgIf,
    TranslateModule
  ],
  standalone: true
})
export class RegistrationHeaderComponent {

  techniciansContactPage = environment.techniciansContactUrl;
  companiesContactPage = environment.companiesContactUrl;
  @Input() brandName: BrandName | string;
  protected readonly BrandName = BrandName;

  constructor() {

  }

  goToContactForTechniciansPage() {
    window.open(this.techniciansContactPage, '_blank');
  }

  goToContactForCompaniesPage() {
    window.open(this.companiesContactPage, '_blank');
  }

}
