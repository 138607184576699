import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { BehaviorSubject, Subject } from 'rxjs';
import {StorageService} from "@libs/shared/services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class RegionSelectorService {
  private readonly REGION_KEY = 'userRegion';
  private regionSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(private store: Store<any>, private storageService: StorageService) {
    this.store.pipe(getFilteredApiRoot).subscribe(async apiRoot => {
        this.storageService.setItem(this.REGION_KEY, apiRoot.apiRegion);
        this.regionSubject.next(await this.getSelectedRegion());
    });
  }

  public async getSelectedRegion(): Promise<string | null> {
      return await this.storageService.getItem(this.REGION_KEY);
  }

  public getSubject(): Subject<string> {
    return this.regionSubject;
  }

  public clear(): void {
    this.storageService.removeItem(this.REGION_KEY);
  }
}
