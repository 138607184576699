import { Pipe, PipeTransform } from '@angular/core';
import { Rate } from '../models/rate.model';

@Pipe({
  name: 'rate'
})
export class RatePipe implements PipeTransform {
  transform(rate: Rate): string {
    if (!rate) {
      return;
    }
    return rate.currencySymbol + ' ' + rate.amount;
  }
}
