import { Component, Input } from '@angular/core';

@Component({
  selector: 'staffnow-counter-badge',
  templateUrl: './counter-badge.component.html',
  styleUrls: ['./counter-badge.component.scss']
})
export class CounterBadge {
  @Input() public amount: number = null;
  @Input() public size: 'small' | 'big' = 'small';
  @Input() public color: 'red' | 'green' = 'red';

  get classes(): string {
    return `count ${this.size} ${this.color}`;
  }

  public toString(count: number): string {
    return count > 99 ? '99+' : String(count);
  }
}
