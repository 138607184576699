import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class AbstractAccessGuardService  {
  protected constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.guardCheck(route, state).pipe(
      tap(guardCheckResult => {
        if (!guardCheckResult) {
          this.router.navigate(['profile']);
        }
      })
    );
  }

  abstract guardCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>;
}
