import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  SimpleChanges
} from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import {
  LoadNotificationList,
  NotificationsActions,
  ResetNotificationList
} from '../../state/notifications.actions';
import { FEATURE_STATE_KEY } from '../../state/notifications-state.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Notification } from '../../notification.model';

@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  host: {
    class: 'notifications-host'
  }
})
export class NotificationListComponent implements OnInit {
  @Input() public notificationsCount: any = null;
  @Output() public toggleNotifications = new EventEmitter();

  public notificationList: Array<Notification> = [];
  public loading: boolean = false;

  private scrollDebouncer: Subject<any> = new Subject();
  public isLastPage: boolean = false;
  private notificationsSub: Subscription = null;
  private SCROLL_DEBOUNCE_TIME: number = 50;
  private SCROLL_TRIGGER_RATIO: number = 0.7;

  @ViewChild('notificationsContainer')
  public notificationsContainer: ElementRef = null;

  constructor(
    private store: Store<any>,
    private elemRef: ElementRef,
    private actionsSubject: ActionsSubject
  ) {
    this.store
      .pipe(select(state => state[FEATURE_STATE_KEY].notificationList))
      .subscribe(notificationList => {
        this.notificationList = cloneDeep(notificationList);
      });

    this.store
      .pipe(
        select(
          state => state[FEATURE_STATE_KEY].isNotificationListLastPageLoaded
        )
      )
      .subscribe(isLastPage => {
        this.isLastPage = isLastPage;
      });

    this.scrollDebouncer
      .pipe(debounceTime(this.SCROLL_DEBOUNCE_TIME))
      .subscribe(value => this.handleScroll(value));

    this.notificationsSub = this.actionsSubject
      .pipe(
        filter(
          action =>
            action.type === NotificationsActions.LoadNotificationList ||
            action.type === NotificationsActions.NotificationListLoaded
        )
      )
      .subscribe(action => {
        this.loading =
          action.type === NotificationsActions.LoadNotificationList;
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadNotificationList());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.notificationsCount &&
      changes.notificationsCount.previousValue !==
        changes.notificationsCount.currentValue
    ) {
      this.store.dispatch(new ResetNotificationList());
      this.store.dispatch(new LoadNotificationList());
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetNotificationList());
    this.notificationsSub ? this.notificationsSub.unsubscribe() : null;
  }

  public onScroll($event: Event): void {
    const scrollRatio =
      this.notificationsContainer.nativeElement.scrollTop /
      (this.notificationsContainer.nativeElement.scrollHeight -
        this.notificationsContainer.nativeElement.clientHeight);
    this.scrollDebouncer.next(scrollRatio);
    /*
      This is needed in case the user scrolls very fast to the top of the container, before he has reached last page.
      In such a case the user is not able to trigger the scroll event anymore, therefore he can't request older messages.
    */
    if (scrollRatio === 1) {
      this.notificationsContainer.nativeElement.scrollTop =
        this.notificationsContainer.nativeElement.scrollTop - 1;
    }
  }

  public toggleNotificationList() {
    this.toggleNotifications.emit();
  }

  private handleScroll(ratio) {
    if (
      ratio > this.SCROLL_TRIGGER_RATIO &&
      !this.isLastPage &&
      !this.loading
    ) {
      this.store.dispatch(new LoadNotificationList());
    }
  }
}
