import {Component, Input} from '@angular/core';
import {
  isFixedPrice,
  OfferPriority,
  OfferType,
} from '@libs/shared/models/offer.model';
import {getEmbeddedResource, getUrl, hasEmbeddedResource, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {Resource} from '@libs/shared/bms-common/rest/resource.model';
import {JobOfferLinkRel} from '@libs/shared/linkrels/job-offer.linkrel';

@Component({
  selector: 'staffnow-job-offer-info',
  templateUrl: './job-offer-info.component.html',
  styleUrls: ['./job-offer-info.component.scss']
})
export class JobOfferInfoComponent {
  @Input() public offer: any = null;
  @Input() public isSharedOfferDto: boolean = false;
  @Input() public displayOfferLocation: boolean = false;
  @Input() public applicationExists: boolean = false;
  @Input() public applicationExistsMessage: string = '';

  public readonly OFFER_TYPE = OfferType

  get isFixedPriceOffer(): boolean {
    return isFixedPrice(this.offer);
  }

  get facilityLocation() {
    if (this.displayOfferLocation && this.offer?.location) {
      return this.offer?.location.name;
    }
    return this.hasFacility ? this.facility.location : null;
  }

  get facility(): any {
    return getEmbeddedResource(
      this.offer as Resource,
      JobOfferLinkRel.Facility
    );
  }

  private get hasFacility(): boolean {
    return hasEmbeddedResource(
      this.offer as Resource,
      JobOfferLinkRel.Facility
    );
  }

  get pictureUrl(): string {
    return hasLink(this.facility, JobOfferLinkRel.FacilityImage)
      ? getUrl(this.facility, JobOfferLinkRel.FacilityImage)
      : null;
  }

  get facilityName(): string {
    return this.hasFacility ? this.facility.name : null;
  }

  get offerPriority(): OfferPriority | null {
    return 'priority' in this.offer ? this.offer.priority : null;
  }
}
