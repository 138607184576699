<div class="title-wrapper">
    <h1 class="h1">Chat</h1>
    @if(!loggedInUserRole.isAdminOrModerator()) {
    <general-button (onClick)="goToCustomerSupportForm()" text="Contact Support" size="small"></general-button>
    }
</div>
@if (conversationList?.length > 0) { @for (conversation of conversationList; track conversation.conversationId){
<staffnow-conversation-item
    [conversation]="conversation"
    [selectedConversationId]="selectedConversationId"
    (loadConversation)="handleLoadConversation($event)"
>
</staffnow-conversation-item>
} } @else {
<div class="empty-conversations-list">
    <i class="material-icons"> question_answer </i>
    <h3 class="h3">
        {{ 'There are no conversations so far.' | translate }}
    </h3>
</div>
}
