<div class="agency-wrapper" [ngClass]="{ selected: isSelected }">
    <div class="agency">
        <div class="content" (click)="handleAgencyClick()">
            <span class="material-icons-outlined circle checked"> done </span>
            <span class="material-icons-outlined circle unchecked">
                circle
            </span>
            <facility-logo [resource]="agency"></facility-logo>
            <div class="name">{{ agency.fullName }}</div>
        </div>
    </div>
</div>
