import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Contract } from '@libs/shared/models/contract.model'
import { AgencyOfferOutDto, OfferOutDto, PackageOfferDto } from '@libs/shared/models/offer.model'
import { Store } from '@ngrx/store'
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils'
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel'
import { AcceptDateExtension, DeclineContract, DeclineDateExtension } from '@libs/request-overview-common/state/requests-overview.actions'
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils'
import { throttleTime } from 'rxjs/operators'
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subject } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'staffnow-technician-contract-actions',
  templateUrl: './technician-contract-actions.component.html',
  styleUrls: ['./technician-contract-actions.component.scss']
})
export class TechnicianContractActionsComponent {
  @Input() public contract: Contract = null;
  @Input() public offer: OfferOutDto | PackageOfferDto | AgencyOfferOutDto = null;
  @Input() public isDisabledAcceptDocumentButton: boolean = false;
  @Input() public shouldDisplayAcceptButton: boolean = false;
  @Output() public acceptDocument: EventEmitter<any> = new EventEmitter();

  private clickThrottler: Subject<any> = new Subject();

  constructor(
    private store: Store
  ) {
    this.clickThrottler
      .pipe(throttleTime(DURATION_1000_MILLISECONDS), untilDestroyed(this))
      .subscribe(() => {
        this.acceptDocument.emit();
      });
  }

  get canAcceptExtendedDate(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptDateExtension);
  }

  public acceptExtendedDate(): void {
    this.store.dispatch(AcceptDateExtension({ contract: this.contract }));
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offer?.periodTo);
  }

  get canDeclineExtendedDate(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineDateExtension);
  }

  public declineExtendedDate(): void {
    this.store.dispatch(DeclineDateExtension({ contract: this.contract }));
  }

  get canDeclineContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineContract);
  }

  public declineContract() {
    this.store.dispatch(DeclineContract({ contract: this.contract }));
  }

  public acceptDocumentEvent(): void {
    if (!this.isDisabledAcceptDocumentButton) {
      this.acceptDocument.emit();
    }
  }

}
