import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {getStaticEnvironment} from "@libs/shared/bms-common/environment/environment.selector";
import {AuthenticationService} from "@libs/auth/services/authentication.service";

@Component({
  selector: 'staffnow-unauthorized-app',
  templateUrl: './unauthorized-app.component.html',
  styleUrls: ['../common.scss']
})
export class UnauthorizedAppComponent {

  brandName: string = '';
  appUrl: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store<any>
  ) {
    authenticationService.clearAuthentication();
    this.store.pipe(getStaticEnvironment).subscribe(envData => {
      this.brandName = envData.brandConfig.brandName;
      this.appUrl = envData.brandConfig.appUrl;
    });
  }

  goToLoginPage() {
    this.authenticationService.goToLoginPage();
  }
}
