import {Component, OnInit} from '@angular/core';
import {AbstractOfferDetailsComponent} from '../abstract-offer-details.component';
import {cloneDeep} from 'lodash-es';
import {RequestOverviewLinkRel} from '@libs/shared/linkrels/request-overview.linkrel';
import {
  AgenciesLoaded,
  ApproveOffer,
  CloseOffer,
  DeleteOffer,
  InviteAgenciesToPackageOffer,
  LoadAgenciesToInvite,
  LoadPackageOfferWithContracts,
  ReopenOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import {getUrl, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {isDateInThePastOrToday} from '@libs/shared/helpers/date-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestOverviewState} from '../../../state/request-overview-state.model';
import {PackageContract} from '@libs/shared/models/package-contract.model';
import {PackageOfferDto} from '@libs/shared/models/offer.model';
import {BsModalService} from 'ngx-bootstrap/modal';
import {PackageAgencySelectionComponent} from '../../package-agency-selection/package-agency-selection.component';

@Component({
  selector: 'staffnow-mro-package-offer-details',
  templateUrl: './mro-package-offer-details.component.html',
  styleUrls: [
    '../facility-job-offer-details.component.scss',
    './mro-package-offer-details.component.scss'
  ]
})
export class MroPackageOfferDetailsComponent
  extends AbstractOfferDetailsComponent
  implements OnInit {
  public contracts: PackageContract[] = [];

  constructor(
    private router: Router,
    private confirmService: ModalService,
    private activatedRoute: ActivatedRoute,
    private bsModalService: BsModalService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadPackageOfferWithContracts({
            refNumber: params['offerRefNumber']
          })
        );
      })
    );
    this.onAction(AgenciesLoaded, action =>
      this.openInviteAgenciesModal(action.responseList)
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contracts = cloneDeep(state.contracts) as any;
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offer?.periodTo);
  }

  get canDeleteOffer(): boolean {
    return hasLink(this.offer, RequestOverviewLinkRel.Delete);
  }

  get canEditOffer(): boolean {
    return hasLink(this.offer, RequestOverviewLinkRel.Edit);
  }

  get canInviteAgencies(): boolean {
    return hasLink(this.offer, RequestOverviewLinkRel.InviteAgencies);
  }

  get canApprove(): boolean {
    return hasLink(this.offer, RequestOverviewLinkRel.ApproveOffer);
  }

  get canCloseOffer(): boolean {
    return hasLink(this.offer, RequestOverviewLinkRel.CloseOffer);
  }

  get canReopenOffer(): boolean {
    return hasLink(this.offer, RequestOverviewLinkRel.ReopenOffer);
  }

  public openEditOfferForm(): void {
    this.router.navigate(['/package-offer', 'edit', this.offer.refNumber], {
      state: {
        offer: this.offer,
        isEdit: true,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('MRO.PACKAGE_OFFER.JO_DETAILS'),
          this.translateService.instant('AGENCY.DETAILS.EDIT_JOB_OPENING'),
        ],
        mroUuid: (this.offer._embedded?.facility as any).uuid
      }
    });
  }

  public handleDeleteOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.DELETE_JO_CONFIRMATION'),
      () => {
        this.store.dispatch(DeleteOffer({url: getUrl(this.offer, 'delete')}));
      }
    );
  }

  public approveOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.APPROVE_PUBLIC_JO'),
      () => {
        this.store.dispatch(ApproveOffer({offer: this.offer}));
      }
    );
  }

  public closeOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.CLOSE_PACKAGE_JO'),
      () => this.store.dispatch(CloseOffer({offer: this.offer}))
    );
  }

  public reopenOffer(): void {
    this.confirmService.openConfirmModal(
      this.translateService.instant('SYSTEM.INFO.REOPEN_PACKAGE_JO'),
      () => this.store.dispatch(ReopenOffer({offer: this.offer}))
    );
  }

  public onInviteAgency() {
    this.store.dispatch(
      LoadAgenciesToInvite({offer: this.offer as PackageOfferDto})
    );
  }

  private openInviteAgenciesModal(agencyList: Array<any>) {
    this.bsModalService.show(PackageAgencySelectionComponent, {
      ignoreBackdropClick: true,
      class: 'invite-agencies-modal',
      initialState: {
        agencyList,
        onConfirmCallback: agencies => this.inviteAgenciesToOffer(agencies)
      }
    });
  }

  private inviteAgenciesToOffer(agencies: Array<any>) {
    this.store.dispatch(
      InviteAgenciesToPackageOffer({
        selectedAgencies: agencies,
        offer: cloneDeep(this.offer as PackageOfferDto)
      })
    );
  }
}
