<!-- This is the main upload form template represented as a card component -->
<ng-container *ngTemplateOutlet="uploadForm"></ng-container>

<!-- This is the main upload form passed to modal window -->
<staffnow-modal-window [content]="uploadForm" #modalWindow></staffnow-modal-window>

<!-- This is the main upload form template -->
<ng-template #uploadForm let-isInModal="isInModal">
    <div
        class="file-uploader-container"
        [style]="!!fileListHeight ? { height: fileListHeight } : {}"
        [ngClass]="{ 'uploader-card': !isInModal, isAdmin: isAdmin }"
    >
        <div class="file-uploader-head">
            <div class="file-uploader-title">
                <h1 class="h1">
                    <strong>
                        {{ documentName + (isMandatory ? '*' : '') }}
                    </strong>
                </h1>
            </div>
            <form>
                <input
                    ng2FileSelect
                    #fileInput
                    hidden
                    type="file"
                    name="file"
                    [accept]="allowedFileTypes"
                    [multiple]="multiple"
                    [uploader]="uploader"
                />
                <general-button
                    *ngIf="canEdit"
                    [isDisabled]="isLoading"
                    (onClick)="fileInput.click()"
                    [tooltip]="'SYSTEM.INFO.FILE_RESTRICTION_TOOLTIP' | translate: {fileTypes: allowedFileTypes, fileSizeLimit: fileSizeLimit}"
                    placement="bottom"
                    size="small"
                    [text]="'GENERAL.BROWSE' | translate | uppercase"
                ></general-button>
            </form>
        </div>
        <div
            *ngIf="canEdit"
            ng2FileDrop
            class="file-uploader-body"
            [uploader]="uploader"
            [ngClass]="{
                dragover: hasBaseDropZoneOver,
                'with-scroll': isInModal
            }"
            (fileOver)="fileOverBase($event)"
        >
            <staffnow-file-list
                [noDocumentsUploadedDisclaimer]="noDocumentsUploadedDisclaimer"
                [documents]="documents"
                [isAdmin]="isAdmin"
                [canEdit]="canEdit"
                [uploader]="uploader"
                (fileDeleted)="deleteFile($event)"
                (downloaded)="downloadDocument($event)"
                [maximumFilesLimit]="maximumFilesLimit"
                [showLabel]="showLabel"
                (labelSet)="handleSetLabel($event)"
            >
            </staffnow-file-list>
        </div>
        <div
            *ngIf="!canEdit"
            class="file-uploader-body"
            [ngClass]="{
                'with-scroll': isInModal
            }"
        >
            <staffnow-file-list
                [noDocumentsUploadedDisclaimer]="noDocumentsUploadedDisclaimer"
                [isReadOnly]="true"
                [documents]="documents"
                (downloaded)="downloadDocument($event)"
                [showLabel]="showLabel"
            >
            </staffnow-file-list>
        </div>
        <staffnow-info-icon-with-tooltip
            [displayHelpLabel]="true"
            [big]="true"
            [placement]="'right'"
            [tooltipMessage]="tooltip | translate"
            *ngIf="isTooltipEnabled"
        ></staffnow-info-icon-with-tooltip>
    </div>
</ng-template>
