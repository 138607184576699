import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { agencyRequestOverviewTabs, AgencyRequestViews } from '../../utils/agency-request-overview-wrapper.tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'staffnow-agency-request-overview-wrapper',
  templateUrl: './agency-request-overview-wrapper.component.html',
  styleUrls: ['./agency-request-overview-wrapper.component.scss']
})
export class AgencyRequestOverviewWrapperComponent implements OnInit {
  AGENCY_REQUEST_OVERVIEW_TABS = Object.values(agencyRequestOverviewTabs);
  public activeTab: any = this.AGENCY_REQUEST_OVERVIEW_TABS[0];

  private lastActiveView: string = null;

  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.watchQueryParmas();
  }


  private watchQueryParmas() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => this.updateFromParams(params));
  }

  private updateFromParams(params: Params) {
    const tabView = params.tab;
    this.activeTab =
      this.AGENCY_REQUEST_OVERVIEW_TABS.find(tab => tab.view == tabView) ||
      this.AGENCY_REQUEST_OVERVIEW_TABS[0];
    this.lastActiveView = this.activeTab.view;
  }

  public updateUrl(): void {
    const currentTab = this.activeTab.view;
    const queryParamsHandling =
      currentTab === this.lastActiveView ? 'merge' : null;
    this.router.navigate([], {
      queryParamsHandling,
      queryParams: {
        tab: currentTab,
        offerType: this.activeTab.offerType
      }
    });
  }

  get isOwnTechniciansView() {
    return this.activeTab.view === AgencyRequestViews.OwnTechnicians;
  }
}
