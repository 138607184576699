<div *ngIf="canShare" class="share-offer-container">
    <span class="share-offer-label">
        {{ 'Share this job opening' | translate }}
    </span>
    <div class="share-offer-input-container">
        <input
            class="form-control share-offer-text"
            [value]="shareUrl"
            readonly
        />
        <general-button
            (onClick)="copyShareOfferUrlToClipboard()"
            [icon]="shareClicked ? 'done' : 'file_copy'"
            backgroundStyle="outline"
            size="small"
            padding="7px"
        ></general-button>
    </div>
</div>
