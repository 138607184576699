import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'staffnow-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() @HostBinding('class.with-shadow') public shadow: boolean = true;
}
