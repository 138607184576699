import {Component, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {getEmbeddedResource} from '@libs/shared/bms-common/rest/resource.utils';
import {JobOfferLinkRel} from '@libs/shared/linkrels/job-offer.linkrel';
import {AbstractRequestCardComponent} from './abstract-request-card.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'staffnow-agency-request-card',
  templateUrl: './agency-request-card.component.html',
  styleUrls: [
    './request-card-common-styles.scss',
    './agency-request-card.component.scss'
  ]
})
export class AgencyRequestCardComponent
  extends AbstractRequestCardComponent
  implements OnChanges {
  public facility: any = null;

  constructor(private router: Router, store: Store) {
    super(store);
  }

  ngOnChanges(): void {
    this.facility = getEmbeddedResource(this.offer, JobOfferLinkRel.Facility);
  }


  public openProcess(): void {
    this.router.navigate([this.getJobOpeningPath(), this.offer.refNumber]);
  }

  private getJobOpeningPath() {
    if (this.isFixedPriceJobOpening) {
      return 'agency-package-contracts';
    } else if (this.isAgencyJobOpening) {
      return 'agency-offer-contracts';
    }
    return 'agency-contracts';
  }

}
