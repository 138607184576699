<div
    class="status"
    [ngClass]="{
        accepted: isAccepted,
        pending: isPending,
        rejected: isRejected,
        'documents-needed': isDocumentsNeeded || isNegotiation
    }"
    [tooltip]="tooltipMessage"
    triggers="mouseenter:mouseout"
    placement="bottom"
>
    @if (isAccepted) {
    <i class="material-icons status-icon"> done </i>
    } @else if (isDocumentsNeeded) {
    <i class="material-icons status-icon"> assignment_late </i>
    } @else if (isNegotiation) {
    <i class="material-icons status-icon"> sync_alt </i>
    } @else if (isPending) { @if (customIcon == UNDER_REVIEW_ICON) {
    <span class="material-icons status-icon"> hourglass_empty </span>
    } @else {
    <span class="status-icon"> ? </span>
    } } @else if (isRejected) { @if (shouldSoftenWords) {
    <i class="material-icons status-icon"> priority_high </i>
    } @else {
    <i class="material-icons status-icon"> clear </i>
    } }
</div>
