import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'card-tab-text',
  templateUrl: './card-tab-text.component.html',
  styleUrls: ['./card-tab-text.component.scss']
})
export class CardTabTextComponent {
  @Input() text: string = '';
  @Input() position: 'center' | 'right' = 'center';
  @Input() type: 'danger' | 'normal' = 'normal';

  @HostBinding('class.center') get isCenter(): boolean {
    return this.position === 'center';
  }
  @HostBinding('class.right') get isRight(): boolean {
    return this.position === 'right';
  }
  @HostBinding('class.danger') get isDanger(): boolean {
    return this.type === 'danger';
  }
}
