import { has, isNil, isUndefined } from 'lodash-es';
import { Link, Resource, ResourceUri } from './resource.model';
import { ApiRootState } from '../api-root/api-root.model';
import { AuthorizedUserProfileModel } from '@libs/shared/models/authorized-user-profile.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';

export function hasLink(resource: Resource, linkRel: String): boolean {
  return has(resource, `_links.${linkRel}`);
}

export function getLink(resource: Resource, linkRel?: string): Link {
  if (isUndefined(linkRel)) {
    return getLink(resource, 'self');
  }

  if (!resource || !resource._links) {
    console.error(`Error requesting ${linkRel}!`);
    throw new Error(
      `The given resource has no links: ${JSON.stringify(resource)}`
    );
  }

  const link = resource._links[linkRel];
  if (isUndefined(link)) {
    console.error(`Error requesting ${linkRel}!`);
    throw new Error(
      `The given resource <${getUrl(resource)}> has no link named <${linkRel}>`
    );
  }

  return link;
}

export function hasEmbeddedResource(
  resource: Resource,
  linkRel: string
): boolean {
  return has(resource, `_embedded.${linkRel}`);
}

export function getEmbeddedResourceNullsafe<T>(
  resource: Resource,
  linkRel: string
): T {
  return hasEmbeddedResource(resource, linkRel)
    ? getEmbeddedResource(resource, linkRel)
    : null;
}

export function getEmbeddedResource<T>(resource: Resource, linkRel: string): T {
  if (!resource._embedded) {
    console.error(`Error requesting ${linkRel}!`);
    throw new Error(
      'The given resource has no embedded resources: ' +
        JSON.stringify(resource)
    );
  }
  if (resource._embedded[linkRel] === undefined) {
    console.error(`Error requesting ${linkRel}!`);
    throw new Error(
      'The given resource <' +
        getUrl(resource) +
        '> has no embedded resource named <' +
        linkRel +
        '>'
    );
  }
  return <T>(resource._embedded[linkRel] as any);
}

export function getUrl(resource: Resource, linkRel?: string): ResourceUri {
  const link = getLink(resource, linkRel);
  return link.href;
}

export function getUrlNullsafe(
  resource: Resource,
  linkRel?: string
): ResourceUri {
  return hasLink(resource, linkRel) ? getLink(resource, linkRel).href : null;
}

export function getUrlWithoutParams(
  resource: Resource,
  linkRel?: string
): ResourceUri {
  const url = getUrl(resource, linkRel);
  return url.split('?')[0];
}

export function getAuthorizedProfileFromState(
  state: ApiRootState
): AuthorizedUserProfileModel {
  return getEmbeddedResource(
    state.apiRoot,
    ApiRootLinkRel.AuthorizedUserProfile
  );
}
