import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output
} from '@angular/core';

//TODO(SN-1369): find a way to fix the bug and then refactor 'favorite-button.component.html' to use one of the other existing buttons instead of <i></i>
@Component({
  selector: 'staffnow-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['favorite-button.component.scss']
})
export class FavoriteButtonComponent implements OnChanges {
  @Input() public isFavorite: boolean = false;
  @Output()
  public updateFavorites: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.isClicked')
  private isClicked: boolean = false;

  ngOnChanges(): void {
    this.isClicked = false;
  }

  public emitValue() {
    if (!this.isClicked) {
      this.isClicked = true;
      this.updateFavorites.emit(this.isFavorite);
    }
  }
}
