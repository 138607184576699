import { Component } from '@angular/core'
import { GeneralButtonComponent } from '@libs/common-ui/buttons/components/general-button/general-button.component'

@Component({
  selector: 'danger-button',
  templateUrl: '../general-button/general-button.component.html',
  styleUrls: ['../general-button/general-button.component.scss']
})
export class DangerButtonComponent extends GeneralButtonComponent {

  constructor() {
    super();
    this.backgroundStyle = "outline";
    this.color = "danger";
    this.size = "small";
  }

}
