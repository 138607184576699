<i class="material-icons close pointer" (click)="close()">close</i>
@if (entityList$ | async; as entities) {
    <div class="add-entity-modal-wrapper">
        <h2> {{ title }} </h2>
        <ng-select
            [items]="entities"
            [(ngModel)]="selectedEntity"
            [multiple]="false"
            [closeOnSelect]="false"
            [searchable]="true"
            [placeholder]="placeholder"
            (close)="onClose()"
            (clear)="onSearch('')"
            (scrollToEnd)="loadMore(entities)"
            (search)="onSearch($event.term)"
            [bindLabel]="bindLabel"
        >
        </ng-select>
        <general-button
            (onClick)="onConfirm()"
            [isDisabled]="!isFormValid()"
            class="mx-auto"
            text="{{confirmLabel}}"
        ></general-button>
    </div>
}
