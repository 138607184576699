import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { GetNotificationSubscriptions, ToggleTechnicianWeeklyJoSummarySubscription, UpdateUserProfile } from '../../state/user-profile.actions';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { Availability } from '@libs/shared/models/availability.model';
import { getLink, getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { Subscription } from 'rxjs';
import { selectUserProfile } from '@libs/user-profile/state/user-profile.selectors';
import { tap } from 'rxjs/operators';
import { NavigationTabs } from '@libs/user-profile/model/account-form.config';
import { MissingData, TechnicianProfileModel, TechnicianSelfIdentificationDto } from '@libs/shared/models/technician-profile.model';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { ToggleWeeklyJoSummarySubscriptionParameters } from '@libs/common-ui/weekly-jo-summary-subscription/weekly-jo-summary-subscription.component';
import { missingDataContextConstants } from '@libs/shared/constants/missingDataContext.constants';

interface TabData {
  tabName: string;
  isMissing: boolean;
}

@Component({
  selector: 'staffnow-edit-section',
  templateUrl: './edit-section.component.html',
  styleUrls: ['./edit-section.component.scss']
})
export class EditSectionComponent implements OnInit, OnDestroy {
  @Input() public technicianPendingChanges: Array<any> = [];
  @Input() public selectedAircraftList: Array<any> = [];
  public userProfile: UserProfileType = null;
  @Input() public selectedItem: string;
  @Input() public availability: Availability;
  @Input() public profileNavItems: Array<string> = [];
  @Input() public blocklist?: any;
  @Output() public loadMonths: EventEmitter<any> = new EventEmitter();
  @Output() public fetchAvailability: EventEmitter<any> = new EventEmitter();
  @Output() public loadBlocklist: EventEmitter<any> = new EventEmitter();
  @Output() public submitBlocklist: EventEmitter<any> = new EventEmitter();

  public activeItem: string = null;
  missingData: MissingData[] = [];
  technicianProfileEmbedded: any = null;
  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store<any>) {}

  public ngOnInit() {
    this.activeItem = this.profileNavItems[0];
    this.subscriptions.add(
      this.store
        .select(selectUserProfile)
        .pipe(
          tap(userProfile => {
            this.userProfile = userProfile;
            this.technicianProfileEmbedded = userProfile._embedded;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get tabs(): typeof NavigationTabs {
    return NavigationTabs;
  }

  public handleLoadMonths($event): void {
    this.loadMonths.emit($event);
  }

  public handleFetchAvailability(): void {
    this.fetchAvailability.emit();
  }

  public selectNewActiveItem(selectedItem: string): void {
    this.store.dispatch(UpdateUserProfile());
    this.activeItem = selectedItem;
  }

  public goToPreviousPerformanceFeedbackRequests(): void {
    this.activeItem = NavigationTabs.PerformanceFeedbacks;
  }

  public handleLoadBlocklist(): void {
    this.loadBlocklist.emit();
  }

  public handleSubmitBlocklist($event): void {
    this.submitBlocklist.emit($event);
  }

  public loadNotificationSubscriptions(): void {
    this.store.dispatch(
      GetNotificationSubscriptions({
        subscriptionsUrl: getUrl(this.userProfile, UserProfileLinkRel.Subscriptions)
      })
    );
  }

  get technicianSelfIdentification(): TechnicianSelfIdentificationDto {
    return (this.userProfile as TechnicianProfileModel).technicianSelfIdentificationOutDto;
  }

  get technicianSelfIdentificationUpdateUrl(): string {
    return hasLink(this.userProfile as TechnicianProfileModel, UserProfileLinkRel.UpdateTechnicianSelfIdentification)
      ? getLink(this.userProfile as TechnicianProfileModel, UserProfileLinkRel.UpdateTechnicianSelfIdentification).href
      : null;
  }

  public onToggleWeeklyJoSummarySubscription(event: ToggleWeeklyJoSummarySubscriptionParameters): void {
    this.store.dispatch(
      ToggleTechnicianWeeklyJoSummarySubscription({
        url: event.url,
        isSubscribing: event.isSubscribing
      })
    );
  }

  getMissingData(): TabData[] {
    const tabsData: TabData[] = [];
    this.profileNavItems.forEach(item => {
      switch (item) {
        case NavigationTabs.General:
          tabsData.push(
            this.getTabData(NavigationTabs.General, [
              missingDataContextConstants.PROFILE,
              missingDataContextConstants.TECHNICIAN
            ])
          );
          break;

        case NavigationTabs.Documents:
          tabsData.push(this.getTabData(NavigationTabs.Documents, [missingDataContextConstants.DOCUMENT]));
          break;

        case NavigationTabs.Skills:
          tabsData.push(this.getTabData(NavigationTabs.Skills, [missingDataContextConstants.TECHNICIAN_SKILLS]));
          break;

        case NavigationTabs.SelfIdentification:
          tabsData.push(
            this.getTabData(NavigationTabs.SelfIdentification, [
              missingDataContextConstants.TECHNICIAN_SELF_IDENTIFICATION
            ])
          );
          break;
      }
    });
    return tabsData;
  }

  isMissingDataIntoSpecificTab(missingDataContext: string[]): boolean {
    return this.technicianProfileEmbedded.missingData?.some(data => missingDataContext.includes(data.context));
  }

  private getTabData(tabName: string, missingDataContext: string[]): TabData {
    return {
      tabName: tabName,
      isMissing: this.isMissingDataIntoSpecificTab(missingDataContext)
    };
  }
}
