<div class="buttons">
    <i class="material-icons close pointer" (click)="close()" (keydown.enter)="close()">close</i>
</div>

<div class="pending-documents-container">
    <div class="header">
        <h1>{{ 'DOCUMENTS_NEEDED.TITLE' | translate }}</h1>
    </div>
    <section class="pending-more-documents">
        <p class="text-muted">
            {{ 'SYSTEM.INFO.PENDING_MORE_DOCUMENTS_REJECT' | translate }}
        </p>
    </section>
    <section class="pending-more-document-description">
        <p class="text-muted">
            {{ 'SYSTEM.INFO.PENDING_MORE_DOCUMENTS_REJECT_REASON' | translate }}
        </p>
        <staffnow-textarea
            [(ngModel)]="pendingMoreDocumentDescription"
            [ngModelOptions]="{ standalone: true }"
            rows="10"
            textAreaMaxLength="{{ textAreaMaxLength }}"
        ></staffnow-textarea>
    </section>
    <div class="actions">
        <general-button
            (onClick)="setPendingMoreDocumentDescription()"
            [text]="'GENERAL.USE_TEMPLATE' | translate"
        ></general-button>
    </div>
    <div class="actions">
        <general-button
            (onClick)="submit()"
            [isDisabled]="!canSubmit"
            [text]="'GENERAL.SUBMIT' | translate"
        ></general-button>
    </div>
</div>
