<div class="summary-header">
    <div>
        <h1 class="h1">{{ 'Summary of profession related documents in possession' }}</h1>
        <p class="guidelines">
            Please select the documents that you are in possession of related to your skills
            in their corresponding area, qualifications and experience. You should be able to
            present them when requested.
        </p>
    </div>
    <div *ngFor="let documentSummary of documentSummaries">
        <h3 class="py-2" (click)="onToggleSummary(documentSummary)">
            <a
                class="avi-collapse-toggle"
                [ngClass]="{ collapsed: !documentSummary.isExpanded }"
            >
                {{ documentSummary.title }}
            </a>
        </h3>
        <div
            class="collapse"
            [ngClass]="{ show: documentSummary.isExpanded }"
            role="tabpanel"
        >
            <div class="collapse-body">
                <div class="summary-checklist">
                    <p *ngFor="let documentType of documentSummary.documentTypes">
                        {{ documentType.title }}
                        <i class="material-icons" *ngIf="documentType.hasPossession"> done </i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>