import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandName, EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';

@UntilDestroy()
@Injectable()
export class RequestNewPasswordService {
  private backendUrl: string;
  
  constructor(
    private httpClient: HttpClient,
    private store: Store<any>
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.setBackendUrl(env);
    });
  }

  private setBackendUrl(env: EnvironmentState) {
    this.backendUrl = (env.brandConfig.brandName === BrandName.eLAUNCHNow) ?
      env.usaApiUrl : env.europeApiUrl;
  }

  public requestNewPassword(
    grecaptchaToken: string,
    email: string
  ): Observable<any> {
    const url = this.backendUrl + '/api/platform/request/password?grecaptchaToken={grecaptchaToken}';
    return this.httpClient.post(
      url.replace('{grecaptchaToken}', grecaptchaToken),
      email
    );
  }
}
