import { Action } from '@ngrx/store';

export enum AgencyRequestOverviewActionTypes {
  LoadOwnTechnicians = '[Agency Request Overview] Load Own Technicians',
  OwnTechniciansLoaded = '[Agency Request Overview] Own Technician Loaded',
  GetOwnTechniciansListing = '[Agency Request Overview] Get own technicians listing',
  OwnTechniciansListingLoaded = '[Agency Request Overview] Own technicians listing loaded',
  FailedToGetTechniciansListing = '[Agency Request Overview] Failed to get own technicians listing'
}

export class LoadOwnTechnicians implements Action {
  public readonly type = AgencyRequestOverviewActionTypes.LoadOwnTechnicians;
}

export class OwnTechniciansLoaded implements Action {
  public readonly type = AgencyRequestOverviewActionTypes.OwnTechniciansLoaded;

  constructor(public readonly ownTechniciansList: Array<any> = null) {}
}

export class GetOwnTechniciansListing implements Action {
  public readonly type =
    AgencyRequestOverviewActionTypes.GetOwnTechniciansListing;
  public readonly filterValue?: any = null;
  public readonly page?: number = null;

  constructor(filterValue?: any, page?: number) {
    this.filterValue = filterValue;
    this.page = page;
  }
}

export class OwnTechniciansListingLoaded implements Action {
  public readonly type =
    AgencyRequestOverviewActionTypes.OwnTechniciansListingLoaded;

  constructor(public readonly payload: any) {}
}

export class FailedToGetTechniciansListing implements Action {
  public readonly type =
    AgencyRequestOverviewActionTypes.FailedToGetTechniciansListing;
}
