import { Notification } from '../notification.model';

export const FEATURE_STATE_KEY = 'notifications';
export const BADGES_LINK_REL = 'badges';
export const NOTIFICATION_LIST_LINK_REL = 'notifications';

export interface NotificationsStateModel {
  badges: Array<any>;
  notificationList: Array<Notification>;
  isNotificationListLastPageLoaded: boolean;
  notificationsPage: number;
  pageSize: number;
}

export const initialState: NotificationsStateModel = {
  badges: [],
  notificationList: [],
  isNotificationListLastPageLoaded: false,
  notificationsPage: 0,
  pageSize: 0
};
