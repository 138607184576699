import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { getUrl } from '../bms-common/rest/resource.utils';
import { getFilteredApiRoot } from '../bms-common/api-root/api-root.selectors';

@Injectable()
export class ReportsResolverService  {
  constructor(private httpService: HttpClient, private store: Store<any>) {}

  public resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
        const url = getUrl(apiRoot, 'reports');
        this.httpService
          .get(url)
          .toPromise()
          .then(resp => resolve(resp['_links']))
          .catch(err => reject(err));
      });
    });
  }
}
