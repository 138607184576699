<div class="notification-subscription-container">
    <h1 class="h1">{{ 'USER_PROFILE.JO_NOTIFICATIONS.TITLE' | translate }}</h1>
    <div class="guidelines">
        <p>{{ 'USER_PROFILE.JO_NOTIFICATIONS.SUMMARY' | translate }}</p>
    </div>
    <div class="custom-control custom-checkbox subscribe-to-all-matching-offers-wrapper">
        <input
            type="radio"
            name="offers"
            class="custom-control-input"
            id="subscribe-to-all-offers"
            [checked]="userProfile.technicianOfferSubscription === SubscriptionType.ALL"
            [disabled]="isLoading"
            (click)="changeTechnicianOffersSubscription(SubscriptionType.ALL)"
        />
        <label for="subscribe-to-all-offers" class="custom-control-label font-weight-bold">
            {{ 'USER_PROFILE.JO_NOTIFICATIONS.SUBSCRIBE_ALL' | translate }}
        </label>
    </div>
    <div class="custom-control custom-checkbox subscribe-to-all-matching-offers-wrapper">
        <input
            type="radio"
            name="offers"
            class="custom-control-input"
            id="subscribe-to-all-matching-offers"
            [checked]="userProfile.technicianOfferSubscription === SubscriptionType.MATCHING"
            [disabled]="isLoading"
            (click)="changeTechnicianOffersSubscription(SubscriptionType.MATCHING)"
        />
        <label for="subscribe-to-all-matching-offers" class="custom-control-label font-weight-bold">
            {{ 'USER_PROFILE.JO_NOTIFICATIONS.SUBSCRIBE_MATCHING' | translate }}
        </label>
    </div>
    <div class="custom-control custom-checkbox subscribe-to-all-matching-offers-wrapper">
        <input
            type="radio"
            name="offers"
            class="custom-control-input"
            id="subscribe-to-selected-offers"
            [checked]="customChecked"
            [disabled]="isLoading"
            (click)="changeTechnicianOfferSubscriptionToCustom()"
        />
        <label for="subscribe-to-selected-offers" class="custom-control-label font-weight-bold">
            {{ 'USER_PROFILE.JO_NOTIFICATIONS.CUSTOMIZE_OPTIONS' | translate }}
        </label>
    </div>
    <div *ngIf="customChecked">
        <label for="mros">{{ 'USER_PROFILE.JO_NOTIFICATIONS.SELECT_AVIATION_COMPANY' | translate }}</label>
        <multiple-options-select
            id="mros"
            placeholder="Select an Aviation Company"
            [searchable]="true"
            [closeOnSelect]="true"
            [elements]="facilities"
            [selectedElements]="preselectedSubs"
            (onChange)="handleMroSelection($event)"
        ></multiple-options-select>

        <div class="list-of-subscriptions">
            <collapsible
                class="subscription-item"
                *ngFor="let subscription of technicianSubscriptions"
                [heading]="subscription.name + ' - ' + generateSubscriptionItemTitle(subscription)"
            >
                <div class="body">
                    <div *ngFor="let ameType of subscription.ameNomenclatures" class="nomenclature">
                        <label class="mb-1">{{ ameType.name | translate }}</label>

                        <div class="licenses-list">
                            <span
                                *ngFor="let license of ameType.licenses"
                                class="license-item custom-control custom-checkbox"
                            >
                                <input
                                    id="{{ generateUniqueId(subscription.uuid, ameType.name, license.name) }}"
                                    type="checkbox"
                                    class="custom-control-input"
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="setHasChanges()"
                                    [(ngModel)]="license.checked"
                                />
                                <label
                                    for="{{ generateUniqueId(subscription.uuid, ameType.name, license.name) }}"
                                    class="custom-control-label"
                                    >{{ license.singleton ? ameType.name : (license.name | translate) }}</label
                                >
                            </span>
                        </div>
                    </div>
                </div>
            </collapsible>
        </div>
        <div class="footer">
            <general-button
                text="Confirm Changes"
                [isLoading]="isLoading"
                [isDisabled]="disableCustomSubmitButton"
                (onClick)="submitCustomSubscription()"
            ></general-button>
        </div>
    </div>
</div>
