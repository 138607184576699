import { Component, Input } from '@angular/core';
import { DocumentSummary } from '@libs/user-profile/model/documents.model';

@Component({
  selector: 'staffnow-document-summaries',
  templateUrl: './document-summaries.component.html',
  styleUrls: ['./document-summaries.component.scss', './../collapsible-document.component.scss']
})
export class DocumentSummariesComponent {

  @Input() documentSummaries: DocumentSummary[] = [];

  onToggleSummary(documentSummary: any) {
    documentSummary.isExpanded = !documentSummary.isExpanded;
  }

  constructor() {}
}
