<input
    id="input"
    class="form-control"
    autocomplete="off"
    typeaheadOptionField="name"
    [(ngModel)]="currentValue"
    [typeahead]="entityList"
    [typeaheadMinLength]="minSearchLength"
    [typeaheadOptionsInScrollableView]="maxOptionsList"
    [placeholder]="placeholder"
    (typeaheadOnSelect)="handleSelectedEntityChange($event)"
    (blur)="onBlurHandler()"
/>
