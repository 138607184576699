import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, OperatorFunction, pipe, Subscription} from 'rxjs';
import {DisableAction, FormGroupState, MarkAsDirtyAction, MarkAsPristineAction, ResetAction, SetValueAction} from 'ngrx-forms';

import {cloneDeep, isEmpty, isNil} from 'lodash-es';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {getAirplanesSortedByName, getFilteredApiRoot, getLoggedInUserRole} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {getEmbeddedResource, getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {AgencyOfferInDto, OfferEditMode, OfferType,} from '@libs/shared/models/offer.model';

import {Actions, ofType} from '@ngrx/effects';
import {CustomNavigationService} from '@libs/shared/services/custom-navigation.service';
import {CreateAgencyOfferFormModel} from './create-agency-offer.model';
import {AdditionalBenefitForm, AdditionalBenefitFormUtils} from './components/additional-benefits-form.model';
import {
  AGO_PRESENTATION_MAX_LENGTH,
  CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY,
  otherAirplanesMaxLength,
  payRateMaxValue,
  workDaysMaxValue
} from './create-agency-offer.reducer';
import {AgencyOfferSubmitSuccess, FailedToSubmitAgencyOffer, ResetAgencyOfferForm, SubmitAgencyOffer} from './create-agency-offer.actions';
import {role, RoleWrapper} from '@libs/shared/models/roles.enum';
import {selectFacilityProfileFiltered} from '../../../../../apps/staffnow-platform/src/app/state/app.selectors';
import {GetFacilityProfile, ResetFacilityProfile} from '@libs/common-ui/facility-profile/facility-profile.actions';
import {FacilityProfileState} from '../../../../../apps/staffnow-platform/src/app/state/app-state.model';
import {AmeLicenseType} from '@libs/shared/models/ame-license-type.model';
import {AmeLicenseLevel} from '@libs/shared/models/ame-license-level.model';
import {FacilityProfileLinkRel} from '@libs/shared/linkrels/facility-profie.linkrel';
import {take} from 'rxjs/operators';
import {AmeTitle} from '@libs/shared/models/ame-title.model';
import {Link} from '@libs/shared/bms-common/rest/resource.model';
import {Rate} from '@libs/shared/models/rate.model';
import {LocationPO} from "@libs/shared/models/location.model";
import {Aircraft} from "@libs/shared/models/aircraft.model";

@Component({
  selector: 'staffnow-create-agency-offer-page',
  templateUrl: './create-agency-offer-page.component.html',
  styleUrls: ['./create-agency-offer-page.component.scss']
})
export class CreateAgencyOfferPageComponent implements OnInit, OnDestroy {
  public readonly PRESENTATION_MAX_LENGTH = AGO_PRESENTATION_MAX_LENGTH;
  public aircraftList: Aircraft[] = [];
  public preselectedFilters: AgencyOfferInDto;
  public isEditForm?: boolean = false;
  public createAgencyOfferForm: Observable<FormGroupState<CreateAgencyOfferFormModel>>;
  public createAgencyOfferFormValue: FormGroupState<CreateAgencyOfferFormModel> = null;
  public isSubmitting: boolean = false;
  public selectedAircraftList: Aircraft[] = [];
  public ameNomenclatureOptions: AmeLicenseType[] = [];
  public ameLicenseTypeOptions: AmeLicenseLevel[] = [];
  public isSingletonNomenclature: boolean = false;
  public fixedWorkingPatterns: { first: string; second: string; third: string; } = {
    first: '5/2',
    second: '3/3',
    third: '6/1'
  };
  public customWorkDaysPattern = {
    on: null,
    off: null
  };
  public manualUpdateControlValues = {
    ameTitleId: null,
    ameType: null,
    aircrafts: []
  };
  public getLabelFunction: Function = item => item;
  private subscriptions: Subscription = new Subscription();
  public benefits: AdditionalBenefitForm[] = AdditionalBenefitFormUtils.getDefault();
  public breadcrumbs: string[] = [
    'My Job Openings',
    'My Agency Fixed-Price Job Openings',
    'Job Opening Details',
    'Create Agency Job Opening'
  ];
  public isSettingInitialValues: boolean = true;
  public facilityProfile: FacilityProfileState;
  public location: LocationPO = null;
  private wrappedRole: RoleWrapper;
  private readonly mroUuid: string = '';
  private readonly packageContractId: number;
  private createUrl: Link = null;
  private editUrl: Link = null;
  private getMroFacilityUrl: Link = null;
  public agencyOfferPeriod: string[] = null;

  get isCustomPatternSelected(): boolean {
    return this.createAgencyOfferFormValue.value.workPattern === 'custom';
  }

  get currencySymbol(): string {
    return this.facilityProfile?.workingCurrency;
  }

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private customNavigationService: CustomNavigationService
  ) {
    let navigationState = this.router.getCurrentNavigation()?.extras?.state;
    if (navigationState) {
      sessionStorage.setItem('FILTERS', JSON.stringify(navigationState));
    } else if (sessionStorage.getItem('FILTERS')) {
      navigationState = JSON.parse(sessionStorage.getItem('FILTERS'));
    }
    this.preselectedFilters = navigationState?.preselectedFilters;
    this.agencyOfferPeriod = [navigationState?.startDate, navigationState?.endDate];
    this.mroUuid = navigationState?.mroUuid;
    this.packageContractId = navigationState?.packageContractId;
    this.createUrl = navigationState?.createUrl;
    this.editUrl = navigationState?.editUrl;
    this.getMroFacilityUrl = navigationState?.getMroFacilityUrl;
    this.isEditForm = navigationState?.isEdit;
    this.breadcrumbs = navigationState?.breadcrumbs;
    this.location = navigationState?.location;
    this.createAgencyOfferForm = this.store.pipe(
      select(state => <FormGroupState<CreateAgencyOfferFormModel>>(state[CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY]))
    );
    this.createAgencyOfferForm.subscribe(value => (this.createAgencyOfferFormValue = cloneDeep(value)));
    this.subscriptions.add(
      this.actions.pipe(ofType(AgencyOfferSubmitSuccess)).subscribe(action => {
        this.isSubmitting = false;
        sessionStorage.removeItem('FILTERS');
        if (action.refNumber && !this.wrappedRole.isAdminOrModerator()) {
          this.customNavigationService.goToOfferDetails(
            action.refNumber,
            OfferType.AGENCY,
            this.isEditForm
          );
        } else {
          this.customNavigationService.goBack();
        }
      })
    );

    this.subscriptions.add(
      this.actions
        .pipe(ofType(FailedToSubmitAgencyOffer))
        .subscribe(() => (this.isSubmitting = false))
    );

    this.storeSubscribe(pipe(getLoggedInUserRole, take(1)), userRole => {
      this.wrappedRole = role(userRole);
    });
  }

  ngOnInit() {
    this.storeSubscribe(pipe(getFilteredApiRoot, take(1)), apiRoot => {
      const facilityUrl = this.wrappedRole.isAgency()
        ? this.getMroFacilityUrl.href
        : getUrl(apiRoot, ApiRootLinkRel.GetMroFacilities) + '/' + this.mroUuid;
      this.store.dispatch(GetFacilityProfile({facilityUrl}));
    });
    this.storeSubscribe(
      pipe(selectFacilityProfileFiltered, take(1)),
      facilityProfile => this.initForm(facilityProfile)
    );
  }

  private initForm(facilityProfile: FacilityProfileState): void {
    this.facilityProfile = facilityProfile;
    this.ameNomenclatureOptions = getEmbeddedResource(
      facilityProfile,
      FacilityProfileLinkRel.AmeNomenclature
    );
    this.ameLicenseTypeOptions = [];
    this.store.dispatch(
      new SetValueAction(
        this.createAgencyOfferFormValue.controls.packageContractId.id,
        this.packageContractId
      )
    );

    if (!this.isEditForm) {
      this.setWorkingPattern(this.fixedWorkingPatterns.first);
    } else {
      this.setWorkingPattern(
        `${this.preselectedFilters['workDaysOn']}/${this.preselectedFilters['workDaysOff']}`
      );
    }

    this.storeSubscribe(getAirplanesSortedByName, aircraftList => {
      this.aircraftList = aircraftList;
    });

    this.setPreselectedValuesAndDisableOnRestrictedMode();

    if (this.isEditForm) {
      this.markAsPristine();
    }

    this.isSettingInitialValues = false;
  }

  private storeSubscribe<T, S>(pipedSelector: OperatorFunction<T, S>, subscribeFn: (a: S) => void) {
    this.subscriptions.add(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAgencyOfferForm());
    this.store.dispatch(ResetFacilityProfile());
    this.store.dispatch(new ResetAction(this.createAgencyOfferFormValue.id));
    this.subscriptions.unsubscribe();
    sessionStorage.removeItem('FILTERS');
  }

  public isPatternSelected(pattern: string): boolean {
    const {workDaysOn, workDaysOff} = this.createAgencyOfferFormValue.value;
    const [workDaysOnPatternValue, workDaysOffPatternValue] = pattern.split(
      '/'
    );

    return (
      !this.isCustomPatternSelected &&
      workDaysOn === +workDaysOnPatternValue &&
      workDaysOff === +workDaysOffPatternValue
    );
  }

  private setPreselectedValuesAndDisableOnRestrictedMode(): void {
    if (this.preselectedFilters && this.aircraftList) {
      const controls = this.createAgencyOfferFormValue.controls;
      const ameTitle: AmeTitle = (this.preselectedFilters as any).ameTitle;

      if (ameTitle) {
        this.getAmeLicenseOptions(ameTitle.type);
        this.manualUpdateControlValues.ameType = ameTitle.type;
        this.manualUpdateControlValues.ameTitleId = ameTitle.id;
        this.isSingletonNomenclature = isNil(ameTitle.license);
      }

      Object.keys(this.preselectedFilters).forEach(controlKey => {
        switch (controlKey) {
          case 'experience': {
            this.store.dispatch(new SetValueAction(controls['minExperience'].id, this.preselectedFilters[controlKey]));
            break;
          }
          case 'hasIndicativeRate': {
            const hasIndicativeRate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['hasIndicativeRate'].id, hasIndicativeRate));
            break;
          }
          case 'payRate':
          case 'maxPayRate': {
            const rate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls[controlKey].id, rate.amount));
            break;
          }
          case 'additionalBenefits': {
            this.preselectedFilters[controlKey].forEach(benefit => {
              const index = this.benefits.findIndex(formBenefit => formBenefit.type === benefit.type);
              this.benefits[index].amount = (benefit.amount as Rate).amount;
              this.benefits[index].description = benefit.description;
              this.benefits[index].isSelected = true;
            });
            break;
          }
          case 'minExperience':
          case 'workDaysOn':
          case 'workDaysOff':
          case 'otherAirplanes':
          case 'vacancies':
          case 'shortPresentation':
          case 'priority':
          case 'title':
          case 'refNumber': {
            this.store.dispatch(new SetValueAction(controls[controlKey].id, this.preselectedFilters[controlKey]));
            break;
          }
          case 'ameTitle': {
            this.store.dispatch(new SetValueAction(controls['ameType'].id, this.manualUpdateControlValues.ameType));
            this.store.dispatch(new SetValueAction(controls['ameTitleId'].id, this.manualUpdateControlValues.ameTitleId));
            break;
          }
          case 'airplanes': {
            this.preselectedFilters[controlKey].forEach(it => {
              this.selectedAircraftList.push(this.aircraftList.find(airplane => airplane.id === Number(it.id)));
            });
            this.store.dispatch(new SetValueAction(controls['airplanes'].id, this.preselectedFilters[controlKey].map(it => it.id)));
            break;
          }
          default: {
            break;
          }
        }
      });
      if (this.isRestrictedMode()) {
        this.disableFieldsOnRestrictedMode();
      }
    }
  }

  public isRestrictedMode(): boolean {
    const editModeKey = 'editMode';
    if (!this.preselectedFilters.hasOwnProperty(editModeKey)) {
      return false;
    }
    const mode = this.preselectedFilters[editModeKey];
    return !isNil(mode) && mode === OfferEditMode.RESTRICTED;
  }

  private disableFieldsOnRestrictedMode(): void {
    const alwaysActiveControls = ['priority', 'vacancies'];
    Object.keys(this.createAgencyOfferFormValue.controls).forEach(
      controlKey => {
        if (!alwaysActiveControls.includes(controlKey)) {
          this.store.dispatch(
            new DisableAction(
              this.createAgencyOfferFormValue.controls[controlKey].id
            )
          );
        }
      }
    );
  }

  public isPatternFixed(pattern: string): boolean {
    let isFixed = false;
    Object.keys(this.fixedWorkingPatterns).forEach(key => {
      isFixed = isFixed || this.fixedWorkingPatterns[key] === pattern;
    });
    return isFixed;
  }

  // Work pattern
  public setWorkingPattern(pattern: string): void {
    const controls = this.createAgencyOfferFormValue.controls;
    const workDaysPattern = pattern.split('/');

    const workingPattern = this.isPatternFixed(pattern) ? 'fixed' : 'custom';

    if (!this.isPatternFixed(pattern)) {
      this.customWorkDaysPattern.on = workDaysPattern[0];
      this.customWorkDaysPattern.off = workDaysPattern[1];
    }

    this.store.dispatch(
      new SetValueAction(controls.workPattern.id, workingPattern)
    );
    this.store.dispatch(
      new SetValueAction(controls.workDaysOn.id, Number(workDaysPattern[0]))
    );
    this.store.dispatch(
      new SetValueAction(controls.workDaysOff.id, Number(workDaysPattern[1]))
    );
    if (!this.isSettingInitialValues) {
      this.store.dispatch(new MarkAsDirtyAction(controls.workPattern.id));
    }
  }

  public setCustomWorkingPattern(e, isOnDays: boolean): void {
    if (e.target.value >= workDaysMaxValue) {
      if (this.customWorkDaysPattern.on >= workDaysMaxValue) {
        e.target.value = workDaysMaxValue;
        this.customWorkDaysPattern.on = workDaysMaxValue;
      }

      if (this.customWorkDaysPattern.off >= workDaysMaxValue) {
        e.target.value = workDaysMaxValue;
        this.customWorkDaysPattern.off = workDaysMaxValue;
      }
    }

    if (isOnDays) {
      this.store.dispatch(
        new SetValueAction(
          this.createAgencyOfferFormValue.controls.workDaysOn.id,
          this.customWorkDaysPattern.on
        )
      );
    } else {
      this.store.dispatch(
        new SetValueAction(
          this.createAgencyOfferFormValue.controls.workDaysOff.id,
          this.customWorkDaysPattern.off
        )
      );
    }
    if (
      !isNil(this.customWorkDaysPattern.on) &&
      !isNil(this.customWorkDaysPattern.off)
    ) {
      this.store.dispatch(
        new MarkAsDirtyAction(
          this.createAgencyOfferFormValue.controls.workPattern.id
        )
      );
    }
  }

  public customPatternSet(): void {
    this.store.dispatch(
      new SetValueAction(
        this.createAgencyOfferFormValue.controls.workPattern.id,
        'custom'
      )
    );
    this.store.dispatch(
      new SetValueAction(
        this.createAgencyOfferFormValue.controls.workDaysOn.id,
        this.customWorkDaysPattern.on
      )
    );
    this.store.dispatch(
      new SetValueAction(
        this.createAgencyOfferFormValue.controls.workDaysOff.id,
        this.customWorkDaysPattern.off
      )
    );
  }

  // Type of aircrafts
  public getListOfAircrafts(airplanes): void {
    const airplaneIdList = airplanes.map(airplane => airplane.id);
    this.store.dispatch(
      new SetValueAction(
        this.createAgencyOfferFormValue.controls.airplanes.id,
        airplaneIdList
      )
    );
    this.store.dispatch(
      new MarkAsDirtyAction(
        this.createAgencyOfferFormValue.controls.airplanes.id
      )
    );
  }

  // Indicative rate
  get hasIndicativeRate(): boolean {
    return this.createAgencyOfferFormValue.controls.hasIndicativeRate.value;
  }

  public toggleIndicativeRateOption(e): void {
    if (e.target.value === 'false') {
      this.resetMaxPayRateField();
    }
  }

  private resetMaxPayRateField(): void {
    const controlId = this.createAgencyOfferFormValue.controls.maxPayRate.id;
    this.store.dispatch(new SetValueAction(controlId, null));
  }

  // ameType and ameLicenses
  public onAmeTypeChange(ameType): void {
    const oldAmeType = this.createAgencyOfferFormValue.controls.ameType.value;
    if (oldAmeType !== ameType) {
      this.store.dispatch(
        new SetValueAction(
          this.createAgencyOfferFormValue.controls.ameType.id,
          ameType
        )
      );
      this.store.dispatch(
        new MarkAsDirtyAction(
          this.createAgencyOfferFormValue.controls.ameType.id
        )
      );
      this.getAmeLicenseOptions(ameType);
    }
  }

  public onAmeLicenseChange(ameTitleId: number): void {
    const control = this.createAgencyOfferFormValue.controls.ameTitleId;
    this.manualUpdateControlValues.ameTitleId = ameTitleId;
    this.store.dispatch(new SetValueAction(control.id, ameTitleId));
    this.store.dispatch(new MarkAsDirtyAction(control.id));
  }

  public getAmeLicenseOptions(ameType: string): void {
    if (isEmpty(this.ameNomenclatureOptions)) {
      return;
    }
    this.manualUpdateControlValues.ameTitleId = null;
    this.store.dispatch(
      new SetValueAction(
        this.createAgencyOfferFormValue.controls.ameTitleId.id,
        null
      )
    );
    const selectedAmeType = this.ameNomenclatureOptions.find(
      option => option.name == ameType
    );
    this.ameLicenseTypeOptions = selectedAmeType
      ? selectedAmeType.licenses
      : [];
    this.isSingletonNomenclature = selectedAmeType?.isSingleton;
    if (this.isSingletonNomenclature) {
      this.onAmeLicenseChange(this.ameLicenseTypeOptions[0].id);
    }
  }

  // Validations
  public payRateValidation(e): void {
    if (e.target.value >= payRateMaxValue) {
      e.preventDefault();
    }

    if (e.target.value.includes('.')) {
      const decimals = e.target.value.split('.');

      if (decimals[0].length !== 0 && decimals[1].length >= 2) {
        e.preventDefault();
      }
    }
  }


  public vacanciesValidation(e): void {
    if (e.target.value.includes('.')) {
      e.target.value = Math.floor(e.target.value);
    }

    if (e.target.value > 1000) {
      e.target.value = 1000;
    }
  }

  public experienceValidation(e): void {
    if (e.target.value.includes('.')) {
      const decimals = e.target.value.split('.');

      if (decimals[0].length !== 0 && decimals[1].length >= 1) {
        e.preventDefault();
      }
    }
  }

  public handleOtherAirplanesLength(e): void {
    if (e.target.value.length >= otherAirplanesMaxLength) {
      e.preventDefault();
    }
  }

  public handleFormSubmit(): void {
    this.markAsPristine();
    this.isSubmitting = true;

    this.handleAgencyOfferFormSubmit();
  }

  private handleAgencyOfferFormSubmit(): void {
    if (this.isEditForm && this.preselectedFilters) {
      const agencyOffer = this.preselectedFilters;
      this.createAgencyOfferFormValue.value['uuid'] = agencyOffer.uuid;
      this.store.dispatch(SubmitAgencyOffer({payload: this.getPayload(), editUrl: this.editUrl.href}));
    } else {
      this.store.dispatch(SubmitAgencyOffer({payload: this.getPayload(), createUrl: this.createUrl.href}));
    }
  }

  private getPayload(): CreateAgencyOfferFormModel {
    const payload = {...this.createAgencyOfferFormValue.value};

    if (this.benefits.some(benefit => benefit.isSelected)) {
      payload.additionalBenefits = this.benefits
        .filter(benefit => benefit.isSelected)
        .map(benefit => ({
          type: benefit.type,
          amount: benefit.amount,
          description: benefit.description?.trim()
        }));
    }
    return payload;
  }

  public cancelCreation(): void {
    sessionStorage.removeItem('FILTERS');
    this.customNavigationService.goBack();
  }

  public areBenefitsValid(): boolean {
    return this.benefits
      .filter(benefit => benefit.isSelected)
      .every(benefit => AdditionalBenefitFormUtils.isValid(benefit));
  }

  public isBenefitValid(benefit: AdditionalBenefitForm): boolean {
    return AdditionalBenefitFormUtils.isValid(benefit);
  }

  public markBenefitsAsDirty(): void {
    this.store.dispatch(new MarkAsDirtyAction(this.createAgencyOfferFormValue.id));
  }

  private markAsPristine(): void {
    this.store.dispatch(new MarkAsPristineAction(this.createAgencyOfferFormValue.id));
  }

}
