import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output, Type,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroupState } from 'ngrx-forms';
import { mroCompanyDetailsForm } from '../../state/registration.selectors';
import { Store } from '@ngrx/store';
import { AgencySelectionComponent } from './steps/agency-selection/agency-selection.component';
import { CompanyDetailsComponent } from './steps/company-details/company-details.component';
import { DisplayOptionsComponent } from './steps/display-options/display-options.component';
import { MroCompanyDetails } from '../../state/registration-state.model';
import {
  LoadLocationList,
  ResetDisplayOptions,
  ResetMroCompanyDetailsForm,
  ResetSelectedAgenciesList
} from '../../state/registration.actions';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'staffnow-mro-registration',
  templateUrl: './mro-registration.component.html',
  styleUrls: ['./mro-registration.component.scss', '../common.scss']
})
export class MroRegistrationComponent implements OnInit, OnDestroy {
  @Output() public onReset: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroupState<MroCompanyDetails>;

  public complete: boolean = false;
  steps: {title: string, icon:string, component: Type<unknown>, data: () => null }[];

  private subscription: Subscription = new Subscription();

  constructor(private store: Store,
              private translateService: TranslateService) {
    this.steps = [
      {
        title: this.translateService.instant('REGISTRATION.MRO.STEPS.REGISTER_YOUR_COMPANY'),
        icon: 'groups',
        component: CompanyDetailsComponent,
        data: () => null
      },
      {
        title: this.translateService.instant('REGISTRATION.MRO.STEPS.AGENCY_SELECTION'),
        icon: 'badge',
        component: AgencySelectionComponent,
        data: () => null
      },
      {
        title: this.translateService.instant('REGISTRATION.MRO.STEPS.DISPLAY_OPTIONS'),
        icon: 'desktop_windows',
        component: DisplayOptionsComponent,
        data: () => null
      }
    ]
    this.store.dispatch(LoadLocationList());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(mroCompanyDetailsForm).subscribe(registrationForm => {
        this.form = registrationForm;
      })
    );
  }

  onComplete(): void {
    this.complete = true;
  }

  public handleReset(): void {
    this.store.dispatch(ResetMroCompanyDetailsForm());
    this.store.dispatch(ResetSelectedAgenciesList());
    this.store.dispatch(ResetDisplayOptions());
    this.onReset.emit();
  }
}
