import { Pipe, PipeTransform } from '@angular/core';
import { profileStatusConstants } from '../constants/statuses.constants';

@Pipe({
  name: 'profileStatusNotificationFormatter'
})
export class ProfileStatusNotificationFormatterPipe implements PipeTransform {
  private statusKeys = Object.keys(
    profileStatusConstants.serverLangToHumanLang
  );

  private statusRegex: RegExp = new RegExp(this.statusKeys.join('|'), 'g');

  transform(value: string, args?: any): any {
    const matches = Array.from(new Set(value.match(this.statusRegex)));
    if (matches && matches.length > 0) {
      matches.forEach(match => {
        value = value.replace(
          `'${match}'`,
          `<br><span class="${match}">${profileStatusConstants.serverLangToHumanLang[match]}</span>`
        );
      });
    }
    return value;
  }
}
