import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {cloneDeep} from 'lodash-es';

import {Contract} from '@libs/shared/models/contract.model';
import {QualityRejectComponent} from '@libs/common-ui/quality-reject/quality-reject.component';
import {ProductionRejectComponent} from '@libs/common-ui/production-reject/production-reject.component';
import {DeclineContract, DocumentsNeededContract} from '@libs/request-overview-common/state/requests-overview.actions';
import {DocumentsNeededModalComponent} from '@libs/common-ui/documents-needed-modal/documents-needed-modal.component';
import {AgencyRejectComponent} from "@libs/common-ui/agency-reject/agency-reject.component";
import {RejectReason} from "@libs/shared/models/reasons.model";

@Injectable()
export class JobOpeningContractDeclinationService {
  constructor(public store: Store<any>, private modalService: BsModalService) {
  }

  public onDeclineAgency(contract: Contract, reason: RejectReason): void {
    this.modalService.show(AgencyRejectComponent, {
      ignoreBackdropClick: true,
      initialState: {
        reason: cloneDeep(reason),
        onRejectCallback: reasonPayload => this.dispatchDeclineContract(contract, [reasonPayload])
      }
    });
  }

  public onDeclineMroProduction(
    contract: Contract,
    reasonsArray: Array<any>
  ): void {
    this.modalService.show(ProductionRejectComponent, {
      ignoreBackdropClick: true,
      initialState: {
        reasonsArray: cloneDeep(reasonsArray),
        onRejectCallback: reasons => this.dispatchDeclineContract(contract, reasons)
      }
    });
  }

  public onDeclineMroQuality(
    contract: Contract,
    reasonsArray: Array<any>
  ): void {
    this.modalService.show(QualityRejectComponent, {
      ignoreBackdropClick: true,
      initialState: {
        reasonsArray: cloneDeep(reasonsArray),
        contract,
        onRejectCallback: reasons => this.dispatchDeclineContract(contract, reasons)
      }
    });
  }

  public onDocumentsNeeded(contract: Contract): void {
    this.modalService.show(DocumentsNeededModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        contract,
        onDocumentsNeededCallback: neededDocuments =>
          this.dispatchDocumentsNeededContract(contract, neededDocuments)
      }
    });
  }

  private dispatchDeclineContract(contract: Contract, reasons?: any): void {
    this.store.dispatch(DeclineContract({contract, reasons}));
  }

  private dispatchDocumentsNeededContract(contract: Contract, neededDocuments: string): void {
    this.store.dispatch(DocumentsNeededContract({contract, neededDocuments}));
  }
}
