import { Component, Input, OnInit } from '@angular/core';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { select, Store } from '@ngrx/store';
import { LoadTechnicianPerformanceFeedbacks } from '@libs/user-profile/state/user-profile.actions';
import { selectTechnicianPerformanceFeedbacks } from '@libs/user-profile/state/user-profile.selectors';
import { TechnicianPerformanceFeedback } from '@libs/user-profile/components/views/backoffice-performance-feedback-management/technician-performance-feedback.model';

@Component({
  selector: 'staffnow-backoffice-performance-feedback-management',
  templateUrl: './backoffice-performance-feedback-management.component.html',
  styleUrls: ['./backoffice-performance-feedback-management.component.scss']
})
export class BackofficePerformanceFeedbackManagementComponent implements OnInit {
  @Input() public userProfile: UserProfileType = null;

  technicianPerformanceFeedbacks: Array<TechnicianPerformanceFeedback> = [];

  protected readonly isNaN = isNaN;

  constructor(private store: Store<any>) {
    this.store.pipe(select(selectTechnicianPerformanceFeedbacks)).subscribe(feedbacks => {
      if (feedbacks) {
        this.technicianPerformanceFeedbacks = feedbacks.map(it => ({ ...it, expanded: false }));
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(
      LoadTechnicianPerformanceFeedbacks({
        url: getUrl(this.userProfile, UserProfileLinkRel.GetTechnicianPerformanceFeedbacks)
      })
    );
  }

  get hasScore(): boolean {
    return this.technicianPerformanceFeedbacks && this.technicianPerformanceFeedbacks.length > 0;
  }

  get overallScore(): number {
    return (
      this.technicianPerformanceFeedbacks
        .map(it => it.averageScore)
        .reduce((previous, current) => current + previous, 0) / this.technicianPerformanceFeedbacks.length
    );
  }

  onToggleFeedback(feedback: TechnicianPerformanceFeedback) {
    feedback.isExpanded = !feedback.isExpanded;
  }
}
