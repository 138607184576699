import { Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConversationService } from '../../services/conversation.service';
import { addUtils, Contract } from '@libs/shared/models/contract.model';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { LoadPublicProfile } from '@libs/request-overview-common/state/requests-overview.actions';
import {JobOpeningContractDeclinationService} from "@libs/shared/services/job-opening-contract-declination.service";
import { TranslateService } from '@ngx-translate/core';

@Component({ template: '' })
export abstract class AbstractContractCardComponent {
  @Input() public contract: Contract = null;

  protected translateService: TranslateService;

  public constructor(
    protected store: Store,
    protected conversationService: ConversationService,
    protected jobOpeningContractDeclinationService: JobOpeningContractDeclinationService,
  ) {
    this.translateService = inject(TranslateService);
  }

  get canCreateConversationWithTechnician(): boolean {
    return this.conversationService.canCreateConversationWithTechnician(
      this.contract
    );
  }

  get canGetPreviousConversationWithTechnician(): boolean {
    return this.conversationService.canGetPreviousConversationWithTechnician(
      this.contract
    );
  }

  get hasReasons(): boolean {
    const contract = addUtils(this.contract);
    return (
      contract.hasRejectedReasons() ||
      contract.hasPendingReasons() ||
      contract.hasAcceptanceNotes()
    );
  }

  public onOpenPublicProfile(entityType: EntityTypes): void {
    this.store.dispatch(
      LoadPublicProfile({ contract: this.contract, entityType })
    );
  }

  public initiateMessageWithTechnician(): void {
    this.conversationService.initiateMessageWithTechnician(this.contract);
  }
}
