<collapsible [heading]="heading + ' (' + contracts.length + ')'">
    <div *ngIf="contracts.length > 0; else noContracts" class="cards-list-grid">
        <staffnow-agency-offer-contract-card
            *ngFor="let contract of contracts"
            [contract]="contract"
        ></staffnow-agency-offer-contract-card>
    </div>
    <ng-template #noContracts>
        <div class="h3">
            {{ 'CONTRACTS.NO_CONTRACTS' | translate: {position: heading.toLowerCase()} }}
        </div>
    </ng-template>
</collapsible>
