import {createFeatureSelector, createSelector, select, Selector, Store} from '@ngrx/store';
import {AccountState, USER_PROFILE_FEATURE_KEY} from './user-profile-state.model';
import {getRouterSelectors, RouterReducerState} from '@ngrx/router-store';
import {map, withLatestFrom} from 'rxjs/operators';
import {getUrl, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';
import {AppState} from '@libs/shared/bms-common/api-root/api-root.model';
import {getLoggedInUserProfile} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {role} from '@libs/shared/models/roles.enum';
import {pipe} from 'rxjs';

const selectRouterState = createFeatureSelector<RouterReducerState>('router');
const {selectRouteParam} = getRouterSelectors(selectRouterState);

export const selectFeature: Selector<
  any,
  AccountState
> = createFeatureSelector<AccountState>(USER_PROFILE_FEATURE_KEY);

export const selectAccountForm = createSelector(
  selectFeature,
  state => state.accountForm
);

export const selectUserProfile = createSelector(
  selectFeature,
  state => state.userProfile
);

export const selectProfileLoading = createSelector(
  selectFeature,
  state => state.profileLoading
);

export const selectProfileStatus = createSelector(
  selectUserProfile,
  userProfile => userProfile._embedded.profile.profileStatus
);

export const selectProfileUrl = pipe(
  select(selectFeature),
  map(state => state.profileUrl)
);

export const selectUserProfileAvatarData = createSelector(
  selectUserProfile,
  userProfile => ({
    pictureUrl: hasLink(userProfile, UserProfileLinkRel.GetProfilePicture)
      ? getUrl(userProfile, UserProfileLinkRel.GetProfilePicture)
      : '',
    userUuid: userProfile ? userProfile._embedded.profile.userUuid : '',
    updatePictureUrl: hasLink(userProfile, UserProfileLinkRel.ProfilePicture)
      ? getUrl(userProfile, UserProfileLinkRel.ProfilePicture)
      : ''
  })
);

export const selectUserProfileAirplanes = createSelector(
  selectUserProfile,
  userProfile => 'airplanes' in userProfile && userProfile.airplanes
);

export const selectAvailability = createSelector(
  selectFeature,
  state => state.availability
);

export const selectAvailabilityStart = createSelector(
  selectFeature,
  state => state.availabilityStart
);

export const selectBlacklist = createSelector(
  selectFeature,
  state => state.blacklist
);

export const selectDocumentAreas = createSelector(
  selectFeature,
  state => state.documentAreas
);

export const selectIsLoadingDocumentAreas = createSelector(
  selectFeature,
  state => state.isLoadingDocumentAreas
);

export const selectIsDownloadingAllDocuments = createSelector(
  selectFeature,
  state => state.isDownloadingAllDocuments
);

export const selectTechnicianPerformanceFeedbacks = createSelector(
  selectFeature,
  state => state.feedbacks
);

// Note: I'm not sure this is the best way to do it, but for now it works
export const getCanEditProfile = (store: Store<AppState>) =>
  store.pipe(
    select(selectUserProfile),
    withLatestFrom(
      getLoggedInUserProfile(store),
      select(selectRouteParam('userUuid'))(store)
    ),
    map(([loadedUserProfile, loggedUserProfile, userUuid]) =>
      canEdit(loadedUserProfile, loggedUserProfile, userUuid)
    )
  );

function canEdit(loadedUserProfile, loggedUserProfile, userUuid) {
  const currentRole = role(loggedUserProfile.role);
  if (currentRole.isMro() && loadedUserProfile?.isFakeFacility) {
    return false;
  }
  return (
    currentRole.isAdminOrModerator() ||
    loggedUserProfile.userUuid === userUuid ||
    !userUuid ||
    loadedUserProfile.isOwnTechnician
  );
}
