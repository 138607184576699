import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';

import { TechnicianRegistrationFormModel } from './technician-registration-form.model';
import { FormErrorMessages } from '@platform/shared/constants/FormValidationErrorMessages';
import { technicianRegistrationForm } from '../../state/registration.selectors';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UpdateEmailVerify } from './technician-registration-form.reducer';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
  selector: 'technician-registration-form',
  templateUrl: './technician-registration-form.component.html',
  styleUrls: ['../common.scss', '../registration.component.scss']
})
export class TechnicianRegistrationFormComponent implements OnInit {
  @Input() public brandName: string;
  @Input() public privacyPolicyLink: string;
  @Input() public termsAndConditionsLink: string;
  @Output() public submit: EventEmitter<any> = new EventEmitter<any>();

  public registrationForm: FormGroupState<TechnicianRegistrationFormModel>;
  public FormValidationErrorMessages = FormErrorMessages;

  constructor(private store: Store<any>, private route: ActivatedRoute) {}

  public submitForm(): void {
    this.submit.emit(this.registrationForm.value);
  }

  ngOnInit(): void {
    this.store
      .select(technicianRegistrationForm)
      .pipe(untilDestroyed(this))
      .subscribe(registrationForm => {
        this.registrationForm = registrationForm;
      });
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['emailVerify'] === 'false')
        this.store.dispatch(UpdateEmailVerify({ emailVerify: false }));
    });
  }

  isRegionUsa(): boolean {
    return this.brandName === BrandName.eLAUNCHNow;
  }
}
