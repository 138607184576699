<div
    [ngClass]="
        doesUserHaveAccessToTechnician
            ? ['contract-head-wrapper', 'contract-head-wrapper-clickable']
            : ['contract-head-wrapper']
    "
    (click)="viewProfile()"
>
    <corner-card *ngIf="expired" [type]="CORNER_CARD_TEXT_TYPE_ENUM.EXPIRED"> </corner-card>
    <borderless-button
        (onClick)="viewProfile()"
        *ngIf="doesUserHaveAccessToTechnician"
        class="search-icon"
        icon="search"
        color="dark"
    ></borderless-button>
    <div class="expired-contract-alert" *ngIf="expired">
        {{ 'GENERAL.EXPIRED_QUESTION' | translate }}
    </div>
    <div class="contract-card-head">
        <div
            *ngIf="technicianHasProfilePicture"
            [ngStyle]="{ 'background-image': technicianPictureUrl }"
            class="profile-pic shadow"
        ></div>
        <div
            *ngIf="!technicianHasProfilePicture"
            class="profile-pic shadow"
        >
            <i class="material-icons"> person </i>
        </div>
        <div class="technician-data">
            <h3 class="technician-name">{{ technicianName }}</h3>
            <p
                class="titles text-secondary overflow-elipsis"
                [title]="technicianTitles"
            >
                {{ technicianTitles }}
            </p>
        </div>
    </div>
</div>

<div class="contract-content-wrapper">
    <ng-content></ng-content>
</div>
