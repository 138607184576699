<div
    [ngClass]="
        doesAgencyUserHaveAccessToTechnician
            ? ['agency-offer-contract-head-wrapper', 'agency-offer-contract-head-wrapper-clickable']
            : ['agency-offer-contract-head-wrapper']
    "
    (click)="viewProfile()"
>
    <corner-card *ngIf="expired" [type]="CORNER_CARD_TEXT_TYPE_ENUM.EXPIRED"> </corner-card>
    <span
        *ngIf="doesAgencyUserHaveAccessToTechnician"
        class="material-icons-outlined search-icon"
        (click)="viewProfile()"
        >search</span
    >
    <div class="expired-agency-offer-contract-alert" *ngIf="expired">
        {{ 'GENERAL.EXPIRED_QUESTION' | translate }}
    </div>
    <div class="agency-offer-contract-card-head">
        <div
            *ngIf="technicianHasProfilePicture"
            [ngStyle]="{ 'background-image': technicianPictureUrl }"
            class="profile-pic shadow"
        ></div>
        <div
            *ngIf="!technicianHasProfilePicture"
            class="profile-pic shadow"
        >
            <i class="material-icons"> person </i>
        </div>
        <div class="technician-data">
            <h3 class="technician-name">{{ technicianName }}</h3>
            <p
                class="titles text-secondary overflow-elipsis"
                [title]="technicianTitles"
            >
                {{ technicianTitles }}
            </p>
        </div>
    </div>
</div>

<div class="agency-offer-contract-content-wrapper">
    <ng-content></ng-content>
</div>
