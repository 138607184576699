import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { FilterConfigModel } from '../../models/filter-config.model';

@Component({
  selector: 'number-filter',
  templateUrl: './number-filter.component.html'
})
export class NumberFilterComponent implements OnChanges {
  @Input() filterConfig: FilterConfigModel = null;
  @Output() public onChange: EventEmitter<number> = new EventEmitter<number>();

  public value: number = null;

  constructor() {}

  ngOnChanges() {
    this.value = this.filterConfig.initialValue;
  }

  public handleChange(newValue: number): void {
    this.onChange.emit(newValue);
  }
}
