import { InjectionToken } from '@angular/core';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import {
  AuthenticationState,
  initialState
} from './authentication-state.model';
import { AuthInitialized, TokenReceived } from './authentication.actions';

export function getReducers(): ActionReducerMap<AuthenticationState> {
  return {
    validTokenReceived: tokenStatusReducer,
    authInitialized: authenticationReducer,
  };
}

const tokenStatusReducer = createReducer(
  initialState.validTokenReceived,
  on(TokenReceived, (state, { isValid }) => isValid)
);

const authenticationReducer = createReducer(
  initialState.authInitialized,
  on(AuthInitialized, () => true)
);

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<AuthenticationState>
>('[Auth] Registered Reducers');
