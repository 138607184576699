import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { Store, ActionsSubject } from '@ngrx/store';

import { ModalService } from '../../services/modal.service';
//TODO(SN-974): Find a better way to handle this.
import { UserProfileActions } from '@libs/user-profile/state/user-profile.actions';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'staffnow-tri-state-switch',
  templateUrl: './tri-state-switch.component.html',
  styleUrls: ['./tri-state-switch.component.scss']
})
export class TriStateSwitchComponent implements OnChanges {
  @Input() public rejected: boolean;
  @Input() public pendingChange: any;
  @Output() public selectedValue: EventEmitter<any> = new EventEmitter();

  public selectedOption: string;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private confirmService: ModalService
  ) {
    this.actionsSubject
      .pipe(ofType(UserProfileActions.FailedToChangeDocumentStatus))
      .subscribe(() => (this.selectedOption = 'null'));
  }

  ngOnChanges() {
    if (this.pendingChange) {
      this.selectedOption = 'null';
    }

    if (this.rejected) {
      this.selectedOption = 'no';
    }

    if (!this.pendingChange && !this.rejected) {
      this.selectedOption = 'yes';
    }
  }

  public setAsActive(value: string): void {
    let message = '';
    if (value === 'yes') {
      message = 'SYSTEM.INFO.APPROVE_DOC';
    } else if (value === 'no') {
      message = 'SYSTEM.INFO.REJECT_DOC';
    }

    this.confirmService.openConfirmModal(message, () => this.emitValue(value));
  }

  public emitValue(value: string): void {
    this.selectedOption = value;

    switch (this.selectedOption) {
      case 'yes':
        return this.selectedValue.emit(true);
      case 'no':
        return this.selectedValue.emit(false);
      default:
        return;
    }
  }
}
