import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { DashboardsComponent } from './dashboards.component';
import { SharedModule } from '@back-office/shared/shared.module';
import { DashboardsRoutingModule } from './dashboards-routing.module';
import { AviationDashboardPageComponent } from './aviation-dashboard-page/aviation-dashboard-page.component';
import { RejectionsDashboardPageComponent } from './rejections-dashboard-page/rejections-dashboard-page.component';

@NgModule({
  imports: [
    DashboardsRoutingModule,
    CommonModule,
    CommonUiModule,
    SharedModule
  ],
  declarations: [
    DashboardsComponent,
    AviationDashboardPageComponent,
    RejectionsDashboardPageComponent
  ],
  exports: []
})
export class DashboardsModule {}
