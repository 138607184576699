<div class="buttons">
    <i class="material-icons close pointer" (click)="close()" (keydown.enter)="close()">close</i>
    @if(readingProfile) {
    <span class="back" (click)="setReadingProfile(null)" (keydown.enter)="setReadingProfile(null)">
        <i class="material-icons">keyboard_backspace</i>
        {{ 'GENERAL.GO_BACK' | translate | uppercase }}
    </span>
    }
</div>
@if (readingProfile) {
<staffnow-facility-profile-modal
    [displayCloseButton]="false"
    [facilityProfile]="readingProfile"
></staffnow-facility-profile-modal>
} @else {
<div class="modal-title">{{ 'GENERAL.SELECT_AGENCY' | translate }}</div>
<div class="agencies-list-container">
    @for (agency of agenciesList; track agency.orderIndex) {
    <div
        class="agency-item"
        (click)="selectAgency(agency)"
        (keydown.enter)="selectAgency(agency)"
        [ngClass]="{ selected: agency.uuid === selectedAgency?.uuid }"
    >
        <facility-logo [resource]="agency" [greenBackground]="true"></facility-logo>
        <span
            class="material-icons-outlined agency-info-button"
            (click)="setReadingProfile(agency)"
            (keydown.enter)="setReadingProfile(agency)"
        >
            info
        </span>
        <div class="agency-title">{{ agency.name }}</div>
        @if(agency.slogan) {
        <div class="agency-slogan">{{ agency.slogan }}</div>
        } @if (!offer.hasHiddenRate) {
        <div class="pay-info">
            <div>YOU GET</div>
            @if (hasIndicativeRateForOffer) {
            <div class="pay-rate">
                {{ agency.payRate | rate }}
                {{ ' - ' }}
                {{ agency.maxPayRate | rate }}
            </div>
            } @else {
            <div class="pay-rate">{{ agency.payRate | rate }}</div>
            }
            <div class="per-day-label">{{ 'GENERAL.PER_HOUR' | translate | uppercase }}</div>
        </div>
        }
    </div>
    }
</div>
<div class="d-flex justify-content-center">
    <general-button
        class="confirm-btn"
        (onClick)="confirm()"
        [isDisabled]="!selectedAgency"
        [text]="'GENERAL.CONFIRM' | translate"
    ></general-button>
</div>
}
