<div id="unauthorized">
    <div class="unauthorized">
        <div class="unauthorized-403">
            <h1>Oops!</h1>
            <h2>
                {{ "403 - You don't have access to this page" | translate }}
            </h2>
            <p id="info-text">Maybe you landed here by mistake.</p>
            <p id="redirect">We will redirect you to the login page in a moment.</p>
        </div>
    </div>
</div>
