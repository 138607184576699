<select
    class="custom-select type-select"
    [(ngModel)]="selectedCountryId"
    (ngModelChange)="onPhoneChange()"
    *ngIf="!apiRegionIsUsa"
>
    <option [ngValue]="null">
        {{ 'Select Prefix' | translate }}
    </option>
    <option *ngFor="let country of countries" [ngValue]="country.id">
        {{ optionTitle(country.id) }}
    </option>
</select>
<input
    class="form-control level-select"
    id="phoneNumber"
    type="text"
    [(ngModel)]="phoneNumber"
    (ngModelChange)="onPhoneChange()"
    [placeholder]="'GENERAL.PHONE_PLACEHOLDER' | translate"
    [mask]="'GENERAL.PHONE_MASK_PLACEHOLDER' | translate"
/>
