import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { AdditionalBenefit } from '@libs/shared/models/additional-benefit.model';
import { OfferPriority } from '@libs/shared/models/offer.model';

export interface TechnicianSearchFilters {
  ameType: string;
  aircraft: string;
  ameLicense: string;
  experience: string;
  availability: string;
}

export interface CreateOfferFormModel {
  refNumber?: string;
  useCustomReference: boolean;
  title?: string;
  ameType: string;
  ameTitleId: string;
  periodTo: string;
  periodFrom: string;
  workPattern: string;
  otherAirplanes: string;
  shortPresentation: string;
  vacancies: number;
  hasIndicativeRate: boolean;
  hasHiddenRate: boolean;
  allowTcnApplications: boolean;
  chargeRate: number;
  maxChargeRate: number;
  workDaysOn: number;
  workDaysOff: number;
  locationId: number;
  minExperience: number;
  closeAfterDays: number;
  isPublic: boolean;
  airplanes: Array<any>;
  additionalBenefits: Array<AdditionalBenefit>;
  priority: OfferPriority;
  mroUuid?: string;
}

export interface FormState extends FormGroupState<CreateOfferFormModel> {}

export const createOfferInitialFormState: FormState = createFormGroupState<CreateOfferFormModel>('createOffer', {
  refNumber: null,
  useCustomReference: false,
  title: '',
  shortPresentation: '',
  periodFrom: '',
  periodTo: '',
  vacancies: null,
  ameType: null,
  ameTitleId: null,
  allowTcnApplications: true,
  airplanes: [],
  otherAirplanes: '',
  minExperience: null,
  closeAfterDays: null,
  hasIndicativeRate: false,
  hasHiddenRate: false,
  chargeRate: null,
  maxChargeRate: null,
  locationId: null,
  workPattern: '',
  workDaysOn: null,
  workDaysOff: null,
  isPublic: true,
  additionalBenefits: [],
  priority: OfferPriority.MEDIUM,
  mroUuid: ''
});
