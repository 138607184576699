import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import {
    LIB_FEATURE_KEY,
    LibStateModel,
    ToastMessage
} from '../../state/lib-state.model';
import { ToastMessageService } from '../toast-message.service';

@Component({
    selector: 'toast-list',
    templateUrl: './toast-message-list.component.html',
    styleUrls: ['./toast-message-list.component.scss']
})
export class ToastMessageListComponent implements OnDestroy {
    public AUTO_DISMISS_TIMEOUT: number = 5000;

    private toastListSub: Subscription = null;

    public toastMessages: Array<ToastMessage> = [];

    constructor(
        private store: Store<LibStateModel>,
        private toastMessageService: ToastMessageService
    ) {
        this.toastListSub = this.store
            .pipe(select(state => state[LIB_FEATURE_KEY].queue))
            .subscribe(toastList => {
                this.toastMessages = toastList;
            });
    }

    ngOnDestroy() {
        if (this.toastListSub) {
            this.toastListSub.unsubscribe();
        }
    }

    public oncloseHandler(message) {
        if (message) {
            this.toastMessageService.deleteToast(message.id);
        }
    }
}
