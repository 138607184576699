<div [class]="['platform-login-wrapper', brandName.toLowerCase()]">
    <div class="log-box-container">
        <div class="log-form-column">
            <div class="logo-container">
                <div [class]="['logo', brandName.toLowerCase()]"></div>
            </div>
            <div class="log-form-content">
                <div class="form-group">
                    <div *ngIf="!sent">
                        <h2>{{ 'VERIFYING_EMAIL' | translate}} </h2>
                    </div>
                    <div *ngIf="sent && success">
                        <h2>{{ 'VERIFYING_EMAIL.SUCCESS' | translate }}</h2>
                    </div>
                    <div *ngIf="sent && !success">
                        <h2 [innerHTML]="'VERIFYING_EMAIL.FAIL' | translate"></h2>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-outline-primary btn-lg" routerLink="/register">
                        {{ 'GENERAL.GO_TO_LOGIN' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
