<div class="state-selector-container">
    <ng-select
        [items]="states$ | async"
        bindLabel="name"
        bindValue="name"
        class="state-selector"
        [ngClass]="{ invalid: !ngrxFormControlState.isValid && ngrxFormControlState.isTouched }"
        [disabled]="ngrxFormControlState.isDisabled"
        [clearable]="!!selectedStateName"
        [(ngModel)]="selectedStateName"
        (change)="onStateChange($event)"
    >
    </ng-select>
</div>
